import * as yup from 'yup';

import i18n from 'locales/i18n';

import { convertDateToUTCString } from 'utils/dateTime';
import { maxTwoDigitsAfterDecimalRegExp } from 'utils/regexp';

export interface IUpdateResourceUsageWorkloadPlanFormData {
  plan: number;
  startDate: Date;
  endDate: Date;
}

export interface IUpdateResourceUsageWorkloadPlanFormDataToSubmit {
  resourceId: number;
  usageId: number;
  formData: {
    startDate: string;
    endDate: string;
    plan: number;
  };
}

const requiredValidation = 'forms:validation:required';

const dateFormatValidation = 'forms:validation:dateFormat';

export const buildValidationFields = () => ({
  plan: yup
    .number()
    .moreThan(-1, i18n.t('forms:validation:positiveNumber'))
    .typeError(i18n.t('forms:validation:mustBeNumber'))
    .test(
      i18n.t('forms:validation:maxDigitsAfterDecimalTest'),
      i18n.t('forms:validation:maxTwoDigitsAfterDecimal'),
      number => maxTwoDigitsAfterDecimalRegExp.test(String(number)),
    )
    .required(i18n.t(requiredValidation)),
  startDate: yup.date().typeError(i18n.t(dateFormatValidation)).required(i18n.t(requiredValidation)),
  endDate: yup.date().typeError(i18n.t(dateFormatValidation)).required(i18n.t(requiredValidation)),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (hours: number, startDate: string, endDate: string) => {
  return {
    plan: hours,
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  };
};

export const formatAttributesToSubmit = (
  resourceId: number,
  usageId: number,
  formData: IUpdateResourceUsageWorkloadPlanFormData,
): IUpdateResourceUsageWorkloadPlanFormDataToSubmit => {
  const { startDate, endDate, plan } = formData;

  const normalizeStartDate = convertDateToUTCString(startDate);
  const normalizeEndDate = convertDateToUTCString(endDate);

  return {
    resourceId,
    usageId,
    formData: {
      plan,
      startDate: normalizeStartDate,
      endDate: normalizeEndDate,
    },
  };
};
