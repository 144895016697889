import { FC, useState } from 'react';

import { Alert } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Loader from 'components/common/Loader';
import MultipleAutocomplete from 'components/common/SmartFilter/components/MultipleAutocomplete';
import { IMultipleSlot } from 'components/common/SmartFilter/types';

import { useGetTechnologiesQuery } from 'domain/technology/apiSlice';
import { ITechnology } from 'domain/technology/types';

import { SuggestionAutocompleteType } from 'enums/SuggestionAutocomplete';

import { IAutocompleteOption } from 'types/autocomplete';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { getSortParameter } from 'utils/runsack';
import { getPreparedDataForSuggestionAutocomplete } from 'utils/suggestionAutocomplete';

import { ITechnologiesProps } from './types';

const Technologies: FC<ITechnologiesProps> = props => {
  const { slot, onSlotPatch, setOpenPopover } = props;

  const { t } = useTranslation(['common', 'smartFilter']);

  const value = slot.value as Array<IMultipleSlot>;

  const [technologyName, setTechnologyName] = useState<string>('');

  const [debouncedTechnologyName] = useDebounce(technologyName, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: technologiesData,
    isFetching: isTechnologiesDataFetching,
    isLoading: isTechnologiesDataLoading,
    isError: isTechnologiesDataLoadingError,
  } = useGetTechnologiesQuery({
    nameCont: debouncedTechnologyName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  if (isTechnologiesDataLoading) {
    return <Loader />;
  }

  if (isTechnologiesDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCurrentTechnologiesChange = (technologies: Array<IAutocompleteOption>, filterWithEnter = false) => {
    if (_.isEmpty(technologies)) {
      onSlotPatch(
        slot.id,
        {
          value: [],
        },
        filterWithEnter,
      );
      return;
    }

    const value = technologies.map(technology => ({ id: technology.id, name: technology.ancestorsPath }));

    onSlotPatch(
      slot.id,
      {
        value,
      },
      filterWithEnter,
    );
  };

  const options = getPreparedDataForSuggestionAutocomplete<ITechnology>({
    data: technologiesData.technologies,
    type: SuggestionAutocompleteType.tree,
  });
  const currentOptions = getPreparedDataForSuggestionAutocomplete<IMultipleSlot>({ data: value });

  return (
    <MultipleAutocomplete
      options={options}
      currentOptions={currentOptions}
      isFetching={isTechnologiesDataFetching}
      onCurrentOptionsChange={handleCurrentTechnologiesChange}
      setName={setTechnologyName}
      name={technologyName}
      label={t('smartFilter:technology.label')}
      setOpenPopover={setOpenPopover}
    />
  );
};

export default Technologies;
