import { FC } from 'react';

import { List } from '@mui/material';

import WeekList from './components/WeekList';
import styles from './styles';
import { ITimeTrackerListProps } from './types';

const TimeTrackerList: FC<ITimeTrackerListProps> = props => {
  const {
    resourceId,
    editLockDate,
    currentWeekNumber,
    entriesGroupedByDate: { sortedWeekKeys, entriesByWeek },
    onTimeTrackerSettingsCopy,
  } = props;

  return (
    <List sx={styles.groups}>
      {sortedWeekKeys.map(weekKey => {
        const weekEntries = entriesByWeek[weekKey];
        const weekListKey = `${weekKey}:${weekEntries.totalWeekMinutes}`;

        return (
          <WeekList
            key={weekListKey}
            resourceId={resourceId}
            editLockDate={editLockDate}
            weekEntries={weekEntries}
            currentWeekNumber={currentWeekNumber}
            onTimeTrackerSettingsCopy={onTimeTrackerSettingsCopy}
          />
        );
      })}
    </List>
  );
};

export default TimeTrackerList;
