export enum AccessibleType {
  group = 'Group',
  project = 'Project',
}

export enum Permission {
  developer = 'developer',
  manager = 'manager',
  hr = 'hr',
  practiceLead = 'practiceLead',
  teamLead = 'teamLead',
  projectManager = 'projectManager',
  productDirector = 'productDirector',
}
