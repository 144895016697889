import * as yup from 'yup';

import i18n from 'locales/i18n';

import { TimeTrackerTrackableType } from 'domain/timeTracker/trackable/enums';

import { ITimeTrackerTrackableState, ITrackableResponse } from 'types/timeTrackerTrackable';

export interface ICreateResourceTrackedTimeEntryFormData {
  description: string;
  date: string;
  duration: number;
  trackable: ITrackableResponse;
}

export interface ICreateResourceTrackedTimeEntryFormDataToSubmit {
  formData: Omit<ICreateResourceTrackedTimeEntryFormData, 'trackable'> & {
    taskId?: number;
    trackableId: number | undefined;
    trackableType: TimeTrackerTrackableType | undefined;
  };
  resourceId: number;
}

const requiredDescriptionValidation = 'forms:validation:description';
const requiredTrackableValidation = 'forms:validation:trackable';

export const buildValidationFields = () => ({
  description: yup.string().required(i18n.t(requiredDescriptionValidation)).default(''),
  trackable: yup.mixed<ITimeTrackerTrackableState>().required(i18n.t(requiredTrackableValidation)).default(null),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => ({
  description: '',
  date: new Date().toISOString(),
  duration: 0,
  trackable: {
    tags: [],
    trackable: {
      trackable: null,
      task: null,
    },
  },
});

export const formatAttributesToSubmit = (
  resourceId: number,
  formData: ICreateResourceTrackedTimeEntryFormData,
): ICreateResourceTrackedTimeEntryFormDataToSubmit => {
  return {
    resourceId,
    formData: {
      ...formData,
      trackableId: formData?.trackable?.trackable?.trackable?.id,
      trackableType: formData?.trackable?.trackable?.trackable?.type,
    },
  };
};
