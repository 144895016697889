import { TableRow, TableCell, Typography, Avatar } from '@mui/material';
import { format, isEqual } from 'date-fns';
import get from 'lodash/get';
import MUISx from 'mui-sx';

import Div from 'components/common/Div';
import { getLastDate } from 'components/pages/Report/Vacations/components/Calendar/service';

import { FIRST_ITEM_INDEX, getLastIndex } from 'utils/array';
import { FULL_DATE_FORMAT } from 'utils/dateTime';
import { getFullName } from 'utils/person';

import DayCell from './components/DayCell';
import { groupVacationsByState } from './service';
import styles from './styles';
import { IRowProps } from './types';

const ZERO_COUNT = 0;

const Row = (props: IRowProps) => {
  const { isWideRange, resource, stickyCellsPositions, dateRangesByMonth } = props;

  const { id, vacationTotals } = resource;
  const vacationsByState = groupVacationsByState(resource);
  const dateRangeEndDate = getLastDate(dateRangesByMonth);

  return (
    <TableRow>
      {stickyCellsPositions.map((cellPosition, cellIndex) => {
        const isLastCell = cellIndex === getLastIndex(stickyCellsPositions);
        const key = `${cellIndex}:${id}`;

        if (cellIndex === FIRST_ITEM_INDEX) {
          return (
            <TableCell key={key} sx={styles.resourceCell} style={cellPosition}>
              <Div sx={styles.resourceCellContent}>
                <Avatar sx={styles.avatar} alt="avatar" />
                <Typography variant="heading3">{getFullName(resource)}</Typography>
              </Div>
            </TableCell>
          );
        }

        const totalValue = get(vacationTotals, cellPosition.id, ZERO_COUNT);

        return (
          <TableCell
            key={key}
            sx={MUISx(styles.totalCell, {
              condition: isLastCell,
              sx: styles.lastTotalCell,
            })}
            style={cellPosition}
          >
            <Typography variant="subtitle4">{totalValue}</Typography>
          </TableCell>
        );
      })}
      {dateRangesByMonth.map(daysInMonth => {
        const monthEndDate = daysInMonth[getLastIndex(daysInMonth)];

        return daysInMonth.map(date => {
          const isMonthEndDate = isEqual(date, monthEndDate);
          const isDateRangeEndDate = isEqual(date, dateRangeEndDate);
          const isRightBorderHidden = isDateRangeEndDate && isWideRange;
          const key = format(date, FULL_DATE_FORMAT);

          return (
            <DayCell
              key={key}
              date={date}
              vacationsByState={vacationsByState}
              isMonthEndDate={isMonthEndDate}
              isRightBorderHidden={isRightBorderHidden}
            />
          );
        });
      })}
    </TableRow>
  );
};

export default Row;
