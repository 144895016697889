import { SxStyles } from 'theme';

const styles: SxStyles = {
  daysInfo: {
    display: 'flex',
  },
  available: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 4,
  },
  scheduled: {
    display: 'flex',
    flexDirection: 'column',
  },
  daysTitle: {
    color: 'text.lightGray',
  },
  daysAvailable: {
    color: 'text.blue',
  },
  daysScheduled: {
    color: 'text.green',
  },
};

export default styles;
