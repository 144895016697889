import { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Button, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import { IConfirmDialogProps } from './types';

const ConfirmDialog: FC<IConfirmDialogProps> = props => {
  const { t } = useTranslation();
  const { open, onConfirm, onDismiss, title, message } = props;

  return (
    <Dialog open={open} onClose={onDismiss} PaperProps={{ sx: styles.wrapper }}>
      <Typography variant="h2">{title}</Typography>
      <Typography sx={styles.text}>{message}</Typography>
      <Stack direction="row" sx={styles.buttonBlock}>
        <Button onClick={onConfirm} autoFocus>
          {t('Yes')}
        </Button>
        <Button onClick={onDismiss} variant="text">
          {t('Cancel')}
        </Button>
      </Stack>
      <CloseIcon sx={styles.closeIcon} onClick={onDismiss} />
    </Dialog>
  );
};

export default ConfirmDialog;
