import { FC } from 'react';

import { Typography } from '@mui/material';

import { makeMonthHeader } from './service';
import styles from './styles';
import { IReportVacationsCalendarTableHeaderMonth } from './types';

const TableHeaderMonth: FC<IReportVacationsCalendarTableHeaderMonth> = props => {
  const { title, firstDayOfMonth, lastDayOfMonth, year } = props;

  const header = makeMonthHeader(title, firstDayOfMonth, lastDayOfMonth, year);

  return (
    <Typography variant="body2" sx={styles.root}>
      {header}
    </Typography>
  );
};

export default TableHeaderMonth;
