import { ScalarType } from 'routes/apiRoutes';
import { provideTags, TagTypes } from 'shared/api/types';

import { VacationRequestState } from 'components/pages/Report/Vacations/enums';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { FIRST_PAGE, ITEMS_PER_PAGE } from 'utils/pagination';

import {
  IReportsVacationsRequestApprovementParameters,
  IReportsVacationsRequestApprovementsResponse,
  IReportVacationRequestItem,
  IVacationApprovements,
} from './types';

interface IGetReportVacationRequestsResponse {
  vacationApprovements: Array<IVacationApprovements>;
  meta: IMeta;
}

export interface IReportVacationRequestsSmartFilters {
  groupIdsIn?: Array<number>;
  projectIdsIn?: Array<number>;
  resourceIdsIn?: Array<number>;
  vacationRequestStateEq?: VacationRequestState;
  technologyIdsIn?: Array<number>;
}

export interface IGetReportVacationRequestsParameters extends IReportVacationRequestsSmartFilters {
  page?: number;
  perPage?: number;
  sort?: ISortParameter;
}

interface IGetReportVacationRequestsProjectsResponse {
  projects: Array<IReportVacationRequestItem>;
  meta: IMeta;
}

interface IGetReportVacationRequestsSpecializationsResponse {
  practices: Array<IReportVacationRequestItem>;
  meta: IMeta;
}

interface IGetReportVacationRequestsProjectsParameters {
  vacationsRequestId: ScalarType;
  parameters?: { page?: number; perPage?: number };
}
interface IGetReportVacationRequestsSpecializationsParameters {
  vacationsRequestId: ScalarType;
  parameters?: { page?: number; perPage?: number };
}

export const reportVacationRequestsApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getReportVacationRequests: builder.query<IGetReportVacationRequestsResponse, IGetReportVacationRequestsParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1ReportsVacationsRequestsPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ReportVacationRequest, response?.vacationApprovements),
    }),
    getReportVacationRequestsProjects: builder.query<
      IGetReportVacationRequestsProjectsResponse,
      IGetReportVacationRequestsProjectsParameters
    >({
      query: ({ vacationsRequestId, parameters }) => {
        return {
          url: apiRoutes.apiV1ReportsVacationsRequestProjectsPath(vacationsRequestId),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ReportVacationRequestProjects, response?.projects),
    }),
    getReportVacationRequestsSpecializations: builder.query<
      IGetReportVacationRequestsSpecializationsResponse,
      IGetReportVacationRequestsSpecializationsParameters
    >({
      query: ({ vacationsRequestId, parameters }) => {
        return {
          url: apiRoutes.apiV1ReportsVacationsRequestSpecializationsPath(vacationsRequestId),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ReportVacationRequestSpecializations, response?.practices),
    }),
    reportsVacationsRequestApprovements: builder.query<
      IReportsVacationsRequestApprovementsResponse,
      IReportsVacationsRequestApprovementParameters
    >({
      query: ({ vacationsRequestId, page = FIRST_PAGE, perPage = ITEMS_PER_PAGE }) => {
        return {
          url: apiRoutes.apiV1ReportsVacationsRequestApprovementsPath(vacationsRequestId),
          method: 'GET',
          params: {
            page,
            perPage,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ReportVacationRequestApprovements, response?.approvements),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReportVacationRequestsQuery,
  useGetReportVacationRequestsProjectsQuery,
  useGetReportVacationRequestsSpecializationsQuery,
  useReportsVacationsRequestApprovementsQuery,
} = reportVacationRequestsApi;
