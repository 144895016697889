import { TagTypes, provideTags } from 'shared/api/types';

import { ConditionState } from 'components/common/SmartFilter/enums';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { ICreateGroupFormDataToSubmit } from './schema/create';
import { IUpdateGroupFormDataToSubmit } from './schema/update';
import { IGroup } from './types';

interface IGetGroupsParameters {
  perPage?: number;
  page?: number;
  paginated?: boolean;
  stateEq?: ConditionState;
  companyIdEq?: number;
  parentIdEq?: number;
  nameCont?: string;
  sort?: ISortParameter;
}

interface IGroupsResponse {
  groups: Array<IGroup>;
  meta: IMeta;
}

interface IGroupResponse {
  group: IGroup;
}

export const groupApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getGroups: builder.query<IGroupsResponse, IGetGroupsParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1GroupsPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.Group, response?.groups),
    }),
    createGroup: builder.mutation<IGroupResponse, ICreateGroupFormDataToSubmit>({
      query: formData => ({
        url: apiRoutes.apiV1GroupsPath(),
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: [TagTypes.Group],
    }),
    updateGroup: builder.mutation<IGroupResponse, IUpdateGroupFormDataToSubmit>({
      query: ({ formData, groupId }) => ({
        url: apiRoutes.apiV1GroupPath(groupId),
        method: 'PUT',
        data: formData,
      }),
      invalidatesTags: ({ group }) => [{ type: TagTypes.Group, id: group.id }],
    }),
    activateGroup: builder.mutation<IGroupResponse, number>({
      query: projectId => ({
        url: apiRoutes.activateApiV1GroupPath(projectId),
        method: 'PUT',
      }),
      invalidatesTags: ({ group }) => [{ type: TagTypes.Group, id: group.id }],
    }),
    deleteGroup: builder.mutation<void, number>({
      query: projectId => ({
        url: apiRoutes.apiV1GroupPath(projectId),
        method: 'DELETE',
      }),
      invalidatesTags: (_response, _error, id) => [{ type: TagTypes.Group, id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useUpdateGroupMutation,
  useGetGroupsQuery,
  useCreateGroupMutation,
  useActivateGroupMutation,
  useDeleteGroupMutation,
} = groupApi;
