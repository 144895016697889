import { SxStyles } from 'theme';

const styles: SxStyles = {
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  smartFilter: {
    marginBottom: 4.5,
  },
  buttons: {
    display: 'flex',
    gap: 1.25,
  },
};

export default styles;
