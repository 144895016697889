import { SxStyles } from 'theme';

const styles: SxStyles = {
  statusBar: {
    height: '50px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  showText: {
    paddingLeft: 2,
  },
  button: {
    paddingRight: 2.5,
    '& span': {
      marginRight: -1,
    },
  },
  arrowRightIcon: { marginLeft: -1 },
};

export default styles;
