import { FC, useMemo, useState } from 'react';

import { Alert, Button, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { useResourceTrackedTimeEntriesQuery } from 'domain/resource/trackedTimeEntry/apiSlice';
import { buildResourceTrackedTimeEntriesParameters } from 'domain/resource/trackedTimeEntry/service';

import { getSortParameter } from 'utils/runsack';

import { ICopiedTimeTrackerSettings } from './components/CreateResourceTrackedEntityField/types';
import TimeTrackerHeader from './components/TimeTrackerHeader';
import TimeTrackerList from './components/TimeTrackerList';
import styles from './styles';

const START_NUMBER_OF_DAYS = 30;
const FULL_NUMBER_OF_DAYS = 90;

const TimeTracker: FC = () => {
  const { t } = useTranslation(['common', 'timeTracker']);
  const { t: translateTimeTracker } = useTranslation('timeTracker');

  const [numberOfDays, setNumberOfDays] = useState<number>(START_NUMBER_OF_DAYS);
  const [copiedTimeTrackerSettings, setCopiedTimeTrackerSettings] = useState<ICopiedTimeTrackerSettings | undefined>();

  const { data: currentUser, isLoading: isCurrentUserLoading, isError: isCurrentUserError } = useGetCurrentUserQuery();

  const resourceTrackedTimeEntriesParameters = useMemo(() => {
    if (!currentUser) return skipToken;

    return buildResourceTrackedTimeEntriesParameters({
      resourceId: currentUser.user.resource.id,
      s: getSortParameter('date', 'desc'),
      numberOfDays,
    });
  }, [currentUser, numberOfDays]);

  const {
    data: resourceTrackedTimeEntriesResponse,
    isLoading: isResourceTrackedTimeEntriesLoading,
    isFetching: isResourceTrackedTimeEntriesFetching,
    isError: isResourceTrackedTimeEntriesLoadingError,
  } = useResourceTrackedTimeEntriesQuery(resourceTrackedTimeEntriesParameters);

  const isDateError = isResourceTrackedTimeEntriesLoadingError || isCurrentUserError;
  const isUserLoading = isCurrentUserLoading || !currentUser;
  const isTimeEntriesLoading = isResourceTrackedTimeEntriesLoading || !resourceTrackedTimeEntriesResponse;
  const isDataLoading = isUserLoading || isTimeEntriesLoading;

  const isLoadMoreButtonVisible = numberOfDays !== FULL_NUMBER_OF_DAYS && !isResourceTrackedTimeEntriesFetching;

  const handleLoadMore = () => setNumberOfDays(FULL_NUMBER_OF_DAYS);

  if (isDateError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  if (isDataLoading) return <Loader />;

  const {
    todayTotalHours,
    currentWeekTotalHours,
    pastWeekTotalHours,
    trackedTimeEditLockDate,
    trackedTimeEntries,
    trackedTimeEntriesGroupedByDate,
    currentWeekNumber,
  } = resourceTrackedTimeEntriesResponse;

  return (
    <Div sx={styles.root}>
      <Div sx={styles.header}>
        <Typography sx={styles.title} variant="h1">
          {t('timeTracker:title')}
        </Typography>
        <TimeTrackerHeader
          trackedTimeEntries={trackedTimeEntries}
          resourceId={currentUser.user.resource.id}
          todayTotalHours={todayTotalHours}
          currentWeekTotalHours={currentWeekTotalHours}
          pastWeekTotalHours={pastWeekTotalHours}
          copiedTimeTrackerSettings={copiedTimeTrackerSettings}
        />
      </Div>
      <TimeTrackerList
        resourceId={currentUser.user.resource.id}
        editLockDate={trackedTimeEditLockDate}
        currentWeekNumber={currentWeekNumber}
        entriesGroupedByDate={trackedTimeEntriesGroupedByDate}
        onTimeTrackerSettingsCopy={setCopiedTimeTrackerSettings}
      />
      <Div sx={styles.loadMoreButtonBlock}>
        {isResourceTrackedTimeEntriesFetching && <Loader />}
        {isLoadMoreButtonVisible && (
          <Button onClick={handleLoadMore} type="button">
            {translateTimeTracker('trackedTimeEntriesGroupList.loadMoreButton')}
          </Button>
        )}
      </Div>
    </Div>
  );
};

export default TimeTracker;
