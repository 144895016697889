import { FC } from 'react';

import { Tooltip, Typography } from '@mui/material';

import Div from 'components/common/Div';

import { getTechnologyNames } from 'domain/technology/presenter';

import styles from './styles';
import { IAllocationTableSpecializationCellProps } from './types';

const TableCellSpecialization: FC<IAllocationTableSpecializationCellProps> = props => {
  const { value } = props;

  const technologyNamesJoinedByNewLine = getTechnologyNames(value, '\n');
  const technologyNamesJoinedByComma = getTechnologyNames(value, ', ');

  return (
    <Tooltip arrow title={<Div sx={styles.tooltip}>{technologyNamesJoinedByNewLine}</Div>} placement="bottom-start">
      <Typography variant="subtitle1" sx={styles.root}>
        {technologyNamesJoinedByComma}
      </Typography>
    </Tooltip>
  );
};

export default TableCellSpecialization;
