import * as yup from 'yup';

import i18n from 'locales/i18n';

import { ITrackingType } from '../types';

const requiredValidation = 'forms:validation:required';

export interface IUpdateTrackingTypeFormData {
  name: string;
}

export interface IUpdateTrackingTypeFormDataToSubmit {
  id: number;
  formData: IUpdateTrackingTypeFormData;
}

export const buildValidationFields = () => ({
  name: yup
    .string()
    .max(300, i18n.t('forms:validation:maxSymbols', { count: 300 }))
    .required(i18n.t(requiredValidation))
    .default(''),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (trackingType: ITrackingType) => {
  return {
    name: trackingType.name,
  };
};

export const formatAttributesToSubmit = (
  id: number,
  formData: IUpdateTrackingTypeFormData,
): IUpdateTrackingTypeFormDataToSubmit => {
  return {
    id,
    formData,
  };
};
