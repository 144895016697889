import { FC, useState } from 'react';

import { Alert, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Calendar from 'components/common/Calendar';
import { CalendarInteractionMode } from 'components/common/Calendar/enums';
import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import YearSwitcher from 'components/common/YearSwitcher';

import { getCurrentOrFirstYearId } from 'domain/holiday/calendar/service';
import { useGetHolidayCalendarYearQuery } from 'domain/holiday/calendar/year/apiSlice';
import { useGetResourceVacationsQuery } from 'domain/resource/vacation/apiSlice';

import { getCurrentYear, getWeekStartsOn } from 'utils/calendar';

import Legend from './components/Legend';
import NewVacation from './components/NewVacation';
import Vacations from './components/Vacations';
import YearVacationInfo from './components/YearVacationInfo';
import styles from './styles';
import { IVacationProps } from './types';

const Vacation: FC<IVacationProps> = props => {
  const { user } = props;

  const [year, setYear] = useState(getCurrentYear());
  const yearId = getCurrentOrFirstYearId(user.calendar.years, year);

  if (!yearId) {
    return null;
  }

  const {
    isError: isGetHolidayCalendarYearError,
    isFetching: isHolidayCalendaryearFetching,
    data: holidayCalendarYear,
  } = useGetHolidayCalendarYearQuery(yearId);

  const resourceId = user.resource.id;
  const { t } = useTranslation('userProfile');

  const [startSelection, setStartSelection] = useState<string | null>(null);
  const [endSelection, setEndSelection] = useState<string | null>(null);

  const onSelectionStart = (date: string) => setStartSelection(date);
  const onSelectionEnd = (date: string) => setEndSelection(date);
  const onClear = () => {
    setStartSelection(null);
    setEndSelection(null);
  };

  const {
    isError: isGetResourceVacationsError,
    isFetching: isResourceVacationsFetching,
    data: resourceVacations,
  } = useGetResourceVacationsQuery({
    forYear: year,
    resourceId,
  });

  if (isGetResourceVacationsError || isGetHolidayCalendarYearError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  if (isResourceVacationsFetching || isHolidayCalendaryearFetching) {
    return <Loader />;
  }

  const { vacations, totals } = resourceVacations;

  return (
    <Div sx={styles.container}>
      <Div sx={styles.wrapper}>
        <Div sx={styles.yearInfo}>
          <Div sx={styles.yearSwitcher}>
            <YearSwitcher currentYear={year} onYearChange={year => setYear(year)} />
          </Div>
          <YearVacationInfo currentYear={year} totals={totals} />
          <Divider />
          <Legend />
          <Divider />
          {startSelection && endSelection && (
            <NewVacation
              startSelection={startSelection}
              endSelection={endSelection}
              onClear={onClear}
              resourceId={resourceId}
            />
          )}
          <Vacations
            resourceId={resourceId}
            vacations={vacations}
            daysInfo={holidayCalendarYear.year.days}
            startSelection={startSelection}
            endSelection={endSelection}
          />
        </Div>
        <Div sx={styles.calendar}>
          <Calendar
            interactionMode={CalendarInteractionMode.range}
            year={year}
            monthsInfo={holidayCalendarYear.year.months}
            holidays={holidayCalendarYear.year.days}
            vacations={vacations}
            weekStartsOn={getWeekStartsOn()}
            startSelection={startSelection}
            endSelection={endSelection}
            onSelectionStart={onSelectionStart}
            onSelectionEnd={onSelectionEnd}
          />
        </Div>
      </Div>
    </Div>
  );
};

export default Vacation;
