import { SxStyles } from 'theme';

const styles: SxStyles = {
  box: {
    maxHeight: '300px',
    overflow: 'auto',
    paddingLeft: 3,
    paddingRight: 2,
    paddingTop: 1,
    marginBottom: 2,
  },
  checkboxLabel: {
    color: 'text.blackLight',
  },
};

export default styles;
