import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { PermissionTargets } from './enums';
import { ICurrentUserPermissions } from './types';

interface IGetCurrentUserPermissionsParameters {
  permissionTarget: PermissionTargets;
}

export const currentUserPermissionsApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getCurrentUserPermissions: builder.query<ICurrentUserPermissions, IGetCurrentUserPermissionsParameters>({
      query: ({ ...parameters }) => {
        return {
          url: apiRoutes.apiV1CurrentUserPermissionsPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: ['currentUserPermissions'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetCurrentUserPermissionsQuery } = currentUserPermissionsApi;
