import { SxStyles } from 'theme';

const styles: SxStyles = {
  wrapper: {
    padding: 5,
    borderRadius: '8px',
  },
  text: {
    marginTop: 1.25,
    marginBottom: 3.125,
    marginX: 0,
    opacity: 0.5,
    color: 'text.primary',
  },
  buttonBlock: {
    width: '320px',
    justifyContent: 'flex-start',
    gap: 1,
  },
  closeIcon: {
    top: '22px',
    right: '22px',
    position: 'absolute',
    cursor: 'pointer',
    color: 'text.gray',
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
};

export default styles;
