import { FC } from 'react';

import { MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import i18n from 'locales/i18n';

import { SkillLevel } from 'enums/SkillLevel';

import { buildErrorMessage } from 'utils/form';

import { ISkillLevelProps } from './types';

const SKILL_LEVEL_OPTIONS = [
  { id: SkillLevel.junior, value: SkillLevel.junior, label: i18n.t('skillLevelSelect:junior') },
  { id: SkillLevel.middle, value: SkillLevel.middle, label: i18n.t('skillLevelSelect:middle') },
  { id: SkillLevel.senior, value: SkillLevel.senior, label: i18n.t('skillLevelSelect:senior') },
];

const SkillLevelSelect: FC<ISkillLevelProps> = props => {
  const { value, onChange, error, label, isRequired = false } = props;

  const { t } = useTranslation('skillLevelSelect');

  return (
    <TextField
      value={value}
      onChange={event => onChange(event.target.value as SkillLevel)}
      required={isRequired}
      helperText={buildErrorMessage(t, error)}
      error={Boolean(error)}
      variant="outlined"
      select
      label={label}
    >
      {SKILL_LEVEL_OPTIONS.map(skillLevel => {
        return (
          <MenuItem key={skillLevel.id} value={skillLevel.value}>
            {skillLevel.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default SkillLevelSelect;
