import _ from 'lodash';
import * as yup from 'yup';

import { INTERVAL_COMPARISONS } from 'components/common/SmartFilter/relations';
import { ISmartFilterComparison } from 'components/common/SmartFilter/types';

import { FilterPredicate } from 'enums/FilterPredicate';

import { floatInputRule } from 'utils/form';

export interface IHoursFormData {
  hours: string | null;
  comparison: ISmartFilterComparison | null;
}

const initialComparison = INTERVAL_COMPARISONS.find(comparison => comparison.predicate === FilterPredicate.eq);

export const buildValidationFields = () => ({
  hours: floatInputRule,
  comparison: yup.mixed<ISmartFilterComparison>().nullable().default(initialComparison),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (form: IHoursFormData | null) => {
  return _.isNil(form)
    ? buildValidationSchema().getDefault()
    : {
        hours: form?.hours,
        comparison: form?.comparison,
      };
};
