import { TagTypes } from 'shared/api/types';

import { IUser } from 'domain/user/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

interface ICurrentUserResponse {
  user: IUser;
}

export const currentUserApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getCurrentUser: builder.query<ICurrentUserResponse, void>({
      query: () => {
        return {
          url: apiRoutes.apiV1CurrentUserPath(),
          method: 'GET',
        };
      },
      providesTags: [TagTypes.CurrentUser],
    }),
  }),
  overrideExisting: false,
});

export const { useGetCurrentUserQuery } = currentUserApi;
