import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IGroup } from 'domain/group/types';
import { IHolidayCalendar } from 'domain/holiday/calendar/types';

const requiredValidation = 'forms:validation:required';

export interface IUpdateGroupFormData {
  name?: string;
  parentGroup: IGroup;
  calendar: IHolidayCalendar;
}

export interface IUpdateGroupPreparedFormData {
  parentId?: number | null;
  name?: string;
  calendarId: number;
}

export interface IUpdateGroupFormDataToSubmit {
  groupId: number;
  formData: IUpdateGroupPreparedFormData;
}

export const buildValidationFields = () => ({
  name: yup.string().required(i18n.t(requiredValidation)).default(''),
  parentGroup: yup.mixed<IGroup>().nullable().default(null),
  calendar: yup.mixed<IHolidayCalendar>().required(i18n.t(requiredValidation)).nullable().default(null),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (currentGroup: IGroup, currentSuperGroup: IGroup) => {
  return {
    name: currentGroup.name,
    parentGroup: currentSuperGroup,
    calendar: currentGroup.calendar,
  };
};

export const formatAttributesToSubmit = (
  groupId: number,
  formData: IUpdateGroupFormData,
): IUpdateGroupFormDataToSubmit => {
  const { name, parentGroup, calendar } = formData;

  const parentId = _.isNil(parentGroup) ? null : parentGroup.id;

  return {
    groupId,
    formData: {
      name,
      parentId,
      calendarId: calendar.id,
    },
  };
};
