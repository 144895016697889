import { FC, useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import _ from 'lodash';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateVacancyMutation } from 'domain/vacancy/apiSlice';
import {
  IUpdateVacancyFormData,
  formatAttributesToSubmit,
  initialValues,
  buildValidationSchema,
} from 'domain/vacancy/schemas/update';

import { MeasurementUnitFilter } from 'enums/MeasurementUnitFilter';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { IUpdateVacancyFteWithDatesFormProps } from './types';

const UpdateVacancyFteWithDatesForm: FC<IUpdateVacancyFteWithDatesFormProps> = props => {
  const { vacancy, projectId, groupId } = props;

  const [hasEndDate, setHasEndDate] = useState<boolean>(!_.isNil(vacancy.endDate));

  const { t } = useTranslation(['common', 'updateVacancyFteWithDatesForm']);

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const { closeModal } = useContext(ModalContext);

  useEffect(() => {
    if (!hasEndDate) {
      setValue('endDate', null);
    }
  }, [hasEndDate]);

  const [updateVacancy, { isLoading: isUpdateVacancyLoading }] = useUpdateVacancyMutation();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IUpdateVacancyFormData>({
    defaultValues: initialValues(vacancy),
    resolver: yupResolver(buildValidationSchema()),
  });

  const onSubmit = async (formData: IUpdateVacancyFormData) => {
    try {
      await updateVacancy(formatAttributesToSubmit(projectId, groupId, vacancy.id, formData)).unwrap();

      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const {
    field: { value: startDate, onChange: onStartDateChange },
  } = useController({ name: 'startDate', control });

  const {
    field: { value: endDate, onChange: onEndDateChange },
  } = useController({ name: 'endDate', control });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div>
        <Div sx={styles.hasEndDate}>
          <FormControlLabel
            sx={styles.switchEndDate}
            control={
              <Switch
                disabled={isUpdateVacancyLoading}
                checked={hasEndDate}
                onChange={() => setHasEndDate(!hasEndDate)}
              />
            }
            label={
              <Typography variant="subtitle1">{t('updateVacancyFteWithDatesForm:switches.endDate.label')}</Typography>
            }
          />
        </Div>

        <Div sx={styles.fields}>
          <DatePicker
            label={t('updateVacancyFteWithDatesForm:fields.startDate.label')}
            value={startDate}
            onChange={onStartDateChange}
            disabled={isUpdateVacancyLoading}
            renderInput={parameters => (
              <TextField
                sx={styles.datePickerField}
                {...parameters}
                error={Boolean(errors.startDate)}
                helperText={buildErrorMessage(t, errors.startDate)}
              />
            )}
          />

          <DatePicker
            label={t('updateVacancyFteWithDatesForm:fields.endDate.label')}
            value={endDate}
            onChange={onEndDateChange}
            disabled={!hasEndDate || isUpdateVacancyLoading}
            renderInput={parameters => (
              <TextField
                sx={styles.datePickerField}
                {...parameters}
                error={Boolean(errors.endDate)}
                helperText={buildErrorMessage(t, errors.endDate)}
              />
            )}
          />

          <TextField
            {...register('fte')}
            label={`${MeasurementUnitFilter.FTE} %`}
            InputLabelProps={{ sx: styles.placeholder }}
            error={Boolean(errors.fte)}
            sx={styles.input}
            disabled={isUpdateVacancyLoading}
          ></TextField>
        </Div>

        <Div>
          <Button sx={styles.changeAlloCationButton} type="submit" disabled={isUpdateVacancyLoading}>
            {t('updateVacancyFteWithDatesForm:buttons.changeAllocation')}
          </Button>
          <Button type="reset" variant="outlined" onClick={closeModal} disabled={isUpdateVacancyLoading}>
            {t('updateVacancyFteWithDatesForm:buttons.cancel')}
          </Button>
        </Div>
      </Div>
    </form>
  );
};

export default UpdateVacancyFteWithDatesForm;
