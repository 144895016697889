import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingX: 2,
    paddingBottom: 3,
  },
  resourceHeaderTitle: {
    color: 'text.red',
  },
  dateHeaderTitle: {
    color: 'text.gray',
  },
};

export default styles;
