import { FC, useState, useContext } from 'react';

import { MoreHorizOutlined } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Typography, Tooltip } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { ModalContext } from 'contexts/ModalContext';

import { useDeleteResourceUsageMutation } from 'domain/resource/usage/apiSlice';

import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { getFullName } from 'utils/person';

import UpdateAllocationForm from './components/UpdateAllocationForm';
import styles from './styles';
import { IAllocationActionsProps } from './types';

const AllocationActions: FC<IAllocationActionsProps> = props => {
  const { allocation } = props;

  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const { getConfirmation } = useConfirmDialog();

  const { t } = useTranslation('allocationActions');

  const [deleteAllocation, { isLoading: isDeleteAllocationLoading }] = useDeleteResourceUsageMutation();
  const { openModal } = useContext(ModalContext);

  const [allocationActionsMenu, setAllocationActionsMenu] = useState<null | HTMLElement>(null);

  const isAllocationActionsMenuOpen = Boolean(allocationActionsMenu);

  const handleAllocationActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAllocationActionsMenu(event.currentTarget);
  };

  const handleAllocationActionsClose = () => {
    setAllocationActionsMenu(null);
  };

  const fullName = getFullName(allocation);

  const handleAllocationUpdateModalOpen = () => {
    setAllocationActionsMenu(null);
    openModal({
      title: t('editAllocationModalTitle'),
      subtitle: `${fullName} - ${allocation.projectName}`,
      content: <UpdateAllocationForm allocation={allocation} />,
    });
  };

  const handleAllocationRemoveModalOpen = async () => {
    setAllocationActionsMenu(null);
    if (
      await getConfirmation({
        title: '',
        message: (
          <Typography variant="subtitle1" component="span">
            <Trans
              t={t}
              i18nKey="updateAllocationForm.removeAllocationMessage"
              values={{ resourceUsageFullName: fullName, projectName: allocation.projectName }}
              components={{
                1: <Typography variant="heading3" component="span" />,
                2: <br />,
                3: <Typography variant="heading3" component="span" />,
              }}
            ></Trans>
          </Typography>
        ),
      })
    ) {
      try {
        await deleteAllocation({ resourceId: allocation.resourceId, resourceUsageId: allocation.id }).unwrap();
        showSuccessNotification();
      } catch (error) {
        const errors = generateBackendErrorMessages(error);
        for (const message of errors) {
          showErrorNotification(message);
        }
      }
    }
  };

  const { canUpdateAllocation, canRemoveAllocation } = allocation.permissions;

  return (
    <>
      <IconButton
        sx={styles.moreHorizButton}
        id={`actions-button-${allocation.id}`}
        aria-controls={isAllocationActionsMenuOpen ? `actions-menu-${allocation.id}` : undefined}
        aria-expanded={isAllocationActionsMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleAllocationActionsClick}
        disabled={isDeleteAllocationLoading}
      >
        <MoreHorizOutlined sx={styles.moreHorizIcon} />
      </IconButton>

      <Menu
        id={`actions-menu-${allocation.id}`}
        anchorEl={allocationActionsMenu}
        open={isAllocationActionsMenuOpen}
        onClose={handleAllocationActionsClose}
        MenuListProps={{
          'aria-labelledby': `actions-button-${allocation.id}`,
        }}
      >
        <Tooltip title={t('notAvailable')} disableHoverListener={canUpdateAllocation}>
          <span>
            <MenuItem onClick={handleAllocationUpdateModalOpen} divider disabled={!canUpdateAllocation}>
              <Typography variant="h6">{t('edit')}</Typography>
            </MenuItem>
            <MenuItem
              onClick={handleAllocationRemoveModalOpen}
              sx={styles.removeAction}
              disabled={!canRemoveAllocation}
            >
              <Typography variant="h6">{t('remove')}</Typography>
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
    </>
  );
};

export default AllocationActions;
