import * as yup from 'yup';

import i18n from 'locales/i18n';

import { ITag } from '../types';

const requiredValidation = 'forms:validation:required';

export interface ICreateTagFormData {
  name: string;
}

export interface ICreateTagFormDataToSubmit {
  name: string;
}

export interface IUpdateTagFormData {
  name: string;
}

export interface IUpdateTagFormDataToSubmit {
  id: number;
  formData: IUpdateTagFormData;
}

export const buildValidationFields = () => ({
  name: yup
    .string()
    .max(300, i18n.t('forms:validation:maxSymbols', { count: 300 }))
    .required(i18n.t(requiredValidation))
    .default(''),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (tag?: ITag): { name: string } => (tag ? { name: tag.name } : { name: '' });

export const formatAttributesToUpdateSubmit = (
  id: number,
  formData: IUpdateTagFormData,
): IUpdateTagFormDataToSubmit => {
  return {
    id,
    formData,
  };
};

export const formatAttributesToCreateSubmit = (values: ICreateTagFormData): ICreateTagFormDataToSubmit => ({
  name: values.name,
});
