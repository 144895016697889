import { FC, useState } from 'react';

import { Alert } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Loader from 'components/common/Loader';
import MultipleAutocomplete from 'components/common/SmartFilter/components/MultipleAutocomplete';
import { IMultipleSlot } from 'components/common/SmartFilter/types';

import { useGetTagsQuery } from 'domain/tag/apiSlice';
import { ITag } from 'domain/tag/types';

import { IAutocompleteOption } from 'types/autocomplete';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { getSortParameter } from 'utils/runsack';
import { getPreparedDataForSuggestionAutocomplete } from 'utils/suggestionAutocomplete';

import { ITagsProps } from './types';

const Tags: FC<ITagsProps> = props => {
  const { slot, onSlotPatch, setOpenPopover } = props;

  const { t } = useTranslation(['common', 'smartFilter']);

  const value = slot.value as Array<IMultipleSlot>;

  const [tagName, setTagName] = useState<string>('');

  const [debouncedTagName] = useDebounce(tagName, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: tagsData,
    isFetching: isTagsDataFetching,
    isLoading: isTagsDataLoading,
    isError: isTagsDataLoadingError,
  } = useGetTagsQuery({
    nameCont: debouncedTagName,
    paginated: false,
    sort: getSortParameter('name', 'asc'),
  });

  if (isTagsDataLoading) {
    return <Loader />;
  }

  if (isTagsDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCurrentTagsChange = (tags: Array<IAutocompleteOption>, filterWithEnter = false) => {
    if (_.isEmpty(tags)) {
      onSlotPatch(
        slot.id,
        {
          value: [],
        },
        filterWithEnter,
      );
      return;
    }

    const value = tags.map(tag => ({ id: tag.id, name: tag.name }));

    onSlotPatch(
      slot.id,
      {
        value,
      },
      filterWithEnter,
    );
  };

  const options = getPreparedDataForSuggestionAutocomplete<ITag>({ data: tagsData.tags });
  const currentOptions = options.filter(option => value.some(slotValue => slotValue.id === option.id));

  return (
    <MultipleAutocomplete
      options={options}
      currentOptions={currentOptions}
      isFetching={isTagsDataFetching}
      onCurrentOptionsChange={handleCurrentTagsChange}
      setName={setTagName}
      name={tagName}
      label={t('smartFilter:tags.label')}
      setOpenPopover={setOpenPopover}
    />
  );
};

export default Tags;
