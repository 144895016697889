import { FC, useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import _ from 'lodash';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import TechnologiesAutocomplete from 'components/common/TechnologiesAutocomplete';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateResourceUsageMutation } from 'domain/resource/usage/apiSlice';
import {
  initialValues,
  buildValidationSchema,
  formatAttributesToSubmit,
  IUpdateResourceUsageFormData,
} from 'domain/resource/usage/schemas/update';
import { useGetTechnologiesQuery } from 'domain/technology/apiSlice';
import { ITechnology } from 'domain/technology/types';

import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';
import { getSortParameter } from 'utils/runsack';

import styles from './styles';
import { IUpdateResourceUsageTechnologiesFormProps } from './types';

const UpdateResourceUsageTechnologiesForm: FC<IUpdateResourceUsageTechnologiesFormProps> = props => {
  const { projectId, resourceUsage } = props;

  const { t } = useTranslation(['common', 'resourceUsageActions']);
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const { closeModal } = useContext(ModalContext);

  const [technologyName, setTechnologyName] = useState<string>('');

  const [debouncedTechnologyName] = useDebounce(technologyName, DEFAULT_DEBOUNCE_DELAY_MS);

  const [updateResourceUsage, { isLoading: isUpdateResourceUsageLoading }] = useUpdateResourceUsageMutation();

  const {
    data,
    isLoading: isTechnologiesLoading,
    isFetching: isTehcnologiesFetching,
  } = useGetTechnologiesQuery({
    nameCont: debouncedTechnologyName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IUpdateResourceUsageFormData>({
    defaultValues: initialValues(resourceUsage),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: technologies, onChange: onTechnologiesChange },
  } = useController({ name: 'technologies', control });

  const onSubmit = async (formData: IUpdateResourceUsageFormData) => {
    try {
      await updateResourceUsage(
        formatAttributesToSubmit(resourceUsage.resourceId, resourceUsage.id, projectId, formData),
      ).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const handleCurrentTechnologiesChange = (technologies: Array<ITechnology>) => {
    if (_.isEmpty(technologies)) {
      onTechnologiesChange([]);
      return;
    }
    onTechnologiesChange(technologies);
  };

  if (isTechnologiesLoading) {
    return <Loader />;
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.technologiesAutocomplete}>
        <TechnologiesAutocomplete
          technologies={data.technologies}
          currentTechnologies={technologies}
          onCurrentTechnologiesChange={handleCurrentTechnologiesChange}
          setTechnologyName={setTechnologyName}
          isError={Boolean(errors.technologies)}
          isTechnologiesFetching={isTehcnologiesFetching}
          label={t('resourceUsageActions:editResourceUsageTechnologiesForm.technologies.label')}
        />
      </Div>

      <Div>
        <Button type="submit" disabled={isUpdateResourceUsageLoading} sx={styles.saveButton}>
          {t('resourceUsageActions:editResourceUsageTechnologiesForm.buttons.save')}
        </Button>
        <Button type="reset" variant="outlined" onClick={closeModal} disabled={isUpdateResourceUsageLoading}>
          {t('resourceUsageActions:editResourceUsageTechnologiesForm.buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default UpdateResourceUsageTechnologiesForm;
