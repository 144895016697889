import { FC, useState } from 'react';

import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FileExtension } from 'enums/File';

import { generateButtonTitle } from './service';
import styles from './styles';
import { IExportButton } from './types';

const ExportButton: FC<IExportButton> = props => {
  const { isDisabled, onCsvDownload, onXlsxDownload } = props;

  const { t } = useTranslation('exportButton');

  const [exportMenuElement, setExportMenuElement] = useState<null | HTMLElement>(null);

  const isExportMenuOpen = Boolean(exportMenuElement);

  const handleExportMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportMenuElement(event.currentTarget);
  };
  const handleExportMenuClose = () => {
    setExportMenuElement(null);
  };

  const handleCsvDownload = () => {
    onCsvDownload();
    handleExportMenuClose();
  };

  const handleXlsxDownload = () => {
    onXlsxDownload();
    handleExportMenuClose();
  };

  const downloadCsvButtonTitle = generateButtonTitle(FileExtension.csv);
  const downloadXlsxButtonTitle = generateButtonTitle(FileExtension.xlsx);

  return (
    <>
      <Button
        id="export-button"
        aria-controls={isExportMenuOpen ? 'export-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isExportMenuOpen ? 'true' : undefined}
        onClick={handleExportMenuClick}
        sx={styles.exportButton}
        variant="outlined"
        disabled={isDisabled}
      >
        {t('exportButton.title')}
      </Button>
      <Menu
        id="export-menu"
        anchorEl={exportMenuElement}
        open={isExportMenuOpen}
        onClose={handleExportMenuClose}
        MenuListProps={{
          'aria-labelledby': 'export-button',
        }}
      >
        <MenuItem divider onClick={handleCsvDownload} disableRipple>
          <Typography variant="subtitle2">{downloadCsvButtonTitle}</Typography>
        </MenuItem>
        <MenuItem divider onClick={handleXlsxDownload} disableRipple>
          <Typography variant="subtitle2">{downloadXlsxButtonTitle}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExportButton;
