import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    paddingTop: 2,
  },
  tabHeadersContainer: {
    borderBottom: '1px solid',
    borderColor: 'border.lightGray',
    marginBottom: 2,
  },
};

export default styles;
