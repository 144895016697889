import { FC, useContext } from 'react';

import { Alert, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { ModalContext } from 'contexts/ModalContext';
import SkillLevelAccessProvider from 'contexts/SkillLevelAccessContext';

import { useDeleteResourceAbilityMutation } from 'domain/resource/ability/apiSlice';
import { useGetUserProfileStackQuery } from 'domain/user/profile/stack/apiSlice';

import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useNotifications } from 'hooks/useNotifications';

import { getFullName } from 'utils/person';

import CreateResourceAbilityButton from './components/CreateResourceAbilityButton';
import CreateResourceAbilityForm from './components/CreateResourceAbilityForm';
import UpdateResourceAbilityForm from './components/UpdateResourceAbilityForm';
import UserSpecializationTree from './components/UserSpecializationTree';
import { INormalizedTree } from './components/UserSpecializationTree/types';
import styles from './styles';
import { ISpecializationProps } from './types';
import { prepareUserProfileStackAbilityForUpdate } from './utils';

const Specialization: FC<ISpecializationProps> = props => {
  const { user } = props;

  const { t } = useTranslation('userProfile');

  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const [deleteResourceAbility, { isLoading: isDeleteResourceAbilityLoading }] = useDeleteResourceAbilityMutation();

  const {
    data: userProfileStackData,
    isLoading: isUserProfileStackDataLoading,
    isError: isUserProfileStackDataLoadingError,
  } = useGetUserProfileStackQuery({ userId: user.id });

  const { openModal } = useContext(ModalContext);

  const { getConfirmation } = useConfirmDialog();

  const handleCreateUserSpecializationModalOpen = () => {
    openModal({
      title: t('specializationAndStack.addModalTitle'),
      content: <CreateResourceAbilityForm resourceId={user.resource.id} />,
    });
  };

  const userFullName = getFullName(user);

  const { canAddTechnologyOrStack, canUpdateSkillLevel } = user.permissions;

  const handleUpdateUserSpecializationModalOpen = (userProfileStackAbility: INormalizedTree) => {
    openModal({
      title: t('specializationAndStack.changeSkill'),
      subtitle: (
        <Typography variant="subtitle1" component="span">
          <Trans
            t={t}
            i18nKey="specializationAndStack.updateSkillModalSubtitle"
            values={{ userFullName, userProfileStackAbility: userProfileStackAbility.technologyName }}
            components={{
              1: <Typography variant="heading3" component="span" />,
              2: <Typography variant="heading3" component="span" />,
            }}
          ></Trans>
        </Typography>
      ),
      content: (
        <UpdateResourceAbilityForm
          resourceId={user.resource.id}
          userProfileStackAbility={prepareUserProfileStackAbilityForUpdate(userProfileStackAbility, user)}
        />
      ),
    });
  };

  const handleDeleteUserSpecialization = async (userProfileStackAbility: INormalizedTree) => {
    if (
      await getConfirmation({
        title: t('specializationAndStack.deleteModalTitle'),
        message: (
          <Typography variant="subtitle1" component="span">
            <Trans
              t={t}
              i18nKey="specializationAndStack.deleteModalText"
              values={{ userFullName, userProfileStackAbility: userProfileStackAbility.technologyName }}
              components={{
                1: <Typography variant="heading3" component="span" />,
                2: <Typography variant="heading3" component="span" />,
              }}
            ></Trans>
          </Typography>
        ),
      })
    ) {
      try {
        await deleteResourceAbility({ id: userProfileStackAbility.id, resourceId: user.resource.id }).unwrap();
        showSuccessNotification();
      } catch {
        showErrorNotification();
      }
    }
  };

  if (isUserProfileStackDataLoading) {
    return <Loader />;
  }

  if (isUserProfileStackDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const isDisabled = isDeleteResourceAbilityLoading;

  return (
    <Div sx={styles.root}>
      <Div sx={styles.resourceAbility}>
        <SkillLevelAccessProvider canUpdateSkillLevel={canUpdateSkillLevel}>
          <UserSpecializationTree
            data={userProfileStackData.abilities}
            onCreate={handleCreateUserSpecializationModalOpen}
            onUpdate={handleUpdateUserSpecializationModalOpen}
            onDelete={handleDeleteUserSpecialization}
            isLoading={isDisabled}
          />
        </SkillLevelAccessProvider>
        {canAddTechnologyOrStack && (
          <CreateResourceAbilityButton
            buttonLabel={t('specializationAndStack.addButton')}
            onAddNodeButtonClick={handleCreateUserSpecializationModalOpen}
          />
        )}
      </Div>
    </Div>
  );
};

export default Specialization;
