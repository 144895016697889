import { FC } from 'react';

import { Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import Budget from './components/Budget';
import SelectBudgetTypeMenu from './components/SelectBudgetTypeMenu';
import styles from './styles';
import { IHoursInfoBlockProps } from './types';

const HoursInfoBlock: FC<IHoursInfoBlockProps> = props => {
  const { hoursBlock, setBudgetType, project, budgetType, canUpdateBudgets } = props;

  const { t } = useTranslation('reportHoursByProject');

  return (
    <Div
      key={hoursBlock.title}
      sx={MUISx(styles.root, {
        condition: hoursBlock.isBudgetBlock,
        sx: styles.budgetBlock,
      })}
    >
      <Div sx={styles.hoursIconBlock}>
        {hoursBlock.isEmoji ? (
          <Typography sx={styles.chairEmoji}>{hoursBlock.icon}</Typography>
        ) : (
          <Icon name={hoursBlock.icon} sx={styles.hoursIcon} />
        )}
      </Div>
      <Div sx={styles.hoursBlock}>
        <Typography variant="subtitle2" sx={styles.hoursBlockTitle}>
          {hoursBlock.title}
        </Typography>
        {hoursBlock.isBudgetBlock ? (
          <Budget
            hours={hoursBlock.hours}
            budgetType={budgetType}
            project={project}
            totalBudgetId={project.totalBudgetId}
            monthlyBudgetId={project.monthlyBudgetId}
            canUpdateBudgets={canUpdateBudgets}
          />
        ) : (
          <Typography variant="body1">{t('info.hours', { count: hoursBlock.hours })}</Typography>
        )}
      </Div>
      {hoursBlock.isBudgetBlock && <SelectBudgetTypeMenu setBudgetType={setBudgetType} />}
    </Div>
  );
};

export default HoursInfoBlock;
