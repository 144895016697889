import { FC, useState } from 'react';

import { Alert } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Loader from 'components/common/Loader';
import MultipleAutocomplete from 'components/common/SmartFilter/components/MultipleAutocomplete';
import { IMultipleSlot } from 'components/common/SmartFilter/types';

import { useGetProjectsQuery } from 'domain/project/apiSlice';
import { IProject } from 'domain/project/types';

import { IAutocompleteOption } from 'types/autocomplete';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { getSortParameter } from 'utils/runsack';
import { getPreparedDataForSuggestionAutocomplete } from 'utils/suggestionAutocomplete';

import { IProjectsProps } from './types';

const Projects: FC<IProjectsProps> = props => {
  const { slot, onSlotPatch, setOpenPopover } = props;

  const { t } = useTranslation(['common', 'smartFilter']);

  const value = slot.value as Array<IMultipleSlot>;

  const [projectName, setProjectName] = useState<string>('');

  const [debouncedProjectName] = useDebounce(projectName, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: projectsData,
    isFetching: isProjectsDataFetching,
    isLoading: isProjectsDataLoading,
    isError: isProjectsDataLoadingError,
  } = useGetProjectsQuery({
    nameCont: debouncedProjectName,
    paginated: false,
    sort: getSortParameter('name', 'asc'),
  });

  if (isProjectsDataLoading) {
    return <Loader />;
  }

  if (isProjectsDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCurrentProjectsChange = (projects: Array<IAutocompleteOption>, filterWithEnter = false) => {
    if (_.isEmpty(projects)) {
      onSlotPatch(
        slot.id,
        {
          value: [],
        },
        filterWithEnter,
      );
      return;
    }

    const value = projects.map(project => ({ id: project.id, name: project.name }));

    onSlotPatch(
      slot.id,
      {
        value,
      },
      filterWithEnter,
    );
  };

  const options = getPreparedDataForSuggestionAutocomplete<IProject>({ data: projectsData.projects });
  const currentOptions = options.filter(option => value.some(({ id }) => id === option.id));

  return (
    <MultipleAutocomplete
      options={options}
      isFetching={isProjectsDataFetching}
      onCurrentOptionsChange={handleCurrentProjectsChange}
      setName={setProjectName}
      name={projectName}
      label={t('smartFilter:projects.label')}
      currentOptions={currentOptions}
      setOpenPopover={setOpenPopover}
    />
  );
};

export default Projects;
