import { FC, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateProjectTaskMutation } from 'domain/project/task/apiSlice';
import {
  initialValues,
  IUpdateProjectTaskFormData,
  buildValidationSchema,
  formatAttributesToSubmit,
} from 'domain/project/task/schemas/update';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { IUpdateProjectTaskFormProps } from './types';

const UpdateProjectTaskForm: FC<IUpdateProjectTaskFormProps> = props => {
  const { projectId, task } = props;

  const { t } = useTranslation('reportHoursByProject');

  const [updateProjectTask, { isLoading: isUpdateProjectTaskLoading }] = useUpdateProjectTaskMutation();

  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const { closeModal } = useContext(ModalContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateProjectTaskFormData>({
    defaultValues: initialValues(task),
    resolver: yupResolver(buildValidationSchema()),
  });

  const onSubmit = async (formData: IUpdateProjectTaskFormData) => {
    try {
      await updateProjectTask(formatAttributesToSubmit(task.id, projectId, formData)).unwrap();

      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.nameField}>
        <TextField
          sx={styles.name}
          {...register('name')}
          error={Boolean(errors.name)}
          fullWidth
          inputProps={{ maxLength: 100 }}
          label={t('projectTasks.task.updateProjectTaskForm.fields.name.label')}
          InputLabelProps={{ sx: styles.placeholder }}
          helperText={buildErrorMessage(t, errors.name)}
        />
      </Div>

      <Div>
        <Button sx={styles.saveButton} type="submit" disabled={isUpdateProjectTaskLoading}>
          {t('projectTasks.task.updateProjectTaskForm.buttons.save')}
        </Button>
        <Button onClick={closeModal} type="button" variant="outlined" disabled={isUpdateProjectTaskLoading}>
          {t('projectTasks.task.updateProjectTaskForm.buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default UpdateProjectTaskForm;
