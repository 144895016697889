import palette from 'theme/baseTheme/palette';

import { IMuiLinkType } from './types';

const MuiLink: IMuiLinkType = {
  defaultProps: {
    underline: 'none',
  },
  styleOverrides: {
    root: {
      color: palette.primary.contrastText,
    },
  },
};

export default MuiLink;
