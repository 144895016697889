import palette from 'theme/baseTheme/palette';

import { IMuiButtonType } from './types';

const MuiButton: IMuiButtonType = {
  defaultProps: {
    color: 'primary',
    variant: 'contained',
    disableElevation: true,
  },

  styleOverrides: {
    root: {
      minWidth: 0,
      textTransform: 'none',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.005em',
    },
    contained: {
      backgroundColor: palette.button.main,
      color: palette.primary.contrastText,
    },
    text: {
      color: palette.button.main,
    },
    outlined: {
      backgroundColor: palette.background.white,
    },
  },
};

export default MuiButton;
