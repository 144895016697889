import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IProjectBudgetMonth } from 'domain/project/budget/month/types';

import { maxTwoDigitsAfterDecimalRegExp } from 'utils/regexp';

export interface IUpdateProjectMonthBudgetFormData {
  budget: number;
}

export interface IProjectBudgetMonthHours {
  budgetedHours: number;
}

export interface IPreparedUpdateProjectMonthBudgetFormData {
  budgetMonth: IProjectBudgetMonthHours;
}

export interface IUpdateProjectMonthBudgetFormDataToSubmit {
  formData: IPreparedUpdateProjectMonthBudgetFormData;
  budgetMonthId: number;
  projectId: number;
}

const requiredValidation = 'forms:validation:required';

export const buildValidationFields = () => ({
  budget: yup
    .number()
    .moreThan(-1, i18n.t('forms:validation:positiveNumber'))
    .typeError(i18n.t('forms:validation:mustBeNumber'))
    .test(
      i18n.t('forms:validation:maxDigitsAfterDecimalTest'),
      i18n.t('forms:validation:maxTwoDigitsAfterDecimal'),
      number => maxTwoDigitsAfterDecimalRegExp.test(String(number)),
    )
    .required(i18n.t(requiredValidation))
    .nullable()
    .default(null),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (budget: IProjectBudgetMonth) => {
  const { budgetedHours } = budget;
  return {
    budget: budgetedHours,
  };
};

export const formatAttributesToSubmit = (
  projectId: number,
  budgetMonthId: number,
  formData: IUpdateProjectMonthBudgetFormData,
): IUpdateProjectMonthBudgetFormDataToSubmit => {
  const { budget } = formData;

  return {
    projectId,
    budgetMonthId,
    formData: {
      budgetMonth: {
        budgetedHours: budget,
      },
    },
  };
};
