import { FC } from 'react';

import { TableRow, TableCell } from '@mui/material';

import styles from './styles';
import { IMigrationsTableBodyProps } from './types';

const MigrationsTableBody: FC<IMigrationsTableBodyProps> = props => {
  const { row } = props;

  return (
    <TableRow {...row.getRowProps()}>
      {row.cells.map(cell => {
        return (
          <TableCell {...cell.getCellProps()} sx={styles.cell}>
            {cell.render('Cell')}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MigrationsTableBody;
