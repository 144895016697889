export default {
  edit: 'Edit Allocation',
  remove: 'Delete',
  editAllocationModalTitle: 'Edit Allocation',
  notAvailable: 'You do not have permission to perform this action',
  updateAllocationForm: {
    period: 'Period',
    switches: {
      endDate: {
        label: 'End Date',
      },
    },
    fields: {
      startDate: {
        label: 'Start Date',
      },
      endDate: {
        label: 'End Date',
      },
    },
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },
    technologies: {
      label: 'Resource Stack',
    },
    removeAllocationMessage:
      "This action will delete <1>{{ resourceUsageFullName }}</1>'s allocation from the database",
  },
};
