import { IDuration } from 'types/dates';

import { HOURS_PER_DAY, MINUTES_PER_HOUR, zeroPad, zeroPadEnd } from './dateTime';

const isContainsDelimiter = (duration: string): IDuration => {
  return duration.includes(':') || duration.includes(' ');
};

const convertDurationWithDelimiterToObject = (duration: string): IDuration => {
  const splitCharacter = duration.includes(':') ? ':' : ' ';

  const [splitHours, splitMinutes] = duration.split(splitCharacter);

  const sanitizedHours = zeroPad(splitHours.trim());
  const sanitizedMinutes = zeroPadEnd(splitMinutes.trim());

  return { hours: sanitizedHours, minutes: sanitizedMinutes };
};

const convertDurationWithoutDelimiterToObject = (duration: string): IDuration => {
  let hours = '00';
  let minutes = '00';

  if (duration.length === 1) {
    hours = duration;
  } else if ((duration.length === 2 && duration.startsWith('0')) || duration.length === 3) {
    hours = duration.slice(0, 1);
    minutes = zeroPadEnd(duration.slice(1));
  } else if (duration.length === 2) {
    hours = duration;
  } else if (duration.length === 4) {
    hours = duration.slice(0, 2);
    minutes = duration.slice(2);
  }

  return { hours: hours, minutes: minutes };
};

const createValidDurationObject = (hours: integer, minutes: integer) => {
  if (hours >= HOURS_PER_DAY) {
    return {
      hours: HOURS_PER_DAY,
      minutes: 0,
    };
  }

  if (minutes >= MINUTES_PER_HOUR) {
    return {
      hours: hours,
      minutes: 0,
    };
  }

  return {
    hours: hours,
    minutes: minutes,
  };
};

export const formatDurationFromNumberToObject = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / MINUTES_PER_HOUR);
  const minutes = totalMinutes % MINUTES_PER_HOUR;

  return { hours, minutes };
};

export const parseDurationFromStringToObject = (duration: string | undefined): IDuration => {
  if (!duration || (duration.length > 4 && !isContainsDelimiter(duration))) {
    return { hours: 0, minutes: 0 };
  }

  let durationObject = { hours: '00', minutes: '00' };

  durationObject = isContainsDelimiter(duration)
    ? convertDurationWithDelimiterToObject(duration)
    : convertDurationWithoutDelimiterToObject(duration);

  const parsedHours = Number.parseInt(durationObject.hours);
  const parsedMinutes = Number.parseInt(durationObject.minutes);
  const formattedHours = Number.isInteger(parsedHours) ? parsedHours : 0;
  const formattedMinutes = Number.isInteger(parsedMinutes) ? parsedMinutes : 0;

  return createValidDurationObject(formattedHours, formattedMinutes);
};

export const formatDurationFromObjectToString = ({ hours, minutes }: IDuration) => {
  return `${zeroPad(hours)} : ${zeroPad(minutes)}`;
};

export const isDurationValidNumbers = (durationValue: string): boolean => {
  const { hours, minutes } = parseDurationFromStringToObject(durationValue);
  return !Number.isNaN(hours) && !Number.isNaN(minutes);
};

export const isDurationValid = (durationValue: string): boolean => {
  const { hours, minutes } = parseDurationFromStringToObject(durationValue);

  const isHoursFitToDay = hours >= 0 && hours < HOURS_PER_DAY;
  const isMinutesFitToHour = minutes >= 0 && minutes < MINUTES_PER_HOUR;
  const isMatchNormal = isHoursFitToDay && isMinutesFitToHour;
  const isEqualTo24Hours = hours === HOURS_PER_DAY && minutes === 0;

  return isMatchNormal || isEqualTo24Hours;
};
