import { SxStyles } from 'theme';

const styles: SxStyles = {
  legend: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    py: 2,
  },
  legendItem: theme => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.text.blackLight,
  }),
  colorItem: {
    width: 12,
    height: 12,
  },
  approved: {
    bgcolor: 'background.mint',
  },
  onApproval: {
    bgcolor: 'background.orange',
  },
  declined: {
    bgcolor: 'background.red',
  },
};

export default styles;
