import { SxStyles } from 'theme';

const styles: SxStyles = {
  autoComplete: {
    width: '250px',
    overflow: 'hidden',
  },
};

export default styles;
