import { forwardRef } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import dictionary from './dictionary';
import { IIconProps } from './types';

const Icon = forwardRef<SVGSVGElement, IIconProps & SvgIconProps>((props, reference) => {
  const { name } = props;
  const { viewBox, width, height, id, styles } = dictionary[name];
  return (
    <SvgIcon sx={styles} viewBox={viewBox} width={width} height={height} ref={reference} {...props}>
      <use xlinkHref={id} />
    </SvgIcon>
  );
});

export default Icon;
