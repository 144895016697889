import _ from 'lodash';

import { ConditionState } from 'components/common/SmartFilter/enums';

import { IReportHoursByProjectsProject } from 'domain/report/hoursByProject/types';

import { Trackables } from 'enums/Trackables';

import { ITimeTrackerTrackable } from './types';

export const isProjectType = (project: IReportHoursByProjectsProject | ITimeTrackerTrackable | undefined) => {
  if (!project) {
    return false;
  }
  return project.type == Trackables.project;
};

export const canUpdateBudgets = (project: IReportHoursByProjectsProject) => {
  return isProjectType(project) ? project?.permissions.canUpdateBudgets : false;
};

export const canAddResources = (project: IReportHoursByProjectsProject) => {
  return project?.permissions.canAddResources;
};

export const hasTasks = (value: ITimeTrackerTrackable) =>
  !_.isEmpty(value.tasks?.filter(task => task.state === ConditionState.active));

export const generateProjectTaskMenuId = (value: ITimeTrackerTrackable) => `project-tasks-menu-${value.id}`;

export const generateProjectTaskOptionId = (value: ITimeTrackerTrackable) => `${value.type}-tasks-option-${value.id}`;
