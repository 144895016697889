import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    paddingTop: 1.25,
  },
  input: {
    minWidth: '400px',
    width: '100%',
    marginBottom: 4,
  },
  placeholder: {
    color: 'text.gray',
  },
  saveBudgetButton: {
    marginRight: 1.25,
  },
};

export default styles;
