import React, { FC, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { ICreateTagFormData, IUpdateTagFormData, initialValues, buildValidationSchema } from 'domain/tag/schemas/form';

import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { ITagFormProps } from './types';

const TagForm: FC<ITagFormProps> = props => {
  const { onSubmit, disabled, submitButtonLabel, tag } = props;

  const { t } = useTranslation('adminPanel');

  const { closeModal } = useContext(ModalContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateTagFormData | ICreateTagFormData>({
    defaultValues: initialValues(tag),
    resolver: yupResolver(buildValidationSchema()),
  });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.input}>
        <TextField
          variant="outlined"
          error={Boolean(errors.name)}
          fullWidth
          label={t('tags.tagForm.name.label')}
          {...register('name')}
          disabled={disabled}
          helperText={buildErrorMessage(t, errors.name)}
        />
      </Div>
      <Div sx={styles.buttonsContainer}>
        <Button type="submit" variant="contained" disabled={disabled}>
          {submitButtonLabel}
        </Button>
        <Button variant="outlined" onClick={closeModal} disabled={disabled}>
          {t('buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default TagForm;
