import _ from 'lodash';

import { IReportTrackedTimeEntry, IReportTrackedTimeEntryUser } from './types';

export interface IResourceTrackedTimeEntriesGroupedByUser {
  [name: string]: { id: number; timeEntries: Array<IReportTrackedTimeEntry> };
}

export interface IResourceTrackedTimeEntriesGroupedByProject {
  [name: string]: { timeEntries: Array<IReportTrackedTimeEntry> };
}

export const getReportTrackedTimeEntriesGroupedByUser = (
  reportTrackedTimeEntries: Array<IReportTrackedTimeEntryUser>,
): IResourceTrackedTimeEntriesGroupedByUser => {
  const entriesGroupedByUser: IResourceTrackedTimeEntriesGroupedByUser = {};
  for (const user of reportTrackedTimeEntries) {
    const { fullName, timeEntries, id } = user;
    entriesGroupedByUser[fullName] = { id, timeEntries };
  }
  return entriesGroupedByUser;
};

export const getReportTrackedTimeEntriesGroupedByProject = (
  reportTrackedTimeEntries: Array<IReportTrackedTimeEntry>,
): IResourceTrackedTimeEntriesGroupedByProject => {
  const flattenReports = reportTrackedTimeEntries.flatMap(element => Object.values(element)).flat();

  const entriesGroupedByProject = _.mapValues(_.groupBy(flattenReports, 'trackableName'), timeEntries => ({
    timeEntries,
  }));

  if (entriesGroupedByProject['undefined']) {
    delete entriesGroupedByProject['undefined'];
  }

  return entriesGroupedByProject;
};

export const getReportTotalTrackedTimeEntriesGroupDuration = (
  reportTrackedTimeEntries: Array<IReportTrackedTimeEntry>,
): number =>
  reportTrackedTimeEntries.reduce((total, reportTrackedTimeEntry) => (total += reportTrackedTimeEntry?.duration), 0);

export const sortByDate = <T>(collection: T[]) => _.sortBy(collection, entry => _.get(entry, ['date']));
