import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { ITechnology } from 'domain/technology/types';
import { IVacancy } from 'domain/vacancy/types';

import { formatToDateWithFixedTimeZone, convertDateToUTCString } from 'utils/dateTime';

export interface IUpdateVacancyFormData {
  fte?: number;
  projectId?: number;
  groupId?: number;
  technologies?: Array<ITechnology>;
  startDate: Date | null;
  endDate?: Date | null;
}

export interface IUpdateVacancyPreparedFormDataToSubmit
  extends Omit<IUpdateVacancyFormData, 'technologies' | 'startDate' | 'endDate'> {
  technologyIds: Array<number>;
  startDate: string;
  endDate: string | null;
}

export interface IUpdateVacancyFormDataToSubmit {
  formData: IUpdateVacancyPreparedFormDataToSubmit;
  id: number;
}

const requiredValidation = 'forms:validation:required';

export const buildValidationFields = () => ({
  fte: yup.number().min(0).max(100).required(i18n.t(requiredValidation)).nullable().default(null),
  startDate: yup.date().required(i18n.t(requiredValidation)).nullable().default(new Date()),
  endDate: yup
    .date()
    .min(yup.ref('startDate'), i18n.t('forms:validation:startDateBeforeEndDate'))
    .nullable()
    .default(null),

  technologies: yup
    .array()
    .of(yup.mixed<ITechnology>())
    .min(1, i18n.t('forms:validation:minItemsInArray', { count: 1 }))
    .required(i18n.t(requiredValidation))
    .default([]),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (vacancy: IVacancy) => {
  if (_.isNil(vacancy)) {
    return buildValidationSchema().getDefault();
  }

  const { fte, startDate, endDate, technologies } = vacancy;

  return {
    fte,
    startDate: formatToDateWithFixedTimeZone(startDate),
    endDate: _.isNil(endDate) ? null : formatToDateWithFixedTimeZone(endDate),
    technologies,
  };
};

export const formatAttributesToSubmit = (
  projectId: number,
  groupId: number,
  vacancyId: number,
  formData: IUpdateVacancyFormData,
): IUpdateVacancyFormDataToSubmit => {
  const { technologies, startDate, endDate, ...values } = formData;

  const technologyIds = technologies?.map(({ id }) => id) ?? [];
  const normalizeStartDate = convertDateToUTCString(startDate);
  const normalizeEndDate = _.isNil(endDate) ? null : convertDateToUTCString(endDate);

  return {
    id: vacancyId,
    formData: {
      technologyIds,
      startDate: normalizeStartDate,
      endDate: normalizeEndDate,
      projectId,
      groupId,
      ...values,
    },
  };
};
