import theme, { SxStyles } from 'theme';

const styles: SxStyles = {
  header: {
    height: 130,
  },
  firstCell: {
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderBottom: `3px solid ${theme.palette.border.main}`,
    width: 250,
    minWidth: 250,
    bgcolor: theme.palette.common.white,
    textAlign: 'center',
    verticalAlign: 'middle',
    zIndex: theme.zIndex.appBar,
  },
  cell: {
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderBottom: `3px solid ${theme.palette.border.main}`,
    textAlign: 'center',
    width: 35,
    minWidth: 35,
    zIndex: theme.zIndex.appBar,
  },
  lastCell: {
    borderRightWidth: 3,
  },
  title: {
    color: 'text.gray',
  },
  verticalTitle: {
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    color: 'text.gray',
    margin: '0 auto',
  },
};

export default styles;
