import { FC } from 'react';

import { Button, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { useCreateResourceVacationMutation } from 'domain/resource/vacation/apiSlice';
import { formatAttributesToSubmit } from 'domain/resource/vacation/schemas/create';

import { useNotifications } from 'hooks/useNotifications';

import { stringToDate, toVacationRange } from 'utils/calendar';
import { generateBackendErrorMessages } from 'utils/error';

import styles from './styles';
import { INewVacationProps } from './types';

const Vacation: FC<INewVacationProps> = props => {
  const { startSelection, endSelection, onClear, resourceId } = props;
  const { t } = useTranslation('userProfile');
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const startDay = stringToDate(startSelection);
  const endDay = stringToDate(endSelection);
  const range = t('vacation.newVacationRange', { start: toVacationRange(startDay), end: toVacationRange(endDay) });

  const [createResourceVacation, { isLoading: isCreateResourceVacationLoading }] = useCreateResourceVacationMutation();

  const onSubmit = async () => {
    try {
      await createResourceVacation(
        formatAttributesToSubmit(resourceId, { startDate: startSelection, endDate: endSelection }),
      ).unwrap();
      onClear();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  return (
    <Div sx={styles.root}>
      <Div sx={styles.range}>
        <Typography>{range}</Typography>
        <Button onClick={onClear} sx={styles.clearButton}>
          <Icon sx={styles.crossIcon} name="cross" />
        </Button>
      </Div>

      <Tooltip title={t('tooltips.sendRequest')} arrow>
        <Button disabled={isCreateResourceVacationLoading} onClick={onSubmit} sx={styles.applyButton}>
          <Icon sx={styles.applyIcon} name="apply" />
        </Button>
      </Tooltip>
    </Div>
  );
};

export default Vacation;
