import { FC } from 'react';

import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import MUISx from 'mui-sx';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { MONTHS } from 'utils/dateTime';

import styles from './styles';
import { ISingleMonthPickerProps } from './types';

const SingleMonthPicker: FC<ISingleMonthPickerProps> = props => {
  const { selectedYear, setSelectedYear, selectedMonthNumber, setSelectedMonthNumber, label } = props;

  return (
    <Div>
      <Div sx={styles.yearChangeSlider}>
        <Typography>{`${label}:`}</Typography>
        <IconButton sx={styles.yearButton} onClick={() => setSelectedYear(selectedYear - 1)}>
          <KeyboardArrowLeftOutlined />
        </IconButton>
        <Div sx={styles.yearChangeSliderPlaceholder}>
          <Div sx={styles.calendarIconBlock}>
            <Icon name="calendar" />
          </Div>
          <Typography variant="body1">{selectedYear}</Typography>
        </Div>
        <IconButton sx={styles.yearButton} onClick={() => setSelectedYear(selectedYear + 1)}>
          <KeyboardArrowRightOutlined />
        </IconButton>
      </Div>

      <Div sx={styles.monthPickerRoot}>
        {MONTHS.map(month => {
          const isSelectedMonth = month.id === selectedMonthNumber;

          return (
            <Button
              variant="outlined"
              sx={MUISx(styles.month, { condition: isSelectedMonth, sx: styles.selectedMonth })}
              key={month.id}
              onClick={() => setSelectedMonthNumber(month.id)}
            >
              <Typography variant="subtitle2">{month.title}</Typography>
            </Button>
          );
        })}
      </Div>
    </Div>
  );
};

export default SingleMonthPicker;
