import { FC, useState } from 'react';

import { Alert } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Loader from 'components/common/Loader';
import MultipleAutocomplete from 'components/common/SmartFilter/components/MultipleAutocomplete';
import { IMultipleSlot } from 'components/common/SmartFilter/types';

import { useGetUsersQuery } from 'domain/user/apiSlice';

import { SuggestionAutocompleteType } from 'enums/SuggestionAutocomplete';

import { IAutocompleteOption } from 'types/autocomplete';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { getSortParameter } from 'utils/runsack';
import { getPreparedDataForSuggestionAutocomplete } from 'utils/suggestionAutocomplete';

import { IUsersProps } from './types';

const Users: FC<IUsersProps> = props => {
  const { slot, onSlotPatch, setOpenPopover } = props;

  const { t } = useTranslation(['common', 'smartFilter']);

  const value = slot.value as Array<IMultipleSlot>;

  const [userName, setUserName] = useState<string>('');

  const [debouncedUserName] = useDebounce(userName, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: usersData,
    isLoading: isUsersDataLoading,
    isFetching: isUsersDataFetching,
    isError: isUsersDataLoadingError,
  } = useGetUsersQuery({
    nameOrEmailCont: debouncedUserName,
    paginated: false,
    sort: getSortParameter('name', 'asc'),
  });

  if (isUsersDataLoading) {
    return <Loader />;
  }

  if (isUsersDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCurrentUsersChange = (users: Array<IAutocompleteOption>, filterWithEnter = false) => {
    if (_.isEmpty(users)) {
      onSlotPatch(
        slot.id,
        {
          value: [],
        },
        filterWithEnter,
      );
      return;
    }

    const value = users.map(user => ({ id: user.id, name: user.name, state: user.state }));

    onSlotPatch(
      slot.id,
      {
        value,
      },
      filterWithEnter,
    );
  };

  const options = getPreparedDataForSuggestionAutocomplete({
    data: usersData.users,
    type: SuggestionAutocompleteType.person,
  });
  const currentOptions = getPreparedDataForSuggestionAutocomplete<IMultipleSlot>({
    data: value,
    type: SuggestionAutocompleteType.person,
  });

  return (
    <MultipleAutocomplete
      options={options}
      currentOptions={currentOptions}
      isFetching={isUsersDataFetching}
      onCurrentOptionsChange={handleCurrentUsersChange}
      setName={setUserName}
      name={userName}
      label={t('smartFilter:users.label')}
      setOpenPopover={setOpenPopover}
    />
  );
};

export default Users;
