import { FC } from 'react';

import { Button, Typography, Divider, Link as MUILink } from '@mui/material';
import { useTranslation } from 'react-i18next';

import appRoutes from 'routes/appRoutes';

import Div from 'components/common/Div';

import styles from './styles';

const GoogleButton: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Button component={MUILink} sx={styles.googleButton} variant="outlined" href={appRoutes.googleOauthLogin.path()}>
        <Typography component="span" sx={styles.googleIcon} />
        {t('logInWithGoogle')}
      </Button>

      <Div sx={styles.dividerBlock}>
        <Divider sx={styles.divider} />
        <Typography sx={styles.or} variant="subtitle1">
          {t('or')}
        </Typography>
        <Divider sx={styles.divider} />
      </Div>
    </>
  );
};

export default GoogleButton;
