import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    position: 'relative',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    backgroundColor: 'background.white',
  },
  rootTable: {
    overflowX: 'auto',
  },
  table: {
    borderCollapse: 'separate',
  },
  tableLoading: {
    display: 'none',
  },
  column: {
    padding: 1,
  },
  centeredColumn: {
    textAlign: 'center',
  },
  firstColumn: {
    paddingLeft: 2.5,
  },
};

export default styles;
