import { IExport } from 'domain/export/types';

export const fileDownload = (file: IExport) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = file.export.url;

  document.body.append(downloadLink);
  downloadLink.click();
  downloadLink.remove();
};
