export enum UsersTableColumnAccessor {
  name = 'name',
  email = 'email',
  technologies = 'technologies',
  physicalLocation = 'physicalLocation',
  userType = 'userType',
  homeLocation = 'homeLocation',
  state = 'state',
  workingDaysCalendar = 'workingDaysCalendar',
  hrsWeek = 'hrsWeek',
}

export enum TabName {
  users = 'List of Users',
  resources = 'Resources Migration',
}
