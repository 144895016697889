import { FC, useContext } from 'react';

import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { useCreateForbiddenWordMutation } from 'domain/forbiddenWord/apiSlice';
import { formatAttributesToSubmit, ICreateForbiddenWordFormData } from 'domain/forbiddenWord/schemas/create';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';

const CreateForbiddenWordForm: FC = () => {
  const { t } = useTranslation('adminPanel');

  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const { closeModal } = useContext(ModalContext);

  const [createForbiddenWord, { isLoading: isCreateForbiddenWordLoading }] = useCreateForbiddenWordMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateForbiddenWordFormData>();

  const onSubmit = async (formData: ICreateForbiddenWordFormData) => {
    try {
      const parameters = formatAttributesToSubmit(formData);
      await createForbiddenWord(parameters).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.input}>
        <TextField
          variant="outlined"
          error={Boolean(errors.name)}
          fullWidth
          label={t('forbiddenWords.inputName')}
          {...register('word')}
          disabled={isCreateForbiddenWordLoading}
          helperText={buildErrorMessage(t, errors.word)}
        ></TextField>
      </Div>
      <Div sx={styles.buttonsContainer}>
        <Button type="submit" variant="contained" disabled={isCreateForbiddenWordLoading}>
          {t('forbiddenWords.addWord')}
        </Button>
        <Button variant="outlined" onClick={closeModal} disabled={isCreateForbiddenWordLoading}>
          {t('buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default CreateForbiddenWordForm;
