export const getHoursColumnAccessor = (columnTitle: string, columnIndex: number) => {
  return `${columnTitle}-${columnIndex}`;
};

export const getGeneralColumnAccessor = (columnIndex: number) => {
  return `column${columnIndex}`;
};

export const capitalizeFirstLetter = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

export const divideBySpace = (text: string): string => capitalizeFirstLetter(text).replaceAll(/([A-Z])/g, ' $1');
