import { FC, useState } from 'react';

import { PopoverProps } from '@mui/material';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import UsersSelectPopover from 'components/common/UsersSelectPopover';

import { ProjectRole } from 'domain/project/enums';

import GeneralInfoBlock from './components/lnfoBlock';
import styles from './styles';
import { IGeneralInfoBlocksProps } from './types';

const GeneralInfoBlocks: FC<IGeneralInfoBlocksProps> = props => {
  const { generalProjectInfo, project, canUpdateManagementTeam } = props;

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [currentRole, setCurrentRole] = useState<ProjectRole | null>(null);

  const handleUserSelectOpen = (role: ProjectRole) => {
    return (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(event.currentTarget);
      setCurrentRole(role);
    };
  };

  const handleUserSelectClose = () => {
    setAnchorElement(null);
  };

  const isChooseUserOpen = Boolean(anchorElement);
  const popoverProps: PopoverProps = {
    open: isChooseUserOpen,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
  };

  return (
    <Div sx={styles.root}>
      <Div sx={styles.twoPeopleIconBlock}>
        <Icon sx={styles.twoPeopleIcon} name="twoPeople" />
      </Div>
      <Div sx={styles.infoBlockWithoutIcon}>
        {generalProjectInfo.map(generalProjectInfoBlock => (
          <GeneralInfoBlock
            key={generalProjectInfoBlock.title}
            generalProjectInfoBlock={generalProjectInfoBlock}
            onClick={handleUserSelectOpen(generalProjectInfoBlock.role)}
            canUpdateManagementTeam={canUpdateManagementTeam}
          />
        ))}
      </Div>
      <UsersSelectPopover
        onClose={handleUserSelectClose}
        popoverProps={popoverProps}
        projectId={project.id}
        anchorElement={anchorElement}
        currentRole={currentRole}
      />
    </Div>
  );
};

export default GeneralInfoBlocks;
