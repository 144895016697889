import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    color: 'button.main',
  },
  iconBlock: {
    marginRight: 0.5,
    width: '24px',
    height: '24px',
  },
  text: {
    textTransform: 'uppercase',
    color: 'button.main',
  },
  leftArrowIcon: {
    marginRight: 1.5,
  },
  rightArrowIcon: {
    marginLeft: 1.5,
  },
};

export default styles;
