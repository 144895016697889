import { TagTypes } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { ISortParameter } from 'types/runsack';

import { IHolidayCalendarYearDetail, IHolidayCalendarYearShort } from './types';

interface IGetHolidayCalendarsYearsQueryParameters {
  yearEq?: number;
}

interface IGetHolidayCalendarsYearsPlainParameters extends IGetHolidayCalendarsYearsQueryParameters {
  page?: number;
  perPage?: number;
}

interface IGetHolidayCalendarsYearsParameters {
  page?: number;
  perPage?: number;
  q?: IGetHolidayCalendarsYearsQueryParameters;
  nameCont?: string;
  sort?: ISortParameter;
}

interface IGetHolidayCalendarsYearsResponse {
  years: Array<IHolidayCalendarYearShort>;
}
interface IGetHolidayCalendarYearResponse {
  year: IHolidayCalendarYearDetail;
}

export const loadingGetHolidayCalendarsYearsParameters = (
  parameters: IGetHolidayCalendarsYearsPlainParameters,
): IGetHolidayCalendarsYearsParameters => {
  const { page, perPage, ...queryParameters } = parameters;
  return {
    page,
    perPage,
    q: {
      ...queryParameters,
    },
  };
};

export const holidayCalendarYearApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getHolidayCalendarsYears: builder.query<IGetHolidayCalendarsYearsResponse, IGetHolidayCalendarsYearsParameters>({
      query: parameters => ({
        url: apiRoutes.apiV1HolidayCalendarsYearsPath(),
        method: 'GET',
        params: parameters,
      }),
      providesTags: [TagTypes.HolidayCalendarYear],
    }),
    getHolidayCalendarYear: builder.query<IGetHolidayCalendarYearResponse, number>({
      query: id => ({
        url: apiRoutes.apiV1HolidayCalendarsYearPath(id),
        method: 'GET',
      }),
      providesTags: ({ year }) => [{ type: TagTypes.HolidayCalendarYear, id: year.id }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetHolidayCalendarsYearsQuery, useGetHolidayCalendarYearQuery, useLazyGetHolidayCalendarYearQuery } =
  holidayCalendarYearApi;
