import { FC } from 'react';

import { ExpandMoreOutlined } from '@mui/icons-material';
import { InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { HoursType } from 'enums/HoursType';

import styles from './styles';
import { IHoursTypeFilterProps } from './types';

const HoursTypeFilter: FC<IHoursTypeFilterProps> = props => {
  const { value, onChange } = props;
  const { t } = useTranslation('reportHoursByResources');

  return (
    <Div sx={styles.root}>
      <InputLabel sx={styles.hoursTypeLabel} id="hoursType-select-label">
        <Typography sx={styles.hoursTypeLabelText} variant="heading3">
          {t('filter.hoursType')}
        </Typography>
      </InputLabel>
      <Select
        labelId="hoursType-select-label"
        sx={styles.select}
        value={value}
        onChange={event => onChange(event.target.value as HoursType)}
        displayEmpty
        IconComponent={ExpandMoreOutlined}
      >
        {Object.values(HoursType).map(hoursType => {
          return (
            <MenuItem key={hoursType} value={hoursType}>
              <Typography sx={styles.hoursTypeOptionText} variant="subtitle1">
                {t(`hoursType.${hoursType}`)}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </Div>
  );
};

export default HoursTypeFilter;
