import { FC, useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import _ from 'lodash';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateResourceUsageMutation } from 'domain/resource/usage/apiSlice';
import {
  IUpdateResourceUsageFormData,
  formatAttributesToSubmit,
  initialValues,
  buildValidationSchema,
} from 'domain/resource/usage/schemas/update';

import { MeasurementUnitFilter } from 'enums/MeasurementUnitFilter';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { IUpdateResourceUsageWorkloadWithDatesFormProps } from './types';

const UpdateResourceUsageWorkloadWithDatesForm: FC<IUpdateResourceUsageWorkloadWithDatesFormProps> = props => {
  const { resourceId, resourceUsage, projectId } = props;

  const [hasEndDate, setHasEndDate] = useState<boolean>(!_.isNil(resourceUsage.endDate));

  const { t } = useTranslation(['common', 'updateResourceUsageWorkloadWithDatesForm']);

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const { closeModal } = useContext(ModalContext);

  useEffect(() => {
    if (!hasEndDate) {
      setValue('endDate', null);
    }
  }, [hasEndDate]);

  const [updateResourceUsage, { isLoading: isUpdateResourceUsageLoading }] = useUpdateResourceUsageMutation();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IUpdateResourceUsageFormData>({
    defaultValues: initialValues(resourceUsage),
    resolver: yupResolver(buildValidationSchema()),
  });

  const onSubmit = async (formData: IUpdateResourceUsageFormData) => {
    try {
      await updateResourceUsage(formatAttributesToSubmit(resourceId, resourceUsage.id, projectId, formData)).unwrap();

      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const {
    field: { value: startDate, onChange: onStartDateChange },
  } = useController({ name: 'startDate', control });

  const {
    field: { value: endDate, onChange: onEndDateChange },
  } = useController({ name: 'endDate', control });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div>
        <Div sx={styles.hasEndDate}>
          <FormControlLabel
            sx={styles.switchEndDate}
            control={
              <Switch
                disabled={isUpdateResourceUsageLoading}
                checked={hasEndDate}
                onChange={() => setHasEndDate(!hasEndDate)}
              />
            }
            label={
              <Typography variant="subtitle1">
                {t('updateResourceUsageWorkloadWithDatesForm:switches.endDate.label')}
              </Typography>
            }
          />
        </Div>

        <Div sx={styles.fields}>
          <DatePicker
            label={t('updateResourceUsageWorkloadWithDatesForm:fields.startDate.label')}
            value={startDate}
            onChange={onStartDateChange}
            disabled={isUpdateResourceUsageLoading}
            renderInput={parameters => (
              <TextField
                sx={styles.datePickerField}
                {...parameters}
                error={Boolean(errors.startDate)}
                helperText={buildErrorMessage(t, errors.startDate)}
              />
            )}
          />

          <DatePicker
            label={t('updateResourceUsageWorkloadWithDatesForm:fields.endDate.label')}
            value={endDate}
            onChange={onEndDateChange}
            disabled={!hasEndDate || isUpdateResourceUsageLoading}
            renderInput={parameters => (
              <TextField
                sx={styles.datePickerField}
                {...parameters}
                error={Boolean(errors.endDate)}
                helperText={buildErrorMessage(t, errors.endDate)}
              />
            )}
          />

          <TextField
            {...register('workload')}
            label={`${MeasurementUnitFilter.FTE} %`}
            InputLabelProps={{ sx: styles.placeholder }}
            error={Boolean(errors.workload)}
            sx={styles.input}
            disabled={isUpdateResourceUsageLoading}
          ></TextField>
        </Div>

        <Div>
          <Button sx={styles.changeAlloCationButton} type="submit" disabled={isUpdateResourceUsageLoading}>
            {t('updateResourceUsageWorkloadWithDatesForm:buttons.changeAllocation')}
          </Button>
          <Button type="reset" variant="outlined" onClick={closeModal} disabled={isUpdateResourceUsageLoading}>
            {t('updateResourceUsageWorkloadWithDatesForm:buttons.cancel')}
          </Button>
        </Div>
      </Div>
    </form>
  );
};

export default UpdateResourceUsageWorkloadWithDatesForm;
