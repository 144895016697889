export default {
  applyButton: 'Apply',
  dateRangePlaceholder: {
    title: 'This month',
  },
  staticRangesLables: {
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This Week',
    lastWeek: 'Last Week',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    thisYear: 'This Year',
    lastYear: 'Last Year',
  },
};
