import { ConditionState } from 'components/common/SmartFilter/enums';

import { IAutocompleteOption } from 'types/autocomplete';

export const createOptionsList = (
  options: Array<IAutocompleteOption>,
  currentOptions: Array<IAutocompleteOption>,
  status: ConditionState,
  inputValue: string,
  isNeedCheckType: boolean,
) => {
  const filteredOptions = options.filter(option => {
    const nameMatches = typeof option.name === 'string' && option.name.toLowerCase().includes(inputValue.toLowerCase());
    const stateMatches = option.state ? option.state === status : true;
    return nameMatches && (stateMatches || status === ConditionState.both);
  });
  const opLists = filteredOptions.reduce(
    (accumulator, element) =>
      isNeedCheckType
        ? reducerWithTypeChecking(accumulator, currentOptions, element)
        : reducerWithoutTypeChecking(accumulator, currentOptions, element),
    {
      selected: [],
      notSelected: [],
    },
  );
  return [...opLists.selected, ...opLists.notSelected];
};

const reducerWithoutTypeChecking = (
  accumulator: { selected: Array<IAutocompleteOption>; notSelected: Array<IAutocompleteOption> },
  currentOptions: Array<IAutocompleteOption>,
  option: IAutocompleteOption,
) => {
  if (currentOptions.some(selectedOption => selectedOption.id === option.id)) {
    accumulator.selected.push(option);
  } else {
    accumulator.notSelected.push(option);
  }
  return accumulator;
};

const reducerWithTypeChecking = (
  accumulator: { selected: Array<IAutocompleteOption>; notSelected: Array<IAutocompleteOption> },
  currentOptions: Array<IAutocompleteOption>,
  option: IAutocompleteOption,
) => {
  if (currentOptions.some(selectedOption => selectedOption.id === option.id && selectedOption.type === option.type)) {
    accumulator.selected.push(option);
  } else {
    accumulator.notSelected.push(option);
  }
  return accumulator;
};

export const getCurrentStatusState = (currentOptions: Array<IAutocompleteOption>): ConditionState => {
  if (currentOptions.length === 0) {
    return ConditionState.active;
  }
  if (currentOptions.every(option => option.state === ConditionState.active)) {
    return ConditionState.active;
  }
  if (currentOptions.every(option => option.state === ConditionState.archived)) {
    return ConditionState.archived;
  }
  return ConditionState.both;
};
