import { FC, useState } from 'react';

import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Button, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  isSameDay,
  subDays,
  subMonths,
  subYears,
  differenceInDays,
  add,
  sub,
} from 'date-fns';
import _ from 'lodash';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { getDateRange, RangeType } from 'utils/dateRangePicker';
import { formatToShortString } from 'utils/dateTime';

import DatePicker from './components/Picker';
import styles from './styles';
import { DateRange, IDateRangePickerTypeProps } from './types';

const DateRangePicker: FC<IDateRangePickerTypeProps> = props => {
  const { currentDateRange, onDateRangeChange } = props;
  const [dateRange, setDateRange] = useState<DateRange>(currentDateRange);

  const [dateRangePickerElement, setDateRangePickerElement] = useState<null | HTMLButtonElement>(null);

  const handleDateRangePlaceholderClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDateRangePickerElement(event.currentTarget);
  };

  const isDateRangePickerOpen = Boolean(dateRangePickerElement);
  const dateRangePickerElementId = isDateRangePickerOpen ? 'date-range' : undefined;

  const startDate = formatToShortString(dateRange.startDate);
  const endDate = formatToShortString(dateRange.endDate);

  const handleDateRangeChange = (newDateRange: Array<DateRange> | null) => {
    newDateRange && setDateRange(newDateRange[0]);
  };

  const handleDateRangePickerClose = () => {
    if (_.isNil(dateRange)) {
      setDateRangePickerElement(null);
      return;
    }

    const { startDate, endDate } = currentDateRange;
    if (isSameDay(startDate, dateRange.startDate) && isSameDay(endDate, dateRange.endDate)) {
      setDateRangePickerElement(null);
      return;
    }

    onDateRangeChange(dateRange);
    setDateRangePickerElement(null);
  };

  const goBackward = () => {
    const newDateRange = getNewDateRange({ dateRange, forward: false });

    onDateRangeChange(newDateRange);
    setDateRange(newDateRange);
  };

  const goForward = () => {
    const newDateRange = getNewDateRange({ dateRange, forward: true });

    onDateRangeChange(newDateRange);
    setDateRange(newDateRange);
  };

  const backwardDateRange = getNewDateRange({ dateRange, forward: false });
  const backwardTooltip = `${formatToShortString(backwardDateRange.startDate)} - ${formatToShortString(
    backwardDateRange.endDate,
  )}`;
  const forwardDateRange = getNewDateRange({ dateRange, forward: true });
  const forwardTooltip = `${formatToShortString(forwardDateRange.startDate)} - ${formatToShortString(
    forwardDateRange.endDate,
  )}`;

  return (
    <Div sx={styles.dateRangePickerBlock}>
      <Div sx={styles.titleContainer}>
        <Tooltip arrow title={backwardTooltip} placement="top">
          <IconButton onClick={goBackward}>
            <KeyboardArrowLeftOutlined />
          </IconButton>
        </Tooltip>
        <Button
          sx={styles.dateRangePickerElementButton}
          aria-controls={dateRangePickerElementId}
          aria-haspopup="true"
          aria-expanded={isDateRangePickerOpen ? 'true' : undefined}
          onClick={handleDateRangePlaceholderClick}
        >
          <Icon name="calendar" sx={styles.iconBlock} />

          <Typography sx={styles.text} variant="body1">
            {`${startDate} - ${endDate}`}
          </Typography>
        </Button>
        <Tooltip arrow title={forwardTooltip} placement="top">
          <IconButton onClick={goForward}>
            <KeyboardArrowRightOutlined />
          </IconButton>
        </Tooltip>
      </Div>
      <Menu
        id={dateRangePickerElementId}
        anchorEl={dateRangePickerElement}
        open={isDateRangePickerOpen}
        onClose={handleDateRangePickerClose}
        MenuListProps={{
          'aria-labelledby': 'date-range-button',
        }}
      >
        <MenuItem disableRipple>
          <DatePicker
            dateRange={[getDateRange(dateRange.startDate, dateRange.endDate, dateRange.rangeType)]}
            onDateRangeChange={handleDateRangeChange}
            onClose={handleDateRangePickerClose}
          />
        </MenuItem>
      </Menu>
    </Div>
  );
};

const getDuration = (parameters: { dateRange: DateRange; forward: boolean }) => {
  const forward = parameters.forward;

  let startDate = parameters.dateRange.startDate;
  let endDate = parameters.dateRange.endDate;

  switch (parameters.dateRange.rangeType) {
    case RangeType.day: {
      startDate = forward ? addDays(startDate, 1) : subDays(startDate, 1);
      endDate = endOfDay(startDate);
      break;
    }
    case RangeType.week: {
      startDate = forward ? addDays(startDate, 7) : subDays(startDate, 7);
      endDate = forward ? addDays(endDate, 7) : subDays(endDate, 7);
      break;
    }
    case RangeType.month: {
      startDate = forward ? addMonths(startDate, 1) : subMonths(startDate, 1);
      endDate = endOfMonth(startDate);
      break;
    }
    case RangeType.year: {
      startDate = forward ? addYears(startDate, 1) : subYears(startDate, 1);
      endDate = endOfYear(startDate);
      break;
    }

    default: {
      const duration = differenceInDays(endDate, startDate);
      const shiftFunction = forward ? add : sub;

      // Move the entire range by one day
      startDate = shiftFunction(startDate, { days: duration + 1 });
      endDate = shiftFunction(endDate, { days: duration + 1 });

      break;
    }
  }

  return { startDate, endDate };
};

const getNewDateRange = ({ dateRange, forward }: { dateRange: DateRange; forward: boolean }) => {
  const { startDate, endDate } = getDuration({ dateRange, forward });

  return getDateRange(startDate, endDate, dateRange.rangeType);
};

export default DateRangePicker;
