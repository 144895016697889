import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minWidth: '180px',
    marginRight: 2.5,
    height: '64px',
    padding: 1.5,
    backgroundColor: 'background.white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '8px',
  },
  budgetBlock: {
    minWidth: '210px',
  },
  hoursIconBlock: {
    width: '32px',
    height: '32px',
    marginRight: 1.5,
  },
  chairEmoji: {
    fontSize: '32px',
    marginTop: 0.625,
  },
  hoursBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  hoursIcon: {
    width: '32px',
    height: '32px',
    color: 'primary.main',
  },
  hoursBlockTitle: {
    color: 'text.gray',
  },
};

export default styles;
