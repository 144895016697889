import { FC, useState, useMemo } from 'react';

import _ from 'lodash';

import Div from 'components/common/Div';
import InputField from 'components/common/SmartFilter/components/InputField';
import StatusBar from 'components/common/SmartFilter/components/StatusBar';

import OptionsList from './components/OptionsList';
import { createOptionsList, getCurrentStatusState } from './service';
import styles from './styles';
import { IMultipleAutocompleteProps } from './types';

const MultipleAutocomplete: FC<IMultipleAutocompleteProps> = props => {
  const {
    options,
    onCurrentOptionsChange,
    label,
    currentOptions = [],
    showBlankOption,
    isBlank,
    setIsBlank = () => {},
    blankLabel,
    setOpenPopover,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [status, setStatus] = useState(getCurrentStatusState(currentOptions));
  const isNeedCheckType = options.some(option => !_.isNil(option.type));

  const optionsList = useMemo(
    () => createOptionsList(options, currentOptions, status, inputValue, isNeedCheckType),
    [inputValue, status],
  );

  const handleMenuItemClick = newStatus => {
    setStatus(newStatus);
  };

  const handleAllButtonClick = () => {
    setIsBlank(false);
    onCurrentOptionsChange(optionsList);
  };

  const handleNoneButtonClick = () => {
    setIsBlank(false);
    onCurrentOptionsChange([]);
  };

  const handleBlankChange = () => {
    setIsBlank(!isBlank);
    onCurrentOptionsChange([]);
  };

  const onInputKeyUp = event => {
    const inputValue = event.target.value;

    if (event.key === 'Enter' && !_.isEmpty(inputValue)) {
      const firstOption = _.isEmpty(optionsList) ? [] : [optionsList[0]];
      onCurrentOptionsChange(firstOption, true);
      setOpenPopover(false);
    }
  };

  return (
    <Div sx={styles.autocompleteBox}>
      <InputField
        onInputKeyUp={event => onInputKeyUp(event)}
        inputValue={inputValue}
        onInputChange={setInputValue}
        label={label}
      />
      <OptionsList
        optionsList={optionsList}
        currentOptions={currentOptions}
        onOptionClick={onCurrentOptionsChange}
        inputValue={inputValue}
        label={label}
        onAllButtonClick={handleAllButtonClick}
        onNoneButtonClick={handleNoneButtonClick}
        showBlankOption={showBlankOption}
        isNeedCheckType={isNeedCheckType}
        isBlank={isBlank}
        onBlankChange={handleBlankChange}
        blankLabel={blankLabel}
      />
      {label !== 'Specialization' && <StatusBar onMenuItemClick={handleMenuItemClick} status={status} />}
    </Div>
  );
};

export default MultipleAutocomplete;
