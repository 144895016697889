import { FC, useContext, useMemo } from 'react';

import EditIcon from '@mui/icons-material/EditOutlined';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import UserProfileDetailedInfoTable from 'components/common/UserProfileDetailedInfoTable/index';

import { ModalContext } from 'contexts/ModalContext';

import { useDeleteUserExpectedBillableTimePercentMutation } from 'domain/user/expectedBillableTimePercent/apiSlice';

import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useNotifications } from 'hooks/useNotifications';

import { formatDateToString, formatToDateWithFixedTimeZone } from 'utils/dateTime';
import { generateBackendErrorMessages } from 'utils/error';

import CreateUserExpectedBillableTimePercentForm from './CreateUserExpectedBillableTimePercentForm';
import styles from './styles';
import { IUserExpectedBillableTimePercentProps } from './types';
import UpdateUserExpectedBillableTimePercentForm from './UpdateUserExpectedBillableTimePercentForm';

const UserExpectedBillableTimePercent: FC<IUserExpectedBillableTimePercentProps> = props => {
  const { userExpectedBillableTimePercents, user } = props;

  const { t } = useTranslation('userProfile');
  const { openModal } = useContext(ModalContext);
  const { getConfirmation } = useConfirmDialog();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [deleteExpectedBillableTimePercent, { isLoading: isDeleteExpectedBillableTimePercentLoading }] =
    useDeleteUserExpectedBillableTimePercentMutation();

  const handleDeleteExpectedBillableTimePercentClick = async expectedBillableTimePercent => {
    if (
      await getConfirmation({
        title: '',
        message: t('schedule.deleteExpectedBillableTimePercentConfirmationDialog.message'),
      })
    ) {
      try {
        await deleteExpectedBillableTimePercent({ id: expectedBillableTimePercent.id, userId: user.id }).unwrap();
        showSuccessNotification();
      } catch (error) {
        const errors = generateBackendErrorMessages(error);
        for (const message of errors) {
          showErrorNotification(message);
        }
      }
    }
  };
  const handleAddExpectedBillableTimePercentClick = () => {
    openModal({
      title: t('schedule.createExpectedBillableTimePercentForm.title'),
      content: <CreateUserExpectedBillableTimePercentForm user={user} />,
    });
  };
  const handleUpdateExpectedBillableTimePercentClick = expectedBillableTimePercent => {
    openModal({
      title: t('schedule.updateExpectedBillableTimePercentForm.title'),
      content: (
        <UpdateUserExpectedBillableTimePercentForm
          expectedBillableTimePercent={expectedBillableTimePercent}
          user={user}
        />
      ),
    });
  };
  const { canUpdateSchedule, canCreateSchedule } = user.permissions;
  const prepareExpectedBillableTimePercentData = expectedBillableTimePercent => {
    const { timePercent, startDate, endDate } = expectedBillableTimePercent;
    const startDateString = formatDateToString(formatToDateWithFixedTimeZone(startDate));
    const endDateString = formatDateToString(formatToDateWithFixedTimeZone(endDate));

    const iconButtonStyles = MUISx(styles.iconButton, {
      condition: !canUpdateSchedule,
      sx: styles.disabledIconButton,
    });

    return {
      timePercent: <Typography variant="subtitle1">{`${timePercent}`}</Typography>,
      startDate: <Typography variant="subtitle1">{startDateString}</Typography>,
      endDate: <Typography variant="subtitle1">{endDate ? endDateString : t('schedule.notDefined')}</Typography>,
      actions: (
        <Div sx={styles.actions}>
          <Tooltip
            title={t('userProfile:accessLevel.userProfileAccessLevel.notAvailable')}
            disableHoverListener={canUpdateSchedule}
          >
            <span>
              <Button
                sx={iconButtonStyles}
                onClick={() => handleUpdateExpectedBillableTimePercentClick(expectedBillableTimePercent)}
                disabled={!canUpdateSchedule}
              >
                <EditIcon sx={styles.editIcon} />
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={t('userProfile:accessLevel.userProfileAccessLevel.notAvailable')}
            disableHoverListener={canUpdateSchedule}
          >
            <span>
              <Button
                onClick={() => handleDeleteExpectedBillableTimePercentClick(expectedBillableTimePercent)}
                disabled={!canUpdateSchedule}
                sx={styles.destroyButton}
              >
                <Icon name="trash" sx={canUpdateSchedule ? styles.destroyButton : styles.disabledIconButton} />
              </Button>
            </span>
          </Tooltip>
        </Div>
      ),
    };
  };

  const EXPECTED_BILLABLE_TIME_PERCENT_COLUMNS = useMemo(() => {
    return [
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('schedule.timePercent')}
          </Typography>
        ),
        accessor: 'timePercent',
      },
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('schedule.startDate')}
          </Typography>
        ),
        accessor: 'startDate',
      },
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('schedule.endDate')}
          </Typography>
        ),
        accessor: 'endDate',
      },
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('schedule.actions')}
          </Typography>
        ),
        accessor: 'actions',
      },
    ];
  }, []);

  const expectedBillableTimePercentData = useMemo(() => {
    return userExpectedBillableTimePercents?.map(expectedBillableTimePercent =>
      prepareExpectedBillableTimePercentData(expectedBillableTimePercent),
    );
  }, [userExpectedBillableTimePercents]);

  return (
    <Div sx={styles.container}>
      <Typography variant="heading3" sx={styles.header}>
        <Div sx={styles.titleLine}>
          <Div sx={styles.title}>{t('schedule.expectedBillableTimePercent')}</Div>
          {canCreateSchedule && (
            <IconButton
              onClick={handleAddExpectedBillableTimePercentClick}
              sx={MUISx(styles.actionButtonIcon)}
              disabled={isDeleteExpectedBillableTimePercentLoading}
            >
              <Icon sx={styles.submitButton} name="plus" />
            </IconButton>
          )}
        </Div>
      </Typography>
      <UserProfileDetailedInfoTable
        data={expectedBillableTimePercentData}
        columns={EXPECTED_BILLABLE_TIME_PERCENT_COLUMNS}
      />
    </Div>
  );
};

export default UserExpectedBillableTimePercent;
