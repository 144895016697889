import { FC, useMemo } from 'react';

import { Divider, Typography, Link as MUILink, Alert } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useScreen } from 'hooks';
import i18n from 'locales/i18n';
import appRoutes from 'routes/appRoutes';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import Loader from 'components/common/Loader';
import Navigation from 'components/common/Navigation';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { useGetCurrentUserPermissionsQuery } from 'domain/currentUser/permission/apiSlice';
import { PermissionTargets } from 'domain/currentUser/permission/enums';

import { INavigationItem } from 'types/sidebar';

import CurrentUserBadge from './components/CurrentUserBadge';
import { Host, EnvironmentPostfix } from './enums';
import { getAllMenuShow } from './service';
import styles from './styles';

const createNavItem = (condition, titleKey, iconName, route) =>
  condition ? [{ title: i18n.t(titleKey), iconName, route }] : [];

const SideBar: FC = () => {
  const { t } = useTranslation(['common', 'sidebar']);

  const { lessThanDesktop } = useScreen();

  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
    isError: isCurrentUserLoadingError,
  } = useGetCurrentUserQuery();

  const {
    data: currentUserPermissions,
    isLoading: isCurrentUserPermissionsLoading,
    isError: isCurrentUserPermissionsLoadingError,
  } = useGetCurrentUserPermissionsQuery({
    permissionTarget: PermissionTargets.sidebar,
  });

  const mainNavigationItems: Array<INavigationItem> = useMemo(
    () => [
      ...createNavItem(
        currentUserPermissions?.permissions?.canAccessTimeTracker,
        'sidebar:mainNavigation.timeTracker.title',
        'hours',
        appRoutes.timeTracker,
      ),
      ...createNavItem(
        currentUserPermissions?.permissions?.canAccessVacations,
        'sidebar:mainNavigation.vacations.title',
        'vacation',
        appRoutes.vacations,
      ),
      ...createNavItem(
        currentUserPermissions?.permissions?.canAccessReports,
        'sidebar:mainNavigation.reports.title',
        'dashboard',
        appRoutes.reportTrackedTimeEntries,
      ),
      ...createNavItem(
        currentUserPermissions?.permissions?.canAccessResources,
        'sidebar:mainNavigation.resources.title',
        'resources',
        appRoutes.reportHoursByResources,
      ),
      ...createNavItem(
        currentUserPermissions?.permissions?.canAccessProjects,
        'sidebar:mainNavigation.projects.title',
        'projects',
        appRoutes.reportHoursByProjects,
      ),
      ...createNavItem(
        currentUserPermissions?.permissions?.canAccessAllocations,
        'sidebar:mainNavigation.allocation.title',
        'allocation',
        appRoutes.allocation,
      ),
    ],
    [currentUserPermissions],
  );

  const settingsNavigationItems: Array<INavigationItem> = useMemo(
    () => [
      ...createNavItem(
        currentUserPermissions?.permissions?.canAccessUsers,
        'sidebar:settingsNavigation.users.title',
        'users',
        appRoutes.users,
      ),
      ...createNavItem(
        currentUserPermissions?.permissions?.canAccessAdminPanel,
        'sidebar:settingsNavigation.adminPanel.title',
        'users',
        appRoutes.adminPanel,
      ),
    ],
    [currentUserPermissions],
  );

  const isError = isCurrentUserLoadingError || isCurrentUserPermissionsLoadingError;
  const isDataLoading = isCurrentUserLoading || isCurrentUserPermissionsLoading;

  if (isDataLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const { isMainMenuShow, isSettingsMenuShow } = getAllMenuShow({
    mainNavigationItems,
    settingsNavigationItems,
    currentUser: currentUser.user,
  });

  const defineEnvironmentPostfix = host => {
    switch (host) {
      case Host.develop: {
        return EnvironmentPostfix.develop;
      }
      case Host.qa: {
        return EnvironmentPostfix.qa;
      }
      case Host.uat: {
        return EnvironmentPostfix.uat;
      }
      case Host.production: {
        return EnvironmentPostfix.production;
      }
      default: {
        return EnvironmentPostfix.uffizzy;
      }
    }
  };

  const host = window.location.host;
  const titlePostfix = defineEnvironmentPostfix(host);
  const isProduction = host === Host.production;
  const title = t('sidebar:logoTitle');

  return (
    <Div sx={styles.root}>
      <MUILink component={Link} to={appRoutes.timeTracker.path()} sx={styles.header}>
        <Div sx={styles.logoBlock}>
          <Icon name="logo" />
        </Div>
        {!lessThanDesktop && (
          <Typography variant="h4" sx={MUISx({ condition: !isProduction, sx: styles.notProductionTitle })}>
            {`${title} ${titlePostfix}`}
          </Typography>
        )}
      </MUILink>

      <Divider sx={styles.divider} />
      <Div sx={styles.content}>
        <Div>
          {isMainMenuShow && <Navigation navigationItems={mainNavigationItems} currentUser={currentUser.user} />}

          <Divider
            sx={MUISx(styles.divider, { condition: !isMainMenuShow || !isSettingsMenuShow, sx: styles.dividerHidden })}
          />

          {isSettingsMenuShow && (
            <Navigation navigationItems={settingsNavigationItems} currentUser={currentUser.user} />
          )}
        </Div>

        <Div sx={styles.footer}>
          <Divider sx={MUISx(styles.divider, styles.footerDivider)} />

          <Div sx={styles.userBadgeBlock}>
            <CurrentUserBadge user={currentUser.user} />
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default SideBar;
