import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    textAlign: 'center',
    color: 'text.primary',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'background.lightGray',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  weekend: {
    color: 'primary.red',
    fontWeight: 600,
    backgroundColor: 'transparent',
  },
  holiday: {
    color: 'primary.red',
    backgroundColor: 'background.lightRed',
  },
  approved: {
    backgroundColor: 'background.green',
  },
  completed: {
    backgroundColor: 'background.lightDarkGreen',
  },
  declined: {
    backgroundColor: 'background.red',
  },
  onApproval: {
    backgroundColor: 'background.orange',
  },
  roundLeft: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  roundRight: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  startSelection: {
    color: 'primary.contrastText',
    backgroundColor: 'background.blue',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endSelection: {
    color: 'primary.contrastText',
    backgroundColor: 'background.blue',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  inSelection: {
    backgroundColor: 'background.lightBlue',
  },
};

export default styles;
