import React, { FC, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import decamelize from 'decamelize';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import {
  IUpdateCompanySettingFormData,
  initialValues,
  buildValidationSchema,
} from 'domain/company/settings/schemas/form';

import { buildErrorMessage } from 'utils/form';

import { CheckTypes, CheckStrategy } from '../../enums';

import styles from './styles';
import { ICompanySettingsFormProps } from './types';

const CompanySettingsForm: FC<ICompanySettingsFormProps> = props => {
  const { onSubmit, disabled, submitButtonLabel, setting } = props;

  const { t } = useTranslation('adminPanel');

  const { closeModal } = useContext(ModalContext);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateCompanySettingFormData>({
    defaultValues: initialValues(setting),
    resolver: yupResolver(buildValidationSchema),
  });

  const {
    field: { value: trackedTimeEditLockDate, onChange: onTrackedTimeEditLockDateChange },
  } = useController({ name: 'trackedTimeEditLockDate', control });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.datePicker}>
        <DatePicker
          label={t('companySettings.companySettingsForm.date.label')}
          value={trackedTimeEditLockDate}
          onChange={onTrackedTimeEditLockDateChange}
          renderInput={parameters => (
            <TextField
              required
              {...parameters}
              error={Boolean(errors.trackedTimeEditLockDate)}
              helperText={buildErrorMessage(t, errors.trackedTimeEditLockDate)}
              sx={styles.datePickerField}
            />
          )}
        />
      </Div>
      <Div sx={styles.datePicker}>
        <TextField
          label={t('companySettings.companySettingsForm.hoursPeerWeek.label')}
          variant="outlined"
          {...register('defaultHoursPerWeek')}
          fullWidth
          type="number"
          placeholder="0"
          sx={styles.datePickerField}
        />
      </Div>
      <Div sx={styles.datePicker}>
        <TextField
          label={t('companySettings.companySettingsForm.expectedPercentage.label')}
          variant="outlined"
          {...register('defaultExpectedBillablePercentage')}
          fullWidth
          placeholder="%"
          type="number"
          sx={styles.datePickerField}
        />
      </Div>
      <Div sx={styles.datePicker}>
        <TextField
          label={t('companySettings.companySettingsForm.duplicationCheck.label')}
          variant="outlined"
          select
          {...register('trackedTimeDuplicationCheck')}
          fullWidth
          placeholder="Duplication check"
          type="string"
          defaultValue={setting.trackedTimeDuplicationCheck}
          sx={styles.datePickerField}
        >
          <MenuItem value={CheckTypes.internal}>
            {t(`companySettings.companySettingsForm.duplicationCheck.${CheckTypes.internal}`)}
          </MenuItem>
          <MenuItem value={CheckTypes.AI}>
            {t(`companySettings.companySettingsForm.duplicationCheck.${CheckTypes.AI}`)}
          </MenuItem>
        </TextField>
      </Div>
      <Div sx={styles.datePicker}>
        <TextField
          {...register('defaultPrecisionForDuplicationCheck')}
          label={t('companySettings.companySettingsForm.defaultPrecisionForDuplicationCheck.label')}
          variant="outlined"
          fullWidth
          placeholder="Precision"
          type="number"
          sx={styles.datePickerField}
        />
      </Div>
      <Div sx={styles.datePicker}>
        <TextField
          {...register('trackedTimeUniquenessCheckStrategy')}
          label={t('companySettings.companySettingsForm.uniquenessCheckStrategy.label')}
          variant="outlined"
          select
          fullWidth
          placeholder="Check strategy"
          type="string"
          sx={styles.datePickerField}
          defaultValue={setting.trackedTimeUniquenessCheckStrategy}
        >
          <MenuItem value={decamelize(CheckStrategy.doNotCheck)}>
            {t(`companySettings.companySettingsForm.uniquenessCheckStrategy.${CheckStrategy.doNotCheck}`)}
          </MenuItem>
          <MenuItem value={decamelize(CheckStrategy.markAsDuplicate)}>
            {t(`companySettings.companySettingsForm.uniquenessCheckStrategy.${CheckStrategy.markAsDuplicate}`)}
          </MenuItem>
          <MenuItem value={decamelize(CheckStrategy.block)}>
            {t(`companySettings.companySettingsForm.uniquenessCheckStrategy.${CheckStrategy.block}`)}
          </MenuItem>
        </TextField>
      </Div>
      <Div sx={styles.buttonsContainer}>
        <Button type="submit" variant="contained" disabled={disabled}>
          {submitButtonLabel}
        </Button>
        <Button variant="outlined" onClick={closeModal} disabled={disabled}>
          {t('buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default CompanySettingsForm;
