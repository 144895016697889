import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    gap: 5,
    minHeight: '100%',
  },
  treeTitle: {
    marginTop: 3,
    marginBottom: 1,
  },
};

export default styles;
