import { TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { ICreateUserHoursPerWeekFormDataToSubmit } from './schemas/create';
import { IUpdateUserHoursPerWeekFormDataToSubmit } from './schemas/update';
import { IUserHoursPerWeek } from './types';

interface IUserHoursPerWeekParameters {
  id: number;
  userId: number;
}

interface IGetUserHoursPerWeekResponse {
  userHoursPerWeek: IUserHoursPerWeek;
}

interface IGetUserHoursPerWeeksResponse {
  userHoursPerWeeks: Array<IUserHoursPerWeek>;
  meta: IMeta;
}

interface IGetUserHoursPerWeekQueryParameters {
  sort?: string;
}

interface IGetUserHoursPerWeeksPlainParameters extends IGetUserHoursPerWeekQueryParameters {
  page?: number;
  perPage?: number;
  userId: number;
}

interface IGetUserHoursPerWeeksParameters {
  q?: IGetUserHoursPerWeekQueryParameters;
  perPage?: number;
  page?: number;
  sort?: string;
  userId: number;
}

export const loadingGetUserHoursPerWeeksParameters = (
  parameters: IGetUserHoursPerWeeksPlainParameters,
): IGetUserHoursPerWeeksParameters => {
  const { userId, ...queryParameters } = parameters;
  return {
    userId,
    q: { ...queryParameters },
  };
};

export const userHoursPerWeeksApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getUserHoursPerWeeks: builder.query<IGetUserHoursPerWeeksResponse, IGetUserHoursPerWeeksParameters>({
      query: ({ userId, ...parameters }) => ({
        url: apiRoutes.apiV1UserHoursPerWeeksPath(userId),
        method: 'GET',
        params: {
          ...parameters,
        },
      }),
      providesTags: response => provideTags(TagTypes.UserHoursPerWeek, response?.userHoursPerWeeks),
    }),
    createUserHoursPerWeek: builder.mutation<IGetUserHoursPerWeekResponse, ICreateUserHoursPerWeekFormDataToSubmit>({
      query: ({ userId, formData }) => ({
        url: apiRoutes.apiV1UserHoursPerWeeksPath(userId),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: [TagTypes.UserHoursPerWeek],
    }),
    updateUserHoursPerWeek: builder.mutation<IGetUserHoursPerWeekResponse, IUpdateUserHoursPerWeekFormDataToSubmit>({
      query: ({ hoursPerWeekId, userId, formData }) => ({
        url: apiRoutes.apiV1UserHoursPerWeekPath(userId, hoursPerWeekId),
        method: 'PUT',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: [TagTypes.UserHoursPerWeek],
    }),
    deleteUserHoursPerWeek: builder.mutation<void, IUserHoursPerWeekParameters>({
      query: ({ id, userId }) => ({
        url: apiRoutes.apiV1UserHoursPerWeekPath(userId, id),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.UserHoursPerWeek],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserHoursPerWeeksQuery,
  useCreateUserHoursPerWeekMutation,
  useUpdateUserHoursPerWeekMutation,
  useDeleteUserHoursPerWeekMutation,
} = userHoursPerWeeksApi;
