import { SxProps } from '@mui/system';

const styles: SxProps = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '0',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #eee',
  },
};

export default styles;
