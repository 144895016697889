import { FC, useMemo, useContext } from 'react';

import { ExpandMoreOutlined, ExpandLessOutlined } from '@mui/icons-material';
import { Typography, IconButton, Button, Tooltip } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useRouter } from 'hooks';
import appRoutes from 'routes/appRoutes';

import CreateResourceAllocation from 'components/common/CreateResourceAllocation';
import CreateResourceUsageAndVacancyForm from 'components/common/CreateResourceUsageAndVacancy';
import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import LinkButton from 'components/common/LinkButton';
import ResourceUsageActions from 'components/common/ResourceUsageActions';
import ResourceUsageWorkloadWithDatesButton from 'components/common/ResourceUsageWorkloadWithDatesButton';
import CommonTableExpanded from 'components/common/TableExpanded';
import VacancyActions from 'components/common/VacancyActions';
import VacancyFteWithDatesButton from 'components/common/VacancyFteWithDatesButton';

import { ModalContext } from 'contexts/ModalContext';

import { IReportHoursByProjectsProject, IReportResourceUsage } from 'domain/report/hoursByProject/types';
import { IResource } from 'domain/resource/types';
import { getTechnologies as getResourceUsageTechnologies } from 'domain/resource/usage/presenter';
import { TimeTrackerTrackableType } from 'domain/timeTracker/trackable/enums';
import { isProjectType, canUpdateBudgets, canAddResources } from 'domain/timeTracker/trackable/service';
import { getTechnologies as getVacancyTechnologies } from 'domain/vacancy/presenter';
import { IVacancy } from 'domain/vacancy/types';

import { HoursByProjectTab } from 'enums/reportHoursByProject';

import { IDateRange } from 'types/dates';

import { formatToShortString, parseDate } from 'utils/dateTime';
import { formatToUSNumber } from 'utils/number';
import { getFullName } from 'utils/person';

import { buildPathForProjectBudgetsClick } from '../../service';

import TeamInfoDropDown from './components/TeamInfoDropDown';
import styles from './styles';
import { ITableCellOptions, ITableProps } from './types';

const Table: FC<ITableProps> = props => {
  const {
    data,
    columnTitles,
    isError,
    totalHours,
    isLoading,
    pagination,
    setPaginationParameters,
    buildPathForResourceNameClick,
    buildPathForProjectPlannedHoursClick,
    buildPathForResourcePlannedHoursClick,
    buildPathForProjectActualHoursClick,
    buildPathForResourceActualHoursClick,
    hasAccessToData = false,
  } = props;
  const { t } = useTranslation('reportHoursByProjects');

  const { openModal } = useContext(ModalContext);

  const { buildPath } = useRouter();

  const titlePlan = t('table.header.plan');
  const titleActual = t('table.header.actual');
  const titleBudgeted = t('table.header.budgeted');
  let dateRange: IDateRange | undefined = {
    dateStart: '',
    dateEnd: '',
  };
  const today = new Date();
  let formattedDateStart = formatToShortString(today);
  let formattedDateEnd = formatToShortString(today);
  if (columnTitles) {
    dateRange = _.head(columnTitles)?.pickedPeriodHours.dateRange;
    formattedDateStart = formatToShortString(parseDate(dateRange?.dateStart ?? ''));
    formattedDateEnd = formatToShortString(parseDate(dateRange?.dateEnd ?? ''));
  }

  const renderTableCell = (cellOptions: ITableCellOptions) => {
    const {
      hours,
      project,
      resourceUsage,
      isAllocationCell = false,
      isResourcesCell = false,
      isBudgetedCell = false,
      projectId = null,
      isPlannedHoursCell = null,
      isResourcePlannedHoursCell = null,
      isActualHoursCell = null,
      isResourceActualHoursCell = null,
    } = cellOptions;

    const isAllocationZeroStyle = isAllocationCell && hours === 0;
    const isAllocationNegativeStyle = isAllocationCell && hours < 0;

    const hoursToUSFormatted = formatToUSNumber(hours);
    const isProjectPlannedHoursLink = Boolean(isPlannedHoursCell && isProjectType);
    const isResourcePlannedHoursLink = Boolean(isResourcePlannedHoursCell && isProjectType);
    const isProjectActualHoursLink = Boolean(isActualHoursCell);
    const isResourceActualHoursLink = Boolean(isResourceActualHoursCell);
    const isHoursLinkCell =
      isProjectPlannedHoursLink || isResourcePlannedHoursLink || isProjectActualHoursLink || isResourceActualHoursLink;

    const getPathForHoursLink = link => {
      switch (link) {
        case isProjectPlannedHoursLink: {
          return buildPathForProjectPlannedHoursClick(
            project,
            {
              startDate: dateRange?.dateStart,
              endDate: dateRange?.dateEnd,
            },
            buildPath,
          );
        }
        case isProjectActualHoursLink: {
          return buildPathForProjectActualHoursClick(
            project,
            {
              startDate: dateRange?.dateStart,
              endDate: dateRange?.dateEnd,
            },
            buildPath,
          );
        }
        case isResourcePlannedHoursLink: {
          return buildPathForResourcePlannedHoursClick(
            project,
            resourceUsage,
            {
              startDate: dateRange?.dateStart,
              endDate: dateRange?.dateEnd,
            },
            buildPath,
          );
        }
        case isResourceActualHoursLink: {
          return buildPathForResourceActualHoursClick(
            project,
            resourceUsage,
            {
              startDate: dateRange?.dateStart,
              endDate: dateRange?.dateEnd,
            },
            buildPath,
          );
        }
      }
    };

    if (isHoursLinkCell) {
      const path = getPathForHoursLink(isHoursLinkCell);

      return (
        <Typography
          to={path}
          component={Link}
          sx={styles.hoursLinkCell}
          variant={isResourcesCell ? 'subtitle1' : 'h4'}
          target="_blank"
          rel="noopener noreferrer"
        >
          {hoursToUSFormatted}
        </Typography>
      );
    }

    if (isBudgetedCell && isProjectType(project)) {
      const path = buildPathForProjectBudgetsClick(projectId, HoursByProjectTab.budgets, {
        startDate: dateRange.dateStart,
        endDate: dateRange.dateEnd,
      });
      return (
        <LinkButton
          variant="outlined"
          sx={styles.transparentButton}
          to={path}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography sx={styles.budgetedCell} variant="h4">
            {hoursToUSFormatted}
          </Typography>
        </LinkButton>
      );
    }

    return (
      <Typography
        sx={MUISx(
          { condition: isAllocationZeroStyle, sx: styles.allocationZero },
          { condition: isAllocationNegativeStyle, sx: styles.allocationNegative },
        )}
        variant={isResourcesCell ? 'subtitle1' : 'h4'}
      >
        {hoursToUSFormatted}
      </Typography>
    );
  };

  const renderTableHeader = () => {
    let fullName = '';

    if (columnTitles) {
      fullName = _.head(columnTitles)?.pickedPeriodHours.fullName ?? '';
    }

    return (
      <Div sx={styles.headerRoot}>
        <Typography variant="tableHeading" component="p">
          {fullName.toUpperCase()}
        </Typography>
        <Typography component="p" sx={styles.columnHeader} variant="subtitle4">
          {formattedDateStart} - {formattedDateEnd}
        </Typography>
      </Div>
    );
  };

  const renderTableSubheader = (title: string) => {
    return (
      <Typography sx={styles.subheader} variant="tableHeading" component="p">
        {title}
      </Typography>
    );
  };

  const renderProjectActions = (project: IReportHoursByProjectsProject) => {
    if (isProjectType(project)) {
      return (
        <Div>
          <TeamInfoDropDown project={project} />
          {canAddResources(project) && (
            <Tooltip arrow title={t('tooltips.addResource')}>
              <IconButton onClick={() => handleAddResourceClick(project)} sx={styles.addResourceButton}>
                <Icon sx={styles.iconPlus} name="plus" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip arrow title={t('tooltips.projectDetails')}>
            <IconButton
              component={Link}
              sx={styles.actionsIconButton}
              to={appRoutes.reportHoursByProject.path(project.id)}
            >
              <Icon sx={styles.externalLinkIcon} name="externalLink" />
            </IconButton>
          </Tooltip>
        </Div>
      );
    }
  };

  const renderTotalHoursCell = (hours: number) => {
    const hoursToUSFormatted = formatToUSNumber(hours);
    return <Typography variant="h4">{hoursToUSFormatted}</Typography>;
  };

  const handleAddResourceClick = (project: IReportHoursByProjectsProject) => {
    openModal({
      title: t('table.addResourceUsageModalTitle', { projectName: project.name }),
      content: <CreateResourceUsageAndVacancyForm projectId={project.id} groupId={project.groupId} />,
    });
  };

  const handleAddResourceUsageClick = (
    resourceUsage: IReportResourceUsage,
    currentProject: IReportHoursByProjectsProject,
  ) => {
    const currentResource: IResource = {
      id: resourceUsage.resourceId,
      firstName: resourceUsage.firstName,
      lastName: resourceUsage.lastName,
      state: resourceUsage.state,
      technologies: resourceUsage.technologies,
    };

    openModal({
      title: t('table.addResourceUsageModalTitle', { projectName: currentProject.name }),
      content: <CreateResourceAllocation currentProject={currentProject} currentResource={currentResource} />,
    });
  };

  const PROJECTS_LIST_COLUMNS = useMemo(
    () => [
      {
        id: 'expander',
        Header: '',
        accessor: 'projectOrResourceNameEmpty',
        minWidth: '270px',
        columns: [
          {
            Header: '',
            accessor: 'projectOrResourceNameEmptyDepth',
            columns: [
              {
                Header: <Typography variant="h4">{t('table.header.titles.total')}</Typography>,
                accessor: 'projectOrResourceName',
                Cell: ({ row }) => {
                  return row.depth === 0 ? (
                    <Div sx={styles.cellTitleBlock} {...row.getToggleRowExpandedProps()}>
                      <IconButton sx={styles.cellTitleIconButton}>
                        {row.isExpanded ? (
                          <ExpandLessOutlined sx={styles.cellTitleIcon} />
                        ) : (
                          <ExpandMoreOutlined sx={styles.cellTitleIcon} />
                        )}
                      </IconButton>
                      {row.original.projectOrResourceName}
                      {row.original.resources}
                    </Div>
                  ) : (
                    row.original.projectOrResourceName
                  );
                },
              },
            ],
          },
        ],
      },
      {
        Header: '',
        Cell: ({ row }) => {
          if (row.isExpanded) {
            return row.original.actions;
          }

          if (row.depth === 1) {
            return row.original.workloadRate;
          }
          return null;
        },
        accessor: 'actions',
        minWidth: '186px',
      },
      {
        Header: renderTableHeader(),
        accessor: 'totalHours',
        columns: [
          {
            Header: renderTableSubheader(titlePlan),
            accessor: 'totalPlanEmpty',
            columns: [
              {
                Header: renderTotalHoursCell(totalHours?.pickedPeriodHours?.plan ?? 0),
                accessor: 'totalPlan',
              },
            ],
          },
          {
            Header: renderTableSubheader(titleActual),
            accessor: 'totalActualEmpty',
            columns: [
              {
                Header: renderTotalHoursCell(totalHours?.pickedPeriodHours?.actual ?? 0),
                accessor: 'totalActual',
              },
            ],
          },
          {
            Header: renderTableSubheader(titleBudgeted),
            accessor: 'totalBudgetedEmpty',
            columns: [
              {
                Header: renderTotalHoursCell(totalHours?.pickedPeriodHours?.budgeted ?? 0),
                accessor: 'totalBudgeted',
              },
            ],
          },
        ],
      },
    ],
    [data],
  );

  const renderResourceUsageNameWithActions = (
    resourceUsage: IReportResourceUsage,
    project: IReportHoursByProjectsProject,
  ) => {
    const fullName = getFullName(resourceUsage);
    const technologies = getResourceUsageTechnologies(resourceUsage);
    const canViewUser = resourceUsage.permissions.canViewUser;

    return (
      <Div sx={styles.resourcesAndVacanciesHeadCellRoot}>
        <ResourceUsageActions resourceUsage={resourceUsage} project={project} />
        <Div sx={styles.resourcesAndVacanciesHeadCell}>
          {canViewUser ? (
            <Typography
              component={Link}
              to={buildPathForResourceNameClick(resourceUsage)}
              sx={styles.resourceUsageCellHeader}
              variant="heading3"
              target="_blank"
              rel="noopener noreferrer"
            >
              {fullName}
            </Typography>
          ) : (
            <Typography sx={styles.resourceUsageCellHeaderNotClickable}>{fullName}</Typography>
          )}
          <Typography variant="subtitle2">{technologies}</Typography>
        </Div>
      </Div>
    );
  };

  const renderVacancyNameWithActions = (vacancy: IVacancy, project: IReportHoursByProjectsProject) => {
    const technologies = getVacancyTechnologies(vacancy);

    return (
      <Div sx={styles.resourcesAndVacanciesHeadCellRoot}>
        <VacancyActions vacancy={vacancy} project={project} groupId={project.groupId} />
        <Div sx={styles.resourcesAndVacanciesHeadCell}>
          <Div sx={styles.vacancy}>
            <Typography variant="heading3">{technologies}</Typography>
            <Typography sx={styles.vacancyIcon}>🪑</Typography>
          </Div>
          <Typography variant="subtitle2">{t('vacancy')}</Typography>
        </Div>
      </Div>
    );
  };

  const renderWorkloadRate = (project, resourceUsage) => {
    if (project.type === TimeTrackerTrackableType.trackingType) {
      return '';
    }

    if (_.isNil(resourceUsage.workload)) {
      return (
        <Button
          sx={styles.addResourceUsageButton}
          variant="outlined"
          onClick={() => handleAddResourceUsageClick(resourceUsage, project)}
        >
          {t('createResourceUsageAndVacancyButton')}
        </Button>
      );
    }

    return (
      <Div sx={styles.fteButton}>
        <ResourceUsageWorkloadWithDatesButton project={project} resourceUsage={resourceUsage} />
      </Div>
    );
  };

  const renderSubRows = (project: IReportHoursByProjectsProject) => {
    const resourceUsages = project.resourceUsages.map(resourceUsage => {
      return {
        projectOrResourceName: renderResourceUsageNameWithActions(resourceUsage, project),
        totalPlan: renderTableCell({
          hours: resourceUsage.pickedPeriodHours.plan,
          isResourcesCell: true,
          isResourcePlannedHoursCell: true,
          project: project,
          resourceUsage: resourceUsage,
          trackingType: project.type,
        }),
        totalActual: renderTableCell({
          hours: resourceUsage.pickedPeriodHours.actual,
          isResourcesCell: true,
          isResourceActualHoursCell: true,
          project: project,
          resourceUsage: resourceUsage,
        }),
        totalBudgeted: '',
        workloadRate: renderWorkloadRate(project, resourceUsage),
      };
    });

    const vacancies = project.vacancies.map(vacancy => {
      return {
        projectOrResourceName: renderVacancyNameWithActions(vacancy, project),
        totalPlan: renderTableCell({ hours: vacancy.pickedPeriodHours.plan, isResourcesCell: true }),
        totalActual: renderTableCell({ hours: vacancy.pickedPeriodHours.actual, isResourcesCell: true }),
        totalBudgeted: '',
        workloadRate: !_.isNil(vacancy.fte) && (
          <Div sx={styles.fteButton}>
            <VacancyFteWithDatesButton project={project} groupId={project.groupId} vacancy={vacancy} />
          </Div>
        ),
      };
    });

    return [...resourceUsages, ...vacancies];
  };

  const tableData = useMemo(() => {
    return data?.map(project => {
      const allocatedResources = project.resourceUsages?.filter(resource => resource.id)?.length;
      const totalResources = project.resourceUsages.length;
      return {
        actions: renderProjectActions(project),
        projectOrResourceName: (
          <Typography sx={styles.cellTitle} variant="h4">
            {project.name}
          </Typography>
        ),
        resources: (
          <>
            <Icon sx={styles.teamIcon} name="team" />
            <Typography sx={styles.cellTitle} variant="h4">
              {totalResources} /{' '}
              <Typography sx={allocatedResources < totalResources ? styles.cellTitleRed : undefined} component="span">
                {allocatedResources}
              </Typography>
            </Typography>
          </>
        ),
        totalPlan: renderTableCell({
          hours: project.pickedPeriodHours.plan,
          isPlannedHoursCell: true,
          project: project,
          trackingType: project.type,
        }),
        totalActual: renderTableCell({
          hours: project.pickedPeriodHours.actual,
          isActualHoursCell: true,
          project: project,
        }),
        totalBudgeted: renderTableCell({
          hours: project.pickedPeriodHours.budgeted,
          isBudgetedCell: canUpdateBudgets(project),
          projectId: project.id,
          budgetMonthId: project.budgetMonthId,
          project: project,
        }),
        subRows: renderSubRows(project),
      };
    });
  }, [data]);

  return (
    <CommonTableExpanded
      data={tableData}
      columns={PROJECTS_LIST_COLUMNS}
      isError={isError}
      isLoading={isLoading}
      onPaginationChange={setPaginationParameters}
      pagination={pagination}
      hasAccessToData={hasAccessToData}
    />
  );
};

export default Table;
