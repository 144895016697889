import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    paddingTop: 4,
    paddingRight: 2,
    paddingBottom: 0,
    paddingLeft: 2,
  },
  listTitle: {
    marginBottom: 3,
  },
  accessLevelsList: {
    marginBottom: 4,
  },
};

export default styles;
