import { SxStyles } from 'theme';

const styles: SxStyles = {
  wrapper: {
    marginTop: 8.125,
    marginX: 0,
    marginBottom: 2,
  },
  rowWrapper: {
    height: '69px',
    paddingY: 2.5,
    paddingX: 1.25,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'border.main',
  },
  row: {
    height: '32px',
    borderRadius: '8px',
  },
};

export default styles;
