import React, { FC } from 'react';

import { Search } from '@mui/icons-material';
import { Box, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import { IInputFieldProps } from './types';

const InputField: FC<IInputFieldProps> = ({ inputValue, onInputChange, label, onInputKeyUp }) => {
  const { t } = useTranslation('smartFilter');

  const handleChange = event => {
    onInputChange(event.target.value);
  };

  return (
    <Box sx={styles.box}>
      <TextField
        fullWidth
        autoFocus
        placeholder={t('multipleAutocomplete.placeholder')}
        value={inputValue}
        onChange={handleChange}
        label={label}
        onKeyUp={onInputKeyUp}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default InputField;
