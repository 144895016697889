import { FC, useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import _ from 'lodash';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import TechnologiesAutocomplete from 'components/common/TechnologiesAutocomplete';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateResourceUsageMutation } from 'domain/resource/usage/apiSlice';
import {
  initialValues,
  buildValidationSchema,
  formatAttributesToSubmit,
  IUpdateResourceUsageFormData,
} from 'domain/resource/usage/schemas/update';
import { useGetTechnologiesQuery } from 'domain/technology/apiSlice';
import { ITechnology } from 'domain/technology/types';

import { MeasurementUnitFilter } from 'enums/MeasurementUnitFilter';

import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';
import { getSortParameter } from 'utils/runsack';

import styles from './styles';
import { IUpdateAllocationFormProps } from './types';

const UpdateAllocationForm: FC<IUpdateAllocationFormProps> = props => {
  const { allocation } = props;

  const { t } = useTranslation(['common', 'allocationActions']);

  const [hasEndDate, setHasEndDate] = useState<boolean>(Boolean(allocation.endDate));

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const { closeModal } = useContext(ModalContext);

  const [technologyName, setTechnologyName] = useState<string>('');

  const [debouncedTechnologyName] = useDebounce(technologyName, DEFAULT_DEBOUNCE_DELAY_MS);

  const [updateResourceUsage, { isLoading: isUpdateResourceUsageLoading }] = useUpdateResourceUsageMutation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IUpdateResourceUsageFormData>({
    defaultValues: initialValues(allocation),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    data: technologiesData,
    isLoading: isTechnologiesDataLoading,
    isError: isTechnologiesDataLoadingError,
    isFetching: isTechnologiesDataFetching,
  } = useGetTechnologiesQuery({
    nameCont: debouncedTechnologyName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const {
    field: { value: technologies, onChange: onTechnologiesChange },
  } = useController({ name: 'technologies', control });

  const {
    field: { value: startDate, onChange: onStartDateChange },
  } = useController({ name: 'startDate', control });

  const {
    field: { value: endDate, onChange: onEndDateChange },
  } = useController({ name: 'endDate', control });

  const onSubmit = async (formData: IUpdateResourceUsageFormData) => {
    try {
      const data = { ...formData, endDate: hasEndDate ? formData.endDate : null };
      await updateResourceUsage(
        formatAttributesToSubmit(allocation.resourceId, allocation.id, allocation.projectId, data),
      ).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const handleCurrentTechnologiesChange = (technologies: Array<ITechnology>) => {
    if (_.isEmpty(technologies)) {
      onTechnologiesChange([]);
      return;
    }
    onTechnologiesChange(technologies);
  };

  if (isTechnologiesDataLoading) {
    return <Loader />;
  }

  if (isTechnologiesDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.technologiesAutocomplete}>
        <TechnologiesAutocomplete
          technologies={technologiesData.technologies}
          currentTechnologies={technologies}
          onCurrentTechnologiesChange={handleCurrentTechnologiesChange}
          setTechnologyName={setTechnologyName}
          isError={Boolean(errors.technologies)}
          isTechnologiesFetching={isTechnologiesDataFetching}
          label={t('allocationActions:updateAllocationForm.technologies.label')}
        />
      </Div>

      <Div sx={styles.dates}>
        <Typography variant="h4" component="p">
          {t('allocationActions:updateAllocationForm.period')}
        </Typography>
        <FormControlLabel
          sx={styles.endDateSwitch}
          control={<Switch checked={hasEndDate} onChange={() => setHasEndDate(!hasEndDate)} />}
          label={
            <Typography variant="subtitle1">
              {t('allocationActions:updateAllocationForm.switches.endDate.label')}
            </Typography>
          }
        />
      </Div>

      <Div sx={styles.groupFields}>
        <Div sx={styles.datePicker}>
          <DatePicker
            label={t('allocationActions:updateAllocationForm.fields.startDate.label')}
            value={startDate}
            onChange={onStartDateChange}
            renderInput={parameters => (
              <TextField
                required
                {...parameters}
                error={Boolean(errors.startDate)}
                helperText={buildErrorMessage(t, errors.startDate)}
                sx={styles.datePickerField}
              />
            )}
          />
        </Div>

        <Div sx={styles.datePicker}>
          <DatePicker
            label={t('allocationActions:updateAllocationForm.fields.endDate.label')}
            value={hasEndDate ? endDate : null}
            onChange={onEndDateChange}
            disabled={!hasEndDate}
            renderInput={parameters => (
              <TextField
                {...parameters}
                helperText={buildErrorMessage(t, errors.endDate)}
                error={Boolean(errors.endDate)}
                sx={styles.datePickerField}
              />
            )}
          />
        </Div>

        <TextField
          required
          inputProps={{ minLength: 1, maxLength: 3 }}
          sx={styles.input}
          {...register('workload')}
          label={`${MeasurementUnitFilter.FTE} %`}
          InputLabelProps={{ sx: styles.selectPlaceholder }}
          error={Boolean(errors.workload)}
        />
      </Div>

      <Div>
        <Button type="submit" disabled={isUpdateResourceUsageLoading} sx={styles.saveButton}>
          {t('allocationActions:updateAllocationForm.buttons.save')}
        </Button>
        <Button type="reset" variant="outlined" onClick={closeModal} disabled={isUpdateResourceUsageLoading}>
          {t('allocationActions:updateAllocationForm.buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default UpdateAllocationForm;
