import { SxStyles } from 'theme';

const styles: SxStyles = {
  input: {
    paddingTop: 0.625,
    marginBottom: 2,
    width: '380px',
  },
  buttonsContainer: {
    marginTop: 4,
    display: 'flex',
    gap: 1.5,
  },
  datePickerField: {
    width: '215px',
  },
  datePicker: {
    marginRight: 1.375,
    margin: 0.625,
    marginBottom: 2,
  },
  inputField: {
    width: '160px',
  },
};

export default styles;
