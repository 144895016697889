import { FC } from 'react';

import { Button, List, ListItem, ListItemText, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { useSkillLevelAccess } from 'contexts/SkillLevelAccessContext';

import Divider from './components/Divider';
import styles from './styles';
import { ITreeInnerProps } from './types';

const TreeInner: FC<ITreeInnerProps> = props => {
  const { t } = useTranslation('userProfile');

  const { data, isRootNode, onCreate, onUpdate, onDelete, isLoading } = props;

  const listStyles = MUISx({ condition: !isRootNode, sx: styles.listInner });
  const nodeStyles = MUISx({ condition: !isRootNode, sx: styles.innerNode }, styles.node);
  const { canUpdateSkillLevel } = useSkillLevelAccess();

  const nodeTypographyVariant = isRootNode ? 'heading3' : 'subtitle1';

  return (
    <List sx={listStyles}>
      {data.map(item => {
        const hasChildren = item.children.length > 0;

        const textContainerStyles = MUISx(styles.textContainer);

        return (
          <ListItem key={item.id} sx={nodeStyles}>
            <Div sx={styles.listItemContainer}>
              <Icon name="listPointer" sx={styles.listPointer} />
              <Div sx={textContainerStyles}>
                <ListItemText
                  primary={item.technologyName}
                  sx={styles.listItemText}
                  primaryTypographyProps={{ variant: nodeTypographyVariant }}
                />

                {!_.isNil(item.skillLevel) && (
                  <Div sx={styles.skillLevelBlock}>
                    <Divider />
                    <Typography sx={styles.skillLevel} variant="mainTableHeading">
                      {item.skillLevel.toUpperCase()}
                    </Typography>
                  </Div>
                )}

                {!_.isNil(item.id) && (
                  <Tooltip title={t('tooltips.notAvailable')} disableHoverListener={canUpdateSkillLevel}>
                    <Div data-actions="item-actions" sx={styles.actions}>
                      <Button
                        variant="text"
                        disabled={isLoading || !canUpdateSkillLevel}
                        onClick={() => onUpdate(item)}
                      >
                        <Typography variant="subtitle3">{t('specializationAndStack.changeSkill')}</Typography>
                      </Button>
                      <Button
                        variant="text"
                        sx={styles.removeSkillButton}
                        disabled={isLoading || !canUpdateSkillLevel}
                        onClick={() => onDelete(item)}
                      >
                        <Typography variant="subtitle3">{t('specializationAndStack.removeSkill')}</Typography>
                      </Button>
                    </Div>
                  </Tooltip>
                )}
              </Div>
            </Div>

            {hasChildren && (
              <TreeInner
                data={item.children}
                isRootNode={false}
                onCreate={onCreate}
                onUpdate={onUpdate}
                onDelete={onDelete}
                isLoading={isLoading}
              />
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

export default TreeInner;
