import { VacationApprovementState } from 'domain/report/vacationRequest/enums';
import { IReportVacationRequestResource } from 'domain/report/vacationRequest/types';

import { ResourseVacationState } from './enums';
import { IVacation } from './types';

export const makeNewVacation = (startSelection: string, endSelection: string): IVacation | null => {
  if (!startSelection || !endSelection) {
    return null;
  }

  return {
    id: 0,
    startDate: startSelection,
    endDate: endSelection,
    state: ResourseVacationState.new,
  };
};

export const makeOnApprovalVacations = (vacations: Array<IVacation>, newVacation: IVacation | null) => {
  const onApprovalVacations = vacations.filter(vacation => isVacationResourceOnApproval(vacation));
  return [...onApprovalVacations, newVacation].filter(Boolean);
};

export const isVacationResourceOnApproval = (vacation: IVacation | IReportVacationRequestResource) => {
  return (
    vacation.state === ResourseVacationState.onApproval ||
    vacation.state === ResourseVacationState.approvedOnProjectLevel
  );
};

export const isVacationResourceNew = (vacation: IVacation | IReportVacationRequestResource) => {
  return vacation.state === ResourseVacationState.new;
};

export const isVacationResourceCompleted = (vacation: IVacation | IReportVacationRequestResource) => {
  return vacation.state === ResourseVacationState.completed;
};

export const isVacationResourceApproved = (vacation: IVacation | IReportVacationRequestResource) => {
  return vacation.state === ResourseVacationState.approved;
};

export const isVacationResourceDeclined = (vacation: IVacation | IReportVacationRequestResource) => {
  return vacation.state === ResourseVacationState.declined;
};

export const isVacationRequestDeclined = (state: VacationApprovementState) => {
  return state === VacationApprovementState.declined;
};

export const isVacationRequestConfirmed = (state: VacationApprovementState) => {
  return state === VacationApprovementState.confirmed;
};
