import { FC, useContext, useState, useEffect, useCallback } from 'react';

import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks';

import Div from 'components/common/Div';
import ExportButton from 'components/common/ExportButton';
import Loader from 'components/common/Loader';
import ReportFiltersButton from 'components/common/ReportFiltersButton';
import SmartFilter from 'components/common/SmartFilter';
import { pickFieldsForSmartFilter } from 'components/common/SmartFilter/service';
import { ISmartFilter } from 'components/common/SmartFilter/types';

import { ModalContext } from 'contexts/ModalContext';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { useGetCurrentUserPermissionsQuery } from 'domain/currentUser/permission/apiSlice';
import { PermissionTargets } from 'domain/currentUser/permission/enums';
import { useLazyGetExportQuery } from 'domain/export/apiSlice';
import { ReportType } from 'domain/report/filter/enums';
import { IReportFilter } from 'domain/report/filter/types';
import {
  useGetReportHoursByProjectsQuery,
  IReportHoursByProjectsSmartFilters,
  useCreateCsvReportHoursByProjectsMutation,
  useCreateXlsxReportHoursByProjectsMutation,
} from 'domain/report/hoursByProject/apiSlice';

import { MeasurementUnitFilter } from 'enums/MeasurementUnitFilter';

import { useNotifications } from 'hooks/useNotifications';
import { usePermissions } from 'hooks/usePermissions';

import { IPaginationParameters } from 'types/api';
import { ISortParameter } from 'types/runsack';

import { convertDateToUTCString, getTimeRanges } from 'utils/dateTime';
import { generateBackendErrorMessages } from 'utils/error';
import { fileDownload } from 'utils/file';
import { defaultPaginationParameters } from 'utils/pagination';
import { getSortParameter } from 'utils/runsack';

import CreateProjectForm from './components/CreateProjectForm';
import Filters from './components/Filters';
import Table from './components/Table';
import {
  buildPathForResourceNameClick,
  buildPathForProjectPlannedHoursClick,
  buildPathForResourcePlannedHoursClick,
  buildPathForProjectActualHoursClick,
  buildPathForResourceActualHoursClick,
} from './service';
import styles from './styles';
import { IHoursByProjectsFilters } from './types';

const FILTERS: Array<ISmartFilter> = ['resource', 'trackable', 'state', 'billable', 'group', 'technology'];

const REPORT_TYPE = ReportType.hoursByProjects;

const HoursByProjects: FC = () => {
  const { t } = useTranslation('reportHoursByProjects');

  const { openModal } = useContext(ModalContext);

  const { camelizedQuery, queryStringUpdate, queryStringClear } = useRouter();

  const [smartFilter, setSmartFilter] = useState<IReportHoursByProjectsSmartFilters | null>(
    pickFieldsForSmartFilter(camelizedQuery, FILTERS),
  );

  const { data: currentUserPermissions, isLoading: isCurrentUserPermissionsLoading } =
    useGetCurrentUserPermissionsQuery({
      permissionTarget: PermissionTargets.hoursByProjects,
    });

  const canCreateProject = currentUserPermissions?.permissions.canCreateProject;

  const { data: currentUser } = useGetCurrentUserQuery();

  const timeRanges = getTimeRanges(currentUser?.user?.weekStartsDay ?? 1);

  const [filters, setFilters] = useState<IHoursByProjectsFilters>({
    measurementUnit: MeasurementUnitFilter.hours,
    startDate: timeRanges.startOfMonth,
    endDate: timeRanges.endOfMonth,
  });

  const [paginationParameters, setPaginationParameters] = useState<IPaginationParameters>({
    pageNumber: Number(camelizedQuery.page) || defaultPaginationParameters.pageNumber,
    pageSize: Number(camelizedQuery.perPage) || defaultPaginationParameters.pageSize,
  });

  const getMappedSmartFilters = useCallback(
    (smartFilter: IHoursByProjectsFilters | null) => {
      const { trackables, ...rest } = smartFilter ?? {};
      const trackablesMapped = trackables?.map(trackable => {
        const { id, type } = trackable;
        return { id, type };
      });
      return { trackables: trackablesMapped, ...rest };
    },
    [smartFilter],
  );

  const getReportHoursByProjectsQueryFiltersParameters = {
    detailed: true,
    perPage: paginationParameters.pageSize,
    page: paginationParameters.pageNumber,
    startDate: convertDateToUTCString(filters.startDate),
    endDate: convertDateToUTCString(filters.endDate),
    sort: (camelizedQuery.sort as ISortParameter) || getSortParameter('name', 'asc'),
  };

  useEffect(() => {
    const parameters = {
      ...getReportHoursByProjectsQueryFiltersParameters,
      ...getMappedSmartFilters(smartFilter),
    };
    queryStringUpdate(parameters);
  }, [filters, smartFilter, paginationParameters]);

  const {
    data: reportHoursByProjects,
    isLoading: isReportHoursByProjectsLoading,
    isFetching: isReportHoursByProjectsFetching,
    isError: isReportHoursByProjectsLoadingError,
  } = useGetReportHoursByProjectsQuery({
    ...getReportHoursByProjectsQueryFiltersParameters,
    ...getMappedSmartFilters(smartFilter),
  });

  const [createReportHoursByProjectsCsv, { isLoading: isCreateReportHoursByProjectsCsvLoading }] =
    useCreateCsvReportHoursByProjectsMutation();

  const [exportReportHoursByProjectsCsv, { isLoading: isExportReportHoursByProjectsCsvLoading }] =
    useLazyGetExportQuery();

  const [createReportHoursByProjectsXlsx, { isLoading: isCreateReportHoursByProjectsXlsxLoading }] =
    useCreateXlsxReportHoursByProjectsMutation();

  const [exportReportHoursByProjectsXlsx, { isLoading: isExportReportHoursByProjectsXlsxLoading }] =
    useLazyGetExportQuery();

  const { showErrorNotification } = useNotifications();

  const { isRoleFeatureAllowed } = usePermissions();

  const handleCreateProjectClick = () => {
    openModal({
      title: t('createProjectModalTitle'),
      content: <CreateProjectForm />,
    });
  };

  const pagination = {
    totalPages: reportHoursByProjects?.meta.totalPages,
    pageSize: reportHoursByProjects?.meta.perPage,
    pageNumber: reportHoursByProjects?.meta.currentPage,
  };

  if (isCurrentUserPermissionsLoading || isReportHoursByProjectsLoading) {
    return <Loader />;
  }

  const handleSmartFiltersChange = (filters: IReportHoursByProjectsSmartFilters | null) => {
    setSmartFilter(filters);
    setPaginationParameters({ pageNumber: defaultPaginationParameters.pageNumber, pageSize: pagination.pageSize });
  };

  const handleCsvDownload = async () => {
    try {
      const {
        export: { id: exportId },
      } = await createReportHoursByProjectsCsv({
        ...getReportHoursByProjectsQueryFiltersParameters,
        ...smartFilter,
      }).unwrap();

      const reportHoursByProjectsCsv = await exportReportHoursByProjectsCsv(exportId).unwrap();

      fileDownload(reportHoursByProjectsCsv);
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleXlsxDownload = async () => {
    try {
      const {
        export: { id: exportId },
      } = await createReportHoursByProjectsXlsx({
        ...getReportHoursByProjectsQueryFiltersParameters,
        ...smartFilter,
      }).unwrap();

      const reportHoursByProjectsXlsx = await exportReportHoursByProjectsXlsx(exportId).unwrap();

      fileDownload(reportHoursByProjectsXlsx);
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const isDisabled =
    isCreateReportHoursByProjectsCsvLoading ||
    isExportReportHoursByProjectsCsvLoading ||
    isCreateReportHoursByProjectsXlsxLoading ||
    isExportReportHoursByProjectsXlsxLoading;

  const hasAccessToData = isRoleFeatureAllowed('canViewHoursByProjectsReport');

  const handleReportFiltersChange = (reportFilters: IReportFilter | null) => {
    const filter = JSON.parse(reportFilters.filter);
    handleSmartFiltersChange(filter);
  };

  return (
    <>
      <Div sx={styles.titleBlock}>
        <Typography variant="h1">{t('title')}</Typography>
        <Div>
          {canCreateProject && (
            <Button sx={styles.createButton} onClick={handleCreateProjectClick}>
              {t('createProjectButton')}
            </Button>
          )}
          <ExportButton isDisabled={isDisabled} onCsvDownload={handleCsvDownload} onXlsxDownload={handleXlsxDownload} />
          <ReportFiltersButton onChange={handleReportFiltersChange} currentReportType={REPORT_TYPE} />
        </Div>
      </Div>

      <>
        <Div sx={styles.smartFilter}>
          <SmartFilter<IReportHoursByProjectsSmartFilters>
            smartFilter={smartFilter}
            onSmartFilterChange={handleSmartFiltersChange}
            filters={FILTERS}
            onReset={queryStringClear}
            reportType={REPORT_TYPE}
          />
        </Div>

        <Filters filters={filters} setFilters={setFilters} meta={reportHoursByProjects?.meta} />

        <Table
          isError={isReportHoursByProjectsLoadingError}
          data={reportHoursByProjects?.report?.trackables}
          totalHours={reportHoursByProjects?.totalHours}
          columnTitles={reportHoursByProjects?.columnTitles}
          isLoading={isReportHoursByProjectsFetching}
          pagination={pagination}
          setPaginationParameters={setPaginationParameters}
          buildPathForResourceNameClick={buildPathForResourceNameClick}
          buildPathForProjectPlannedHoursClick={buildPathForProjectPlannedHoursClick}
          buildPathForResourcePlannedHoursClick={buildPathForResourcePlannedHoursClick}
          buildPathForProjectActualHoursClick={buildPathForProjectActualHoursClick}
          buildPathForResourceActualHoursClick={buildPathForResourceActualHoursClick}
          hasAccessToData={hasAccessToData}
        />
      </>
    </>
  );
};

export default HoursByProjects;
