import { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DateRangePicker from 'components/common/DateRangePicker';
import { DateRange } from 'components/common/DateRangePicker/types';
import Div from 'components/common/Div';
import MeasurementUnitFilters from 'components/common/MeasurementUnitFilters';

import { MeasurementUnitFilter } from 'enums/MeasurementUnitFilter';

import { getDateRange, RangeType } from 'utils/dateRangePicker';

import styles from './styles';
import { IFiltersProps } from './types';

const Filters: FC<IFiltersProps> = props => {
  const { filters, setFilters, meta } = props;

  const onDateRangeChange = (dateRange: DateRange | null) => {
    setFilters({
      ...filters,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    });
  };

  const { t } = useTranslation('reportHoursByProjects');

  const handleMeasurementUnitFilterChange = (measurementUnit: MeasurementUnitFilter) => {
    setFilters({ ...filters, measurementUnit });
  };

  return (
    <Div sx={styles.filters}>
      <Typography variant="h2">{t('filters.projects', { count: meta?.totalCount })}</Typography>
      <Div sx={styles.dateRangePickerBlock}>
        <DateRangePicker
          currentDateRange={getDateRange(filters.startDate, filters.endDate, RangeType.month)}
          onDateRangeChange={onDateRangeChange}
        />
        <MeasurementUnitFilters
          selectedMeasurementUnit={filters.measurementUnit}
          onSelectedMeasurementUnitChange={handleMeasurementUnitFilterChange}
        />
      </Div>
    </Div>
  );
};

export default Filters;
