import { FC } from 'react';

import { FormControlLabel, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DateRangePicker from 'components/common/DateRangePicker';
import { DateRange } from 'components/common/DateRangePicker/types';
import Div from 'components/common/Div';
import PtoFilter from 'components/common/PtoFilter';

import { HoursType } from 'enums/HoursType';
import { PtoType } from 'enums/PtoType';

import { getDateRange, RangeType } from 'utils/dateRangePicker';

import HoursTypeFilter from './components/HoursTypeFilter';
import styles from './styles';
import { IHoursByResourcesFiltersProps } from './types';

const Filters: FC<IHoursByResourcesFiltersProps> = props => {
  const { filters, setFilters, resourcesTotalCount } = props;

  const { t } = useTranslation('reportHoursByResources');

  const handleDetailedInfoViewSwitch = () => {
    setFilters({ ...filters, isDetailedInfo: !filters.isDetailedInfo });
  };

  const onDateRangeChange = (dateRange: DateRange | null) => {
    setFilters({
      ...filters,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    });
  };
  const handlePtoFilterChange = (event: SelectChangeEvent) => {
    setFilters({ ...filters, pto: event.target.value as PtoType });
  };
  const handleHoursTypeFilterChange = (hoursType: HoursType) => {
    setFilters({ ...filters, hoursType: hoursType });
  };

  return (
    <Div sx={styles.root}>
      <Div>
        <Typography variant="h2">{t('filter.resources', { count: resourcesTotalCount })}</Typography>
      </Div>
      <Div sx={styles.mainFilters}>
        <Div>
          <FormControlLabel
            control={<Switch checked={filters.isDetailedInfo} onChange={handleDetailedInfoViewSwitch} />}
            label={<Typography variant="subtitle1">{t('filter.detailedInfoLabel')}</Typography>}
          />
        </Div>

        <Div sx={styles.dateRangePickerBlock}>
          <DateRangePicker
            currentDateRange={getDateRange(filters.startDate, filters.endDate, RangeType.month)}
            onDateRangeChange={onDateRangeChange}
          />
        </Div>

        <HoursTypeFilter value={filters.hoursType} onChange={handleHoursTypeFilterChange} />
        <PtoFilter value={filters.pto} onChange={handlePtoFilterChange} />
      </Div>
    </Div>
  );
};

export default Filters;
