import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    marginY: 4,
    marginX: 0,
  },
  header: {
    display: 'block',
    marginBottom: 2,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  yearInfo: {
    width: '240px',
    marginRight: 2.75,
    flexShrink: 0,
  },
  calendar: {
    width: '100%',
  },
  days: {
    display: 'flex',
  },
  week: {
    display: 'flex',
  },
  cell: {
    width: '32px',
    height: '24px',
  },
  month: {
    marginBottom: 1.25,
  },
  yearSwitcher: {
    marginBottom: 2.75,
  },
};

export default styles;
