import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '816px',
    paddingTop: 1,
    gap: 2,
  },
  topRow: {
    display: 'flex',
    gap: '16px',
    width: '100%',
  },
  nameField: {
    width: '50%',
  },
  periodTypeField: {
    width: '50%',
  },
  monthlyBudgetField: {
    width: '50%',
  },
  descriptionField: {
    width: '100%',
  },
  field: {
    width: '100%',
  },
  technologiesField: {
    width: '100%',
  },
  groupsField: {
    width: '50%',
  },
  shortField: {
    width: '192px',
  },
  placeholder: {
    color: 'text.gray',
  },
  buttons: {
    marginTop: 4,
  },
  createProjectButton: {
    marginRight: 1.25,
  },
  clearButton: {
    width: '24px',
    height: '24px',
  },
  clearIcon: {
    width: '17px',
    height: '17px',
    stroke: theme => theme.palette.primary.gray,
  },
  switches: {
    paddingLeft: 1.375,
  },
  privacyField: {
    width: '50%',
  },
  subRow: {
    display: 'flex',
    gap: '16px',
    width: '50%',
  },
};

export default styles;
