import { provideTags, TagTypes } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { IUserProfileStackAbility } from './types';

interface IUserProfileStackResponse {
  abilities: Array<IUserProfileStackAbility>;
  meta: IMeta;
}

interface IUserProfileStackQueryParameters {
  nameCont?: string;
}

interface IUserProfileStackParameters {
  userId: number;
  page?: number;
  perPage?: number;
  q?: IUserProfileStackQueryParameters;
}

export const userProfileStackApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getUserProfileStack: builder.query<IUserProfileStackResponse, IUserProfileStackParameters>({
      query: ({ userId, ...parameters }) => ({
        url: apiRoutes.apiV1UserProfileStackIndexPath(userId),
        method: 'GET',
        params: {
          ...parameters,
        },
      }),
      providesTags: response => provideTags(TagTypes.UserProfileStack, response?.abilities),
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserProfileStackQuery } = userProfileStackApi;
