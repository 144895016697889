import {
  ISortDirection,
  ISortFieldName,
  ISortParameter,
  ISortParameterWithRelation,
  ISortRelation,
} from 'types/runsack';

export const getSortParameter = (fieldName: ISortFieldName, direction: ISortDirection): ISortParameter => {
  return `${fieldName} ${direction}`;
};

export const getSortParameterWithRelation = (
  fieldName: ISortFieldName,
  direction: ISortDirection,
  relation: ISortRelation,
): ISortParameterWithRelation => {
  return `${relation}_${fieldName} ${direction}`;
};
