import { FC, useContext, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Alert, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import Loader from 'components/common/Loader';

import { ModalContext } from 'contexts/ModalContext';

import { loadingGetProjectTasksParameters, useGetProjectTasksQuery } from 'domain/project/task/apiSlice';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';

import CreateProjectTaskForm from './components/CreateProjectTaskForm';
import TasksList from './components/TasksList';
import styles from './styles';
import { IProjectTasksProps } from './types';

const ProjectTasks: FC<IProjectTasksProps> = props => {
  const { projectId, canAddTasks, canUpdateTasks } = props;

  const { t } = useTranslation(['common', 'reportHoursByProject']);

  const { openModal } = useContext(ModalContext);

  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [debouncedSearchInputValue] = useDebounce(searchInputValue, DEFAULT_DEBOUNCE_DELAY_MS);

  const projectTasksQueryParameters = loadingGetProjectTasksParameters({
    projectId,
    nameCont: debouncedSearchInputValue,
  });
  const {
    data: tasksData,
    isFetching: isTasksDataFetching,
    isError: isTasksDataLoadingError,
    isSuccess: isTasksDataLoadingSuccess,
  } = useGetProjectTasksQuery(projectTasksQueryParameters);

  const handleCreateTaskModalOpen = () => {
    openModal({
      title: t('reportHoursByProject:projectTasks.createModalTitle'),
      content: <CreateProjectTaskForm projectId={projectId} />,
    });
  };

  const needShowList = !isTasksDataFetching && isTasksDataLoadingSuccess && !isTasksDataLoadingError;

  if (isTasksDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  return (
    <Div>
      <Div sx={styles.actions}>
        <TextField
          sx={styles.search}
          placeholder="Search by Task Name"
          value={searchInputValue}
          onChange={event => setSearchInputValue(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {_.isEmpty(searchInputValue) ? (
                  <IconButton disabled>
                    <SearchIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setSearchInputValue('')}>
                    <Icon name="closeWithDarkCircle" />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />

        {canAddTasks && (
          <Button type="button" sx={styles.addTasksButton} onClick={handleCreateTaskModalOpen}>
            {t('reportHoursByProject:projectTasks.addTaskButton')}
          </Button>
        )}
      </Div>
      {isTasksDataFetching && <Loader />}
      {needShowList && <TasksList tasks={tasksData.tasks} projectId={projectId} canUpdateTasks={canUpdateTasks} />}
    </Div>
  );
};

export default ProjectTasks;
