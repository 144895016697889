import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { convertDateToUTCString } from 'utils/dateTime';

export interface ICreateUserHoursPerWeekFormData {
  weeklyHours: number;
  startDate: Date;
  endDate?: Date;
}

export interface ICreateUserHoursPerWeekPreparedFormData
  extends Omit<ICreateUserHoursPerWeekFormData, 'weeklyHours' | 'startDate' | 'endDate'> {
  startDate: string;
  endDate: string | null;
}
export interface ICreateUserHoursPerWeekFormDataToSubmit {
  userId: number;
  formData: {
    hoursPerWeek: ICreateUserHoursPerWeekPreparedFormData;
  };
}

const requiredValidation = 'forms:validation:required';

export const buildValidationFields = t => ({
  startDate: yup
    .date()
    .typeError(t('forms:validation:dateFormat'))
    .required(i18n.t(requiredValidation))
    .default(new Date()),
  endDate: yup
    .date()
    .typeError(t('forms:validation:dateFormat'))
    .nullable()
    .min(yup.ref('startDate'), t('forms:validation:endDateBeforeStartBillableDate'))
    .default(null),
  weeklyHours: yup.number().min(0).max(40).required(i18n.t(requiredValidation)).default(40),
});

export const buildValidationSchema = () => {
  return yup.object(buildValidationFields(i18n.t));
};

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (
  formData: ICreateUserHoursPerWeekFormData,
  userId: number,
): ICreateUserHoursPerWeekFormDataToSubmit => {
  const { startDate, endDate, ...values } = formData;
  const normalizeStartDate = convertDateToUTCString(startDate);
  const normalizeEndDate = _.isNil(endDate) ? null : convertDateToUTCString(endDate);

  return {
    userId,
    formData: {
      hoursPerWeek: {
        startDate: normalizeStartDate,
        endDate: normalizeEndDate,
        ...values,
      },
    },
  };
};
