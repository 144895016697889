import { FC, useState } from 'react';

import { ExpandMoreOutlined } from '@mui/icons-material';
import { Autocomplete, Chip, CircularProgress, TextField, Typography } from '@mui/material';

import Icon from 'components/common/Icon';

import { IGroup } from 'domain/group/types';

import styles from './styles';
import { IGroupsAutocompleteProps } from './types';

const GroupsAutocomplete: FC<IGroupsAutocompleteProps> = props => {
  const {
    currentGroup,
    onCurrentGroupChange,
    groups,
    isError,
    setGroupName,
    isGroupsFetching,
    label,
    isRequired = false,
    helperText = '',
  } = props;

  const [isAutoCompleteOpen, setIsAutocompleteOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const handleGroupsAutocompleteChange = (value: string) => {
    if (value !== inputValue) {
      setInputValue(value);
      setGroupName(value);
    }
  };

  const handleAutocompleteOpen = () => {
    setIsAutocompleteOpen(true);
  };

  const handleAutocompleteClose = () => {
    setIsAutocompleteOpen(false);
  };

  return (
    <Autocomplete
      open={isAutoCompleteOpen}
      onOpen={handleAutocompleteOpen}
      onClose={handleAutocompleteClose}
      popupIcon={<ExpandMoreOutlined />}
      autoComplete
      getOptionLabel={(group: IGroup) => group.ancestorsPath ?? ''}
      isOptionEqualToValue={(option: IGroup, value: IGroup) => option.id === value.id}
      options={groups}
      sx={styles.select}
      onChange={(_, group: IGroup) => onCurrentGroupChange(group)}
      value={currentGroup}
      onInputChange={(_, value) => handleGroupsAutocompleteChange(value)}
      renderInput={parameters => (
        <TextField
          required={isRequired}
          helperText={helperText}
          error={isError}
          {...parameters}
          label={label}
          InputLabelProps={{ sx: styles.selectPlaceholder }}
          InputProps={{
            ...parameters.InputProps,
            endAdornment: (
              <>
                {isGroupsFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {parameters.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, group: IGroup) => {
        return (
          <Typography {...props} key={group.id}>
            {group.ancestorsPath}
          </Typography>
        );
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => {
          return (
            <Chip
              key={option.id}
              deleteIcon={<Icon sx={styles.closeChipIcon} name="closeWithoutCircle" />}
              sx={styles.chip}
              {...getTagProps({ index })}
              label={<Typography variant="subtitle2">{option.ancestorsPath}</Typography>}
            />
          );
        });
      }}
    />
  );
};
export default GroupsAutocomplete;
