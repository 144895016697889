import { SxStyles } from 'theme';

const styles: SxStyles = {
  navigation: {
    paddingY: 2.375,
    paddingX: 0,
  },
};

export default styles;
