import { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { TextField, Button, InputAdornment, IconButton } from '@mui/material';
import MUISx from 'mui-sx';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { buildValidationSchema, INewPasswordFormData } from './schema';
import styles from './styles';

const onSubmit = () => {};

const Form: FC = () => {
  const { t } = useTranslation('newPassword');

  const [isVissibilityPassword, setIsVisibilityPassword] = useState<boolean>(false);
  const [isVissibilityPasswordConfirmation, setIsVisibilityPasswordConfirmation] = useState<boolean>(false);

  const handleSwitchVisibilityPasswordClick = () => {
    setIsVisibilityPassword(!isVissibilityPassword);
  };

  const handleSwitchVisibilityPasswordConfirmationClick = () => {
    setIsVisibilityPasswordConfirmation(!isVissibilityPasswordConfirmation);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<INewPasswordFormData>({
    resolver: yupResolver(buildValidationSchema()),
  });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <TextField
        sx={MUISx(styles.input, styles.passwordInput)}
        {...register('password')}
        placeholder={t('newPassword:form.passwordField.placeholder')}
        error={Boolean(errors.password)}
        helperText={errors?.password?.message}
        fullWidth
        autoComplete="current-password"
        type={isVissibilityPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleSwitchVisibilityPasswordClick}
                edge="end"
              >
                {isVissibilityPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        sx={MUISx(styles.input, styles.passwordConfirmationInput)}
        {...register('passwordConfirmation')}
        placeholder={t('newPassword:form.passwordConfirmationField.placeholder')}
        error={Boolean(errors.passwordConfirmation)}
        helperText={errors?.passwordConfirmation?.message}
        fullWidth
        autoComplete="current-password"
        type={isVissibilityPasswordConfirmation ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleSwitchVisibilityPasswordConfirmationClick}
                edge="end"
              >
                {isVissibilityPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Button sx={styles.button} fullWidth type="submit" disabled={isSubmitting}>
        {t('newPassword:form.setNewPasswordButton')}
      </Button>
    </form>
  );
};

export { Form };
