import { SxStyles } from 'theme';

const styles: SxStyles = {
  addButton: {
    paddingX: 2.5,
    paddingY: 1,
  },
};

export default styles;
