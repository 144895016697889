import decamelize from 'decamelize';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { AccessibleType, Permission } from 'domain/accessLevel/enums';
import { IAccessLevel } from 'domain/accessLevel/types';
import { ITechnology } from 'domain/technology/types';

export interface IUpdateAccessLevelFormData {
  userId?: number;
  accessibleId?: number;
  accessibleType?: AccessibleType;
  permission?: Permission;
  technologies?: Array<ITechnology>;
}

export interface IPreparedUpdateAccessLevelFormData extends Omit<IUpdateAccessLevelFormData, 'technologies'> {
  technologyIds?: Array<number>;
}

export interface IUpdateAccessLevelFormDataToSubmit {
  accessLevelId: number;
  formData: IPreparedUpdateAccessLevelFormData;
}

const requiredField = 'forms:validation:required';

const buildValidationFields = () => ({
  userId: yup.number().positive().required(i18n.t(requiredField)).default(0),
  accessibleId: yup.number().positive().required(i18n.t(requiredField)).default(0),
  accessibleType: yup.mixed<AccessibleType>().required(i18n.t(requiredField)).default(AccessibleType.group),
  permission: yup.mixed<Permission>().required(i18n.t(requiredField)).default(Permission.developer),
  technologies: yup.array().of(yup.mixed<ITechnology>()).required(i18n.t(requiredField)).default([]),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (accessLevel: IAccessLevel) => {
  const { userId, accessibleId, accessibleType, permission, technologies } = accessLevel;
  return {
    userId,
    accessibleId,
    accessibleType,
    permission,
    technologies,
  };
};

export const formatAttributesToSubmit = (
  formData: IUpdateAccessLevelFormData,
  accessLevelId: number,
): IUpdateAccessLevelFormDataToSubmit => {
  const { technologies, permission, ...values } = formData;

  const technologyIds = technologies.map(technology => technology.id);
  const decamelizedPermission = decamelize(permission);
  return {
    accessLevelId,
    formData: {
      technologyIds,
      permission: decamelizedPermission,
      ...values,
    },
  };
};
