export default {
  title: 'Admin panel',
  tabs: {
    hierarchy: 'Hierarchy',
    specializations: 'Specializations & Stacks',
    workingCalendars: 'Working Calendars',
    projectGroups: 'Project Groups',
    companyInfo: 'Company Info',
    companySettings: 'Company Settings',
    trackingTypes: 'Tracking Types',
    tags: 'Tags',
    forbiddenWords: 'Forbidden Words',
  },
  buttons: {
    addNode: 'Add Node',
    editNode: 'Edit Node',
    edit: 'Edit',
    archive: 'Archive',
    activate: 'Activate',
    delete: 'Delete',
    cancel: 'Cancel',
    rename: 'Rename',
  },
  hierarchy: {
    title: 'Global',
    searchBar: 'Search by Node Name',
    searchBarButton: 'Add Node',
    add: 'Add Hierarchy Node',
    edit: 'Edit Hierarchy Node',
    inputName: 'Hierarchy Node Name',
    inputParent: 'Parent Entity',
    calendar: 'Calendar',
  },
  root: 'Root',
  archived: 'Archived',
  specializations: {
    deleteMessage: 'Are you sure you want to delete {{technologyName}} technology?',
    searchBar: 'Search by Specializations & Stacks',
    addButton: 'Add Node',
    add: 'Add Specializaion/Stack',
    edit: 'Edit Specialization/Stack',
    inputName: 'Stack Name',
    inputParent: 'Parent Entities',
    addStack: 'Add Stack',
    saveStack: 'Save Stack',
    editStack: 'Edit Stack',
  },
  workingCalendars: {
    noCalendars: 'No working calendars for {{yearNumber}} year',
    addNew: 'Add New Calendar',
    rename: 'Rename',
    delete: 'Delete',
    deleteCalendar: 'Are you sure you want to delete {{calendarName}} calendar?',
    save: 'Save',
    cancel: 'Cancel',
    workingDays: 'Working: ',
    holidays: 'Holidays: ',
    days_one: '{{ count }} day',
    days_other: '{{ count }} days',
    setAsHoliday: 'Set as Holiday',
    setAsWorkingDay: 'Set as Working Day',
    setAsWeekend: 'Set as Weekend',
    typeHolidayName: 'Type the Holiday Name',
  },
  trackingTypes: {
    billable: 'Billable',
    nonBillable: 'Non-Billable',
    mainListItem: {
      createTrackingTypeFormTitle: 'Add Tracking Type',
      addNewCategoryButton: 'Add New Category',
      innerListItem: {
        editTrackingTypeFormTitle: 'Edit Tracking Type',
        archived: 'Archived',
        buttons: {
          rename: 'Rename',
          delete: 'Delete',
          activate: 'Activate',
          archive: 'Archive',
        },
        updateTrackingTypeForm: {
          name: {
            label: 'name',
          },
          buttons: {
            edit: 'Edit Tracking Type',
            cancel: 'Cancel',
          },
        },
      },
      createTrackingTypeForm: {
        name: {
          label: 'name',
        },
        buttons: {
          add: 'Add Tracking Type',
          cancel: 'Cancel',
        },
      },
    },
  },
  tags: {
    disabled: 'Disabled',
    searchBar: 'Search by Tag',
    addButton: 'Add Tag',
    addTag: 'Add Tag',
    createTagForm: {
      title: 'Add Tag',
    },
    updateTagForm: {
      title: 'Edit Tag',
    },
    tagForm: {
      name: {
        label: 'name',
      },
    },
  },
  forbiddenWords: {
    deleteMessage: 'Are you sure you want to delete <1>{{ word }}</1> word?',
    searchBar: 'Search by Forbidden Word',
    addButton: 'Add Word',
    add: 'Add Forbidden Word',
    inputName: 'Forbidden Word',
    addWord: 'Add Word',
  },
  companySettings: {
    updateCompanySettingsForm: {
      title: 'Edit Company Settings',
    },
    companySettingsForm: {
      date: {
        label: 'lock date',
      },
      hoursPeerWeek: {
        label: 'Default hours per week',
      },
      expectedPercentage: {
        label: 'Default expected percentage',
      },
      duplicationCheck: {
        label: 'Duplication check',
        AI: 'AI',
        internal: 'internal',
      },
      defaultPrecisionForDuplicationCheck: {
        label: 'Default precision for duplication check',
      },
      uniquenessCheckStrategy: {
        label: 'Uniqueness check strategy',
        doNotCheck: 'Do not check',
        markAsDuplicate: 'Mark as duplicate',
        block: 'Block',
      },
    },
  },
};
