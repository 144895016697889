import { FC } from 'react';

import { Typography } from '@mui/material';

import Div from 'components/common/Div';

import { getFullName } from 'utils/person';

import UserActionsMenu from './components/UserActionsMenu';
import styles from './styles';
import { IUsersTableNameCellProps } from './types';

const TableNameCell: FC<IUsersTableNameCellProps> = props => {
  const { user } = props;

  const fullName = getFullName(user);

  return (
    <Div sx={styles.root}>
      <Typography variant="subtitle1">{fullName}</Typography>
      <UserActionsMenu user={user} />
    </Div>
  );
};

export default TableNameCell;
