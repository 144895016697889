import _ from 'lodash';
import * as yup from 'yup';

export interface IDescriptionFormData {
  description: string;
  blank: boolean;
}

export const buildValidationFields = () => ({
  description: yup.string().max(300).nullable().default(null),
  blank: yup.boolean().default(false),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (form: IDescriptionFormData) => {
  return _.isNil(form)
    ? buildValidationSchema().getDefault()
    : {
        description: form.description,
        blank: form.blank,
      };
};
