import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    paddingTop: 1.25,
  },
  input: {
    minWidth: '400px',
    width: '100%',
    marginBottom: 4,
  },
  placeholder: {
    color: 'text.gray',
  },
  groupFields: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  datePicker: {
    marginTop: 2,
    marginBottom: 4,
  },
  startDate: {
    marginRight: 2,
  },
  updateButton: {
    marginRight: 2,
  },
};

export default styles;
