import { SxStyles } from 'theme';

const styles: SxStyles = {
  impersonation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '20px',
    marginBottom: 2,
    borderRadius: '3px',
    backgroundColor: 'background.red',
    color: 'primary.contrastText',
  },
  impersonationText: {
    textTransform: 'uppercase',
  },
};

export default styles;
