import { Dispatch, SetStateAction, useEffect } from 'react';

import { startOfDay, addDays, differenceInMilliseconds, isAfter } from 'date-fns';

import { IWeekStartsOn } from 'types/dates';

import { MILLISECONDS_IN_DAY } from 'utils/constants';
import { getStaticRanges } from 'utils/dateRangePicker';

export function useStaticDateRangeEffect(
  setStaticRanges: Dispatch<SetStateAction<Array<StaticRange>>>,
  weekStartsOn: IWeekStartsOn,
) {
  useEffect(() => {
    const refreshStaticRanges = () => {
      setStaticRanges(getStaticRanges(weekStartsOn));
    };

    let timeTabWasHidden = startOfDay(new Date());
    let timeTabWasVisible = startOfDay(new Date());

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        timeTabWasVisible = startOfDay(new Date());
        if (isAfter(timeTabWasVisible, timeTabWasHidden)) {
          refreshStaticRanges();
        }
      } else {
        timeTabWasHidden = startOfDay(new Date());
      }
    };

    const setupVisibilityListener = () => {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    };

    const setupRefreshTimer = () => {
      const now = new Date();
      const midnight = startOfDay(addDays(now, 1));
      const timeUntilMidnight = differenceInMilliseconds(midnight, now);
      const timerId = setTimeout(refreshStaticRanges, timeUntilMidnight);
      return () => {
        clearTimeout(timerId);
      };
    };

    const setupRefreshInterval = () => {
      const intervalId = setInterval(refreshStaticRanges, MILLISECONDS_IN_DAY);
      return () => {
        clearInterval(intervalId);
      };
    };

    const cleanupFunctions = [setupVisibilityListener(), setupRefreshTimer(), setupRefreshInterval()];

    return () => {
      for (const cleanup of cleanupFunctions) cleanup();
    };
  }, [setStaticRanges]);
}
