import _ from 'lodash';

import { getTaskWithProject } from 'domain/project/task/presenter';

import { SuggestionAutocompleteType } from 'enums/SuggestionAutocomplete';

import { IAutocompleteOption } from 'types/autocomplete';
import { IPerson, ITree } from 'types/common';

import { getFullName } from './person';
import { getAncestorsPath } from './tree';

interface IGetPreparedDataForSuggestionAutocompleteOptions<T> {
  data: Array<T>;
  type?: SuggestionAutocompleteType;
  isTree?: boolean;
}

export const getDataForSuggestionAutocomplete = (data, type: SuggestionAutocompleteType) => {
  const mapSuggestionTypeToFunction = {
    [SuggestionAutocompleteType.common]: data => data.name,
    [SuggestionAutocompleteType.tree]: data => getAncestorsPath(data as ITree),
    [SuggestionAutocompleteType.person]: data => getFullName(data as IPerson),
    [SuggestionAutocompleteType.calendar]: data => data.calendarName,
    [SuggestionAutocompleteType.task]: data => getTaskWithProject(data),
  };

  return mapSuggestionTypeToFunction[type](data);
};

export const getPreparedDataForSuggestionAutocomplete = <T extends IAutocompleteOption>(
  options: IGetPreparedDataForSuggestionAutocompleteOptions<T>,
): Array<IAutocompleteOption> => {
  const { data, type = SuggestionAutocompleteType.common } = options;

  return data
    .map(option => {
      const name = getDataForSuggestionAutocomplete(option, type);
      return { id: option.id, name: name, state: option.state };
    })
    .filter(option => !_.isNil(option.id));
};
