export enum FilterPredicate {
  eq = 'eq',
  noteq = 'noteq',
  in = 'in',
  lt = 'lt',
  gt = 'gt',
  lteq = 'lteq',
  gteq = 'gteq',
  cont = 'cont',
  blank = 'blank',
  null = 'null',
}
