import { FC } from 'react';

import Loader from 'components/common/Loader';
import VacationRequestTables from 'components/pages/Report/Vacations/components/VacationRequests/components/VacationRequestItem/components/VacationRequestExpandedContent/components/VacationRequestTabs/components/VacationRequestTables/index';
import { IVacationRequestTabsProps } from 'components/pages/Report/Vacations/components/VacationRequests/components/VacationRequestItem/components/VacationRequestExpandedContent/components/VacationRequestTabs/types';

import { useGetReportVacationRequestsProjectsQuery } from 'domain/report/vacationRequest/apiSlice';

const VacationRequestProjectsTab: FC<IVacationRequestTabsProps> = props => {
  const { vacationsEndDate, vacationsRequestId, vacationsRequestName, vacationsRequestYear, vacationsStartDate } =
    props;

  const {
    data: vacationRequestProjects,
    isLoading,
    isFetching,
  } = useGetReportVacationRequestsProjectsQuery({ vacationsRequestId });

  return isLoading || isFetching ? (
    <Loader />
  ) : (
    vacationRequestProjects?.projects?.map(project => (
      <VacationRequestTables
        key={`project${project.id}`}
        tableName={project.name}
        year={vacationsRequestYear}
        endDate={vacationsEndDate}
        startDate={vacationsStartDate}
        resources={project.resources}
        currentResourceName={vacationsRequestName}
      />
    ))
  );
};
export default VacationRequestProjectsTab;
