import { IAccessLevel } from 'domain/accessLevel/types';
import { IUserProfileAccessLevel } from 'domain/user/profile/accessLevel/types';
import { IUser } from 'domain/user/types';

export const prepareUserProfileAccessLevelToUpdate = (
  userProfileAccessLevel: IUserProfileAccessLevel,
  user: IUser,
): IAccessLevel => {
  return {
    id: userProfileAccessLevel.id,
    userId: user.id,
    userFirstName: user.firstName,
    userLastName: user.lastName,
    accessibleId: userProfileAccessLevel.accessibleId,
    accessibleType: userProfileAccessLevel.accessibleType,
    accessibleName: userProfileAccessLevel.accessibleName,
    permission: userProfileAccessLevel.permission,
    technologies: userProfileAccessLevel.technologies,
  };
};
