import { TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IVacationApprovements } from '../../report/vacationRequest/types';

import { ICreateResourceVacationFormDataToSubmit } from './schemas/create';
import { IUpdateResourceVacationFormDataToSubmit } from './schemas/update';
import { IVacation, IVacationTotal } from './types';

interface IGetResourceVacationsParameters {
  resourceId: number;
  forYear: number;
}

interface IResourceVacationParameters {
  id: number;
  resourceId: number;
}

interface IResourceVacationApprovementsParameters {
  id: number;
  resourceId: number;
  vacationId: number;
}

interface IResourceVacationsResponse {
  vacations: Array<IVacation>;
  totals: IVacationTotal;
}

interface IResourceVacationResponse {
  vacation: IVacation;
}

interface IResourceVacationApprovementsResponse {
  vacationApprovement: IVacationApprovements;
}

export const resourceVacationsApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getResourceVacations: builder.query<IResourceVacationsResponse, IGetResourceVacationsParameters>({
      query: ({ resourceId, ...parameters }) => {
        return {
          url: apiRoutes.apiV1ResourceVacationsPath(resourceId),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ResourceVacation, response?.vacations),
    }),
    createResourceVacation: builder.mutation<IResourceVacationResponse, ICreateResourceVacationFormDataToSubmit>({
      query: ({ resourceId, formData }) => ({
        url: apiRoutes.apiV1ResourceVacationsPath(resourceId),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: [TagTypes.ResourceVacation],
    }),
    updateResourceVacation: builder.mutation<IResourceVacationResponse, IUpdateResourceVacationFormDataToSubmit>({
      query: ({ id, resourceId, formData }) => ({
        url: apiRoutes.apiV1ResourceVacationPath(resourceId, id),
        method: 'PUT',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: ({ vacation }) => [{ type: TagTypes.ResourceVacation, id: vacation.id }],
    }),
    deleteResourceVacation: builder.mutation<void, IResourceVacationParameters>({
      query: ({ id, resourceId }) => ({
        url: apiRoutes.apiV1ResourceVacationPath(resourceId, id),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.ResourceVacation, TagTypes.ReportVacationRequest],
    }),
    declineResourceVacation: builder.mutation<
      IResourceVacationApprovementsResponse,
      IResourceVacationApprovementsParameters
    >({
      query: ({ id, resourceId, vacationId }) => ({
        url: apiRoutes.declineApiV1ResourceVacationApprovementPath(resourceId, vacationId, id),
        method: 'PUT',
      }),
      invalidatesTags: [TagTypes.ResourceVacation, TagTypes.ReportVacationRequest],
    }),
    approveResourceVacation: builder.mutation<
      IResourceVacationApprovementsResponse,
      IResourceVacationApprovementsParameters
    >({
      query: ({ id, resourceId, vacationId }) => ({
        url: apiRoutes.approveApiV1ResourceVacationApprovementPath(resourceId, vacationId, id),
        method: 'PUT',
      }),
      invalidatesTags: [TagTypes.ResourceVacation, TagTypes.ReportVacationRequest],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateResourceVacationMutation,
  useDeleteResourceVacationMutation,
  useGetResourceVacationsQuery,
  useUpdateResourceVacationMutation,
  useDeclineResourceVacationMutation,
  useApproveResourceVacationMutation,
} = resourceVacationsApi;
