import { SxStyles } from 'theme';

const styles: SxStyles = {
  selectPlaceholder: {
    color: 'text.gray',
  },
  inputIcon: {
    color: 'text.lightGray',
  },
};

export default styles;
