import { FC, useState } from 'react';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Menu, Button, MenuItem, Typography, Alert } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { useGetReportFiltersQuery, useDeleteReportFilterMutation } from 'domain/report/filter/apiSlice';
import { ReportType } from 'domain/report/filter/enums';
import { IReportFilter } from 'domain/report/filter/types';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { getSortParameter } from 'utils/runsack';

import styles from './styles';
import { IReportFiltersButtonProps } from './types';

const ReportFiltersButton: FC<IReportFiltersButtonProps> = props => {
  const { onChange, currentReportType } = props;

  const { t } = useTranslation('smartFilter');

  const [reportFiltersMenuElement, setReportFiltersMenuElement] = useState<null | HTMLElement>(null);

  const [selectedFilterId, setSelectedFilterId] = useState(null);
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const { data: currentUser, isLoading: isCurrentUserLoading, isError: isCurrentUserError } = useGetCurrentUserQuery();

  const [deleteReportFilter, { isLoading: isDeleteReportFilterLoading }] = useDeleteReportFilterMutation();

  const {
    data: reportFiltersData,
    isLoading: isReportFiltersDataLoading,
    isError: isReportFiltersDataLoadingError,
  } = useGetReportFiltersQuery({
    userIdEq: currentUser.user.id,
    reportTypeEq: ReportType[currentReportType],
    paginated: false,
    sort: getSortParameter('name', 'asc'),
  });

  if (isReportFiltersDataLoading) {
    return <Loader />;
  }

  if (isReportFiltersDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const isReportFiltersMenuOpen = Boolean(reportFiltersMenuElement);

  const handleReportFiltersMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setReportFiltersMenuElement(event.currentTarget);
  };
  const handleReportFiltersMenuClose = () => {
    setReportFiltersMenuElement(null);
  };
  const handleReportFilterClick = (reportFilter: IReportFilter) => {
    setSelectedFilterId(reportFilter.id);
    onChange(reportFilter);
  };

  const handleDeleteReportFilter = async reportFilter => {
    try {
      await deleteReportFilter(reportFilter.id).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const isDisabled = isCurrentUserLoading || isCurrentUserError || isReportFiltersDataLoading;

  return (
    <>
      {!_.isEmpty(reportFiltersData.filters) && (
        <Button
          id="reportFilters-button"
          aria-controls={isReportFiltersMenuOpen ? 'reportFilters-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isReportFiltersMenuOpen ? 'true' : undefined}
          onClick={handleReportFiltersMenuClick}
          sx={styles.reportFiltersButton}
          variant="outlined"
          disabled={isDisabled}
        >
          {t('reportFilter.title')}
        </Button>
      )}
      <Menu
        id="reportFilters-menu"
        anchorEl={reportFiltersMenuElement}
        open={isReportFiltersMenuOpen}
        onClose={handleReportFiltersMenuClose}
        MenuListProps={{
          'aria-labelledby': 'reportFilters-button',
          sx: styles.filterMenu,
        }}
      >
        {reportFiltersData.filters.map(reportFilter => {
          return (
            <MenuItem
              key={reportFilter.id}
              value={reportFilter.id}
              onClick={() => handleReportFilterClick(reportFilter)}
              sx={MUISx({ condition: reportFilter.id === selectedFilterId, sx: styles.selectedItem })}
            >
              <Typography variant="subtitle1">{reportFilter.name}</Typography>
              <Div sx={styles.buttons}>
                <Button
                  onClick={() => handleDeleteReportFilter(reportFilter)}
                  disabled={isDeleteReportFilterLoading}
                  sx={styles.cancel}
                >
                  <ClearOutlinedIcon sx={styles.icon} />
                </Button>
              </Div>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ReportFiltersButton;
