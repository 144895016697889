import { FC } from 'react';

import { Button, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import { getNodeState, isGroupsTree, isTechnologiesTree } from 'components/common/Tree/utils';

import styles from './styles';
import { ITreeInnerProps } from './types';

const TreeInner: FC<ITreeInnerProps> = props => {
  const { t } = useTranslation('adminPanel');

  const { data, isRootNode, onAddModalOpen, onEditModalOpen, onNodeActivate, onNodeDeactivate, isLoading, type } =
    props;

  const listStyles = MUISx({ condition: !isRootNode, sx: styles.listInner });
  const nodeStyles = MUISx({ condition: !isRootNode, sx: styles.innerNode }, styles.node);

  const nodeTypographyVariant = isRootNode ? 'heading3' : 'subtitle1';

  const isGroupsType = isGroupsTree(type);
  const isTechnologiesType = isTechnologiesTree(type);

  return (
    <List sx={listStyles}>
      {data.map(item => {
        const isArchivedNode = getNodeState.isArchivedNode(item);
        const isActiveNode = getNodeState.isActiveNode(item);
        const hasChildren = item.children.length > 0;

        const textContainerStyles = MUISx({ condition: isArchivedNode, sx: styles.archivedNode }, styles.textContainer);
        const controlGroupsTreeButtonLabel = isArchivedNode ? t('buttons.activate') : t('buttons.archive');
        const controlGroupsTreeButtonStyles = MUISx(
          { condition: isActiveNode, sx: styles.archiveButton },
          { condition: isArchivedNode, sx: styles.activateButton },
        );

        const handleControlGroupsTreeButtonClick = isArchivedNode ? onNodeActivate : onNodeDeactivate;

        const handleEditClick = () => {
          const parentId = item.parentId;

          onEditModalOpen(item.id, parentId);
        };

        return (
          <ListItem key={item.id} sx={nodeStyles}>
            <Div sx={styles.listItemContainer}>
              <Icon name="listPointer" sx={styles.listPointer} />
              <Div sx={textContainerStyles}>
                <ListItemText
                  primary={item.name}
                  sx={styles.listItemText}
                  primaryTypographyProps={{ variant: nodeTypographyVariant }}
                />

                {isArchivedNode && (
                  <Typography variant="subtitle3" sx={styles.archivedTitle}>
                    {t('archived')}
                  </Typography>
                )}

                <Div data-actions="item-actions" sx={styles.actions}>
                  <IconButton onClick={() => onAddModalOpen(item.id)} disabled={isArchivedNode || isLoading}>
                    <Icon name="plusBorderless" />
                  </IconButton>
                  <Button variant="text" disabled={isArchivedNode || isLoading} onClick={handleEditClick}>
                    <Typography variant="subtitle3">{t('buttons.edit')}</Typography>
                  </Button>

                  {isGroupsType && (
                    <Button
                      variant="text"
                      disabled={isLoading}
                      sx={controlGroupsTreeButtonStyles}
                      onClick={() => handleControlGroupsTreeButtonClick(item.id)}
                    >
                      <Typography variant="subtitle3">{controlGroupsTreeButtonLabel}</Typography>
                    </Button>
                  )}

                  {isTechnologiesType && (
                    <Button
                      variant="text"
                      disabled={isLoading}
                      sx={styles.deleteButton}
                      onClick={() => onNodeDeactivate(item.id, item.name)}
                    >
                      <Typography variant="subtitle3">Delete</Typography>
                    </Button>
                  )}
                </Div>
              </Div>
            </Div>

            {hasChildren && (
              <TreeInner
                data={item.children}
                isRootNode={false}
                onAddModalOpen={onAddModalOpen}
                onEditModalOpen={onEditModalOpen}
                onNodeActivate={onNodeActivate}
                onNodeDeactivate={onNodeDeactivate}
                isLoading={isLoading}
                type={type}
              />
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

export default TreeInner;
