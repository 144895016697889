import { SxStyles } from 'theme';

const styles: SxStyles = {
  body: {
    overflow: 'auto',
    height: 'fit-content',
    maxHeight: '100%',
    maxWidth: '100%',
  },
};

export default styles;
