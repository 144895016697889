import { FC, SyntheticEvent } from 'react';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getInitialValue } from 'components/common/SmartFilter/components/Slot/service';
import { SmartFilterDescriptionApprovalState, SmartFilterDescriptionState } from 'components/common/SmartFilter/enums';
import { APPROVAL_STATUS_COMPARISONS } from 'components/common/SmartFilter/relations';

import { capitalizeFirstLetter } from 'utils/string';

import styles from './styles';
import { IApprovalStatusProps } from './types';

const STATE_DEFAULT = SmartFilterDescriptionState.all;

const APPROVAL_STATUS_VALUES = {
  [SmartFilterDescriptionApprovalState.all]: null,
  [SmartFilterDescriptionApprovalState.onApproval]: 'on_approval',
  [SmartFilterDescriptionApprovalState.approved]: 'approved',
  [SmartFilterDescriptionApprovalState.completed]: 'completed',
};

const ApprovalStatus: FC<IApprovalStatusProps> = props => {
  const { slot, onSlotPatch } = props;

  const { t } = useTranslation(['smartFilter']);

  const initialValue = getInitialValue(slot.value, STATE_DEFAULT, APPROVAL_STATUS_VALUES);

  const handleComparisonChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    const newValue = value === STATE_DEFAULT ? null : { id: null, name: APPROVAL_STATUS_VALUES[value] };

    onSlotPatch(slot.id, { value: newValue });
  };

  return (
    <FormControl>
      <FormLabel sx={styles.title} id="status-buttons-group-label">
        <Typography sx={styles.titleText} variant="heading4">
          {t('smartFilter:status.title')}
        </Typography>
      </FormLabel>
      <RadioGroup
        aria-labelledby="status-comparison-radio-buttons-group"
        name="status-comparison"
        value={initialValue}
        onChange={handleComparisonChange}
      >
        {APPROVAL_STATUS_COMPARISONS.map(comparison => {
          const label = (
            <Typography sx={styles.label} variant="subtitle1">
              {capitalizeFirstLetter(comparison.description)}
            </Typography>
          );

          return (
            <FormControlLabel
              sx={styles.checkboxInput}
              key={comparison.description}
              value={comparison.description}
              control={<Radio size="small" />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default ApprovalStatus;
