import { FC, useState } from 'react';

import { Button, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import SingleMonthPicker from './components/SingleMonthPicker';
import styles from './styles';
import { IMonthPickerProps } from './types';

const MonthPicker: FC<IMonthPickerProps> = props => {
  const {
    startMonthNumber,
    endMonthNumber,
    startYear,
    endYear,
    setStartMonthNumber,
    setEndMonthNumber,
    setStartYear,
    setEndYear,
    onClose,
  } = props;

  const { t } = useTranslation('monthPicker');

  const [selectedStartYear, setSelectedStartYear] = useState<number | null>(startYear ?? new Date().getFullYear());
  const [selectedEndYear, setSelectedEndYear] = useState<number | null>(endYear ?? new Date().getFullYear());
  const [selectedStartMonthNumber, setSelectedStartMonthNumber] = useState<number | null>(
    startMonthNumber ?? new Date().getMonth() + 1,
  );
  const [selectedEndMonthNumber, setSelectedEndMonthNumber] = useState<number | null>(
    endMonthNumber ?? new Date().getMonth() + 1,
  );

  const handleApplyButtonClick = () => {
    setStartMonthNumber(selectedStartMonthNumber);
    setEndMonthNumber(selectedEndMonthNumber);
    setStartYear(selectedStartYear);
    setEndYear(selectedEndYear);
    onClose();
  };

  const isDisabled =
    selectedStartYear > selectedEndYear ||
    (selectedStartYear === selectedEndYear && selectedStartMonthNumber > selectedEndMonthNumber);

  return (
    <Div sx={styles.root}>
      <Div sx={styles.content}>
        <SingleMonthPicker
          selectedYear={selectedStartYear}
          setSelectedYear={setSelectedStartYear}
          selectedMonthNumber={selectedStartMonthNumber}
          setSelectedMonthNumber={setSelectedStartMonthNumber}
          label={t('from')}
        />

        <Divider sx={styles.divider} orientation="vertical" flexItem />

        <SingleMonthPicker
          selectedYear={selectedEndYear}
          setSelectedYear={setSelectedEndYear}
          selectedMonthNumber={selectedEndMonthNumber}
          setSelectedMonthNumber={setSelectedEndMonthNumber}
          label={t('to')}
        />
      </Div>

      <Button disabled={isDisabled} sx={styles.applyButton} type="button" onClick={handleApplyButtonClick}>
        {t('buttons.apply')}
      </Button>
    </Div>
  );
};

export default MonthPicker;
