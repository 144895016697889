import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    overflow: 'hidden',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'background.white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    marginBottom: 2.5,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingY: 1,
    paddingX: 2,
  },
  headerTitle: {
    color: 'text.blackLight',
    marginRight: 2,
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    gap: 1.25,
    flexWrap: 'wrap',
  },
  plusIconButton: {
    borderColor: 'border.whiteBlue',
    backgroundColor: 'border.whiteBlue',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  plusIcon: {
    width: '16px',
    height: '16px',
    color: 'text.blue',
  },
};

export default styles;
