import { FC, useMemo, useState } from 'react';

import { Button, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import MonthPicker from 'components/common/MonthPicker';

import {
  loadingGetProjectBudgetMonthsParameters,
  useGetProjectBudgetMonthsQuery,
} from 'domain/project/budget/month/apiSlice';
import { getNormilizeStartDate } from 'domain/project/budget/month/presenter';
import { IProjectBudgetMonth } from 'domain/project/budget/month/types';

import { getSortParameterWithRelation } from 'utils/runsack';

import Actions from './components/Actions';
import MonthPickerPlaceholder from './components/MonthPickerPlaceholder';
import Table from './components/Table';
import TableCell from './components/TableCell';
import TableHeader from './components/TableHeader';
import styles from './styles';
import { IProjectBudgetMonthsProps, ISortParameters } from './types';

const buildSParameter = (sortParameters: ISortParameters[]) => {
  return sortParameters.map(({ fieldName, direction, relation }) => {
    return getSortParameterWithRelation(fieldName, direction, relation);
  });
};

const ProjectBudgetMonths: FC<IProjectBudgetMonthsProps> = props => {
  const { projectId, canUpdateBudgets } = props;

  const { t } = useTranslation(['common', 'reportHoursByProject']);

  const router = useRouter();
  const { startDate, endDate } = router.camelizedQuery;

  const startDateFormat = startDate ? new Date(startDate) : new Date();
  const endDateFormat = endDate ? new Date(endDate) : new Date();

  startDateFormat.setMonth(startDateFormat.getMonth() - 6);
  endDateFormat.setMonth(endDateFormat.getMonth() + 6);

  const [startMonthNumber, setStartMonthNumber] = useState<number>(startDateFormat.getMonth() + 1);
  const [endMonthNumber, setEndMonthNumber] = useState<number>(endDateFormat.getMonth() + 1);
  const [startYear, setStartYear] = useState<number>(startDateFormat.getFullYear());
  const [endYear, setEndYear] = useState<number>(endDateFormat.getFullYear());
  const [monthPickerElement, setMonthPickerElement] = useState<HTMLButtonElement | null>(null);

  const handleMonthPickerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMonthPickerElement(event.currentTarget);
  };

  const handleMonthPickerClose = () => {
    setMonthPickerElement(null);
  };

  const monthPickerOpen = Boolean(monthPickerElement);
  const monthPickerId = open ? 'month-picker' : undefined;

  const projectBudgetMonthsQueryParameters = loadingGetProjectBudgetMonthsParameters(projectId, {
    dateBetween: [startMonthNumber, startYear, endMonthNumber, endYear],
    s: buildSParameter([
      { fieldName: 'year', direction: 'asc', relation: 'budget_calendar_month' },
      { fieldName: 'number', direction: 'asc', relation: 'budget_calendar_month' },
    ]),
  });
  const {
    data: projectBudgetMonthsData,
    isLoading: isProjectBudgetMonthsDataLoading,
    isFetching: isProjectBudgetMonthsDataFetching,
    isError: isProjectBudgetMonthsDataLoadingError,
  } = useGetProjectBudgetMonthsQuery(projectBudgetMonthsQueryParameters);

  const PROJECT_BUDGET_MONTHS_LIST_COLUMNS = useMemo(() => {
    const columns = [
      {
        Header: <TableHeader title={t('reportHoursByProject:projectBudgetMonths.table.headers.startDate')} />,
        accessor: 'startDate',
      },
      {
        Header: <TableHeader title={t('reportHoursByProject:projectBudgetMonths.table.headers.budgetHours')} />,
        accessor: 'budgedHours',
      },
    ];

    if (canUpdateBudgets) {
      columns.push({
        Header: '',
        accessor: 'actions',
      });
    }

    return columns;
  }, []);

  const tableData = useMemo(() => {
    return projectBudgetMonthsData?.budgetMonths.map((budgetMonth: IProjectBudgetMonth) => {
      const { budgetedHours } = budgetMonth;

      const startDate = getNormilizeStartDate(budgetMonth);

      return {
        startDate: <TableCell value={startDate} />,
        budgedHours: <TableCell value={budgetedHours} />,
        actions: canUpdateBudgets && <Actions projectId={projectId} budget={budgetMonth} />,
      };
    });
  }, [projectBudgetMonthsData]);

  if (isProjectBudgetMonthsDataLoading) {
    return <Loader />;
  }

  return (
    <Div>
      <Div sx={styles.monthPicker}>
        <Button
          variant="text"
          sx={styles.monthPickerPlaceholderButton}
          aria-describedby={monthPickerId}
          onClick={handleMonthPickerClick}
        >
          <MonthPickerPlaceholder
            startMonthNumber={startMonthNumber}
            endMonthNumber={endMonthNumber}
            startYear={startYear}
            endYear={endYear}
          />
        </Button>
        <Popover
          id={monthPickerId}
          open={monthPickerOpen}
          anchorEl={monthPickerElement}
          onClose={handleMonthPickerClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MonthPicker
            startMonthNumber={startMonthNumber}
            setStartMonthNumber={setStartMonthNumber}
            endMonthNumber={endMonthNumber}
            setEndMonthNumber={setEndMonthNumber}
            startYear={startYear}
            setStartYear={setStartYear}
            endYear={endYear}
            setEndYear={setEndYear}
            onClose={handleMonthPickerClose}
          />
        </Popover>
      </Div>

      <Table
        data={tableData}
        columns={PROJECT_BUDGET_MONTHS_LIST_COLUMNS}
        isError={isProjectBudgetMonthsDataLoadingError}
        isLoading={isProjectBudgetMonthsDataFetching}
      />
    </Div>
  );
};

export default ProjectBudgetMonths;
