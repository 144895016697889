import { FC, useMemo } from 'react';

import { Alert, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import UserProfileDetailedInfoTable from 'components/common/UserProfileDetailedInfoTable';

import { useGetUserProfileProjectsQuery } from 'domain/user/profile/project/apiSlice';
import { IUserProfileProject } from 'domain/user/profile/project/types';

import { getFullName } from 'utils/person';

import styles from './styles';
import { IProjectsProps } from './types';

const prepareTableData = (project: IUserProfileProject) => {
  const { name, workload, productDirector, projectManager, teamLead } = project;

  return {
    project: <Typography variant="subtitle1">{name}</Typography>,
    fte: <Typography variant="subtitle1">{`${workload}%`}</Typography>,
    specialization: <Typography variant="subtitle1">{}</Typography>,
    productDirector: <Typography variant="subtitle1">{getFullName(productDirector)}</Typography>,
    teamLead: <Typography variant="subtitle1">{getFullName(teamLead)}</Typography>,
    projectManager: <Typography variant="subtitle1">{getFullName(projectManager)}</Typography>,
  };
};

const Projects: FC<IProjectsProps> = props => {
  const { user } = props;
  const { t } = useTranslation('userProfile');
  const { data, isLoading, isError, error } = useGetUserProfileProjectsQuery({ userId: user.id });

  const PROJECTS_LIST_COLUMNS = useMemo(() => {
    return [
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('projects.project')}
          </Typography>
        ),
        accessor: 'project',
      },
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('projects.fte')}
          </Typography>
        ),
        accessor: 'fte',
      },
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('projects.specialization')}
          </Typography>
        ),
        accessor: 'specialization',
      },
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('projects.productDirector')}
          </Typography>
        ),
        accessor: 'productDirector',
      },
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('projects.teamLead')}
          </Typography>
        ),
        accessor: 'teamLead',
      },
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('projects.projectManager')}
          </Typography>
        ),
        accessor: 'projectManager',
      },
    ];
  }, []);

  const activeTableData = useMemo(() => {
    return data?.projects.filter(project => project.active).map(project => prepareTableData(project));
  }, [data]);

  const inactiveTableData = useMemo(() => {
    return data?.projects.filter(project => !project.active).map(project => prepareTableData(project));
  }, [data]);

  if (isError) {
    return <Alert severity="error">{`${t('common:defaultErrorNotificationText')}: ${error}`}</Alert>;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Div sx={styles.container}>
      <Typography variant="heading3" sx={styles.header}>
        {t('projects.active')}
      </Typography>
      <UserProfileDetailedInfoTable data={activeTableData} columns={PROJECTS_LIST_COLUMNS} />
      <Typography variant="heading3" sx={styles.header}>
        {t('projects.inactive')}
      </Typography>
      <UserProfileDetailedInfoTable data={inactiveTableData} columns={PROJECTS_LIST_COLUMNS} />
    </Div>
  );
};

export default Projects;
