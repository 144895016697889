import { ConditionState } from '../SmartFilter/enums';

import { TreeType } from './enums';
import { IPlainTree, INormalizedTree, IPlainTreeDictionary } from './types';

const normalizeData = (data: Array<IPlainTree>): Array<INormalizedTree> => {
  const parentKey = 'parentId';

  const idDictionary: IPlainTreeDictionary = data.reduce((accumulator, element) => {
    if (element[parentKey] === null) {
      return { ...accumulator, root: [...(accumulator['root'] || []), { ...element, children: [] }] };
    }

    accumulator[element[parentKey]] = [...(accumulator[element[parentKey]] || []), { ...element, children: [] }];

    return accumulator;
  }, {});

  const inner = (dictionary: IPlainTreeDictionary, keyName: 'root' | number) => {
    if (!dictionary.hasOwnProperty(keyName)) {
      return [];
    }

    return dictionary[keyName].map(item => {
      return { ...item, children: inner(dictionary, item.id) };
    });
  };

  return inner(idDictionary, 'root');
};

const isActiveNode = (node: INormalizedTree): boolean => {
  return node.state === ConditionState.active;
};

const isArchivedNode = (node: INormalizedTree): boolean => {
  return node.state === ConditionState.archived;
};

export const isGroupsTree = (treeType: TreeType): boolean => TreeType.groups === treeType;
export const isTechnologiesTree = (treeType: TreeType): boolean => TreeType.technologies === treeType;

export const getNodeState = { isActiveNode, isArchivedNode };

export { normalizeData };
