import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    paddingTop: 1,
  },
  select: {
    width: '400px',
    marginBottom: 4,
    textTransform: 'capitalize',
  },
  selectPlaceholder: {
    color: 'text.gray',
  },
  permissionValue: {
    textTransform: 'capitalize',
  },
  saveButton: {
    marginRight: 1.25,
  },
};

export default styles;
