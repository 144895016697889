import { SxStyles } from 'theme';

const styles: SxStyles = {
  searchIcon: {
    color: 'text.gray',
    marginRight: 1,
  },
  checkbox: {
    paddingY: 2,
    paddingX: 1.25,
  },
  checkboxLabel: {
    color: 'text.blackLight',
  },
};

export default styles;
