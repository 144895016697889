import * as yup from 'yup';

import i18n from 'locales/i18n';

const requiredValidation = 'forms:validation:required';

export interface ICreateTrackingTypeFormData {
  name: string;
}

export interface ICreateTrackingTypeFormDataToSubmit {
  formData: ICreateTrackingTypeFormData;
}

export const buildValidationFields = () => ({
  name: yup
    .string()
    .max(300, i18n.t('forms:validation:maxSymbols', { count: 300 }))
    .required(i18n.t(requiredValidation))
    .default(''),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (
  formData: ICreateTrackingTypeFormData,
): ICreateTrackingTypeFormDataToSubmit => {
  return {
    formData,
  };
};
