import { FC } from 'react';

import Div from 'components/common/Div';

import DaysInfo from './components/DaysInfo';
import Legend from './components/Legend';
import VacationRequestTabs from './components/VacationRequestTabs';
import styles from './styles';
import { IVacationRequestExpandedContent } from './types';

const VacationRequestExpandedContent: FC<IVacationRequestExpandedContent> = props => {
  const { vacationRequest } = props;

  return (
    <Div sx={styles.root}>
      <Div sx={styles.topBar}>
        <DaysInfo vacationRequest={vacationRequest} />
        <Legend />
      </Div>
      <VacationRequestTabs
        vacationsEndDate={vacationRequest.endDate}
        vacationsRequestId={vacationRequest.id}
        vacationsRequestName={vacationRequest.requestedName}
        vacationsRequestYear={vacationRequest.year}
        vacationsStartDate={vacationRequest.startDate}
      />
    </Div>
  );
};

export default VacationRequestExpandedContent;
