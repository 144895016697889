export default {
  title: 'Filter by',
  sections: {
    project: {
      label: 'Project',
    },
    members: {
      label: 'Members',
    },
    users: {
      label: 'Users',
    },
    calendars: {
      label: 'Calendars',
    },
    hierarchy: {
      label: 'Hierarchy',
    },
    utilization: {
      label: 'Utilization',
    },
    allocation: {
      label: 'Allocation',
    },
    status: {
      label: 'Status',
    },
    actualHours: {
      label: 'Actual',
    },
    plannedHours: {
      label: 'Planned',
    },
    description: {
      label: 'Description',
    },
    trackable: {
      label: 'Project',
    },
    workload: {
      label: 'FTE',
    },
    technology: {
      label: 'Specialization',
    },
    billable: {
      label: 'Billable',
    },
    endDate: {
      label: 'End date',
    },
    groupByPeriod: {
      label: 'Group by',
    },
    endDate: {
      label: 'End date',
    },
    task: {
      label: 'Task',
    },
    tag: {
      label: 'Tag',
    },
  },
  relations: {
    lteq: {
      description: 'Less or Equal',
    },
    gteq: {
      description: 'More or equal',
    },
    equals: {
      description: 'Equal',
    },
    notEquals: {
      description: 'Not Equal',
    },
    lessThan: {
      description: 'Less',
    },
    moreThan: {
      description: 'More',
    },
    cont: {
      description: 'Contains',
    },
    blank: {
      description: 'Blank',
    },
  },
  utilization: {
    label: 'Utilization',
    placeholder: 'Set Value',
  },
  workload: {
    label: 'FTE%',
    placeholder: 'Set Value',
  },
  technology: {
    label: 'Specialization',
    placeholder: 'Set Value',
  },
  allocation: {
    label: 'Allocation',
    placeholder: 'Set Value',
  },
  groups: {
    label: 'Hierarchy',
  },
  projects: {
    label: 'Project',
  },
  resources: {
    label: 'Members',
  },
  users: {
    label: 'Users',
  },
  status: {
    title: 'Status',
    labelPending: 'pending',
  },
  groupByPeriod: {
    title: 'Group by',
  },
  calendars: {
    label: 'Calendars',
  },
  actualHours: {
    label: 'Actual',
    placeholder: 'Set Value',
  },
  plannedHours: {
    label: 'Planned',
    placeholder: 'Set Value',
  },
  description: {
    placeholder: 'Description',
    blankLabel: 'Without description',
  },
  trackables: {
    label: 'Project',
    blankLabel: 'Entries without project',
  },
  billable: {
    title: 'Billable',
  },
  endDate: {
    title: 'End date',
    options: {
      all: 'All',
      withEndDate: 'With end date',
      withoutEndDate: 'Without end date',
    },
  },
  tasks: {
    label: 'Tasks',
  },
  tags: {
    label: 'Tags',
  },
  clearButton: 'Clear All',
  multipleAutocomplete: {
    placeholder: 'Find subject...',
    buttons: {
      all: 'All',
      none: 'None',
    },
    show: 'SHOW',
    state: {
      active: 'Active',
      archived: 'Archived',
      both: 'Both',
    },
  },
  reportFilter: {
    title: 'Saved Filters',
    saveReportFilterButton: 'Save Filters',
    label: 'Filter Title',
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },
  },
};
