export enum Host {
  develop = 'localhost:3000',
  qa = 'qa.projectx.dualbootpartners.com',
  uat = 'uat.projectx.dualbootpartners.com',
  production = 'projectx.dualbootpartners.com',
}

export enum EnvironmentPostfix {
  develop = 'DEV',
  uffizzy = 'UFZ',
  qa = 'QA',
  uat = 'UAT',
  production = '',
}
