import { SxStyles } from 'theme';

const styles: SxStyles = {
  select: {
    width: '100%',
  },
  selectPlaceholder: {
    color: 'text.gray',
  },
};

export default styles;
