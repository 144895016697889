import { CircularProgress, SxProps, Theme } from '@mui/material';

import Div from 'components/common/Div';

import styles from './styles';

interface ILoaderProps {
  sx?: SxProps<Theme>;
}

const Loader = (props: ILoaderProps) => {
  const { sx = [] } = props;

  return (
    <Div sx={[styles.progress, ...(Array.isArray(sx) ? sx : [sx])]}>
      <CircularProgress />
    </Div>
  );
};

export default Loader;
