import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 3,
  },
  rightFilters: {
    display: 'flex',
    alignContent: 'center',
  },
  measurementUnitFilterBlock: {
    marginRight: 4,
  },
  dateRangePickerBlock: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 2,
  },
};

export default styles;
