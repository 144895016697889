import { SxStyles } from 'theme';

const styles: SxStyles = {
  box: {
    maxHeight: '300px',
    overflow: 'auto',
    padding: 1,
  },
  groupItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'text.gray',
  },
  groupList: {
    width: '100%',
    padding: 0,
  },
  groupListTitle: {
    width: '100%',
    textTransform: 'uppercase',
  },
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  optionButton: {
    width: '100%',
    justifyContent: 'start',
    alignItems: 'center',
    textAlign: 'left',
  },
  projectOptionButton: {
    justifyContent: 'space-between',
  },
  optionText: {
    color: 'text.primary',
  },
  selectedOption: {
    backgroundColor: 'background.lightGray',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
  },
  arrowRightBlock: {
    width: '16px',
    height: '16px',
  },
  arrowRightIcon: {
    width: '16px',
    height: '16px',
  },
};

export default styles;
