import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    paddingTop: 1,
  },
  input: {
    width: '400px',
  },
  buttons: {
    marginTop: 4,
    display: 'flex',
    gap: 1.25,
  },
};

export default styles;
