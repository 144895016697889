import { FC, useMemo } from 'react';

import { Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { getHoursAndMinutesStringFromMinutes } from 'utils/dateTime';

import styles from './styles';
import { IReportTrackedTimeEntriesTotalInfoProps } from './types';

const TotalInfo: FC<IReportTrackedTimeEntriesTotalInfoProps> = props => {
  const { totalTrackedTime, billableTrackedTime, percentOfBillableTrackedTime } = props;

  const { t } = useTranslation('reportTrackedTimeEntries');

  const totalHoursView = useMemo(() => getHoursAndMinutesStringFromMinutes(totalTrackedTime), [totalTrackedTime]);

  const billableHoursView = useMemo(
    () => getHoursAndMinutesStringFromMinutes(billableTrackedTime),
    [billableTrackedTime],
  );

  return (
    <Div sx={styles.root}>
      <Div sx={MUISx(styles.total, styles.totalHours)}>
        <Typography sx={styles.totalTitle} variant="heading3">
          {t('totalInfo.totalHours')}
        </Typography>
        <Typography variant="body2">{totalHoursView}</Typography>
      </Div>
      <Div sx={styles.total}>
        <Typography sx={styles.totalTitle} variant="heading3">
          {t('totalInfo.billableHours')}
        </Typography>
        <Typography variant="body2">{billableHoursView}</Typography>
        <Div sx={styles.divider} />
        <Typography variant="body2">{`${percentOfBillableTrackedTime}%`}</Typography>
      </Div>
    </Div>
  );
};

export default TotalInfo;
