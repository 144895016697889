import { FC, useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format, isValid } from 'date-fns';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Autocomplete from 'components/common/Autocomplete';
import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { ModalContext } from 'contexts/ModalContext';

import { useGetGroupsQuery } from 'domain/group/apiSlice';
import { IGroup } from 'domain/group/types';
import { useCreateUserProfileMigrationMutation } from 'domain/user/profile/migrations/apiSlice';
import {
  ICreateNewLocationFormData,
  initialValues,
  buildValidationSchema,
  formatAttributesToSubmit,
  ICreateNewLocationPreparedFormData,
} from 'domain/user/profile/migrations/schemas/create';

import { SuggestionAutocompleteType } from 'enums/SuggestionAutocomplete';

import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { buildErrorMessage } from 'utils/form';
import { getSortParameter } from 'utils/runsack';
import { getPreparedDataForSuggestionAutocomplete } from 'utils/suggestionAutocomplete';

import styles from './styles';
import { ICreateNewLocationFormProps } from './types';

const formatToDatePickers = (dateToFormat: string | Date | number) => {
  if (typeof dateToFormat === undefined || (typeof dateToFormat === 'object' && !isValid(dateToFormat))) return null;
  if (typeof dateToFormat === 'object' || typeof dateToFormat === 'number') return format(dateToFormat, 'MM-dd-yyyy');
  return `${dateToFormat.slice(5, 7)}-${dateToFormat.slice(8, 10)}-${dateToFormat.slice(0, 4)}`;
};

const CreateNewLocationForm: FC<ICreateNewLocationFormProps> = props => {
  const { userId } = props;

  const { t } = useTranslation('userProfile');

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const { closeModal } = useContext(ModalContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ICreateNewLocationFormData>({
    defaultValues: initialValues(),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: group, onChange: onGroupChange },
  } = useController({ name: 'group', control });

  const {
    field: { value: receiptDate, onChange: onReceiptDateChange },
  } = useController({ name: 'receiptDate', control });

  const {
    field: { value: eliminationDate, onChange: onEliminationDateChange },
  } = useController({ name: 'eliminationDate', control });

  const [groupName, setGroupName] = useState<string>('');

  const [debouncedGroupName] = useDebounce(groupName, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: groupsData,
    isLoading: isGroupsDataLoading,
    isFetching: isGroupsDataFetching,
    isError: isGroupsDataLoadingError,
  } = useGetGroupsQuery({
    nameCont: debouncedGroupName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const [createNewLocation, { isLoading: isCreateNewLocationLoading }] = useCreateUserProfileMigrationMutation();

  if (isGroupsDataLoading) {
    return <Loader />;
  }

  if (isGroupsDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCurrentGroupChange = (currentGroup: IGroup) => {
    onGroupChange(currentGroup ?? null);
  };

  const onSubmit = async ({ group, receiptDate, eliminationDate }: ICreateNewLocationFormData) => {
    try {
      const userMigration: ICreateNewLocationPreparedFormData = {
        receiptDate: receiptDate,
        eliminationDate: eliminationDate,
        groupId: group?.id,
        userId: userId,
      };
      await createNewLocation(formatAttributesToSubmit({ userMigration })).unwrap();
      showSuccessNotification();
    } catch ({ data: { errors } }) {
      for (const error in errors) {
        for (const message of errors[error]) {
          showErrorNotification(message);
        }
      }
    } finally {
      closeModal();
    }
  };

  const groupsOptions = getPreparedDataForSuggestionAutocomplete<IGroup>({
    data: groupsData.groups,
    type: SuggestionAutocompleteType.tree,
  });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.container}>
        <Div sx={styles.select}>
          <Autocomplete
            isRequired
            helperText={buildErrorMessage(t, errors.group)}
            options={groupsOptions}
            isFetching={isGroupsDataFetching}
            isError={Boolean(isGroupsDataLoadingError)}
            name={groupName}
            setName={setGroupName}
            onCurrentValueChange={handleCurrentGroupChange}
            currentValue={group}
            label={'Tech Center'}
          />
        </Div>
        <Div sx={styles.datePicker}>
          <DatePicker
            label={t('migrations.createNewLocationStartDate')}
            value={formatToDatePickers(receiptDate)}
            onChange={onReceiptDateChange}
            renderInput={parameters => (
              <TextField
                required
                {...parameters}
                error={Boolean(errors.receiptDate)}
                helperText={buildErrorMessage(t, errors.receiptDate)}
                sx={styles.datePickerField}
              />
            )}
          />
          <DatePicker
            label={t('migrations.createNewLocationEndDate')}
            value={formatToDatePickers(eliminationDate)}
            onChange={onEliminationDateChange}
            renderInput={parameters => (
              <TextField
                required
                {...parameters}
                error={Boolean(errors.eliminationDate)}
                helperText={buildErrorMessage(t, errors.eliminationDate)}
                sx={styles.datePickerField}
              />
            )}
          />
        </Div>
        <Div sx={styles.buttonsContainer}>
          <Button type="submit" variant="contained" disabled={isCreateNewLocationLoading}>
            {t('migrations.createNewLocationAddButton')}
          </Button>
          <Button variant="outlined" onClick={closeModal} disabled={isCreateNewLocationLoading}>
            {t('migrations.createNewLocationCancelButton')}
          </Button>
        </Div>
      </Div>
    </form>
  );
};

export default CreateNewLocationForm;
