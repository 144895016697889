export default {
  resourceType: {
    label: 'Resource Type',
  },
  selects: {
    name: {
      label: 'Name',
    },
  },
  fields: {
    startDate: {
      label: 'Start Date',
    },
    endDate: {
      label: 'End Date',
    },
  },
  switches: {
    endDate: {
      label: 'End Date',
    },
  },
  buttons: {
    addResource: 'Add resource',
    cancel: 'Cancel',
  },
  createVacancyForm: {
    vacancyStack: {
      label: 'Vacancy Stack',
    },
  },
  createResourceUsageForm: {
    practice: {
      label: 'Practice',
    },
  },
};
