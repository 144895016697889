import { FC, useContext } from 'react';

import { Button, ListItem, Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { ModalContext } from 'contexts/ModalContext';

import { useActivateProjectTaskMutation, useDeleteProjectTaskMutation } from 'domain/project/task/apiSlice';
import { getProjectTaskState } from 'domain/project/task/service';
import { ITask } from 'domain/project/task/types';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';

import UpdateProjectTaskForm from './components/UpdateProjectTaskForm';
import styles from './styles';
import { IProjectTaskProps } from './types';

const Task: FC<IProjectTaskProps> = props => {
  const { task, projectId, canUpdateTasks } = props;

  const { t } = useTranslation('reportHoursByProject');

  const { openModal } = useContext(ModalContext);

  const [activateProjectTask, { isLoading: isActivateProjectTaskLoading }] = useActivateProjectTaskMutation();
  const [deleteProjectTask, { isLoading: isDeleteProjectTaskLoading }] = useDeleteProjectTaskMutation();

  const isTaskActivationLoading = isActivateProjectTaskLoading || isDeleteProjectTaskLoading;

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const handleUpdateTaskModalOpen = (task: ITask) => {
    openModal({
      title: t('reportHoursByProject:projectTasks.task.renameModalTitle'),
      content: <UpdateProjectTaskForm projectId={projectId} task={task} />,
    });
  };

  const handleActivateClick = async (taskId: number) => {
    try {
      await activateProjectTask({ projectId, id: taskId }).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };
  const handleDeactivateClick = async (taskId: number) => {
    try {
      await deleteProjectTask({ projectId, id: taskId }).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const { isActive, isArchived } = getProjectTaskState(task);

  const controlButtonLabel = isArchived
    ? t('projectTasks.task.buttons.enable')
    : t('projectTasks.task.buttons.disable');

  const handleTaskControlButtonClick = (taskId: number) =>
    isArchived ? handleActivateClick(taskId) : handleDeactivateClick(taskId);

  return (
    <ListItem sx={styles.listItem} key={task.id}>
      <Div sx={styles.listPointer}>
        <Icon name="ellipseListPointer" sx={styles.listPointerIcon} />
      </Div>

      <Div data-task="task-content" sx={styles.taskContent}>
        <Typography
          sx={MUISx(styles.taskName, { condition: isArchived, sx: styles.archivedTaskName })}
          variant="heading3"
          component="p"
        >
          {task.name}
        </Typography>

        {isArchived && (
          <Typography sx={styles.disabledChip} variant="heading4" component="p">
            {t('projectTasks.task.disabled')}
          </Typography>
        )}

        <Div sx={styles.buttons} data-task="task-actions">
          {canUpdateTasks && (
            <>
              <Button
                variant="text"
                disabled={isArchived || isTaskActivationLoading}
                onClick={() => handleUpdateTaskModalOpen(task)}
              >
                <Typography variant="heading4">{t('projectTasks.task.buttons.rename')}</Typography>
              </Button>
              <Button
                variant="text"
                disabled={isTaskActivationLoading}
                onClick={() => handleTaskControlButtonClick(task.id)}
              >
                <Typography
                  sx={MUISx(
                    { condition: isArchived, sx: styles.disableButtonText },
                    { condition: isActive, sx: styles.enableButtonText },
                  )}
                  variant="heading4"
                >
                  {controlButtonLabel}
                </Typography>
              </Button>
            </>
          )}
        </Div>
      </Div>
    </ListItem>
  );
};

export default Task;
