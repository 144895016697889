import { TFunction } from 'i18next';
import _ from 'lodash';
import { DeepRequired, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { floatNumberRegExp, maxTwoDigitsAfterDecimalRegExp, intNumberRegExp } from './regexp';

export const buildErrorMessage = (
  t: TFunction,
  field?:
    | FieldError
    | Array<FieldError>
    | Record<string, FieldError>
    | Array<Record<string, FieldError>>
    | Merge<FieldError, FieldErrorsImpl<DeepRequired<unknown>>>
    | null,
) => {
  if (_.isNil(field)) {
    return;
  }

  if (Array.isArray(field)) {
    return field.map(item => t(`${item.message}`)).join(', ');
  }

  return field && t(`${field.message}`);
};

export const floatInputRule = yup
  .mixed()
  .test('is-number', i18n.t('forms:validation:mustBeNumber'), value =>
    _.isNil(value) || _.isEmpty(value) ? true : floatNumberRegExp.test(value),
  )
  .test('number-is-positive', i18n.t('forms:validation:positiveNumber'), value =>
    _.isNil(value) || _.isEmpty(value) ? true : Number.parseFloat(value) >= 0,
  )
  .test(
    i18n.t('forms:validation:maxDigitsAfterDecimalTest'),
    i18n.t('forms:validation:maxTwoDigitsAfterDecimal'),
    value => (_.isNil(value) || _.isEmpty(value) ? true : maxTwoDigitsAfterDecimalRegExp.test(value)),
  )
  .required(i18n.t('forms:validation:required'))
  .nullable()
  .default(null);

export const percentageInputRule = yup
  .mixed()
  .test('is-number', i18n.t('forms:validation:mustBeNumber'), value =>
    _.isNil(value) || _.isEmpty(value) ? true : intNumberRegExp.test(value),
  )
  .test('zero-is-min', i18n.t('forms:validation:minNumber', { count: 0 }), value =>
    _.isNil(value) || _.isEmpty(value) ? true : Number.parseInt(value) >= 0,
  )
  .test('hundred-is-max', i18n.t('forms:validation:maxNumber', { count: 100 }), value =>
    _.isNil(value) || _.isEmpty(value) ? true : Number.parseInt(value) <= 100,
  )
  .required(i18n.t('forms:validation:required'))
  .nullable()
  .default(null);
