import { isWithinInterval } from 'date-fns';

import { IVacation } from 'domain/resource/vacation/types';

import { formatToDateWithFixedTimeZone } from 'utils/dateTime';

export const isDateInVacationPeriod = (date: Date, vacations: IVacation[]): boolean =>
  vacations.some(({ startDate, endDate }) => {
    const start = formatToDateWithFixedTimeZone(startDate);
    const end = formatToDateWithFixedTimeZone(endDate);
    return isWithinInterval(date, { start, end });
  });
