import { IReportVacationResource } from 'domain/report/vacation/types';

export interface IVacationTableProps {
  isFetching: boolean;
  resources: IReportVacationResource[];
  dateRangesByMonth: Date[][];
}

export enum StickyCellKey {
  Resource = 'resource',
  CarryoverPrevious = 'carryoverPrev',
  Available = 'available',
  Scheduled = 'scheduled',
  CarryoverNext = 'carryoverNext',
  Month = 'month',
}

export interface IStickyCellPosition {
  id: StickyCellKey;
  left: number;
  top: number;
}
