import { FC } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';

import { getDateAdapterWithCustomStartsOfWeek } from 'utils/dateRangePicker';

import { IUserLocaleContextProps } from './types';

const UserLocaleProvider: FC<IUserLocaleContextProps> = props => {
  const { children } = props;
  const { data: currentUser } = useGetCurrentUserQuery();

  return (
    <LocalizationProvider dateAdapter={getDateAdapterWithCustomStartsOfWeek(currentUser?.user?.weekStartsDay)}>
      {children}
    </LocalizationProvider>
  );
};

export default UserLocaleProvider;
