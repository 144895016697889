export enum VacationApprovementApprovingLevelType {
  project = 'project',
  group = 'group',
}

export enum VacationApprovementState {
  indefinite = 'indefinite',
  confirmed = 'confirmed',
  declined = 'declined',
}
