import _ from 'lodash';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { ICurrentUser } from 'domain/currentUser/types';
import { UserType } from 'domain/user/enums';

export type IRoleFeature =
  | 'canProfileEdit'
  | 'canToggleProfileState'
  | 'canCreateSpecializationOrStack'
  | 'canCreateProjectAccessLevel'
  | 'canCreateGroupAccessLevel'
  | 'canCreateHoursPerWeek'
  | 'canApproveVacation'
  | 'canUpdateAccessLevel'
  | 'canUpdateHoursPerWeek'
  | 'canSelectUsers'
  | 'canCopySelectedUsers'
  | 'canViewHoursByProjectsReport'
  | 'canViewHoursByResourcesReport'
  | 'canViewVacationsReport'
  | 'canViewAllocationsReport'
  | 'canViewMigrations';

export type IPermissions = Record<UserType, Array<IRoleFeature>>;

const permissions: IPermissions = {
  [UserType.admin]: [
    'canProfileEdit',
    'canToggleProfileState',
    'canCreateSpecializationOrStack',
    'canCreateProjectAccessLevel',
    'canCreateGroupAccessLevel',
    'canCreateHoursPerWeek',
    'canApproveVacation',
    'canUpdateAccessLevel',
    'canUpdateHoursPerWeek',
    'canSelectUsers',
    'canCopySelectedUsers',
    'canViewHoursByProjectsReport',
    'canViewHoursByResourcesReport',
    'canViewVacationsReport',
    'canViewAllocationsReport',
    'canViewMigrations',
  ],
  [UserType.superAdmin]: [
    'canViewHoursByProjectsReport',
    'canViewHoursByResourcesReport',
    'canViewVacationsReport',
    'canViewAllocationsReport',
    'canProfileEdit',
    'canToggleProfileState',
    'canCreateSpecializationOrStack',
    'canCreateProjectAccessLevel',
    'canCreateGroupAccessLevel',
    'canCreateHoursPerWeek',
    'canApproveVacation',
    'canUpdateAccessLevel',
    'canSelectUsers',
    'canCopySelectedUsers',
    'canViewMigrations',
  ],
  [UserType.user]: [
    'canProfileEdit',
    'canToggleProfileState',
    'canCreateSpecializationOrStack',
    'canCreateProjectAccessLevel',
    'canCreateGroupAccessLevel',
    'canApproveVacation',
    'canUpdateAccessLevel',
    'canSelectUsers',
    'canCopySelectedUsers',
    'canViewHoursByProjectsReport',
    'canViewHoursByResourcesReport',
    'canViewVacationsReport',
    'canViewAllocationsReport',
  ],
};

export const isRoleFeatureAllowedForUser = (user: ICurrentUser, roleFeature: IRoleFeature): boolean => {
  return permissions[user.userType].includes(roleFeature);
};

export const usePermissions = () => {
  const { isFetching, data } = useGetCurrentUserQuery();

  const isRoleFeatureAllowed = (feature: IRoleFeature) => {
    return isFetching || _.isNil(data?.user) ? false : isRoleFeatureAllowedForUser(data.user, feature);
  };

  return {
    isRoleFeatureAllowed,
    currentUser: data.user,
  };
};
