import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
  },
  content: {
    width: '100%',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: 'background.default',
    paddingTop: 4,
    paddingX: 3,
    paddingBottom: 3,
  },
};

export default styles;
