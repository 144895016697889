import { FC, useState } from 'react';

import { Button, Popover, TextField, Typography } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

import Icon from 'components/common/Icon';

import { formatDateToDatePickerString } from 'utils/dateTime';

import { useSetRightCurrentDateEffect } from './service';
import styles from './styles';
import { ITimeTrackerTrackableDatePickerProps } from './types';

const TimeTrackerTrackableDatePicker: FC<ITimeTrackerTrackableDatePickerProps> = props => {
  const { date, setDate, isDisabled } = props;

  useSetRightCurrentDateEffect(setDate);

  const [datePickerElement, setDatePickerElement] = useState<HTMLButtonElement | null>(null);

  const handleDatePickerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDatePickerElement(event.currentTarget);
  };

  const handleDatePickerClose = () => {
    setDatePickerElement(null);
  };

  const isDatePickerOpen = Boolean(datePickerElement);
  const datePickerId = open ? 'trackable-date-picker' : undefined;

  return (
    <>
      <Button
        sx={styles.datePickerButtonPlaceholder}
        aria-describedby={datePickerId}
        variant="text"
        onClick={handleDatePickerClick}
        disabled={isDisabled}
      >
        <Typography sx={styles.datePickerButtonPlaceholderText}>{formatDateToDatePickerString(date)}</Typography>
        <Icon name="calendar" />
      </Button>
      <Popover
        id={datePickerId}
        open={isDatePickerOpen}
        anchorEl={datePickerElement}
        onClose={handleDatePickerClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StaticDatePicker
          components={{
            ActionBar: () => null,
          }}
          showToolbar={false}
          value={date}
          onChange={setDate}
          renderInput={parameters => <TextField {...parameters} />}
        />
      </Popover>
    </>
  );
};

export default TimeTrackerTrackableDatePicker;
