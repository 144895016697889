import { FC } from 'react';

import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import { IRenderInputProps } from './types';

const RenderInput: FC<IRenderInputProps> = props => {
  const { onDescriptionChange, parameters } = props;

  const { t } = useTranslation('timeTracker');

  return (
    <TextField
      {...parameters}
      onChange={onDescriptionChange}
      placeholder={t('createResourceTrackedEntityField.inputPlaceholder')}
      inputProps={{
        ...parameters.inputProps,
        maxLength: 600,
      }}
      sx={styles}
    />
  );
};

export default RenderInput;
