export enum CheckStrategy {
  doNotCheck = 'doNotCheck',
  markAsDuplicate = 'markAsDuplicate',
  block = 'block',
}

export enum CheckTypes {
  internal = 'internal',
  AI = 'AI',
}
