import { FC } from 'react';

import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import MUISx from 'mui-sx';

import Div from 'components/common/Div';

import styles from './styles';
import { IYearSwitcherProps } from './types';

const YearSwitcher: FC<IYearSwitcherProps> = props => {
  const { currentYear, onYearChange, isDisabledPrevious = false, isDisabledNext = false } = props;

  const decrementYear = () => onYearChange(currentYear - 1);
  const incrementYear = () => onYearChange(currentYear + 1);

  return (
    <Div sx={styles.root}>
      <IconButton onClick={decrementYear} disabled={isDisabledPrevious}>
        <KeyboardArrowLeftOutlined />
      </IconButton>
      <Typography sx={MUISx({ condition: isDisabledPrevious || isDisabledNext, sx: styles.disabled })} variant="h3">
        {currentYear}
      </Typography>
      <IconButton onClick={incrementYear} disabled={isDisabledNext}>
        <KeyboardArrowRightOutlined />
      </IconButton>
    </Div>
  );
};

export default YearSwitcher;
