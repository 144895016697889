import { invalidateTags, getListTag, TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { ICreateForbiddenWordFormDataToSubmit } from './schemas/create';
import { IForbiddenWord } from './types';

interface IGetForbiddenWordsParameters {
  perPage?: number;
  page?: number;
  word_cont?: string;
  sort?: ISortParameter;
}

interface IForbiddenWordsResponse {
  meta: IMeta;
  words: Array<IForbiddenWord>;
}

interface IForbiddenWordResponse {
  forbiddenWord: IForbiddenWord;
}

export const forbiddenWordApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getForbiddenWords: builder.query<IForbiddenWordsResponse, IGetForbiddenWordsParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1TimeTrackerForbiddenWordsPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: ({ forbiddenWords }) => provideTags(TagTypes.ForbiddenWord, forbiddenWords),
    }),
    createForbiddenWord: builder.mutation<IForbiddenWordResponse, ICreateForbiddenWordFormDataToSubmit>({
      query: formData => ({
        url: apiRoutes.apiV1TimeTrackerForbiddenWordsPath(),
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: invalidateTags(getListTag(TagTypes.ForbiddenWord)),
    }),
    deleteForbiddenWord: builder.mutation<unknown, number>({
      query: forbiddenWordId => ({
        url: apiRoutes.apiV1TimeTrackerForbiddenWordPath(forbiddenWordId),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateTags(getListTag(TagTypes.ForbiddenWord)),
    }),
  }),
  overrideExisting: false,
});

export const { useGetForbiddenWordsQuery, useCreateForbiddenWordMutation, useDeleteForbiddenWordMutation } =
  forbiddenWordApi;
