import { FC, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { useCreateProjectTaskMutation } from 'domain/project/task/apiSlice';
import {
  initialValues,
  ICreateProjectTaskFormData,
  buildValidationSchema,
  formatAttributesToSubmit,
} from 'domain/project/task/schemas/create';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { ICreateProjectTaskFormProps } from './types';

const CreateProjectTaskForm: FC<ICreateProjectTaskFormProps> = props => {
  const { projectId } = props;

  const { t } = useTranslation('reportHoursByProject');

  const [createProjectTask, { isLoading: isCreateProjectTaskLoading }] = useCreateProjectTaskMutation();

  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const { closeModal } = useContext(ModalContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateProjectTaskFormData>({
    defaultValues: initialValues(),
    resolver: yupResolver(buildValidationSchema()),
  });

  const onSubmit = async (formData: ICreateProjectTaskFormData) => {
    try {
      await createProjectTask(formatAttributesToSubmit(projectId, formData)).unwrap();

      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.nameField}>
        <TextField
          sx={styles.name}
          {...register('name')}
          error={Boolean(errors.name)}
          fullWidth
          inputProps={{ maxLength: 100 }}
          label={t('projectTasks.createProjectTaskForm.fields.name.label')}
          InputLabelProps={{ sx: styles.placeholder }}
          helperText={buildErrorMessage(t, errors.name)}
        />
      </Div>

      <Div>
        <Button sx={styles.createButton} type="submit" disabled={isCreateProjectTaskLoading}>
          {t('projectTasks.createProjectTaskForm.buttons.create')}
        </Button>
        <Button onClick={closeModal} type="button" variant="outlined" disabled={isCreateProjectTaskLoading}>
          {t('projectTasks.createProjectTaskForm.buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default CreateProjectTaskForm;
