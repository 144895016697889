import _ from 'lodash';
import * as yup from 'yup';

import { ProjectRole } from 'domain/project/enums';
import { IUser } from 'domain/user/types';

import { IUpdateProjectFormDataToSubmit } from './update';

export interface IUpdateProjectUserRolesFormData {
  user: IUser | null;
}

export const buildValidationFields = () => ({
  user: yup.mixed<IUser>().nullable().default(null),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (
  formData: IUpdateProjectUserRolesFormData,
  role: ProjectRole,
  projectId: number,
): IUpdateProjectFormDataToSubmit => {
  const id = _.isNil(formData.user) ? null : formData.user.id;
  const currentRoleToChangeKey = `${role}Id`;

  return {
    projectId: projectId,
    formData: {
      [currentRoleToChangeKey]: id,
    },
  };
};
