import _ from 'lodash';

import i18n from 'locales/i18n';

import { IPerson } from 'types/common';

export const getFullName = (person: IPerson | null, assigned = false) => {
  if (_.isNil(person)) {
    return assigned ? i18n.t('assign') : i18n.t('notAssigned');
  }

  const { firstName, lastName } = person;
  return `${firstName} ${lastName}`;
};
