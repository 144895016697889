import { FC, useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormGroup, ListItem, TextField, Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import {
  useCreateHolidayCalendarMutation,
  useDeleteHolidayCalendarMutation,
  useUpdateHolidayCalendarMutation,
} from 'domain/holiday/calendar/apiSlice';
import {
  initialValues as createInitialValues,
  formatAttributesToSubmit as createFormatAttributesToSubmit,
  buildValidationSchema as createbuildValidationSchema,
  ICreateHolidayCalendarFormData,
} from 'domain/holiday/calendar/schemas/create';
import {
  initialValues as updateInitialValues,
  formatAttributesToSubmit as updateFormatAttributesToSubmit,
  buildValidationSchema as updatebuildValidationSchema,
  IUpdateHolidayCalendarFormData,
} from 'domain/holiday/calendar/schemas/update';

import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useNotifications } from 'hooks/useNotifications';

import { isCreateMode, isUpdateMode } from 'utils/entityActionType';
import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { IHolidayCalendarListItem } from './types';

const HolidayCalendarListItem: FC<IHolidayCalendarListItem> = props => {
  const { year, type, onCancel = () => {}, selectedYearId, onYearSelect = () => {}, onCreate = () => {} } = props;

  const { t } = useTranslation('adminPanel');
  const { getConfirmation } = useConfirmDialog();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const isCreate = isCreateMode(type);
  const isUpdate = isUpdateMode(type);
  const calendarNameReference = useRef(null);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(isCreate);
  const isCalendarSelected = selectedYearId === year?.id;

  useEffect(() => {
    if (isFormVisible) {
      calendarNameReference.current.focus();
    }
  }, [isFormVisible]);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<IUpdateHolidayCalendarFormData>({
    defaultValues: isCreate ? createInitialValues() : updateInitialValues(year),
    resolver: isCreate ? yupResolver(createbuildValidationSchema()) : yupResolver(updatebuildValidationSchema()),
  });

  const [createHolidayCalendar, { isLoading: isCreateHolidayCalendarLoading }] = useCreateHolidayCalendarMutation();
  const [deleteHolidayCalendar, { isLoading: isDeleteHolidayCalendarLoading }] = useDeleteHolidayCalendarMutation();
  const [updateHolidayCalendar, { isLoading: isUpdateHolidayCalendarLoading }] = useUpdateHolidayCalendarMutation();

  const isLoading = isCreateHolidayCalendarLoading || isDeleteHolidayCalendarLoading || isUpdateHolidayCalendarLoading;

  const handleCreateOrUpdateCalendar = async (
    formData: ICreateHolidayCalendarFormData | IUpdateHolidayCalendarFormData,
  ) => {
    try {
      if (isCreate) {
        await createHolidayCalendar(createFormatAttributesToSubmit(formData)).unwrap();
        onCreate();
      }
      if (isUpdate) {
        await updateHolidayCalendar(updateFormatAttributesToSubmit(formData, year.calendarId)).unwrap();
      }
      showSuccessNotification();
      setIsFormVisible(false);
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleDeleteButtonClick = async () => {
    if (
      await getConfirmation({
        title: '',
        message: t('workingCalendars.deleteCalendar', { calendarName: year.calendarName }),
      })
    ) {
      try {
        await deleteHolidayCalendar(year.calendarId);
        onYearSelect(null);
        showSuccessNotification();
      } catch (error) {
        const errors = generateBackendErrorMessages(error);
        for (const message of errors) {
          showErrorNotification(message);
        }
      }
    }
  };

  const handleCancelEditButtonClick = () => {
    setIsFormVisible(false);
    setValue('name', year?.calendarName);
    onCancel();
  };

  const handleEditButtonClick = () => setIsFormVisible(true);
  const handleCalendarNameClick = () => {
    if (!year) {
      return;
    }
    onYearSelect(year.id);
  };

  return (
    <ListItem sx={styles.listItem}>
      <Div
        sx={MUISx(styles.listItemContainer, {
          condition: isCalendarSelected,
          sx: styles.listItemContainerSelected,
        })}
      >
        <FormGroup sx={styles.form}>
          <Div sx={styles.item}>
            <Div
              onClick={handleCalendarNameClick}
              sx={MUISx(
                styles.textContainer,
                {
                  condition: isCalendarSelected,
                  sx: styles.textContainerSelected,
                },
                {
                  condition: isFormVisible,
                  sx: styles.textContainerFormVisible,
                },
              )}
            >
              <Div sx={styles.form}>
                <Icon name="listPointer" sx={styles.listPointer} />
                {isFormVisible ? (
                  <TextField
                    inputProps={{
                      tabIndex: 0,
                    }}
                    sx={styles.description}
                    inputRef={calendarNameReference}
                    error={Boolean(errors.name)}
                    helperText={buildErrorMessage(t, errors.name)}
                    {...register('name')}
                  />
                ) : (
                  <>
                    <Typography
                      sx={MUISx(styles.calendarName, {
                        condition: isCalendarSelected,
                        sx: styles.calendarNameSelected,
                      })}
                      variant={isCalendarSelected ? 'h4' : 'heading3'}
                    >
                      {getValues('name')}
                    </Typography>
                  </>
                )}
              </Div>
              <Div
                sx={MUISx(styles.actions, {
                  condition: isFormVisible,
                  sx: styles.actionsEditable,
                })}
                data-actions="item-actions"
              >
                {isFormVisible ? (
                  <>
                    <Button variant="text" disabled={isLoading} onClick={handleSubmit(handleCreateOrUpdateCalendar)}>
                      <Typography variant="subtitle3">{t('workingCalendars.save')}</Typography>
                    </Button>
                    <Button
                      variant="text"
                      disabled={isLoading}
                      sx={styles.deleteButton}
                      onClick={handleCancelEditButtonClick}
                    >
                      <Typography variant="subtitle3">{t('workingCalendars.cancel')}</Typography>
                    </Button>
                  </>
                ) : (
                  <>
                    <Button variant="text" disabled={isLoading} onClick={handleEditButtonClick}>
                      <Typography variant="subtitle3">{t('workingCalendars.rename')}</Typography>
                    </Button>
                    <Button
                      variant="text"
                      disabled={isLoading}
                      sx={styles.deleteButton}
                      onClick={handleDeleteButtonClick}
                    >
                      <Typography variant="subtitle3">{t('workingCalendars.delete')}</Typography>
                    </Button>
                  </>
                )}
              </Div>
            </Div>
            {isUpdate && (
              <Div sx={styles.calendarInfo}>
                {isCalendarSelected && (
                  <Div sx={styles.daysInfo}>
                    <Typography variant="subtitle1">
                      {t('workingCalendars.workingDays')}
                      {t('workingCalendars.days', { count: year?.workingDays })}
                    </Typography>
                    <Typography variant="subtitle1">
                      {t('workingCalendars.holidays')}
                      {t('workingCalendars.days', { count: year?.holidays })}
                    </Typography>
                  </Div>
                )}
              </Div>
            )}
          </Div>
        </FormGroup>
      </Div>
    </ListItem>
  );
};

export default HolidayCalendarListItem;
