import { SxStyles } from 'theme';

const styles: SxStyles = {
  submitButton: {
    width: '24px',
    height: '24px',
    color: 'primary.blue',
  },
  container: {
    marginY: 4,
    marginX: 3,
  },
  header: {
    display: 'block',
    marginBottom: 2,
  },
  actions: {
    display: 'flex',
  },
  actionButtonIcon: {
    width: '24px',
    height: '24px',
  },

  titleLine: {
    display: 'flex',
  },
  title: {
    marginRight: 1.25,
  },
  iconButton: {
    padding: 0,
    width: '23px',
    height: '23px',
    marginRight: 1.25,
  },
  editIcon: {
    backgroundColor: 'white',
    color: 'primary.blue',
  },
  disabledIconButton: {
    backgroundColor: 'white',
    color: 'lightgrey',
  },
  destroyButton: {
    color: 'red',
    backgroundColor: 'white',
    padding: 0,
    width: '24px',
    height: '24px',
  },
  destroyIcon: {
    backgroundColor: 'white',
  },
};

export default styles;
