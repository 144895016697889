import { TagTypes, provideTags } from 'shared/api/types';

import { ITimeTrackerTrackable } from 'domain/timeTracker/trackable/types';

import { GroupingOption } from 'enums/GroupingOption';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { CreateExportReportTrackedTimeEntriesExportState } from './enums';
import { IReportTrackedTimeEntries, IReportTrackedTimeEntriesGroupedByUser } from './types';

interface IGetReportTrackedTimeEntriesGroupedByUserResponse {
  report: IReportTrackedTimeEntriesGroupedByUser;
  meta: IMeta;
}

interface IGetReportTrackedTimeEntriesResponse {
  report: IReportTrackedTimeEntries;
  meta: IMeta;
  totalTrackedTime: number;
  billableTrackedTime: number;
  percentOfBillableTrackedTime: number;
  lastTogglSyncDate: string;
}

export interface IReportTrackedTimeEntriesSmartFilters {
  hoursEq?: number | null;
  hoursNoteq?: number | null;
  hoursLteq?: number | null;
  hoursLt?: number | null;
  hoursGteq?: number | null;
  hoursGt?: number | null;
  descriptionCont?: string;
  resourceIdsIn?: Array<number>;
  taskIdsIn?: Array<number>;
  s?: ISortParameter;
  trackables?: Array<ITimeTrackerTrackable>;
  trackableBlank?: boolean;
}
export interface IGetReportTrackedTimeEntriesParameters {
  page?: number | null;
  perPage?: number;
  afterDate?: string;
  beforeDate?: string;
  groupBy?: GroupingOption;
}

interface ICreateExportReportTrackedTimeEntriesExport {
  id: number;
  state: CreateExportReportTrackedTimeEntriesExportState;
}

interface ICreateExportReportTrackedTimeEntriesResponse {
  export: ICreateExportReportTrackedTimeEntriesExport;
}

export const reportTrackedTimeEntryApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getReportTrackedTimeEntries: builder.query<
      IGetReportTrackedTimeEntriesResponse,
      IGetReportTrackedTimeEntriesParameters
    >({
      query: parameters => {
        return {
          url: apiRoutes.apiV1ReportsTrackedTimeEntriesPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ReportTrackedTimeEntry, response?.report?.trackedTimeEntries),
    }),
    getReportTrackedTimeEntriesGroupedByUser: builder.query<
      IGetReportTrackedTimeEntriesGroupedByUserResponse,
      IGetReportTrackedTimeEntriesParameters
    >({
      query: parameters => {
        return {
          url: apiRoutes.apiV1ReportsTrackedTimeEntriesGroupedByUserPath(),
          params: {
            ...parameters,
          },
          method: 'GET',
        };
      },
      providesTags: response => provideTags(TagTypes.ReportTrackedTimeEntryGroupedByUser, response?.report?.users),
    }),
    createCsvReportTrackedTimeEntries: builder.mutation<
      ICreateExportReportTrackedTimeEntriesResponse,
      IGetReportTrackedTimeEntriesParameters
    >({
      query: formData => ({
        url: apiRoutes.createCsvApiV1ReportsTrackedTimeEntriesPath(),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
    }),
    createXlsxReportTrackedTimeEntries: builder.mutation<
      ICreateExportReportTrackedTimeEntriesResponse,
      IGetReportTrackedTimeEntriesParameters
    >({
      query: formData => ({
        url: apiRoutes.createXlsxApiV1ReportsTrackedTimeEntriesPath(),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReportTrackedTimeEntriesQuery,
  useGetReportTrackedTimeEntriesGroupedByUserQuery,
  useCreateCsvReportTrackedTimeEntriesMutation,
  useCreateXlsxReportTrackedTimeEntriesMutation,
} = reportTrackedTimeEntryApi;
