import { FC } from 'react';

import { Typography, Divider, Link as MUILink } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import appRoutes from 'routes/appRoutes';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import styles from './styles';

const Aside: FC = () => {
  const { t } = useTranslation();

  return (
    <Div sx={styles.root}>
      <MUILink component={Link} sx={styles.logoBlock} to={appRoutes.timeTracker.path()}>
        <Div sx={styles.imageBlock} />
        <Div sx={styles.topContent}>
          <Div sx={styles.titleBlock}>
            <Typography sx={styles.title} variant="heading3">
              {t('project')}
            </Typography>
            <Div sx={styles.xBlock}>
              <Icon sx={styles.xIcon} name="x" />
            </Div>
          </Div>
          <Divider sx={styles.divider} />
        </Div>
      </MUILink>
    </Div>
  );
};

export default Aside;
