import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Button } from '@mui/material';
import MUISx from 'mui-sx';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import appRoutes from 'routes/appRoutes';

import { buildValidationSchema, IResetPasswordFormData } from './schema';
import styles from './styles';

const onSubmit = () => {};

const Form: FC = () => {
  const { t } = useTranslation('resetPassword');

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IResetPasswordFormData>({
    resolver: yupResolver(buildValidationSchema()),
  });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <TextField
        sx={styles.input}
        {...register('email')}
        placeholder={t('form.emailField.placeholder')}
        error={Boolean(errors.email)}
        helperText={errors?.email?.message}
        fullWidth
        autoComplete="email"
      />
      <Button sx={MUISx(styles.button, styles.submitButton)} fullWidth type="submit" disabled={isSubmitting}>
        {t('form.resetButton')}
      </Button>
      <Button
        variant="text"
        component={Link}
        to={appRoutes.signIn.path()}
        sx={styles.button}
        fullWidth
        type="button"
        disabled={isSubmitting}
      >
        {t('form.returnToLoginButton')}
      </Button>
    </form>
  );
};

export { Form };
