import { TagTypes, provideTags } from 'shared/api/types';

import { ProjectState } from 'domain/project/enums';

import { GroupByPeriodFilter } from 'enums/GroupByPeriodFilter';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { CreateExportReportHoursByProjectsExportState, CreateExportReportHoursByProjectExportState } from './enums';
import {
  IReportHoursByProjectsColumnTitle,
  IReportHoursByProjectReport,
  IReportHoursByProjects,
  IReportHoursByProjectColumnDetailedProjectTitle,
  IReportHoursByProjectsTotalHours,
} from './types';

export interface IReportHoursByProjectsSmartFilters {
  resourceNameEq?: string;
  projectIdsIn?: Array<number>;
  resourceStateEq?: ProjectState | '';
  billableEq?: boolean | null;
}

interface IGetReportHoursByProjectsResponse {
  report: IReportHoursByProjects;
  meta: IMeta;
  columnTitles: Array<IReportHoursByProjectsColumnTitle>;
  totalHours: IReportHoursByProjectsTotalHours;
}

export interface IGetReportHoursByProjectResponse {
  report: IReportHoursByProjectReport;
  columnTitles: Array<IReportHoursByProjectColumnDetailedProjectTitle>;
}

interface IGetReportHoursByProjectQueryParameters {
  groupByPeriod: GroupByPeriodFilter;
  startDate: string;
  endDate: string;
}

interface IGetReportHoursByProjectPlainParameters extends IGetReportHoursByProjectQueryParameters {
  projectId: string;
}

interface IGetReportHoursByProjectParameters {
  projectId: string;
  parameters: IGetReportHoursByProjectQueryParameters;
}

interface IGetReportHoursByProjectsQueryParameters extends IReportHoursByProjectsSmartFilters {
  startDate: string;
  endDate: string;
  stateEq?: ProjectState;
  sort?: ISortParameter;
  nameCont?: string;
  perPage?: number;
  page?: number;
}

export const loadingGetReportHoursByProjectParameters = (
  parameters: IGetReportHoursByProjectPlainParameters,
): IGetReportHoursByProjectParameters => {
  const { projectId, ...queryParameters } = parameters;
  return {
    projectId,
    parameters: { ...queryParameters },
  };
};

interface ICreateExportReportHoursByProjectsExport {
  id: number;
  state: CreateExportReportHoursByProjectsExportState;
}

interface ICreateExportReportHoursByProjectsResponse {
  export: ICreateExportReportHoursByProjectsExport;
}

interface ICreateExportReportHoursByProjectExport {
  id: number;
  state: CreateExportReportHoursByProjectExportState;
}

interface ICreateExportReportHoursByProjectResponse {
  export: ICreateExportReportHoursByProjectExport;
}

export const reportHoursByProjectApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getReportHoursByProjects: builder.query<
      IGetReportHoursByProjectsResponse,
      IGetReportHoursByProjectsQueryParameters
    >({
      query: parameters => {
        return {
          url: apiRoutes.apiV1ReportsHoursByProjectsPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ReportHoursByProject, response?.report?.trackables),
    }),
    getReportHoursByProject: builder.query<IGetReportHoursByProjectResponse, IGetReportHoursByProjectParameters>({
      query: ({ projectId, parameters }) => ({
        url: apiRoutes.apiV1ReportsHoursByProjectPath(projectId),
        method: 'GET',
        params: {
          ...parameters,
        },
      }),
      providesTags: ({ report: { project } }) => [{ type: TagTypes.ReportHoursByProject, id: project.id }],
    }),
    createCsvReportHoursByProjects: builder.mutation<
      ICreateExportReportHoursByProjectsResponse,
      IGetReportHoursByProjectsQueryParameters
    >({
      query: formData => ({
        url: apiRoutes.createCsvApiV1ReportsHoursByProjectsPath(),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
    }),
    createCsvReportHoursByProject: builder.mutation<
      ICreateExportReportHoursByProjectResponse,
      IGetReportHoursByProjectParameters
    >({
      query: ({ projectId, formData }) => ({
        url: apiRoutes.createCsvApiV1ReportsHoursByProjectPath(projectId),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
    }),
    createXlsxReportHoursByProjects: builder.mutation<
      ICreateExportReportHoursByProjectsResponse,
      IGetReportHoursByProjectsQueryParameters
    >({
      query: formData => ({
        url: apiRoutes.createXlsxApiV1ReportsHoursByProjectsPath(),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
    }),
    createXlsxReportHoursByProject: builder.mutation<
      ICreateExportReportHoursByProjectResponse,
      IGetReportHoursByProjectParameters
    >({
      query: ({ projectId, formData }) => ({
        url: apiRoutes.createXlsxApiV1ReportsHoursByProjectPath(projectId),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReportHoursByProjectsQuery,
  useCreateCsvReportHoursByProjectsMutation,
  useCreateCsvReportHoursByProjectMutation,
  useCreateXlsxReportHoursByProjectsMutation,
  useCreateXlsxReportHoursByProjectMutation,
  useGetReportHoursByProjectQuery,
} = reportHoursByProjectApi;
