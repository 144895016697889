import { TableCell } from '@mui/material';
import { isWeekend } from 'date-fns';
import MUISx from 'mui-sx';

import { isDateInVacationPeriod } from './service';
import styles from './styles';
import { IDayCell } from './types';

const DayCell = (props: IDayCell) => {
  const {
    date,
    vacationsByState: { completed, approved, requested },
    isMonthEndDate,
    isRightBorderHidden,
  } = props;

  const isWeekendDay = isWeekend(date);
  const isApprovedDay = !isWeekendDay && isDateInVacationPeriod(date, approved);
  const isCompletedDay = !isWeekendDay && isDateInVacationPeriod(date, completed);
  const isRequestedDay = !isWeekendDay && isDateInVacationPeriod(date, requested);

  return (
    <TableCell
      sx={MUISx(
        styles.day,
        { condition: isWeekendDay, sx: styles.weekendDay },
        { condition: isApprovedDay, sx: styles.approvedDay },
        { condition: isCompletedDay, sx: styles.completedDay },
        { condition: isRequestedDay, sx: styles.requestedDay },
        { condition: isMonthEndDate, sx: styles.monthEndDate },
        { condition: isRightBorderHidden, sx: styles.hiddenRightBorder },
      )}
    />
  );
};

export default DayCell;
