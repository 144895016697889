import { FC, useContext } from 'react';

import { Button, Typography } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import EmptyValue from 'components/common/EmptyValue';
import UpdateVacancyFteWithDatesForm from 'components/common/UpdateVacancyFteWithDatesForm';

import { ModalContext } from 'contexts/ModalContext';

import styles from './styles';
import { IVacancyFteWithDatesButtonProps } from './types';

const VacancyFteWithDatesButton: FC<IVacancyFteWithDatesButtonProps> = props => {
  const { vacancy, groupId, project } = props;

  const { t } = useTranslation('vacancyFteWithDatesButton');

  const { openModal } = useContext(ModalContext);

  const { fte } = vacancy;
  const { id: projectId, name: projectName } = project;

  const handleWorkloadChangeClick = () => {
    const title = `${t('Vacancy')} - ${projectName}`;

    openModal({
      title,
      content: <UpdateVacancyFteWithDatesForm vacancy={vacancy} groupId={groupId} projectId={projectId} />,
    });
  };

  const isEmptyWorkload = _.isNil(fte);

  return (
    <Button variant="outlined" sx={styles.transparentButton} onClick={handleWorkloadChangeClick}>
      <Typography
        sx={MUISx(styles.workloadBlock, { condition: isEmptyWorkload, sx: styles.emptyWorkloadBlock })}
        component="p"
        variant="heading3"
      >
        {_.isNil(fte) ? <EmptyValue /> : `${fte}%`}
      </Typography>
    </Button>
  );
};

export default VacancyFteWithDatesButton;
