import { FC, SyntheticEvent } from 'react';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getInitialValue } from 'components/common/SmartFilter/components/Slot/service';
import { SmartFilterDescriptionVacationRequestState } from 'components/common/SmartFilter/enums';
import { VACATION_REQUEST_STATUS_COMPARISONS } from 'components/common/SmartFilter/relations';

import { capitalizeFirstLetter } from 'utils/string';

import styles from './styles';
import { IVacationRequestStatusProps } from './types';

const STATE_DEFAULT = SmartFilterDescriptionVacationRequestState.indefinite;

const VACATION_REQUEST_STATUS_VALUES = {
  [SmartFilterDescriptionVacationRequestState.all]: 'all',
  [SmartFilterDescriptionVacationRequestState.indefinite]: 'indefinite',
  [SmartFilterDescriptionVacationRequestState.confirmed]: 'confirmed',
  [SmartFilterDescriptionVacationRequestState.declined]: 'declined',
};

const VacationRequestStatus: FC<IVacationRequestStatusProps> = props => {
  const { slot, onSlotPatch } = props;

  const { t } = useTranslation(['smartFilter']);

  const initialValue = getInitialValue(slot.value, STATE_DEFAULT, VACATION_REQUEST_STATUS_VALUES);

  const handleComparisonChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    const newValue = { id: null, name: VACATION_REQUEST_STATUS_VALUES[value] };

    onSlotPatch(slot.id, { value: newValue });
  };

  return (
    <FormControl>
      <FormLabel sx={styles.title} id="status-buttons-group-label">
        <Typography sx={styles.titleText} variant="heading4">
          {t('smartFilter:status.title')}
        </Typography>
      </FormLabel>
      <RadioGroup
        aria-labelledby="status-comparison-radio-buttons-group"
        name="status-comparison"
        value={initialValue}
        onChange={handleComparisonChange}
      >
        {VACATION_REQUEST_STATUS_COMPARISONS.map(({ description }) => {
          const labelText =
            description === SmartFilterDescriptionVacationRequestState.indefinite
              ? t('smartFilter:status.labelPending')
              : description;

          const label = (
            <Typography sx={styles.label} variant="subtitle1">
              {capitalizeFirstLetter(labelText)}
            </Typography>
          );

          return (
            <FormControlLabel
              sx={styles.checkboxInput}
              key={description}
              value={description}
              control={<Radio size="small" />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default VacationRequestStatus;
