import { FC, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateProjectBudgetMonthMutation } from 'domain/project/budget/month/apiSlice';
import { getNormilizeStartDate } from 'domain/project/budget/month/presenter';
import {
  formatAttributesToSubmit,
  initialValues,
  IUpdateProjectMonthBudgetFormData,
  buildValidationSchema,
} from 'domain/project/budget/month/schemas/update';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { IUpdateProjectMonthBudgetFormProps } from './types';

const UpdateProjectMonthBudgetForm: FC<IUpdateProjectMonthBudgetFormProps> = props => {
  const { projectId, budget } = props;

  const { t } = useTranslation('reportHoursByProject');

  const { closeModal } = useContext(ModalContext);

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [updateProjectBudgetMonth, { isLoading: isUpdateProjectBudgetMonthLoading }] =
    useUpdateProjectBudgetMonthMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateProjectMonthBudgetFormData>({
    defaultValues: initialValues(budget),
    resolver: yupResolver(buildValidationSchema()),
  });

  const onSubmit = async (formData: IUpdateProjectMonthBudgetFormData) => {
    try {
      await updateProjectBudgetMonth(formatAttributesToSubmit(projectId, budget.id, formData)).unwrap();

      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.root}>
        <TextField
          sx={styles.input}
          {...register('budget')}
          label={getNormilizeStartDate(budget)}
          InputLabelProps={{ sx: styles.placeholder }}
          error={Boolean(errors.budget)}
          helperText={buildErrorMessage(t, errors.budget)}
        />

        <Div>
          <Button sx={styles.saveBudgetButton} type="submit" disabled={isUpdateProjectBudgetMonthLoading}>
            {t('projectBudgetMonths.table.actions.updateProjectMonthBudgetForm.buttons.save')}
          </Button>
          <Button type="reset" variant="outlined" onClick={closeModal} disabled={isUpdateProjectBudgetMonthLoading}>
            {t('projectBudgetMonths.table.actions.updateProjectMonthBudgetForm.buttons.cancel')}
          </Button>
        </Div>
      </Div>
    </form>
  );
};

export default UpdateProjectMonthBudgetForm;
