import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { ITechnology } from '../types';

export interface IUpdateTechnologyFormData {
  name?: string;
  parentTechnology: ITechnology;
}
export interface IUpdateTechnologyPreparedFormData {
  parentId?: number | null;
  name?: string;
}

export interface IUpdateTechnologyFormDataToSubmit {
  formData: IUpdateTechnologyPreparedFormData;
  technologyId: number;
}

export const buildValidationFields = () => ({
  name: yup
    .string()
    .max(30, i18n.t('forms:validation:maxSymbols', { count: 30 }))
    .default(''),
  parentTechnology: yup.mixed<ITechnology>().nullable().default(null),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (currentTechnology: ITechnology, currentParentTechnology: ITechnology) => {
  return {
    name: currentTechnology.name,
    parentTechnology: currentParentTechnology,
  };
};

export const formatAttributesToSubmit = (
  technologyId: number,
  formData: IUpdateTechnologyFormData,
): IUpdateTechnologyFormDataToSubmit => {
  const { name, parentTechnology } = formData;

  const parentId = _.isNil(parentTechnology) ? null : parentTechnology.id;

  return {
    technologyId,
    formData: {
      parentId,
      name,
    },
  };
};
