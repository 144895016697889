import { IResourceAbility } from 'domain/resource/ability/types';
import { IUser } from 'domain/user/types';

import { INormalizedTree } from './components/UserSpecializationTree/types';

export const prepareUserProfileStackAbilityForUpdate = (treeItem: INormalizedTree, user: IUser): IResourceAbility => {
  return {
    id: treeItem.id,
    resourceId: user.resource.id,
    skillLevel: treeItem.skillLevel,
    technologyId: treeItem.technologyId,
  };
};
