import { FC } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Button, TextField, IconButton, InputAdornment } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import styles from './styles';
import { ISearchBarProps } from './types';

const SearchBar: FC<ISearchBarProps> = props => {
  const { placeholder, buttonLabel, onAddNodeButtonClick, searchString, setSearchString, buttonWidth } = props;

  return (
    <Div sx={styles.root}>
      <TextField
        placeholder={placeholder}
        value={searchString}
        onChange={event => setSearchString(event.target.value)}
        sx={styles.searchInput}
        inputProps={{ maxLength: 300 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {_.isEmpty(searchString) ? (
                <IconButton disabled>
                  <SearchIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => setSearchString('')}>
                  <Icon name="closeWithDarkCircle" />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Button
        sx={MUISx({ condition: !_.isNil(buttonWidth), sx: { width: `${buttonWidth}px` } })}
        onClick={onAddNodeButtonClick}
      >
        {buttonLabel}
      </Button>
    </Div>
  );
};

export default SearchBar;
