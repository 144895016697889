import { FC, useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, MenuItem, TextField } from '@mui/material';
import camelize from 'camelize';
import _ from 'lodash';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import TechnologiesAutocomplete from 'components/common/TechnologiesAutocomplete';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateAccessLevelMutation } from 'domain/accessLevel/apiSlice';
import {
  IUpdateAccessLevelFormData,
  formatAttributesToSubmit,
  buildValidationSchema,
  initialValues,
} from 'domain/accessLevel/schemas/update';
import { permissionOptions } from 'domain/accessLevel/service';
import { useGetTechnologiesQuery } from 'domain/technology/apiSlice';
import { ITechnology } from 'domain/technology/types';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';
import { getSortParameter } from 'utils/runsack';

import { prepareUserProfileAccessLevelToUpdate } from './service';
import styles from './styles';
import { IUpdateUserProfileAdditionAccessLevelFormProps } from './types';

const UpdateUserProfileAdditionAccessLevelForm: FC<IUpdateUserProfileAdditionAccessLevelFormProps> = props => {
  const { userProfileAccessLevel, user, isUserProfileProjectAccessLevel } = props;

  const { t } = useTranslation(['userProfile']);

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const { closeModal } = useContext(ModalContext);

  const [updateUserProfileAdditionAccessLevel, { isLoading: isUpdateUserProfileAdditionAccessLevelLoading }] =
    useUpdateAccessLevelMutation();

  const [technologyName, setTechnologyName] = useState<string>('');

  const [debouncedTechnologyName] = useDebounce(technologyName, 300);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IUpdateAccessLevelFormData>({
    defaultValues: initialValues(prepareUserProfileAccessLevelToUpdate(userProfileAccessLevel, user)),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    data: technologiesData,
    isLoading: isTechnologiesDataLoading,
    isError: isTechnologiesDataLoadingError,
    isFetching: isTechnologiesDataFetching,
  } = useGetTechnologiesQuery(
    {
      nameCont: debouncedTechnologyName,
      sort: getSortParameter('descendants_names', 'asc'),
    },
    { skip: isUserProfileProjectAccessLevel },
  );

  const {
    field: { value: permission, onChange: onPermissionChange },
  } = useController({ name: 'permission', control });

  const {
    field: { value: technologies, onChange: onTechnologiesChange },
  } = useController({ name: 'technologies', control });

  const onSubmit = async (formData: IUpdateAccessLevelFormData) => {
    try {
      await updateUserProfileAdditionAccessLevel(
        formatAttributesToSubmit(formData, userProfileAccessLevel.id),
      ).unwrap();

      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const handleCurrentTechnologiesChange = (technologies: Array<ITechnology>) => {
    if (_.isEmpty(technologies)) {
      onTechnologiesChange([]);
      return;
    }
    onTechnologiesChange(technologies);
  };

  const isDataLoading = isTechnologiesDataLoading;
  const isError = isTechnologiesDataLoadingError;

  if (isDataLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }
  const userAccessLevelType = camelize(userProfileAccessLevel.accessibleType);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.root}>
        {!isUserProfileProjectAccessLevel && (
          <Div sx={styles.select}>
            <TechnologiesAutocomplete
              technologies={technologiesData.technologies}
              currentTechnologies={technologies}
              onCurrentTechnologiesChange={handleCurrentTechnologiesChange}
              setTechnologyName={setTechnologyName}
              isError={Boolean(errors.technologies)}
              isTechnologiesFetching={isTechnologiesDataFetching}
              label={t('createResourceUsageAndVacancy:createResourceUsageForm.practice.label')}
              helperText={buildErrorMessage(t, errors.technologies)}
            />
          </Div>
        )}

        <TextField
          sx={styles.select}
          select
          error={Boolean(errors.permission)}
          value={camelize(permission) ?? ''}
          onChange={onPermissionChange}
          label={t(
            'userProfile:accessLevel.userProfileAccessLevel.updateAdditionalAccessForm.selects.permission.label',
          )}
          InputLabelProps={{ sx: styles.selectPlaceholder }}
        >
          {permissionOptions[userAccessLevelType].map(permissionOption => {
            return (
              <MenuItem sx={styles.permissionValue} key={permissionOption.id} value={permissionOption.id}>
                {permissionOption.name}
              </MenuItem>
            );
          })}
        </TextField>

        <Div sx={styles.buttons}>
          <Button sx={styles.saveButton} type="submit" disabled={isUpdateUserProfileAdditionAccessLevelLoading}>
            {t('userProfile:accessLevel.userProfileAccessLevel.updateAdditionalAccessForm.buttons.save')}
          </Button>
          <Button
            type="reset"
            variant="outlined"
            onClick={closeModal}
            disabled={isUpdateUserProfileAdditionAccessLevelLoading}
          >
            {t('userProfile:accessLevel.userProfileAccessLevel.updateAdditionalAccessForm.buttons.cancel')}
          </Button>
        </Div>
      </Div>
    </form>
  );
};

export default UpdateUserProfileAdditionAccessLevelForm;
