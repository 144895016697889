import {
  format,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  eachWeekOfInterval,
  eachDayOfInterval,
  isSameMonth,
  isWithinInterval,
  parse,
  isSameDay,
  isFriday,
  isMonday,
  isFirstDayOfMonth,
  isLastDayOfMonth,
} from 'date-fns';

import { isWorkingDay } from 'domain/holiday/calendar/year/service';
import { ICalendarDay, ICalendarMonth } from 'domain/holiday/calendar/year/types';
import { IVacation } from 'domain/resource/vacation/types';

import { IWeekStartsOn } from 'types/dates';

import { getCurrentUserLocaleString } from './userLangLocale';

const MONTH_NUMBER = 12;
export const FIRST_DAY_OF_MONTH = 1;
export const DATE_FORMAT = 'yyyy-MM-dd';

export const stringToDate = (date: string) => {
  return parse(date, DATE_FORMAT, new Date());
};

export const dateToString = (date: Date) => {
  return format(date, DATE_FORMAT);
};

export const getCurrentYear = () => {
  return Number(format(new Date(), 'yyyy'));
};

export const generateMonths = (year: number) => {
  return Array.from({ length: MONTH_NUMBER }, (_, index) => startOfMonth(new Date(year, index, FIRST_DAY_OF_MONTH)));
};

export const generateWeeks = (startOfMonth: Date, weekStartsOn: IWeekStartsOn) => {
  return eachWeekOfInterval(
    {
      start: startOfMonth,
      end: endOfMonth(startOfMonth),
    },
    {
      weekStartsOn,
    },
  );
};

type DayState = {
  vacation: IVacation | null;
  roundLeft: boolean;
  roundRight: boolean;
};

const getDayInfo = (day: Date, vacations: Array<IVacation>): DayState => {
  for (const vacation of vacations) {
    const start = stringToDate(vacation.startDate);
    const end = stringToDate(vacation.endDate);
    const inInterval = isWithinInterval(day, {
      start,
      end,
    });

    if (inInterval) {
      return {
        vacation,
        roundLeft: isSameDay(day, start) || isMonday(day) || isFirstDayOfMonth(day),
        roundRight: isSameDay(day, end) || isFriday(day) || isLastDayOfMonth(day),
      };
    }
  }

  return {
    vacation: null,
    roundLeft: false,
    roundRight: false,
  };
};

const getDateDetails = (dayToFind: Date, holidays: Array<ICalendarDay>) => {
  const dayString = dateToString(dayToFind);
  return holidays.find(dayItem => dayItem.date === dayString);
};

interface IGenerateDaysOptions {
  vacations: Array<IVacation>;
  weekStartsOn: IWeekStartsOn;
  holidays: Array<ICalendarDay>;
}

export const generateDays = (startOfWeek: Date, startOfMonth: Date, options: IGenerateDaysOptions) => {
  const { vacations = [], holidays = [], weekStartsOn = 0 } = options;

  const daysInWeek = eachDayOfInterval({
    start: startOfWeek,
    end: endOfWeek(startOfWeek, { weekStartsOn }),
  });

  return daysInWeek.map(day => {
    const dayInfo = getDayInfo(day, vacations);

    return {
      day,
      label: format(day, 'd'),
      show: isSameMonth(day, startOfMonth),
      vacation: dayInfo.vacation,
      roundLeft: dayInfo.roundLeft,
      roundRight: dayInfo.roundRight,
      dateDetails: getDateDetails(day, holidays),
    };
  });
};

export const toVacationRange = (date: Date) => {
  return format(date, 'MMM d');
};

export const toOnApprovalRange = (date: Date) => {
  return format(date, 'MMMM d');
};

export const adjustWeekDays = (weekDays, weekStartsOn) => {
  const index = weekDays.findIndex(day => day.index === weekStartsOn);
  return [...weekDays.slice(index), ...weekDays.slice(0, index)];
};

export const getWeekStartsOn = () => {
  const currentUserLocale = getCurrentUserLocaleString();

  if (currentUserLocale === 'ru') {
    return 1;
  }

  return 0;
};

export const getMonthWorkingHours = (monthsInfo: Array<ICalendarMonth>, monthNumber: number): number | null => {
  const month = monthsInfo.find(monthItem => monthItem.number === monthNumber);

  if (!month) {
    return null;
  }

  return month.workingHours;
};

export const getVacationDaysCount = (daysInfo: Array<ICalendarDay>, startDate: string, endDate: string) => {
  const daysInVacation = eachDayOfInterval({
    start: stringToDate(startDate),
    end: stringToDate(endDate),
  });

  const days = daysInVacation.map(date => {
    const dayString = dateToString(date);
    return daysInfo.find(day => day.date === dayString);
  });

  const workingDays = days.filter(day => isWorkingDay(day));
  return workingDays.length;
};

export const getCurrentDate = () => new Date().getDate();
