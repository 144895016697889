import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: '24px',
    height: '24px',
    marginRight: 1,
  },
};

export default styles;
