import { getListTag, TagTypes, invalidateTags, provideTags } from 'shared/api/types';

import { ConditionState } from 'components/common/SmartFilter/enums';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { ICreateTagFormDataToSubmit, IUpdateTagFormDataToSubmit } from './schemas/form';
import { ITag } from './types';

interface IGetTagsParameters {
  perPage?: number;
  page?: number;
  nameCont?: string;
  sort?: ISortParameter;
  stateEq?: ConditionState;
  paginated?: boolean;
}

interface ITagsResponse {
  meta: IMeta;
  tags: Array<ITag>;
}

interface ITagResponse {
  tag: ITag;
}

export const tagApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getTags: builder.query<ITagsResponse, IGetTagsParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1TagsPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.Tag, response?.tags),
    }),
    createTag: builder.mutation<ITagsResponse, ICreateTagFormDataToSubmit>({
      query: formData => ({
        url: apiRoutes.apiV1TagsPath(),
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: invalidateTags(getListTag(TagTypes.Tag)),
    }),
    updateTag: builder.mutation<ITagResponse, IUpdateTagFormDataToSubmit>({
      query: ({ id, formData }) => ({
        url: apiRoutes.apiV1TagPath(id),
        method: 'PUT',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: invalidateTags(getListTag(TagTypes.Tag)),
    }),
    deleteTag: builder.mutation<void, number>({
      query: id => ({
        url: apiRoutes.apiV1TagPath(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateTags(getListTag(TagTypes.Tag)),
    }),
    activateTag: builder.mutation<ITagResponse, number>({
      query: id => ({
        url: apiRoutes.activateApiV1TagPath(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateTags(getListTag(TagTypes.Tag)),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTagsQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useActivateTagMutation,
  useDeleteTagMutation,
} = tagApi;
