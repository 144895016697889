import _ from 'lodash';

import { IHolidayCalendarYear } from './types';

export const getCurrentOrFirstYearId = (
  years: Array<IHolidayCalendarYear>,
  currentYearToFind: number,
): number | null => {
  const currentYear = years.find(({ year }) => year === currentYearToFind);
  const defaultYear = _.head(years);

  return (currentYear ?? defaultYear)?.id ?? null;
};
