import { FC } from 'react';

import Div from 'components/common/Div';

import styles from './styles';

const Divider: FC = () => {
  return (
    <Div sx={styles.root}>
      <Div sx={styles.divider} />
    </Div>
  );
};

export default Divider;
