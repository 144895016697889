import { FC, useContext } from 'react';

import { Button, Typography } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import EmptyValue from 'components/common/EmptyValue';

import { ModalContext } from 'contexts/ModalContext';

import UpdateBudgetForm from './components/UpdateBudgetForm';
import styles from './styles';
import { IBudgetProps } from './types';

const Budget: FC<IBudgetProps> = props => {
  const { hours, project, budgetType, totalBudgetId, monthlyBudgetId, canUpdateBudgets } = props;
  const { openModal } = useContext(ModalContext);

  const { t } = useTranslation('reportHoursByProject');

  const isEmptyHours = _.isNil(hours);

  const handleChangeBudgetClick = () => {
    openModal({
      title: t('info.updateBudgetFormTitle'),
      content: (
        <UpdateBudgetForm
          totalBudgetId={totalBudgetId}
          monthlyBudgetId={monthlyBudgetId}
          budgetType={budgetType}
          project={project}
          canUpdateBudgets={canUpdateBudgets}
        />
      ),
    });
  };

  return (
    <Div sx={styles.changeBudgetButtonBlock}>
      <Button sx={styles.changeBudgetButton} onClick={handleChangeBudgetClick} variant="text">
        <Typography
          sx={MUISx(styles.changeBudgetButtonHours, {
            condition: isEmptyHours,
            sx: styles.changeBudgetButtonHoursEmpty,
          })}
          variant="body1"
        >
          {hours ?? <EmptyValue />}
        </Typography>
      </Button>
      <Typography variant="body1">{t('info.hoursText')}</Typography>
    </Div>
  );
};

export default Budget;
