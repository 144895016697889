import { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Form } from './Form';
import styles from './styles';

const ResetPassword: FC = () => {
  const { t } = useTranslation('resetPassword');

  return (
    <>
      <Typography sx={styles.title} variant="h3">
        {t('title')}
      </Typography>

      <Typography sx={styles.subtitle} variant="subtitle2">
        {t('subtitle')}
      </Typography>

      <Form />
    </>
  );
};

export default ResetPassword;
