import { SxStyles } from 'theme';

const styles: SxStyles = {
  listItem: {
    '&:hover div[data-task=task-content]': {
      backgroundColor: 'background.lightGray',
      transition: 'background-color 0.2s linear',
      borderRadius: '4px',
    },
    '&:hover div[data-task=task-actions]': {
      visibility: 'visible',
      opacity: 1,
      transition: 'visibility 0.2s linear, opacity 0.2s linear',
    },
  },
  listPointer: {
    marginTop: 0.625,
  },
  listPointerIcon: {
    width: '16px',
    height: '40px',
  },
  taskContent: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 1,
  },
  taskName: {
    marginRight: 12.5,
  },
  archivedTaskName: {
    color: 'text.lightGray',
    marginRight: 1,
  },
  disabledChip: {
    paddingY: 0,
    paddingX: 1,
    marginRight: 8.75,
    color: 'text.lightGray',
    backgroundColor: 'background.gray',
    borderRadius: '4px',
  },
  buttons: {
    opacity: 0,
    visibility: 'hidden',
  },
  disableButtonText: {
    color: 'text.green',
  },
  enableButtonText: {
    color: 'text.red',
  },
};

export default styles;
