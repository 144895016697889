import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    marginY: 4,
    marginX: 3,
  },
  header: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 5,
  },
  actionEdit: {
    cursor: 'pointer',
    color: 'primary.blue',
  },
};

export default styles;
