export default {
  title: 'Allocation',
  exportButton: 'Export',
  createAllocationButton: 'Create Allocation',
  createAllocationModalTitle: 'Create Allocation',
  filter: {
    resources_one: '{{ count }} Allocation Entry',
    resources_other: '{{ count }} Allocation Entries',
  },
  table: {
    columnsHeaders: {
      resource: 'Resource',
      project: 'Project',
      specialization: 'Specialization',
      fte: 'FTE%',
      startDate: 'Start Date',
      endDate: 'End Date',
    },
  },
  createAllocationForm: {
    period: 'Period',
    selects: {
      name: {
        label: 'Name',
      },
    },
    fields: {
      project: {
        label: 'Project',
      },
      resource: {
        label: 'Resource',
      },
      resourceStack: {
        label: 'Resource Stack',
      },
      startDate: {
        label: 'Start Date',
      },
      endDate: {
        label: 'End Date',
      },
    },
    switches: {
      endDate: {
        label: 'End Date',
      },
    },
    buttons: {
      addAllocation: 'Add allocation',
      cancel: 'Cancel',
    },
  },
};
