import { SxStyles } from 'theme';

const styles: SxStyles = {
  row: {
    display: 'flex',
    marginTop: 0,
    marginRight: 2.5,
    marginBottom: 2.5,
    marginLeft: 3,
    height: '56px',
    width: '500px',
    borderRadius: '8px',
  },
};

export default styles;
