import { FC } from 'react';

import { Skeleton, Stack } from '@mui/material';
import _ from 'lodash';

import Div from 'components/common/Div';

import { ITEMS_PER_PAGE } from 'utils/pagination';

import styles from './styles';
import { ITableSkeletonProps } from './types';

const TableSkeleton: FC<ITableSkeletonProps> = props => {
  const { perPage = ITEMS_PER_PAGE } = props;

  const renderTableSkeleton = () => {
    return _.range(0, perPage, 1).map(counter => (
      <Div sx={styles.rowWrapper} key={counter}>
        <Skeleton animation="pulse" variant="rectangular" sx={styles.row} />
      </Div>
    ));
  };

  return <Stack sx={styles.wrapper}>{renderTableSkeleton()}</Stack>;
};

export default TableSkeleton;
