import { FC } from 'react';

import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { generateWeeks, getMonthWorkingHours } from 'utils/calendar';

import Week from './components/Week';
import WeekDays from './components/WeekDays';
import styles from './styles';
import { IMonthProps } from './types';

const Month: FC<IMonthProps> = props => {
  const {
    month,
    vacations,
    onSelectionStart,
    onSelectionEnd,
    startSelection,
    endSelection,
    weekStartsOn,
    interactionMode,
    onDayClick,
    monthsInfo,
    monthNumber,
    holidays,
  } = props;
  const { t } = useTranslation('userProfile');

  const monthName = format(month, 'MMMM');
  const weeks = generateWeeks(month, weekStartsOn);
  const workingHours = getMonthWorkingHours(monthsInfo, monthNumber);
  const monthWorkingHours = t('vacation.workingHours', { hours: workingHours });

  return (
    <Div sx={styles.root}>
      <Div sx={styles.header}>
        <Typography variant="h5">{monthName}</Typography>
        {workingHours && (
          <Typography sx={styles.workingHours} variant="h6">
            {monthWorkingHours}
          </Typography>
        )}
      </Div>
      <WeekDays weekStartsOn={weekStartsOn} />
      {weeks.map(week => (
        <Week
          startSelection={startSelection}
          endSelection={endSelection}
          onSelectionStart={onSelectionStart}
          onSelectionEnd={onSelectionEnd}
          week={week}
          month={month}
          vacations={vacations}
          key={week.toJSON()}
          weekStartsOn={weekStartsOn}
          interactionMode={interactionMode}
          onDayClick={onDayClick}
          holidays={holidays}
        />
      ))}
    </Div>
  );
};

export default Month;
