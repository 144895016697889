import { FC } from 'react';

import { Avatar, Typography } from '@mui/material';

import Div from 'components/common/Div';

import styles from './styles';
import { IVacationsCalendarTableNameCellProps } from './types';

const TableNameCell: FC<IVacationsCalendarTableNameCellProps> = props => {
  const { fullName } = props;

  return (
    <Div sx={styles.root}>
      <Avatar sx={styles.avatar} alt="avatar" />
      <Typography variant="heading3">{fullName}</Typography>
    </Div>
  );
};

export default TableNameCell;
