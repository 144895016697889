import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  select: {
    paddingTop: 0.625,
  },
  buttonsContainer: {
    width: '380px',
    marginTop: 4,
    display: 'flex',
    gap: 1.5,
  },
  datePickerField: {
    width: '188px',
  },
  datePicker: {
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'space-between',
  },
};

export default styles;
