import { FC } from 'react';

import { Alert, List } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { loadingGetTrackingTypesParameters, useGetTrackingTypesQuery } from 'domain/trackingType/apiSlice';

import { getSortParameter } from 'utils/runsack';

import MainListItem from './components/MainListItem';

const TrackingTypes: FC = () => {
  const { t } = useTranslation(['common', 'adminPanel']);

  const trackingTypesQueryParameters = loadingGetTrackingTypesParameters({
    s: getSortParameter('name', 'asc'),
  });
  const {
    data: trackingTypesData,
    isLoading: isTrackingTypesDataLoading,
    isError: isTrackingTypesDataLoadingError,
  } = useGetTrackingTypesQuery(trackingTypesQueryParameters);

  if (isTrackingTypesDataLoading) {
    return <Loader />;
  }

  if (isTrackingTypesDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  return (
    <Div>
      <List>
        <MainListItem title={t('adminPanel:trackingTypes.billable')} />

        <MainListItem
          title={t('adminPanel:trackingTypes.nonBillable')}
          trackingTypes={trackingTypesData.trackingTypes}
        />
      </List>
    </Div>
  );
};

export default TrackingTypes;
