import { FC, useRef, useState } from 'react';

import { Alert } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Loader from 'components/common/Loader';
import MultipleAutocomplete from 'components/common/SmartFilter/components/MultipleAutocomplete';
import { SlotVariant } from 'components/common/SmartFilter/enums';
import { IMultipleSlot } from 'components/common/SmartFilter/types';

import {
  loadingGetTimeTrackerTrackablesParameters,
  useGetTimeTrackerTrackablesQuery,
} from 'domain/timeTracker/trackable/apiSlice';

import { IAutocompleteOption } from 'types/autocomplete';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { getSortParameter } from 'utils/runsack';

import { ITrackablesWithBlankProps } from './types';

const TrackablesWithBlank: FC<ITrackablesWithBlankProps> = props => {
  const { slot, onSlotPatch, setOpenPopover } = props;
  const value = slot.value as Array<IMultipleSlot>;

  const isBlank = useRef(value.some(option => option.blank));
  const setIsBlank = (value: boolean) => (isBlank.current = value);

  const { t } = useTranslation(['common', 'smartFilter']);

  const [timeTrackerTrackableName, setTimeTrackerTrackableName] = useState<string>('');

  const [debouncedTimeTrackerTrackableName] = useDebounce(timeTrackerTrackableName, DEFAULT_DEBOUNCE_DELAY_MS);

  const timeTrackerTrackablesParameters = loadingGetTimeTrackerTrackablesParameters({
    nameCont: debouncedTimeTrackerTrackableName,
    paginated: false,
    s: getSortParameter('name', 'asc'),
  });
  const {
    data: timeTrackerTrackablesData,
    isLoading: isTimeTrackerTrackablesDataLoading,
    isFetching: isTimeTrackerTrackablesDataFetching,
    isError: isTimeTrackerTrackablesDataLoadingError,
  } = useGetTimeTrackerTrackablesQuery(timeTrackerTrackablesParameters);

  if (isTimeTrackerTrackablesDataLoading) {
    return <Loader />;
  }

  if (isTimeTrackerTrackablesDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCurrentTrackablesChange = (trackables: Array<IAutocompleteOption>, filterWithEnter = false) => {
    if (isBlank.current) {
      onSlotPatch(slot.id, { value: [{ id: null, name: null, blank: true }] }, filterWithEnter);
      return;
    }

    if (_.isEmpty(trackables)) {
      onSlotPatch(
        slot.id,
        {
          value: [],
        },
        filterWithEnter,
      );
      return;
    }

    const value = trackables.map(trackable => ({
      id: trackable.id,
      name: trackable.name,
      entireObject: trackable,
    }));

    onSlotPatch(
      slot.id,
      {
        value,
      },
      filterWithEnter,
    );
  };

  const preparedOption = timeTrackerTrackablesData.trackables.map(trackable => ({
    ...trackable,
    category: SlotVariant.project,
  }));

  const preparedCurrentOption = preparedOption.filter(option =>
    value.some(item => _.isEqual(item.id, option.id) && _.isEqual(option.type, item.entireObject?.type)),
  );

  return (
    <MultipleAutocomplete
      options={preparedOption}
      currentOptions={preparedCurrentOption}
      isFetching={isTimeTrackerTrackablesDataFetching}
      onCurrentOptionsChange={handleCurrentTrackablesChange}
      setName={setTimeTrackerTrackableName}
      name={timeTrackerTrackableName}
      label={t('smartFilter:trackables.label')}
      isBlank={isBlank.current}
      setIsBlank={setIsBlank}
      blankLabel={t('smartFilter:trackables.blankLabel')}
      showBlankOption
      setOpenPopover={setOpenPopover}
    />
  );
};

export default TrackablesWithBlank;
