import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    padding: 6.25,
    borderRadius: '8px',
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: 'text.gray',
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  dialogTitle: {
    marginBottom: 2.25,
    padding: 0,
  },
  title: {
    marginBottom: 1.25,
  },
  subTitle: {
    color: 'text.blackLight',
  },
  dialogContent: {
    padding: 0,
  },
};

export default styles;
