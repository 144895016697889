import { FC, useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Button, FormControlLabel, Switch, Typography, Alert } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import _ from 'lodash';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Autocomplete from 'components/common/Autocomplete';
import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import TechnologiesAutocomplete from 'components/common/TechnologiesAutocomplete';

import { ModalContext } from 'contexts/ModalContext';

import { useGetProjectsQuery } from 'domain/project/apiSlice';
import { IProject } from 'domain/project/types';
import { useGetResourcesQuery } from 'domain/resource/apiSlice';
import { IResource } from 'domain/resource/types';
import { useCreateResourceUsageMutation } from 'domain/resource/usage/apiSlice';
import {
  formatAttributesToSubmit,
  ICreateAllocationForResourceFormData,
  initialValues,
  validationSchema,
} from 'domain/resource/usage/schemas/createAllocationForResource';
import { useGetTechnologiesQuery } from 'domain/technology/apiSlice';
import { ITechnology } from 'domain/technology/types';

import { MeasurementUnitFilter } from 'enums/MeasurementUnitFilter';

import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';
import { getFullName } from 'utils/person';
import { getSortParameter } from 'utils/runsack';

import styles from './styles';
import { ICreateAllocationProps } from './types';

const CreateResourceAllocation: FC<ICreateAllocationProps> = props => {
  const { currentResource, currentProject } = props;
  const { closeModal } = useContext(ModalContext);

  const [resourceName, setResourceName] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [technologyName, setTechnologyName] = useState<string>('');
  const [hasEndDate, setHasEndDate] = useState<boolean>(false);

  const { t } = useTranslation(['common', 'allocation']);
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [createResourceUsage, { isLoading: isCreateResourceUsageLoading }] = useCreateResourceUsageMutation();

  const [debouncedTechnologyName] = useDebounce(technologyName, DEFAULT_DEBOUNCE_DELAY_MS);
  const [debouncedProjectName] = useDebounce(projectName, DEFAULT_DEBOUNCE_DELAY_MS);
  const [debouncedResourceName] = useDebounce(resourceName, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: resourcesData,
    isLoading: isResourcesDataLoading,
    isError: isResourcesDataLoadingError,
    isFetching: isResourcesDataFetching,
  } = useGetResourcesQuery({
    nameCont: debouncedResourceName,
    sort: getSortParameter('name', 'asc'),
  });

  const {
    data: teachnologiesData,
    isLoading: isTechnologiesDataLoading,
    isError: isTechnologiesDataLoadingError,
    isFetching: isTehcnologiesDataFetching,
  } = useGetTechnologiesQuery({
    nameCont: debouncedTechnologyName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const {
    data: projectsData,
    isLoading: isProjectsDataLoading,
    isError: isProjectsDataLoadingError,
    isFetching: isProjectsDataFetching,
  } = useGetProjectsQuery({
    nameCont: debouncedProjectName,
    sort: getSortParameter('name', 'asc'),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ICreateAllocationForResourceFormData>({
    defaultValues: initialValues(currentResource, currentProject),
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!hasEndDate) {
      setValue('endDate', null);
    }
  }, [hasEndDate]);

  const {
    field: { value: project, onChange: onProjectChange },
  } = useController({ name: 'project', control });

  const {
    field: { value: resource, onChange: onResourceChange },
  } = useController({ name: 'resource', control });

  const {
    field: { value: technologies, onChange: onTechnologiesChange },
  } = useController({ name: 'technologies', control });

  const {
    field: { value: startDate, onChange: onStartDateChange },
  } = useController({ name: 'startDate', control });

  const {
    field: { value: endDate, onChange: onEndDateChange },
  } = useController({ name: 'endDate', control });

  const onSubmit = async (formData: ICreateAllocationForResourceFormData) => {
    try {
      await createResourceUsage(formatAttributesToSubmit(formData)).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const isDataLoading = isTechnologiesDataLoading || isProjectsDataLoading || isResourcesDataLoading;
  const isError = isTechnologiesDataLoadingError || isProjectsDataLoadingError || isResourcesDataLoadingError;

  if (isDataLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCurrentProjectChange = (currentProject: IProject) => {
    if (_.isNil(currentProject)) {
      onProjectChange(null);
      return;
    }
    onProjectChange(currentProject);
  };

  const handleCurrentResourceChange = (currentResource: IResource) => {
    if (_.isNil(currentResource)) {
      onResourceChange(null);
      return;
    }
    onResourceChange(currentResource);
  };

  const handleCurrentTechnologiesChange = (technologies: Array<ITechnology>) => {
    if (_.isEmpty(technologies)) {
      onTechnologiesChange([]);
      return;
    }
    onTechnologiesChange(technologies);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.root}>
        <Div sx={styles.select}>
          <Autocomplete
            isPerson
            isRequired
            options={resourcesData.resources}
            isFetching={isResourcesDataFetching}
            isError={Boolean(errors.resource)}
            name={getFullName(resource)}
            setName={setResourceName}
            onCurrentValueChange={handleCurrentResourceChange}
            currentValue={resource}
            label={t('createResourceUsageAndVacancy:selects.name.label')}
            helperText={buildErrorMessage(t, errors.resource)}
          />
        </Div>
        <Div sx={styles.select}>
          <Autocomplete
            isRequired
            options={projectsData.projects}
            isFetching={isProjectsDataFetching}
            isError={Boolean(errors.project)}
            name={projectName}
            setName={setProjectName}
            onCurrentValueChange={handleCurrentProjectChange}
            currentValue={project}
            label={t('allocation:createAllocationForm.fields.project.label')}
            helperText={buildErrorMessage(t, errors.project)}
          />
        </Div>
        <Div sx={styles.select}>
          <TechnologiesAutocomplete
            technologies={teachnologiesData.technologies}
            currentTechnologies={technologies}
            onCurrentTechnologiesChange={handleCurrentTechnologiesChange}
            setTechnologyName={setTechnologyName}
            isError={Boolean(errors.technologies)}
            isTechnologiesFetching={isTehcnologiesDataFetching}
            label={t('allocation:createAllocationForm.fields.resourceStack.label')}
            isRequired
            helperText={buildErrorMessage(t, errors.technologies)}
          />
        </Div>

        <Div sx={styles.dates}>
          <Typography variant="h4" component="p">
            {t('allocation:createAllocationForm.period')}
          </Typography>
          <FormControlLabel
            sx={styles.endDateSwitch}
            control={<Switch checked={hasEndDate} onChange={() => setHasEndDate(!hasEndDate)} />}
            label={
              <Typography variant="subtitle1">{t('allocation:createAllocationForm.switches.endDate.label')}</Typography>
            }
          />
        </Div>

        <Div sx={styles.groupFields}>
          <Div sx={styles.datePicker}>
            <DatePicker
              label={t('allocation:createAllocationForm.fields.startDate.label')}
              value={startDate}
              onChange={onStartDateChange}
              renderInput={parameters => (
                <TextField
                  required
                  {...parameters}
                  error={Boolean(errors.startDate)}
                  helperText={buildErrorMessage(t, errors.startDate)}
                  sx={styles.datePickerField}
                />
              )}
            />
          </Div>

          <Div sx={styles.datePicker}>
            <DatePicker
              label={t('allocation:createAllocationForm.fields.endDate.label')}
              value={endDate}
              onChange={onEndDateChange}
              disabled={!hasEndDate}
              renderInput={parameters => (
                <TextField
                  {...parameters}
                  helperText={buildErrorMessage(t, errors.endDate)}
                  error={Boolean(errors.endDate)}
                  sx={styles.datePickerField}
                />
              )}
            />
          </Div>

          <TextField
            required
            inputProps={{ minLength: 1, maxLength: 3 }}
            sx={styles.input}
            {...register('workload')}
            label={`${MeasurementUnitFilter.FTE} %`}
            InputLabelProps={{ sx: styles.selectPlaceholder }}
            error={Boolean(errors.workload)}
          />
        </Div>

        <Div>
          <Button type="submit" sx={styles.addButton} disabled={isCreateResourceUsageLoading}>
            {t('allocation:createAllocationForm.buttons.addAllocation')}
          </Button>
          <Button type="reset" onClick={closeModal} variant="outlined" disabled={isCreateResourceUsageLoading}>
            {t('allocation:createAllocationForm.buttons.cancel')}
          </Button>
        </Div>
      </Div>
    </form>
  );
};

export default CreateResourceAllocation;
