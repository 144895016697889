import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    marginY: 4,
    marginX: 0,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  yearInfo: {
    width: '324px',
    marginRight: 2.75,
    flexShrink: 0,
  },
  calendar: {
    width: '100%',
  },
  deleteButton: {
    color: 'text.red',
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
  },
  yearSwitcher: {
    marginBottom: 2.75,
  },
};

export default styles;
