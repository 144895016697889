import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    paddingTop: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  createAdditionalAccessButton: {
    marginRight: 1.25,
  },
  select: {
    width: '400px',
    marginBottom: 2,
    textTransform: 'capitalize',
  },
  selectPlaceholder: {
    color: 'text.gray',
  },
  buttons: {
    marginTop: 2,
  },
  permissionValue: {
    textTransform: 'capitalize',
  },
};

export default styles;
