import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    whiteSpace: 'pre-line',
  },
};

export default styles;
