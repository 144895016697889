import { FC, useState } from 'react';

import { ExpandMoreOutlined } from '@mui/icons-material';
import { Autocomplete as MUIAutocomplete, CircularProgress, TextField, Typography } from '@mui/material';

import { IAutocompleteOption } from 'types/autocomplete';
import { IPerson } from 'types/common';

import { getFullName } from 'utils/person';

import styles from './styles';
import { IAutocompleteProps } from './types';

const Autocomplete: FC<IAutocompleteProps> = props => {
  const {
    currentValue,
    onCurrentValueChange,
    options,
    isError,
    helperText = '',
    setName,
    isFetching,
    label,
    name,
    isRequired = false,
    isPerson = false,
  } = props;

  const [isAutoCompleteOpen, setIsAutocompleteOpen] = useState<boolean>(false);

  const handleProjectsAutocompleteChange = (value: string) => {
    if (currentValue && value === currentValue.name) {
      return;
    }
    if (value !== name) {
      setName(value);
    }
  };

  const handleAutocompleteOpen = () => {
    setIsAutocompleteOpen(true);
  };

  const handleAutocompleteClose = () => {
    setIsAutocompleteOpen(false);
  };

  return (
    <MUIAutocomplete
      open={isAutoCompleteOpen}
      onOpen={handleAutocompleteOpen}
      onClose={handleAutocompleteClose}
      autoComplete
      popupIcon={<ExpandMoreOutlined />}
      getOptionLabel={(value: IAutocompleteOption) => {
        if (isPerson) {
          const fullName = getFullName(value as IPerson);

          return fullName ?? '';
        }

        return String(value.name) ?? '';
      }}
      isOptionEqualToValue={(option: IAutocompleteOption, value: IAutocompleteOption) => option.id === value.id}
      options={options}
      sx={styles.select}
      onChange={(_, value: IAutocompleteOption) => onCurrentValueChange(value)}
      value={currentValue}
      onInputChange={(_, value) => handleProjectsAutocompleteChange(value)}
      renderInput={parameters => (
        <TextField
          value={name}
          {...parameters}
          required={isRequired}
          error={isError}
          helperText={helperText}
          label={label}
          InputLabelProps={{ sx: styles.selectPlaceholder }}
          InputProps={{
            ...parameters.InputProps,
            endAdornment: (
              <>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {parameters.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, value: IAutocompleteOption) => {
        const name = isPerson ? getFullName(value as IPerson) : value.name;

        return (
          <Typography {...props} key={value.id}>
            {name}
          </Typography>
        );
      }}
    />
  );
};
export default Autocomplete;
