import { FC } from 'react';

import Div from 'components/common/Div';

import { generateMonths } from 'utils/calendar';

import Month from './components/Month';
import { CalendarInteractionMode } from './enums';
import styles from './styles';
import { ICalendarProps } from './types';

const Calendar: FC<ICalendarProps> = props => {
  const {
    year,
    weekStartsOn = 0,
    interactionMode = CalendarInteractionMode.range,
    vacations = [],
    startSelection = null,
    endSelection = null,
    onSelectionStart = () => {},
    onSelectionEnd = () => {},
    onDayClick = () => {},
    monthsInfo = [],
    holidays = [],
  } = props;

  const months = generateMonths(year);

  return (
    <Div sx={styles.root}>
      {months.map((month, index) => (
        <Month
          month={month}
          monthsInfo={monthsInfo}
          monthNumber={index + 1}
          holidays={holidays}
          key={month.toJSON()}
          vacations={vacations}
          startSelection={startSelection}
          endSelection={endSelection}
          onSelectionStart={onSelectionStart}
          onSelectionEnd={onSelectionEnd}
          weekStartsOn={weekStartsOn}
          interactionMode={interactionMode}
          onDayClick={onDayClick}
        />
      ))}
    </Div>
  );
};

export default Calendar;
