import { FC, useState } from 'react';

import Div from 'components/common/Div';

import {
  useApproveResourceVacationMutation,
  useDeclineResourceVacationMutation,
} from 'domain/resource/vacation/apiSlice';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';

import VacationRequestExpandedContent from './components/VacationRequestExpandedContent';
import VacationRequestVisibleContent from './components/VacationRequestVisibleContent';
import styles from './styles';
import { IVacationRequestItemProps } from './types';

const VacationRequestItem: FC<IVacationRequestItemProps> = props => {
  const { approvementId, vacationRequest, approvementState, withBackground, approvingLevelName, approvingLevelType } =
    props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [declineResourceVacation, { isLoading: isDeclineResourceVacationLoading }] =
    useDeclineResourceVacationMutation();
  const [approveResourceVacation, { isLoading: isApproveResourceVacationLoading }] =
    useApproveResourceVacationMutation();

  const handleDeleteResourceVacation = async () => {
    try {
      await declineResourceVacation({
        id: approvementId,
        vacationId: vacationRequest.id,
        resourceId: vacationRequest.resourceId,
      }).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleApproveResourceVacation = async () => {
    try {
      await approveResourceVacation({
        id: approvementId,
        vacationId: vacationRequest.id,
        resourceId: vacationRequest.resourceId,
      }).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const isLoading = isApproveResourceVacationLoading || isDeclineResourceVacationLoading;
  const handleExpandButtonClick = () => setIsExpanded(previousValue => !previousValue);

  return (
    <Div sx={styles.root}>
      <VacationRequestVisibleContent
        isLoading={isLoading}
        isExpanded={isExpanded}
        withBackground={withBackground}
        vacationRequest={vacationRequest}
        approvementState={approvementState}
        approvingLevelName={approvingLevelName}
        approvingLevelType={approvingLevelType}
        onExpand={handleExpandButtonClick}
        onApprove={handleApproveResourceVacation}
        onDecline={handleDeleteResourceVacation}
      />
      {isExpanded && <VacationRequestExpandedContent vacationRequest={vacationRequest} />}
    </Div>
  );
};

export default VacationRequestItem;
