import { FC, SyntheticEvent } from 'react';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getInitialValue } from 'components/common/SmartFilter/components/Slot/service';
import { SmartFilterDescriptionBillable } from 'components/common/SmartFilter/enums';
import { BILLABLE_COMPARISONS } from 'components/common/SmartFilter/relations';

import { capitalizeFirstLetter } from 'utils/string';

import styles from './styles';
import { IBillableProps } from './types';

const BILLABLE_DEFAULT = SmartFilterDescriptionBillable.all;

const BILLABLE_VALUES = {
  [SmartFilterDescriptionBillable.all]: null,
  [SmartFilterDescriptionBillable.billable]: true,
  [SmartFilterDescriptionBillable.nonBillable]: false,
};

const Billable: FC<IBillableProps> = props => {
  const { slot, onSlotPatch } = props;

  const { t } = useTranslation(['smartFilter']);

  const initialValue = getInitialValue(slot.value, BILLABLE_DEFAULT, BILLABLE_VALUES);

  const handleComparisonChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    const newValue = value === BILLABLE_DEFAULT ? null : { id: null, name: BILLABLE_VALUES[value] };

    onSlotPatch(slot.id, { value: newValue });
  };

  return (
    <FormControl>
      <FormLabel sx={styles.title} id="status-buttons-group-label">
        <Typography sx={styles.titleText} variant="heading4">
          {t('smartFilter:status.title')}
        </Typography>
      </FormLabel>
      <RadioGroup
        aria-labelledby="status-comparison-radio-buttons-group"
        name="status-comparison"
        value={initialValue}
        onChange={handleComparisonChange}
      >
        {BILLABLE_COMPARISONS.map(comparison => {
          const label = (
            <Typography sx={styles.label} variant="subtitle1">
              {capitalizeFirstLetter(comparison.description)}
            </Typography>
          );

          return (
            <FormControlLabel
              sx={styles.checkboxInput}
              key={comparison.description}
              value={comparison.description}
              control={<Radio size="small" />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default Billable;
