import { add, eachMonthOfInterval, format, getMonth } from 'date-fns';

import { getCountDaysOfMonth, getFirstAndLastDateOfMonthForYear, getRangeDates, isWeekendDay } from 'utils/dateTime';

import { IMonthItem } from './types';

export const getVacationRequestsMonths = (
  startPeriodDate: Date,
  endPeriodDate: Date,
): Array<{ name: string; monthIndex: number }> => {
  return eachMonthOfInterval({ start: startPeriodDate, end: endPeriodDate }).map(d => {
    return { name: format(d, 'MMMM').toLocaleLowerCase(), monthIndex: getMonth(d) };
  });
};

export const getMonthsHeadersData = (year: number, endDate: string, startDate: string): Array<IMonthItem> => {
  const startPeriodDate = add(new Date(startDate), { days: -15 });
  const endPeriodDate = add(new Date(endDate), { days: 15 });

  const months = getVacationRequestsMonths(startPeriodDate, endPeriodDate);

  return months.reduce((accumulator, month) => {
    const daysCount = getCountDaysOfMonth(year, month.monthIndex);

    let { firstDate, lastDate } = getFirstAndLastDateOfMonthForYear(year, month.monthIndex);

    if (month.monthIndex === getMonth(startPeriodDate)) {
      firstDate = startPeriodDate;
    }

    if (month.monthIndex === getMonth(endPeriodDate)) {
      lastDate = endPeriodDate;
    }

    const rangeDates = getRangeDates(firstDate, lastDate);

    const item = {
      title: month.name,
      firstDay: Number.parseInt(format(firstDate, 'dd')),
      lastDay: Number.parseInt(format(lastDate, 'dd')),
      daysCount: daysCount,
      weekendAccessors: rangeDates
        .filter(date => isWeekendDay(date))
        .map(date => `${month.name}-${Number(date.getDate())}`),
      lastDayAccessor: `${month.name}-${Number(lastDate.getDate())}`,
    };
    return [...accumulator, item];
  }, []);
};

export const getVacationRangeAccesors = (startDate: string, endDate: string): Array<string> => {
  const vacationStartDate = new Date(startDate.replaceAll('-', '/'));
  const vacationEndDate = new Date(endDate.replaceAll('-', '/'));

  const vacationRanges = getRangeDates(vacationStartDate, vacationEndDate);
  return vacationRanges.map(date => `${format(date, 'MMMM').toLocaleLowerCase()}-${Number(date.getDate())}`);
};
