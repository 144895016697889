import { FC } from 'react';

import { Typography } from '@mui/material';

import styles from './styles';
import { IWorkloadProps } from './types';

const Workload: FC<IWorkloadProps> = props => {
  const { workload } = props;

  return <Typography sx={styles.workloadBlock}>{`${workload} %`}</Typography>;
};

export default Workload;
