import { GroupByPeriodFilter } from 'enums/GroupByPeriodFilter';

export const getGroupByPeriodFilter = (filter: GroupByPeriodFilter) => ({
  isDayPeriod: filter === GroupByPeriodFilter.day,
  isWeekPeriod: filter === GroupByPeriodFilter.week,
  isMonthPeriod: filter === GroupByPeriodFilter.month,
  isWholePeriod: filter === GroupByPeriodFilter.wholePeriod,
});

export const sortOptionsBySelection = (options, selectedOptions) => {
  return options.sort((a, b) => {
    if (selectedOptions.includes(a.id) && !selectedOptions.includes(b.id)) {
      return -1;
    }
    if (!selectedOptions.includes(a.id) && selectedOptions.includes(b.id)) {
      return 1;
    }
    return 0;
  });
};
