import { FC } from 'react';

import { Typography } from '@mui/material';

import styles from './styles';

const EmptyValue: FC = () => {
  return <Typography sx={styles.root} component="span" />;
};

export default EmptyValue;
