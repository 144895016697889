import { SxStyles } from 'theme';

const styles: SxStyles = {
  listItem: {
    display: 'block',
  },
  listItemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 0,
    marginBottom: 1,
  },
  listItemContainerSelected: {
    backgroundColor: 'background.frostBlue',
    borderRadius: '8px',
  },
  listPointer: {
    width: '5px',
    height: '5px',
    color: 'text.lightGray',
    position: 'absolute',
    top: 'calc(50% - 5px / 2)',
    left: '10px',
  },
  listItemText: {
    display: 'inline',
    flexGrow: 0,
    outline: '0px solid transparent',
  },
  textContainer: {
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    paddingY: 1,
    paddingRight: 1,
    paddingLeft: 3,
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'background.gray',
    },
    '&:hover div[data-actions=item-actions]': {
      visibility: 'visible',
      opacity: 1,
    },
  },
  textContainerSelected: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  textContainerFormVisible: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  actions: {
    position: 'absolute',
    top: '6px',
    right: '8px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    visibility: 'hidden',
    marginLeft: 'auto',
    minWidth: '125px',
    backgroundColor: 'inherit',
  },
  actionsEditable: {
    visibility: 'initial',
  },
  deleteButton: {
    color: 'text.red',
  },
  description: {
    border: 'none',
    '& fieldset': {
      border: 'none',
    },
    '& > div': {
      color: 'text.primary',
    },
    '& > div:hover fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      border: 'none',
    },
    '& input': {
      fontSize: 16,
      fontWeight: 400,
      padding: 0,
      cursor: 'initial',
    },
  },
  calendarName: {
    cursor: 'pointer',
    maxWidth: '140px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    userSelect: 'none',
  },
  calendarNameSelected: {
    color: 'text.darkBlue',
  },
  daysInfo: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'background.breezeBlue',
    borderRadius: '0px 0px 8px 8px',
    paddingY: 1,
    paddingRight: 1,
    paddingLeft: 3,
    gap: 1,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    width: '100%',
  },
};

export default styles;
