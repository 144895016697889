import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    paddingRight: 2,
  },
  title: {
    marginBottom: 4,
    paddingTop: 4,
  },
  header: {
    position: 'sticky',
    top: '-2em',
    zIndex: '1',
    backgroundColor: 'background.default',
  },
  loadMoreButtonBlock: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default styles;
