import { FC } from 'react';

import { Typography } from '@mui/material';

import { IReportVacationsCalendarTableBodyTotalCell } from './types';

const TableBodyTotalCell: FC<IReportVacationsCalendarTableBodyTotalCell> = props => {
  const { role } = props;

  return <Typography variant="heading3">{role}</Typography>;
};

export default TableBodyTotalCell;
