import { FC } from 'react';

import { Alert, TableHead, Table as MUITable, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';
import { usePagination, useTable } from 'react-table';

import Div from 'components/common/Div';
import TableSkeleton from 'components/common/TableSkeleton';

import styles from './styles';
import { IProjectBudgetMonthsTableProps } from './types';

const Table: FC<IProjectBudgetMonthsTableProps> = props => {
  const { data = [], columns = [], isLoading = false, isError = false } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    usePagination,
  );

  const { t } = useTranslation('table');

  const isTableDataEmpty = page.length === 0;

  const isDataEmpty = isTableDataEmpty && !isLoading;

  if (isError) {
    return <Alert severity="error">{t('errorText')}</Alert>;
  }

  return (
    <Div sx={styles.root}>
      <MUITable sx={MUISx(styles.table, { condition: isLoading, sx: styles.tableLoading })} {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => {
            return (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex, columns) => {
                  const isLastColumn = columns.length - 1 === columnIndex;

                  return (
                    <TableCell
                      sx={MUISx(styles.column, { condition: isLastColumn, sx: styles.lastColumn })}
                      {...column.getHeaderProps({
                        style: {
                          width: column.width ?? 'auto',
                          minWidth: column.minWidth ?? 'auto',
                          maxWidth: column.maxWidth ?? 'auto',
                        },
                      })}
                    >
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);

            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex, cells) => {
                  const isLastColumn = cells.length - 1 === cellIndex;

                  return (
                    <TableCell
                      sx={MUISx(styles.column, { condition: isLastColumn, sx: styles.lastColumn })}
                      {...cell.getCellProps({
                        style: {
                          width: cell.column.width ?? 'auto',
                          minWidth: cell.column.minWidth ?? 'auto',
                          maxWidth: cell.column.maxWidth ?? 'auto',
                        },
                      })}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MUITable>

      {isLoading && <TableSkeleton perPage={pageSize} />}
      {isDataEmpty && <Typography textAlign="center">{t('noData')}</Typography>}
    </Div>
  );
};

export default Table;
