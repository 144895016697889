import theme, { SxStyles } from 'theme';

const styles: SxStyles = {
  cell: {
    height: 41,
    p: 0.75,
    borderRight: `3px solid ${theme.palette.border.main}`,
    bgcolor: theme.palette.common.white,
  },
  resource: {
    borderRightWidth: 1,
  },
  stickyCell: {
    position: 'sticky',
    zIndex: theme.zIndex.fab,
  },
  skeleton: {
    height: 1,
  },
  boldRightBorder: {
    borderRightWidth: 3,
  },
  hiddenRightBorder: {
    borderRight: 'none',
  },
};

export default styles;
