import React, { FC } from 'react';

import { Search } from '@mui/icons-material';
import { CircularProgress, InputAdornment, TextField } from '@mui/material';

import Div from 'components/common/Div';

import styles from './styles';
import { IProjectTasksInputProps } from './types';

const ProjectTasksInput: FC<IProjectTasksInputProps> = ({
  inputValue,
  onInputChange,
  placeholder,
  isFetching,
  isError,
  tasksModalReference,
}) => {
  const handleChange = event => {
    onInputChange(event.target.value);
  };
  return (
    <Div sx={styles.box}>
      <TextField
        fullWidth
        autoFocus
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
        error={isError}
        inputRef={tasksModalReference}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search sx={styles.searchIcon} />
            </InputAdornment>
          ),
          endAdornment: isFetching ? (
            <InputAdornment position="end">
              <CircularProgress color="inherit" size={20} />
            </InputAdornment>
          ) : null,
        }}
      />
    </Div>
  );
};

export default ProjectTasksInput;
