export default {
  validation: {
    email: 'Неправильный email-адрес',
    required: 'Необходимо заполнить это поле',
    password: 'Пароли не совпадают',
    minimumSymbols: 'Пароль должен содержать минимум 8 символов',
    maxDigitsAfterDecimalTest: 'maxDigitsAfterDecimal',
    maxTwoDigitsAfterDecimal: 'Число должно содержать до 2-х знаков после запятой',
    maxOneDigitsAfterDecimal: 'Число должно содержать один знак после запятой',
    maxSymbols_one: 'Строка должна содержать максимум {{ count }} символ',
    maxSymbols_other: 'Строка должна содержать максимум {{ count }} символов',
    mustBeNumber: 'Некорректное число',
    minNumber: 'Число должно быть больше {{ count }}',
    maxNumber: 'Число должно быть меньше {{ count }}',
    positiveNumber: 'Число должно быть больше нуля',
    startDateBeforeEndDate: 'Дата окончания не может быть до даты начала',
    startDateBeforeStartBillableDate: 'Начало оплаты не должно быть до даты начала проекта',
    endDateBeforeStartBillableDate: 'Дата окончания не может быть до даты начала оплаты',
    betweenNumber: 'Число должно быть больше {{ count1 }} и меньше {{ count2 }}',
    phoneNumber: 'Некорретный номер телефона',
    minItemsInArray_one: 'Поле должно содержать как минимум {{ count }} элемент',
    minItemsInArray_other: 'Поле должно содержать как минимум {{ count }} элементов',
    description: 'Описание не может быть пустым',
    trackable: 'Необходимо указать проект',
  },
};
