import { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import styles from './styles';
import { IDaysInfo } from './types';

const DaysInfo: FC<IDaysInfo> = props => {
  const { vacationRequest } = props;
  const { t } = useTranslation('reportVacations');

  return (
    <Div sx={styles.daysInfo}>
      <Div sx={styles.available}>
        <Typography variant="subtitle4" sx={styles.daysTitle}>
          {t('vacationRequests.available', { year: vacationRequest.year })}
        </Typography>
        <Typography variant="subtitle3" sx={styles.daysAvailable}>
          {vacationRequest.available}
        </Typography>
      </Div>
      <Div sx={styles.scheduled}>
        <Typography variant="subtitle4" sx={styles.daysTitle}>
          {t('vacationRequests.scheduled', { year: vacationRequest.year })}
        </Typography>
        <Typography variant="subtitle3" sx={styles.daysScheduled}>
          {vacationRequest.scheduled}
        </Typography>
      </Div>
    </Div>
  );
};

export default DaysInfo;
