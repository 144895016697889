import { TagTypes, provideTags } from 'shared/api/types';

import { ConditionState } from 'components/common/SmartFilter/enums';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { ICreateUserFormDataToSubmit } from './schemas/create';
import { IUpdateUserFormDataToSubmit } from './schemas/update';
import { IUser } from './types';

export interface IUsersSmartFilters {
  resourceIdsIn?: Array<number>;
  resourceNameEq?: string;
  groupIdEq?: number | null;
  resourceStateEq?: ConditionState | '';
}

interface IGetUsersParameters {
  perPage?: number;
  page?: number;
  stateEq?: ConditionState;
  nameOrEmailCont?: string;
  sort?: ISortParameter;
}

interface IUserResponse {
  user: IUser;
}

interface IUsersResponse {
  users: Array<IUser>;
  meta: IMeta;
}

export const loadingGetUsersParameters = (parameters: IGetUsersParameters) => {
  const { perPage, page, stateEq, nameOrEmailCont, sort } = parameters;
  return {
    perPage,
    page,
    stateEq,
    nameOrEmailCont,
    sort,
  };
};

export const userApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getUsers: builder.query<IUsersResponse, IGetUsersParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1UsersPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.User, response?.users),
    }),
    getUser: builder.query<IUserResponse, number>({
      query: id => ({
        url: apiRoutes.apiV1UserPath(id),
        method: 'GET',
      }),
      providesTags: ({ user }) => [{ type: TagTypes.User, id: user.id }],
    }),
    deleteUser: builder.mutation<void, number>({
      query: id => ({
        url: apiRoutes.apiV1UserPath(id),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.User, TagTypes.CurrentUser],
    }),
    activateUser: builder.mutation<IUserResponse, number>({
      query: id => ({
        url: apiRoutes.activateApiV1UserPath(id),
        method: 'PUT',
      }),
      invalidatesTags: ({ user }) => [{ type: TagTypes.User, id: user.id }, TagTypes.CurrentUser],
    }),
    updateUser: builder.mutation<IUserResponse, IUpdateUserFormDataToSubmit>({
      query: ({ id, formData }) => ({
        url: apiRoutes.apiV1UserPath(id),
        method: 'PUT',
        data: formData,
      }),
      invalidatesTags: ({ user }) => [{ type: TagTypes.User, id: user.id }],
    }),
    createUser: builder.mutation<IUserResponse, ICreateUserFormDataToSubmit>({
      query: formData => ({
        url: apiRoutes.apiV1UsersPath(),
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: [TagTypes.User],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useDeleteUserMutation,
  useActivateUserMutation,
  useUpdateUserMutation,
  useCreateUserMutation,
} = userApi;
