import { MONTHS } from 'utils/dateTime';

import { IProjectBudgetMonth } from './types';

export const getNormilizeStartDate = (budgetMonth: IProjectBudgetMonth): string => {
  const { number, year } = budgetMonth;

  const monthName = MONTHS.find(month => month.id === number).title;

  return `${monthName} ${year}`;
};
