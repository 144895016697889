import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { IReportMigrationsResource } from './types';

interface IGetReportMigrationsResponse {
  usersMigrations: Array<IReportMigrationsResource>;
  meta: IMeta;
}

export interface IReportMigrationsSmartFilters {
  groupIdsIn?: Array<number>;
  projectIdsIn?: Array<number>;
  resourceIdsIn?: Array<number>;
  technologyIdsIn?: Array<number>;
}

interface IGetReportMigrationsParameters extends IReportMigrationsSmartFilters {
  start_date: string;
}

export const reportMigrationsApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getReportMigrations: builder.query<IGetReportMigrationsResponse, IGetReportMigrationsParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1ReportsUsersMigrationsPath(parameters),
          method: 'GET',
          params: parameters,
        };
      },
      providesTags: props => {
        if (!props) return ['reportMigrations'];
        const { usersMigrations } = props;
        return usersMigrations
          ? [...usersMigrations.map(({ id }) => ({ type: 'reportMigrations' as const, id })), 'reportMigrations']
          : ['reportMigrations'];
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetReportMigrationsQuery } = reportMigrationsApi;
