import { IPaginationParameters } from 'types/api';

export const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50];
export const [ITEMS_PER_PAGE] = PER_PAGE_OPTIONS;
export const MAX_ITEMS_PER_PAGE = PER_PAGE_OPTIONS.at(-1);
export const FIRST_PAGE = 1;

export const defaultPaginationParameters: IPaginationParameters = {
  pageNumber: FIRST_PAGE,
  pageSize: ITEMS_PER_PAGE,
};
