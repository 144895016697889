import { FC, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateVacancyMutation } from 'domain/vacancy/apiSlice';
import {
  formatAttributesToSubmit,
  initialValues,
  IUpdateVacancyFormData,
  buildValidationSchema,
} from 'domain/vacancy/schemas/update';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { IUpdateVacancyEndDateFormProps } from './types';

const UpdateVacancyEndDateForm: FC<IUpdateVacancyEndDateFormProps> = props => {
  const { projectId, groupId, vacancy } = props;

  const { t } = useTranslation('vacancyActions');

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const { closeModal } = useContext(ModalContext);

  const [updateVacancy, { isLoading: isUpdateVacancyLoading }] = useUpdateVacancyMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IUpdateVacancyFormData>({
    defaultValues: initialValues(vacancy),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: endDate, onChange: onEndDateChange },
  } = useController({ name: 'endDate', control });

  const onSubmit = async (formData: IUpdateVacancyFormData) => {
    try {
      await updateVacancy(formatAttributesToSubmit(projectId, groupId, vacancy.id, formData)).unwrap();

      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.endDatePicker}>
        <DatePicker
          label={t('removeVacancyEndDateForm.fields.endDate.label')}
          value={endDate}
          onChange={onEndDateChange}
          disabled={isUpdateVacancyLoading}
          renderInput={parameters => (
            <TextField
              {...parameters}
              error={Boolean(errors.endDate)}
              helperText={buildErrorMessage(t, errors.endDate)}
            />
          )}
        />
      </Div>

      <Div sx={styles.buttons}>
        <Button sx={styles.removeButton} type="submit" disabled={isUpdateVacancyLoading}>
          {t('removeVacancyEndDateForm.buttons.remove')}
        </Button>
        <Button type="reset" variant="outlined" onClick={closeModal} disabled={isUpdateVacancyLoading}>
          {t('removeVacancyEndDateForm.buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default UpdateVacancyEndDateForm;
