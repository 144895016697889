import theme, { SxStyles } from 'theme';

const styles: SxStyles = {
  cell: {
    borderRight: `3px solid ${theme.palette.border.main}`,
    borderBottom: 'none',
    width: 'fit-content',
    bgcolor: theme.palette.common.white,
    color: 'text.gray',
    verticalAlign: 'middle',
    px: 0.5,
  },
  hiddenRightBorder: {
    borderRight: 'none',
  },
  title: {
    textAlign: 'center',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
  },
  verticalTitle: {
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    margin: '0 auto',
  },
};

export default styles;
