import { FC, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateUserExpectedBillableTimePercentMutation } from 'domain/user/expectedBillableTimePercent/apiSlice';
import {
  formatAttributesToSubmit,
  IUpdateUserExpectedBillableTimePercentFormData,
  initialValues,
  buildValidationSchema,
} from 'domain/user/expectedBillableTimePercent/schemas/update';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { IUpdateUserExpectedBillableTimePercentFormProps } from './types';

const UpdateUserExpectedBillableTimePercentForm: FC<IUpdateUserExpectedBillableTimePercentFormProps> = props => {
  const { user, expectedBillableTimePercent } = props;
  const { closeModal } = useContext(ModalContext);
  const { t } = useTranslation('userProfile');

  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const [updateExpectedBillableTimePercent, { isLoading: isUpdateExpectedBillableTimePercentLoading }] =
    useUpdateUserExpectedBillableTimePercentMutation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<IUpdateUserExpectedBillableTimePercentFormData>({
    defaultValues: initialValues(expectedBillableTimePercent),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: startDateValue, onChange: onStartDateChange },
  } = useController({ name: 'startDate', control });

  const {
    field: { value: endDateValue, onChange: onEndDateChange },
  } = useController({ name: 'endDate', control });

  const onSubmit = async (formData: IUpdateUserExpectedBillableTimePercentFormData) => {
    try {
      await updateExpectedBillableTimePercent(
        formatAttributesToSubmit(expectedBillableTimePercent.id, user.id, formData),
      ).unwrap();

      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.root}>
        <TextField
          sx={styles.input}
          {...register('timePercent')}
          label={t('schedule.timePercent')}
          InputLabelProps={{ sx: styles.placeholder }}
          error={Boolean(errors.timePercent)}
          helperText={buildErrorMessage(t, errors.timePercent)}
          disabled={isUpdateExpectedBillableTimePercentLoading}
          inputProps={{ maxLength: 100 }}
        />

        <Div>
          <DatePicker
            label={t('schedule.startDate')}
            value={startDateValue}
            onChange={onStartDateChange}
            renderInput={parameters => (
              <TextField
                required
                InputLabelProps={{ sx: styles.placeholder }}
                {...parameters}
                sx={styles.input}
                error={Boolean(errors.startDate)}
                FormHelperTextProps={{ sx: styles.helperText }}
                helperText={buildErrorMessage(t, errors.startDate)}
              />
            )}
          />
        </Div>

        <Div>
          <DatePicker
            label={t('schedule.endDate')}
            value={endDateValue}
            onChange={onEndDateChange}
            renderInput={parameters => (
              <TextField
                InputLabelProps={{ sx: styles.placeholder }}
                {...parameters}
                sx={styles.input}
                error={Boolean(errors.endDate)}
                FormHelperTextProps={{ sx: styles.helperText }}
                helperText={buildErrorMessage(t, errors.endDate)}
              />
            )}
          />
        </Div>

        <Div sx={styles.buttons}>
          <Button
            sx={styles.updateExpectedBillableTimePercentButton}
            type="submit"
            disabled={isUpdateExpectedBillableTimePercentLoading}
          >
            {t('schedule.updateExpectedBillableTimePercentForm.buttons.update')}
          </Button>
          <Button
            type="reset"
            variant="outlined"
            onClick={closeModal}
            disabled={isUpdateExpectedBillableTimePercentLoading}
          >
            {t('schedule.updateExpectedBillableTimePercentForm.buttons.cancel')}
          </Button>
        </Div>
      </Div>
    </form>
  );
};

export default UpdateUserExpectedBillableTimePercentForm;
