import { FC } from 'react';

import { Typography, Tooltip } from '@mui/material';

import Div from 'components/common/Div';

import styles from './styles';
import { ITrackedTimeEntryTagsChipProps } from './types';

const TrackedTimeEntryTagsChip: FC<ITrackedTimeEntryTagsChipProps> = props => {
  const { tagsNames } = props;

  return (
    <Div sx={styles.root}>
      <Tooltip arrow title={tagsNames} placement="top-end">
        <Typography sx={styles.tagsNames} variant="heading3">
          {tagsNames}
        </Typography>
      </Tooltip>
    </Div>
  );
};

export default TrackedTimeEntryTagsChip;
