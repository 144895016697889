import { FC } from 'react';

import { Button } from '@mui/material';

import styles from './styles';
import { ICreateNewLocationButtonProps } from './types';

const CreateNewLocationButton: FC<ICreateNewLocationButtonProps> = props => {
  const { buttonLabel, onAddNodeButtonClick } = props;

  return (
    <Button variant="contained" sx={styles.addButton} onClick={onAddNodeButtonClick}>
      {buttonLabel}
    </Button>
  );
};

export default CreateNewLocationButton;
