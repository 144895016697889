import { FC, SyntheticEvent } from 'react';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getInitialValue } from 'components/common/SmartFilter/components/Slot/service';
import { SmartFilterDescriptionEndDate } from 'components/common/SmartFilter/enums';
import { END_DATE_COMPARISONS } from 'components/common/SmartFilter/relations';

import { divideBySpace } from 'utils/string';

import styles from './styles';
import { IEndDateProps } from './types';

const END_DATE_DEFAULT = SmartFilterDescriptionEndDate.all;

const END_DATE_VALUES = {
  [SmartFilterDescriptionEndDate.all]: 'null',
  [SmartFilterDescriptionEndDate.withEndDate]: 'false',
  [SmartFilterDescriptionEndDate.withoutEndDate]: 'true',
};

const EndDate: FC<IEndDateProps> = props => {
  const { slot, onSlotPatch } = props;

  const { t } = useTranslation(['smartFilter']);

  const initialValue = getInitialValue(slot.value, END_DATE_DEFAULT, END_DATE_VALUES);

  const handleComparisonChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    const newValue = value === END_DATE_DEFAULT ? null : { id: null, name: END_DATE_VALUES[value] };

    onSlotPatch(slot.id, { value: newValue });
  };

  return (
    <FormControl>
      <FormLabel sx={styles.title} id="status-buttons-group-label">
        <Typography sx={styles.titleText} variant="heading4">
          {t('smartFilter:status.title')}
        </Typography>
      </FormLabel>
      <RadioGroup
        aria-labelledby="status-comparison-radio-buttons-group"
        name="status-comparison"
        value={initialValue}
        onChange={handleComparisonChange}
      >
        {END_DATE_COMPARISONS.map(comparison => {
          const label = (
            <Typography sx={styles.label} variant="subtitle1">
              {divideBySpace(comparison.description)}
            </Typography>
          );

          return (
            <FormControlLabel
              sx={styles.checkboxInput}
              key={comparison.description}
              value={comparison.description}
              control={<Radio size="small" />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default EndDate;
