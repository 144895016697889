import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: '24px',
    height: '24px',
    marginRight: 1,
  },
  fullName: {
    zIndex: 1,
    color: 'text.black',
  },
  background: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'border.lightGray',
  },
};

export default styles;
