import { FC } from 'react';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Button } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import {
  getReportTrackedTimeEntriesGrouping,
  getSelectionInfo,
} from 'components/pages/Report/TrackedTimeEntries/service';

import styles from './styles';
import { IEntriesSelectionProps } from './types';

const EntriesSelection: FC<IEntriesSelectionProps> = props => {
  const { group, selectedEntryIds, onSelectAll, onDeselectAll, onCopy, reportTrackedTimeEntries } = props;
  const { t } = useTranslation('reportTrackedTimeEntries');

  const { isGroupingByUser } = getReportTrackedTimeEntriesGrouping(group);
  if (!isGroupingByUser || _.isEmpty(reportTrackedTimeEntries)) {
    return null;
  }

  const { isAllEntriesSelected, selectedEntriesNotEmpty, allEntriesIds } = getSelectionInfo(
    reportTrackedTimeEntries,
    selectedEntryIds,
  );

  return (
    isGroupingByUser && (
      <Div sx={styles.selectionBlock}>
        {!isAllEntriesSelected && (
          <Button variant="text" onClick={() => onSelectAll(allEntriesIds)}>
            {t('reportTrackedTimeEntries:selectAll')}
          </Button>
        )}
        {isAllEntriesSelected && (
          <Button variant="text" onClick={onDeselectAll}>
            {t('reportTrackedTimeEntries:deselectAll')}
          </Button>
        )}
        <CheckCircleOutlineOutlinedIcon
          sx={MUISx(styles.icon, { sx: styles.iconActive, condition: selectedEntriesNotEmpty })}
        />
        {selectedEntryIds.length}
        <Div sx={styles.buttonGroup}>
          {selectedEntriesNotEmpty && <Button onClick={onCopy}>{t('reportTrackedTimeEntries:copyUser')}</Button>}
        </Div>
      </Div>
    )
  );
};

export default EntriesSelection;
