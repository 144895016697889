import { FC } from 'react';

import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { useGetUserQuery } from 'domain/user/apiSlice';

import ProfileDetailedInfo from './components/ProfileDeatailedInfo';
import ProfileHeader from './components/ProfileHeader';
import ProfileInfo from './components/ProfileInfo';
import styles from './styles';

const UserProfile: FC = () => {
  const { t } = useTranslation('userProfile');

  const {
    camelizedQuery: { userId },
  } = useRouter();

  const { data: userData, isLoading: isUserLoading, isError: isUserError } = useGetUserQuery(Number(userId));
  const {
    data: currentUserData,
    isLoading: isCurrentUserLoading,
    isError: isCurrentUserError,
  } = useGetCurrentUserQuery();

  const isLoading = isUserLoading || isCurrentUserLoading;
  const isError = isUserError || isCurrentUserError;

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  return (
    <>
      <ProfileHeader user={userData.user} />
      <Div sx={styles.container}>
        <ProfileInfo user={userData.user} />
        <ProfileDetailedInfo user={userData.user} currentUser={currentUserData.user} />
      </Div>
    </>
  );
};

export default UserProfile;
