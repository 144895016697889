import { provideTags, TagTypes } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { CreateAllocationReportExportState } from './enums';
import { IAllocationItem, IPermissions } from './types';

export interface IGetAllocationsResponse {
  meta: IMeta;
  permissions: IPermissions;
  usages: Array<IAllocationItem>;
}

export interface IReportAllocationsSmartFilters {
  resourceIdsIn?: Array<number>;
  projectIdsIn?: Array<number>;
  technologyIdsIn?: Array<number>;
  workloadEq?: number;
  workloadNoteq?: number;
  workloadLt?: number;
  workloadLteq?: number;
  workloadGt?: number;
  workloadGteq?: number;
}

interface IGetReportAllocationsParameters extends IReportAllocationsSmartFilters {
  page?: number;
  perPage?: number;
  sort?: string;
  startDate?: string;
  endDate?: string;
}

interface ICreateAllocationReportExport {
  id: number;
  state: CreateAllocationReportExportState;
}

interface ICreateExportReportTrackedTimeEntriesResponse {
  export: ICreateAllocationReportExport;
}

export const reportAllocationApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getAllocations: builder.query<IGetAllocationsResponse, IGetReportAllocationsParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1ReportsAllocationsPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ReportAllocation, response?.usages),
    }),
    createCsvAllocationReport: builder.mutation<
      ICreateExportReportTrackedTimeEntriesResponse,
      IGetReportAllocationsParameters
    >({
      query: formData => ({
        url: apiRoutes.createCsvApiV1ReportsAllocationsPath(),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
    }),
    createXlsxAllocationReport: builder.mutation<
      ICreateExportReportTrackedTimeEntriesResponse,
      IGetReportAllocationsParameters
    >({
      query: formData => ({
        url: apiRoutes.createXlsxApiV1ReportsAllocationsPath(),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllocationsQuery, useCreateCsvAllocationReportMutation, useCreateXlsxAllocationReportMutation } =
  reportAllocationApi;
