import { FC, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl } from '@mui/material';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import SkillLevelSelect from 'components/common/SkillLevelSelect';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateResourceAbilityMutation } from 'domain/resource/ability/apiSlice';
import {
  IUpdateResourceAbilityFormData,
  initialValues,
  buildValidationSchema,
  formatAttributesToSubmit,
} from 'domain/resource/ability/schemas/update';

import { SkillLevel } from 'enums/SkillLevel';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';

import styles from './styles';
import { IUpdateResourceAbilityFormProps } from './types';

const UpdateResourceAbilityForm: FC<IUpdateResourceAbilityFormProps> = props => {
  const { resourceId, userProfileStackAbility } = props;

  const { t } = useTranslation('userProfile');

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IUpdateResourceAbilityFormData>({
    defaultValues: initialValues(userProfileStackAbility),
    resolver: yupResolver(buildValidationSchema()),
  });

  const [updateResourceAbility, { isLoading: isUpdateResourceAbilityLoading }] = useUpdateResourceAbilityMutation();

  const {
    field: { value: skillLevel, onChange: onSkillLevelChange },
  } = useController({ name: 'skillLevel', control });

  const { closeModal } = useContext(ModalContext);

  const onSubmit = async (formData: IUpdateResourceAbilityFormData) => {
    try {
      await updateResourceAbility(formatAttributesToSubmit(userProfileStackAbility.id, resourceId, formData)).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const handleSkillLevelChange = (skillLevel: SkillLevel) => {
    onSkillLevelChange(skillLevel);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth sx={styles.select}>
        <SkillLevelSelect
          value={skillLevel}
          onChange={handleSkillLevelChange}
          error={errors.skillLevel}
          label={t('specializationAndStack.skillInputLabel')}
          isRequired
        />
      </FormControl>

      <Div sx={styles.buttonsContainer}>
        <Button type="submit" variant="contained" disabled={isUpdateResourceAbilityLoading}>
          {t('specializationAndStack.saveButton')}
        </Button>
        <Button variant="outlined" onClick={closeModal} disabled={isUpdateResourceAbilityLoading}>
          {t('specializationAndStack.cancelButton')}
        </Button>
      </Div>
    </form>
  );
};

export default UpdateResourceAbilityForm;
