import i18n from 'locales/i18n';

import { AccessibleType, Permission } from './enums';

const DEVELOPER = i18n.t('common:developer');
const MANAGER = i18n.t('common:manager');
const HR = i18n.t('common:hr');
const PRACTICE_LEAD = i18n.t('common:practiceLead');
const TEAM_LEAD = i18n.t('common:teamLead');
const PROJECT_MANAGER = i18n.t('common:projectManager');
const PRODUCT_DIRECTOR = i18n.t('common:productDirector');

const GROUP = i18n.t('common:group');
const PROJECT = i18n.t('common:project');

export const PERMISSION_OPTIONS = {
  [Permission.developer]: DEVELOPER,
  [Permission.manager]: MANAGER,
  [Permission.hr]: HR,
  [Permission.practiceLead]: PRACTICE_LEAD,
  [Permission.teamLead]: TEAM_LEAD,
  [Permission.projectManager]: PROJECT_MANAGER,
  [Permission.productDirector]: PRODUCT_DIRECTOR,
};

export const GROUP_PERMISSION_OPTIONS = [
  { id: Permission.developer, name: DEVELOPER },
  { id: Permission.manager, name: MANAGER },
  { id: Permission.hr, name: HR },
  { id: Permission.practiceLead, name: PRACTICE_LEAD },
];

export const PROJECT_PERMISSION_OPTIONS = [
  { id: Permission.developer, name: DEVELOPER },
  { id: Permission.teamLead, name: TEAM_LEAD },
  { id: Permission.projectManager, name: PROJECT_MANAGER },
  { id: Permission.productDirector, name: PRODUCT_DIRECTOR },
];

export const permissionOptions = {
  [AccessibleType.group]: GROUP_PERMISSION_OPTIONS,
  [AccessibleType.project]: PROJECT_PERMISSION_OPTIONS,
};

export const ACCESSIBLE_TYPE_OPTIONS = [
  { id: AccessibleType.group, name: GROUP },
  { id: AccessibleType.project, name: PROJECT },
];
