import { FC } from 'react';

import _ from 'lodash';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import appRoutes from 'routes/appRoutes';

import Loader from 'components/common/Loader';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';

import { isRouteAllowed } from 'utils/routes';

const Router: FC = () => {
  const { data: currentUser, isLoading: isCurrentUserLoading } = useGetCurrentUserQuery();

  if (isCurrentUserLoading) {
    return <Loader />;
  }

  return (
    <BrowserRouter>
      <Routes>
        {Object.keys(appRoutes)
          .filter(keyRoute => !appRoutes[keyRoute].onlyPath)
          .map(keyRoute => {
            const route = appRoutes[keyRoute];

            if (_.isNil(currentUser)) {
              return route.onlyUnauthorized ? (
                <Route key={route.id} path={route.path()} element={route.element} />
              ) : null;
            }

            const hasAccess = isRouteAllowed(currentUser?.user, route.protected ?? []);

            return hasAccess ? (
              <Route
                key={route.id}
                path={_.isNil(route.pathStringIds) ? route.path() : route.path(route.pathStringIds.join('/'))}
                element={route.element}
              />
            ) : null;
          })}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
