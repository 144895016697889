import { SxStyles } from 'theme';

const styles: SxStyles = {
  cell: {
    borderBottom: '1px solid',
    borderColor: 'border.main',
  },
};

export default styles;
