import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  billableName: {
    color: 'text.orange',
  },
  nonBillableName: {
    color: 'text.blue',
  },
  content: {
    maxWidth: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dot: {
    flexShrink: 0,
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    marginRight: 0.625,
  },
  billableDot: {
    backgroundColor: 'text.orange',
  },
  nonBillableDot: {
    backgroundColor: 'text.blue',
  },
};

export default styles;
