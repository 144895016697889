import { TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { ICreateUserExpectedBillableTimePercentFormDataToSubmit } from './schemas/create';
import { IUpdateUserExpectedBillableTimePercentFormDataToSubmit } from './schemas/update';
import { IUserExpectedBillableTimePercent } from './types';

interface IUserExpectedBillableTimePercentParameters {
  id: number;
  userId: number;
}

interface IGetUserExpectedBillableTimePercentResponse {
  userExpectedBillableTimePercent: IUserExpectedBillableTimePercent;
}

interface IGetUserExpectedBillableTimePercentsResponse {
  userExpectedBillableTimePercents: Array<IUserExpectedBillableTimePercent>;
  meta: IMeta;
}

interface IGetUserExpectedBillableTimePercentQueryParameters {
  sort?: string;
}

interface IGetUserExpectedBillableTimePercentsPlainParameters
  extends IGetUserExpectedBillableTimePercentQueryParameters {
  page?: number;
  perPage?: number;
  userId: number;
}

interface IGetUserExpectedBillableTimePercentsParameters {
  q?: IGetUserExpectedBillableTimePercentQueryParameters;
  perPage?: number;
  page?: number;
  sort?: string;
  userId: number;
}

export const loadingGetUserExpectedBillableTimePercentsParameters = (
  parameters: IGetUserExpectedBillableTimePercentsPlainParameters,
): IGetUserExpectedBillableTimePercentsParameters => {
  const { userId, ...queryParameters } = parameters;
  return {
    userId,
    q: { ...queryParameters },
  };
};

export const userExpectedBillableTimePercentsApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getUserExpectedBillableTimePercents: builder.query<
      IGetUserExpectedBillableTimePercentsResponse,
      IGetUserExpectedBillableTimePercentsParameters
    >({
      query: ({ userId, ...parameters }) => ({
        url: apiRoutes.apiV1UserExpectedBillableTimePercentsPath(userId),
        method: 'GET',
        params: {
          ...parameters,
        },
      }),
      providesTags: response =>
        provideTags(TagTypes.UserExpectedBillableTimePercent, response?.userExpectedBillableTimePercents),
    }),
    createUserExpectedBillableTimePercent: builder.mutation<
      IGetUserExpectedBillableTimePercentResponse,
      ICreateUserExpectedBillableTimePercentFormDataToSubmit
    >({
      query: ({ userId, formData }) => ({
        url: apiRoutes.apiV1UserExpectedBillableTimePercentsPath(userId),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: [TagTypes.UserExpectedBillableTimePercent],
    }),
    updateUserExpectedBillableTimePercent: builder.mutation<
      IGetUserExpectedBillableTimePercentResponse,
      IUpdateUserExpectedBillableTimePercentFormDataToSubmit
    >({
      query: ({ expectedBillableTimePercentId, userId, formData }) => {
        return {
          url: apiRoutes.apiV1UserExpectedBillableTimePercentPath(userId, expectedBillableTimePercentId),
          method: 'PUT',
          data: {
            ...formData,
          },
        };
      },
      invalidatesTags: [TagTypes.UserExpectedBillableTimePercent],
    }),
    deleteUserExpectedBillableTimePercent: builder.mutation<void, IUserExpectedBillableTimePercentParameters>({
      query: ({ id, userId }) => ({
        url: apiRoutes.apiV1UserExpectedBillableTimePercentPath(userId, id),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.UserExpectedBillableTimePercent],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserExpectedBillableTimePercentsQuery,
  useCreateUserExpectedBillableTimePercentMutation,
  useUpdateUserExpectedBillableTimePercentMutation,
  useDeleteUserExpectedBillableTimePercentMutation,
} = userExpectedBillableTimePercentsApi;
