import { FC, useState } from 'react';

import { ExpandMoreOutlined } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import { ISelectBudgetTypeMenuProps } from './types';

const SelectBudgetTypeMenu: FC<ISelectBudgetTypeMenuProps> = props => {
  const { setBudgetType } = props;

  const [budgetTypeMenu, setBudgetTypeMenu] = useState<null | HTMLElement>(null);

  const { t } = useTranslation('reportHoursByProject');

  const isBudgetTypeMenuOpen = Boolean(budgetTypeMenu);

  const handleExpandedBudgetClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setBudgetTypeMenu(event.currentTarget);
  };

  const handleExpandedBudgetClose = () => {
    setBudgetTypeMenu(null);
  };

  const handleMonthlyBudgetExpandedItemClick = () => {
    setBudgetType('monthly');
    handleExpandedBudgetClose();
  };

  const handleTotalProjectBudgetExpandedItemClick = () => {
    setBudgetType('total');
    handleExpandedBudgetClose();
  };

  return (
    <>
      <IconButton
        id={'budget-type-button'}
        aria-controls={isBudgetTypeMenuOpen ? 'budget-type-menu' : undefined}
        aria-expanded={isBudgetTypeMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleExpandedBudgetClick}
        sx={styles.arrowIconBlock}
      >
        <ExpandMoreOutlined sx={styles.arrowIcon} />
      </IconButton>

      <Menu
        id={'budget-type-menu'}
        anchorEl={budgetTypeMenu}
        open={isBudgetTypeMenuOpen}
        onClose={handleExpandedBudgetClose}
        MenuListProps={{
          'aria-labelledby': 'budget-type-button',
        }}
      >
        <MenuItem sx={styles.budgetTypeItem} onClick={handleMonthlyBudgetExpandedItemClick}>
          <Typography sx={styles.expandedItemText} variant="subtitle2">
            {t('info.titles.hoursBlocks.monthlyBudget')}
          </Typography>
        </MenuItem>
        <MenuItem sx={styles.budgetTypeItem} onClick={handleTotalProjectBudgetExpandedItemClick}>
          <Typography sx={styles.budgetTypeItemText} variant="subtitle2">
            {t('info.titles.hoursBlocks.totalProjectBudget')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SelectBudgetTypeMenu;
