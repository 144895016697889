import { FC } from 'react';

import { Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { formatToShortString, parseDate } from 'utils/dateTime';
import { getGroupByPeriodFilter } from 'utils/filters';

import styles from './styles';
import { ITableHeaderProps } from './types';

const TableHeader: FC<ITableHeaderProps> = props => {
  const { column, groupByPeriod } = props;

  const { t } = useTranslation('tableHeader');

  const { weekNumber, weekLength, dateRange, fullName } = column;
  const dateStart = formatToShortString(parseDate(dateRange.dateStart));
  const dateEnd = formatToShortString(parseDate(dateRange.dateEnd));

  const { isDayPeriod, isWeekPeriod, isMonthPeriod } = getGroupByPeriodFilter(groupByPeriod);

  if (_.isNil(fullName)) {
    return (
      <Div>
        {isDayPeriod && (
          <Typography component="p" sx={styles.columnHeader} variant="subtitle4">
            {dateStart}
          </Typography>
        )}
        {isWeekPeriod && (
          <>
            <Typography component="p" sx={styles.columnHeader} variant="subtitle4">
              {dateStart} - {dateEnd}
            </Typography>
            <Typography component="p" sx={styles.columnHeader} variant="subtitle4">
              {weekNumber} ({t('days', { count: weekLength })})
            </Typography>
          </>
        )}
        {isMonthPeriod && (
          <Typography component="p" sx={styles.columnHeader} variant="subtitle4">
            {dateStart} - {dateEnd}
          </Typography>
        )}
      </Div>
    );
  }

  return (
    <Div>
      <Typography sx={styles.columnHeader} variant="mainTableHeading">
        {fullName.toUpperCase()}
      </Typography>
      <Typography component="p" sx={styles.columnHeader} variant="mainTableHeading">
        {dateStart} - {dateEnd}
      </Typography>
    </Div>
  );
};

export default TableHeader;
