import { SxStyles } from 'theme';

const styles: SxStyles = {
  transparentButton: {
    background: 'transparent',
    border: 'none',
    '&:hover': {
      border: 'none',
      background: 'transparent',
    },
  },
  workloadBlock: {
    minWidth: '30px',
    borderBottomWidth: '1.5px',
    borderBottomStyle: 'dashed',
    borderBottomColor: 'border.blue',
  },
  transparentButtonNonClickable: {
    cursor: 'default',
    background: 'transparent',
    border: 'none',
    '&:hover': {
      border: 'none',
      background: 'transparent',
    },
  },
  emptyWorkloadBlock: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default styles;
