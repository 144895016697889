import { SxStyles } from 'theme';

const styles: SxStyles = {
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  search: {
    width: '500px',
    marginRight: 3,
    backgroundColor: 'background.white',
    borderRadius: '4px',
  },
  addTasksButton: {
    height: '56px',
  },
};

export default styles;
