import { SxStyles } from 'theme';

const styles: SxStyles = {
  timeTrackerWeekAndDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 4,
  },
  weekTimeTrackerCount: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingRight: '16px',
  },
  weekContainer: {
    display: 'flex',
    gap: '2px',
  },
  weekLabel: {
    color: '#7C8993',
    fontSize: 16,
    fontWeight: 600,
    marginRight: '10px',
    marginBottom: '32px',
  },
  weekDateRange: {
    fontWeight: 700,
    fontSize: 16,
    color: '#55626B',
  },
  weekTotal: {
    fontWeight: 700,
    fontSize: 16,
    width: '50px',
    marginRight: '89px',
    textAlign: 'center',
    color: '#55626B',
  },
};

export default styles;
