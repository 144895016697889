import { FC, useContext, useState } from 'react';

import { Alert, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import Tree from 'components/common/Tree';
import { TreeType } from 'components/common/Tree/enums';
import SearchBar from 'components/pages/AdminPanel/components/SearchBar';

import { ModalContext } from 'contexts/ModalContext';

import { useActivateGroupMutation, useDeleteGroupMutation, useGetGroupsQuery } from 'domain/group/apiSlice';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';

import CreateHierarchyGroupForm from './components/CreateHierarchyGroupForm';
import UpdateHierarchyGroupForm from './components/UpdateHierarchyGroupForm';
import styles from './styles';

const Hierarchy: FC = () => {
  const [searchString, setSearchString] = useState<string>('');

  const [debouncedSearchString] = useDebounce(searchString, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: groupsData,
    isLoading: isGetGroupsLoading,
    isError,
  } = useGetGroupsQuery({ nameCont: debouncedSearchString });

  const { t } = useTranslation('adminPanel');

  const { openModal } = useContext(ModalContext);

  const [activateGroup, { isLoading: isActivateGroupLoading }] = useActivateGroupMutation();
  const [deleteGroup, { isLoading: isDeleteGroupLoading }] = useDeleteGroupMutation();

  const isLoading = isActivateGroupLoading || isDeleteGroupLoading;

  const handleEditGroupModalOpen = (groupId: number, parentId: number) => {
    const currentGroup = groupsData.groups.find(group => group.id === groupId);
    const currentSuperGroup = groupsData.groups.find(group => group.id === parentId);

    openModal({
      title: t('hierarchy.edit'),
      content: <UpdateHierarchyGroupForm currentGroup={currentGroup} currentSuperGroup={currentSuperGroup} />,
    });
  };

  const handleAddGroupModalOpen = (parentGroupId: number = null) => {
    const currentGroup = groupsData.groups.find(group => group.id === parentGroupId) ?? null;

    openModal({
      title: t('hierarchy.add'),
      content: <CreateHierarchyGroupForm currentGroup={currentGroup} />,
    });
  };

  if (isGetGroupsLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  return (
    <Div sx={styles.container}>
      <Div>
        <SearchBar
          placeholder={t('hierarchy.searchBar')}
          buttonLabel={t('hierarchy.searchBarButton')}
          onAddNodeButtonClick={handleAddGroupModalOpen}
          searchString={searchString}
          setSearchString={setSearchString}
          buttonWidth={125}
        />

        <Div>
          <Typography variant="body1" sx={styles.treeTitle}>
            {t('hierarchy.title')}
          </Typography>
          <Tree
            data={groupsData.groups}
            onAddModalOpen={handleAddGroupModalOpen}
            onEditModalOpen={handleEditGroupModalOpen}
            onNodeActivate={activateGroup}
            onNodeDeactivate={deleteGroup}
            isLoading={isLoading}
            type={TreeType.groups}
          />
        </Div>
      </Div>
    </Div>
  );
};

export default Hierarchy;
