import { Dispatch, SetStateAction, useEffect } from 'react';

import { startOfDay, addDays, differenceInMilliseconds, isAfter } from 'date-fns';

import { ITag } from 'domain/tag/types';

import { ITimeTrackerTrackableState } from 'types/timeTrackerTrackable';

import { MILLISECONDS_IN_DAY } from 'utils/constants';

import { ICopiedTimeTrackerSettings } from '../../types';

export function useSetRightCurrentDateEffect(setDate: Dispatch<SetStateAction<Date>>) {
  useEffect(() => {
    const refreshDate = () => {
      setDate(new Date());
    };

    let timeTabWasHidden = startOfDay(new Date());
    let timeTabWasVisible = startOfDay(new Date());

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        timeTabWasVisible = startOfDay(new Date());
        if (isAfter(timeTabWasVisible, timeTabWasHidden)) {
          refreshDate();
        }
      } else {
        timeTabWasHidden = startOfDay(new Date());
      }
    };

    const setupVisibilityListener = () => {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    };

    const setupRefreshTimer = () => {
      const now = new Date();
      const midnight = startOfDay(addDays(now, 1));
      const timeUntilMidnight = differenceInMilliseconds(midnight, now);
      const timerId = setTimeout(refreshDate, timeUntilMidnight);
      return () => {
        clearTimeout(timerId);
      };
    };

    const setupRefreshInterval = () => {
      const intervalId = setInterval(refreshDate, MILLISECONDS_IN_DAY);
      return () => {
        clearInterval(intervalId);
      };
    };

    const cleanupFunctions = [setupVisibilityListener(), setupRefreshTimer(), setupRefreshInterval()];

    return () => {
      for (const cleanup of cleanupFunctions) cleanup();
    };
  }, [setDate]);
}

export function useSetCurrentTimeTrackerTrackableState(
  copiedTimeTrackerSettings: ICopiedTimeTrackerSettings | null,
  setDate: Dispatch<SetStateAction<Date>>,
  setDuration: Dispatch<SetStateAction<{ hours: string; minutes: string }>>,
  setCurrentTimeTrackerTrackable: Dispatch<SetStateAction<ITimeTrackerTrackableState>>,
  setCurrentTimeTrackerTags: Dispatch<SetStateAction<ITag[]>>,
  onDescriptionChange: (description: string) => void,
  onTrackableChange: (event: unknown) => void,
) {
  useEffect(() => {
    if (!copiedTimeTrackerSettings) return;
    onDescriptionChange(copiedTimeTrackerSettings.description);
    const hours = Math.floor(copiedTimeTrackerSettings.duration / 60)
      .toString()
      .padStart(2, '0');
    const minutes = Math.floor(copiedTimeTrackerSettings.duration % 60)
      .toString()
      .padStart(2, '0');
    setDuration({
      hours,
      minutes,
    });
    const date = new Date(copiedTimeTrackerSettings.date);
    setDate(new Date(date.getTime() + date.getTimezoneOffset() * 60_000));
    setCurrentTimeTrackerTrackable(copiedTimeTrackerSettings.trackable);
    onTrackableChange({
      trackable: copiedTimeTrackerSettings.trackable,
      task: copiedTimeTrackerSettings.trackable?.task,
    });
    setCurrentTimeTrackerTags(copiedTimeTrackerSettings.tags);
  }, [copiedTimeTrackerSettings]);
}
