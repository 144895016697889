import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { convertDateToUTCString } from 'utils/dateTime';

import { IUserExpectedBillableTimePercent } from '../types';

export interface IUpdateUserExpectedBillableTimePercentFormData {
  timePercent: number;
  startDate: Date;
  endDate: Date;
}

export interface IUpdateUserExpectedBillableTimePercentPreparedFormData
  extends Omit<IUpdateUserExpectedBillableTimePercentFormData, 'timePercent' | 'startDate' | 'endDate'> {
  startDate: string;
  endDate: string | null;
}

export interface IUpdateUserExpectedBillableTimePercentFormDataToSubmit {
  expectedBillableTimePercentId: number;
  userId: number;
  formData: {
    expectedBillableTimePercent: IUpdateUserExpectedBillableTimePercentPreparedFormData;
  };
}

const requiredField = 'forms:validation:required';

const buildValidationFields = t => ({
  startDate: yup
    .date()
    .typeError(t('forms:validation:dateFormat'))
    .required(t(requiredField))
    .nullable()
    .default(new Date()),
  endDate: yup
    .date()
    .typeError(t('forms:validation:dateFormat'))
    .nullable()
    .min(yup.ref('startDate'), t('forms:validation:endDateBeforeStartBillableDate'))
    .default(null),
  timePercent: yup.number().min(0).max(100).required(t(requiredField)).default(100),
});

export const buildValidationSchema = () => {
  return yup.object(buildValidationFields(i18n.t));
};

export const initialValues = (expectedBillableTimePercent: IUserExpectedBillableTimePercent) => {
  const { timePercent, startDate, endDate } = expectedBillableTimePercent;
  return {
    timePercent,
    startDate: new Date(startDate),
    endDate: endDate ? new Date(endDate) : null,
  };
};

export const formatAttributesToSubmit = (
  expectedBillableTimePercentId: number,
  userId: number,
  formData: IUpdateUserExpectedBillableTimePercentFormData,
): IUpdateUserExpectedBillableTimePercentFormDataToSubmit => {
  const { startDate, endDate, ...values } = formData;
  const normalizeStartDate = convertDateToUTCString(startDate);
  const normalizeEndDate = _.isNil(endDate) ? null : convertDateToUTCString(endDate);

  return {
    expectedBillableTimePercentId,
    userId,
    formData: {
      expectedBillableTimePercent: {
        startDate: normalizeStartDate,
        endDate: normalizeEndDate,
        ...values,
      },
    },
  };
};
