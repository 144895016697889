import { FC } from 'react';

import { Button, List, ListItem, Typography } from '@mui/material';
import MUISx from 'mui-sx';

import { MeasurementUnitFilter } from 'enums/MeasurementUnitFilter';

import styles from './styles';
import { IMeasurementUnitFilterProps } from './types';

const measurementUnits = [
  {
    value: MeasurementUnitFilter.cost,
  },
  {
    value: MeasurementUnitFilter.FTE,
  },
  {
    value: MeasurementUnitFilter.hours,
  },
];

const MeasurementUnitFilters: FC<IMeasurementUnitFilterProps> = props => {
  const { selectedMeasurementUnit, onSelectedMeasurementUnitChange } = props;

  return (
    <List sx={styles.measurementUnits}>
      {measurementUnits.map(measurementUnit => {
        const isActive = selectedMeasurementUnit === measurementUnit.value;

        return (
          <ListItem sx={styles.measurementUnit} key={measurementUnit.value}>
            <Button
              onClick={() => onSelectedMeasurementUnitChange(measurementUnit.value)}
              sx={MUISx(styles.measurementUnitButton, { condition: isActive, sx: styles.measurementUnitButtonActive })}
              variant="text"
            >
              <Typography variant="subtitle3">{measurementUnit.value}</Typography>
            </Button>
          </ListItem>
        );
      })}
    </List>
  );
};

export default MeasurementUnitFilters;
