import { FC, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateResourceUsageMutation } from 'domain/resource/usage/apiSlice';
import {
  formatAttributesToSubmit,
  initialValues,
  IUpdateResourceUsageFormData,
  buildValidationSchema,
} from 'domain/resource/usage/schemas/update';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { IUpdateResourceUsageEndDateFormProps } from './types';

const UpdateResourceUsageEndDateForm: FC<IUpdateResourceUsageEndDateFormProps> = props => {
  const { projectId, resourceUsage } = props;

  const { t } = useTranslation('resourceUsageActions');

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const { closeModal } = useContext(ModalContext);

  const [updateResourceUsage, { isLoading: isUpdateResourceUsageLoading }] = useUpdateResourceUsageMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IUpdateResourceUsageFormData>({
    defaultValues: initialValues(resourceUsage),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: endDate, onChange: onEndDateChange },
  } = useController({ name: 'endDate', control });

  const onSubmit = async (formData: IUpdateResourceUsageFormData) => {
    try {
      await updateResourceUsage(
        formatAttributesToSubmit(resourceUsage.resourceId, resourceUsage.id, projectId, formData),
      ).unwrap();

      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.endDatePicker}>
        <DatePicker
          label={t('removeResourceUsageEndDateForm.fields.endDate.label')}
          value={endDate}
          onChange={onEndDateChange}
          disabled={isUpdateResourceUsageLoading}
          renderInput={parameters => (
            <TextField
              {...parameters}
              error={Boolean(errors.endDate)}
              helperText={buildErrorMessage(t, errors.endDate)}
            />
          )}
        />
      </Div>

      <Div sx={styles.buttons}>
        <Button sx={styles.removeButton} type="submit" disabled={isUpdateResourceUsageLoading}>
          {t('removeResourceUsageEndDateForm.buttons.remove')}
        </Button>
        <Button type="reset" variant="outlined" onClick={closeModal} disabled={isUpdateResourceUsageLoading}>
          {t('removeResourceUsageEndDateForm.buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default UpdateResourceUsageEndDateForm;
