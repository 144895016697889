import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  rootCreate: {
    height: '36px',
    borderRadius: '6px',
  },
  rootUpdate: {
    paddingY: 1,
    paddingX: 3,
  },
  inputUpdate: {
    width: '100%',
  },
  inputHoursUpdate: {
    marginRight: 1,
  },
  inputHoursCreate: {
    padding: 0,
    width: '70px',
    '& fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      border: 'none',
    },
    '& input': {
      paddingY: 0.75,
      paddingX: 0.375,
      textAlign: 'center',
      color: 'text.blackLight',
    },
  },
  separator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10px',
    color: 'text.blackLight',
  },
};

export default styles;
