import { FC, useState } from 'react';

import { ExpandMoreOutlined } from '@mui/icons-material';
import { Autocomplete as MUIAutocomplete, CircularProgress, TextField, Typography } from '@mui/material';

import { getFullName } from 'utils/person';

import styles from './styles';
import { IUsersAutocompleteProps, IUsersAutocompleteValue } from './types';

const Autocomplete: FC<IUsersAutocompleteProps> = props => {
  const { currentValue, onCurrentValueChange, options, isError, setName, isFetching, label } = props;

  const [isAutoCompleteOpen, setIsAutocompleteOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const handleUsersAutocompleteChange = (value: string) => {
    const name = getFullName(currentValue);
    if (currentValue && value === name) {
      return;
    }
    if (value !== inputValue) {
      setInputValue(value);
      setName(value);
    }
  };

  const handleAutocompleteOpen = () => {
    setIsAutocompleteOpen(true);
  };

  const handleAutocompleteClose = () => {
    setIsAutocompleteOpen(false);
  };

  return (
    <MUIAutocomplete
      open={isAutoCompleteOpen}
      onOpen={handleAutocompleteOpen}
      onClose={handleAutocompleteClose}
      autoComplete
      popupIcon={<ExpandMoreOutlined />}
      getOptionLabel={(value: IUsersAutocompleteValue) => getFullName(value) ?? ''}
      isOptionEqualToValue={(option: IUsersAutocompleteValue, value: IUsersAutocompleteValue) => option.id === value.id}
      options={options}
      filterOptions={x => x}
      sx={styles.select}
      onChange={(_, value: IUsersAutocompleteValue) => onCurrentValueChange(value)}
      value={currentValue}
      onInputChange={(_, value) => handleUsersAutocompleteChange(value)}
      renderInput={parameters => (
        <TextField
          error={isError}
          {...parameters}
          label={label}
          InputLabelProps={{ sx: styles.selectPlaceholder }}
          InputProps={{
            ...parameters.InputProps,
            endAdornment: (
              <>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {parameters.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, value: IUsersAutocompleteValue) => {
        return (
          <Typography {...props} key={value.id}>
            {getFullName(value)}
          </Typography>
        );
      }}
    />
  );
};
export default Autocomplete;
