import { SxStyles } from 'theme';

const styles: SxStyles = {
  groupItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'text.gray',
  },
  groupList: {
    width: '100%',
    padding: 0,
  },
};

export default styles;
