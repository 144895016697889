import theme, { SxStyles } from 'theme';

const styles: SxStyles = {
  day: {
    width: 35,
    bgcolor: theme.palette.common.white,
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  weekendDay: {
    bgcolor: 'border.lightGray',
  },
  monthEndDate: {
    borderRightWidth: 3,
  },
  hiddenRightBorder: {
    borderRight: 'none',
  },
  completedDay: {
    bgcolor: 'background.lightDarkGreen',
  },
  approvedDay: {
    bgcolor: 'background.mint',
  },
  requestedDay: {
    bgcolor: 'background.orange',
  },
};

export default styles;
