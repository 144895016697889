import { FC, useContext } from 'react';

import { Button, Typography } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';

import EmptyValue from 'components/common/EmptyValue';
import UpdateResourceUsageWorkloadWithDatesForm from 'components/common/UpdateResourceUsageWorkloadWithDatesForm';

import { ModalContext } from 'contexts/ModalContext';

import { getFullName } from 'utils/person';

import styles from './styles';
import { IResourceUsageWorkloadWithDatesButtonProps } from './types';

const ResourceUsageWorkloadWithDatesButton: FC<IResourceUsageWorkloadWithDatesButtonProps> = props => {
  const { resourceUsage, project } = props;

  const { openModal } = useContext(ModalContext);

  const { resourceId, workload, permissions } = resourceUsage;
  const { id: projectId, name: projectName } = project;
  const canEditAllocation = permissions.canEditAllocation;

  const handleWorkloadChangeClick = () => {
    const title = `${getFullName(resourceUsage)} - ${projectName}`;

    openModal({
      title,
      content: (
        <UpdateResourceUsageWorkloadWithDatesForm
          resourceUsage={resourceUsage}
          resourceId={resourceId}
          projectId={projectId}
        />
      ),
    });
  };

  const isEmptyWorkload = _.isNil(workload);

  return (
    <Button
      variant="outlined"
      sx={canEditAllocation ? styles.transparentButton : styles.transparentButtonNonClickable}
      onClick={canEditAllocation ? handleWorkloadChangeClick : () => {}}
    >
      <Typography
        sx={MUISx(
          {
            condition: canEditAllocation,
            sx: styles.workloadBlock,
          },
          {
            condition: isEmptyWorkload,
            sx: styles.emptyWorkloadBlock,
          },
        )}
        component="p"
        variant="heading3"
      >
        {_.isNil(workload) ? <EmptyValue /> : `${workload}%`}
      </Typography>
    </Button>
  );
};

export default ResourceUsageWorkloadWithDatesButton;
