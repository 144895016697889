import { FC } from 'react';

import { SelectChangeEvent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DateRangePicker from 'components/common/DateRangePicker';
import { DateRange } from 'components/common/DateRangePicker/types';
import Div from 'components/common/Div';
import GroupedByPeriodFilter from 'components/common/GroupedByPeriodFilter';
import MeasurementUnitFilters from 'components/common/MeasurementUnitFilters';

import { GroupByPeriodFilter } from 'enums/GroupByPeriodFilter';
import { MeasurementUnitFilter } from 'enums/MeasurementUnitFilter';

import { getDateRange, RangeType } from 'utils/dateRangePicker';

import styles from './styles';
import { IHoursByProjectFiltersProps } from './types';

const Filters: FC<IHoursByProjectFiltersProps> = props => {
  const { filters, setFilters, project } = props;

  const { t } = useTranslation('reportHoursByProject');

  const handleMeasurementUnitFilterChange = (measurementUnit: MeasurementUnitFilter) => {
    setFilters({ ...filters, measurementUnit });
  };

  const onDateRangeChange = (dateRange: DateRange | null) => {
    setFilters({
      ...filters,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    });
  };

  const handleGroupedByPeriodFilterChange = (event: SelectChangeEvent) => {
    setFilters({ ...filters, groupByPeriod: event.target.value as GroupByPeriodFilter });
  };

  return (
    <Div sx={styles.root}>
      <Typography variant="h2">
        {t('filters.plannedResources', { count: project.planedResourcesNumber })} (
        {t('filters.vacancies', { count: project.vacanciesNumber })})
      </Typography>
      <Div sx={styles.rightFilters}>
        <Div sx={styles.measurementUnitFilterBlock}>
          <MeasurementUnitFilters
            selectedMeasurementUnit={filters.measurementUnit}
            onSelectedMeasurementUnitChange={handleMeasurementUnitFilterChange}
          />
        </Div>

        <Div sx={styles.dateRangePickerBlock}>
          <DateRangePicker
            currentDateRange={getDateRange(filters.startDate, filters.endDate, RangeType.month)}
            onDateRangeChange={onDateRangeChange}
          />
        </Div>

        <GroupedByPeriodFilter value={filters.groupByPeriod} onChange={handleGroupedByPeriodFilterChange} />
      </Div>
    </Div>
  );
};

export default Filters;
