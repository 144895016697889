import { FC } from 'react';

import CreateProjectAndTaskButton from 'components/common/CreateProjectAndTaskButton';
import Div from 'components/common/Div';

import styles from './styles';
import { IProjectChipProps } from './types';

const ProjectChip: FC<IProjectChipProps> = props => {
  const {
    currentTimeTrackerTrackable,
    setCurrentTimeTrackerTrackable,
    isDisabled,
    onCurrentTimeTrackerTrackableChange,
  } = props;

  return (
    <Div sx={styles.root}>
      <CreateProjectAndTaskButton
        currentTimeTrackerTrackable={currentTimeTrackerTrackable}
        setCurrentTimeTrackerTrackable={setCurrentTimeTrackerTrackable}
        isDisabled={isDisabled}
        onCurrentTimeTrackerTrackableChange={onCurrentTimeTrackerTrackableChange}
      />
    </Div>
  );
};

export default ProjectChip;
