import { FC } from 'react';

import Div from 'components/common/Div';

import SideBar from 'layouts/public/BaseLayout/components/SideBar';

import styles from './styles';
import { IBaseLayoutProps } from './types';

const BaseLayout: FC<IBaseLayoutProps> = props => {
  const { children } = props;

  return (
    <Div sx={styles.root}>
      <SideBar />
      <Div sx={styles.content}>{children}</Div>
    </Div>
  );
};

export default BaseLayout;
