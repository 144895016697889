import { FC } from 'react';

import { Input } from '@mui/material';

import styles from './styles';
import { IInputFieldProps } from './types';

const InputField: FC<IInputFieldProps> = props => {
  const {
    value,
    onChange,
    placeholder = '',
    isDisabled = false,
    inputProps = {},
    onBlur = _ => {},
    onFocus = _ => {},
    disableUnderline = true,
  } = props;

  return (
    <Input
      sx={styles.input}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={isDisabled}
      onBlur={onBlur}
      onFocus={onFocus}
      inputProps={inputProps}
      disableUnderline={disableUnderline}
    />
  );
};

export default InputField;
