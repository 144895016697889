import { FC } from 'react';

import { MenuItem, Typography, Tooltip } from '@mui/material';

import { IConditionalMenuItemProps } from './types';

const ConditionalMenuItem: FC<IConditionalMenuItemProps> = props => {
  const { canEdit, onClick, sx, title, tooltip } = props;

  return (
    <>
      {canEdit ? (
        <MenuItem onClick={onClick} sx={sx}>
          <Typography variant="h6">{title}</Typography>
        </MenuItem>
      ) : (
        <Tooltip title={tooltip}>
          <span>
            <MenuItem disabled onClick={onClick} sx={sx}>
              <Typography variant="h6">{title}</Typography>
            </MenuItem>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default ConditionalMenuItem;
