import { FC, useState } from 'react';

import { MoreHorizOutlined } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Typography, Link as MUILink, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import appRoutes from 'routes/appRoutes';

import { useDeleteCurrentUserImpersonationMutation } from 'domain/currentUser/impersonation/apiSlice';

import { useNotifications } from 'hooks/useNotifications';

import { apiContainer, store } from 'store';

import { generateBackendErrorMessages } from 'utils/error';

import styles from './styles';
import ICurrentUserActionsMenuProps from './types';

const CurrentUserActionsMenu: FC<ICurrentUserActionsMenuProps> = props => {
  const { isImpersonated, userId } = props;

  const [menu, setMenu] = useState<null | HTMLElement>(null);

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [
    userDeleteImpersonation,
    { isLoading: isUserDeleteImpersonationLoading, isError: isUserDeleteImpersonationLoadingError },
  ] = useDeleteCurrentUserImpersonationMutation();

  const { t } = useTranslation(['common', 'sidebar']);

  const isMenuOpen = Boolean(menu);

  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenu(event.currentTarget);
  };

  const handleActionsClose = () => {
    setMenu(null);
  };

  const handleUserStopImpersonating = async () => {
    try {
      await userDeleteImpersonation().unwrap();
      store.dispatch(apiContainer.util.resetApiState());
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      handleActionsClose();
    }
  };

  if (isUserDeleteImpersonationLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  return (
    <>
      <IconButton
        sx={styles.actionsButton}
        id="profile-actions-button"
        aria-controls={isMenuOpen ? 'profile-actions-menu' : undefined}
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleActionsClick}
      >
        <MoreHorizOutlined sx={styles.moreHorizIcon} />
      </IconButton>
      <Menu
        id="profile-actions-menu"
        anchorEl={menu}
        open={isMenuOpen}
        onClose={handleActionsClose}
        MenuListProps={{
          'aria-labelledby': 'profile-actions-button',
        }}
      >
        {isImpersonated && (
          <MenuItem onClick={handleUserStopImpersonating} divider disabled={isUserDeleteImpersonationLoading}>
            <Typography variant="subtitle1">
              {t('sidebar:currentUserBadge.userActionsMenu.menuItems.stopImpersonating')}
            </Typography>
          </MenuItem>
        )}

        <MenuItem
          component={Link}
          to={appRoutes.userProfile.path(userId)}
          onClick={handleActionsClose}
          divider
          disabled={isUserDeleteImpersonationLoading}
        >
          <Typography variant="subtitle1">
            {t('sidebar:currentUserBadge.userActionsMenu.menuItems.profilePage')}
          </Typography>
        </MenuItem>
        <MenuItem
          component={MUILink}
          href={appRoutes.googleOauthLogout.path()}
          disabled={isUserDeleteImpersonationLoading}
        >
          <Typography variant="subtitle1">{t('sidebar:currentUserBadge.userActionsMenu.menuItems.logOut')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CurrentUserActionsMenu;
