import { SxStyles } from 'theme';

const styles: SxStyles = {
  input: {
    paddingTop: 0.625,
    marginBottom: 2,
    width: '380px',
  },
  buttonsContainer: {
    marginTop: 4,
    display: 'flex',
    gap: 1.5,
  },
  autocomplete: {
    marginBottom: 2,
  },
  placeholder: {
    color: 'text.gray',
  },
};

export default styles;
