import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import camelize from 'camelize';
import decamelizeKeysDeep from 'decamelize-keys-deep';
import _ from 'lodash';
import { stringify } from 'qs';

export const axiosInstance = axios.create({
  paramsSerializer: parameters => stringify(parameters, { arrayFormat: 'brackets' }),
  withCredentials: true,
});

const headers = () => {
  return {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  };
};

axiosInstance.defaults.headers.common = headers();

export const axiosBaseQuery = (): BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
  },
  unknown,
  unknown
> => {
  return async ({ url, method, data, params, headers }) => {
    try {
      const result = await axiosInstance({
        url: url,
        method,
        data: !_.isNil(data) && decamelizeKeysDeep(data),
        params: !_.isNil(params) && decamelizeKeysDeep(params),
        headers,
        withCredentials: true,
      });
      const camelizedResult = camelize(result.data);
      return { data: camelizedResult };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      return {
        error: {
          status: error.response?.status,
          data: camelize(error.response?.data) || error.message,
        },
      };
    }
  };
};
