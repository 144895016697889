import i18n from 'locales/i18n';

import { VacationApprovementApprovingLevelType, VacationApprovementState } from 'domain/report/vacationRequest/enums';
import { isTechCenter } from 'domain/report/vacationRequest/service';

import styles from './styles';

export const getColorSx = (state: VacationApprovementState) => {
  switch (state) {
    case VacationApprovementState.indefinite: {
      return styles.requested;
    }
    case VacationApprovementState.confirmed: {
      return styles.confirmed;
    }
    case VacationApprovementState.declined: {
      return styles.declined;
    }
    default: {
      return styles.none;
    }
  }
};

export const approvementToString = (state: VacationApprovementState): string => {
  switch (state) {
    case VacationApprovementState.indefinite: {
      return i18n.t('reportVacations:approvements.labels.pending');
    }
    case VacationApprovementState.confirmed: {
      return i18n.t('reportVacations:approvements.labels.approved');
    }
    case VacationApprovementState.declined: {
      return i18n.t('reportVacations:approvements.labels.declined');
    }
    default: {
      return i18n.t('reportVacations:approvements.labels.none');
    }
  }
};

export const levelTypeToString = (type: VacationApprovementApprovingLevelType): string => {
  return isTechCenter(type)
    ? i18n.t('reportVacations:approvements.labels.techCenter')
    : i18n.t('reportVacations:approvements.labels.project');
};
