export default {
  buttons: {
    addPeriod: 'Add period',
    changeAllocation: 'Change Allocation',
    cancel: 'Cancel',
  },
  fields: {
    startDate: {
      label: 'Start Date',
    },
    endDate: {
      label: 'End Date',
    },
  },
  switches: {
    endDate: {
      label: 'End Date',
    },
  },
};
