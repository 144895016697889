import { FC, useState } from 'react';

import { ExpandMoreOutlined } from '@mui/icons-material';
import { MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ResourceUsageType } from 'domain/resource/usage/enums';
import { getResourceUsageType } from 'domain/resource/usage/service';

import CreateResourceUsageForm from './components/CreateResourceUsageForm';
import CreateVacancyForm from './components/CreateVacancyForm';
import styles from './styles';
import { ICreateResourceUsageAndVacancyProps } from './types';

const CreateResourceUsageAndVacancy: FC<ICreateResourceUsageAndVacancyProps> = props => {
  const { projectId, groupId } = props;

  const { t } = useTranslation('createResourceUsageAndVacancy');

  const [resourceUsageType, setResourceUsageType] = useState<ResourceUsageType>(ResourceUsageType.employee);

  const { isEmployee, isVacancy } = getResourceUsageType(resourceUsageType);

  return (
    <Div sx={styles.root}>
      <TextField
        select
        sx={styles.select}
        value={resourceUsageType}
        SelectProps={{ IconComponent: ExpandMoreOutlined }}
        onChange={event => setResourceUsageType(event.target.value as ResourceUsageType)}
        label={t('resourceType.label')}
        InputLabelProps={{ sx: styles.selectPlaceholder }}
      >
        <MenuItem value={ResourceUsageType.employee}>{ResourceUsageType.employee}</MenuItem>
        <MenuItem value={ResourceUsageType.vacancy}>{ResourceUsageType.vacancy}</MenuItem>
      </TextField>

      {isEmployee && <CreateResourceUsageForm projectId={projectId} />}
      {isVacancy && <CreateVacancyForm projectId={projectId} groupId={groupId} />}
    </Div>
  );
};

export default CreateResourceUsageAndVacancy;
