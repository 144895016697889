import { useState } from 'react';

import { IModalData } from './types';

export const useModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<IModalData | null>(null);

  const openModal = (modalData: IModalData) => {
    setOpen(true);
    setModalData(modalData);
  };

  const closeModal = () => {
    setOpen(false);
    setModalData(null);
  };

  return { open, openModal, closeModal, modalData };
};
