import { FC } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';

import locales from 'locales/i18n';

import Icon from 'components/common/Icon';

import ConfirmationDialogProvider from 'contexts/ConfirmDialogContext';
import ModalProvider from 'contexts/ModalContext';
import UserLocaleProvider from 'contexts/UserLocaleContext';

import { store } from 'store';

import theme from 'theme';

import Router from './routers/Router';

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <I18nextProvider i18n={locales}>
          <ReduxProvider store={store}>
            <UserLocaleProvider>
              <SnackbarProvider maxSnack={5} iconVariant={{ error: <Icon name="errorOutline" /> }}>
                <ConfirmationDialogProvider>
                  <ModalProvider>
                    <Router />
                  </ModalProvider>
                </ConfirmationDialogProvider>
              </SnackbarProvider>
            </UserLocaleProvider>
          </ReduxProvider>
        </I18nextProvider>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default App;
