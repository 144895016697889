import { SxStyles } from 'theme';

const styles: SxStyles = {
  actionsButton: {
    padding: 0,
  },
  moreHorizIcon: {
    color: 'background.lightGray',
  },
};

export default styles;
