import * as yup from 'yup';

import { TimeTrackerTrackableType } from 'domain/timeTracker/trackable/enums';
export interface ICreateResourceFavoriteTimeEntryFormData {
  description: string;
  duration: number;
  taskId?: number;
  trackableId: number;
  trackableType: TimeTrackerTrackableType;
}
export interface ICreateResourceFavoriteTimeEntryFormDataToSubmit {
  formData: ICreateResourceFavoriteTimeEntryFormData;
  resourceId: number;
}

export const validationFields = {
  description: yup.string().default(''),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = () => validationSchema.getDefault();

export const formatAttributesToSubmit = (
  resourceId: number,
  formData: ICreateResourceFavoriteTimeEntryFormData,
): ICreateResourceFavoriteTimeEntryFormDataToSubmit => {
  return {
    resourceId,
    formData,
  };
};
