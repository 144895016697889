import { FC, useMemo } from 'react';

import { Typography } from '@mui/material';

import Div from 'components/common/Div';

import { getFullName } from 'utils/person';

import Table from './components/Table';
import { getColoredAccessors } from './components/Table/service';
import TableHeaderMonth from './components/TableHeaderMonth';
import TableNameCell from './components/TableNameCell';
import { ReportVacationRequestsTableColumnAccessor } from './enums';
import { getMonthsHeadersData, getVacationRangeAccesors } from './service';
import styles from './styles';
import { IVacationRequestTablesProps } from './types';

const VacationRequestTables: FC<IVacationRequestTablesProps> = props => {
  const { tableName, resources, year, endDate, startDate, currentResourceName } = props;

  const vacationAccessors = getVacationRangeAccesors(startDate, endDate);
  const monthsData = getMonthsHeadersData(year, endDate, startDate);
  const weekendsAccessors = monthsData.flatMap(monthData => monthData.weekendAccessors);
  const lastDayOfMonthAccessors = monthsData.map(monthsData => monthsData.lastDayAccessor);

  const RESOURCES_LIST_COLUMNS = useMemo(() => {
    const stickyHeaders = [
      {
        Header: '',
        accessor: ReportVacationRequestsTableColumnAccessor.resourcesEmpty,
        columns: [
          {
            Header: (
              <Typography sx={styles.resourceHeaderTitle} variant="body2">
                {tableName}
              </Typography>
            ),
            accessor: ReportVacationRequestsTableColumnAccessor.resourcesEmptyDepth,
            columns: [
              {
                Header: '',
                accessor: ReportVacationRequestsTableColumnAccessor.resources,
                minWidth: '250px',
              },
            ],
          },
        ],
      },
    ];

    const monthHeaders = monthsData.map(monthItem => {
      return {
        Header: '',
        accessor: `${monthItem.title}-empty`,
        columns: [
          {
            Header: (
              <TableHeaderMonth
                title={monthItem.title}
                firstDayOfMonth={monthItem.firstDay}
                lastDayOfMonth={monthItem.lastDay}
                year={year}
              />
            ),
            accessor: monthItem.title,
            columns: Array.from(
              { length: monthItem.lastDay - monthItem.firstDay + 1 },
              (_, index) => monthItem.firstDay + index,
            ).map(value => {
              const date = value;

              return {
                Header: (
                  <Typography sx={styles.dateHeaderTitle} variant="h6">
                    {date}
                  </Typography>
                ),
                accessor: `${monthItem.title}-${date}`,
                minWidth: '35px',
                width: '35px',
              };
            }),
          },
        ],
      };
    });

    return [...stickyHeaders, ...monthHeaders];
  }, [year]);

  const tableData = useMemo(() => {
    return resources.map(resource => {
      const fullName = getFullName(resource);
      const isActive = fullName === currentResourceName;

      return {
        [ReportVacationRequestsTableColumnAccessor.resources]: (
          <TableNameCell fullName={fullName} isActive={isActive} />
        ),
      };
    });
  }, [resources]);

  const coloredAccessors = getColoredAccessors(resources);

  return (
    <Div sx={styles.root}>
      <Table
        data={tableData}
        columns={RESOURCES_LIST_COLUMNS}
        coloredAccessors={coloredAccessors}
        weekendsAccessors={weekendsAccessors}
        lastDayOfMonthAccessors={lastDayOfMonthAccessors}
        vacationAccessors={vacationAccessors}
      />
    </Div>
  );
};

export default VacationRequestTables;
