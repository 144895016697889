import { FC } from 'react';

import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReportTrackedTimeEntriesCopyingType } from 'domain/report/trackedTimeEntry/usersList/enums';

import styles from './styles';
import { IUserTypeRadioGroupProps } from './types';

const UserTypeRadioGroup: FC<IUserTypeRadioGroupProps> = props => {
  const { type, onTypeChange } = props;

  const { t } = useTranslation('reportTrackedTimeEntries');

  const copyingTypeRadioGroup = [
    {
      label: t('copyEntryDrawer.slackNameType'),
      value: ReportTrackedTimeEntriesCopyingType.slackName,
    },
    {
      label: t('copyEntryDrawer.flatNameType'),
      value: ReportTrackedTimeEntriesCopyingType.flatName,
    },
    {
      label: t('copyEntryDrawer.emailType'),
      value: ReportTrackedTimeEntriesCopyingType.email,
    },
  ];

  return (
    <RadioGroup
      sx={styles.copyingTypeRadioGroup}
      aria-labelledby="copying-type-radio-buttons-group"
      name="copying-type"
      value={type}
      onChange={event => onTypeChange(event.target.value as ReportTrackedTimeEntriesCopyingType)}
    >
      {copyingTypeRadioGroup.map(copyingType => {
        const label = <Typography variant="subtitle1">{copyingType.label}</Typography>;

        return (
          <FormControlLabel
            key={copyingType.value}
            value={copyingType.value}
            control={<Radio size="small" />}
            label={label}
          />
        );
      })}
    </RadioGroup>
  );
};

export default UserTypeRadioGroup;
