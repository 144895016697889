import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { IconButton, Tooltip, Typography, Button } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { Link } from 'react-router-dom';

import appRoutes from 'routes/appRoutes';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import { TableCellAlignment } from 'components/common/Table/enums';
import Workload from 'components/common/Workload';

import { IReportHoursByResource } from 'domain/report/hoursByResource/types';
import { isProjectType } from 'domain/timeTracker/trackable/service';

import { formatToUSNumber } from 'utils/number';
import { getFullName } from 'utils/person';

import {
  IRenderExpandedHoursCellOptions,
  IRenderHoursCellOptions,
  IRenderSubRowHeaderCellOptions,
  IRenderUtilizationHoursCellOptions,
} from './types';

export const renderHelper = ({ styles, buildPathHelpers, onAddAllocationClick, t }) => {
  const getAvailableHoursText = (availableHours: number) => {
    return t('table.availableHours', { hours: formatToUSNumber(availableHours) });
  };

  const renderHoursCell = (options: IRenderHoursCellOptions) => {
    const {
      value,
      planHours,
      resource,
      project,
      isBillableProject,
      dateRange,
      isActualHoursCell = false,
      isPlannedHoursCell = false,
      isProjectActualHoursCell = false,
    } = options;

    if (value === '') {
      return '';
    }

    const isActualNotBalanced = isActualHoursCell && value !== 0 && planHours === 0;

    const valueToUSFormatted = formatToUSNumber(value);
    const isResourceActualHoursLink = Boolean(isActualHoursCell && resource);
    const isResourcePlannedHoursLink = Boolean(isPlannedHoursCell && resource);
    const isProjectActualHoursLink = Boolean(isProjectActualHoursCell && resource && project);
    const isHoursLinkCell = isResourceActualHoursLink || isProjectActualHoursLink || isResourcePlannedHoursLink;

    const hoursCellstyles = MUISx(
      { condition: isHoursLinkCell, sx: styles.hoursLinkCell },
      { condition: isActualNotBalanced, sx: styles.actualNotBalanced },
    );

    const getPathForHoursLink = () => {
      if (isProjectActualHoursLink) {
        return buildPathHelpers.buildPathForProjectActualHoursClick(resource, project, isBillableProject, {
          startDate: dateRange.dateStart,
          endDate: dateRange.dateEnd,
        });
      }

      if (isResourceActualHoursLink) {
        return buildPathHelpers.buildPathForResourceActualHoursClick(resource, {
          startDate: dateRange.dateStart,
          endDate: dateRange.dateEnd,
        });
      }

      if (isResourcePlannedHoursLink) {
        return buildPathHelpers.buildPathForResourcePlannedHoursClick(resource, {
          startDate: dateRange.dateStart,
          endDate: dateRange.dateEnd,
        });
      }
    };

    if (isHoursLinkCell) {
      const path = getPathForHoursLink();

      return (
        <Typography
          to={path}
          component={Link}
          sx={hoursCellstyles}
          variant="subtitle1"
          target="_blank"
          rel="noopener noreferrer"
        >
          {valueToUSFormatted}
        </Typography>
      );
    }

    return (
      <Typography sx={hoursCellstyles} variant="subtitle1">
        {valueToUSFormatted}
      </Typography>
    );
  };

  const renderUtilizationHoursCell = (options: IRenderUtilizationHoursCellOptions) => {
    const {
      value,
      isTargetHoursCell = false,
      isPlannedHoursCell = false,
      isActualHoursCell = false,
      expectedHours = 0,
      plannedHours = 0,
      actualHours = 0,
      availableHours,
    } = options;

    const getTopTooltipValue = () => {
      if (isTargetHoursCell) {
        return t('table.expectedHours', { hours: formatToUSNumber(expectedHours) });
      }

      if (isPlannedHoursCell) {
        return t('table.plannedHours', { hours: formatToUSNumber(plannedHours) });
      }

      if (isActualHoursCell) {
        return t('table.actualHours', { hours: formatToUSNumber(actualHours) });
      }
    };

    const topTooltipValue = getTopTooltipValue();
    const title = (
      <>
        <Typography component="p">{topTooltipValue}</Typography>
        <Typography component="p">{getAvailableHoursText(availableHours)}</Typography>
      </>
    );

    return (
      <Tooltip arrow title={title} placement="top">
        <Typography sx={styles.utilization} variant="subtitle1">
          {formatToUSNumber(value)}%
        </Typography>
      </Tooltip>
    );
  };

  const renderExpandedCell = (options: IRenderExpandedHoursCellOptions) => {
    const { value, withTruncate, textAlign } = options;

    const tooltipValue = withTruncate ? value : '';
    const expandedCellStyles = MUISx(
      { condition: withTruncate, sx: styles.truncate },
      { condition: textAlign == TableCellAlignment.left, sx: styles.textAlignLeft },
    );

    return (
      <Tooltip arrow title={tooltipValue} placement="top">
        <Typography variant="subtitle1" sx={expandedCellStyles}>
          {value}
        </Typography>
      </Tooltip>
    );
  };

  const renderResourceUsageHeaderCell = (resource: IReportHoursByResource) => {
    const fullName = getFullName(resource);
    const { canViewUser, canCreateAllocation } = resource.permissions;

    return (
      <Div sx={styles.withWorkload}>
        <Div sx={styles.resourceFullName}>
          {canViewUser ? (
            <Typography
              component={Link}
              to={appRoutes.userProfile.path(resource.userId)}
              sx={styles.resourceUsageCellHeader}
              variant="heading3"
              target="_blank"
              rel="noopener noreferrer"
            >
              {fullName}
            </Typography>
          ) : (
            <Typography sx={styles.resourceUsageCellHeaderNotClickable} variant="heading3">
              {fullName}
            </Typography>
          )}
        </Div>
        {canCreateAllocation && (
          <Tooltip arrow title={t('tooltips.addResourceAllocation')}>
            <IconButton onClick={() => onAddAllocationClick(resource)} sx={styles.addAllocationButton}>
              <Icon sx={styles.iconPlus} name="plus" />
            </IconButton>
          </Tooltip>
        )}
        {!_.isNil(resource.workload) && <Workload workload={resource.workload} />}
      </Div>
    );
  };

  const renderSubRowHeaderCell = (options: IRenderSubRowHeaderCellOptions) => {
    const {
      header,
      resource,
      workload,
      isBillableProject = false,
      isNonBillableProject = false,
      project,
      isClickable,
      isHeader,
      hasAccessToProject,
    } = options;
    const tooltipText = t('table.deny_access', { header: header });
    const isProject = !_.isNil(project?.id) && isProjectType(project);
    const canCreateAllocation = resource?.permissions?.canCreateAllocation;

    const typographyElement = (
      <Typography
        component={isClickable && hasAccessToProject ? Link : 'span'}
        to={buildPathHelpers.buildPathForProjectNameClick(project)}
        sx={MUISx(
          styles.projectHeader,
          { condition: isBillableProject, sx: styles.billableProjectHeader },
          { condition: isNonBillableProject, sx: styles.nonBillableProjectHeader },
          { condition: isClickable, sx: styles.isProjectClickable },
        )}
        variant="heading3"
        target="_blank"
        rel="noopener noreferrer"
      >
        {header}
      </Typography>
    );

    return (
      <Div sx={styles.withWorkload}>
        {isHeader && typographyElement}
        {!isHeader && hasAccessToProject && typographyElement}
        {!isHeader && isClickable && !hasAccessToProject && (
          <Tooltip arrow title={tooltipText} placement="top">
            {typographyElement}
          </Tooltip>
        )}
        {_.isNil(workload) ? (
          isProject &&
          canCreateAllocation && (
            <Button
              sx={styles.addResourceUsageButton}
              variant="outlined"
              onClick={() => onAddAllocationClick(resource, project)}
            >
              {t('table.createResourceUsageAndVacancyButton')}
            </Button>
          )
        ) : (
          <Workload workload={workload} />
        )}
      </Div>
    );
  };

  const renderColumns = ({ isDetailedInfo, data }) => {
    const {
      report: { totals },
    } = data;

    if (_.isEmpty(totals)) {
      return [];
    }

    const mainColumn = [
      {
        id: 'expander',
        minWidth: '360px',
        Header: '',
        accessor: 'resourceTotalEmpty',
        columns: [
          {
            Header: '',
            accessor: 'resourceTotalEmptyDepth',
            columns: [
              {
                Header: <Typography variant="h4">{t('table.columnsHeaders.resourcesTotal')}</Typography>,
                accessor: 'resourceTotal',
                Cell: ({ row }) => {
                  return row.depth === 0 ? (
                    <Div sx={styles.cellTitleBlock}>
                      <IconButton sx={styles.cellTitleIconButton} {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? (
                          <ExpandLessOutlined sx={styles.cellTitleIcon} />
                        ) : (
                          <ExpandMoreOutlined sx={styles.cellTitleIcon} />
                        )}
                      </IconButton>
                      {row.original.resourcesTotal}
                    </Div>
                  ) : (
                    row.original.resourcesTotal
                  );
                },
              },
            ],
          },
        ],
      },
    ];

    const expandedColumns = isDetailedInfo
      ? [
          {
            Header: '',
            accessor: 'firstExpandedHeader',
            columns: [
              {
                Header: '',
                accessor: 'secondExpandedHeader',
                columns: [
                  {
                    Header: <Typography variant="h4">{t('table.columnsHeaders.specialization')}</Typography>,
                    accessor: 'technologies',
                  },
                  {
                    Header: <Typography variant="h4">{t('table.columnsHeaders.physicalLocation')}</Typography>,
                    accessor: 'physicalLocation',
                    minWidth: '170px',
                  },
                  {
                    Header: <Typography variant="h4">{t('table.columnsHeaders.hierarchy')}</Typography>,
                    accessor: 'hierarchy',
                  },
                  {
                    Header: <Typography variant="h4">{t('table.columnsHeaders.calendar')}</Typography>,
                    accessor: 'calendar',
                  },
                  {
                    Header: <Typography variant="h4">{t('table.columnsHeaders.startDate')}</Typography>,
                    accessor: 'startDate',
                    minWidth: '120px',
                  },
                  {
                    Header: <Typography variant="h4">{t('table.columnsHeaders.billable')}</Typography>,
                    accessor: 'billable',
                  },
                  {
                    Header: <Typography variant="h4">{t('table.columnsHeaders.billableStartDate')}</Typography>,
                    accessor: 'billableStartDate',
                    minWidth: '180px',
                  },
                ],
              },
            ],
          },
        ]
      : [];

    const hoursColumns = [
      {
        Header: <Typography variant="tableHeading">{t('table.columnsHeaders.hours').toUpperCase()}</Typography>,
        accessor: 'hoursHeader',
        columns: [
          {
            Header: (
              <Tooltip arrow title={<Typography>{t('table.columnsTooltips.available')}</Typography>} placement="top">
                <Typography variant="tableHeading">{t('table.columnsHeaders.available')}</Typography>
              </Tooltip>
            ),
            accessor: 'availableEmpty',
            minWidth: '148px',
            columns: [
              {
                Header: <Typography variant="h4">{formatToUSNumber(totals.actual.availableHours)}</Typography>,
                accessor: 'available',
              },
            ],
          },
          {
            Header: (
              <Tooltip arrow title={<Typography>{t('table.columnsTooltips.expected')}</Typography>} placement="top">
                <Typography variant="tableHeading">{t('table.columnsHeaders.expected')}</Typography>
              </Tooltip>
            ),
            accessor: 'expectedEmpty',
            minWidth: '148px',
            columns: [
              {
                Header: <Typography variant="h4">{formatToUSNumber(totals.plan.expectedHours)}</Typography>,
                accessor: 'expected',
              },
            ],
          },
          {
            Header: (
              <Tooltip arrow title={<Typography>{t('table.columnsTooltips.planned')}</Typography>} placement="top">
                <Typography variant="tableHeading">{t('table.columnsHeaders.planned')}</Typography>
              </Tooltip>
            ),
            accessor: 'plannedEmpty',
            minWidth: '148px',
            columns: [
              {
                Header: <Typography variant="h4">{formatToUSNumber(totals.plan.hours)}</Typography>,
                accessor: 'planned',
              },
            ],
          },
          {
            Header: (
              <Tooltip arrow title={<Typography>{t('table.columnsTooltips.actual')}</Typography>} placement="top">
                <Typography variant="tableHeading">{t('table.columnsHeaders.actual')}</Typography>
              </Tooltip>
            ),
            accessor: 'actualEmpty',
            minWidth: '148px',
            columns: [
              {
                Header: <Typography variant="h4">{formatToUSNumber(totals.actual.hours)}</Typography>,
                accessor: 'actual',
              },
            ],
          },
        ],
      },
    ];

    const utilizationColumns = [
      {
        Header: <Typography variant="tableHeading">{t('table.columnsHeaders.utilization').toUpperCase()}</Typography>,
        accessor: 'utilizationHeader',
        columns: [
          {
            Header: (
              <Tooltip
                arrow
                title={<Typography>{t('table.columnsTooltips.targetUtilization')}</Typography>}
                placement="top"
              >
                <Typography variant="tableHeading">{t('table.columnsHeaders.targetUtilization')}</Typography>
              </Tooltip>
            ),
            accessor: 'targetUtilizationEmpty',
            minWidth: '148px',
            columns: [
              {
                Header: (
                  <Tooltip
                    arrow
                    title={
                      <>
                        <Typography component="p">
                          {t('table.expectedHours', { hours: formatToUSNumber(totals.plan.expectedHours) })}
                        </Typography>
                        <Typography component="p">{getAvailableHoursText(totals.actual.availableHours)}</Typography>
                      </>
                    }
                    placement="top"
                  >
                    <Typography sx={styles.utilization} variant="h4">
                      {formatToUSNumber(totals.plan.targetUtilization)}%
                    </Typography>
                  </Tooltip>
                ),
                accessor: 'targetUtilization',
              },
            ],
          },
          {
            Header: (
              <Tooltip
                arrow
                title={<Typography>{t('table.columnsTooltips.plannedUtilization')}</Typography>}
                placement="top"
              >
                <Typography variant="tableHeading">{t('table.columnsHeaders.plannedUtilization')}</Typography>
              </Tooltip>
            ),
            accessor: 'plannedUtilizationEmpty',
            minWidth: '148px',
            columns: [
              {
                Header: (
                  <Tooltip
                    arrow
                    title={
                      <>
                        <Typography component="p">
                          {t('table.plannedHours', { hours: formatToUSNumber(totals.plan.hours) })}
                        </Typography>
                        <Typography component="p">{getAvailableHoursText(totals.actual.availableHours)}</Typography>
                      </>
                    }
                    placement="top"
                  >
                    <Typography sx={styles.utilization} variant="h4">
                      {formatToUSNumber(totals.plan.plannedUtilization)}%
                    </Typography>
                  </Tooltip>
                ),
                accessor: 'plannedUtilization',
              },
            ],
          },
          {
            Header: (
              <Tooltip
                arrow
                title={<Typography>{t('table.columnsTooltips.actualUtilization')}</Typography>}
                placement="top"
              >
                <Typography variant="tableHeading">{t('table.columnsHeaders.actualUtilization')}</Typography>
              </Tooltip>
            ),
            accessor: 'actualUtilizationEmpty',
            minWidth: '148px',
            columns: [
              {
                Header: (
                  <Tooltip
                    arrow
                    title={
                      <>
                        <Typography component="p">
                          {t('table.actualHours', { hours: formatToUSNumber(totals.actual.hours) })}
                        </Typography>
                        <Typography component="p">{getAvailableHoursText(totals.actual.availableHours)}</Typography>
                      </>
                    }
                    placement="top"
                  >
                    <Typography sx={styles.utilization} variant="h4">
                      {formatToUSNumber(totals.actual.utilization)}%
                    </Typography>
                  </Tooltip>
                ),
                accessor: 'actualUtilization',
              },
            ],
          },
        ],
      },
    ];

    return [...mainColumn, ...expandedColumns, ...hoursColumns, ...utilizationColumns];
  };

  return {
    renderHoursCell,
    renderUtilizationHoursCell,
    renderExpandedCell,
    renderResourceUsageHeaderCell,
    renderSubRowHeaderCell,
    renderColumns,
  };
};
