import { FC } from 'react';

import Loader from 'components/common/Loader';
import VacationRequestTables from 'components/pages/Report/Vacations/components/VacationRequests/components/VacationRequestItem/components/VacationRequestExpandedContent/components/VacationRequestTabs/components/VacationRequestTables/index';
import { IVacationRequestTabsProps } from 'components/pages/Report/Vacations/components/VacationRequests/components/VacationRequestItem/components/VacationRequestExpandedContent/components/VacationRequestTabs/types';

import { useGetReportVacationRequestsSpecializationsQuery } from 'domain/report/vacationRequest/apiSlice';

const VacationRequestPracticesTab: FC<IVacationRequestTabsProps> = props => {
  const { vacationsEndDate, vacationsRequestId, vacationsRequestName, vacationsRequestYear, vacationsStartDate } =
    props;

  const {
    data: vacationRequestProjects,
    isLoading,
    isFetching,
  } = useGetReportVacationRequestsSpecializationsQuery({ vacationsRequestId });

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return vacationRequestProjects?.practices?.map(practice => (
    <VacationRequestTables
      key={`practice${practice.id}`}
      tableName={practice.name}
      year={vacationsRequestYear}
      endDate={vacationsEndDate}
      startDate={vacationsStartDate}
      resources={practice.resources}
      currentResourceName={vacationsRequestName}
    />
  ));
};
export default VacationRequestPracticesTab;
