import { TableCell, Typography } from '@mui/material';
import { getDate, isToday, isWeekend } from 'date-fns';
import MUISx from 'mui-sx';

import styles from './styles';
import { IDayCellProps } from './types';

const DayCell = (props: IDayCellProps) => {
  const { date, cellPositionStyle, isMonthEndDate, isRightBorderHidden } = props;

  return (
    <TableCell
      style={cellPositionStyle}
      sx={MUISx(
        styles.cell,
        { condition: isMonthEndDate, sx: styles.boldRightBorder },
        { condition: isRightBorderHidden, sx: styles.hiddenRightBorder },
        { condition: isWeekend(date), sx: styles.weekendCell },
      )}
    >
      <Typography
        variant="h6"
        sx={MUISx(styles.title, {
          condition: isToday(date),
          sx: styles.todayTitle,
        })}
      >
        {getDate(date)}
      </Typography>
    </TableCell>
  );
};

export default DayCell;
