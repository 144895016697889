import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IAllocationItem } from 'domain/report/allocation/types';
import { IReportResourceUsage } from 'domain/report/hoursByProject/types';
import { ITechnology } from 'domain/technology/types';

import { convertDateToUTCString, formatToDateWithFixedTimeZone } from 'utils/dateTime';

export interface IUpdateResourceUsageFormData {
  workload?: number;
  technologies?: Array<ITechnology>;
  startDate: Date | null;
  endDate: Date | null;
}

export interface IUpdateResourceUsagePreparedFormData
  extends Omit<IUpdateResourceUsageFormData, 'technologies' | 'startDate' | 'endDate'> {
  technologyIds?: Array<number>;
  projectId: number;
  startDate: string;
  endDate: string | null;
}

export interface IUpdateResourceUsageFormDataToSubmit {
  resourceId: number;
  resourceUsageId: number;
  formData: IUpdateResourceUsagePreparedFormData;
}

const requiredValidation = 'forms:validation:required';

const dateFormatValidation = 'forms:validation:dateFormat';

export const buildValidationFields = () => ({
  workload: yup.number().min(0).max(100).required(i18n.t(requiredValidation)).nullable().default(null),
  technologies: yup.array().of(yup.mixed<ITechnology>()).default([]),
  startDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .required(i18n.t(requiredValidation))
    .nullable()
    .default(null),
  endDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .min(yup.ref('startDate'), i18n.t('forms:validation:startDateBeforeEndDate'))
    .nullable()
    .default(null),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (resourceUsage: IReportResourceUsage | IAllocationItem) => {
  if (_.isNil(resourceUsage)) {
    return buildValidationSchema().getDefault();
  }

  const { workload, startDate, endDate, technologies } = resourceUsage;

  return {
    workload,
    startDate: formatToDateWithFixedTimeZone(startDate),
    endDate: _.isNil(endDate) ? null : formatToDateWithFixedTimeZone(endDate),
    technologies,
  };
};

export const formatAttributesToSubmit = (
  resourceId: number,
  resourceUsageId: number,
  projectId: number,
  formData: IUpdateResourceUsageFormData,
): IUpdateResourceUsageFormDataToSubmit => {
  const { technologies, startDate, endDate, ...values } = formData;

  const technologyIds = technologies.map(({ id }) => id);
  const normalizeStartDate = convertDateToUTCString(startDate);
  const normalizeEndDate = _.isNil(endDate) ? null : convertDateToUTCString(endDate);

  return {
    resourceId,
    resourceUsageId,
    formData: {
      technologyIds,
      startDate: normalizeStartDate,
      endDate: normalizeEndDate,
      ...values,
      projectId,
    },
  };
};
