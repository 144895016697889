import { IResource } from 'domain/resource/types';
import { ResourseVacationState } from 'domain/resource/vacation/enums';
import { IVacation } from 'domain/resource/vacation/types';

import { IVacationsByState } from './types';

const getVacationsByState = (vacations: IVacation[], state: ResourseVacationState): IVacation[] =>
  vacations.filter(vacation => vacation.state === state);

export const groupVacationsByState = (resource: IResource): IVacationsByState => {
  const completed = getVacationsByState(resource.vacations, ResourseVacationState.completed);
  const approved = getVacationsByState(resource.vacations, ResourseVacationState.approved);
  const approvedOnProjectLevel = getVacationsByState(resource.vacations, ResourseVacationState.approvedOnProjectLevel);
  const onApproval = getVacationsByState(resource.vacations, ResourseVacationState.onApproval);
  const requested = [...approvedOnProjectLevel, ...onApproval];

  return {
    completed,
    approved,
    requested,
  };
};
