import { SxStyles } from 'theme';

const styles: SxStyles = {
  mainListItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 2,
  },
  mainListItemTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 1,
  },
  listPointer: {
    width: '5px',
    height: '5px',
    marginRight: 1.25,
    color: 'text.lightGray',
  },
  innerList: {
    paddingLeft: 2.25,
    paddingTop: 1,
    paddingBottom: 1,
    marginLeft: 0.25,
    borderLeft: theme => `1px solid ${theme.palette.border.slateBlueGray}`,
  },
  addNewCategoryButton: {
    borderLeft: theme => `1px solid ${theme.palette.border.slateBlueGray}`,
    marginLeft: 0.25,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    paddingLeft: 2.375,
    paddingBottom: 1,
  },
};

export default styles;
