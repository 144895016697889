import { FC, useContext } from 'react';

import { Button, List, ListItem, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { ModalContext } from 'contexts/ModalContext';

import CreateTrackingTypeForm from './components/CreateTrackingTypeForm';
import InnerListItem from './components/InnerListItem';
import styles from './styles';
import { IAdminPanelTrackingTypesMainListItemProps } from './types';

const MainListItem: FC<IAdminPanelTrackingTypesMainListItemProps> = props => {
  const { trackingTypes = [], title } = props;

  const { openModal } = useContext(ModalContext);

  const { t } = useTranslation('adminPanel');

  const handleAddNewCategoryClick = () => {
    openModal({
      title: t('trackingTypes.mainListItem.createTrackingTypeFormTitle'),
      content: <CreateTrackingTypeForm />,
    });
  };

  return (
    <ListItem sx={styles.mainListItem}>
      <Div sx={styles.mainListItemTitle}>
        <Icon name="listPointer" sx={styles.listPointer} />
        <Typography variant="heading3">{title}</Typography>
      </Div>

      {!_.isEmpty(trackingTypes) && (
        <>
          <List sx={styles.innerList}>
            {trackingTypes.map(trackingType => {
              return <InnerListItem key={trackingType.id} trackingType={trackingType} />;
            })}
          </List>
          <Button type="button" onClick={handleAddNewCategoryClick} sx={styles.addNewCategoryButton} variant="text">
            {t('trackingTypes.mainListItem.addNewCategoryButton')}
          </Button>
        </>
      )}
    </ListItem>
  );
};

export default MainListItem;
