import { TableRow, TableCell, Skeleton } from '@mui/material';
import { isEqual } from 'date-fns';
import MUISx from 'mui-sx';

import { getLastDate } from 'components/pages/Report/Vacations/components/Calendar/service';

import { getLastIndex } from 'utils/array';

import styles from './styles';
import { ISkeletonRowProps } from './types';

const ONE_ITEM = 1;
const DEFAULT_COL_SPAN = 4;

const SkeletonRow = (props: ISkeletonRowProps) => {
  const { isWideRange, dateRangesByMonth, stickyCellsPositions } = props;

  const [firstCellPosition, secondCellPosition] = stickyCellsPositions;

  const stickyPositionsLength = stickyCellsPositions.length;
  const secondCellColSpan = stickyPositionsLength === 0 ? DEFAULT_COL_SPAN : stickyPositionsLength - ONE_ITEM;

  return (
    <TableRow>
      <TableCell sx={MUISx(styles.cell, styles.stickyCell, styles.resource)} style={firstCellPosition}>
        <Skeleton animation="pulse" variant="rectangular" sx={styles.skeleton} />
      </TableCell>
      <TableCell sx={MUISx(styles.cell, styles.stickyCell)} style={secondCellPosition} colSpan={secondCellColSpan}>
        <Skeleton animation="pulse" variant="rectangular" sx={styles.skeleton} />
      </TableCell>
      {dateRangesByMonth.map((daysInMonth, index) => {
        const monthEndDate = daysInMonth[getLastIndex(daysInMonth)];
        const dateRangeEndDate = getLastDate(dateRangesByMonth);
        const isDateRangeEndDate = isEqual(monthEndDate, dateRangeEndDate);
        const isRightBorderHidden = isDateRangeEndDate && isWideRange;

        return (
          <TableCell
            key={index}
            sx={MUISx(styles.cell, { condition: isRightBorderHidden, sx: styles.hiddenRightBorder })}
            colSpan={daysInMonth.length}
          >
            <Skeleton animation="pulse" variant="rectangular" sx={styles.skeleton} />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default SkeletonRow;
