import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IResource } from 'domain/resource/types';
import { ITechnology } from 'domain/technology/types';

import { convertDateToUTCString } from 'utils/dateTime';

const requiredValidation = 'forms:validation:required';

const dateFormatValidation = 'forms:validation:dateFormat';

export interface ICreateResourceUsageFormData {
  resource: IResource;
  projectId?: number;
  workload: number;
  technologies: Array<ITechnology>;
  startDate: Date;
  endDate?: Date | null;
}

export interface ICreateResourceUsagePreparedFormData
  extends Omit<ICreateResourceUsageFormData, 'resource' | 'technologies' | 'startDate' | 'endDate'> {
  technologyIds: Array<number>;
  startDate: string;
  endDate: string | null;
}

export interface ICreateResourceUsageFormDataToSubmit {
  resourceId: number;
  formData: ICreateResourceUsagePreparedFormData;
}

export const buildValidationFields = () => ({
  resource: yup.mixed<IResource>().required(i18n.t(requiredValidation)).nullable().default(null),
  workload: yup.number().min(0).max(100).required(i18n.t(requiredValidation)).nullable().default(null),
  startDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .required(i18n.t(requiredValidation))
    .nullable()
    .default(new Date()),
  endDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .min(yup.ref('startDate'), i18n.t('forms:validation:startDateBeforeEndDate'))
    .nullable()
    .default(null),
  technologies: yup.array().of(yup.mixed<ITechnology>()).default([]),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (resource: IResource) => {
  if (_.isNil(resource)) {
    return buildValidationSchema().getDefault();
  }

  return {
    resource,
    technologies: resource.technologies,
  };
};

export const formatAttributesToSubmit = (
  projectId: number,
  formData: ICreateResourceUsageFormData,
): ICreateResourceUsageFormDataToSubmit => {
  const { technologies, resource, startDate, endDate, ...values } = formData;

  const technologyIds = technologies.map(({ id }) => id);
  const normalizeStartDate = convertDateToUTCString(startDate);
  const normalizeEndDate = _.isNil(endDate) ? null : convertDateToUTCString(endDate);

  return {
    resourceId: resource.id,
    formData: {
      technologyIds,
      projectId,
      startDate: normalizeStartDate,
      endDate: normalizeEndDate,
      ...values,
    },
  };
};
