import { FC, useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuItem, TextField, Alert } from '@mui/material';
import _ from 'lodash';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Autocomplete from 'components/common/Autocomplete';
import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import TechnologiesAutocomplete from 'components/common/TechnologiesAutocomplete';

import { ModalContext } from 'contexts/ModalContext';

import { useCreateAccessLevelMutation } from 'domain/accessLevel/apiSlice';
import { AccessibleType } from 'domain/accessLevel/enums';
import {
  formatAttributesToSubmit,
  ICreateAccessLevelFormData,
  initialValues,
  buildValidationSchema,
} from 'domain/accessLevel/schemas/create';
import { permissionOptions } from 'domain/accessLevel/service';
import { useGetGroupsQuery } from 'domain/group/apiSlice';
import { IGroup } from 'domain/group/types';
import { useGetProjectsQuery } from 'domain/project/apiSlice';
import { IProject } from 'domain/project/types';
import { useGetTechnologiesQuery } from 'domain/technology/apiSlice';
import { ITechnology } from 'domain/technology/types';

import { SuggestionAutocompleteType } from 'enums/SuggestionAutocomplete';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';
import { getSortParameter } from 'utils/runsack';
import { getPreparedDataForSuggestionAutocomplete } from 'utils/suggestionAutocomplete';

import styles from './styles';
import { ICreateUserProfileAdditionAccessLevelFormProps } from './types';

const CreateUserProfileAdditionAccessLevelForm: FC<ICreateUserProfileAdditionAccessLevelFormProps> = props => {
  const { userId, isUserProfileProjectAccessLevel } = props;

  const [accessibleName, setAccessibleName] = useState<string>('');

  const { closeModal } = useContext(ModalContext);

  const { t } = useTranslation('userProfile');

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [createAccessLevel, { isLoading: isCreateAccessLevelLoading }] = useCreateAccessLevelMutation();

  const [debouncedAccessibleName] = useDebounce(accessibleName, 300);

  const [technologyName, setTechnologyName] = useState<string>('');

  const [debouncedTechnologyName] = useDebounce(technologyName, 300);

  const {
    data: groupsData,
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching,
  } = useGetGroupsQuery({
    nameCont: debouncedAccessibleName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const {
    data: technologiesData,
    isLoading: isTechnologiesDataLoading,
    isError: isTechnologiesDataLoadingError,
    isFetching: isTechnologiesDataFetching,
  } = useGetTechnologiesQuery({
    nameCont: debouncedTechnologyName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const {
    data: projectsData,
    isLoading: isProjectsLoading,
    isFetching: isProjectsFetching,
  } = useGetProjectsQuery({
    nameCont: debouncedAccessibleName,
    sort: getSortParameter('name', 'asc'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateAccessLevelFormData>({
    defaultValues: initialValues(),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: permission, onChange: onPermissionChange },
  } = useController({ name: 'permission', control });

  const {
    field: { value: technologies, onChange: onTechnologiesChange },
  } = useController({ name: 'technologies', control });

  const {
    field: { value: accessible, onChange: onAccessibleChange },
  } = useController({ name: 'accessible', control });
  const accessibleType = isUserProfileProjectAccessLevel ? AccessibleType.project : AccessibleType.group;

  const onSubmit = async (formData: ICreateAccessLevelFormData) => {
    try {
      await createAccessLevel(
        formatAttributesToSubmit({ ...formData, accessibleType: accessibleType }, userId),
      ).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const handleCurrentAccessibleChange = (currentValue: IGroup | IProject) => {
    if (_.isNil(currentValue)) {
      onAccessibleChange(null);
      return;
    }
    onAccessibleChange(currentValue);
  };

  const handleCurrentTechnologiesChange = (technologies: Array<ITechnology>) => {
    if (_.isEmpty(technologies)) {
      onTechnologiesChange([]);
      return;
    }
    onTechnologiesChange(technologies);
  };

  const isDataLoading = isCreateAccessLevelLoading || isGroupsLoading || isProjectsLoading || isTechnologiesDataLoading;
  const isError = isTechnologiesDataLoadingError;

  if (isDataLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const groupsOptions = getPreparedDataForSuggestionAutocomplete<IGroup>({
    data: groupsData.groups,
    type: SuggestionAutocompleteType.tree,
  });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.root}>
        <Div sx={styles.select}>
          <Autocomplete
            options={isUserProfileProjectAccessLevel ? projectsData.projects : groupsOptions}
            isFetching={isUserProfileProjectAccessLevel ? isProjectsFetching : isGroupsFetching}
            isError={Boolean(errors.accessible)}
            name={accessibleName}
            setName={setAccessibleName}
            onCurrentValueChange={handleCurrentAccessibleChange}
            currentValue={accessible}
            label={
              isUserProfileProjectAccessLevel
                ? t('accessLevel.createAdditionalAccessForm.selects.project.label')
                : t('accessLevel.createAdditionalAccessForm.selects.group.label')
            }
          />
        </Div>

        {!isUserProfileProjectAccessLevel && (
          <Div sx={styles.select}>
            <TechnologiesAutocomplete
              technologies={technologiesData.technologies}
              currentTechnologies={technologies}
              onCurrentTechnologiesChange={handleCurrentTechnologiesChange}
              setTechnologyName={setTechnologyName}
              isError={Boolean(errors.technologies)}
              isTechnologiesFetching={isTechnologiesDataFetching}
              label={t('createResourceUsageAndVacancy:createResourceUsageForm.practice.label')}
              helperText={buildErrorMessage(t, errors.technologies)}
            />
          </Div>
        )}

        <TextField
          select
          error={Boolean(errors.permission)}
          sx={styles.select}
          value={permission ?? ''}
          onChange={onPermissionChange}
          label={t('accessLevel.createAdditionalAccessForm.selects.permission.label')}
          InputLabelProps={{ sx: styles.selectPlaceholder }}
        >
          {permissionOptions[accessibleType].map(permissionOption => {
            return (
              <MenuItem sx={styles.permissionValue} key={permissionOption.id} value={permissionOption.id}>
                {permissionOption.name}
              </MenuItem>
            );
          })}
        </TextField>

        <Div sx={styles.buttons}>
          <Button sx={styles.createAdditionalAccessButton} type="submit" disabled={isDataLoading}>
            {t('accessLevel.createAdditionalAccessForm.buttons.addAccess')}
          </Button>
          <Button type="reset" variant="outlined" onClick={closeModal} disabled={isDataLoading}>
            {t('accessLevel.createAdditionalAccessForm.buttons.cancel')}
          </Button>
        </Div>
      </Div>
    </form>
  );
};

export default CreateUserProfileAdditionAccessLevelForm;
