export enum TabName {
  hierarchy = 'hierarchy',
  specializations = 'specializations',
  workingCalendars = 'workingCalendars',
  projectGroups = 'projectGroups',
  companyInfo = 'companyInfo',
  companySettings = 'companySettings',
  trackingTypes = 'trackingTypes',
  tags = 'tags',
  forbiddenWords = 'forbiddenWords',
}
