import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { convertDateToUTCString } from 'utils/dateTime';

import { IUserHoursPerWeek } from '../types';

export interface IUpdateUserHoursPerWeekFormData {
  weeklyHours: number;
  startDate: Date;
  endDate: Date;
}

export interface IUpdateUserHoursPerWeekPreparedFormData
  extends Omit<IUpdateUserHoursPerWeekFormData, 'weeklyHours' | 'startDate' | 'endDate'> {
  startDate: string;
  endDate: string | null;
}

export interface IUpdateUserHoursPerWeekFormDataToSubmit {
  hoursPerWeekId: number;
  userId: number;
  formData: {
    hoursPerWeek: IUpdateUserHoursPerWeekPreparedFormData;
  };
}

const requiredField = 'forms:validation:required';

const buildValidationFields = t => ({
  startDate: yup
    .date()
    .typeError(t('forms:validation:dateFormat'))
    .required(t(requiredField))
    .nullable()
    .default(new Date()),
  endDate: yup
    .date()
    .typeError(t('forms:validation:dateFormat'))
    .nullable()
    .min(yup.ref('startDate'), t('forms:validation:endDateBeforeStartBillableDate'))
    .default(null),
  weeklyHours: yup.number().min(0).max(40).required(t(requiredField)).default(40),
});

export const buildValidationSchema = () => {
  const { t } = useTranslation();
  return yup.object(buildValidationFields(t));
};

export const initialValues = (hoursPerWeek: IUserHoursPerWeek) => {
  const { weeklyHours, startDate, endDate } = hoursPerWeek;
  return {
    weeklyHours,
    startDate: new Date(startDate),
    endDate: endDate ? new Date(endDate) : null,
  };
};

export const formatAttributesToSubmit = (
  hoursPerWeekId: number,
  userId: number,
  formData: IUpdateUserHoursPerWeekFormData,
): IUpdateUserHoursPerWeekFormDataToSubmit => {
  const { startDate, endDate, ...values } = formData;
  const normalizeStartDate = convertDateToUTCString(startDate);
  const normalizeEndDate = _.isNil(endDate) ? null : convertDateToUTCString(endDate);

  return {
    hoursPerWeekId,
    userId,
    formData: {
      hoursPerWeek: {
        startDate: normalizeStartDate,
        endDate: normalizeEndDate,
        ...values,
      },
    },
  };
};
