import { FC } from 'react';

import { TableHead, TableRow, TableCell, TableBody, Table } from '@mui/material';
import { useTable } from 'react-table';

import Div from 'components/common/Div';

import styles from './styles';
import { IUserProfileDetailedInfoTableProps } from './types';

const UserProfileDetailedInfoTable: FC<IUserProfileDetailedInfoTableProps> = props => {
  const { data, columns } = props;
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  });

  return (
    <Div sx={styles.root}>
      <Table sx={styles.table} {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => {
            return (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (
                    <TableCell {...column.getHeaderProps()} sx={styles.headerCell}>
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);

            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()} sx={styles.cell}>
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Div>
  );
};

export default UserProfileDetailedInfoTable;
