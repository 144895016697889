import { TagTypes, provideTags, getListTag, invalidateTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { AccessibleType } from './enums';
import { ICreateAccessLevelFormDataToSubmit } from './schemas/create';
import { IUpdateAccessLevelFormDataToSubmit } from './schemas/update';
import { IAccessLevel } from './types';

interface IAccessLevelsResponse {
  accessLevels: Array<IAccessLevel>;
  meta: IMeta;
}

interface IAccessLevelResponse {
  accessLevel: IAccessLevel;
}

interface IGetAccessLevelsQueryParameters {
  userIdEq?: number;
  accessibleTypeEq?: AccessibleType;
}

interface IGetAccessLevelsPlainParameters extends IGetAccessLevelsQueryParameters {
  page?: number;
  perPage?: number;
}

interface IGetAccessLevelsParameters {
  page?: number;
  perPage?: number;
  q?: IGetAccessLevelsQueryParameters;
}

export const loadingGetAccessLevelsParameters = (
  parameters: IGetAccessLevelsPlainParameters,
): IGetAccessLevelsParameters => {
  const { page, perPage, ...queryParameters } = parameters;
  return {
    perPage,
    page,
    q: { ...queryParameters },
  };
};

export const accessLevelApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getAccessLevels: builder.query<IAccessLevelsResponse, IGetAccessLevelsParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1AccessLevelsPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.AccessLevel, response?.accessLevels),
    }),
    getAccessLevel: builder.query<IAccessLevelResponse, number>({
      query: id => {
        return {
          url: apiRoutes.apiV1AccessLevelPath(id),
          method: 'GET',
        };
      },
      providesTags: response => provideTags(TagTypes.AccessLevel, response?.accessLevel ? [response?.accessLevel] : []),
    }),
    createAccessLevel: builder.mutation<void, ICreateAccessLevelFormDataToSubmit>({
      query: formData => {
        return {
          url: apiRoutes.apiV1AccessLevelsPath(),
          method: 'POST',
          data: formData,
        };
      },
      invalidatesTags: invalidateTags(getListTag(TagTypes.AccessLevel), getListTag(TagTypes.UserProfileAccessLevel)),
    }),
    updateAccessLevel: builder.mutation<IAccessLevelResponse, IUpdateAccessLevelFormDataToSubmit>({
      query: ({ accessLevelId, formData }) => {
        return {
          url: apiRoutes.apiV1AccessLevelPath(accessLevelId),
          method: 'PUT',
          data: formData,
        };
      },
      invalidatesTags: invalidateTags(getListTag(TagTypes.AccessLevel), getListTag(TagTypes.UserProfileAccessLevel)),
    }),
    deleteAccessLevel: builder.mutation<void, number>({
      query: id => {
        return {
          url: apiRoutes.apiV1AccessLevelPath(id),
          method: 'DELETE',
        };
      },
      invalidatesTags: invalidateTags(getListTag(TagTypes.AccessLevel), getListTag(TagTypes.UserProfileAccessLevel)),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAccessLevelsQuery,
  useGetAccessLevelQuery,
  useCreateAccessLevelMutation,
  useUpdateAccessLevelMutation,
  useDeleteAccessLevelMutation,
} = accessLevelApi;
