import { FC } from 'react';

import { Typography } from '@mui/material';

import styles from './styles';
import { IUsersTableHeaderProps } from './types';

const TableHeader: FC<IUsersTableHeaderProps> = props => {
  const { title } = props;

  return (
    <Typography sx={styles.title} variant="subtitle2">
      {title}
    </Typography>
  );
};

export default TableHeader;
