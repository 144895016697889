import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: 'text.lightGray',
  },
  contentName: {
    color: 'primary.main',
    cursor: 'pointer',
  },
  notClickable: {
    color: 'primary.main',
    cursor: 'default',
  },
};

export default styles;
