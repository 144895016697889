import { FC, SyntheticEvent, useEffect, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { SubmitHandler, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/Icon';
import { INTERVAL_COMPARISONS } from 'components/common/SmartFilter/relations';
import { IMultipleSlot } from 'components/common/SmartFilter/types';

import { FilterPredicate } from 'enums/FilterPredicate';

import { buildErrorMessage } from 'utils/form';

import { initialValues, buildValidationSchema, IAllocationFormData } from './schema';
import styles from './styles';
import { IAllocationProps } from './types';

const onSubmit: SubmitHandler<IAllocationFormData> = () => {};

const Allocation: FC<IAllocationProps> = props => {
  const { slot, onSlotPatch } = props;

  const initialAllocation = _.isNil(slot.value) ? null : ((slot.value as IMultipleSlot).name as string);

  const { t } = useTranslation('smartFilter');

  const allocationInputElement = useRef(null);

  useEffect(() => {
    allocationInputElement.current.select();
  }, []);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<IAllocationFormData>({
    mode: 'onChange',
    defaultValues: initialValues({ allocation: initialAllocation, comparison: slot.comparison }),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: comparison, onChange: onComparisonChange },
  } = useController({ name: 'comparison', control });

  const {
    field: { value: allocation, onChange: onAllocationChange },
  } = useController({ name: 'allocation', control });

  useEffect(() => {
    handleSlotPatch();
  }, [comparison, allocation]);

  const handleComparisonChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    const comparison = INTERVAL_COMPARISONS.find(comparison => comparison.textSymbol === value);

    onComparisonChange(comparison);
  };

  const handleSlotPatch = () => {
    const isAllocationValueError = Boolean(errors.allocation);
    const isAllocationComparisonValueError = Boolean(errors.comparison);

    const allocation = getValues('allocation');

    const initialComparison = INTERVAL_COMPARISONS.find(comparison => comparison.predicate === FilterPredicate.eq);

    const allocationValue = isAllocationValueError ? null : allocation;
    const comparisonValue = isAllocationComparisonValueError ? initialComparison : comparison;

    const currentAllocation =
      _.isNil(allocationValue) || allocationValue === '' ? null : { id: null, name: String(allocationValue) };
    onSlotPatch(slot.id, { value: currentAllocation, comparison: comparisonValue });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="smart-filter-allocation-form" onBlur={handleSlotPatch}>
      <TextField
        fullWidth
        inputRef={allocationInputElement}
        autoFocus
        placeholder={`${t('allocation.placeholder')}...`}
        value={allocation ?? ''}
        onChange={onAllocationChange}
        onFocus={event => {
          event.target.select();
        }}
        error={Boolean(errors.allocation)}
        helperText={buildErrorMessage(t, errors.allocation)}
        label={t('allocation.label')}
        InputProps={{
          endAdornment: !_.isEmpty(getValues('allocation')) && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setValue('allocation', null);
                  clearErrors('allocation');
                }}
              >
                <Icon name="closeWithDarkCircle" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <FormControl>
        <RadioGroup
          aria-labelledby="allocation-comparison-radio-buttons-group"
          name="allocation-comparison"
          value={comparison.textSymbol}
          onChange={handleComparisonChange}
        >
          {INTERVAL_COMPARISONS.map(comparison => {
            const label = (
              <Typography
                sx={styles.label}
                variant="subtitle1"
              >{`${comparison.textSymbol} (${comparison.description})`}</Typography>
            );

            return (
              <FormControlLabel
                sx={styles.checkboxInput}
                key={comparison.predicate}
                value={comparison.textSymbol}
                control={<Radio size="small" />}
                label={label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </form>
  );
};

export default Allocation;
