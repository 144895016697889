import { SxStyles } from 'theme';

const styles: SxStyles = {
  popover: {
    padding: 0.625,
    width: '280px',
  },
  buttonContainer: {
    marginTop: 1.25,
  },
  closeButton: {
    width: '100%',
    paddingY: 1.5,
    paddingX: 2,
    backgroundColor: 'white',
  },
};

export default styles;
