import _ from 'lodash';

import { DayType } from 'domain/holiday/calendar/day/enums';

import { ICalendarDay } from './types';

export const isWorkingDay = (day: ICalendarDay | null) => {
  if (_.isNil(day)) {
    return false;
  }

  return day.dayType === DayType.working;
};

export const isWeekendDay = (day: ICalendarDay | null) => {
  if (_.isNil(day)) {
    return false;
  }

  return day.dayType === DayType.weekend;
};

export const isHolidayDay = (day: ICalendarDay | null) => {
  if (_.isNil(day)) {
    return false;
  }

  return day.dayType === DayType.holiday;
};
