import * as yup from 'yup';

import i18n from 'locales/i18n';

export interface INewPasswordFormData {
  password: string;
  passwordConfirmation: string;
}

export const buildValidationFields = () => ({
  password: yup
    .string()
    .required(i18n.t('forms:validation.required'))
    .min(8, i18n.t('forms:validation.minimumSymbols'))
    .default(''),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), undefined], i18n.t('forms:validation.password')),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());
