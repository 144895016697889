import { Chip } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { getHoursAndMinutesStringFromMinutes } from 'utils/dateTime';

import FavoriteTooltipContent from './components/FavoriteTooltipContent';
import styles from './styles';

const FavoriteItem = ({ favorite, handleClick, handleRemoveClick }) => {
  const { description, projectName, tagNames, id } = favorite;
  const duration = favorite.duration !== 0 && getHoursAndMinutesStringFromMinutes(favorite.duration);
  const label = description || projectName || duration || tagNames;

  return (
    <Div key={id}>
      <Tooltip
        title={<FavoriteTooltipContent favorite={favorite} />}
        slotProps={{
          tooltip: {
            sx: styles.tooltip,
          },
        }}
      >
        <Chip
          sx={styles.button}
          label={label}
          onClick={event => handleClick(event, favorite)}
          onDelete={event => handleRemoveClick(event, id)}
          deleteIcon={<Icon sx={styles.closeChipIcon} name="closeWithoutCircle" />}
          variant="outlined"
        />
      </Tooltip>
    </Div>
  );
};

export default FavoriteItem;
