import _ from 'lodash';

import i18n from 'locales/i18n';

import { IBackendError } from 'types/error';

export const generateBackendErrorMessages = (error?: IBackendError) => {
  if (_.isNil(error) || _.isNil(error.data) || _.isNil(error.data.errors)) {
    return [i18n.t('defaultErrorNotificationText')];
  }
  return Object.entries(error.data.errors).map(([field, messages]) => `${field}: ${messages.join(',')}`);
};
