import { Fragment } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useSnackbar, SnackbarOrigin } from 'notistack';
import { useTranslation } from 'react-i18next';

const ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

export const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const action = (snackbarId: number) => (
    <Fragment>
      <IconButton
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <CloseIcon style={{ color: 'white' }} />
      </IconButton>
    </Fragment>
  );

  const showSuccessNotification = (message?: string) => {
    enqueueSnackbar(message ?? t('defaultSuccessNotificationText'), {
      variant: 'success',
      anchorOrigin: ANCHOR_ORIGIN,
      autoHideDuration: 6000,
      disableWindowBlurListener: true,
      action: action,
    });
  };
  const showErrorNotification = (message?: string) => {
    enqueueSnackbar(message ?? t('defaultErrorNotificationText'), {
      variant: 'error',
      anchorOrigin: ANCHOR_ORIGIN,
      autoHideDuration: 6000,
      disableWindowBlurListener: true,
      action: action,
    });
  };

  return {
    showSuccessNotification,
    showErrorNotification,
  };
};
