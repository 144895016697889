import { FC } from 'react';

import { Typography } from '@mui/material';

import { IProjectBudgetMonthsTableCellProps } from './types';

const TableCell: FC<IProjectBudgetMonthsTableCellProps> = props => {
  const { value } = props;
  return <Typography variant="subtitle1">{value}</Typography>;
};

export default TableCell;
