export enum ReportVacationsCalendarTableColumnAccessor {
  resourcesEmpty = 'resourcesEmpty',
  resourcesEmptyDepth = 'resourcesEmptyDepth',
  resources = 'resources',
  carryoverPrevious = 'carryoverPrevious',
  carryoverPreviousEmpty = 'carryoverPreviousEmpty',
  carryoverPreviousEmptyDepth = 'carryoverPreviousEmptyDepth',
  available = 'available',
  availableEmpty = 'availableEmpty',
  availableEmptyDepth = 'availableEmptyDepth',
  scheduled = 'scheduled',
  scheduledEmpty = 'scheduledEmpty',
  scheduledEmptyDepth = 'scheduledEmptyDepth',
  carryoverNext = 'carryoverNext',
  carryoverNextEmpty = 'carryoverNextEmpty',
  carryoverNextEmptyDepth = 'carryoverNextEmptyDepth',
}
