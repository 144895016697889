import { FC } from 'react';

import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Loader from 'components/common/Loader';

import {
  useGetUserProfileAccessLevelsQuery,
  loadingGetUserProfileAccessLevelsParameters,
} from 'domain/user/profile/accessLevel/apiSlice';

import UserProfileAccessLevels from './components/UserProfileAccessLevels';
import { IAccessLevelProps } from './types';

const AccessLevel: FC<IAccessLevelProps> = props => {
  const { user } = props;

  const { t } = useTranslation(['common']);

  const userProfileAccessLevelsParameters = loadingGetUserProfileAccessLevelsParameters({ userId: user.id });
  const {
    data: userProfileAccessLevelsData,
    isLoading: isUserProfileAccessLevelsDataLoading,
    isSuccess: isUserProfileAccessLevelsDataLoadingSuccess,
    isError: isUserProfileAccessLevelsDataLoadingError,
  } = useGetUserProfileAccessLevelsQuery(userProfileAccessLevelsParameters);

  if (isUserProfileAccessLevelsDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  if (isUserProfileAccessLevelsDataLoading) {
    <Loader />;
  }

  return (
    isUserProfileAccessLevelsDataLoadingSuccess && (
      <UserProfileAccessLevels userProfileAccessLevels={userProfileAccessLevelsData.accessLevels} user={user} />
    )
  );
};

export default AccessLevel;
