import { IPlainTree, INormalizedTree, IPlainTreeDictionary } from './types';

const normalizeData = (data: Array<IPlainTree>): Array<INormalizedTree> => {
  const idDictionary: IPlainTreeDictionary = data.reduce((accumulator, element) => {
    if (element.parentTechnologyId === null) {
      return { ...accumulator, root: [...(accumulator['root'] || []), { ...element, children: [] }] };
    }

    accumulator[element.parentTechnologyId] = [
      ...(accumulator[element.parentTechnologyId] || []),
      { ...element, children: [] },
    ];

    return accumulator;
  }, {});

  const inner = (dictionary: IPlainTreeDictionary, keyName: 'root' | number) => {
    if (!dictionary.hasOwnProperty(keyName)) {
      return [];
    }

    return dictionary[keyName].map(item => {
      return { ...item, children: inner(dictionary, item.technologyId) };
    });
  };

  return inner(idDictionary, 'root');
};

export { normalizeData };
