import { Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import styles from './styles';

const Legend = () => {
  const { t } = useTranslation('userProfile');

  return (
    <Div sx={styles.legend}>
      <Typography sx={styles.legendItem} variant="heading3" component="div">
        {t('vacation.approved')}
        <Div sx={MUISx(styles.approved, styles.colorItem)} />
      </Typography>
      <Typography sx={styles.legendItem} variant="heading3" component="div">
        {t('vacation.onApproval')}
        <Div sx={MUISx(styles.onApproval, styles.colorItem)} />
      </Typography>
      <Typography sx={styles.legendItem} variant="heading3" component="div">
        {t('vacation.declined')}
        <Div sx={MUISx(styles.declined, styles.colorItem)} />
      </Typography>
    </Div>
  );
};

export default Legend;
