import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    marginBottom: 1.25,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingY: 1,
    paddingX: 2,
    marginBottom: 2.5,
  },
  starIcon: {
    marginRight: 1,
    color: 'primary.blue',
    width: '18px',
    height: '18px',
  },
  description: {
    maxWidth: '400px',
    minWidth: '250px',
    marginLeft: 2,
  },
  buttons: {
    display: 'flex',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 1,
  },
  project: {
    marginLeft: 1.25,
    '& .MuiTypography-root': {
      maxWidth: '100px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  tagsButton: {
    marginLeft: 1.25,
    display: 'inline-block',
    borderColor: 'border.whiteBlue',
    backgroundColor: 'border.whiteBlue',
    borderRadius: '6px',
    border: 'none',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  duration: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 1.25,
    marginTop: 0.625,
  },
};

export default styles;
