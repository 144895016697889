import { FC } from 'react';

import { List } from '@mui/material';

import ReportTrackedTimeEntryItem from 'components/common/TrackedTimeEntry';

import { sortByDate } from 'domain/report/trackedTimeEntry/service';

import styles from './styles';
import { IReportTrackedTimeEntriesNotGroupListProps } from './types';

const ReportTrackedTimeEntriesNotGroupList: FC<IReportTrackedTimeEntriesNotGroupListProps> = props => {
  const { trackedTimeEntries } = props;

  const sortedByDateTrackedTimeEntries = sortByDate(trackedTimeEntries);

  return (
    <List sx={styles.root}>
      {sortedByDateTrackedTimeEntries.map(trackedTimeEntry => (
        <ReportTrackedTimeEntryItem key={trackedTimeEntry.id} trackedTimeEntry={trackedTimeEntry} />
      ))}
    </List>
  );
};

export default ReportTrackedTimeEntriesNotGroupList;
