import { FC } from 'react';

import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { ListItem, Button, Typography, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ITag } from 'domain/tag/types';

import styles from './styles';
import { ITagsAutocompleteOptionsProps } from './types';

const tagsAutocompleteOptions: FC<ITagsAutocompleteOptionsProps> = props => {
  const { onCurrentTagsChange, currentValue, selectedTags, notSelectedTags } = props;
  const { t } = useTranslation('timeTracker');

  const renderOption = (option: ITag) => {
    const { id, name } = option;
    const isSelected = currentValue.some(tag => tag.id === id);
    const handleOptionClick = () =>
      isSelected
        ? onCurrentTagsChange(currentValue.filter(tag => tag.id !== id))
        : onCurrentTagsChange([...currentValue, option]);

    return (
      <ListItem key={id}>
        <Button type="button" variant="text" sx={styles.optionButton} onClick={handleOptionClick} fullWidth>
          <Typography sx={styles.optionText} variant="subtitle1">
            {name}
          </Typography>
          <Div sx={styles.checkbox}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              checked={isSelected}
            />
          </Div>
        </Button>
      </ListItem>
    );
  };

  return (
    <Div sx={styles.optionsBox}>
      <Typography variant="heading4" sx={styles.tagsTitle}>
        {t('createTagsButton.autocomplete.tagsTitle')}
      </Typography>
      {selectedTags.map((tag: ITag) => renderOption(tag))}
      {notSelectedTags.map((tag: ITag) => renderOption(tag))}
    </Div>
  );
};

export default tagsAutocompleteOptions;
