import { SxStyles } from 'theme';

const styles: SxStyles = {
  arrowIconBlock: {
    width: '16px',
    height: '16px',
    position: 'absolute',
    right: 1.25,
    top: 1.25,
  },
  arrowIcon: {
    width: '16px',
    height: '16px',
    color: 'button.gray',
  },
  budgetTypeItem: {
    '&:first-of-type': {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'border.main',
    },
  },
  budgetTypeItemText: {
    color: 'text.primary',
  },
};

export default styles;
