import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    marginY: 4,
    marginX: 3,
  },
  header: {
    display: 'block',
    marginBottom: 2,
  },
  table: {
    marginY: 3,
    marginX: 'auto',
  },
};

export default styles;
