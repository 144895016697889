import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { ITechnology } from 'domain/technology/types';

export interface ICreateTechnologyFormData {
  name: string;
  technology: ITechnology;
}

export interface ICreateTechnologyFormDataToSubmit {
  parentId: number | null;
  name: string;
}

export const buildValidationFields = () => ({
  name: yup
    .string()
    .required(i18n.t('forms:validation:required'))
    .max(30, i18n.t('forms:validation:maxSymbols', { count: 30 }))
    .default(''),
  technology: yup.mixed<ITechnology>().nullable().default(null),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (technology: ITechnology) => {
  if (_.isNil(technology)) {
    return buildValidationSchema().getDefault();
  }
  return {
    name: '',
    technology,
  };
};

export const formatAttributesToSubmit = (values: ICreateTechnologyFormData): ICreateTechnologyFormDataToSubmit => {
  const { name, technology } = values;

  const parentId = _.isNil(technology) ? null : technology.id;

  return {
    name,
    parentId,
  };
};
