import { FC, useState } from 'react';

import { Alert } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Loader from 'components/common/Loader';
import MultipleAutocomplete from 'components/common/SmartFilter/components/MultipleAutocomplete';
import { IMultipleSlot } from 'components/common/SmartFilter/types';

import { useGetTrackedTimeEntriesTasksQuery } from 'domain/project/task/apiSlice';
import { ITask } from 'domain/project/task/types';

import { SuggestionAutocompleteType } from 'enums/SuggestionAutocomplete';

import { IAutocompleteOption } from 'types/autocomplete';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { getSortParameter } from 'utils/runsack';
import { getPreparedDataForSuggestionAutocomplete } from 'utils/suggestionAutocomplete';

import { ITasksProps } from './types';

const Tasks: FC<ITasksProps> = props => {
  const { slot, onSlotPatch, setOpenPopover } = props;

  const { t } = useTranslation(['common', 'smartFilter']);

  const value = slot.value as Array<IMultipleSlot>;

  const [taskName, setTaskName] = useState<string>('');

  const [debouncedTaskName] = useDebounce(taskName, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: tasksData,
    isFetching: isTasksDataFetching,
    isLoading: isTasksDataLoading,
    isError: isTasksDataLoadingError,
  } = useGetTrackedTimeEntriesTasksQuery({
    nameCont: debouncedTaskName,
    paginated: false,
    sort: getSortParameter('name', 'asc'),
  });

  if (isTasksDataLoading) {
    return <Loader />;
  }

  if (isTasksDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCurrentTasksChange = (tasks: Array<IAutocompleteOption>, filterWithEnter = false) => {
    if (_.isEmpty(tasks)) {
      onSlotPatch(
        slot.id,
        {
          value: [],
        },
        filterWithEnter,
      );
      return;
    }

    const value = tasks.map(task => ({ id: task.id, name: task.name }));

    onSlotPatch(
      slot.id,
      {
        value,
      },
      filterWithEnter,
    );
  };

  const options = getPreparedDataForSuggestionAutocomplete<ITask>({
    data: tasksData.tasks,
    type: SuggestionAutocompleteType.task,
  });
  const currentOptions = options.filter(option => value.some(slot => slot.id === option.id));

  return (
    <MultipleAutocomplete
      options={options}
      currentOptions={currentOptions}
      isFetching={isTasksDataFetching}
      onCurrentOptionsChange={handleCurrentTasksChange}
      setName={setTaskName}
      name={taskName}
      label={t('smartFilter:tasks.label')}
      setOpenPopover={setOpenPopover}
    />
  );
};

export default Tasks;
