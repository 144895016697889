export default {
  project: 'Project',
  or: 'or',
  logInWithGoogle: 'Log in with Google',
  defaultSuccessNotificationText: 'Success',
  defaultErrorNotificationText: 'Something went wrong',
  user: 'User',
  admin: 'Admin',
  superAdmin: 'Super Admin',
  executive: 'Executive',
  employee: 'Employee',
  manager: 'Manager',
  developer: 'Developer',
  hr: 'HR',
  practiceLead: 'Practice Lead',
  teamLead: 'Team Lead',
  projectManager: 'Project Manager',
  productDirector: 'Product Director',
  group: 'Group',
  assign: 'Assign',
  notAssigned: 'Not Assigned',
  leaveVacant: 'Leave vacant',
  searchUser: 'Search user',
  active: 'Active',
  archived: 'Archived',
  today: 'Today',
  yesterday: 'Yesterday',
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  noData: 'No Data',
  noAccessToData: 'You do not have access to data.',
  weekDay: {
    sunday: 'Su',
    monday: 'Mo',
    tuesday: 'Tu',
    wednesday: 'We',
    thursday: 'Th',
    friday: 'Fr',
    saturday: 'Sa',
  },
};
