import { FC } from 'react';

import Div from 'components/common/Div';

import styles from './styles';

const Divider: FC = () => {
  return <Div sx={styles.root} />;
};

export default Divider;
