import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IProject } from 'domain/project/types';
import { IResource } from 'domain/resource/types';
import { ITechnology } from 'domain/technology/types';

import { convertDateToUTCString } from 'utils/dateTime';

const requiredValidation = 'forms:validation:required';

const dateFormatValidation = 'forms:validation:dateFormat';

export interface ICreateAllocationFormData {
  resource: IResource;
  project: IProject;
  workload: number;
  technologies: Array<ITechnology>;
  startDate: Date;
  endDate?: Date | null;
}

export interface ICreateAllocationPreparedFormData
  extends Omit<ICreateAllocationFormData, 'resource' | 'technologies' | 'startDate' | 'endDate' | 'project'> {
  technologyIds: Array<number>;
  startDate: string;
  endDate: string | null;
  projectId: number;
}

export interface ICreateAllocationFormDataToSubmit {
  resourceId: number;
  formData: ICreateAllocationPreparedFormData;
}

export const buildValidationFields = () => ({
  project: yup.mixed<IProject>().required(i18n.t(requiredValidation)).nullable().default(null),
  resource: yup.mixed<IResource>().required(i18n.t(requiredValidation)).nullable().default(null),
  workload: yup.number().min(0).max(100).required(i18n.t(requiredValidation)).nullable().default(null),
  startDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .required(i18n.t(requiredValidation))
    .nullable()
    .default(new Date()),
  endDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .min(yup.ref('startDate'), i18n.t('forms:validation:startDateBeforeEndDate'))
    .nullable()
    .default(null),
  technologies: yup.array().of(yup.mixed<ITechnology>()).default([]),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (formData: ICreateAllocationFormData): ICreateAllocationFormDataToSubmit => {
  const { technologies, resource, startDate, project, endDate, ...values } = formData;

  const technologyIds = technologies.map(({ id }) => id);
  const normalizeStartDate = convertDateToUTCString(startDate);
  const normalizeEndDate = _.isNil(endDate) ? null : convertDateToUTCString(endDate);

  return {
    resourceId: resource.id,
    formData: {
      technologyIds,
      projectId: project.id,
      startDate: normalizeStartDate,
      endDate: normalizeEndDate,
      ...values,
    },
  };
};
