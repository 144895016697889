import { FC, useContext, useMemo } from 'react';

import EditIcon from '@mui/icons-material/EditOutlined';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import UserProfileDetailedInfoTable from 'components/common/UserProfileDetailedInfoTable/index';

import { ModalContext } from 'contexts/ModalContext';

import { useDeleteUserHoursPerWeekMutation } from 'domain/user/hoursPerWeek/apiSlice';

import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useNotifications } from 'hooks/useNotifications';

import { formatDateToString, formatToDateWithFixedTimeZone } from 'utils/dateTime';
import { generateBackendErrorMessages } from 'utils/error';

import CreateUserHoursPerWeekForm from './CreateUserHoursPerWeekForm';
import styles from './styles';
import { IUserScheduleProps } from './types';
import UpdateUserHoursPerWeekForm from './UpdateUserProfileHoursPerWeekForm';

const UserHoursPerWeek: FC<IUserScheduleProps> = props => {
  const { userHoursPerWeeks, user } = props;

  const { t } = useTranslation('userProfile');
  const { openModal } = useContext(ModalContext);
  const { getConfirmation } = useConfirmDialog();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [deleteHoursPerWeek, { isLoading: isDeleteHoursPerWeekLoading }] = useDeleteUserHoursPerWeekMutation();

  const handleDeleteHoursPerWeekClick = async hoursPerWeek => {
    if (
      await getConfirmation({
        title: '',
        message: t('schedule.deleteHoursPerWeekConfirmationDialog.message'),
      })
    ) {
      try {
        await deleteHoursPerWeek({ id: hoursPerWeek.id, userId: user.id }).unwrap();
        showSuccessNotification();
      } catch (error) {
        const errors = generateBackendErrorMessages(error);
        for (const message of errors) {
          showErrorNotification(message);
        }
      }
    }
  };
  const handleAddHoursPerWeekClick = () => {
    openModal({
      title: t('schedule.createHoursPerWeekForm.title'),
      content: <CreateUserHoursPerWeekForm user={user} />,
    });
  };
  const handleUpdateHoursPerWeekClick = hoursPerWeek => {
    openModal({
      title: t('schedule.updateHoursPerWeekForm.title'),
      content: <UpdateUserHoursPerWeekForm hoursPerWeek={hoursPerWeek} user={user} />,
    });
  };

  const { canUpdateSchedule, canCreateSchedule } = user.permissions;
  const prepareHoursPerWeekData = hoursPerWeek => {
    const { weeklyHours, startDate, endDate } = hoursPerWeek;
    const startDateString = formatDateToString(formatToDateWithFixedTimeZone(startDate));
    const endDateString = formatDateToString(formatToDateWithFixedTimeZone(endDate));
    const iconButtonStyles = MUISx(styles.iconButton, {
      condition: !canUpdateSchedule,
      sx: styles.disabledIconButton,
    });

    return {
      weeklyHours: <Typography variant="subtitle1">{`${weeklyHours}`}</Typography>,
      startDate: <Typography variant="subtitle1">{startDateString}</Typography>,
      endDate: <Typography variant="subtitle1">{endDate ? endDateString : t('schedule.notDefined')}</Typography>,
      actions: (
        <Div sx={styles.actions}>
          <Tooltip
            title={t('userProfile:accessLevel.userProfileAccessLevel.notAvailable')}
            disableHoverListener={canUpdateSchedule}
          >
            <span>
              <Button
                sx={iconButtonStyles}
                onClick={() => handleUpdateHoursPerWeekClick(hoursPerWeek)}
                disabled={!canUpdateSchedule}
              >
                <EditIcon sx={styles.editIcon} />
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={t('userProfile:accessLevel.userProfileAccessLevel.notAvailable')}
            disableHoverListener={canUpdateSchedule}
          >
            <span>
              <Button
                onClick={() => handleDeleteHoursPerWeekClick(hoursPerWeek)}
                disabled={!canUpdateSchedule}
                sx={styles.destroyButton}
              >
                <Icon name="trash" sx={canUpdateSchedule ? styles.destroyButton : styles.disabledIconButton} />
              </Button>
            </span>
          </Tooltip>
        </Div>
      ),
    };
  };

  const HOURS_PER_WEEK_COLUMNS = useMemo(() => {
    return [
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('schedule.weeklyHours')}
          </Typography>
        ),
        accessor: 'weeklyHours',
      },
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('schedule.startDate')}
          </Typography>
        ),
        accessor: 'startDate',
      },
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('schedule.endDate')}
          </Typography>
        ),
        accessor: 'endDate',
      },
      {
        Header: (
          <Typography sx={styles.headerColumn} variant="subtitle2">
            {t('schedule.actions')}
          </Typography>
        ),
        accessor: 'actions',
      },
    ];
  }, []);

  const hoursPerWeekData = useMemo(() => {
    return userHoursPerWeeks?.map(hoursPerWeek => prepareHoursPerWeekData(hoursPerWeek));
  }, [userHoursPerWeeks]);

  return (
    <Div sx={styles.container}>
      <Typography variant="heading3" sx={styles.header}>
        <Div sx={styles.titleLine}>
          <Div sx={styles.title}>{t('schedule.hoursPerWeek')}</Div>
          {canCreateSchedule && (
            <IconButton
              onClick={handleAddHoursPerWeekClick}
              sx={styles.actionButtonIcon}
              disabled={isDeleteHoursPerWeekLoading}
            >
              <Icon sx={styles.submitButton} name="plus" />
            </IconButton>
          )}
        </Div>
      </Typography>
      <UserProfileDetailedInfoTable data={hoursPerWeekData} columns={HOURS_PER_WEEK_COLUMNS} />
    </Div>
  );
};

export default UserHoursPerWeek;
