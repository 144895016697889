import { FC } from 'react';

import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { getTitle } from './service';
import styles from './styles';
import { IMonthPickerPlaceholderProps } from './types';

const MonthPickerPlaceholder: FC<IMonthPickerPlaceholderProps> = props => {
  const { startYear, endYear, startMonthNumber, endMonthNumber } = props;

  const title = getTitle(startMonthNumber, endMonthNumber, startYear, endYear);

  return (
    <Div sx={styles.root}>
      <KeyboardArrowLeftOutlined sx={styles.leftArrowIcon} />
      <Div sx={styles.iconBlock}>
        <Icon name="calendar" />
      </Div>
      <Typography sx={styles.text} variant="body1">
        {title}
      </Typography>
      <KeyboardArrowRightOutlined sx={styles.rightArrowIcon} />
    </Div>
  );
};

export default MonthPickerPlaceholder;
