import * as yup from 'yup';

import i18n from 'locales/i18n';

import { isDurationValid, isDurationValidNumbers } from 'utils/durationFormat';

export interface ITimeTrackerTrackableDurationFormData {
  durationValue?: string;
}

export const buildValidationFields = () => ({
  durationValue: yup
    .string()
    .test('onlyDigits', i18n.t('forms:mustBeOnlyDigits'), value => /^\d*(:\d*)?$/.test(value))
    .test('durationValue-test-to-number', i18n.t('forms:mustBeNumber'), isDurationValidNumbers)
    .test('durationValue-test-main', i18n.t('forms:betweenNumber'), isDurationValid)
    .default(''),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());
