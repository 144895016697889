import { useEffect, useRef, useState } from 'react';

import { TableContainer, Table, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import { FIRST_ITEM_INDEX } from 'utils/array';
import { ONE_MONTH } from 'utils/dateTime';

import Body from './components/Body';
import Header from './components/Header';
import { buildStickyCellsPositions } from './service';
import styles from './styles';
import { IStickyCellPosition, IVacationTableProps } from './types';

const INITIAL_DAY_COUNT = 0;
const MAX_DAYS_COUNT = 31;
const LAST_ITEM_INDEX = -1;

const VacationTable = (props: IVacationTableProps) => {
  const { isFetching, resources, dateRangesByMonth } = props;

  const { t } = useTranslation('table');

  const cellReferences = useRef<HTMLTableCellElement[]>([]);
  const [stickyCellsPositions, setStickyCellsPositions] = useState<IStickyCellPosition[]>([]);

  const allDateCount = dateRangesByMonth.reduce((count, daysInMonth) => count + daysInMonth.length, INITIAL_DAY_COUNT);
  const bodyStickyCellsPositions = stickyCellsPositions.slice(FIRST_ITEM_INDEX, LAST_ITEM_INDEX);

  const isPositionsLoading = isEmpty(stickyCellsPositions);
  const isWideRange = dateRangesByMonth.length > ONE_MONTH && allDateCount > MAX_DAYS_COUNT;
  const isDataEmpty = isEmpty(resources) && !isFetching;

  useEffect(() => {
    if (cellReferences.current) {
      const initialStickyPositions = buildStickyCellsPositions(cellReferences.current);

      setStickyCellsPositions(initialStickyPositions);
    }
  }, []);

  return (
    <TableContainer
      sx={MUISx(styles.tableContainer, {
        condition: isPositionsLoading,
        sx: styles.noScroll,
      })}
    >
      <Table
        stickyHeader
        padding="none"
        sx={MUISx(styles.table, {
          condition: isPositionsLoading,
          sx: styles.blur,
        })}
      >
        <Header
          isWideRange={isWideRange}
          cellReferences={cellReferences}
          stickyCellsPositions={stickyCellsPositions}
          dateRangesByMonth={dateRangesByMonth}
        />
        <Body
          isFetching={isFetching}
          isWideRange={isWideRange}
          resources={resources}
          stickyCellsPositions={bodyStickyCellsPositions}
          dateRangesByMonth={dateRangesByMonth}
        />
      </Table>
      {isDataEmpty && (
        <Typography textAlign="center" sx={styles.noDataTitle}>
          {t('noData')}
        </Typography>
      )}
    </TableContainer>
  );
};

export default VacationTable;
