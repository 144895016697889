import * as yup from 'yup';

export interface ICreateResourceVacationFormData {
  startDate: string;
  endDate: string;
}

export interface ICreateResourceVacationFormDataToSubmit {
  resourceId: number;
  formData: {
    vacation: ICreateResourceVacationFormData;
  };
}

export const buildValidationFields = () => ({
  startDate: yup.string().required().default(''),
  endDate: yup.string().required().default(''),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (
  resourceId: number,
  formData: ICreateResourceVacationFormData,
): ICreateResourceVacationFormDataToSubmit => {
  return {
    resourceId,
    formData: {
      vacation: formData,
    },
  };
};
