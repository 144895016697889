import { SxStyles } from 'theme';

const styles: SxStyles = {
  personalInformation: {
    marginBottom: 7,
  },
  input: {
    width: '500px',
  },
  leftSideInput: {
    marginRight: 5,
  },
  placeholder: {
    color: 'text.gray',
  },
  groupFieldsTitle: {
    marginBottom: 3,
  },
  inputsLine: {
    display: 'flex',
    marginBottom: 3,
  },
  userSettings: {
    marginBottom: 5,
  },
  buttons: {
    display: 'flex',
  },
  button: {
    width: '110px',
  },
  addUserButton: {
    marginRight: 1.25,
  },
};

export default styles;
