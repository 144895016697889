import { FC, useEffect } from 'react';

import {
  Alert,
  SelectChangeEvent,
  TableHead,
  Table as MUITable,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';
import { usePagination, useTable } from 'react-table';

import Div from 'components/common/Div';
import Pagination from 'components/common/TablePagination';
import TableSkeleton from 'components/common/TableSkeleton';
import { AllocationTableColumnAccessor } from 'components/pages/Report/Allocation/enums';

import styles from './styles';
import { IUsersTableProps } from './types';

const Table: FC<IUsersTableProps> = props => {
  const {
    data = [],
    columns = [],
    isLoading = false,
    isError = false,
    pagination,
    onPaginationChange,
    hasAccessToData = false,
  } = props;

  const pageCount = pagination?.totalPages;
  const pageSize = pagination?.pageSize;
  const pageIndex = pagination?.pageNumber - 1;

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, gotoPage, setPageSize, page } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    usePagination,
  );

  const { t } = useTranslation('table');

  useEffect(() => {
    if (pagination?.pageNumber - 1 !== pageIndex) {
      gotoPage(0);
    }
  }, [pagination]);

  const isTableDataEmpty = page.length === 0;

  const isDataEmpty = isTableDataEmpty && !isLoading;

  const handleCurrentPageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    onPaginationChange({ pageNumber: page, pageSize });
    gotoPage(page - 1);
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    const pageSize = Number(event.target.value);
    onPaginationChange({ pageNumber: 1, pageSize });
    gotoPage(0);
    setPageSize(pageSize);
  };

  if (isError) {
    return <Alert severity="error">{t('errorText')}</Alert>;
  }

  if (!hasAccessToData) {
    return <Typography textAlign="center">{t('noAccessToData')}</Typography>;
  }

  return (
    <Div sx={styles.root}>
      <MUITable sx={MUISx(styles.table, { condition: isLoading, sx: styles.tableLoading })} {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => {
            return (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => {
                  const { id: columnAccessor, width, minWidth, maxWidth } = column;

                  const isFirstHeaderColumn = columnIndex === 0;
                  const isFteColumn = columnAccessor === AllocationTableColumnAccessor.fte;

                  const isCenteredColumn = isFteColumn;

                  return (
                    <TableCell
                      sx={MUISx(
                        styles.column,
                        { condition: isCenteredColumn, sx: styles.centeredColumn },
                        { condition: isFirstHeaderColumn, sx: styles.firstColumn },
                      )}
                      {...column.getHeaderProps({
                        style: {
                          width: width ?? 'auto',
                          minWidth: minWidth ?? 'auto',
                          maxWidth: maxWidth ?? 'auto',
                        },
                      })}
                    >
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);

            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => {
                  const { id: columnAccessor, width, minWidth, maxWidth } = cell.column;
                  const isFirstBodyColumn = cellIndex === 0;
                  const isFteColumn = columnAccessor === AllocationTableColumnAccessor.fte;

                  const isCenteredColumn = isFteColumn;

                  return (
                    <TableCell
                      sx={MUISx(
                        styles.column,
                        { condition: isCenteredColumn, sx: styles.centeredColumn },
                        { condition: isFirstBodyColumn, sx: styles.firstColumn },
                      )}
                      {...cell.getCellProps({
                        style: {
                          width: width ?? 'auto',
                          minWidth: minWidth ?? 'auto',
                          maxWidth: maxWidth ?? 'auto',
                        },
                      })}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MUITable>

      {isLoading && <TableSkeleton perPage={pageSize} />}
      {isDataEmpty && <Typography textAlign="center">{t('noData')}</Typography>}

      <Pagination
        isDataEmpty={isDataEmpty}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        handleCurrentPageChange={handleCurrentPageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </Div>
  );
};

export default Table;
