import { FC } from 'react';

import { Typography } from '@mui/material';
import MUISx from 'mui-sx';

import i18n from 'locales/i18n';

import Div from 'components/common/Div';

import styles from './styles';

const TABLE_LEGEND_ITEMS = [
  {
    title: i18n.t('reportVacations:info.tableLegend.tableLegendItems.completed'),
    colorSx: styles.completed,
  },
  {
    title: i18n.t('reportVacations:info.tableLegend.tableLegendItems.approved'),
    colorSx: styles.approved,
  },
  {
    title: i18n.t('reportVacations:info.tableLegend.tableLegendItems.requested'),
    colorSx: styles.requested,
  },
];

const TableLegend: FC = () => {
  return (
    <Div sx={styles.tableLegend}>
      {TABLE_LEGEND_ITEMS.map(({ title, colorSx }) => {
        return (
          <Div key={title} sx={styles.legendItem}>
            <Div sx={MUISx(styles.colorItem, colorSx)} />
            <Typography variant="subtitle5">{title}</Typography>
          </Div>
        );
      })}
    </Div>
  );
};

export default TableLegend;
