import { TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { TrackingTypeState } from './enums';
import { ICreateTrackingTypeFormDataToSubmit } from './schemas/create';
import { IUpdateTrackingTypeFormDataToSubmit } from './schemas/update';
import { ITrackingType } from './types';

interface IGetTrackingTypesQueryParameters {
  s?: string;
  nameCont?: string;
  stateEq?: TrackingTypeState;
}

interface IGetTrackingTypesPlainParameters extends IGetTrackingTypesQueryParameters {
  page?: number;
  perPage?: number;
}

interface IGetTrackingTypesParameters {
  q?: IGetTrackingTypesPlainParameters;
  perPage?: number;
  page?: number;
}

interface ITrackingTypeResponse {
  trackingType: ITrackingType;
}

interface IGetTrackingTypesResponse {
  trackingTypes: Array<ITrackingType>;
  meta: IMeta;
}

export const loadingGetTrackingTypesParameters = (
  parameters: IGetTrackingTypesPlainParameters,
): IGetTrackingTypesParameters => {
  const { perPage, page, ...queryParameters } = parameters;
  return {
    perPage,
    page,
    q: { ...queryParameters },
  };
};

export const trackingTypeApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getTrackingTypes: builder.query<IGetTrackingTypesResponse, IGetTrackingTypesParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1TrackingTypesPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.TrackingType, response?.trackingTypes),
    }),
    createTrackingType: builder.mutation<ITrackingTypeResponse, ICreateTrackingTypeFormDataToSubmit>({
      query: ({ formData }) => ({
        url: apiRoutes.apiV1TrackingTypesPath(),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: [TagTypes.TrackingType],
    }),
    updateTrackingType: builder.mutation<ITrackingTypeResponse, IUpdateTrackingTypeFormDataToSubmit>({
      query: ({ id, formData }) => ({
        url: apiRoutes.apiV1TrackingTypePath(id),
        method: 'PUT',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: ({ trackingType }) => [{ type: TagTypes.TrackingType, id: trackingType.id }],
    }),
    deleteTrackingType: builder.mutation<void, number>({
      query: id => ({
        url: apiRoutes.apiV1TrackingTypePath(id),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.TrackingType],
    }),
    activateTrackingType: builder.mutation<ITrackingTypeResponse, number>({
      query: id => ({
        url: apiRoutes.activateApiV1TrackingTypePath(id),
        method: 'PUT',
      }),
      invalidatesTags: ({ trackingType }) => [{ type: TagTypes.TrackingType, id: trackingType.id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTrackingTypesQuery,
  useCreateTrackingTypeMutation,
  useUpdateTrackingTypeMutation,
  useDeleteTrackingTypeMutation,
  useActivateTrackingTypeMutation,
} = trackingTypeApi;
