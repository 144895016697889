import { FC } from 'react';

import { Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import styles from './styles';

const Legend: FC = () => {
  const { t } = useTranslation('reportVacations');

  return (
    <Div sx={styles.legend}>
      <Typography sx={styles.legendItem} variant="subtitle5">
        <Div sx={MUISx(styles.completedColor, styles.colorItem)} />
        {t('vacationRequests.completed')}
      </Typography>
      <Typography sx={styles.legendItem} variant="subtitle5">
        <Div sx={MUISx(styles.approvedColor, styles.colorItem)} />
        {t('vacationRequests.approved')}
      </Typography>
      <Typography sx={styles.legendItem} variant="subtitle5">
        <Div sx={MUISx(styles.requestedColor, styles.colorItem)} />
        {t('vacationRequests.requested')}
      </Typography>
      <Typography sx={styles.legendItem} variant="subtitle5">
        <Div sx={MUISx(styles.currentColor, styles.colorItem)} />
        {t('vacationRequests.today')}
      </Typography>
    </Div>
  );
};

export default Legend;
