import { TagTypes } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

export const exportApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getExport: builder.query<BlobPart, number>({
      query: id => ({
        url: apiRoutes.apiV1ExportPath(id),
        method: 'GET',
      }),
      providesTags: [TagTypes.Export],
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetExportQuery } = exportApi;
