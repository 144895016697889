import { TableCell, Typography } from '@mui/material';
import { format, getDate } from 'date-fns';
import MUISx from 'mui-sx';

import { FIRST_ITEM_INDEX, getLastIndex } from 'utils/array';
import {
  LONG_MONTH_FORMAT,
  LONG_YEAR_FORMAT,
  ONE_DAY,
  SHORT_MONTH_FORMAT,
  SHORT_YEAR_FORMAT,
  TWO_DAYS,
} from 'utils/dateTime';

import styles from './styles';
import { IMonthCellProps } from './types';

const MonthCell = (props: IMonthCellProps) => {
  const { isRightBorderHidden, daysInMonth, onCellReferenceAdd } = props;

  const lastDayIndex = getLastIndex(daysInMonth);
  const hasMoreThanOneDay = daysInMonth.length > ONE_DAY;
  const hasMoreThanTwoDays = daysInMonth.length > TWO_DAYS;

  const monthStartDate = daysInMonth[FIRST_ITEM_INDEX];
  const startDayOfMonth = getDate(monthStartDate);
  const endDayOfMonth = getDate(daysInMonth[lastDayIndex]);
  const monthFormat = hasMoreThanTwoDays ? LONG_MONTH_FORMAT : SHORT_MONTH_FORMAT;
  const yearFormat = hasMoreThanTwoDays ? LONG_YEAR_FORMAT : SHORT_YEAR_FORMAT;
  const monthName = format(monthStartDate, monthFormat);
  const year = format(monthStartDate, yearFormat);

  const monthDateRange = hasMoreThanOneDay ? `${startDayOfMonth} - ${endDayOfMonth}` : startDayOfMonth;
  const monthRange = `${monthDateRange} ${monthName} ${year}`;

  return (
    <TableCell
      colSpan={daysInMonth.length}
      ref={onCellReferenceAdd}
      sx={MUISx(styles.cell, { condition: isRightBorderHidden, sx: styles.hiddenRightBorder })}
    >
      <Typography variant="body2" sx={MUISx(styles.title, { condition: !hasMoreThanOneDay, sx: styles.verticalTitle })}>
        {monthRange}
      </Typography>
    </TableCell>
  );
};

export default MonthCell;
