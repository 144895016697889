import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { Button, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks';

import AllocationActions from 'components/common/AllocationActions';
import Div from 'components/common/Div';
import ExportButton from 'components/common/ExportButton';
import Loader from 'components/common/Loader';
import ReportFiltersButton from 'components/common/ReportFiltersButton';
import SmartFilter from 'components/common/SmartFilter';
import { pickFieldsForSmartFilter } from 'components/common/SmartFilter/service';
import { ISmartFilter } from 'components/common/SmartFilter/types';

import { ModalContext } from 'contexts/ModalContext';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { useGetCurrentUserPermissionsQuery } from 'domain/currentUser/permission/apiSlice';
import { PermissionTargets } from 'domain/currentUser/permission/enums';
import { useLazyGetExportQuery } from 'domain/export/apiSlice';
import {
  IReportAllocationsSmartFilters,
  useCreateCsvAllocationReportMutation,
  useCreateXlsxAllocationReportMutation,
  useGetAllocationsQuery,
} from 'domain/report/allocation/apiSlice';
import { IAllocationItem } from 'domain/report/allocation/types';
import { ReportType } from 'domain/report/filter/enums';
import { IReportFilter } from 'domain/report/filter/types';

import { useNotifications } from 'hooks/useNotifications';
import { usePermissions } from 'hooks/usePermissions';

import { IPaginationParameters } from 'types/api';
import { ISortParameter } from 'types/runsack';

import { dateToString, stringToDate } from 'utils/calendar';
import { formatToDateWithFixedTimeZone, formatToShortString, getTimeRanges } from 'utils/dateTime';
import { generateBackendErrorMessages } from 'utils/error';
import { fileDownload } from 'utils/file';
import { defaultPaginationParameters } from 'utils/pagination';
import { getFullName } from 'utils/person';

import CreateAllocationForm from './components/CreateAllocationForm';
import Filter from './components/Filter';
import Table from './components/Table';
import TableCell from './components/TableCell';
import TableCellSpecialization from './components/TableCellSpecialization';
import TableHeader from './components/TableHeader';
import { AllocationTableColumnAccessor } from './enums';
import styles from './styles';
import { IAllocationFilters } from './types';

const FILTERS: Array<ISmartFilter> = ['project', 'resource', 'technology', 'workload', 'endDate', 'group'];

const REPORT_TYPE = ReportType.allocations;

const Allocation: FC = () => {
  const { t } = useTranslation('allocation');
  const { queryStringUpdate, queryStringClear, camelizedQuery } = useRouter();
  const { data: currentUser } = useGetCurrentUserQuery();

  const timeRanges = getTimeRanges(currentUser?.user?.weekStartsDay ?? 1);

  const { openModal } = useContext(ModalContext);

  const { showErrorNotification } = useNotifications();
  const [filters, setFilters] = useState<IAllocationFilters>({
    startDate: (camelizedQuery.startDate && stringToDate(camelizedQuery.startDate)) || timeRanges.startOfMonth,
    endDate: (camelizedQuery.endDate && stringToDate(camelizedQuery.endDate)) || timeRanges.endOfMonth,
    sort: 'name ASC',
  });

  const [smartFilter, setSmartFilter] = useState<IReportAllocationsSmartFilters>(
    pickFieldsForSmartFilter(camelizedQuery, FILTERS),
  );

  const [paginationParameters, setPaginationParameters] = useState<IPaginationParameters>(defaultPaginationParameters);

  const getResourcesUsageQueryFiltersParameters = {
    page: Number(camelizedQuery.page) || paginationParameters.pageNumber,
    perPage: Number(camelizedQuery.perPage) || paginationParameters.pageSize,
    startDate: dateToString(filters.startDate),
    endDate: dateToString(filters.endDate),
    sort: (camelizedQuery.sort as ISortParameter) || filters.sort,
  };

  useEffect(() => {
    const parameters = {
      ...getResourcesUsageQueryFiltersParameters,
      ...smartFilter,
      page: paginationParameters.pageNumber,
      perPage: paginationParameters.pageSize,
    };
    queryStringUpdate(parameters);
  }, [filters, smartFilter, paginationParameters]);

  const {
    data: allocations,
    isLoading: isAllocationsLoading,
    isFetching: isAllocationsFetching,
    isError: isAllocationsLoadingError,
  } = useGetAllocationsQuery({
    ...getResourcesUsageQueryFiltersParameters,
    ...smartFilter,
  });

  const [createAllocationReportCsv, { isLoading: isCreateAllocationReportCsv }] =
    useCreateCsvAllocationReportMutation();

  const [createReportTrackedTimeEntriesXlsx, { isLoading: isCreateReportTrackedTimeEntriesXlsxLoading }] =
    useCreateXlsxAllocationReportMutation();

  const [exportAllocationReportCsv, { isLoading: isExportAllocationReportCsvLoading }] = useLazyGetExportQuery();

  const [exportAllocationReportXlsx, { isLoading: isExportAllocationReportXlsxLoading }] = useLazyGetExportQuery();

  const { isRoleFeatureAllowed } = usePermissions();

  const hasAccessToData = isRoleFeatureAllowed('canViewAllocationsReport');

  const pagination = {
    totalPages: allocations?.meta.totalPages,
    pageSize: allocations?.meta.perPage,
    pageNumber: allocations?.meta.currentPage,
  };

  const handlePaginationChange = (parameters: IPaginationParameters) => {
    setPaginationParameters(parameters);
  };

  const handleSmartFiltersChange = (filters: IReportAllocationsSmartFilters | null) => {
    setSmartFilter(filters);
    setPaginationParameters({ pageNumber: defaultPaginationParameters.pageNumber, pageSize: pagination.pageSize });
  };

  const handleCreateAllocationClick = () => {
    openModal({
      title: t('createAllocationModalTitle'),
      content: <CreateAllocationForm />,
    });
  };

  const handleReportFiltersChange = (reportFilters: IReportFilter | null) => {
    const filter = JSON.parse(reportFilters.filter);
    handleSmartFiltersChange(filter);
  };

  const { data: currentUserPermissions, isLoading: isCurrentUserPermissionsLoading } =
    useGetCurrentUserPermissionsQuery({
      permissionTarget: PermissionTargets.allocations,
    });

  const canCreateAllocation = currentUserPermissions?.permissions.canCreateAllocation;

  const ALLOCATION_LIST_COLUMNS = useMemo(() => {
    return [
      {
        Header: <TableHeader title={t('table.columnsHeaders.resource')} />,
        accessor: AllocationTableColumnAccessor.resource,
        minWidth: '220px',
      },
      {
        Header: <TableHeader title={t('table.columnsHeaders.project')} />,
        accessor: AllocationTableColumnAccessor.project,
        minWidth: '310px',
      },
      {
        Header: <TableHeader title={t('table.columnsHeaders.specialization')} />,
        accessor: AllocationTableColumnAccessor.specialization,
        minWidth: '200px',
      },
      {
        Header: <TableHeader title={t('table.columnsHeaders.fte')} />,
        accessor: AllocationTableColumnAccessor.fte,
        minWidth: '200px',
      },
      {
        Header: <TableHeader title={t('table.columnsHeaders.startDate')} />,
        accessor: AllocationTableColumnAccessor.startDate,
        minWidth: '200px',
      },
      {
        Header: <TableHeader title={t('table.columnsHeaders.endDate')} />,
        accessor: AllocationTableColumnAccessor.endDate,
        minWidth: '100px',
      },
      {
        Header: '',
        accessor: AllocationTableColumnAccessor.actions,
        width: '50px',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return allocations?.usages?.map((allocation: IAllocationItem) => {
      const { projectName, startDate, endDate, workload, technologies } = allocation;

      const resourceName = getFullName(allocation);
      const localisedStartDate = formatToShortString(formatToDateWithFixedTimeZone(startDate));
      const localisedEndDate = _.isNil(endDate) ? '' : formatToShortString(formatToDateWithFixedTimeZone(endDate));

      return {
        [AllocationTableColumnAccessor.resource]: <TableCell value={resourceName} />,
        [AllocationTableColumnAccessor.project]: <TableCell value={projectName} />,
        [AllocationTableColumnAccessor.specialization]: <TableCellSpecialization value={technologies} />,
        [AllocationTableColumnAccessor.fte]: <TableCell value={workload} />,
        [AllocationTableColumnAccessor.startDate]: <TableCell value={localisedStartDate} />,
        [AllocationTableColumnAccessor.endDate]: <TableCell value={localisedEndDate} />,
        [AllocationTableColumnAccessor.actions]: <AllocationActions allocation={allocation} />,
      };
    });
  }, [allocations]);

  if (isCurrentUserPermissionsLoading || isAllocationsLoading) {
    return <Loader />;
  }

  const handleCsvDownload = async () => {
    try {
      const {
        export: { id: exportId },
      } = await createAllocationReportCsv({
        ...getResourcesUsageQueryFiltersParameters,
        ...smartFilter,
      }).unwrap();

      const allocationReportCsv = await exportAllocationReportCsv(exportId).unwrap();

      fileDownload(allocationReportCsv);
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleXlsxDownload = async () => {
    try {
      const {
        export: { id: exportId },
      } = await createReportTrackedTimeEntriesXlsx({
        ...getResourcesUsageQueryFiltersParameters,
        ...smartFilter,
      }).unwrap();

      const allocationReportXlsx = await exportAllocationReportXlsx(exportId).unwrap();

      fileDownload(allocationReportXlsx);
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const isDisabled =
    isCreateAllocationReportCsv ||
    isCreateReportTrackedTimeEntriesXlsxLoading ||
    isExportAllocationReportCsvLoading ||
    isExportAllocationReportXlsxLoading;

  return (
    <>
      <Div sx={styles.titleBlock}>
        <Typography variant="h1">{t('title')}</Typography>
        <Div sx={styles.buttons}>
          {canCreateAllocation && <Button onClick={handleCreateAllocationClick}>{t('createAllocationButton')}</Button>}
          <ExportButton isDisabled={isDisabled} onCsvDownload={handleCsvDownload} onXlsxDownload={handleXlsxDownload} />
          <ReportFiltersButton onChange={handleReportFiltersChange} currentReportType={REPORT_TYPE} />
        </Div>
      </Div>

      <Div sx={styles.smartFilter}>
        <SmartFilter<IReportAllocationsSmartFilters>
          smartFilter={smartFilter}
          onSmartFilterChange={handleSmartFiltersChange}
          filters={FILTERS}
          onReset={queryStringClear}
          reportType={REPORT_TYPE}
        />
      </Div>

      <>
        <Filter filters={filters} setFilters={setFilters} resourcesTotalCount={allocations?.meta.totalCount} />
        <Table
          data={tableData}
          columns={ALLOCATION_LIST_COLUMNS}
          isLoading={isAllocationsFetching}
          isError={isAllocationsLoadingError}
          pagination={pagination}
          onPaginationChange={handlePaginationChange}
          hasAccessToData={hasAccessToData}
        />
      </>
    </>
  );
};

export default Allocation;
