import React, { FC, useContext } from 'react';

import { Button, Table, TableBody, TableCell, TableRow } from '@mui/material';
import camelize from 'camelize';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import CompanySettingsForm from 'components/pages/AdminPanel/components/CompanyInfo/components/CompanySettingsForm';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateCompanySettingsMutation } from 'domain/company/settings/apiSlice';
import { IUpdateCompanySettingFormData, formatAttributesToUpdateSubmit } from 'domain/company/settings/schemas/form';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';

import styles from './styles';
import { ICompanySettingsProps } from './types';

const CompanySettings: FC<ICompanySettingsProps> = props => {
  const { setting } = props;

  const { t } = useTranslation('adminPanel');

  const { openModal, closeModal } = useContext(ModalContext);

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [updateSettings, { isLoading: isUpdateCompanySettingsLoading }] = useUpdateCompanySettingsMutation();

  const handleSubmitButtonClick = async (formData: IUpdateCompanySettingFormData) => {
    try {
      await updateSettings(formatAttributesToUpdateSubmit(formData)).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const handleEditButtonClick = () => () => {
    openModal({
      title: t('companySettings.updateCompanySettingsForm.title'),
      content: (
        <CompanySettingsForm
          onSubmit={handleSubmitButtonClick}
          disabled={isUpdateCompanySettingsLoading}
          submitButtonLabel={t('buttons.edit')}
          setting={setting}
        />
      ),
    });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: '1' }}>
        <Table>
          <TableBody sx={styles.settingBody}>
            <TableRow>
              <TableCell sx={styles.settingName}>Tracked Time Lock Date:</TableCell>
              <TableCell sx={styles.settingValue}>{setting.trackedTimeEditLockDate}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles.settingName}>Default hours per week:</TableCell>
              <TableCell sx={styles.settingValue}>{setting.defaultHoursPerWeek}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles.settingName}>Default billable percentage:</TableCell>
              <TableCell sx={styles.settingValue}>{setting.defaultExpectedBillablePercentage}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles.settingName}>
                {t('companySettings.companySettingsForm.duplicationCheck.label')}:
              </TableCell>
              <TableCell sx={styles.settingValue}>{setting.trackedTimeDuplicationCheck}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles.settingName}>
                {t('companySettings.companySettingsForm.defaultPrecisionForDuplicationCheck.label')}:
              </TableCell>
              <TableCell sx={styles.settingValue}>{setting.defaultPrecisionForDuplicationCheck}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles.settingName}>
                {t('companySettings.companySettingsForm.uniquenessCheckStrategy.label')}:
              </TableCell>
              <TableCell sx={styles.settingValue}>
                {t(
                  `companySettings.companySettingsForm.uniquenessCheckStrategy.${camelize(
                    setting.trackedTimeUniquenessCheckStrategy,
                  )}`,
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <Div sx={styles.container}>
        <Div sx={MUISx({ condition: !_.isNil(145), sx: { width: `${145}px` } })}>
          <Button type="submit" variant="contained" onClick={handleEditButtonClick()}>
            {' '}
            Edit{' '}
          </Button>
        </Div>
      </Div>
    </div>
  );
};

export default CompanySettings;
