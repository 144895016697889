import { capitalize } from '@mui/material';

import { getLastIndex } from 'utils/array';

import { IStickyCellPosition, StickyCellKey } from './types';

const ZERO_OFFSET = 0;

export const buildStickyCellsPositions = (cellReferences: HTMLTableCellElement[]): IStickyCellPosition[] => {
  const [firsCell, secondCell, ...otherCells] = cellReferences;
  const lastCellIndex = getLastIndex(otherCells);
  const initialStyles = [
    { id: firsCell.id, left: ZERO_OFFSET, top: ZERO_OFFSET },
    { id: secondCell.id, left: firsCell.offsetWidth, top: ZERO_OFFSET },
  ];

  return otherCells.reduce((styles, cellReference, cellIndex) => {
    const { id: cellId, offsetWidth, offsetHeight } = cellReference;

    const id = StickyCellKey[capitalize(cellId)] ?? StickyCellKey.Month;

    if (cellIndex === lastCellIndex) {
      return [...styles, { id, left: ZERO_OFFSET, top: offsetHeight }];
    }

    const lastLeftOffset = styles[getLastIndex(styles)].left;
    const leftOffset = lastLeftOffset + offsetWidth;

    return [...styles, { id, left: leftOffset, top: ZERO_OFFSET }];
  }, initialStyles);
};
