import { FC, useMemo, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import CreateResourceAllocation from 'components/common/CreateResourceAllocation';
import CommonTableGroupedExpanded from 'components/common/TableGroupedExpanded';

import { ModalContext } from 'contexts/ModalContext';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { IReportHoursByResourceProjectItem, IReportHoursByResource } from 'domain/report/hoursByResource/types';

import { prepareTableData, prepareTableColumns } from './service';
import styles from './styles';
import { IResourcesTableProps } from './types';

const Table: FC<IResourcesTableProps> = props => {
  const {
    data,
    isError,
    isDetailedInfo,
    isLoading,
    pagination,
    setPaginationParameters,
    hoursType,
    groupByPeriod,
    buildPathForProjectNameClick,
    buildPathForResourceActualHoursClick,
    buildPathForResourcePlannedHoursClick,
    buildPathForProjectActualHoursClick,
    hasAccessToData = false,
  } = props;

  const { t } = useTranslation('reportHoursByResources');

  const { openModal } = useContext(ModalContext);
  const { data: currentUser } = useGetCurrentUserQuery();

  const handleAddAllocationClick = (
    currentResource: IReportHoursByResource,
    currentProject: IReportHoursByResourceProjectItem = null,
  ) => {
    openModal({
      title: t('table.addAllocationModalTitle'),
      content: <CreateResourceAllocation currentResource={currentResource} currentProject={currentProject} />,
    });
  };

  const buildPathHelpers = {
    buildPathForProjectNameClick,
    buildPathForResourceActualHoursClick,
    buildPathForResourcePlannedHoursClick,
    buildPathForProjectActualHoursClick,
  };

  const tableData = useMemo(
    () =>
      prepareTableData({
        data,
        isDetailedInfo,
        hoursType,
        currentUser,
        buildPathHelpers,
        styles,
        onAddAllocationClick: handleAddAllocationClick,
        t,
      }),
    [data, isDetailedInfo, hoursType],
  );

  const tableColumns = useMemo(
    () =>
      prepareTableColumns({
        data,
        isDetailedInfo,
        groupByPeriod,
        styles,
        t,
      }),
    [data, isDetailedInfo, hoursType, groupByPeriod],
  );

  return (
    <CommonTableGroupedExpanded
      data={tableData}
      columns={tableColumns}
      isError={isError}
      isLoading={isLoading}
      onPaginationChange={setPaginationParameters}
      pagination={pagination}
      isDetailedInfo={isDetailedInfo}
      hasAccessToData={hasAccessToData}
    />
  );
};

export default Table;
