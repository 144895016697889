export default {
  edit: 'Change role',
  remove: 'End allocation',
  editResourceUsageModalTitle: 'Change role',
  editResourceUsageTechnologiesForm: {
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },
    technologies: {
      label: 'Resource Stack',
    },
  },
  removeResourceUsageModalTitle: 'End Allocation',
  notAvailable: 'You do not have permission to perform this action',
  removeResourceUsageModalSubtitle:
    'You are about to remove <1>{{ resourceUsageFullName }}</1><2></2>from the project <3>{{ projectName }}<3/>',
  removeResourceUsageEndDateForm: {
    fields: {
      endDate: {
        label: 'End Date',
      },
    },
    buttons: {
      remove: 'Remove',
      cancel: 'Cancel',
    },
  },
};
