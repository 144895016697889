import { SxStyles } from 'theme';

const styles: SxStyles = {
  nameField: {
    paddingTop: 1,
  },
  name: {
    width: '400px',
    marginBottom: 4,
  },
  placeholder: {
    color: 'text.gray',
  },
  createButton: {
    marginRight: 1.25,
  },
};

export default styles;
