/* eslint-disable sonarjs/cognitive-complexity */
import { FC, useCallback, useEffect, useState } from 'react';

import { Alert, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks';

import Div from 'components/common/Div';
import ExportButton from 'components/common/ExportButton';
import ReportFiltersButton from 'components/common/ReportFiltersButton';
import SmartFilter from 'components/common/SmartFilter';
import { pickFieldsForSmartFilter } from 'components/common/SmartFilter/service';
import { ISmartFilter } from 'components/common/SmartFilter/types';
import TableSkeleton from 'components/common/TableSkeleton';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { useLazyGetExportQuery } from 'domain/export/apiSlice';
import { ReportType } from 'domain/report/filter/enums';
import { IReportFilter } from 'domain/report/filter/types';
import {
  IReportTrackedTimeEntriesSmartFilters,
  useCreateCsvReportTrackedTimeEntriesMutation,
  useCreateXlsxReportTrackedTimeEntriesMutation,
  useGetReportTrackedTimeEntriesGroupedByUserQuery,
  useGetReportTrackedTimeEntriesQuery,
} from 'domain/report/trackedTimeEntry/apiSlice';
import { IReportTrackedTimeEntry, IReportTrackedTimeEntryUser } from 'domain/report/trackedTimeEntry/types';

import { GroupingOption } from 'enums/GroupingOption';

import { useNotifications } from 'hooks/useNotifications';
import { usePermissions } from 'hooks/usePermissions';

import { IPaginationParameters } from 'types/api';

import { dateToString, stringToDate } from 'utils/calendar';
import { getTimeRanges } from 'utils/dateTime';
import { generateBackendErrorMessages } from 'utils/error';
import { fileDownload } from 'utils/file';
import { defaultPaginationParameters } from 'utils/pagination';
import { getSortParameter } from 'utils/runsack';

import EntriesSelection from './components/EntriesSelection';
import Filter from './components/Filter';
import ReportTrackedTimeEntriesList from './components/ReportTrackedTimeEntriesList';
import TotalInfo from './components/TotalInfo';
import UsersCopy from './components/UsersCopy';
import { dataIsEmpty, getSelectionInfo, hasMoreItems as getMoreItems, updateSelectedUserIds } from './service';
import styles from './styles';
import { IReportTrackedTimeEntriesFilter } from './types';

const EMPTY_TOTAL_TRACKED_TIME = 0;
const PAGE_SIZE_FOR_PROJECT_GROUPING = 5;
const REPORT_TYPE = ReportType.trackedTimeEntries;
const FILTERS: Array<ISmartFilter> = [
  'trackable',
  'trackableBlank',
  'resource',
  'actualHours',
  'description',
  'billable',
  'group',
  'technology',
  'task',
  'tag',
];

const TrackedTimeEntries: FC = () => {
  const { t } = useTranslation(['common', 'reportTrackedTimeEntries']);
  const { queryStringUpdate, queryStringClear, camelizedQuery } = useRouter();
  const [selectedEntryIds, setSelectedEntryIds] = useState<Array<number>>([]);
  const [isSelectedAllActive, setIsSelectedAllActive] = useState<boolean>(false);
  const [isEntriesCopyDrawerOpened, setEntriesCopyDrawerOpened] = useState<boolean>(false);
  const { isRoleFeatureAllowed } = usePermissions();
  const { data: currentUser } = useGetCurrentUserQuery();
  const [lastGroup, setLastGroup] = useState<GroupingOption | null>(null);
  const timeRanges = getTimeRanges(currentUser?.user?.weekStartsDay ?? 1);

  const handleEntrySelect = (selectedId: number) => {
    setSelectedEntryIds(previousValues => updateSelectedUserIds(previousValues, selectedId));
  };

  const [reportTrackedTimeEntries, setReportTrackedTimeEntries] = useState<Array<IReportTrackedTimeEntry>>([]);
  const [reportTrackedTimeEntriesGroupedByUser, setReportTrackedTimeEntriesGroupedByUser] = useState<
    Array<IReportTrackedTimeEntryUser>
  >([]);
  const { isAllEntriesSelected } = getSelectionInfo(reportTrackedTimeEntries, selectedEntryIds);
  const [pagination, setPagination] = useState<IPaginationParameters>({
    pageNumber: Number(camelizedQuery.page) || defaultPaginationParameters.pageNumber,
    pageSize: Number(camelizedQuery.perPage) || defaultPaginationParameters.pageSize,
  });

  const { showErrorNotification } = useNotifications();

  const [filter, setFilter] = useState<IReportTrackedTimeEntriesFilter>({
    afterDate: (camelizedQuery.afterDate && stringToDate(camelizedQuery.afterDate)) || timeRanges.startOfWeek,
    beforeDate: (camelizedQuery.beforeDate && stringToDate(camelizedQuery.beforeDate)) || timeRanges.endOfWeek,
  });

  const [smartFilter, setSmartFilter] = useState<IReportTrackedTimeEntriesSmartFilters | null>(
    pickFieldsForSmartFilter(camelizedQuery, FILTERS),
  );

  const [group, setGroup] = useState<GroupingOption>(
    (camelizedQuery.group as GroupingOption) || GroupingOption.noGrouping,
  );

  const changeGroupBy = (groupBy: GroupingOption) => {
    setReportTrackedTimeEntries([]);
    setReportTrackedTimeEntriesGroupedByUser([]);
    const pagination =
      groupBy === GroupingOption.project
        ? { ...defaultPaginationParameters, pageSize: PAGE_SIZE_FOR_PROJECT_GROUPING }
        : defaultPaginationParameters;
    setLastGroup(group);
    setPagination(pagination);
    setGroup(groupBy);
  };

  const getMappedSmartFilters = useCallback(
    (smartFilter: IReportTrackedTimeEntriesSmartFilters | null) => {
      const { trackables, ...rest } = smartFilter ?? {};
      const trackablesMapped = trackables?.map(trackable => {
        const { id, type } = trackable;
        return { id, type };
      });
      return { trackables: trackablesMapped, ...rest };
    },
    [smartFilter],
  );

  const reportTrackedTimeEntriesParameters = {
    page: pagination.pageNumber,
    perPage: pagination.pageSize,
    afterDate: dateToString(filter.afterDate),
    beforeDate: dateToString(filter.beforeDate),
    s: getSortParameter('date', 'asc'),
    groupBy: group,
  };

  useEffect(() => {
    const parameters = {
      ...reportTrackedTimeEntriesParameters,
      ...getMappedSmartFilters(smartFilter),
      group,
    };
    queryStringUpdate(parameters);
  }, [filter, smartFilter, group]);

  const {
    data: reportTrackedTimeEntriesData,
    isLoading: isReportTrackedTimeEntriesDataLoading,
    isError: isReportTrackedTimeEntriesDataLoadingError,
    isFetching: isReportTrackedTimeEntriesDataFetching,
  } = useGetReportTrackedTimeEntriesQuery(
    {
      ...reportTrackedTimeEntriesParameters,
      ...getMappedSmartFilters(smartFilter),
    },
    { skip: GroupingOption.user === group },
  );

  const {
    data: reportTrackedTimeEntriesGroupedByUserData,
    isLoading: isReportTrackedTimeEntriesGroupedByUserDataLoading,
    isError: isReportTrackedTimeEntriesGroupedByUserDataLoadingError,
    isFetching: isReportTrackedTimeEntriesGroupedByUserDataFetching,
  } = useGetReportTrackedTimeEntriesGroupedByUserQuery(
    {
      ...reportTrackedTimeEntriesParameters,
      ...getMappedSmartFilters(smartFilter),
    },
    {
      skip: GroupingOption.user !== group,
    },
  );

  const [createReportTrackedTimeEntriesCsv, { isLoading: isCreateReportTrackedTimeEntriesCsvLoading }] =
    useCreateCsvReportTrackedTimeEntriesMutation();

  const [exportReportTrackedTimeEntriesCsv, { isLoading: isExportReportTrackedTimeEntriesCsvLoading }] =
    useLazyGetExportQuery();

  const [createReportTrackedTimeEntriesXlsx, { isLoading: isCreateReportTrackedTimeEntriesXlsxLoading }] =
    useCreateXlsxReportTrackedTimeEntriesMutation();

  const [exportReportTrackedTimeEntriesXlsx, { isLoading: isExportReportTrackedTimeEntriesXlsxLoading }] =
    useLazyGetExportQuery();

  useEffect(() => {
    if (!_.isNil(reportTrackedTimeEntriesData) && GroupingOption.user !== group) {
      let allEntries: IReportTrackedTimeEntry[] = [];
      allEntries =
        group !== lastGroup && pagination.pageNumber === defaultPaginationParameters.pageNumber
          ? [...reportTrackedTimeEntriesData.report.trackedTimeEntries]
          : [...reportTrackedTimeEntries, ...reportTrackedTimeEntriesData.report.trackedTimeEntries];
      const { allEntriesIds } = getSelectionInfo(allEntries, []);
      setReportTrackedTimeEntries(allEntries);
      setSelectedEntryIds(isSelectedAllActive ? allEntriesIds : []);
    }
    if (!_.isNil(reportTrackedTimeEntriesGroupedByUserData) && GroupingOption.user === group) {
      const allEntries = [
        ...reportTrackedTimeEntriesGroupedByUser,
        ...reportTrackedTimeEntriesGroupedByUserData.report.users,
      ];
      const { allEntriesIds } = getSelectionInfo(allEntries, []);
      setReportTrackedTimeEntriesGroupedByUser(allEntries);
      setSelectedEntryIds(isSelectedAllActive ? allEntriesIds : []);
    }
  }, [group, reportTrackedTimeEntriesData, reportTrackedTimeEntriesGroupedByUserData]);

  useEffect(() => {
    setIsSelectedAllActive(isAllEntriesSelected);
  }, [selectedEntryIds]);

  if (isReportTrackedTimeEntriesDataLoadingError || isReportTrackedTimeEntriesGroupedByUserDataLoadingError) {
    return <Alert severity="error">{`${t('common:defaultErrorNotificationText')}`}</Alert>;
  }

  const handleLoadMore = () => {
    const pageNumber = pagination.pageNumber ?? 0;
    setPagination({ ...pagination, pageNumber: pageNumber + 1 });
  };

  const hasMoreItems =
    GroupingOption.user === group
      ? getMoreItems(reportTrackedTimeEntriesGroupedByUserData?.meta) &&
        !isReportTrackedTimeEntriesGroupedByUserDataLoading &&
        !isReportTrackedTimeEntriesGroupedByUserDataFetching
      : getMoreItems(reportTrackedTimeEntriesData?.meta) &&
        !isReportTrackedTimeEntriesDataLoading &&
        !isReportTrackedTimeEntriesDataFetching;

  const handleReportTrackedTimeEntriesReset = () => {
    setReportTrackedTimeEntries([]);
    setReportTrackedTimeEntriesGroupedByUser([]);
    setPagination(defaultPaginationParameters);
    queryStringClear();
  };

  const isEmptyData =
    GroupingOption.user === group
      ? dataIsEmpty(EMPTY_TOTAL_TRACKED_TIME, reportTrackedTimeEntriesGroupedByUserData?.report?.totalTrackedTime) &&
        !isReportTrackedTimeEntriesGroupedByUserDataLoading &&
        !isReportTrackedTimeEntriesGroupedByUserDataFetching
      : dataIsEmpty(EMPTY_TOTAL_TRACKED_TIME, reportTrackedTimeEntriesData?.totalTrackedTime) &&
        !isReportTrackedTimeEntriesDataLoading &&
        !isReportTrackedTimeEntriesDataFetching;

  const isDisabled =
    isCreateReportTrackedTimeEntriesCsvLoading ||
    isExportReportTrackedTimeEntriesCsvLoading ||
    isCreateReportTrackedTimeEntriesXlsxLoading ||
    isExportReportTrackedTimeEntriesXlsxLoading;

  const handleCsvDownload = async () => {
    try {
      const {
        export: { id: exportId },
      } = await createReportTrackedTimeEntriesCsv({
        ...reportTrackedTimeEntriesParameters,
        ...smartFilter,
      }).unwrap();

      const reportTrackedTimeEntriesCsv = await exportReportTrackedTimeEntriesCsv(exportId).unwrap();

      fileDownload(reportTrackedTimeEntriesCsv);
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleXlsxDownload = async () => {
    try {
      const {
        export: { id: exportId },
      } = await createReportTrackedTimeEntriesXlsx({
        ...reportTrackedTimeEntriesParameters,
        ...smartFilter,
      }).unwrap();

      const reportTrackedTimeEntriesXlsx = await exportReportTrackedTimeEntriesXlsx(exportId).unwrap();

      fileDownload(reportTrackedTimeEntriesXlsx);
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleReportFiltersChange = (reportFilters: IReportFilter | null) => {
    if (!reportFilters) return;
    const filter = JSON.parse(reportFilters.filter);
    handleSmartFilterChange(filter);
  };

  const handleSmartFilterChange = (filters: IReportTrackedTimeEntriesSmartFilters | null) => {
    setSmartFilter(filters);
  };

  const handleCopyEntriesButtonClick = () => {
    setEntriesCopyDrawerOpened(true);
  };

  const handleSelectAllEntriesButtonClick = (entryIds: Array<number>) => {
    setSelectedEntryIds(entryIds);
    setIsSelectedAllActive(true);
  };

  const handleDeselectAllEntriesButtonClick = () => {
    setSelectedEntryIds([]);
    setIsSelectedAllActive(false);
  };

  const handleCloseCopyDrawer = () => setEntriesCopyDrawerOpened(false);

  return (
    <>
      <Div sx={styles.titleBlock}>
        <Typography variant="h1">{t('reportTrackedTimeEntries:title')}</Typography>
        <Div sx={styles.buttons}>
          <ExportButton isDisabled={isDisabled} onCsvDownload={handleCsvDownload} onXlsxDownload={handleXlsxDownload} />
          <ReportFiltersButton onChange={handleReportFiltersChange} currentReportType={REPORT_TYPE} />
        </Div>
      </Div>

      <Div sx={styles.smartFilter}>
        <SmartFilter<IReportTrackedTimeEntriesSmartFilters>
          smartFilter={smartFilter}
          onSmartFilterChange={handleSmartFilterChange}
          filters={FILTERS}
          onReset={handleReportTrackedTimeEntriesReset}
          reportType={REPORT_TYPE}
        />
      </Div>

      <Div sx={styles.totalFilter}>
        <TotalInfo
          totalTrackedTime={
            (GroupingOption.user === group
              ? reportTrackedTimeEntriesGroupedByUserData?.report?.totalTrackedTime
              : reportTrackedTimeEntriesData?.totalTrackedTime) ?? 0
          }
          billableTrackedTime={
            (GroupingOption.user === group
              ? reportTrackedTimeEntriesGroupedByUserData?.report?.billableTrackedTime
              : reportTrackedTimeEntriesData?.billableTrackedTime) ?? 0
          }
          percentOfBillableTrackedTime={
            (GroupingOption.user === group
              ? reportTrackedTimeEntriesGroupedByUserData?.report?.percentOfBillableTrackedTime
              : reportTrackedTimeEntriesData?.percentOfBillableTrackedTime) ?? 0
          }
        />

        <Filter
          filter={filter}
          setFilter={setFilter}
          group={group}
          setGroup={changeGroupBy}
          onReset={handleReportTrackedTimeEntriesReset}
        />
      </Div>

      {isRoleFeatureAllowed('canSelectUsers') && (
        <EntriesSelection
          group={group}
          onSelectAll={handleSelectAllEntriesButtonClick}
          onDeselectAll={handleDeselectAllEntriesButtonClick}
          onCopy={handleCopyEntriesButtonClick}
          selectedEntryIds={selectedEntryIds}
          reportTrackedTimeEntries={
            GroupingOption.user === group ? reportTrackedTimeEntriesGroupedByUser : reportTrackedTimeEntries
          }
        />
      )}
      {isRoleFeatureAllowed('canCopySelectedUsers') && (
        <UsersCopy
          isOpened={isEntriesCopyDrawerOpened}
          onClose={handleCloseCopyDrawer}
          hasMoreItems={hasMoreItems}
          selectedEntryIds={selectedEntryIds}
          smartFilter={smartFilter}
          reportTrackedTimeEntries={
            GroupingOption.user === group ? reportTrackedTimeEntriesGroupedByUser : reportTrackedTimeEntries
          }
          reportTrackedTimeEntriesParameters={reportTrackedTimeEntriesParameters}
        />
      )}

      {!isReportTrackedTimeEntriesDataLoading && !isReportTrackedTimeEntriesGroupedByUserDataLoading && (
        <ReportTrackedTimeEntriesList
          trackedTimeEntries={
            GroupingOption.user === group ? reportTrackedTimeEntriesGroupedByUser : reportTrackedTimeEntries
          }
          onLoadMore={handleLoadMore}
          hasMoreItems={hasMoreItems}
          isFetching={isReportTrackedTimeEntriesDataFetching || isReportTrackedTimeEntriesGroupedByUserDataFetching}
          group={group}
          onEntrySelect={handleEntrySelect}
          selectedEntryIds={selectedEntryIds}
        />
      )}

      {(isReportTrackedTimeEntriesDataFetching || isReportTrackedTimeEntriesGroupedByUserDataFetching) && (
        <TableSkeleton />
      )}

      {isEmptyData && GroupingOption.user !== group && <Typography textAlign="center">{t('common:noData')}</Typography>}
    </>
  );
};

export default TrackedTimeEntries;
