import { SxStyles } from 'theme';

const styles: SxStyles = {
  box: {
    width: '100%',
  },
  searchIcon: {
    color: 'text.lightGray',
  },
};

export default styles;
