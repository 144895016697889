import { FC } from 'react';

import { Tooltip, Typography } from '@mui/material';

import Div from 'components/common/Div';

import { getTechnologyNamesWithAncestors } from 'domain/technology/presenter';

import styles from './styles';
import { IUserTableSpecializationCellProps } from './types';

const TableCellSpecialization: FC<IUserTableSpecializationCellProps> = props => {
  const { value } = props;

  const technologyNamesJoinedByNewLine = getTechnologyNamesWithAncestors(value, '\n');
  const technologyNamesJoinedByComma = getTechnologyNamesWithAncestors(value, ', ');

  return (
    <Tooltip arrow title={<Div sx={styles.tooltip}>{technologyNamesJoinedByNewLine}</Div>} placement="bottom-start">
      <Typography variant="subtitle1" sx={styles.root} noWrap>
        {technologyNamesJoinedByComma}
      </Typography>
    </Tooltip>
  );
};

export default TableCellSpecialization;
