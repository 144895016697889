import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    marginBottom: 5.5,
    paddingLeft: 1,
    paddingRight: 1,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 2,
    paddingRight: 2,
    backgroundColor: 'background.gray',
    marginBottom: 1,
  },
  workingHours: {
    color: 'text.lightGray',
  },
};

export default styles;
