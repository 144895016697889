import { FC, useEffect, useRef, useState } from 'react';

import { Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';

import Div from 'components/common/Div';

import styles from './styles';
import { ITrackedTimeEntryProjectChipProps } from './types';

const TrackedTimeEntryProjectChip: FC<ITrackedTimeEntryProjectChipProps> = props => {
  const { isBillable, projectName, taskName } = props;

  const hasTask = !_.isNil(taskName);
  const trackableReference = useRef<HTMLDivElement>(null);
  const [tooltipTitle, setTooltipTitle] = useState<string>('');

  useEffect(() => {
    const projectContainer = trackableReference.current.querySelector('span[aria-describedby="trackable-name"]');
    const taskContainer = trackableReference.current.querySelector('span[aria-describedby="trackable-task"]');
    const isProjectNameOverflowing = projectContainer && projectContainer.clientWidth < projectContainer.scrollWidth;
    const isTaskNameOverflowing = taskContainer && taskContainer.clientWidth < taskContainer.scrollWidth;
    if (isProjectNameOverflowing || isTaskNameOverflowing) {
      setTooltipTitle(_.isNil(taskName) ? projectName : `${projectName}: ${taskName}`);
    }
  }, [taskName, projectName]);

  return (
    <Tooltip title={tooltipTitle} placement="top-end" arrow>
      <Div sx={styles.root} ref={trackableReference}>
        <Typography
          component="span"
          sx={MUISx(
            styles.dot,
            { condition: isBillable, sx: styles.billableDot },
            { condition: !isBillable, sx: styles.nonBillableDot },
          )}
        />
        <Typography
          aria-describedby="trackable-name"
          sx={MUISx(
            styles.content,
            { condition: isBillable, sx: styles.billableName },
            { condition: !isBillable, sx: styles.nonBillableName },
          )}
          variant="heading3"
        >
          {projectName}
        </Typography>
        {hasTask && (
          <Typography
            aria-describedby="trackable-task"
            sx={MUISx(
              styles.content,
              { condition: isBillable, sx: styles.billableName },
              { condition: !isBillable, sx: styles.nonBillableName },
            )}
            variant="heading3"
          >
            {`: ${taskName}`}
          </Typography>
        )}
      </Div>
    </Tooltip>
  );
};

export default TrackedTimeEntryProjectChip;
