import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';
import { stringify } from 'qs';

import { SmartFilterDescriptionState } from 'components/common/SmartFilter/enums';

import { VacationsTab } from 'enums/reportVacations';

import { IWeekStartsOn } from 'types/dates';

import { dateToString } from 'utils/calendar';
import { getTimeRanges } from 'utils/dateTime';
import { getSortParameter } from 'utils/runsack';

import { VacationRequestState } from './enums';
import { VacationFilter } from './types';

export const getDefaultTabName = (name?: string): VacationsTab => {
  if (!name) {
    return VacationsTab.calendar;
  }

  return VacationsTab[name];
};

export const buildFilterValues = (camelizedQuery: Params, weekStartsDay: IWeekStartsOn): VacationFilter => {
  const { activeTab, startDate, endDate, stateEq, vacationRequestStateEq, ...smartFilterValues } = camelizedQuery;

  const timeRanges = getTimeRanges(weekStartsDay);
  const stateEqValue = stateEq ? SmartFilterDescriptionState[stateEq] : SmartFilterDescriptionState.active;
  const vacationRequestStateEqValue = vacationRequestStateEq
    ? VacationRequestState[capitalize(vacationRequestStateEq)]
    : VacationRequestState.Indefinite;

  return {
    activeTab: getDefaultTabName(activeTab),
    startDate: startDate ?? dateToString(timeRanges.startOfMonth),
    endDate: endDate ?? dateToString(timeRanges.endOfMonth),
    sort: getSortParameter('name', 'asc'),
    stateEq: stateEqValue,
    vacationRequestStateEq: vacationRequestStateEqValue,
    ...smartFilterValues,
  };
};

export const getQueryString = (parameters: Params): string => {
  const entries = sortBy(Object.entries(parameters), ([key]) => key);

  return stringify(Object.fromEntries(entries));
};
