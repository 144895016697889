export interface ICreateForbiddenWordFormData {
  word: string;
}

export interface ICreateForbiddenWordFormDataToSubmit {
  word: string;
}

export const formatAttributesToSubmit = (
  values: ICreateForbiddenWordFormData,
): ICreateForbiddenWordFormDataToSubmit => {
  const { word } = values;

  return {
    word,
  };
};
