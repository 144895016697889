import common from './common';
import allocationActions from './components/allocationActions';
import createResourceUsageAndVacancy from './components/createResourceUsageAndVacancy';
import dateRangePicker from './components/dateRangePicker';
import exportButton from './components/exportButton';
import groupedByPeriodFilter from './components/groupedByPeriodFilter';
import monthPicker from './components/monthPicker';
import resourceUsageActions from './components/resourceUsageActions';
import sidebar from './components/sidebar';
import skillLevelSelect from './components/skillLevelSelect';
import smartFilter from './components/smartFilter';
import table from './components/table';
import tableHeader from './components/tableHeader';
import updateResourceUsageWorkloadWithDatesForm from './components/updateResourceUsageWorkloadWithDatesForm';
import updateVacancyFteWithDatesForm from './components/updateVacancyFteWithDatesForm';
import vacancyActions from './components/vacancyActions';
import vacancyFteWithDatesButton from './components/vacancyFteWithDatesButton';
import forms from './forms';
import adminPanel from './pages/adminPanel';
import newPassword from './pages/newPassword';
import allocation from './pages/reportAllocation';
import reportHoursByProject from './pages/reportHoursByProject';
import reportHoursByProjects from './pages/reportHoursByProjects';
import reportHoursByResources from './pages/reportHoursByResources';
import reportTrackedTimeEntries from './pages/reportTrackedTimeEntries';
import reportVacations from './pages/reportVacations';
import resetPassword from './pages/resetPassword';
import signIn from './pages/signIn';
import temporaryResources from './pages/temporaryResources';
import timeTracker from './pages/timeTracker';
import userProfile from './pages/userProfile';
import users from './pages/users';

const en = {
  sidebar,
  common,
  forms,
  signIn,
  resetPassword,
  newPassword,
  reportHoursByProjects,
  users,
  temporaryResources,
  table,
  dateRangePicker,
  monthPicker,
  reportHoursByProject,
  resourceUsageActions,
  updateResourceUsageWorkloadWithDatesForm,
  userProfile,
  reportHoursByResources,
  groupedByPeriodFilter,
  smartFilter,
  timeTracker,
  adminPanel,
  reportTrackedTimeEntries,
  exportButton,
  tableHeader,
  createResourceUsageAndVacancy,
  vacancyActions,
  vacancyFteWithDatesButton,
  updateVacancyFteWithDatesForm,
  allocation,
  skillLevelSelect,
  allocationActions,
  reportVacations,
};

export default en;

export const ns = Object.keys(en);
