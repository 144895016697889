import theme, { SxStyles } from 'theme';

const styles: SxStyles = {
  tableContainer: {
    border: `2px solid ${theme.palette.border.main}`,
    borderRadius: 2,
    maxHeight: 365,
    overflowY: 'auto',
  },
  headerCell: {
    px: 2,
    py: 1,
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderBottom: `2px solid ${theme.palette.border.main}`,
    bgcolor: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  cellTitle: {
    fontWeight: 600,
  },
  boldRightBorder: {
    borderRightWidth: 2,
  },
};

export default styles;
