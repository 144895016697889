import { FC, useState } from 'react';

import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import TabPanel from 'components/common/TabPanel';

import { VacationRequestTab } from 'enums/reportVacations';

import Approvements from './components/VacationRequestTables/components/Tabs/Approvements';
import VacationRequestPracticesTab from './components/VacationRequestTables/components/Tabs/Practices';
import VacationRequestProjectsTab from './components/VacationRequestTables/components/Tabs/Projects';
import styles from './styles';
import { IVacationRequestTabsProps } from './types';

const VACATION_REQUEST_TAB_LABEL = 'vacationRequest';

const VacationRequestTabs: FC<IVacationRequestTabsProps> = props => {
  const { vacationsEndDate, vacationsRequestId, vacationsRequestName, vacationsRequestYear, vacationsStartDate } =
    props;

  const { t } = useTranslation('reportVacations');

  const [activeTabName, setActiveTabName] = useState<VacationRequestTab>(VacationRequestTab.project);

  const handleTabChange = (_: React.SyntheticEvent, newTab: VacationRequestTab) => setActiveTabName(newTab);

  return (
    <Div sx={styles.root}>
      <Div sx={styles.tabHeadersContainer}>
        <Tabs value={activeTabName} onChange={handleTabChange} aria-label="tabs" textColor="inherit">
          <Tab label={t('vacationRequests.tabs.project.label')} value={VacationRequestTab.project} />
          <Tab label={t('vacationRequests.tabs.practice.label')} value={VacationRequestTab.practice} />
          <Tab label={t('vacationRequests.tabs.pipeline.label')} value={VacationRequestTab.approvements} />
        </Tabs>
      </Div>
      <TabPanel value={activeTabName} name={VacationRequestTab.project} tabGroupName={VACATION_REQUEST_TAB_LABEL}>
        <VacationRequestProjectsTab
          vacationsEndDate={vacationsEndDate}
          vacationsRequestId={vacationsRequestId}
          vacationsRequestName={vacationsRequestName}
          vacationsRequestYear={vacationsRequestYear}
          vacationsStartDate={vacationsStartDate}
        />
      </TabPanel>
      <TabPanel value={activeTabName} name={VacationRequestTab.practice} tabGroupName={VACATION_REQUEST_TAB_LABEL}>
        <VacationRequestPracticesTab
          vacationsEndDate={vacationsEndDate}
          vacationsRequestId={vacationsRequestId}
          vacationsRequestName={vacationsRequestName}
          vacationsRequestYear={vacationsRequestYear}
          vacationsStartDate={vacationsStartDate}
        />
      </TabPanel>
      <TabPanel value={activeTabName} name={VacationRequestTab.approvements} tabGroupName={VACATION_REQUEST_TAB_LABEL}>
        <Approvements vacationsRequestId={vacationsRequestId} />
      </TabPanel>
    </Div>
  );
};

export default VacationRequestTabs;
