import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    position: 'relative',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    backgroundColor: 'background.white',
  },
  table: {
    borderCollapse: 'separate',
  },
  tableHead: {
    position: 'sticky',
    top: '-32px',
    backgroundColor: 'background.white',
    zIndex: 'fab',
  },
  tableLoading: {
    display: 'none',
  },
  singleRow: {
    borderBottom: 'none',
  },
  paginationWrapper: {
    alignItems: 'center',
    marginTop: 4,
    marginX: 0,
    marginBottom: 7.25,
  },
  pageNumbers: {
    flexGrow: 1,
  },
  perPage: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
  selector: {
    width: '104px',
  },
  paginationButton: {
    alignItems: 'center',
    gap: 1,
  },
  rowWithOpacity: {
    opacity: 0.4,
  },
  firstHeaderRow: {
    borderBottom: 'none',
  },
  cell: {
    textAlign: 'center',
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: 'border.main',
    padding: 1,
  },
  row: {
    position: 'relative',
  },
  depthRow: {
    '&::after': {
      content: "''",
      position: 'absolute',
      width: '4px',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'primary.main',
    },
  },
  firstHeaderColumn: {
    borderRight: 'none',
    textAlign: 'left',
  },
  lastHeaderColumn: {
    borderRight: 'none',
  },
  headerColumnsGroup: {
    borderRightWidth: '2px',
  },
  secondHeaderRow: {
    borderBottomWidth: '2px',
  },
  totalHoursRow: {
    backgroundColor: 'text.blackLight',
    color: 'text.whiteBlue',
  },
};

export default styles;
