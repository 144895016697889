import { TagTypes } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { ICreateResourceFavoriteTimeEntryFormDataToSubmit } from './schemas/create';
import { IResourceFavoriteTimeEntry } from './types';

interface IResourceFavoriteTimeEntriesResponse {
  favorites: Array<IResourceFavoriteTimeEntry>;
}

interface IDeleteResourceFavoriteTimeEntryQueryParameters {
  id: number;
  resourceId: number;
}

function createTagsFromFavorites(response) {
  return response.favorites
    ? [
        ...response.favorites.map(({ id }) => ({ type: TagTypes.ResourceFavoriteTimeEntry, id })),
        TagTypes.ResourceFavoriteTimeEntry,
      ]
    : [TagTypes.ResourceFavoriteTimeEntry];
}

export const resourceFavoriteTimeEntryApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getResourceFavoriteTimeEntries: builder.query<IResourceFavoriteTimeEntriesResponse, { resourceId: number }>({
      query: ({ resourceId }) => ({
        url: apiRoutes.apiV1ResourceFavoriteTimeEntriesPath(resourceId),
        method: 'GET',
      }),
      providesTags: response => createTagsFromFavorites(response),
    }),
    createResourceFavoriteTimeEntry: builder.mutation<
      IResourceFavoriteTimeEntry,
      ICreateResourceFavoriteTimeEntryFormDataToSubmit
    >({
      query: ({ resourceId, formData }) => ({
        url: apiRoutes.apiV1ResourceFavoriteTimeEntriesPath(resourceId),
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: [TagTypes.ResourceFavoriteTimeEntry],
    }),

    deleteResourceFavoriteTimeEntry: builder.mutation<void, IDeleteResourceFavoriteTimeEntryQueryParameters>({
      query: ({ id, resourceId }) => ({
        url: apiRoutes.apiV1ResourceFavoriteTimeEntryPath(resourceId, id),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.ResourceFavoriteTimeEntry],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetResourceFavoriteTimeEntriesQuery,
  useCreateResourceFavoriteTimeEntryMutation,
  useDeleteResourceFavoriteTimeEntryMutation,
} = resourceFavoriteTimeEntryApi;
