import theme, { SxStyles } from 'theme';

const styles: SxStyles = {
  cell: {
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    px: 2,
    py: 1,
    bgcolor: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(16),
  },
  baselineCell: {
    verticalAlign: 'baseline',
    position: 'relative',
  },
  stickyCell: {
    position: 'sticky',
    top: 30,
  },
  stickyCellContent: {
    position: 'sticky',
    top: 42,
  },
  confirmed: {
    color: 'background.mint',
  },
  declined: {
    color: 'background.red',
  },
  requested: {
    color: 'background.orange',
  },
  none: {
    color: 'inherit',
  },
  boldBottomBorder: {
    borderBottomWidth: 2,
  },
  boldRightBorder: {
    borderRightWidth: 2,
  },
  hiddenBottomBorder: {
    borderBottom: 'none',
  },
  darkBackground: {
    bgcolor: 'background.lightGray',
  },
  levelType: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
  },
};

export default styles;
