import { FC } from 'react';

import { Tab } from '@mui/material';
import { Link } from 'react-router-dom';

interface ILinkTabProps {
  to: {
    path: string;
    hash: string;
    search?: string;
  };
  label: string;
  blockLabel: string;
  value: string;
}

const LinkTab: FC<ILinkTabProps> = props => {
  const { blockLabel, value, ...restProps } = props;
  return (
    <Tab
      id={`tab${blockLabel}`}
      component={Link}
      aria-controls={`tabpanel${blockLabel}`}
      value={value}
      {...restProps}
    />
  );
};

export default LinkTab;
