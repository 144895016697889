import { FC, useState, useMemo } from 'react';

import { IconButton, Menu, MenuItem, Typography, PopoverProps, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import UsersSelectPopover from 'components/common/UsersSelectPopover';

import { ProjectRole } from 'domain/project/enums';

import { getFullName } from 'utils/person';

import styles from './styles';
import { IInfoField, ITeamInfoDropDownProps } from './types';

const TeamInfoDropDown: FC<ITeamInfoDropDownProps> = props => {
  const { project } = props;
  const { id, productDirector, teamLead, projectManager, permissions } = project;
  const canUpdateManagementTeam = permissions.canUpdateManagementTeam;

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [currentRole, setCurrentRole] = useState<ProjectRole | null>(null);

  const isChooseUserOpen = Boolean(anchorElement);
  const handleUserSelectOpen = (role: ProjectRole) => {
    return (event: React.MouseEvent<HTMLElement>) => {
      setPopoverInitialized(true);
      setAnchorElement(event.currentTarget);
      setCurrentRole(role);
    };
  };
  const handleUserSelectClose = () => {
    setAnchorElement(null);
  };

  const { t } = useTranslation('reportHoursByProjects');

  const [teamInfoElement, setTeamInfoElement] = useState<null | HTMLElement>(null);
  const [isPopoverInitialized, setPopoverInitialized] = useState(false);

  const isTeamInfoOpen = Boolean(teamInfoElement);

  const handleTeamInfoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTeamInfoElement(event.currentTarget);
  };
  const handleMonthPickerClose = () => {
    setTeamInfoElement(null);
  };

  const infoFields: Array<IInfoField> = useMemo(
    () => [
      {
        title: t('table.teamInfoDropDown.fields.titles.productDirector'),
        content: getFullName(productDirector),
        role: ProjectRole.productDirector,
      },
      {
        title: t('table.teamInfoDropDown.fields.titles.teamLead'),
        content: getFullName(teamLead),
        role: ProjectRole.teamLead,
      },
      {
        title: t('table.teamInfoDropDown.fields.titles.projectManager'),
        content: getFullName(projectManager),
        role: ProjectRole.projectManager,
      },
    ],
    [project],
  );

  const popoverProps: PopoverProps = {
    open: isChooseUserOpen,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
  };

  return (
    <>
      <Tooltip arrow title={t('tooltips.managementTeam')}>
        <IconButton
          id={`team-info-button-${id}`}
          aria-controls={isTeamInfoOpen ? `team-info-menu-${id}` : undefined}
          aria-haspopup="true"
          aria-expanded={isTeamInfoOpen ? 'true' : undefined}
          onClick={handleTeamInfoClick}
          sx={styles.twoPeopleIconButton}
        >
          <Icon sx={styles.twoPeopleIcon} name="twoPeople" />
        </IconButton>
      </Tooltip>
      <Menu
        id={`team-info-menu-${id}`}
        anchorEl={teamInfoElement}
        open={isTeamInfoOpen}
        onClose={handleMonthPickerClose}
        MenuListProps={{
          'aria-labelledby': `team-info-button-${id}`,
        }}
      >
        <MenuItem disableRipple sx={styles.menuItem}>
          <Div sx={styles.root}>
            <Typography variant="h2" sx={styles.header}>
              {t('table.teamInfoDropDown.header')}
            </Typography>
            <Div sx={styles.infoFields}>
              {infoFields.map(infoField => {
                return (
                  <Div key={`${infoField.title}-${id}`} sx={styles.infoField}>
                    <Typography variant="subtitle3" sx={styles.infoFieldTitle}>
                      {infoField.title}
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      onClick={canUpdateManagementTeam ? handleUserSelectOpen(infoField.role) : () => {}}
                      sx={canUpdateManagementTeam ? styles.infoFieldContent : styles.infoFieldContentNonClickable}
                    >
                      {infoField.content}
                    </Typography>
                  </Div>
                );
              })}
            </Div>
            {isPopoverInitialized && (
              <UsersSelectPopover
                popoverProps={popoverProps}
                onClose={handleUserSelectClose}
                projectId={id}
                anchorElement={anchorElement}
                currentRole={currentRole}
              />
            )}
          </Div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default TeamInfoDropDown;
