import { TableRow, TableCell, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import MUISx from 'mui-sx';

import { getLastIndex } from 'utils/array';
import { isEven } from 'utils/number';

import { getColorSx, approvementToString, levelTypeToString } from './service';
import styles from './styles';
import { IRowProps } from './types';

const DEFAULT_ROW_SPAN = 1;

const Row = (props: IRowProps) => {
  const {
    isBottomBorderHidden,
    approvement: { id, approvingLevelType, approvingLevelName, state, possibleApprovers },
  } = props;

  const rowSpan = possibleApprovers.length || DEFAULT_ROW_SPAN;

  const [firstApprover, ...otherApprovers] = possibleApprovers;
  const firstApproverFullName = firstApprover?.fullName ?? '';
  const firstApproverState = firstApprover?.approvalState ?? '';

  const isBoldBottomBorder = isEmpty(otherApprovers);

  return (
    <>
      <TableRow key={id}>
        <TableCell
          rowSpan={rowSpan}
          sx={MUISx(styles.cell, styles.baselineCell, styles.boldBottomBorder, {
            condition: isBottomBorderHidden,
            sx: styles.hiddenBottomBorder,
          })}
        >
          <Typography variant="subtitle1" sx={styles.stickyCellContent}>
            <Typography component="span" sx={styles.levelType}>
              {levelTypeToString(approvingLevelType)}
            </Typography>
            : {approvingLevelName}
          </Typography>
        </TableCell>
        <TableCell
          rowSpan={rowSpan}
          sx={MUISx(
            styles.cell,
            styles.baselineCell,
            styles.boldRightBorder,
            styles.boldBottomBorder,
            getColorSx(state),
            {
              condition: isBottomBorderHidden,
              sx: styles.hiddenBottomBorder,
            },
          )}
        >
          <Typography variant="subtitle1" sx={styles.stickyCellContent}>
            {approvementToString(state)}
          </Typography>
        </TableCell>
        <TableCell
          sx={MUISx(
            styles.cell,
            { condition: isBoldBottomBorder, sx: styles.boldBottomBorder },
            { condition: isBottomBorderHidden, sx: styles.hiddenBottomBorder },
          )}
        >
          <Typography variant="subtitle1">{firstApproverFullName}</Typography>
        </TableCell>
        <TableCell
          sx={MUISx(
            styles.cell,
            getColorSx(firstApproverState),
            { condition: isBoldBottomBorder, sx: styles.boldBottomBorder },
            { condition: isBottomBorderHidden, sx: styles.hiddenBottomBorder },
          )}
        >
          <Typography variant="subtitle1">
            {firstApproverFullName && approvementToString(firstApproverState)}
          </Typography>
        </TableCell>
      </TableRow>
      {otherApprovers.map(({ id: approverId, fullName, approvalState }, rowIndex) => {
        const isBoldBottomBorder = rowIndex === getLastIndex(otherApprovers);
        const hasDarkBackground = isEven(rowIndex);

        return (
          <TableRow key={approverId}>
            <TableCell
              sx={MUISx(
                styles.cell,
                { condition: isBoldBottomBorder, sx: styles.boldBottomBorder },
                { condition: isBottomBorderHidden, sx: styles.hiddenBottomBorder },
                { condition: hasDarkBackground, sx: styles.darkBackground },
              )}
            >
              <Typography variant="subtitle1">{fullName}</Typography>
            </TableCell>
            <TableCell
              sx={MUISx(
                styles.cell,
                getColorSx(approvalState),
                { condition: isBoldBottomBorder, sx: styles.boldBottomBorder },
                { condition: isBoldBottomBorder, sx: styles.boldBottomBorder },
                { condition: hasDarkBackground, sx: styles.darkBackground },
              )}
            >
              <Typography variant="subtitle1">{approvementToString(approvalState)}</Typography>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default Row;
