import _ from 'lodash';

import { ICurrentUser } from 'domain/currentUser/types';
import { isSuperAdmin } from 'domain/user/service';

import { IFooterMenuItem, INavigationItem } from 'types/sidebar';

interface IGetAllMenuShowOptions {
  mainNavigationItems: Array<INavigationItem>;
  settingsNavigationItems: Array<INavigationItem>;
  currentUser: ICurrentUser;
}

export const isMenuShow = (menu: Array<INavigationItem | IFooterMenuItem>, currentUser: ICurrentUser): boolean => {
  if (isSuperAdmin(currentUser)) {
    return true;
  }

  return !_.isEmpty(
    menu.filter(item => {
      const accessUserTypes = _.isNil(item.accessUserTypes) ? item.route.protected : item.accessUserTypes;

      return accessUserTypes.includes(currentUser.userType);
    }),
  );
};

export const getAllMenuShow = (options: IGetAllMenuShowOptions) => {
  const { mainNavigationItems, settingsNavigationItems, currentUser } = options;
  return {
    isMainMenuShow: isMenuShow(mainNavigationItems, currentUser),
    isSettingsMenuShow: isMenuShow(settingsNavigationItems, currentUser),
  };
};
