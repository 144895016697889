import { configureStore } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { TagTypes } from 'shared/api/types';

import { axiosBaseQuery } from 'utils/axiosBaseQuery';

export const apiContainer = createApi({
  reducerPath: 'api',
  tagTypes: Object.values(TagTypes),
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
});

const { reducerPath, reducer, middleware } = apiContainer;

export const store = configureStore({
  reducer: {
    [reducerPath]: reducer,
  },
  middleware: getDefaultMiddleware => [...getDefaultMiddleware(), middleware],
});
