import { IMuiListType } from './types';

const MuiList: IMuiListType = {
  defaultProps: {},
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
};

export default MuiList;
