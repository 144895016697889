import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    marginY: 0,
    marginX: 1,
    width: '16px',
  },
  divider: {
    width: '100px',
    height: '2px',
    backgroundColor: 'text.primary',
  },
};

export default styles;
