import { SxStyles } from 'theme';

const styles: SxStyles = {
  hasEndDate: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: 2.125,
  },
  switchEndDate: {
    marginRight: 0,
  },
  fields: {
    display: 'flex',
    marginBottom: 4,
    paddingTop: 1.25,
  },
  datePickerField: {
    width: '160px',
    marginRight: 2,
  },
  input: {
    width: '75px',
  },
  changeAlloCationButton: {
    marginRight: 1.25,
  },
  placeholder: {
    color: 'text.gray',
  },
};

export default styles;
