import _ from 'lodash';

import { ReportVacationRequestsTableColumnAccessor } from 'components/pages/Report/Vacations/components/VacationRequests/components/VacationRequestItem/components/VacationRequestExpandedContent/components/VacationRequestTabs/components/VacationRequestTables/enums';

import { IReportVacationRequestInnerItem } from 'domain/report/vacationRequest/types';
import { ResourseVacationState } from 'domain/resource/vacation/enums';

import { Month } from 'enums/Months';

import { formatToDateWithFixedTimeZone, getMonthEnName, getRangeDates, isWeekendDay } from 'utils/dateTime';
import { getFullName } from 'utils/person';

import { IReportVacationsCalendarTableColoredAccessorData } from './types';

export const getColumns = (
  columnAccessor: string,
  weekendsAccessors: Array<string>,
  lastDayOfMonthAccessors: Array<string>,
  vacationAccessors: Array<string>,
) => {
  return {
    isResourcesColumn: columnAccessor === ReportVacationRequestsTableColumnAccessor.resources,
    isResourcesEmptyColumn: columnAccessor === `${ReportVacationRequestsTableColumnAccessor.resourcesEmpty}`,
    isResourcesEmptyDepthColumn: columnAccessor === `${ReportVacationRequestsTableColumnAccessor.resourcesEmptyDepth}`,
    isMonthHeaderColumn: Object.values(Month).includes(
      columnAccessor.slice(
        0,
        columnAccessor.includes('-empty') ? columnAccessor.indexOf('-') : columnAccessor.length,
      ) as Month,
    ),
    isDayColumn: !_.isNil(Object.values(Month).find(month => columnAccessor.startsWith(`${month}-`))),
    isWeekendColumn: weekendsAccessors.includes(columnAccessor),
    isLastDayOfMonthColumn: lastDayOfMonthAccessors.includes(columnAccessor),
    isVacationColumn: vacationAccessors.includes(columnAccessor),
  };
};

const generateAccessors = (rangeDates: Array<Date>) => {
  return rangeDates
    .filter(date => !isWeekendDay(date))
    .map(date => {
      const month = getMonthEnName(date).toLowerCase();
      const day = Number(date.getDate());
      return `${month}-${day}`;
    });
};

export const getColoredAccessors = (
  resources: Array<IReportVacationRequestInnerItem>,
): Array<IReportVacationsCalendarTableColoredAccessorData> => {
  return resources
    .filter(resource => !_.isEmpty(resource.vacations))
    .flatMap(resource => {
      return resource.vacations.map(vacation => {
        const startDate = formatToDateWithFixedTimeZone(vacation.startDate);
        const endDate = formatToDateWithFixedTimeZone(vacation.endDate);
        const rangeDates = getRangeDates(startDate, endDate);

        return {
          id: vacation.id,
          accessors: generateAccessors(rangeDates),
          state: vacation.state,
          resourceFullName: getFullName(resource),
        };
      });
    });
};

const getColumnColorState = (
  currentAccessors: Array<IReportVacationsCalendarTableColoredAccessorData>,
  resourceRowFullName: string,
  state: ResourseVacationState,
) => {
  return !_.isEmpty(
    currentAccessors.filter(
      coloredAccessor => coloredAccessor.state === state && coloredAccessor.resourceFullName === resourceRowFullName,
    ),
  );
};

export const getColumnColor = (
  coloredAccessors: Array<IReportVacationsCalendarTableColoredAccessorData>,
  columnAccessor: string,
  resourceRowFullName: string,
) => {
  const currentAccessors = coloredAccessors.filter(coloredAccessor =>
    coloredAccessor.accessors.includes(columnAccessor),
  );

  const isRequestedState = getColumnColorState(currentAccessors, resourceRowFullName, ResourseVacationState.onApproval);
  const isCompletedState = getColumnColorState(currentAccessors, resourceRowFullName, ResourseVacationState.completed);
  const isApprovedState = getColumnColorState(currentAccessors, resourceRowFullName, ResourseVacationState.approved);

  return {
    isCompleted: !_.isEmpty(currentAccessors) && isCompletedState,
    isApproved: !_.isEmpty(currentAccessors) && isApprovedState,
    isRequested: !_.isEmpty(currentAccessors) && isRequestedState,
  };
};
