import { FC, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateTrackingTypeMutation } from 'domain/trackingType/apiSlice';
import {
  IUpdateTrackingTypeFormData,
  initialValues,
  buildValidationSchema,
  formatAttributesToSubmit,
} from 'domain/trackingType/schemas/update';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { IUpdateTrackingTypeProps } from './types';

const UpdateTrackingTypeForm: FC<IUpdateTrackingTypeProps> = props => {
  const { trackingType } = props;

  const { closeModal } = useContext(ModalContext);

  const { t } = useTranslation('adminPanel');

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [updateTrackingType, { isLoading: isUpdateTrackingTypeLoading }] = useUpdateTrackingTypeMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateTrackingTypeFormData>({
    defaultValues: initialValues(trackingType),
    resolver: yupResolver(buildValidationSchema()),
  });

  const onSubmit = async (formData: IUpdateTrackingTypeFormData) => {
    try {
      await updateTrackingType(formatAttributesToSubmit(trackingType.id, formData)).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.root}>
        <TextField
          {...register('name')}
          sx={styles.input}
          label={t('trackingTypes.mainListItem.innerListItem.updateTrackingTypeForm.name.label')}
          InputLabelProps={{ sx: styles.placeholder }}
          error={Boolean(errors.name)}
          disabled={isUpdateTrackingTypeLoading}
          helperText={buildErrorMessage(t, errors.name)}
        />

        <Div sx={styles.buttons}>
          <Button type="submit" disabled={isUpdateTrackingTypeLoading}>
            {t('trackingTypes.mainListItem.innerListItem.updateTrackingTypeForm.buttons.edit')}
          </Button>
          <Button type="reset" variant="outlined" onClick={closeModal} disabled={isUpdateTrackingTypeLoading}>
            {t('trackingTypes.mainListItem.innerListItem.updateTrackingTypeForm.buttons.cancel')}
          </Button>
        </Div>
      </Div>
    </form>
  );
};

export default UpdateTrackingTypeForm;
