import { FC, useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import _ from 'lodash';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CreateProjectAndTaskButton from 'components/common/CreateProjectAndTaskButton';
import CreateTagsButton from 'components/common/CreateTagsButton';
import Div from 'components/common/Div';
import TimeTrackerTrackableDuration from 'components/common/TimeTrackerTrackableDuration';

import { ModalContext } from 'contexts/ModalContext';

import { useCreateResourceFavoriteTimeEntryMutation } from 'domain/resource/favoriteTimeEntry/apiSlice';
import {
  initialValues,
  ICreateResourceFavoriteTimeEntryFormData,
  validationSchema,
} from 'domain/resource/favoriteTimeEntry/schemas/create';
import { ITag } from 'domain/tag/types';

import { EntityAction } from 'enums/EntityAction';
import { KeyboardKeys } from 'enums/KeyboardKeys';

import { useNotifications } from 'hooks/useNotifications';

import { IDuration } from 'types/dates';
import { ITimeTrackerTrackableState } from 'types/timeTrackerTrackable';

import { getMinutesFromHours } from 'utils/dateTime';
import { generateBackendErrorMessages } from 'utils/error';

import styles from './styles';
import { ICreateResourceFavoriteEntityFieldProps } from './types';

const CreateFavoriteForm: FC<ICreateResourceFavoriteEntityFieldProps> = props => {
  const { resourceId } = props;

  const { t } = useTranslation('timeTracker');

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [currentTimeTrackerTrackable, setCurrentTimeTrackerTrackable] = useState<ITimeTrackerTrackableState | null>({
    trackable: null,
    task: null,
  });
  const [currentTimeTrackerTags, setCurrentTimeTrackerTags] = useState<Array<ITag>>([]);

  const [duration, setDuration] = useState<IDuration | null>({ hours: '00', minutes: '00' });
  const [createResourceFavoriteEntity, { isLoading: isCreateResourceFavoriteEntityLoading }] =
    useCreateResourceFavoriteTimeEntryMutation();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<ICreateResourceFavoriteTimeEntryFormData>({
    defaultValues: initialValues(),
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (errors.description) {
      showErrorNotification(errors.description.message);
    }
  }, [errors.description]);

  useEffect(() => {
    if (errors.trackable) {
      showErrorNotification(errors.trackable.message);
    }
  }, [errors.trackable]);

  const {
    field: { value: description, onChange: onDescriptionChange },
  } = useController({ name: 'description', control });

  const {
    field: { value: trackable, onChange: onTrackableChange },
  } = useController({ name: 'trackable', control });

  const { closeModal } = useContext(ModalContext);

  const onSubmit = handleSubmit(async () => {
    const durationMinutes = getMinutesFromHours(Number(duration.hours)) + Number(duration.minutes);
    const currentTrackable = trackable?.trackable;
    const formData = {
      description,
      duration: durationMinutes,
      projectTaskId: trackable?.task?.id,
      trackableId: currentTrackable?.id,
      trackableType: currentTrackable?.type,
      tagIds: currentTimeTrackerTags.map(({ id }) => id),
    };

    try {
      await createResourceFavoriteEntity({ resourceId, formData }).unwrap();
      setValue('description', '');
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      const errorMessage = errors.join('\n');
      showErrorNotification(errorMessage);
    } finally {
      closeModal();
    }
  });

  const handleCurrentTimeTrackerTrackableChange = (currentTimeTrackerTrackable: ITimeTrackerTrackableState) => {
    if (_.isEmpty(currentTimeTrackerTrackable.trackable)) {
      onTrackableChange(null);
      return;
    }
    onTrackableChange(currentTimeTrackerTrackable);
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KeyboardKeys.enter) {
      await onSubmit();
    }
  };

  return (
    <Div sx={styles.root}>
      <Div onKeyDown={handleKeyDown} sx={styles.input}>
        <TextField
          autoFocus
          sx={styles.input}
          {...register('description')}
          type="text"
          value={description}
          onChange={onDescriptionChange}
          label={t('createResourceTrackedEntityField.inputPlaceholder')}
          error={Boolean(errors.description)}
        />
      </Div>

      <Div sx={styles.actions}>
        <Div sx={styles.action}>
          <CreateProjectAndTaskButton
            currentTimeTrackerTrackable={currentTimeTrackerTrackable}
            setCurrentTimeTrackerTrackable={setCurrentTimeTrackerTrackable}
            isDisabled={isCreateResourceFavoriteEntityLoading}
            onCurrentTimeTrackerTrackableChange={handleCurrentTimeTrackerTrackableChange}
          />
        </Div>

        <Div sx={styles.action}>
          <CreateTagsButton
            currentTags={currentTimeTrackerTags}
            setCurrentTags={setCurrentTimeTrackerTags}
            isDisabled={isCreateResourceFavoriteEntityLoading}
          />
        </Div>

        <Div sx={styles.action} onKeyDown={handleKeyDown}>
          <TimeTrackerTrackableDuration
            duration={duration}
            setDuration={setDuration}
            isDisabled={isCreateResourceFavoriteEntityLoading}
            type={EntityAction.create}
          />
        </Div>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          {t('favoriteTrackedTimeEntriesBar.createFavoriteTimeEntry.save')}
        </Button>
      </Div>
    </Div>
  );
};

export default CreateFavoriteForm;
