import { Skeleton, Stack, Grid } from '@mui/material';
import _ from 'lodash';

import Div from 'components/common/Div';

import styles from './styles';

const UserFormSkeleton = () => {
  const renderUserFormSkeleton = () => {
    return _.range(0, 8, 1).map(counter => (
      <Div key={counter}>
        <Grid container>
          <Grid>
            <Skeleton animation="pulse" variant="rectangular" sx={styles.row} />
          </Grid>
          <Grid>
            <Skeleton animation="pulse" variant="rectangular" sx={styles.row} />
          </Grid>
        </Grid>
      </Div>
    ));
  };

  return <Stack sx={styles.wrapper}>{renderUserFormSkeleton()}</Stack>;
};

export default UserFormSkeleton;
