import { Month } from 'enums/Months';

import { getCountDaysOfMonth, getFirstAndLastDateOfMonthForYear, getRangeDates, isWeekendDay } from 'utils/dateTime';

import { IMonthItem } from './types';

export const getMonthsHeadersData = (year: number): Array<IMonthItem> => {
  return Object.values(Month).reduce((accumulator, month, index) => {
    const daysCount = getCountDaysOfMonth(year, index);

    const { firstDate, lastDate } = getFirstAndLastDateOfMonthForYear(year, index);
    const rangeDates = getRangeDates(firstDate, lastDate);

    const item = {
      title: month,
      daysCount: daysCount,
      weekendAccessors: rangeDates.filter(date => isWeekendDay(date)).map(date => `${month}-${Number(date.getDate())}`),
      lastDayAccessor: `${month}-${Number(lastDate.getDate())}`,
    };
    return [...accumulator, item];
  }, []);
};
