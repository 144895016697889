import React, { FC } from 'react';

import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { useGetCompanySettingsQuery } from 'domain/company/settings/apiSlice';

import CompanySettings from './components/CompanySettings';
import styles from './styles';

const CompanyInfo: FC = () => {
  const { t } = useTranslation('adminPanel');

  const {
    data: settingsData,
    isLoading: isCompaniesDataLoading,
    isError: isCompaniesDataLoadingError,
  } = useGetCompanySettingsQuery();

  if (isCompaniesDataLoading) {
    return <Loader />;
  }

  if (isCompaniesDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  return (
    <Div sx={styles.tagsListContainer}>
      <CompanySettings setting={settingsData['company/setting']} />
    </Div>
  );
};

export default CompanyInfo;
