import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { DayType } from 'domain/holiday/calendar/day/enums';
import { ICalendarDay } from 'domain/holiday/calendar/year/types';

export interface IUpdateHolidayCalendarDayFormData {
  dayType: DayType;
  description: string;
}

export interface IUpdateHolidayCalendarFormDataToSubmit {
  formData: IUpdateHolidayCalendarDayFormData;
  dayId: number;
}

const requiredValidation = 'forms:validation:required';

export const buildValidationFields = () => ({
  dayType: yup.mixed<DayType>().required(i18n.t(requiredValidation)).default(DayType.holiday),
  description: yup
    .string()
    .max(30, i18n.t('forms:validation:maxSymbols', { count: 30 }))
    .required(i18n.t(requiredValidation))
    .default(''),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (values: ICalendarDay | null) => {
  if (_.isNil(values)) {
    return buildValidationSchema().getDefault();
  }

  return {
    dayType: values.dayType,
    description: values.description,
  };
};

export const formatAttributesToSubmit = (values: IUpdateHolidayCalendarDayFormData, dayId: number) => {
  const { dayType, description = null } = values;

  return {
    dayId,
    formData: {
      dayType,
      description,
    },
  };
};
