import { FC, useContext, useState } from 'react';

import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import Tree from 'components/common/Tree';
import { TreeType } from 'components/common/Tree/enums';
import SearchBar from 'components/pages/AdminPanel/components/SearchBar';

import { ModalContext } from 'contexts/ModalContext';

import { useDeleteTechnologyMutation, useGetTechnologiesQuery } from 'domain/technology/apiSlice';

import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';
import { getSortParameter } from 'utils/runsack';

import CreateTechnologyForm from './components/CreateTechnologyForm';
import UpdateTechnologyForm from './components/UpdateTechnologyForm';
import styles from './styles';

const Specialization: FC = () => {
  const { getConfirmation } = useConfirmDialog();

  const [searchString, setSearchString] = useState<string>('');

  const [debouncedSearchString] = useDebounce(searchString, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: technologiesData,
    isLoading: isTechnologiesDataLoading,
    isError: isTechnologiesDataLoadingError,
  } = useGetTechnologiesQuery({
    nameCont: debouncedSearchString,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const { t } = useTranslation('adminPanel');

  const { openModal } = useContext(ModalContext);

  const [deleteTechnology, { isLoading: isDeleteTechnologyLoading }] = useDeleteTechnologyMutation();

  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const handleEditTechnologyModalOpen = (technologyId: number, parentId: number) => {
    const currentTechnology = technologiesData.technologies.find(technology => technology.id === technologyId);
    const currentParentTechnology = technologiesData.technologies.find(
      parentTechnology => parentTechnology.id === parentId,
    );

    openModal({
      title: t('specializations.edit'),
      content: (
        <UpdateTechnologyForm currentTechnology={currentTechnology} currentParentTechnology={currentParentTechnology} />
      ),
    });
  };

  const handleAddTechnologyModalOpen = (parentId: number = null) => {
    const currentTechnology = technologiesData.technologies.find(technology => technology.id === parentId) ?? null;

    openModal({
      title: t('specializations.add'),
      content: <CreateTechnologyForm currentTechnology={currentTechnology} />,
    });
  };

  const handleTechnologyDelete = async (technologyId: number, technologyName: string) => {
    if (
      await getConfirmation({
        title: '',
        message: t('specializations.deleteMessage', { technologyName }),
      })
    ) {
      try {
        await deleteTechnology(technologyId).unwrap();
        showSuccessNotification();
      } catch (error) {
        const errors = generateBackendErrorMessages(error);
        for (const message of errors) {
          showErrorNotification(message);
        }
      }
    }
  };

  if (isTechnologiesDataLoading) {
    return <Loader />;
  }

  if (isTechnologiesDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  return (
    <Div sx={styles.container}>
      <Div>
        <SearchBar
          searchString={searchString}
          setSearchString={setSearchString}
          placeholder={t('specializations.searchBar')}
          buttonLabel={t('specializations.addButton')}
          onAddNodeButtonClick={handleAddTechnologyModalOpen}
          buttonWidth={125}
        />

        <Div sx={styles.specificationsTreeContainer}>
          <Tree
            data={technologiesData.technologies}
            onAddModalOpen={handleAddTechnologyModalOpen}
            onEditModalOpen={handleEditTechnologyModalOpen}
            onNodeDeactivate={handleTechnologyDelete}
            isLoading={isDeleteTechnologyLoading}
            type={TreeType.technologies}
          />
        </Div>
      </Div>
    </Div>
  );
};

export default Specialization;
