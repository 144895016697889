export default {
  title: 'Time Tracker',
  totalInfo: {
    today: 'Today:',
    currentWeekTotal: 'Current Week Total:',
    pastWeekTotal: 'Past Week Total:',
  },
  trackedTimeEntriesGroupList: {
    loadMoreButton: 'Load More',
    lockedPeriod: "Locked Period — You CAN'T change and delete entries older than this date",
    totalWeek: 'Total week {{weekNumber}}',
    week: 'Week {{weekNumber}}',
    currentWeek: 'Week {{weekNumber}} (current)',
  },
  trackedTimeEntry: {
    projectChip: {
      billable: '$',
    },
  },
  createProjectAndTaskButton: {
    button: 'Project & Task',
    autocomplete: {
      placeholder: 'Search Project',
      projectTasksMenu: {
        project: 'Project',
        tasks: 'Tasks',
        backToProjectsButton: 'Back to Projects',
      },
      typeGroup: {
        projects: 'Projects',
        trackingTypes: 'Tracking Types',
      },
    },
  },
  createResourceTrackedEntityField: {
    inputPlaceholder: 'What are you working on?',
    timeTrackerTrackableDatePicker: {
      buttons: {
        apply: 'Apply',
        cancel: 'Cancel',
      },
    },
  },
  favoriteTrackedTimeEntriesBar: {
    favorites: 'Favorites',
    createFavoriteTimeEntry: {
      title: 'Create Favorite Time Entry',
      save: 'Save',
    },
    deleteMessage: 'Do you really want to remove the favorite time entry?',
    favoritePopover: {
      favorite: 'Favorite',
      buttons: {
        apply: 'Apply',
        remove: 'Remove',
      },
    },
  },
  createTagsButton: {
    button: 'Tag',
    autocomplete: {
      placeholder: 'Search Tag',
      tagsTitle: 'Tags',
    },
  },
  deleteTimeEntryDialog: {
    title: 'Remove time entity',
    remove: 'Remove',
    cancel: 'Cancel',
  },
};
