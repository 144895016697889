import React, { createContext, useContext, ReactNode } from 'react';

interface ISkillLevelAccessContextProps {
  children: ReactNode;
  canUpdateSkillLevel: boolean;
}

const SkillLevelAccessContext = createContext<{ canUpdateSkillLevel: boolean } | undefined>(undefined);

const SkillLevelAccessProvider: React.FC<ISkillLevelAccessContextProps> = ({ children, canUpdateSkillLevel }) => {
  return (
    <SkillLevelAccessContext.Provider value={{ canUpdateSkillLevel }}>{children}</SkillLevelAccessContext.Provider>
  );
};

export const useSkillLevelAccess = () => {
  const context = useContext(SkillLevelAccessContext);
  if (context === undefined) {
    throw new Error('useSkillLevelAccess must be used within a SkillLevelAccessProvider');
  }
  return context;
};

export default SkillLevelAccessProvider;
