import _ from 'lodash';

import { IMultipleSlot, ISlotValue } from 'components/common/SmartFilter/types';

export const getInitialValue = (initialValue: ISlotValue, defaultValue: string, VALUES: Record<string, unknown>) => {
  if (_.isNil(initialValue)) {
    return defaultValue;
  }

  return Object.entries(VALUES)
    .find(([_, value]) => value === (initialValue as IMultipleSlot).name)
    .at(0);
};
