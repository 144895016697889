import { FC, useState } from 'react';

import { List, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { EntityAction } from 'enums/EntityAction';

import HolidayCalendarListItem from './components/HolidayCalendarListItem';
import styles from './styles';
import { ICalendarListProps } from './types';

const CalendarList: FC<ICalendarListProps> = props => {
  const { years, selectedYearId, onYearSelect } = props;
  const { t } = useTranslation('adminPanel');

  const [isCreateFormVisible, setIsCreateFormVisible] = useState<boolean>(false);
  const handleCreateClick = () => setIsCreateFormVisible(true);
  const handleCancelClick = () => setIsCreateFormVisible(false);

  return (
    <>
      <List>
        {years.map(year => (
          <HolidayCalendarListItem
            key={year.id}
            year={year}
            type={EntityAction.update}
            selectedYearId={selectedYearId}
            onYearSelect={onYearSelect}
          />
        ))}
        {isCreateFormVisible && (
          <HolidayCalendarListItem
            key="new"
            type={EntityAction.create}
            onCancel={handleCancelClick}
            onCreate={handleCancelClick}
          />
        )}
      </List>
      <Button onClick={handleCreateClick} sx={styles.createButton} variant="text">
        <Typography variant="heading4">{t('workingCalendars.addNew')}</Typography>
      </Button>
    </>
  );
};

export default CalendarList;
