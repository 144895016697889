import { FC, useEffect, useState, useCallback } from 'react';

import { Typography } from '@mui/material';
import camelize from 'camelize';
import decamelize from 'decamelize';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks';
import appRoutes from 'routes/appRoutes';

import Div from 'components/common/Div';
import ExportButton from 'components/common/ExportButton';
import Loader from 'components/common/Loader';
import ReportFiltersButton from 'components/common/ReportFiltersButton';
import SmartFilter from 'components/common/SmartFilter';
import { pickFieldsForSmartFilter } from 'components/common/SmartFilter/service';
import { ISmartFilter } from 'components/common/SmartFilter/types';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { useLazyGetExportQuery } from 'domain/export/apiSlice';
import { ReportType } from 'domain/report/filter/enums';
import { IReportFilter } from 'domain/report/filter/types';
import {
  IReportHoursByResourcesSmartFilters,
  useCreateCsvReportHoursByResourcesMutation,
  useCreateXlsxReportHoursByResourcesMutation,
  useGetReportHoursByResourcesQuery,
} from 'domain/report/hoursByResource/apiSlice';
import { IReportHoursByResource, IReportHoursByResourceProjectItem } from 'domain/report/hoursByResource/types';

import { GroupByPeriodFilter } from 'enums/GroupByPeriodFilter';
import { HoursType } from 'enums/HoursType';
import { PtoType } from 'enums/PtoType';

import { useNotifications } from 'hooks/useNotifications';
import { usePermissions } from 'hooks/usePermissions';

import { IPaginationParameters } from 'types/api';
import { ISortParameter } from 'types/runsack';

import { dateToString, stringToDate } from 'utils/calendar';
import { getTimeRanges } from 'utils/dateTime';
import { generateBackendErrorMessages } from 'utils/error';
import { fileDownload } from 'utils/file';
import { defaultPaginationParameters } from 'utils/pagination';
import { getSortParameter } from 'utils/runsack';

import Filter from './components/Filter';
import Table from './components/Table';
import {
  makeParametersForResourceNameClick,
  makeParametersForProjectActualHoursClick,
  makeParametersForResourceActualHoursClick,
  makeParametersForResourcePlannedHoursClick,
} from './service';
import styles from './styles';
import { IHoursByResourcesFilters } from './types';

const FILTERS: Array<ISmartFilter> = [
  'trackable',
  'resource',
  'group',
  'utilization',
  'allocation',
  'state',
  'technology',
  'actualHours',
  'plannedHours',
  'groupByPeriod',
];

const REPORT_TYPE = ReportType.hoursByResources;

const HoursByResources: FC = () => {
  const { buildPath, queryStringUpdate, queryStringClear, camelizedQuery } = useRouter();
  const { data: currentUser } = useGetCurrentUserQuery();

  const timeRanges = getTimeRanges(currentUser?.user?.weekStartsDay ?? 1);

  const [filters, setFilters] = useState<IHoursByResourcesFilters>({
    startDate: (camelizedQuery.startDate && stringToDate(camelizedQuery.startDate)) || timeRanges.startOfMonth,
    endDate: (camelizedQuery.endDate && stringToDate(camelizedQuery.endDate)) || timeRanges.endOfMonth,
    isDetailedInfo: camelizedQuery.detailed === 'true',
    hoursType: (camelizedQuery.hoursType ?? HoursType.all) as HoursType,
    pto: camelize(camelizedQuery.pto) || PtoType.all,
  });

  const [smartFilter, setSmartFilter] = useState<IReportHoursByResourcesSmartFilters | null>(
    pickFieldsForSmartFilter(camelizedQuery, FILTERS),
  );
  const [paginationParameters, setPaginationParameters] = useState<IPaginationParameters>({
    pageNumber: Number(camelizedQuery.page) || defaultPaginationParameters.pageNumber,
    pageSize: Number(camelizedQuery.perPage) || defaultPaginationParameters.pageSize,
  });

  const { t } = useTranslation('reportHoursByResources');

  const { showErrorNotification } = useNotifications();

  const { isRoleFeatureAllowed } = usePermissions();

  const getMappedSmartFilters = useCallback(
    (smartFilter: IReportHoursByResourcesSmartFilters | null) => {
      const { trackables, ...rest } = smartFilter ?? {};
      const trackablesMapped = trackables?.map(trackable => {
        const { id, type } = trackable;
        return { id, type };
      });
      return { trackables: trackablesMapped, ...rest };
    },
    [smartFilter],
  );

  const getReportHoursByResourcesQueryFiltersParameters = {
    detailed: true,
    perPage: paginationParameters.pageSize,
    page: paginationParameters.pageNumber,
    startDate: dateToString(filters.startDate),
    endDate: dateToString(filters.endDate),
    sort: (camelizedQuery.sort as ISortParameter) || getSortParameter('name', 'asc'),
    hoursType: filters.hoursType,
  };

  useEffect(() => {
    const parameters = {
      ...getReportHoursByResourcesQueryFiltersParameters,
      ...getMappedSmartFilters(smartFilter),
      detailed: filters.isDetailedInfo,
      page: paginationParameters.pageNumber,
      perPage: paginationParameters.pageSize,
      pto: decamelize(filters.pto),
    };

    queryStringUpdate(parameters);
  }, [filters, smartFilter, paginationParameters]);

  const {
    data: reportHoursbyResources,
    isLoading: isReportHoursbyResourcesLoading,
    isFetching: isReportHoursByResourcesFetching,
    isSuccess: isReportHoursbyResourcesLoadingSuccess,
    isError: isReportHoursbyResourcesLoadingError,
  } = useGetReportHoursByResourcesQuery({
    ...getReportHoursByResourcesQueryFiltersParameters,
    ...getMappedSmartFilters(smartFilter),
    pto: decamelize(filters.pto) as PtoType,
  });

  const [createReportHoursByResourcesCsv, { isLoading: isCreateReportHoursByResourcesCsvLoading }] =
    useCreateCsvReportHoursByResourcesMutation();

  const [exportReportHoursByResourcesCsv, { isLoading: isExportReportHoursByResourcesCsvLoading }] =
    useLazyGetExportQuery();

  const [createReportHoursByResourcesXlsx, { isLoading: isCreateReportHoursByResourcesXlsxLoading }] =
    useCreateXlsxReportHoursByResourcesMutation();

  const [exportReportHoursByResourcesXlsx, { isLoading: isExportReportHoursByResourcesXlsxLoading }] =
    useLazyGetExportQuery();

  const pagination = {
    totalPages: reportHoursbyResources?.meta.totalPages,
    pageSize: reportHoursbyResources?.meta.perPage,
    pageNumber: reportHoursbyResources?.meta.currentPage,
  };

  if (isReportHoursbyResourcesLoading) {
    return <Loader />;
  }

  const handleSmartFiltersChange = (filters: IReportHoursByResourcesSmartFilters | null) => {
    setSmartFilter(filters);
    setPaginationParameters({ pageNumber: defaultPaginationParameters.pageNumber, pageSize: pagination.pageSize });
  };

  const handleCsvDownload = async () => {
    try {
      const {
        export: { id: exportId },
      } = await createReportHoursByResourcesCsv({
        ...getReportHoursByResourcesQueryFiltersParameters,
        ...smartFilter,
        pto: decamelize(filters.pto) as PtoType,
      }).unwrap();

      const reportHoursByResourcesCsv = await exportReportHoursByResourcesCsv(exportId).unwrap();

      fileDownload(reportHoursByResourcesCsv);
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleXlsxDownload = async () => {
    try {
      const {
        export: { id: exportId },
      } = await createReportHoursByResourcesXlsx({
        ...getReportHoursByResourcesQueryFiltersParameters,
        ...smartFilter,
      }).unwrap();

      const reportHoursByResourcesXlsx = await exportReportHoursByResourcesXlsx(exportId).unwrap();

      fileDownload(reportHoursByResourcesXlsx);
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleReportFiltersChange = (reportFilters: IReportFilter | null) => {
    const filter = JSON.parse(reportFilters.filter);
    handleSmartFiltersChange(filter);
  };

  const buildPathForResourceActualHoursClick = (
    resource: IReportHoursByResource,
    options: { startDate: string; endDate: string },
  ) => {
    const parameters = makeParametersForResourceActualHoursClick(resource, options);
    return buildPath(appRoutes.reportTrackedTimeEntries.path(), parameters);
  };

  const buildPathForResourcePlannedHoursClick = (
    resource: IReportHoursByResource,
    options: { startDate: string; endDate: string },
  ) => {
    const parameters = makeParametersForResourcePlannedHoursClick(resource, options);
    return buildPath(appRoutes.allocation.path(), parameters);
  };

  const buildPathForProjectActualHoursClick = (
    resource: IReportHoursByResource,
    project: IReportHoursByResourceProjectItem,
    billable: boolean,
    options: { startDate: string; endDate: string },
  ) => {
    const parameters = makeParametersForProjectActualHoursClick(resource, project, billable, options);
    return buildPath(appRoutes.reportTrackedTimeEntries.path(), parameters);
  };

  const buildPathForResourceNameClick = (resource: IReportHoursByResource) => {
    const parameters = makeParametersForResourceNameClick(filters, resource);
    return buildPath(appRoutes.reportTrackedTimeEntries.path(), parameters);
  };

  const buildPathForProjectNameClick = (project: IReportHoursByResourceProjectItem) => {
    return project ? buildPath(appRoutes.reportHoursByProject.path(project.id), {}) : null;
  };

  const isDisabled =
    isCreateReportHoursByResourcesCsvLoading ||
    isExportReportHoursByResourcesCsvLoading ||
    isCreateReportHoursByResourcesXlsxLoading ||
    isExportReportHoursByResourcesXlsxLoading;

  const hasAccessToData = isRoleFeatureAllowed('canViewHoursByResourcesReport');

  return (
    <>
      <Div sx={styles.titleBlock}>
        <Typography variant="h1">{t('title')}</Typography>
        <Div sx={styles.buttons}>
          <ExportButton isDisabled={isDisabled} onCsvDownload={handleCsvDownload} onXlsxDownload={handleXlsxDownload} />
          <ReportFiltersButton onChange={handleReportFiltersChange} currentReportType={REPORT_TYPE} />
        </Div>
      </Div>

      <Div sx={styles.smartFilter}>
        <SmartFilter<IReportHoursByResourcesSmartFilters>
          smartFilter={smartFilter}
          onSmartFilterChange={handleSmartFiltersChange}
          filters={FILTERS}
          onReset={queryStringClear}
          reportType={REPORT_TYPE}
        />
      </Div>
      <Filter filters={filters} setFilters={setFilters} resourcesTotalCount={reportHoursbyResources?.meta.totalCount} />
      {isReportHoursbyResourcesLoadingSuccess && (
        <>
          <Table
            data={reportHoursbyResources}
            isError={isReportHoursbyResourcesLoadingError}
            isDetailedInfo={filters.isDetailedInfo}
            setPaginationParameters={setPaginationParameters}
            pagination={pagination}
            isLoading={isReportHoursByResourcesFetching}
            hoursType={filters.hoursType}
            groupByPeriod={smartFilter ? smartFilter.groupByPeriodEq : GroupByPeriodFilter.wholePeriod}
            pto={filters.pto}
            buildPathForResourceNameClick={buildPathForResourceNameClick}
            buildPathForProjectNameClick={buildPathForProjectNameClick}
            buildPathForResourceActualHoursClick={buildPathForResourceActualHoursClick}
            buildPathForResourcePlannedHoursClick={buildPathForResourcePlannedHoursClick}
            buildPathForProjectActualHoursClick={buildPathForProjectActualHoursClick}
            hasAccessToData={hasAccessToData}
          />
        </>
      )}
    </>
  );
};

export default HoursByResources;
