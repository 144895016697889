import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import { getLastIndex } from 'utils/array';

import Row from './components/Row';
import styles from './styles';
import { IApprovementsTableProps } from './types';

const ApprovementsTable = (props: IApprovementsTableProps) => {
  const { approvements } = props;

  const { t } = useTranslation('reportVacations');

  return (
    <TableContainer sx={styles.tableContainer}>
      <Table stickyHeader padding="none">
        <TableHead>
          <TableRow>
            <TableCell sx={styles.headerCell}>
              <Typography variant="subtitle2" sx={styles.cellTitle}>
                {t('approvements.titles.approvingLevel')}
              </Typography>
            </TableCell>
            <TableCell sx={MUISx(styles.headerCell, styles.boldRightBorder)}>
              <Typography variant="subtitle2" sx={styles.cellTitle}>
                {t('approvements.titles.status')}
              </Typography>
            </TableCell>
            <TableCell sx={styles.headerCell}>
              <Typography variant="subtitle2" sx={styles.cellTitle}>
                {t('approvements.titles.approver')}
              </Typography>
            </TableCell>
            <TableCell sx={styles.headerCell}>
              <Typography variant="subtitle2" sx={styles.cellTitle}>
                {t('approvements.titles.action')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {approvements.map((approvement, rowIndex) => {
            const isBottomBorderHidden = rowIndex === getLastIndex(approvements);

            return <Row key={approvement.id} isBottomBorderHidden={isBottomBorderHidden} approvement={approvement} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ApprovementsTable;
