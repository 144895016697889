import { FC } from 'react';

import Div from 'components/common/Div';

import { SxStyles } from 'theme';

import { ITabPanelProps } from './types';

const styles: SxStyles = {
  panel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
};

const TabPanel: FC<ITabPanelProps> = props => {
  const { children, value, name, sx, tabGroupName } = props;

  return (
    <Div
      role={`tabpanel${tabGroupName}`}
      hidden={value !== name}
      id={`tabpanel${tabGroupName}-${name}`}
      aria-labelledby={`tab${tabGroupName}-${name}`}
      sx={styles.panel}
    >
      {value === name && <Div sx={sx}>{children}</Div>}
    </Div>
  );
};

export default TabPanel;
