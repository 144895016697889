import * as yup from 'yup';

import i18n from 'locales/i18n';

export interface IResetPasswordFormData {
  email: string;
}

export const buildValidationFields = () => ({
  email: yup.string().email(i18n.t('forms:validation.email')).required(i18n.t('forms:validation.required')).default(''),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());
