import * as yup from 'yup';

import i18n from 'locales/i18n';

import { ICompanySettings } from 'domain/company/settings/types';

import { formatToDateWithFixedTimeZone } from 'utils/dateTime';

export interface IUpdateCompanySettingFormData {
  trackedTimeEditLockDate: Date;
  defaultHoursPerWeek: number;
  defaultExpectedBillablePercentage: number;
  trackedTimeDuplicationCheck: string;
  defaultPrecisionForDuplicationCheck: number;
  trackedTimeUniquenessCheckStrategy: string;
}

export interface IUpdateCompanySettingFormDataToSubmit {
  formData: IUpdateCompanySettingFormData;
}

const requiredValidation = 'forms:validation:required';

const dateFormatValidation = 'forms:validation:dateFormat';

export const buildValidationFields = {
  trackedTimeEditLockDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .required(i18n.t(requiredValidation))
    .default(new Date()),
};

export const buildValidationSchema = yup.object(buildValidationFields);

export const initialValues = (settings: ICompanySettings): IUpdateCompanySettingFormData => ({
  trackedTimeEditLockDate: formatToDateWithFixedTimeZone(settings.trackedTimeEditLockDate),
  defaultHoursPerWeek: settings.defaultHoursPerWeek,
  defaultExpectedBillablePercentage: settings.defaultExpectedBillablePercentage,
  trackedTimeDuplicationCheck: settings.trackedTimeDuplicationCheck,
  defaultPrecisionForDuplicationCheck: settings.defaultPrecisionForDuplicationCheck,
  trackedTimeUniquenessCheckStrategy: settings.trackedTimeUniquenessCheckStrategy,
});

export const formatAttributesToUpdateSubmit = (
  formData: IUpdateCompanySettingFormData,
): IUpdateCompanySettingFormDataToSubmit => {
  return {
    formData,
  };
};
