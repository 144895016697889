import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IGroup } from 'domain/group/types';
import { IHolidayCalendar } from 'domain/holiday/calendar/types';

const requiredValidation = 'forms:validation:required';
export interface ICreateGroupFormData {
  name: string;
  group: IGroup;
  calendar: IHolidayCalendar;
}

export interface ICreateGroupFormDataToSubmit {
  parentId: number | null;
  name: string;
  calendarId: number;
}

export const buildValidationFields = () => ({
  name: yup.string().required(i18n.t(requiredValidation)).default(''),
  group: yup.mixed<IGroup>().nullable().default(null),
  calendar: yup.mixed<IHolidayCalendar>().required(i18n.t(requiredValidation)).nullable().default(null),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (group: IGroup) => {
  if (_.isNil(group)) {
    return buildValidationSchema().getDefault();
  }

  return {
    name: '',
    group,
    calendar: group.calendar,
  };
};

export const formatAttributesToSubmit = (formData: ICreateGroupFormData): ICreateGroupFormDataToSubmit => {
  const { name, group, calendar } = formData;

  const parentId = _.isNil(group) ? null : group.id;

  return {
    name,
    parentId,
    calendarId: calendar.id,
  };
};
