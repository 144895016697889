import _ from 'lodash';

import { IUserProfileAccessLevel } from './types';

export interface IUserProfileAccessLevelsGroupedByAccessType {
  [accessType: string]: Array<IUserProfileAccessLevel>;
}

export const getUserProfileAccessLevelsGroupedByAccessType = (
  userProfileAccessLevels: Array<IUserProfileAccessLevel>,
): IUserProfileAccessLevelsGroupedByAccessType => {
  return _.groupBy(userProfileAccessLevels, userProfileAccessLevel => {
    return userProfileAccessLevel.accessibleType;
  });
};
