import { FC } from 'react';

import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import { MenuItem, Pagination, PaginationItem, Select, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { PER_PAGE_OPTIONS } from 'utils/pagination';

import styles from './styles';
import { ITablePaginationProps } from './types';

const TablePagination: FC<ITablePaginationProps> = props => {
  const { isDataEmpty, pageCount, pageIndex, pageSize, handleCurrentPageChange, handlePageSizeChange } = props;

  const { t } = useTranslation('table');

  return (
    !isDataEmpty && (
      <Stack direction="row" sx={styles.paginationWrapper}>
        <Div sx={styles.pageNumbers}>
          {pageCount > 1 && (
            <Pagination
              shape="rounded"
              size="medium"
              count={pageCount || 0}
              page={pageIndex + 1}
              onChange={handleCurrentPageChange}
              renderItem={item => (
                <PaginationItem
                  components={{
                    previous: () => (
                      <Stack direction="row" sx={styles.paginationButton}>
                        <ChevronLeftOutlined />
                        <Typography variant="heading3">{t('previous')}</Typography>
                      </Stack>
                    ),
                    next: () => (
                      <Stack direction="row" sx={styles.paginationButton}>
                        <Typography variant="heading3">{t('next')}</Typography>
                        <ChevronRightOutlined />
                      </Stack>
                    ),
                  }}
                  {...item}
                />
              )}
            />
          )}
        </Div>
        <Stack sx={styles.perPage}>
          <Select
            sx={styles.selector}
            onChange={handlePageSizeChange}
            value={pageSize ?? ''}
            defaultValue={PER_PAGE_OPTIONS.at(0)}
            disabled={pageCount < 2}
          >
            {PER_PAGE_OPTIONS.map(pageSize => (
              <MenuItem key={pageSize} value={pageSize}>
                {pageSize}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="heading3">{t('itemsPerPage')}</Typography>
        </Stack>
      </Stack>
    )
  );
};

export default TablePagination;
