import { FC, useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import _ from 'lodash';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import TechnologiesAutocomplete from 'components/common/TechnologiesAutocomplete';

import { ModalContext } from 'contexts/ModalContext';

import { useGetTechnologiesQuery } from 'domain/technology/apiSlice';
import { ITechnology } from 'domain/technology/types';
import { useUpdateVacancyMutation } from 'domain/vacancy/apiSlice';
import {
  initialValues,
  buildValidationSchema,
  formatAttributesToSubmit,
  IUpdateVacancyFormData,
} from 'domain/vacancy/schemas/update';

import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';

import styles from './styles';
import { IUpdateVacancyTechnologiesFormProps } from './types';

const UpdateVacancyTechnologiesForm: FC<IUpdateVacancyTechnologiesFormProps> = props => {
  const { projectId, groupId, vacancy } = props;

  const { t } = useTranslation(['common', 'vacancyUsageActions']);
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const { closeModal } = useContext(ModalContext);

  const [technologyName, setTechnologyName] = useState<string>('');

  const [debouncedTechnologyName] = useDebounce(technologyName, DEFAULT_DEBOUNCE_DELAY_MS);

  const [updateVacancy, { isLoading: isUpdateVacancyLoading }] = useUpdateVacancyMutation();

  const {
    data,
    isLoading: isTechnologiesLoading,
    isFetching: isTehcnologiesFetching,
  } = useGetTechnologiesQuery({ nameCont: debouncedTechnologyName });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IUpdateVacancyFormData>({
    defaultValues: initialValues(vacancy),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: technologies, onChange: onTechnologiesChange },
  } = useController({ name: 'technologies', control });

  const onSubmit = async (formData: IUpdateVacancyFormData) => {
    try {
      await updateVacancy(formatAttributesToSubmit(projectId, groupId, vacancy.id, formData)).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const handleCurrentTechnologiesChange = (technologies: Array<ITechnology>) => {
    if (_.isEmpty(technologies)) {
      onTechnologiesChange([]);
      return;
    }
    onTechnologiesChange(technologies);
  };

  if (isTechnologiesLoading) {
    return <Loader />;
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.technologiesAutocomplete}>
        <TechnologiesAutocomplete
          technologies={data.technologies}
          currentTechnologies={technologies}
          onCurrentTechnologiesChange={handleCurrentTechnologiesChange}
          setTechnologyName={setTechnologyName}
          isError={Boolean(errors.technologies)}
          isTechnologiesFetching={isTehcnologiesFetching}
          label={t('vacancyActions:vacancyStack')}
        />
      </Div>

      <Div>
        <Button type="submit" disabled={isUpdateVacancyLoading} sx={styles.saveButton}>
          {t('vacancyActions:editVacancyTechnologiesForm.buttons.save')}
        </Button>
        <Button type="reset" variant="outlined" onClick={closeModal} disabled={isUpdateVacancyLoading}>
          {t('vacancyActions:editVacancyTechnologiesForm.buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default UpdateVacancyTechnologiesForm;
