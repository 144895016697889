import { FC, useEffect, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { CheckBox, CheckBoxOutlineBlank, SearchOutlined } from '@mui/icons-material';
import { TextField, Checkbox, FormControlLabel, Typography } from '@mui/material';
import _ from 'lodash';
import { SubmitHandler, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IMultipleSlot } from 'components/common/SmartFilter/types';

import { buildErrorMessage } from 'utils/form';

import { initialValues, buildValidationSchema, IDescriptionFormData } from './schema';
import styles from './styles';
import { IDescriptionProps } from './types';

const onSubmit: SubmitHandler<IDescriptionFormData> = () => {};

const Description: FC<IDescriptionProps> = props => {
  const { slot, onSlotPatch } = props;

  const initialDescription = _.isNil(slot.value) ? null : (slot.value as IMultipleSlot).name;
  const initialBlank = _.isNil((slot?.value as IMultipleSlot)?.blank) ? false : (slot.value as IMultipleSlot).blank;

  const { t } = useTranslation('smartFilter');

  const descriptionInputElement = useRef(null);

  useEffect(() => {
    descriptionInputElement.current?.select();
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IDescriptionFormData>({
    mode: 'onChange',
    defaultValues: initialValues({ description: initialDescription, blank: initialBlank }),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: blank, onChange: onBlankChange },
  } = useController({ name: 'blank', control });

  const {
    field: { value: description, onChange: onDescriptionChange },
  } = useController({ name: 'description', control });

  useEffect(() => {
    if (blank) {
      onDescriptionChange('');
    }
  }, [blank]);

  useEffect(() => {
    handleSlotPatch();
  }, [blank, description]);

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onDescriptionChange(event.target.value);
    onBlankChange(false);
  };

  const handleSlotPatch = () => {
    if (blank) {
      onSlotPatch(slot.id, { value: { id: null, name: null, blank: true } });
      return;
    }

    const isDescriptionValueError = Boolean(errors.description);
    const descriptionValue = isDescriptionValueError ? null : description;

    const currentDescription =
      _.isNil(descriptionValue) || _.isEmpty(descriptionValue) ? null : { id: null, name: descriptionValue };
    onSlotPatch(slot.id, { value: currentDescription });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="smart-filter-description-form">
      <TextField
        fullWidth
        inputRef={descriptionInputElement}
        autoFocus
        placeholder={`${t('description.placeholder')}...`}
        value={description ?? ''}
        onChange={handleDescriptionChange}
        onFocus={event => {
          event.target.select();
        }}
        error={Boolean(errors.description)}
        helperText={buildErrorMessage(t, errors.description)}
        InputProps={{
          startAdornment: <SearchOutlined sx={styles.searchIcon} />,
        }}
      />

      <FormControlLabel
        sx={styles.checkbox}
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
            checked={blank}
            onChange={onBlankChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label={
          <Typography sx={styles.checkboxLabel} variant="subtitle1">
            {t('description.blankLabel')}
          </Typography>
        }
      />
    </form>
  );
};

export default Description;
