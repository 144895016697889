import { TableHead, TableRow, TableCell, Typography } from '@mui/material';
import { format, getYear, isEqual } from 'date-fns';
import MUISx from 'mui-sx';

import { getFirstDate, getLastDate } from 'components/pages/Report/Vacations/components/Calendar/service';

import { FIRST_ITEM_INDEX, getLastIndex } from 'utils/array';
import { FULL_DATE_FORMAT, YEAR_MONTH_FORMAT } from 'utils/dateTime';

import DayCell from './components/DayCell';
import MonthCell from './components/MonthCell';
import { STICKY_CELLS } from './service';
import styles from './styles';
import { IHeaderProps } from './types';

const MONTH_CELL_ITEM = 1;

const Header = (props: IHeaderProps) => {
  const { isWideRange, cellReferences, stickyCellsPositions, dateRangesByMonth } = props;

  const dateRangeStartDate = getFirstDate(dateRangesByMonth);
  const dateRangeEndDate = getLastDate(dateRangesByMonth);
  const startYear = getYear(dateRangeStartDate);

  const handleCellReferenceAdd = (node: HTMLTableCellElement) => {
    const hasAllReferences = cellReferences.current.length === STICKY_CELLS.length + MONTH_CELL_ITEM;

    if (node && !hasAllReferences) {
      cellReferences.current = [...cellReferences.current, node];
    }
  };

  return (
    <TableHead sx={styles.header}>
      <TableRow>
        {STICKY_CELLS.map((column, columnIndex) => {
          const { id, variant, cellSx, titleSx, rowSpan, getColumnTitle } = column;
          const isLastCell = columnIndex === getLastIndex(STICKY_CELLS);

          return (
            <TableCell
              key={id}
              id={id}
              sx={MUISx(cellSx, {
                condition: isLastCell,
                sx: styles.lastCell,
              })}
              style={stickyCellsPositions[columnIndex]}
              rowSpan={rowSpan}
              ref={handleCellReferenceAdd}
            >
              <Typography variant={variant} sx={titleSx}>
                {getColumnTitle(id, startYear)}
              </Typography>
            </TableCell>
          );
        })}
        {dateRangesByMonth.map((daysInMonth, monthIndex) => {
          const monthStartDate = daysInMonth[FIRST_ITEM_INDEX];
          const monthCellKey = format(monthStartDate, YEAR_MONTH_FORMAT);
          const isLastMonth = monthIndex === getLastIndex(dateRangesByMonth);
          const isRightBorderHidden = isLastMonth && isWideRange;

          return (
            <MonthCell
              key={monthCellKey}
              isRightBorderHidden={isRightBorderHidden}
              daysInMonth={daysInMonth}
              onCellReferenceAdd={handleCellReferenceAdd}
            />
          );
        })}
      </TableRow>
      <TableRow>
        {dateRangesByMonth.map(daysInMonth => {
          const monthEndDate = daysInMonth[getLastIndex(daysInMonth)];
          const cellPositionStyle = stickyCellsPositions[getLastIndex(stickyCellsPositions)];

          return daysInMonth.map(date => {
            const isMonthEndDate = isEqual(date, monthEndDate);
            const isDateRangeEndDate = isEqual(date, dateRangeEndDate);
            const isRightBorderHidden = isDateRangeEndDate && isWideRange;
            const dayCellKey = format(date, FULL_DATE_FORMAT);

            return (
              <DayCell
                key={dayCellKey}
                date={date}
                cellPositionStyle={cellPositionStyle}
                isMonthEndDate={isMonthEndDate}
                isRightBorderHidden={isRightBorderHidden}
              />
            );
          });
        })}
      </TableRow>
    </TableHead>
  );
};

export default Header;
