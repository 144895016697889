import { FC } from 'react';

import Div from 'components/common/Div';

import Aside from './components/Aside';
import styles from './styles';
import { IAuthLayoutProps } from './types';

const AuthLayout: FC<IAuthLayoutProps> = props => {
  const { children } = props;

  return (
    <Div sx={styles.root}>
      <Div sx={styles.outerWrapper}>
        <Div sx={styles.innerWrapper}>
          <Aside />
          <Div sx={styles.content}>{children}</Div>
        </Div>
      </Div>
    </Div>
  );
};

export default AuthLayout;
