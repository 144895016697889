import { FC, useState, useEffect } from 'react';

import _ from 'lodash';
import { DateRangePicker as RangePicker } from 'react-date-range';

import { useScreen } from 'hooks';

import Div from 'components/common/Div';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';

import { KeyboardKeys } from 'enums/KeyboardKeys';

import { getDatePickerLocale, getIsStaticRange, getStaticRanges, RangeType } from 'utils/dateRangePicker';

import { useStaticDateRangeEffect } from '../../service';
import { DateRange } from '../../types';

import styles from './styles';
import { IDateRangePickerTypeProps } from './types';

const DatePicker: FC<IDateRangePickerTypeProps> = props => {
  const { dateRange: initialDateRange, onDateRangeChange, onClose } = props;
  const [dateRange, setDateRange] = useState<Array<DateRange>>(initialDateRange);
  const { data: currentUser } = useGetCurrentUserQuery();

  const { lessThanTablet } = useScreen();
  const months = lessThanTablet ? 1 : 2;

  const handleApplyDateRange = (range: Array<DateRange>) => {
    setDateRange(range);
    onDateRangeChange(range);
  };
  const handleDateRangeChange = payload => {
    const isStaticRangeSelected = getIsStaticRange(staticRanges, payload);

    const newDateRange = {
      ..._.head(dateRange),
      ...payload.selection,
      // if  user selection is a custom range we need to manually update the rangeType
      ...(!isStaticRangeSelected && { rangeType: RangeType.custom }),
    };

    handleApplyDateRange([newDateRange]);
  };

  const handleKeyDown = event => {
    if (event.code === KeyboardKeys.enter) {
      onClose?.();
    }
  };
  useEffect(() => {
    window.document.addEventListener('keydown', handleKeyDown);
    return () => {
      window.document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const datePickerLocale = getDatePickerLocale();
  const weekStartsDay = currentUser?.user?.weekStartsDay ?? 1;
  const [staticRanges, setStaticRanges] = useState<Array<StaticRange>>(getStaticRanges(weekStartsDay));
  useStaticDateRangeEffect(setStaticRanges, weekStartsDay);

  return (
    <Div sx={styles.root}>
      <RangePicker
        onChange={handleDateRangeChange}
        moveRangeOnFirstSelection={false}
        months={months}
        ranges={dateRange}
        direction="horizontal"
        preventSnapRefocus
        calendarFocus="backwards"
        rangeColors={['#43BDF1']}
        monthDisplayFormat="MMMM"
        weekdayDisplayFormat="EEEEEE"
        locale={datePickerLocale}
        staticRanges={staticRanges}
        weekStartsOn={currentUser?.user?.weekStartsDay}
      />
    </Div>
  );
};

export default DatePicker;
