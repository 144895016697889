export default {
  title: 'Login',
  form: {
    emailField: {
      placeholder: 'Email@dualbootpartners.com',
    },
    passwordField: {
      placeholder: 'Password',
    },
    loginButton: 'Log In',
    resetButton: 'Reset Password',
  },
};
