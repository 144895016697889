import { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useScreen } from 'hooks';

import Div from 'components/common/Div';

import styles from './styles';

const ImpersonationBadge: FC = () => {
  const { t } = useTranslation('sidebar');

  const { lessThanDesktop } = useScreen();

  const label = lessThanDesktop
    ? t('currentUserBadge.impersonationBadge.impersonatedAsShort')
    : t('currentUserBadge.impersonationBadge.impersonatedAs');

  return (
    <Div sx={styles.impersonation}>
      <Typography sx={styles.impersonationText} variant="subtitle4">
        {label}
      </Typography>
    </Div>
  );
};

export default ImpersonationBadge;
