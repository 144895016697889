import { FC, useState } from 'react';

import { ExpandMoreOutlined } from '@mui/icons-material';
import { Autocomplete, Chip, CircularProgress, TextField, Typography } from '@mui/material';

import Icon from 'components/common/Icon';

import { ITechnology } from 'domain/technology/types';

import styles from './styles';
import { ITechnologiesAutocompleteProps } from './types';

const TechnologiesAutocomplete: FC<ITechnologiesAutocompleteProps> = props => {
  const {
    currentTechnologies,
    onCurrentTechnologiesChange,
    technologies,
    isError,
    setTechnologyName,
    isTechnologiesFetching,
    label,
    isRequired = false,
    helperText = '',
  } = props;

  const [isAutoCompleteOpen, setIsAutocompleteOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const handleTechnologiesAutocompleteChange = (value: string) => {
    if (value !== inputValue) {
      setInputValue(value);
      setTechnologyName(value);
    }
  };

  const handleAutocompleteOpen = () => {
    setIsAutocompleteOpen(true);
  };

  const handleAutocompleteClose = () => {
    setIsAutocompleteOpen(false);
  };

  return (
    <Autocomplete
      open={isAutoCompleteOpen}
      onOpen={handleAutocompleteOpen}
      onClose={handleAutocompleteClose}
      popupIcon={<ExpandMoreOutlined />}
      multiple
      autoComplete
      getOptionLabel={(technology: ITechnology) => technology.ancestorsPath ?? ''}
      isOptionEqualToValue={(option: ITechnology, value: ITechnology) => option.id === value.id}
      options={technologies}
      sx={styles.select}
      onChange={(_, technologies: Array<ITechnology>) => onCurrentTechnologiesChange(technologies)}
      value={currentTechnologies}
      onInputChange={(_, value) => handleTechnologiesAutocompleteChange(value)}
      renderInput={parameters => (
        <TextField
          required={isRequired}
          helperText={helperText}
          error={isError}
          {...parameters}
          label={label}
          InputLabelProps={{ sx: styles.selectPlaceholder }}
          InputProps={{
            ...parameters.InputProps,
            endAdornment: (
              <>
                {isTechnologiesFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {parameters.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, technology: ITechnology) => {
        return (
          <Typography {...props} key={technology.id}>
            {technology.ancestorsPath}
          </Typography>
        );
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => {
          return (
            <Chip
              key={option.id}
              deleteIcon={<Icon sx={styles.closeChipIcon} name="closeWithoutCircle" />}
              sx={styles.chip}
              {...getTagProps({ index })}
              label={<Typography variant="subtitle2">{option.ancestorsPath}</Typography>}
            />
          );
        });
      }}
    />
  );
};
export default TechnologiesAutocomplete;
