import { FC, useContext } from 'react';

import { Button, ListItem, Typography, List } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import TagForm from 'components/pages/AdminPanel/components/Tags/components/TagForm';

import { ModalContext } from 'contexts/ModalContext';

import { useActivateTagMutation, useDeleteTagMutation, useUpdateTagMutation } from 'domain/tag/apiSlice';
import { IUpdateTagFormData, formatAttributesToUpdateSubmit } from 'domain/tag/schemas/form';
import { getTagState } from 'domain/tag/service';
import { ITag } from 'domain/tag/types';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';

import styles from './styles';
import { ITagsListProps } from './types';

const TagsList: FC<ITagsListProps> = props => {
  const { tags } = props;

  const { t } = useTranslation('adminPanel');

  const { openModal, closeModal } = useContext(ModalContext);

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [activateTag, { isLoading: isActivateTagLoading }] = useActivateTagMutation();
  const [deleteTag, { isLoading: isDeleteTagLoading }] = useDeleteTagMutation();
  const [updateTag, { isLoading: isUpdateTagLoading }] = useUpdateTagMutation();

  const handleActivateButtonClick = async (id: number) => {
    try {
      await activateTag(id).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleArchiveButtonClick = async (id: number) => {
    try {
      await deleteTag(id).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleEditButtonClick = (id: number) => async (formData: IUpdateTagFormData) => {
    try {
      await updateTag(formatAttributesToUpdateSubmit(id, formData)).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const handleRenameButtonClick = (tag: ITag) => () => {
    openModal({
      title: t('tags.updateTagForm.title'),
      content: (
        <TagForm
          onSubmit={handleEditButtonClick(tag.id)}
          disabled={isUpdateTagLoading}
          submitButtonLabel={t('buttons.edit')}
          tag={tag}
        />
      ),
    });
  };

  const getButtonProps = tag => {
    const { isArchived, isActive } = getTagState(tag);

    const label = isArchived ? t('buttons.activate') : t('buttons.archive');

    const handleClick = isArchived ? () => handleActivateButtonClick(tag.id) : () => handleArchiveButtonClick(tag.id);

    const style = MUISx(
      { condition: isActive, sx: styles.archiveButton },
      { condition: isArchived, sx: styles.activateButton },
    );

    return {
      label,
      handleClick,
      style,
    };
  };

  const isLoading = isActivateTagLoading || isDeleteTagLoading;

  return (
    <List>
      {tags.map(tag => {
        const { isArchived } = getTagState(tag);
        const buttonProps = getButtonProps(tag);
        return (
          <ListItem key={tag.id}>
            <Icon name="listPointer" sx={styles.listPointer} />

            <Div sx={MUISx(styles.textContainer, { condition: isArchived, sx: styles.archived })}>
              <Typography variant="subtitle1">{tag.name}</Typography>

              {isArchived && (
                <Typography variant="subtitle3" sx={styles.archivedTitle}>
                  {t('tags.disabled')}
                </Typography>
              )}

              <Div data-actions="item-actions" sx={styles.actions}>
                <Button
                  variant="text"
                  type="button"
                  onClick={handleRenameButtonClick(tag)}
                  disabled={isArchived || isLoading}
                >
                  {t('buttons.rename')}
                </Button>
                <Button variant="text" disabled={isLoading} sx={buttonProps.style} onClick={buttonProps.handleClick}>
                  <Typography variant="subtitle3">{buttonProps.label}</Typography>
                </Button>
              </Div>
            </Div>
          </ListItem>
        );
      })}
    </List>
  );
};

export default TagsList;
