import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

export const projectAllocations = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getProjectAllocations: builder.query({
      query: ({ ...parameters }) => ({
        url: apiRoutes.apiV1ProjectAllocationsPath(parameters?.projectId),
        method: 'GET',
        params: {
          ...parameters,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetProjectAllocationsQuery } = projectAllocations;
