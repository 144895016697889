import i18next from 'locales/i18n';

import { StickyCellKey } from 'components/pages/Report/Vacations/components/Calendar/components/VacationTable/types';

import { ONE_YEAR } from 'utils/dateTime';

import styles from './styles';
import { IStickyCell } from './types';

const TWO_ROWS = 2;

const getColumnTitle = (key: string, year?: number): string =>
  i18next.t(`calendar.tableHeaders.${key}`, { ns: 'reportVacations', count: year });

export const STICKY_CELLS: IStickyCell[] = [
  {
    id: StickyCellKey.Resource,
    variant: 'body2',
    cellSx: styles.firstCell,
    titleSx: styles.title,
    rowSpan: TWO_ROWS,
    getColumnTitle,
  },
  {
    id: StickyCellKey.CarryoverPrevious,
    variant: 'h6',
    cellSx: styles.cell,
    titleSx: styles.verticalTitle,
    rowSpan: TWO_ROWS,
    getColumnTitle: (key: string, year: number) => getColumnTitle(key, year - ONE_YEAR),
  },
  {
    id: StickyCellKey.Available,
    variant: 'h6',
    cellSx: styles.cell,
    titleSx: styles.verticalTitle,
    rowSpan: TWO_ROWS,
    getColumnTitle,
  },
  {
    id: StickyCellKey.Scheduled,
    variant: 'h6',
    cellSx: styles.cell,
    titleSx: styles.verticalTitle,
    rowSpan: TWO_ROWS,
    getColumnTitle,
  },
  {
    id: StickyCellKey.CarryoverNext,
    variant: 'h6',
    cellSx: styles.cell,
    titleSx: styles.verticalTitle,
    rowSpan: TWO_ROWS,
    getColumnTitle: (key: string, year: number) => getColumnTitle(key, year + ONE_YEAR),
  },
];
