import { FC, useMemo } from 'react';

import DateRangePicker from 'components/common/DateRangePicker';
import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { useGetReportVacationsQuery } from 'domain/report/vacation/apiSlice';

import { getDateRange, RangeType } from 'utils/dateRangePicker';
import { formatToDateWithFixedTimeZone } from 'utils/dateTime';

import TableLegend from './components/TableLegend';
import VacationTable from './components/VacationTable';
import { buildValuesToSubmit, groupDateRangeByMonth } from './service';
import styles from './styles';
import { IVacationsCalendarProps } from './types';

const Calendar: FC<IVacationsCalendarProps> = props => {
  const { filters, onDateRangeChange } = props;

  const { data: reportVacations, isLoading, isFetching } = useGetReportVacationsQuery(buildValuesToSubmit(filters));

  const { startDate, endDate } = filters;

  const dateRangesByMonth = useMemo(() => groupDateRangeByMonth(startDate, endDate), [startDate, endDate]);

  const dateRangeStartDate = formatToDateWithFixedTimeZone(startDate);
  const dateRangeEndDate = formatToDateWithFixedTimeZone(endDate);
  const currentDateRange = getDateRange(dateRangeStartDate, dateRangeEndDate, RangeType.month);

  if (isLoading || !reportVacations) {
    return <Loader />;
  }

  return (
    <Div sx={styles.calendar}>
      <Div sx={styles.controlPanel}>
        <TableLegend />
        <DateRangePicker currentDateRange={currentDateRange} onDateRangeChange={onDateRangeChange} />
      </Div>
      <VacationTable
        isFetching={isFetching}
        resources={reportVacations.resources}
        dateRangesByMonth={dateRangesByMonth}
      />
    </Div>
  );
};

export default Calendar;
