import { FC } from 'react';

import Div from 'components/common/Div';

import { generateDays } from 'utils/calendar';

import Day from './components/Day';
import styles from './styles';
import { IWeekProps } from './types';

const Week: FC<IWeekProps> = props => {
  const {
    week,
    month,
    vacations,
    onSelectionStart,
    onSelectionEnd,
    startSelection,
    endSelection,
    weekStartsOn,
    interactionMode,
    onDayClick,
    holidays,
  } = props;

  const options = { holidays, vacations, weekStartsOn };
  const days = generateDays(week, month, options);

  return (
    <Div sx={styles.root}>
      {days.map(({ day, show, label, vacation, roundLeft, roundRight, dateDetails }) => (
        <Day
          startSelection={startSelection}
          endSelection={endSelection}
          onSelectionStart={onSelectionStart}
          onSelectionEnd={onSelectionEnd}
          dateDetails={dateDetails}
          show={show}
          label={label}
          day={day}
          key={day.toJSON()}
          isRoundLeft={roundLeft}
          isRoundRight={roundRight}
          interactionMode={interactionMode}
          onDayClick={onDayClick}
          vacation={vacation}
        />
      ))}
    </Div>
  );
};

export default Week;
