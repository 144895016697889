import { ICurrentUser } from 'domain/currentUser/types';
import { UserType } from 'domain/user/enums';
import { isSuperAdmin } from 'domain/user/service';

export const isRouteAllowed = (currentUser: ICurrentUser, protectedUserTypes: Array<UserType>) => {
  if (isSuperAdmin(currentUser)) {
    return true;
  }

  return protectedUserTypes.includes(currentUser.userType);
};
