import * as yup from 'yup';

import i18n from 'locales/i18n';

export interface ICreateHolidayCalendarFormData {
  name: string;
}

export interface ICreateHolidayCalendarFormDataToSubmit {
  formData: ICreateHolidayCalendarFormData;
}

const requiredValidation = 'forms:validation:required';

export const buildValidationFields = () => ({
  name: yup
    .string()
    .max(30, i18n.t('forms:validation:maxSymbols', { count: 30 }))
    .required(i18n.t(requiredValidation))
    .default(''),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (values: ICreateHolidayCalendarFormData) => {
  const { name } = values;

  return {
    formData: {
      name,
    },
  };
};
