import { FC, useState, useContext } from 'react';

import { MoreHorizOutlined } from '@mui/icons-material';
import { IconButton, Menu, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import ConditionalMenuItem from 'components/common/ConditionalMenuItem';

import { ModalContext } from 'contexts/ModalContext';

import { getFullName } from 'utils/person';

import UpdateResourceUsageEndDateForm from './components/UpdateResourceUsageEndDateForm';
import UpdateResourceUsageTechnologiesForm from './components/UpdateResourceUsageTechnologiesForm';
import styles from './styles';
import { IResourceUsageActionsProps } from './types';

const ResourceUsageActions: FC<IResourceUsageActionsProps> = props => {
  const { resourceUsage, project } = props;

  const { t } = useTranslation('resourceUsageActions');

  const { openModal } = useContext(ModalContext);

  const [resourceUsageActionsMenu, setResourceUsageActionsMenu] = useState<null | HTMLElement>(null);

  const isResourceUsageActionsMenuOpen = Boolean(resourceUsageActionsMenu);

  const handleResourceUsageActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setResourceUsageActionsMenu(event.currentTarget);
  };

  const handleResourceUsageActionsClose = () => {
    setResourceUsageActionsMenu(null);
  };

  const fullName = getFullName(resourceUsage);

  const handleResourceUsageUpdateModalOpen = () => {
    openModal({
      title: t('editResourceUsageModalTitle'),
      subtitle: `${fullName} - ${project.name}`,
      content: <UpdateResourceUsageTechnologiesForm projectId={project.id} resourceUsage={resourceUsage} />,
    });
    setResourceUsageActionsMenu(null);
  };

  const handleResourceUsageRemoveEndDateModalOpen = () => {
    openModal({
      title: t('removeResourceUsageModalTitle'),
      subtitle: (
        <Typography variant="subtitle1" component="span">
          <Trans
            t={t}
            i18nKey="removeResourceUsageModalSubtitle"
            values={{ resourceUsageFullName: fullName, projectName: project.name }}
            components={{
              1: <Typography variant="heading3" component="span" />,
              2: <br />,
              3: <Typography variant="heading3" component="span" />,
            }}
          ></Trans>
        </Typography>
      ),
      content: <UpdateResourceUsageEndDateForm projectId={project.id} resourceUsage={resourceUsage} />,
    });
    setResourceUsageActionsMenu(null);
  };

  const canEditAllocation = resourceUsage?.permissions.canEditAllocation;

  return (
    <>
      <IconButton
        sx={styles.moreHorizButton}
        id={`actions-button-${resourceUsage.id}`}
        aria-controls={isResourceUsageActionsMenuOpen ? `actions-menu-${resourceUsage.id}` : undefined}
        aria-expanded={isResourceUsageActionsMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleResourceUsageActionsClick}
      >
        <MoreHorizOutlined sx={styles.moreHorizIcon} />
      </IconButton>
      <Menu
        id={`actions-menu-${resourceUsage.id}`}
        anchorEl={resourceUsageActionsMenu}
        open={isResourceUsageActionsMenuOpen}
        onClose={handleResourceUsageActionsClose}
        MenuListProps={{
          'aria-labelledby': `actions-button-${resourceUsage.id}`,
        }}
      >
        <ConditionalMenuItem
          canEdit={canEditAllocation}
          onClick={handleResourceUsageUpdateModalOpen}
          sx={null}
          title={t('edit')}
          tooltip={t('notAvailable')}
        />
        <ConditionalMenuItem
          canEdit={canEditAllocation}
          onClick={handleResourceUsageRemoveEndDateModalOpen}
          sx={styles.removeResourceAction}
          title={t('remove')}
          tooltip={t('notAvailable')}
        />
      </Menu>
    </>
  );
};

export default ResourceUsageActions;
