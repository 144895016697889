export default {
  values: {
    mobile: 0,
    fablet: 375,
    tablet: 768,
    desktop: 1024,
    largeScreen: 1440,
  },
};

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    fablet: true;
    tablet: true;
    desktop: true;
    largeScreen: true;
  }
}
