import { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Popover, Button } from '@mui/material';
import _ from 'lodash';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { useUpdateProjectMutation } from 'domain/project/apiSlice';
import {
  formatAttributesToSubmit,
  IUpdateProjectUserRolesFormData,
  initialValues,
  buildValidationSchema,
} from 'domain/project/schemas/updateUserRoles';
import { loadingGetUsersParameters, useGetUsersQuery } from 'domain/user/apiSlice';
import { IUser } from 'domain/user/types';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { getSortParameter } from 'utils/runsack';

import UsersAutocomplete from './components/UsersAutocomplete';
import styles from './styles';
import { IUsersSelectPopoverProps } from './types';

const UsersSelectPopover: FC<IUsersSelectPopoverProps> = props => {
  const { popoverProps, onClose: handleUserSelectClose, projectId, anchorElement, currentRole } = props;

  const { t } = useTranslation('common');
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [userName, setUserName] = useState<string>('');
  const [debouncedUserName] = useDebounce(userName, 500);

  const [updateProject, { isLoading }] = useUpdateProjectMutation();

  const getUsersQueryParameters = loadingGetUsersParameters({
    nameOrEmailCont: debouncedUserName,
    sort: getSortParameter('name', 'asc'),
  });
  const { data: users, isLoading: isUsersLoading, isFetching, isError } = useGetUsersQuery(getUsersQueryParameters);

  const { handleSubmit, control } = useForm<IUpdateProjectUserRolesFormData>({
    defaultValues: initialValues(),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { onChange: onUserChange, value: user },
  } = useController({ name: 'user', control });

  const handleCurrentUserChange = (currentUser: IUser) => {
    if (_.isNil(currentUser)) {
      onUserChange(null);
      return;
    }
    onUserChange(currentUser);
  };

  const onSubmit = async (formData: IUpdateProjectUserRolesFormData) => {
    const parameters = formatAttributesToSubmit(formData, currentRole, projectId);
    try {
      await updateProject(parameters).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      handleUserSelectClose();
      onUserChange(null);
      setUserName('');
    }
  };

  const buttonLabel = _.isNil(user) ? t('leaveVacant') : t('assign');

  if (isUsersLoading) {
    return <Loader />;
  }

  return (
    <Popover anchorEl={anchorElement} onClose={handleUserSelectClose} {...popoverProps}>
      <Div sx={styles.popover}>
        <UsersAutocomplete
          currentValue={user}
          onCurrentValueChange={handleCurrentUserChange}
          options={users.users}
          isError={isError}
          isFetching={isFetching}
          setName={setUserName}
          label={t('searchUser')}
        />
        <Div sx={styles.buttonContainer} onClick={handleSubmit(onSubmit)}>
          <Button sx={styles.closeButton} variant="text" disabled={isLoading}>
            {buttonLabel}
          </Button>
        </Div>
      </Div>
    </Popover>
  );
};

export default UsersSelectPopover;
