import { SxStyles } from 'theme';

const styles: SxStyles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 1,
  },
  range: {
    color: 'text.blackLight',
  },
  days: {
    color: 'text.lightGray',
  },
  cancel: {
    padding: 0,
    color: 'primary.red',
  },
  icon: {
    width: '16px',
    height: '16px',
    marginRight: 1,
  },
  divider: {
    marginTop: 2,
    marginBottom: 2,
  },
  sendRequest: {
    display: 'flex',
    alignItems: 'center',
    color: 'text.green',
  },
  approve: {
    padding: 0,
    color: 'text.green',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default styles;
