import { FC, useState } from 'react';

import { Alert } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Loader from 'components/common/Loader';
import MultipleAutocomplete from 'components/common/SmartFilter/components/MultipleAutocomplete';
import { IMultipleSlot } from 'components/common/SmartFilter/types';

import { useGetHolidayCalendarsYearsQuery } from 'domain/holiday/calendar/year/apiSlice';
import { IHolidayCalendarYearShort } from 'domain/holiday/calendar/year/types';

import { SuggestionAutocompleteType } from 'enums/SuggestionAutocomplete';

import { IAutocompleteOption } from 'types/autocomplete';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { getSortParameter } from 'utils/runsack';
import { getPreparedDataForSuggestionAutocomplete } from 'utils/suggestionAutocomplete';

import { ICalendarsProps } from './types';

const Calendars: FC<ICalendarsProps> = props => {
  const { slot, onSlotPatch, setOpenPopover } = props;

  const { t } = useTranslation(['common', 'smartFilter']);

  const value = slot.value as Array<IMultipleSlot>;

  const [calendarName, setCalendarName] = useState<string>('');

  const [debouncedCalendarName] = useDebounce(calendarName, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: calendarsData,
    isLoading: isCalendarsDataLoading,
    isFetching: isCalendarsDataFetching,
    isError: isCalendarsDataLoadingError,
  } = useGetHolidayCalendarsYearsQuery({
    nameCont: debouncedCalendarName,
    sort: getSortParameter('name', 'asc'),
  });

  if (isCalendarsDataLoading) {
    return <Loader />;
  }

  if (isCalendarsDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCurrentCalendarsChange = (calendars: Array<IAutocompleteOption>, filterWithEnter = false) => {
    if (_.isEmpty(calendars)) {
      onSlotPatch(
        slot.id,
        {
          value: [],
        },
        filterWithEnter,
      );
      return;
    }

    const value = calendars.map(calendar => ({ id: calendar.id, name: calendar.calendarName }));

    onSlotPatch(
      slot.id,
      {
        value,
      },
      filterWithEnter,
    );
  };

  const options = getPreparedDataForSuggestionAutocomplete<IHolidayCalendarYearShort>({
    data: calendarsData.years,
    type: SuggestionAutocompleteType.calendar,
  });

  const currentOptions = getPreparedDataForSuggestionAutocomplete<IMultipleSlot>({
    data: value,
    type: SuggestionAutocompleteType.calendar,
  });

  return (
    <MultipleAutocomplete
      options={options}
      currentOptions={currentOptions}
      isFetching={isCalendarsDataFetching}
      onCurrentOptionsChange={handleCurrentCalendarsChange}
      setName={setCalendarName}
      name={calendarName}
      label={t('smartFilter:calendars.label')}
      setOpenPopover={setOpenPopover}
    />
  );
};

export default Calendars;
