import { FC } from 'react';

import { Button, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import styles from './styles';
import { IMultipleAutocompleteGroupProps } from './types';

const Group: FC<IMultipleAutocompleteGroupProps> = props => {
  const { group, onAllButtonClick, onNoneButtonClick } = props;

  const { t } = useTranslation('smartFilter');
  return (
    <Div>
      <ListItem sx={styles.listElement}>
        <Div sx={styles.groupItem}>
          <Typography variant="heading4">{group}</Typography>
          <Div>
            <Button onClick={onAllButtonClick} variant="text" type="button">
              {t('multipleAutocomplete.buttons.all')}
            </Button>
            <Button onClick={onNoneButtonClick} variant="text" type="button">
              {t('multipleAutocomplete.buttons.none')}
            </Button>
          </Div>
        </Div>
      </ListItem>
    </Div>
  );
};

export default Group;
