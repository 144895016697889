import { FC } from 'react';

import { Tooltip, Typography } from '@mui/material';
import { isBefore, isWithinInterval } from 'date-fns';
import MUISx from 'mui-sx';

import { CalendarInteractionMode } from 'components/common/Calendar/enums';
import Div from 'components/common/Div';

import { isHolidayDay, isWeekendDay } from 'domain/holiday/calendar/year/service';
import {
  isVacationResourceApproved,
  isVacationResourceCompleted,
  isVacationResourceDeclined,
  isVacationResourceOnApproval,
} from 'domain/resource/vacation/service';

import { stringToDate, dateToString } from 'utils/calendar';

import styles from './styles';
import { IDayProps } from './types';

const Day: FC<IDayProps> = props => {
  const {
    show,
    label,
    day,
    isRoundLeft,
    isRoundRight,
    onSelectionStart,
    onSelectionEnd,
    startSelection,
    endSelection,
    interactionMode,
    onDayClick,
    vacation,
    dateDetails,
  } = props;

  const dayString = dateToString(day);
  const isApproved = vacation && isVacationResourceApproved(vacation);
  const isCompleted = vacation && isVacationResourceCompleted(vacation);
  const isOnApproval = vacation && isVacationResourceOnApproval(vacation);
  const isDeclined = vacation && isVacationResourceDeclined(vacation);

  const isWeekend = dateDetails && isWeekendDay(dateDetails);
  const isHoliday = dateDetails && isHolidayDay(dateDetails);

  const isStartSelection = dayString === startSelection;
  const isEndSelection = dayString === endSelection;
  const inSelection =
    startSelection &&
    endSelection &&
    isWithinInterval(day, {
      start: stringToDate(startSelection),
      end: stringToDate(endSelection),
    });

  const handleDayClick = (event: React.MouseEvent<HTMLElement>) => {
    if (interactionMode === CalendarInteractionMode.range) {
      if (startSelection && !isBefore(day, stringToDate(startSelection))) {
        onSelectionEnd(dayString);
        return;
      }

      if (!startSelection) {
        onSelectionStart(dayString);
      }
    }

    if (interactionMode === CalendarInteractionMode.singleDay) {
      onDayClick(event, dateDetails);
    }
  };

  const classes = MUISx(
    styles.root,
    { condition: !show, sx: styles.hidden },
    { condition: isApproved, sx: styles.approved },
    { condition: isCompleted, sx: styles.completed },
    { condition: isDeclined, sx: styles.declined },
    { condition: isOnApproval, sx: styles.onApproval },
    { condition: isWeekend, sx: styles.weekend },
    { condition: isHoliday, sx: styles.holiday },
    { condition: isRoundLeft, sx: styles.roundLeft },
    { condition: isRoundRight, sx: styles.roundRight },
    { condition: inSelection, sx: styles.inSelection },
    { condition: isStartSelection, sx: styles.startSelection },
    { condition: isEndSelection, sx: styles.endSelection },
  );

  const holidayDescription = isHoliday ? dateDetails.description : '';

  return (
    <Tooltip arrow title={holidayDescription} placement="bottom">
      <Div onClick={handleDayClick} sx={classes}>
        <Typography variant="h6">{label}</Typography>
      </Div>
    </Tooltip>
  );
};

export default Day;
