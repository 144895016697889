import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    minWidth: '150px',
    height: '40px',
    backgroundColor: 'background.white',
    borderColor: 'border.main',
  },
  groupedByLabel: {
    marginRight: 1,
  },
  groupedByLabelText: {
    color: 'text.gray',
  },
  groupByOptionText: {
    color: 'text.primary',
  },
};

export default styles;
