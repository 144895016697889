import { FC, useEffect } from 'react';

import {
  Alert,
  SelectChangeEvent,
  TableHead,
  Table as MUITable,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';
import { usePagination, useTable } from 'react-table';

import Div from 'components/common/Div';
import Pagination from 'components/common/TablePagination';
import TableSkeleton from 'components/common/TableSkeleton';
import { UsersTableColumnAccessor } from 'components/pages/Users/enums';

import styles from './styles';
import { IUsersTableProps } from './types';

const Table: FC<IUsersTableProps> = props => {
  const { data = [], columns = [], isLoading = false, isError = false, pagination, onPaginationChange } = props;

  const pageCount = pagination?.totalPages;
  const pageSize = pagination?.pageSize;
  const pageIndex = (pagination?.pageNumber ?? 0) - 1;

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, gotoPage, setPageSize, page } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    usePagination,
  );

  const { t } = useTranslation('table');

  useEffect(() => {
    if ((pagination?.pageNumber ?? 0) - 1 !== pageIndex) {
      gotoPage(0);
    }
  }, [pagination]);

  const isTableDataEmpty = page.length === 0;

  const isDataEmpty = isTableDataEmpty && !isLoading;

  const handleCurrentPageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    onPaginationChange({ pageNumber: page, pageSize });
    gotoPage(page - 1);
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    const pageSize = Number(event.target.value);
    onPaginationChange({ pageNumber: 1, pageSize });
    gotoPage(0);
    setPageSize(pageSize);
  };

  if (isError) {
    return <Alert severity="error">{t('errorText')}</Alert>;
  }

  return (
    <Div sx={styles.root}>
      <Div sx={styles.rootTable}>
        <MUITable sx={MUISx(styles.table, { condition: isLoading, sx: styles.tableLoading })} {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => {
              return (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, columnIndex, columns) => {
                    const isLastColumn = columns.length - 1 === columnIndex;
                    const isFirstColumn = columnIndex === 0;

                    return (
                      <TableCell
                        sx={MUISx(
                          styles.column,
                          styles.headerColumn,
                          { condition: isLastColumn, sx: styles.lastColumn },
                          { condition: isFirstColumn, sx: styles.firstColumn },
                        )}
                        {...column.getHeaderProps({
                          style: {
                            width: column.width ?? 'auto',
                            minWidth: column.minWidth ?? 'auto',
                            maxWidth: column.maxWidth ?? 'auto',
                          },
                        })}
                      >
                        {column.render('Header')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);

              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex, cells) => {
                    const { id: columnAccessor, width, minWidth, maxWidth } = cell.column;
                    const isLastColumn = cells.length - 1 === cellIndex;
                    const isFirstColumn = cellIndex === 0;

                    const isUserTypeColumn = columnAccessor === UsersTableColumnAccessor.userType;
                    const isStateColumn = columnAccessor === UsersTableColumnAccessor.state;

                    const isCenteredColumn = isUserTypeColumn || isStateColumn || isLastColumn;

                    return (
                      <TableCell
                        sx={MUISx(
                          styles.column,
                          { condition: isLastColumn, sx: styles.lastColumn },
                          { condition: isFirstColumn, sx: styles.firstColumn },
                          { condition: isCenteredColumn, sx: styles.centeredColumn },
                        )}
                        {...cell.getCellProps({
                          style: {
                            width: width ?? 'auto',
                            minWidth: minWidth ?? 'auto',
                            maxWidth: maxWidth ?? 'auto',
                          },
                        })}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MUITable>

        {isLoading && <TableSkeleton perPage={pageSize} />}
        {isDataEmpty && <Typography textAlign="center">{t('noData')}</Typography>}
      </Div>
      <Pagination
        isDataEmpty={isDataEmpty}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        handleCurrentPageChange={handleCurrentPageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </Div>
  );
};

export default Table;
