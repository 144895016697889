import React, { FC } from 'react';

import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import _ from 'lodash';

import Div from 'components/common/Div';

import Group from '../Group';

import styles from './styles';
import { IMultipleAutocompleteOptionsListProps } from './types';

const OptionsList: FC<IMultipleAutocompleteOptionsListProps> = ({
  currentOptions,
  optionsList,
  onOptionClick,
  label,
  onAllButtonClick,
  onNoneButtonClick,
  isNeedCheckType,
  showBlankOption,
  isBlank = false,
  blankLabel = '',
  onBlankChange = () => {},
}) => {
  const handleOptionClick = (option, isSelected) => {
    if (isBlank) {
      onBlankChange();
    }
    const updatedOptions = isSelected
      ? currentOptions.filter(
          selectedOption => selectedOption.id !== option.id || (isNeedCheckType && selectedOption.type !== option.type),
        )
      : [...currentOptions, option];
    onOptionClick(updatedOptions);
  };

  return (
    <Div sx={styles.box}>
      {showBlankOption && (
        <FormControlLabel
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              checked={isBlank}
              onChange={onBlankChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={
            <Typography sx={styles.checkboxLabel} variant="subtitle1">
              {blankLabel}
            </Typography>
          }
        />
      )}
      {!_.isEmpty(optionsList) && (
        <Group group={label.toUpperCase()} onAllButtonClick={onAllButtonClick} onNoneButtonClick={onNoneButtonClick} />
      )}
      {optionsList.map(option => {
        const isSelected = isNeedCheckType
          ? currentOptions.some(
              selectedOption => selectedOption.id === option.id && selectedOption.type === option.type,
            )
          : currentOptions.some(selectedOption => selectedOption.id === option.id);
        const optionKey = `${option.id}-${option.name}`;

        return (
          <div key={optionKey}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={isSelected}
                  onClick={() => handleOptionClick(option, isSelected)}
                />
              }
              label={<Typography variant="subtitle1">{option.name}</Typography>}
            />
          </div>
        );
      })}
    </Div>
  );
};

export default OptionsList;
