export enum VacationsTab {
  vacationRequests = 'vacationRequests',
  calendar = 'calendar',
}

export enum VacationRequestTab {
  project = 'project',
  practice = 'practice',
  approvements = 'approvements',
}
