import { SxStyles } from 'theme';

const styles: SxStyles = {
  userChip: {
    marginRight: 1,
    marginBottom: 1,
    backgroundColor: 'background.frostBlue',

    '& .MuiChip-label': {
      paddingLeft: 0.75,
      paddingRight: 1.5,
    },
  },
  closeChipIcon: {
    width: '16px',
    height: '16px',
  },
  chipUserName: {
    display: 'flex',
    alignItems: 'center',
    color: 'text.blue',
  },
  userAvatar: {
    width: '24px',
    height: '24px',
    marginRight: 1,
  },
};

export default styles;
