import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    marginBottom: 1,
  },
};

export default styles;
