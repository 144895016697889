import { useContext } from 'react';

import { ConfirmDialogContext } from 'contexts/ConfirmDialogContext';

import { IConfirmDialogDefault } from 'types/confirmDialog';

interface IUseConfirmation {
  getConfirmation: (props: IConfirmDialogDefault) => Promise<boolean>;
}

const useConfirmDialog = (): IUseConfirmation => {
  const { openDialog } = useContext(ConfirmDialogContext);

  const getConfirmation = ({ title, message }) =>
    new Promise<boolean>(resolve => {
      openDialog({ actionCallback: resolve, title, message });
    });

  return { getConfirmation };
};

export { useConfirmDialog };
