import { provideTags, TagTypes } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { ICreateResourceUsageFormDataToSubmit } from './schemas/create';
import { IUpdateResourceUsageFormDataToSubmit } from './schemas/update';
import { IResourceUsage } from './types';

interface IGetResourcesUsageResponse {
  usages: Array<IResourceUsage>;
  meta: IMeta;
}

interface IGetResourceUsageParameters {
  resourceId: number;
  page?: number;
  perPage?: number;
}

interface IGetResourceUsageResponse {
  usage: IResourceUsage;
}

interface IGetResourceUsageQueryParameters {
  resourceId: number;
  resourceUsageId: number;
}

interface IDeleteResourceUsageParameters {
  resourceId: number;
  resourceUsageId: number;
}

export const resourceUsageApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getResourcesUsage: builder.query<IGetResourcesUsageResponse, IGetResourceUsageParameters>({
      query: ({ resourceId, ...parameters }) => {
        return {
          url: apiRoutes.apiV1ResourceUsagesPath(resourceId),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ResourceUsage, response?.usages),
    }),
    getResourceUsage: builder.query<IGetResourceUsageResponse, IGetResourceUsageQueryParameters>({
      query: ({ resourceId, resourceUsageId }) => {
        return {
          url: apiRoutes.apiV1ResourceUsagePath(resourceId, resourceUsageId),
          method: 'GET',
        };
      },
      providesTags: ({ usage }) => [{ type: TagTypes.ResourceUsage, id: usage.id }],
    }),

    createResourceUsage: builder.mutation<IGetResourceUsageResponse, ICreateResourceUsageFormDataToSubmit>({
      query: ({ resourceId, formData }) => ({
        url: apiRoutes.apiV1ResourceUsagesPath(resourceId),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: (result, error) => {
        if (error) {
          return [TagTypes.ResourceUsage];
        }
        return [
          TagTypes.ResourceUsage,
          TagTypes.ReportAllocation,
          { type: TagTypes.ReportHoursByProject, id: result.usage.projectId },
        ];
      },
    }),
    updateResourceUsage: builder.mutation<IGetResourceUsageResponse, IUpdateResourceUsageFormDataToSubmit>({
      query: ({ resourceId, resourceUsageId, formData }) => ({
        url: apiRoutes.apiV1ResourceUsagePath(resourceId, resourceUsageId),
        method: 'PUT',
        data: formData,
      }),
      invalidatesTags: (result, error) => {
        if (error) {
          return [TagTypes.ResourceUsage];
        }
        return [
          { type: TagTypes.ResourceUsage, id: result.usage.id },
          { type: TagTypes.ReportHoursByProject, id: result.usage.projectId },
          TagTypes.ReportAllocation,
        ];
      },
    }),
    deleteResourceUsage: builder.mutation<void, IDeleteResourceUsageParameters>({
      query: ({ resourceId, resourceUsageId }) => ({
        url: apiRoutes.apiV1ResourceUsagePath(resourceId, resourceUsageId),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.ResourceUsage, TagTypes.ReportAllocation],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateResourceUsageMutation,
  useUpdateResourceUsageMutation,
  useGetResourceUsageQuery,
  useGetResourcesUsageQuery,
  useDeleteResourceUsageMutation,
} = resourceUsageApi;
