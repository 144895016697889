import { FC } from 'react';

import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import identity from 'lodash/identity';

import { IResourceTrackedTimeEntry } from 'domain/resource/trackedTimeEntry/types';

import { formatDurationFromNumberToObject } from 'utils/durationFormat';

import RenderInput from './RenderInput';
import styles from './styles';
import { IInputWithOptionsProps } from './types';

const TOTAL_ENTRIES_TO_SHOW = 10;

const getOptionLabel = (option: IResourceTrackedTimeEntry | string) => {
  if (typeof option === 'string') {
    return option;
  }
  if (option.description) {
    return option.description;
  }
  return '';
};

const InputWithOptions: FC<IInputWithOptionsProps> = props => {
  const {
    description,
    onDescriptionChange,
    isCreateResourceTrackedEntityLoading,
    trackedTimeEntries,
    setDuration,
    setCurrentTimeTrackerTags,
    setCurrentTimeTrackerTrackable,
    handleCurrentTimeTrackerTrackableChange,
  } = props;

  const handleOptionClick = (event, localProps, option) => {
    const { onClick = identity } = localProps;
    onClick(event);
    onDescriptionChange(option.description);
    setDuration(formatDurationFromNumberToObject(option.duration));
    setCurrentTimeTrackerTags(option.tags);
    const currentTimeTrackerTrackable = {
      task: option.task,
      trackable: {
        billable: option.billable,
        id: option.trackableId,
        name: option.trackableName,
        state: option.task?.state,
        type: option.trackableType,
      },
    };
    setCurrentTimeTrackerTrackable(currentTimeTrackerTrackable);
    handleCurrentTimeTrackerTrackableChange(currentTimeTrackerTrackable);
  };

  const listItemStyles = {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
  };

  const renderOption = (props, option) => (
    <li
      style={listItemStyles as React.CSSProperties}
      {...props}
      onClick={event => handleOptionClick(event, props, option)}
    >
      {option.description}
    </li>
  );

  return (
    <Autocomplete
      value={description}
      freeSolo
      disableClearable
      clearOnEscape
      disabled={isCreateResourceTrackedEntityLoading}
      options={[...trackedTimeEntries].slice(0, TOTAL_ENTRIES_TO_SHOW)}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      renderInput={(parameters: AutocompleteRenderInputParams) => (
        <RenderInput parameters={parameters} onDescriptionChange={onDescriptionChange} />
      )}
      sx={styles}
    />
  );
};

export default InputWithOptions;
