export default {
  logoTitle: 'Project X',
  mainNavigation: {
    timeTracker: {
      title: 'Time Tracker',
    },
    vacations: {
      title: 'Vacations',
    },
    reports: {
      title: 'Reports',
    },
    resources: {
      title: 'Resources',
    },
    projects: {
      title: 'Projects',
    },
    allocation: {
      title: 'Allocation',
    },
  },
  settingsNavigation: {
    users: {
      title: 'Users',
    },
    adminPanel: {
      title: 'Admin panel',
    },
  },
  footer: {
    navigation: {
      healthCheck: {
        title: 'Health Check',
      },
      dbpLearn: {
        title: 'DBP Learn',
      },
    },
    signInButton: 'Sign in',
  },
  currentUserBadge: {
    impersonationBadge: {
      impersonatedAs: 'Impersonated as:',
      impersonatedAsShort: 'Imp',
    },
    userActionsMenu: {
      menuItems: {
        stopImpersonating: 'Stop Impersonating',
        profilePage: 'Profile Page',
        logOut: 'Log Out',
      },
    },
  },
};
