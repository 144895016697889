import { FC, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { ModalContext } from 'contexts/ModalContext';

import { getUserProfileAccessLevelsGroupedByAccessType } from 'domain/user/profile/accessLevel/service';

import CreateUserProfileAdditionAccessLevelForm from './components/CreateUserProfileAdditionAccessLevelForm';
import UserProfileGroupAccessLevels from './components/UserProfileGroupAccessLevels';
import { IUserProfileAccessLevelsProps } from './types';

const UserProfileAccessLevels: FC<IUserProfileAccessLevelsProps> = props => {
  const { userProfileAccessLevels, user } = props;

  const { t } = useTranslation(['userProfile']);

  const { openModal } = useContext(ModalContext);

  const handleAddGroupAccessClick = () => {
    openModal({
      title: t('userProfile:accessLevel.createGroupAccessModalTitle'),
      content: <CreateUserProfileAdditionAccessLevelForm userId={user.id} />,
    });
  };

  const handleAddProjectAccessClick = () => {
    openModal({
      title: t('userProfile:accessLevel.createProjectAccessModalTitle'),
      content: <CreateUserProfileAdditionAccessLevelForm isUserProfileProjectAccessLevel userId={user.id} />,
    });
  };

  const userProfileAccessLevelsGroupedByAccessType =
    getUserProfileAccessLevelsGroupedByAccessType(userProfileAccessLevels);

  const userProfileGroupAccessLevels = userProfileAccessLevelsGroupedByAccessType.Group;
  const userProfileProjectAccessLevels = userProfileAccessLevelsGroupedByAccessType.Project;

  return (
    <>
      <UserProfileGroupAccessLevels
        groupTitle={t('userProfile:accessLevel.titles.groupAccess')}
        userProfileAccessLevels={userProfileGroupAccessLevels}
        permission={'canCreateGroupAccessLevel'}
        onAddAccess={handleAddGroupAccessClick}
        user={user}
      />

      <UserProfileGroupAccessLevels
        isUserProfileProjectAccessLevel
        groupTitle={t('userProfile:accessLevel.titles.projectAccess')}
        userProfileAccessLevels={userProfileProjectAccessLevels}
        permission={'canCreateProjectAccessLevel'}
        onAddAccess={handleAddProjectAccessClick}
        user={user}
      />
    </>
  );
};

export default UserProfileAccessLevels;
