import { SxStyles } from 'theme';

const styles: SxStyles = {
  title: {
    paddingLeft: 1,
  },
  titleText: {
    textTransform: 'uppercase',
  },
  checkboxInput: {
    paddingLeft: 1.25,
  },
  label: {
    color: 'text.blackLight',
  },
};

export default styles;
