import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    marginBottom: 3,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'background.white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '6px',
  },
  input: {
    overflow: 'hidden',
    minWidth: '100px',
    flex: 1,
    borderRadius: '6px',
    border: 'none',
    marginRight: 2.5,
    '& input': {
      paddingY: 2,
      paddingX: 1.5,
      width: '100%',
      height: '100%',
      border: 'none',
      fontSize: '16px',
      fontWeight: 400,
      color: 'text.primary',
    },
    '& input:focus': {
      outline: 'none',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    paddingRight: 1.5,
    height: '56px',
  },
  action: {
    marginRight: 2,
    '& .MuiTypography-root': {
      maxWidth: '100px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  submitButton: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: 'background.lightDarkGreen',
    '&:hover': {
      border: theme => `1px solid ${theme.palette.border.green}`,
      backgroundColor: 'background.lightGreen',
    },
  },
  submitButtonIcon: {
    width: '20px',
    height: '20px',
    color: 'text.green',
  },
  duration: {
    backgroundColor: 'background.lightGray',
  },
  descriptionErrors: {
    borderTopLeftRadius: '7px',
    borderBottomLeftRadius: '7px',
    borderTopRightRadius: '7px',
    borderBottomRightRadius: '7px',
    border: theme => `1px solid ${theme.palette.border.red}`,
    '& input::placeholder': {
      color: 'text.red',
      opacity: 1,
    },
  },
  trackableErrors: {
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    border: theme => `1.5px solid ${theme.palette.border.red}`,

    '& button': {
      color: 'text.red',
    },
  },
};

export default styles;
