import { SxStyles } from 'theme';

const styles: SxStyles = {
  input: {
    width: '400px',
    marginBottom: 2,
    textTransform: 'capitalize',
  },
  root: {
    paddingTop: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  createHoursPerWeekButton: {
    marginRight: 1.25,
  },
  buttons: {
    marginTop: 2,
  },
  placeholder: {
    color: 'text.gray',
  },
  helperText: {
    textTransform: 'none',
  },
};

export default styles;
