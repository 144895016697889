import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    display: 'flex',
    gap: 3,
  },
  addButton: {
    paddingY: 1.5,
    paddingX: 2.5,
    height: '50px',
    whiteSpace: 'nowrap',
    minWidth: 'min-content',
  },
};

export default styles;
