export default {
  title: 'Create New Password',
  subtitle: 'Your password must be at least six characters and cannot contain spaces.',
  form: {
    passwordField: {
      placeholder: 'New Password',
    },
    passwordConfirmationField: {
      placeholder: 'Confirm New Password',
    },
    setNewPasswordButton: 'Set New Password',
  },
};
