import theme, { SxStyles } from 'theme';

const styles: SxStyles = {
  cell: {
    textAlign: 'center',
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderBottom: `3px solid ${theme.palette.border.main}`,
    height: 35,
    width: 35,
    minWidth: 35,
    bgcolor: theme.palette.common.white,
  },
  boldRightBorder: {
    borderRightWidth: 3,
  },
  hiddenRightBorder: {
    borderRight: 'none',
  },
  weekendCell: {
    bgcolor: 'border.lightGray',
  },
  title: {
    color: 'text.gray',
  },
  todayTitle: {
    color: 'text.blue',
  },
};

export default styles;
