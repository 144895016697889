import { SxStyles } from 'theme';

const styles: SxStyles = {
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  button: {
    backgroundColor: 'background.default',
    marginRight: 1.25,
  },
  createButton: {
    marginRight: 1.25,
  },
  container: {
    padding: 2,
  },
  tabHeadersContainer: {
    borderBottom: '1px solid',
    borderColor: 'border.blue',
    marginBottom: 3.75,
  },
  deleteButton: {
    borderColor: 'border.red',
    color: 'text.red',
  },
  activateButton: {
    borderColor: 'border.green',
    color: 'text.green',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default styles;
