import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IResourceAbility } from 'domain/resource/ability/types';

import { SkillLevel } from 'enums/SkillLevel';

const requiredValidation = 'forms:validation:required';

export interface IUpdateResourceAbilityFormData {
  skillLevel: SkillLevel;
}

export interface IUpdateResourceAbilityFormDataToSubmit {
  id: number;
  resourceId: number;
  formData: IUpdateResourceAbilityFormData;
}

export const buildValidationFields = () => ({
  skillLevel: yup.mixed<SkillLevel>().required(i18n.t(requiredValidation)).default(SkillLevel.junior),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (ability: IResourceAbility) => {
  const { skillLevel } = ability;
  return {
    skillLevel,
  };
};

export const formatAttributesToSubmit = (
  id: number,
  resourceId: number,
  formData: IUpdateResourceAbilityFormData,
): IUpdateResourceAbilityFormDataToSubmit => {
  return {
    id,
    resourceId,
    formData,
  };
};
