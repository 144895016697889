import { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DateRangePicker from 'components/common/DateRangePicker';
import { DateRange } from 'components/common/DateRangePicker/types';
import Div from 'components/common/Div';

import { getDateRange, RangeType } from 'utils/dateRangePicker';

import styles from './styles';
import { IAllocationFiltersProps } from './types';

const Filters: FC<IAllocationFiltersProps> = props => {
  const { filters, setFilters, resourcesTotalCount } = props;

  const { t } = useTranslation('allocation');

  const onDateRangeChange = (dateRange: DateRange | null) => {
    setFilters({
      ...filters,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    });
  };

  return (
    <Div sx={styles.root}>
      <Div>
        <Typography variant="h2">{t('filter.resources', { count: resourcesTotalCount })}</Typography>
      </Div>
      <Div sx={styles.mainFilters}>
        <DateRangePicker
          currentDateRange={getDateRange(filters.startDate, filters.endDate, RangeType.month)}
          onDateRangeChange={onDateRangeChange}
        />
      </Div>
    </Div>
  );
};

export default Filters;
