import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 427,
    paddingTop: 1.25,
  },
  select: {
    width: '100%',
    marginBottom: 1.375,
  },
  dates: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 1.375,
  },
  endDateSwitch: {
    marginRight: 0,
  },
  groupFields: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  datePickerField: {
    width: '160px',
  },
  input: {
    width: '85px',
    marginBottom: 4,
  },
  addButton: {
    marginRight: 1.25,
  },
  selectPlaceholder: {
    color: 'text.gray',
  },
  datePicker: {
    marginRight: 1.375,
  },
};

export default styles;
