import { SxStyles } from 'theme';

const styles: SxStyles = {
  input: {
    width: '100%',
    height: '100%',
    border: 'none',
    borderColor: 'none',
    outline: 'none',
    '&:hover': {
      outline: 'none',
    },
    '& ::placeholder': {
      color: 'text.primary',
      opacity: 1,
    },
  },
  inputErrors: {
    '::placeholder': {
      color: 'text.red !important',
    },
  },
};

export default styles;
