import { FC, useContext } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

import { ModalContext } from 'contexts/ModalContext';

import styles from './styles';

const Modal: FC = () => {
  const { open, closeModal, modalData } = useContext(ModalContext);

  return (
    <Dialog
      fullScreen={modalData?.fullScreen ?? false}
      open={open}
      onClose={closeModal}
      onBackdropClick={closeModal}
      PaperProps={{ sx: styles.root }}
    >
      <IconButton onClick={closeModal} sx={styles.closeButton}>
        <CloseIcon />
      </IconButton>
      {(modalData?.title || modalData?.subtitle) && (
        <DialogTitle sx={styles.dialogTitle}>
          <Typography variant="h2" component="p" sx={styles.title}>
            {modalData.title}
          </Typography>
          <Typography variant="body1" component="p" sx={styles.subTitle}>
            {modalData.subtitle}
          </Typography>
        </DialogTitle>
      )}
      {modalData?.content && <DialogContent sx={styles.dialogContent}>{modalData.content}</DialogContent>}
    </Dialog>
  );
};

export default Modal;
