import { FC, useState, useEffect } from 'react';

import { IconButton } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';

import CreateProjectAndTaskButton from 'components/common/CreateProjectAndTaskButton';
import CreateTagsButton from 'components/common/CreateTagsButton';
import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import InputWithOptions from 'components/common/InputWithOptions';
import TimeTrackerTrackableDuration from 'components/common/TimeTrackerTrackableDuration';

import { ITask } from 'domain/project/task/types';
import { useCreateResourceTrackedTimeEntryMutation } from 'domain/resource/trackedTimeEntry/apiSlice';
import { ITimeTrackerTrackable } from 'domain/timeTracker/trackable/types';

import { EntityAction } from 'enums/EntityAction';

import { useNotifications } from 'hooks/useNotifications';

import { ITrackableResponse } from 'types/timeTrackerTrackable';

import { convertDateToUTCString, getMinutesFromHours } from 'utils/dateTime';
import { generateBackendErrorMessages } from 'utils/error';

import TimeTrackerTrackableDatePicker from './components/TimeTrackerTrackableDatePicker';
import { useSetCurrentTimeTrackerTrackableState } from './components/TimeTrackerTrackableDatePicker/service';
import styles from './styles';
import { ICreateResourceTrackedEntityFieldProps } from './types';

const alertUser = (event: Event) => {
  event.preventDefault();
};

const CreateResourceTrackedEntityField: FC<ICreateResourceTrackedEntityFieldProps> = props => {
  const {
    resourceId,
    trackedTimeEntries,
    currentTimeTrackerTrackable,
    setCurrentTimeTrackerTrackable,
    currentTimeTrackerTags,
    setCurrentTimeTrackerTags,
    description,
    trackable: trackableValue,
    onDescriptionChange,
    onTrackableChange,
    handleSubmit,
    duration,
    setDuration,
    copiedTimeTrackerSettings,
    reset,
    errors,
  } = props;

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [shouldShowNotification, setShouldShowNotification] = useState<boolean>(false);

  const [date, setDate] = useState<Date>(new Date());

  const [createResourceTrackedEntity, { isLoading: isCreateResourceTrackedEntityLoading }] =
    useCreateResourceTrackedTimeEntryMutation();

  useEffect(() => {
    if (!copiedTimeTrackerSettings) return;
    onDescriptionChange(copiedTimeTrackerSettings.description);
    const hours = Math.floor(copiedTimeTrackerSettings.duration / 60)
      .toString()
      .padStart(2, '0');
    const minutes = Math.floor(copiedTimeTrackerSettings.duration % 60)
      .toString()
      .padStart(2, '0');
    setDuration({
      hours,
      minutes,
    });
    const date = new Date(copiedTimeTrackerSettings.date);
    setDate(new Date(date.getTime() + date.getTimezoneOffset() * 60_000));
    setCurrentTimeTrackerTrackable(copiedTimeTrackerSettings.trackable);
    setCurrentTimeTrackerTags(copiedTimeTrackerSettings.tags);
  }, [copiedTimeTrackerSettings]);

  useEffect(() => {
    if (errors.description && shouldShowNotification) {
      showErrorNotification(errors.description.message);
    }
    if (errors.trackable && shouldShowNotification) {
      showErrorNotification(errors?.trackable?.message);
    }
  }, [errors?.description, errors?.trackable]);

  useSetCurrentTimeTrackerTrackableState(
    copiedTimeTrackerSettings,
    setDate,
    setDuration,
    setCurrentTimeTrackerTrackable,
    setCurrentTimeTrackerTags,
    onDescriptionChange,
    onTrackableChange,
  );

  const onSubmit = handleSubmit(async () => {
    if (duration === null) {
      return;
    }
    const durationMinutes = getMinutesFromHours(Number(duration.hours)) + Number(duration.minutes);
    const currentTrackable: ITimeTrackerTrackable | { trackable: ITimeTrackerTrackable | null; task: ITask | null } =
      trackableValue.trackable?.trackable ?? trackableValue.trackable;
    const formData = {
      description,
      billable: currentTrackable.billable,
      date: convertDateToUTCString(date),
      duration: durationMinutes,
      taskId: currentTimeTrackerTrackable?.task?.id,
      trackableId: currentTrackable?.id,
      trackableType: currentTrackable?.type,
      tagIds: currentTimeTrackerTags.map(({ id }) => id),
    };

    try {
      await createResourceTrackedEntity({ resourceId, formData }).unwrap();
      onReset();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  });

  const onReset = () => {
    reset();
    setCurrentTimeTrackerTrackable({ trackable: null, task: null });
    setCurrentTimeTrackerTags([]);
    setDate(new Date());
    setDuration({ hours: '0', minutes: '0' });
  };

  const handleCurrentTimeTrackerTrackableChange = (currentTimeTrackerTrackable: ITrackableResponse) => {
    if (_.isEmpty(currentTimeTrackerTrackable.trackable)) {
      onTrackableChange(null);
      return;
    }
    onTrackableChange(currentTimeTrackerTrackable);
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setShouldShowNotification(true);
      await onSubmit();
      setShouldShowNotification(false);
    }
  };

  const handleSendButtonClick = async () => {
    setShouldShowNotification(true);
    await onSubmit();
    setShouldShowNotification(false);
  };

  useEffect(() => {
    if (
      description.length > 0 ||
      Number(duration?.hours) != 0 ||
      Number(duration?.minutes) != 0 ||
      trackableValue.trackable?.trackable ||
      currentTimeTrackerTags.length > 0
    ) {
      window.addEventListener('beforeunload', alertUser);
      return () => {
        window.removeEventListener('beforeunload', alertUser);
      };
    }
  });

  return (
    <Div sx={MUISx(styles.root, { condition: Boolean(errors.description), sx: styles.descriptionErrors })}>
      <Div sx={styles.input} onKeyDown={handleKeyDown}>
        <InputWithOptions
          description={description}
          onDescriptionChange={onDescriptionChange}
          isCreateResourceTrackedEntityLoading={isCreateResourceTrackedEntityLoading}
          trackedTimeEntries={trackedTimeEntries}
          setDuration={setDuration}
          setCurrentTimeTrackerTags={setCurrentTimeTrackerTags}
          setCurrentTimeTrackerTrackable={setCurrentTimeTrackerTrackable}
          handleCurrentTimeTrackerTrackableChange={handleCurrentTimeTrackerTrackableChange}
        />
      </Div>

      <Div sx={styles.actions}>
        <Div sx={MUISx(styles.action, { condition: Boolean(errors.trackable), sx: styles.trackableErrors })}>
          <CreateProjectAndTaskButton
            currentTimeTrackerTrackable={currentTimeTrackerTrackable}
            setCurrentTimeTrackerTrackable={setCurrentTimeTrackerTrackable}
            isDisabled={isCreateResourceTrackedEntityLoading}
            onCurrentTimeTrackerTrackableChange={handleCurrentTimeTrackerTrackableChange}
          />
        </Div>

        <Div sx={styles.action}>
          <CreateTagsButton
            currentTags={currentTimeTrackerTags}
            setCurrentTags={setCurrentTimeTrackerTags}
            isDisabled={isCreateResourceTrackedEntityLoading}
          />
        </Div>

        <Div sx={styles.action}>
          <TimeTrackerTrackableDatePicker
            date={date}
            setDate={setDate}
            isDisabled={isCreateResourceTrackedEntityLoading}
          />
        </Div>

        <Div sx={styles.action} onKeyDown={handleKeyDown}>
          <TimeTrackerTrackableDuration
            duration={duration}
            setDuration={setDuration}
            isDisabled={isCreateResourceTrackedEntityLoading}
            type={EntityAction.create}
          />
        </Div>

        <IconButton
          disabled={isCreateResourceTrackedEntityLoading}
          onClick={handleSendButtonClick}
          sx={styles.submitButton}
        >
          <Icon sx={styles.submitButtonIcon} name="plus" />
        </IconButton>
      </Div>
    </Div>
  );
};

export default CreateResourceTrackedEntityField;
