import { isSuperAdmin } from 'domain/user/service';
import { IUser } from 'domain/user/types';

import { ProjectAccessLevel, ProjectState } from './enums';

export const isProjectActive = (state: ProjectState): boolean => {
  return state === ProjectState.active;
};

export const checkAccessToProject = (accessLevel: string, currentUser: IUser) => {
  const hasManagerAccessToProject = accessLevel === ProjectAccessLevel.manager;
  const hasAdminAccessToProject = accessLevel === ProjectAccessLevel.admin || isSuperAdmin(currentUser);
  return hasManagerAccessToProject || hasAdminAccessToProject;
};
