import * as yup from 'yup';

import i18n from 'locales/i18n';

import { ITechnology } from 'domain/technology/types';

import { SkillLevel } from 'enums/SkillLevel';

const requiredValidation = 'forms:validation:required';

export interface ICreateResourceAbilityFormData {
  technology: ITechnology;
  skillLevel: SkillLevel;
}

export interface ICreateResourceAbilityPreparedFormData extends Omit<ICreateResourceAbilityFormData, 'technology'> {
  technologyId: number;
}

export interface ICreateResourceAbilityFormDataToSubmit {
  resourceId: number;
  formData: ICreateResourceAbilityPreparedFormData;
}

export const buildValidationFields = () => ({
  technology: yup.mixed<ITechnology>().required(i18n.t(requiredValidation)).nullable().default(null),
  skillLevel: yup.mixed<SkillLevel>().required(i18n.t(requiredValidation)).default(SkillLevel.junior),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (
  resourceId: number,
  formData: ICreateResourceAbilityFormData,
): ICreateResourceAbilityFormDataToSubmit => {
  const { technology } = formData;

  return {
    resourceId,
    formData: {
      technologyId: technology.id,
      ...formData,
    },
  };
};
