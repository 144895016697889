import { FC, useContext, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, TextField, Tooltip } from '@mui/material';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { ModalContext } from 'contexts/ModalContext';

import {
  useGetProjectBudgetMonthQuery,
  useUpdateProjectBudgetMonthMutation,
} from 'domain/project/budget/month/apiSlice';
import {
  initialValues,
  buildValidationSchema,
  IUpdateProjectMonthBudgetFormData,
  formatAttributesToSubmit,
} from 'domain/project/budget/month/schemas/update';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';
import { isTimeboundProject } from 'utils/project';

import styles from './styles';
import { IUpdateBudgetFormProps } from './types';

const UpdateBudgetForm: FC<IUpdateBudgetFormProps> = props => {
  const { budgetType, project, monthlyBudgetId, canUpdateBudgets } = props;

  const { t } = useTranslation(['common', 'reportHoursByProject']);

  const { closeModal } = useContext(ModalContext);

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [updateProjectBudgetMonth, { isLoading: isUpdateProjectBudgetMonthLoading }] =
    useUpdateProjectBudgetMonthMutation();

  const isMonthBudgetForm = budgetType === 'monthly';
  const isTotalBudgetForm = budgetType === 'total';

  const {
    data: projectBudgetMonthData,
    isLoading: isProjectBudgetMonthDataLoading,
    isError: isProjectBudgetMonthDataLoadingError,
  } = useGetProjectBudgetMonthQuery({ projectId: project.id, id: monthlyBudgetId });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IUpdateProjectMonthBudgetFormData>({
    resolver: yupResolver(buildValidationSchema()),
  });

  useEffect(() => {
    if (!_.isNil(projectBudgetMonthData?.budgetMonth)) {
      reset(initialValues(projectBudgetMonthData.budgetMonth));
    }
  }, [projectBudgetMonthData]);

  const onSubmit = async (formData: IUpdateProjectMonthBudgetFormData) => {
    try {
      if (isMonthBudgetForm) {
        await updateProjectBudgetMonth(formatAttributesToSubmit(project.id, monthlyBudgetId, formData)).unwrap();
      }

      if (isTotalBudgetForm) {
        return;
      }
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  if (isTimeboundProject(project.periodType)) {
    return <Alert severity="error">{t('reportHoursByProject:info.updateBudgetForm.warningForTimebound')}</Alert>;
  }

  if (isProjectBudgetMonthDataLoading) {
    return <Loader />;
  }

  if (isProjectBudgetMonthDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.root}>
        <TextField
          sx={styles.input}
          {...register('budget')}
          label={
            isMonthBudgetForm
              ? t('reportHoursByProject:info.updateBudgetForm.input.labelForMonthBudget')
              : t('reportHoursByProject:info.updateBudgetForm.input.labelForTotalBudget')
          }
          InputLabelProps={{ sx: styles.placeholder }}
          error={Boolean(errors.budget)}
          helperText={buildErrorMessage(t, errors.budget)}
        />

        <Div>
          <Tooltip
            title={t('reportHoursByProject:info.updateBudgetForm.notAvailable')}
            disableHoverListener={canUpdateBudgets}
          >
            <span>
              <Button
                sx={styles.changeAmountButton}
                type="submit"
                disabled={isUpdateProjectBudgetMonthLoading || !canUpdateBudgets}
              >
                {t('reportHoursByProject:info.updateBudgetForm.buttons.changeAmount')}
              </Button>
            </span>
          </Tooltip>
          <Button type="reset" variant="outlined" onClick={closeModal} disabled={isUpdateProjectBudgetMonthLoading}>
            {t('reportHoursByProject:info.updateBudgetForm.buttons.cancel')}
          </Button>
        </Div>
      </Div>
    </form>
  );
};

export default UpdateBudgetForm;
