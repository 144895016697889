import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    position: 'relative',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    backgroundColor: 'background.white',
  },
  rootTable: {
    overflowX: 'auto',
  },
  table: {
    borderCollapse: 'separate',
  },
  tableLoading: {
    display: 'none',
  },
  column: {
    padding: 1,
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: 'border.main',
  },
  headerColumn: {
    textAlign: 'center',
  },
  lastColumn: {
    borderRight: 'none',
  },
  firstColumn: {
    position: 'sticky',
    left: 0,
    top: 0,
    backgroundColor: 'background.white',
    borderRightWidth: '2px',
    borderRightStyle: 'solid',
    borderRightColor: 'border.main',
  },
  centeredColumn: {
    textAlign: 'center',
  },
};

export default styles;
