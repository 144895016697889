import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IGroup } from 'domain/group/types';
import { IHolidayCalendar } from 'domain/holiday/calendar/types';
import { ITechnology } from 'domain/technology/types';
import { UserType } from 'domain/user/enums';

import { convertDateToUTCString } from 'utils/dateTime';
import { phoneRegExp } from 'utils/regexp';

export interface ICreateUserFormData {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth?: Date | null;
  userType: UserType;
  location?: string;
  group: IGroup;
  phoneNumber?: string;
  calendar: IHolidayCalendar;
  hoursPerWeek: number;
  employmentStartDate: Date;
  billableStartDate: Date;
  technologies?: Array<ITechnology>;
  expectedBillableTimePercent: number;
}

export interface ICreateUserFormDataToSubmit
  extends Omit<
    ICreateUserFormData,
    'dateOfBirth' | 'group' | 'calendar' | 'employmentStartDate' | 'billableStartDate' | 'technologies'
  > {
  dateOfBirth: string;
  groupId: number;
  calendarId: number;
  employmentStartDate: string;
  billableStartDate: string;
  technologyIds: Array<number>;
}

const requiredValidation = 'forms:validation:required';

export const buildValidationFields = () => ({
  firstName: yup.string().required(i18n.t(requiredValidation)).default(''),
  lastName: yup.string().required(i18n.t(requiredValidation)).default(''),
  email: yup.string().email(i18n.t('forms:validation.email')).required(i18n.t(requiredValidation)).default(''),
  userType: yup.mixed<UserType>().required(i18n.t(requiredValidation)).default(UserType.user),
  dateOfBirth: yup.date().nullable().default(null),
  location: yup
    .string()
    .max(100, i18n.t('forms:validation:maxSymbols', { count: 100 }))
    .default(''),
  group: yup.mixed<IGroup>().required(i18n.t(requiredValidation)).nullable().default(null),
  calendar: yup.mixed<IHolidayCalendar>().required(i18n.t(requiredValidation)).nullable().default(null),
  hoursPerWeek: yup
    .number()
    .min(0, i18n.t('forms:validation:minNumber', { count: 0 }))
    .max(168, i18n.t('forms:validation:maxNumber', { count: 168 }))
    .required(i18n.t(requiredValidation))
    .default(40),
  expectedBillableTimePercent: yup
    .number()
    .min(0, i18n.t('forms:validation:minNumber', { count: 0 }))
    .max(100, i18n.t('forms:validation:maxNumber', { count: 100 }))
    .required(i18n.t(requiredValidation))
    .default(100),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, { message: i18n.t('forms:validation.phoneNumber'), excludeEmptyString: true })
    .default(''),
  employmentStartDate: yup.date().required(i18n.t(requiredValidation)).default(new Date()),
  billableStartDate: yup
    .date()
    .required(i18n.t(requiredValidation))
    .min(yup.ref('employmentStartDate'), i18n.t('forms:validation:startDateBeforeStartBillableDate'))
    .default(new Date()),
  technologies: yup.array().of(yup.mixed<ITechnology>()).default([]),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (formData: ICreateUserFormData): ICreateUserFormDataToSubmit => {
  const {
    firstName,
    lastName,
    email,
    userType,
    dateOfBirth,
    location,
    group,
    calendar,
    hoursPerWeek,
    phoneNumber,
    employmentStartDate,
    billableStartDate,
    technologies,
    expectedBillableTimePercent,
  } = formData;
  const technologyIds = technologies?.map(({ id }) => id) ?? [];

  return {
    firstName,
    lastName,
    email,
    userType,
    dateOfBirth: _.isNil(dateOfBirth) ? '' : convertDateToUTCString(dateOfBirth),
    location,
    groupId: group.id,
    calendarId: calendar.id,
    hoursPerWeek,
    phoneNumber,
    employmentStartDate: convertDateToUTCString(employmentStartDate),
    billableStartDate: convertDateToUTCString(billableStartDate),
    technologyIds,
    expectedBillableTimePercent,
  };
};
