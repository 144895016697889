export default {
  vacancy: 'Vacancy',
  vacancyStack: 'Vacancy Stack',
  edit: 'Edit Stack',
  remove: 'Remove',
  editVacancyModalTitle: 'Edit Stack',
  editVacancyTechnologiesForm: {
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },
  },
  removeVacancyModalTitle: 'Remove Vacancy',
  removeVacancyModalSubtitle: 'You are about to remove <1>Vacancy</1><2></2>from the project <3>{{ projectName }}<3/>',
  removeVacancyEndDateForm: {
    fields: {
      endDate: {
        label: 'End Date',
      },
    },
    buttons: {
      remove: 'Remove',
      cancel: 'Cancel',
    },
  },
};
