import _ from 'lodash';
import * as yup from 'yup';

import { INTERVAL_COMPARISONS } from 'components/common/SmartFilter/relations';
import { ISmartFilterComparison } from 'components/common/SmartFilter/types';

import { FilterPredicate } from 'enums/FilterPredicate';

import { percentageInputRule } from 'utils/form';

export interface IWorkloadFormData {
  workload: string | null;
  comparison: ISmartFilterComparison | null;
}

const initialComparison = INTERVAL_COMPARISONS.find(comparison => comparison.predicate === FilterPredicate.eq);

export const buildValidationFields = () => ({
  workload: percentageInputRule,
  comparison: yup.mixed<ISmartFilterComparison>().nullable().default(initialComparison),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (form: IWorkloadFormData) => {
  return _.isNil(form)
    ? buildValidationSchema().getDefault()
    : {
        workload: form.workload,
        comparison: form.comparison,
      };
};
