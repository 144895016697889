import { FC, useContext } from 'react';

import { Button, ListItem, Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { ModalContext } from 'contexts/ModalContext';

import { useActivateTrackingTypeMutation, useDeleteTrackingTypeMutation } from 'domain/trackingType/apiSlice';
import { getTrackingTypeState } from 'domain/trackingType/service';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';

import UpdateTrackingTypeForm from './components/UpdateTrackingTypeForm';
import styles from './styles';
import { IAdminPanelInnerListItemProps } from './types';

const InnerListItem: FC<IAdminPanelInnerListItemProps> = props => {
  const { trackingType } = props;

  const { openModal } = useContext(ModalContext);

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const { t } = useTranslation('adminPanel');

  const [activateTrackingType, { isLoading: isActivateTrackingTypeLoading }] = useActivateTrackingTypeMutation();
  const [deleteTrackingType, { isLoading: isDeleteTrackingTypeLoading }] = useDeleteTrackingTypeMutation();

  const handleRenameTrackingTypeClick = () => {
    openModal({
      title: t('trackingTypes.mainListItem.innerListItem.editTrackingTypeFormTitle'),
      content: <UpdateTrackingTypeForm trackingType={trackingType} />,
    });
  };

  const handleActivate = async (id: number) => {
    try {
      await activateTrackingType(id).unwrap();

      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const handleArchive = async (id: number) => {
    try {
      await deleteTrackingType(id).unwrap();

      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  const { isArchived, isActive } = getTrackingTypeState(trackingType);

  const controlGroupsTreeButtonLabel = isArchived
    ? t('trackingTypes.mainListItem.innerListItem.buttons.activate')
    : t('trackingTypes.mainListItem.innerListItem.buttons.archive');
  const controlGroupsTreeButtonStyles = MUISx(
    { condition: isActive, sx: styles.archiveButton },
    { condition: isArchived, sx: styles.activateButton },
  );
  const handleControlGroupsTreeButtonClick = isArchived ? handleActivate : handleArchive;

  const isLoading = isActivateTrackingTypeLoading || isDeleteTrackingTypeLoading;

  return (
    <ListItem key={trackingType.id} sx={styles.innerListItem}>
      <Icon name="listPointer" sx={styles.listPointer} />

      <Div sx={MUISx(styles.textContainer, { condition: isArchived, sx: styles.archived })}>
        <Typography variant="subtitle1">{trackingType.name}</Typography>

        {isArchived && (
          <Typography variant="subtitle3" sx={styles.archivedTitle}>
            {t('trackingTypes.mainListItem.innerListItem.archived')}
          </Typography>
        )}

        <Div data-actions="item-actions" sx={styles.actions}>
          <Button
            variant="text"
            type="button"
            onClick={handleRenameTrackingTypeClick}
            disabled={isArchived || isLoading}
          >
            {t('trackingTypes.mainListItem.innerListItem.buttons.rename')}
          </Button>
          <Button
            variant="text"
            disabled={isLoading}
            sx={controlGroupsTreeButtonStyles}
            onClick={() => handleControlGroupsTreeButtonClick(trackingType.id)}
          >
            <Typography variant="subtitle3">{controlGroupsTreeButtonLabel}</Typography>
          </Button>
        </Div>
      </Div>
    </ListItem>
  );
};

export default InnerListItem;
