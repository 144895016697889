import { SxStyles } from 'theme';

const styles: SxStyles = {
  paginationWrapper: {
    alignItems: 'center',
    marginTop: 4,
    marginX: 0,
    marginBottom: 7.25,
  },
  pageNumbers: {
    flexGrow: 1,
  },
  perPage: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
    marginRight: 1.125,
  },
  selector: {
    width: '104px',
  },
  paginationButton: {
    alignItems: 'center',
    gap: 1,
    color: 'text.500',
  },
};

export default styles;
