import * as yup from 'yup';

import i18n from 'locales/i18n';

export interface ICreateProjectTaskFormData {
  name: string;
}

export interface ICreateProjectTaskFormDataToSubmit {
  projectId: number;
  formData: ICreateProjectTaskFormData;
}

export const buildValidationFields = () => ({
  name: yup
    .string()
    .max(100, i18n.t('forms:validation:maxNumber', { count: 100 }))
    .required()
    .default(''),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (
  projectId: number,
  formData: ICreateProjectTaskFormData,
): ICreateProjectTaskFormDataToSubmit => {
  return {
    projectId,
    formData: {
      ...formData,
    },
  };
};
