import { TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { ICreateResourceAbilityFormDataToSubmit } from './schemas/create';
import { IUpdateResourceAbilityFormDataToSubmit } from './schemas/update';
import { IResourceAbility } from './types';

interface IResourceAbilityParameters {
  id: number;
  resourceId: number;
}

interface IGetResourceAbilityResponse {
  ability: IResourceAbility;
}

interface IGetResourceAbilitiesResponse {
  abilities: Array<IResourceAbility>;
  meta: IMeta;
}

interface IGetResourceAbilitiesQueryParameters {
  s?: string;
}

interface IGetResourceAbilitiesPlainParameters extends IGetResourceAbilitiesQueryParameters {
  page?: number;
  perPage?: number;
  resourceId: number;
}

interface IGetResourceAbilitiesParameters {
  q?: IGetResourceAbilitiesQueryParameters;
  perPage?: number;
  page?: number;
  resourceId: number;
}

export const loadingGetResourceAbilitiesParameters = (
  parameters: IGetResourceAbilitiesPlainParameters,
): IGetResourceAbilitiesParameters => {
  const { resourceId, ...queryParameters } = parameters;
  return {
    resourceId,
    q: { ...queryParameters },
  };
};

export const resourceAbilitiesApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getResourceAbilities: builder.query<IGetResourceAbilitiesResponse, IGetResourceAbilitiesParameters>({
      query: ({ resourceId, ...parameters }) => ({
        url: apiRoutes.apiV1ResourceAbilitiesPath(resourceId),
        method: 'GET',
        params: {
          ...parameters,
        },
      }),
      providesTags: response => provideTags(TagTypes.ResourceAbility, response?.abilities),
    }),
    createResourceAbility: builder.mutation<IGetResourceAbilityResponse, ICreateResourceAbilityFormDataToSubmit>({
      query: ({ resourceId, formData }) => ({
        url: apiRoutes.apiV1ResourceAbilitiesPath(resourceId),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: [TagTypes.ResourceAbility, TagTypes.UserProfileStack],
    }),
    updateResourceAbility: builder.mutation<IGetResourceAbilityResponse, IUpdateResourceAbilityFormDataToSubmit>({
      query: ({ id, resourceId, formData }) => ({
        url: apiRoutes.apiV1ResourceAbilityPath(resourceId, id),
        method: 'PUT',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: ({ ability }) => [
        { type: TagTypes.ResourceAbility, id: ability.id },
        { type: TagTypes.UserProfileStack, id: ability.id },
      ],
    }),
    deleteResourceAbility: builder.mutation<void, IResourceAbilityParameters>({
      query: ({ id, resourceId }) => ({
        url: apiRoutes.apiV1ResourceAbilityPath(resourceId, id),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.ResourceAbility, TagTypes.UserProfileStack],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetResourceAbilitiesQuery,
  useCreateResourceAbilityMutation,
  useUpdateResourceAbilityMutation,
  useDeleteResourceAbilityMutation,
} = resourceAbilitiesApi;
