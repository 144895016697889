import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    minHeight: '100%',
  },
  tagsListContainer: {
    marginTop: 2.5,
    marginLeft: 1.875,
  },
};

export default styles;
