import { SxStyles } from 'theme';

const styles: SxStyles = {
  select: {
    width: '100%',
  },
  selectPlaceholder: {
    color: 'text.gray',
  },
  chip: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.blue',
    borderRadius: '4px',
    backgroundColor: 'background.white',
  },
  closeChipIcon: {
    width: '24px',
    height: '24px',
    stroke: theme => theme.palette.primary.blue,
  },
};

export default styles;
