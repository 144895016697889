import _ from 'lodash';

import { formatToDateWithFixedTimeZone } from 'utils/dateTime';

import {
  ResourceTrackedTimeEntriesResponseDTO,
  ResourceTrackedTimeEntriesResponse,
  ResourceTrackedTimeEntriesByDate,
  IResourceTrackedTimeEntry,
  ResourceTrackedTimeDayEntries,
  ResourceTrackedTimeEntriesByWeek,
  IGetResourceTrackedTimeEntriesPlainParameters,
  IGetResourceTrackedTimeEntriesParameters,
} from './types';

export const buildResourceTrackedTimeEntriesParameters = (
  parameters: IGetResourceTrackedTimeEntriesPlainParameters,
): IGetResourceTrackedTimeEntriesParameters => {
  const { page, perPage, resourceId, numberOfDays, ...queryParameters } = parameters;
  return {
    perPage,
    page,
    numberOfDays,
    resourceId,
    q: { ...queryParameters },
  };
};

const buildDayEntries = (
  entriesByDate: ResourceTrackedTimeEntriesByDate,
  entry: IResourceTrackedTimeEntry,
): ResourceTrackedTimeDayEntries => {
  const { date, duration } = entry;
  const dayEntries = entriesByDate[date];

  if (dayEntries) {
    return {
      entries: [...entriesByDate[date].entries, entry],
      totalDayMinutes: dayEntries.totalDayMinutes + duration,
    };
  }

  return { entries: [entry], totalDayMinutes: duration };
};

export const mapTrackedTimeEntriesByWeekAndDay = (
  response: ResourceTrackedTimeEntriesResponseDTO,
): ResourceTrackedTimeEntriesResponse => {
  const { trackedTimeEntries, ...metaData } = response;

  const entriesByWeek = trackedTimeEntries.reduce((weeks: ResourceTrackedTimeEntriesByWeek, entry) => {
    const { date, weekStartDate, weekNumber, weekRange, duration } = entry;

    if (_.has(weeks, weekStartDate)) {
      const entriesByDate = weeks[weekStartDate].entriesByDate;
      const totalWeekMinutes = weeks[weekStartDate].totalWeekMinutes + duration;
      const newDateEntries = buildDayEntries(entriesByDate, entry);

      return {
        ...weeks,
        [weekStartDate]: {
          ...weeks[weekStartDate],
          totalWeekMinutes,
          entriesByDate: { ...entriesByDate, [date]: newDateEntries },
        },
      };
    }

    return {
      ...weeks,
      [weekStartDate]: {
        weekNumber,
        weekRange,
        totalWeekMinutes: duration,
        entriesByDate: { [date]: { entries: [entry], totalDayMinutes: duration } },
      },
    };
  }, {});

  const sortedWeekKeys = _.orderBy(Object.keys(entriesByWeek), formatToDateWithFixedTimeZone, ['desc']);

  return {
    ...metaData,
    trackedTimeEntries,
    trackedTimeEntriesGroupedByDate: {
      entriesByWeek,
      sortedWeekKeys,
    },
  };
};
