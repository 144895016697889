import { TagTypes } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { ICurrentUserImpersonation } from './types';

interface ICurrentUserImpersonationResponse {
  impersonation: ICurrentUserImpersonation;
}

interface ICreateCurrentUserImpersonation {
  subjectId: number;
}

interface ICreateCurrentUserImpersonationDataToSubmit {
  impersonation: ICreateCurrentUserImpersonation;
}

export const currentUserImpersonationApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getCurrentUserImpersonation: builder.query<ICurrentUserImpersonationResponse, void>({
      query: () => {
        return {
          url: apiRoutes.apiV1CurrentUserImpersonationPath(),
          method: 'GET',
        };
      },
      providesTags: [TagTypes.CurrentUserImpersonation],
    }),
    createCurrentUserImpersonation: builder.mutation<
      ICurrentUserImpersonationResponse,
      ICreateCurrentUserImpersonationDataToSubmit
    >({
      query: data => ({
        url: apiRoutes.apiV1CurrentUserImpersonationPath(),
        method: 'POST',
        data,
      }),
    }),
    deleteCurrentUserImpersonation: builder.mutation<void, void>({
      query: () => ({
        url: apiRoutes.apiV1CurrentUserImpersonationPath(),
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCurrentUserImpersonationQuery,
  useCreateCurrentUserImpersonationMutation,
  useDeleteCurrentUserImpersonationMutation,
} = currentUserImpersonationApi;
