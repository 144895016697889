import { FC } from 'react';

import DateRangePicker from 'components/common/DateRangePicker';
import { DateRange } from 'components/common/DateRangePicker/types';
import Div from 'components/common/Div';

import { getDateRange, RangeType } from 'utils/dateRangePicker';

import GroupedByCategory from './components/GroupedByCategory';
import styles from './styles';
import { IReportTrackedTimeEntriesFilterProps } from './types';

const Filter: FC<IReportTrackedTimeEntriesFilterProps> = props => {
  const { filter, setFilter, group, setGroup, onReset } = props;

  const onDateRangeChange = (newDateRange: DateRange | null) => {
    onReset();
    setFilter({
      ...filter,
      beforeDate: newDateRange?.endDate,
      afterDate: newDateRange?.startDate,
    });
  };

  return (
    <Div sx={styles.root}>
      <DateRangePicker
        currentDateRange={getDateRange(filter.afterDate, filter.beforeDate, RangeType.week)}
        onDateRangeChange={onDateRangeChange}
      />
      <GroupedByCategory group={group} setGroup={setGroup} />
    </Div>
  );
};

export default Filter;
