import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    marginY: 3,
    marginX: 'auto',
  },
  table: {
    borderStyle: 'hidden',
    borderColor: 'border.main',
    borderCollapse: 'collapse',
    boxShadow: '0 0 0 1px rgba(200, 209, 216, 0.85)',
  },
  headerCell: {
    borderBottom: '1px solid',
    borderColor: 'border.main',
  },
  cell: {
    borderBottom: '1px solid',
    borderColor: 'border.main',
  },
};

export default styles;
