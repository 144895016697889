import { FC } from 'react';

import { Typography } from '@mui/material';
import MUISx from 'mui-sx';

import i18n from 'locales/i18n';

import Div from 'components/common/Div';

import { adjustWeekDays } from 'utils/calendar';

import styles from './styles';
import { IWeekDaysProps } from './types';

const weekDays = [
  { index: 0, label: i18n.t('common:weekDay.sunday'), isWeekend: true },
  { index: 1, label: i18n.t('common:weekDay.monday') },
  { index: 2, label: i18n.t('common:weekDay.tuesday') },
  { index: 3, label: i18n.t('common:weekDay.wednesday') },
  { index: 4, label: i18n.t('common:weekDay.thursday') },
  { index: 5, label: i18n.t('common:weekDay.friday') },
  { index: 6, label: i18n.t('common:weekDay.saturday'), isWeekend: true },
];

const WeekDays: FC<IWeekDaysProps> = props => {
  const { weekStartsOn } = props;

  const adjustedWeekDays = adjustWeekDays(weekDays, weekStartsOn);

  return (
    <Div sx={styles.root}>
      {adjustedWeekDays.map(({ label, isWeekend }) => (
        <Div sx={MUISx(styles.cell, { condition: isWeekend, sx: styles.weekend })} key={label}>
          <Typography variant="h6">{label}</Typography>
        </Div>
      ))}
    </Div>
  );
};

export default WeekDays;
