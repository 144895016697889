import _ from 'lodash';

import styles from './styles';
import allocation from './svg/allocation.svg';
import apply from './svg/apply.svg';
import arrowRight from './svg/arrowRight.svg';
import avatarPlaceholder from './svg/avatarPlaceholder.svg';
import billable from './svg/billable.svg';
import briefcase from './svg/briefcase.svg';
import calendar from './svg/calendar.svg';
import calendarSmall from './svg/calendarSmall.svg';
import closeWithDarkCircle from './svg/closeWithDarkCircle.svg';
import closeWithoutCircle from './svg/closeWithoutCircle.svg';
import copy from './svg/copy.svg';
import cross from './svg/cross.svg';
import dashboard from './svg/dashboard.svg';
import dollarInCircle from './svg/dollarInCircle.svg';
import ellipseListPointer from './svg/ellipseListPointer.svg';
import errorOutline from './svg/errorOutline.svg';
import externalLink from './svg/externalLink.svg';
import healthCheck from './svg/healthCheck.svg';
import hierarchy from './svg/hierarchy.svg';
import hours from './svg/hours.svg';
import letter from './svg/letter.svg';
import listPointer from './svg/listPointer.svg';
import location from './svg/location.svg';
import logo from './svg/logo.svg';
import myActionItems from './svg/myActionItems.svg';
import phone from './svg/phone.svg';
import planned from './svg/planned.svg';
import plus from './svg/plus.svg';
import plusBorderless from './svg/plusBorderless.svg';
import points from './svg/points.svg';
import projects from './svg/projects.svg';
import reports from './svg/reports.svg';
import resources from './svg/resources.svg';
import sendRequest from './svg/sendRequest.svg';
import team from './svg/team.svg';
import text from './svg/text.svg';
import time from './svg/time.svg';
import trash from './svg/trash.svg';
import twoPeople from './svg/twoPeople.svg';
import users from './svg/users.svg';
import vacation from './svg/vacation.svg';
import x from './svg/x.svg';
import { IIconDimensions, IBrowserSpriteSymbol, IIconAttributes } from './types';

const getIconDimensions = (viewbox: string): IIconDimensions => {
  const [width, height] = _.takeRight(viewbox.split(' '), 2);

  return {
    width,
    height,
  };
};

const getIconAttributes = (name: IBrowserSpriteSymbol): IIconAttributes => {
  const getIconStyles = {
    errorOutline: styles.errorOutline,
  };

  const { width, height } = getIconDimensions(name.viewBox);
  const iconStyles = getIconStyles[name.id];

  return {
    src: name,
    styles: iconStyles,
    viewBox: name.viewBox,
    id: `#${name.id}`,
    width,
    height,
  };
};

export default {
  dashboard: getIconAttributes(dashboard),
  logo: getIconAttributes(logo),
  healthCheck: getIconAttributes(healthCheck),
  myActionItems: getIconAttributes(myActionItems),
  twoPeople: getIconAttributes(twoPeople),
  projects: getIconAttributes(projects),
  reports: getIconAttributes(reports),
  resources: getIconAttributes(resources),
  users: getIconAttributes(users),
  x: getIconAttributes(x),
  calendar: getIconAttributes(calendar),
  calendarSmall: getIconAttributes(calendarSmall),
  plus: getIconAttributes(plus),
  externalLink: getIconAttributes(externalLink),
  dollarInCircle: getIconAttributes(dollarInCircle),
  planned: getIconAttributes(planned),
  closeWithoutCircle: getIconAttributes(closeWithoutCircle),
  briefcase: getIconAttributes(briefcase),
  location: getIconAttributes(location),
  time: getIconAttributes(time),
  letter: getIconAttributes(letter),
  phone: getIconAttributes(phone),
  listPointer: getIconAttributes(listPointer),
  plusBorderless: getIconAttributes(plusBorderless),
  points: getIconAttributes(points),
  closeWithDarkCircle: getIconAttributes(closeWithDarkCircle),
  copy: getIconAttributes(copy),
  trash: getIconAttributes(trash),
  ellipseListPointer: getIconAttributes(ellipseListPointer),
  arrowRight: getIconAttributes(arrowRight),
  hierarchy: getIconAttributes(hierarchy),
  allocation: getIconAttributes(allocation),
  team: getIconAttributes(team),
  avatarPlaceholder: getIconAttributes(avatarPlaceholder),
  cross: getIconAttributes(cross),
  apply: getIconAttributes(apply),
  sendRequest: getIconAttributes(sendRequest),
  hours: getIconAttributes(hours),
  text: getIconAttributes(text),
  vacation: getIconAttributes(vacation),
  billable: getIconAttributes(billable),
  errorOutline: getIconAttributes(errorOutline),
};
