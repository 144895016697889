import React, { FC, useContext, useState } from 'react';

import { Alert } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import SearchBar from 'components/pages/AdminPanel/components/SearchBar';

import { ModalContext } from 'contexts/ModalContext';

import { useGetTagsQuery, useCreateTagMutation } from 'domain/tag/apiSlice';
import { formatAttributesToCreateSubmit, ICreateTagFormData } from 'domain/tag/schemas/form';

import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';
import { getSortParameter } from 'utils/runsack';

import TagForm from './components/TagForm';
import TagsList from './components/TagsList';
import styles from './styles';

const Tags: FC = () => {
  const [searchString, setSearchString] = useState<string>('');

  const { t } = useTranslation('adminPanel');

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const { openModal, closeModal } = useContext(ModalContext);

  const [debouncedSearchString] = useDebounce(searchString, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: tagsData,
    isLoading: isTagsDataLoading,
    isError: isTagsDataLoadingError,
  } = useGetTagsQuery({
    nameCont: debouncedSearchString,
    sort: getSortParameter('name', 'asc'),
  });

  const [createTag, { isLoading: isCreateTagLoading }] = useCreateTagMutation();

  const handleAddTagButtonClick = async (formData: ICreateTagFormData) => {
    try {
      const dataToSubmit = formatAttributesToCreateSubmit(formData);
      await createTag(dataToSubmit).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const handleAddTagModalOpen = () => {
    openModal({
      title: t('tags.createTagForm.title'),
      content: (
        <TagForm
          onSubmit={handleAddTagButtonClick}
          disabled={isCreateTagLoading}
          submitButtonLabel={t('tags.addTag')}
        />
      ),
    });
  };

  if (isTagsDataLoading) {
    return <Loader />;
  }

  if (isTagsDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  return (
    <Div sx={styles.container}>
      <Div sx={styles.searchBar}>
        <SearchBar
          searchString={searchString}
          setSearchString={setSearchString}
          placeholder={t('tags.searchBar')}
          buttonLabel={t('tags.addButton')}
          onAddNodeButtonClick={handleAddTagModalOpen}
          buttonWidth={125}
        />
        {!_.isNil(tagsData) && (
          <Div sx={styles.tagsListContainer}>
            <TagsList tags={tagsData.tags} />
          </Div>
        )}
      </Div>
    </Div>
  );
};

export default Tags;
