import { SxStyles } from 'theme';

const styles: SxStyles = {
  input: {
    width: '360px',
  },
  emailInput: {
    marginBottom: 2,
  },
  passwordInput: {
    marginBottom: 5,
  },
  button: {
    width: '240px',
  },
  submitButton: {
    marginBottom: 1.875,
  },
};

export default styles;
