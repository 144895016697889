import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { Avatar, Button, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks';

import Div from 'components/common/Div';
import LinkTab from 'components/common/LinkTab';
import SmartFilter from 'components/common/SmartFilter';
import { pickFieldsForSmartFilter } from 'components/common/SmartFilter/service';
import { ISmartFilter } from 'components/common/SmartFilter/types';
import TabPanel from 'components/common/TabPanel';

import { ModalContext } from 'contexts/ModalContext';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { useGetCurrentUserPermissionsQuery } from 'domain/currentUser/permission/apiSlice';
import { PermissionTargets } from 'domain/currentUser/permission/enums';
import { useGetReportMigrationsQuery } from 'domain/report/migrations/apiSlice';
import { UsersMigrationsTableColumnAccessor } from 'domain/report/migrations/enums';
import { IReportMigrationsResource } from 'domain/report/migrations/types';
import { IUsersSmartFilters, useGetUsersQuery } from 'domain/user/apiSlice';
import { isAdmin, isSuperAdmin } from 'domain/user/service';
import { IUser } from 'domain/user/types';

import { IPaginationParameters } from 'types/api';
import { ISortParameter } from 'types/runsack';

import { defaultPaginationParameters } from 'utils/pagination';
import { getSortParameter } from 'utils/runsack';

import avatarPlaceholder from '../../common/Icon/svg/avatarPlaceholder.svg';

import CreateUserForm from './components/CreateUserForm';
import Table from './components/Table';
import TableCell from './components/TableCell';
import TableCellSpecialization from './components/TableCellSpecialization';
import TableHeader from './components/TableHeader';
import TableNameCell from './components/TableNameCell';
import { TabName, UsersTableColumnAccessor } from './enums';
import styles from './styles';

const FILTERS: Array<ISmartFilter> = ['user', 'group', 'state', 'calendar', 'technology'];
const RESOURCES_FILTERS: Array<ISmartFilter> = ['user', 'group'];

const Users: FC = () => {
  const { queryStringUpdate, queryStringClear, camelizedQuery, navigate } = useRouter();

  const { data: currentUser } = useGetCurrentUserQuery();

  const { pathname } = useRouter();

  const { t } = useTranslation(['common', 'users']);
  const { openModal } = useContext(ModalContext);

  const [smartFilter, setSmartFilter] = useState<IUsersSmartFilters | null>(
    pickFieldsForSmartFilter(camelizedQuery, FILTERS),
  );

  const [resourcesSmartFilter, setResourcesSmartFilter] = useState<IUsersSmartFilters | null>(
    pickFieldsForSmartFilter(camelizedQuery, FILTERS),
  );

  const [currentTabIndex, setCurrentTabName] = useState<string>(TabName.users);

  const [paginationParameters, setPaginationParameters] = useState<IPaginationParameters>({
    pageNumber: Number(camelizedQuery.page) || defaultPaginationParameters.pageNumber,
    pageSize: Number(camelizedQuery.perPage) || defaultPaginationParameters.pageSize,
  });

  const [paginationUserMigrationsParameters, setPaginationUserMigrationsParameters] = useState<IPaginationParameters>({
    pageNumber: Number(camelizedQuery.page) || defaultPaginationParameters.pageNumber,
    pageSize: Number(camelizedQuery.perPage) || defaultPaginationParameters.pageSize,
  });

  const getUsersQueryFiltersParameters = {
    perPage: paginationParameters.pageSize,
    page: paginationParameters.pageNumber,
    sort: (camelizedQuery.sort as ISortParameter) || getSortParameter('name', 'asc'),
  };

  const getUsersMigrationsFiltersParameters = {
    start_date: '01-01-2023',
    page: paginationUserMigrationsParameters.pageNumber,
    per_page: paginationUserMigrationsParameters.pageSize,
  };

  useEffect(() => {
    const parameters = {
      ...getUsersQueryFiltersParameters,
      ...smartFilter,
      page: paginationParameters.pageNumber,
      perPage: paginationParameters.pageSize,
    };

    queryStringUpdate(parameters);
  }, [smartFilter, paginationParameters]);

  useEffect(() => {
    const parameters = {
      ...resourcesSmartFilter,
      ...getUsersMigrationsFiltersParameters,
      page: paginationUserMigrationsParameters.pageNumber,
      perPage: paginationUserMigrationsParameters.pageSize,
    };

    queryStringUpdate(parameters);
  }, [resourcesSmartFilter, paginationUserMigrationsParameters]);

  const {
    data: usersData,
    isLoading: isUsersDataLoading,
    isError: isUsersDataLoadingError,
  } = useGetUsersQuery({
    ...getUsersQueryFiltersParameters,
    ...smartFilter,
  });

  const {
    data: usersMigrationsData,
    isLoading: isUsersMigrationsDataLoading,
    isError: isUsersMigrationsDataLoadingError,
  } = useGetReportMigrationsQuery({
    ...resourcesSmartFilter,
    ...getUsersMigrationsFiltersParameters,
  });

  const handleCreateModalOpen = () => {
    openModal({
      title: t('users:createUserModalTitle'),
      content: <CreateUserForm />,
    });
  };

  const pagination = {
    totalPages: usersData?.meta.totalPages,
    pageSize: usersData?.meta.perPage,
    pageNumber: usersData?.meta.currentPage,
  };

  const paginationUserMigrations = {
    totalPages: usersMigrationsData?.meta.totalPages,
    pageSize: usersMigrationsData?.meta.perPage,
    pageNumber: usersMigrationsData?.meta.currentPage,
  };

  const handlePaginationChange = (parameters: IPaginationParameters) => {
    setPaginationParameters(parameters);
  };

  const handleUserMigrationsPaginationChange = (parameters: IPaginationParameters) => {
    setPaginationUserMigrationsParameters(parameters);
  };

  const handleSmartFiltersChange = (filters: IUsersSmartFilters | null) => {
    setSmartFilter(filters);
    setPaginationParameters({ pageNumber: defaultPaginationParameters.pageNumber, pageSize: pagination.pageSize ?? 0 });
  };

  const handleResourcesSmartFiltersChange = (filters: IUsersSmartFilters | null) => {
    setResourcesSmartFilter(filters);
    setPaginationUserMigrationsParameters({
      pageNumber: defaultPaginationParameters.pageNumber,
      pageSize: pagination.pageSize ?? 0,
    });
  };

  const USERS_LIST_COLUMNS = useMemo(() => {
    return [
      {
        Header: <TableHeader title={t('users:table.headers.name')} />,
        accessor: UsersTableColumnAccessor.name,
        minWidth: '220px',
      },
      {
        Header: <TableHeader title={t('users:table.headers.email')} />,
        accessor: UsersTableColumnAccessor.email,
        minWidth: '310px',
      },
      {
        Header: <TableHeader title={t('users:table.headers.specialization')} />,
        accessor: UsersTableColumnAccessor.technologies,
        minWidth: '200px',
      },
      {
        Header: <TableHeader title={t('users:table.headers.physicalLocation')} />,
        accessor: UsersTableColumnAccessor.physicalLocation,
        minWidth: '200px',
      },
      {
        Header: <TableHeader title={`${t('users:table.headers.userType')}*`} />,
        accessor: UsersTableColumnAccessor.userType,
        minWidth: '100px',
      },
      {
        Header: <TableHeader title={`${t('users:table.headers.homeLocation')}*`} />,
        accessor: UsersTableColumnAccessor.homeLocation,
        minWidth: '200px',
      },
      {
        Header: <TableHeader title={t('users:table.headers.state')} />,
        accessor: UsersTableColumnAccessor.state,
      },
      {
        Header: <TableHeader title={t('users:table.headers.workingDaysCalendar')} />,
        accessor: UsersTableColumnAccessor.workingDaysCalendar,
        minWidth: '300px',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return usersData?.users.map((user: IUser) => {
      const { email, state, userType, technologies, location, group, calendar } = user;

      return {
        [UsersTableColumnAccessor.name]: <TableNameCell user={user} />,
        [UsersTableColumnAccessor.email]: <TableCell value={email} />,
        [UsersTableColumnAccessor.technologies]: <TableCellSpecialization value={technologies} />,
        [UsersTableColumnAccessor.physicalLocation]: <TableCell value={location} />,
        [UsersTableColumnAccessor.userType]: <TableCell value={userType} />,
        [UsersTableColumnAccessor.homeLocation]: <TableCell value={group.ancestorsPath} />,
        [UsersTableColumnAccessor.state]: <TableCell value={state} />,
        [UsersTableColumnAccessor.workingDaysCalendar]: <TableCell value={calendar.name} />,
      };
    });
  }, [usersData]);

  const { data: currentUserPermissions, isLoading: isCurrentUserPermissionsLoading } =
    useGetCurrentUserPermissionsQuery({
      permissionTarget: PermissionTargets.users,
    });
  const canCreateUser = currentUserPermissions?.permissions.canCreateUser;
  const openUserProfile = (userId: number) => {
    navigate(`/users/${userId}/profile`);
  };

  const tableUserMigrationsData = useMemo(() => {
    if (!usersMigrationsData) return;
    return usersMigrationsData?.usersMigrations?.map((migration: IReportMigrationsResource) => {
      const { hierarchy, firstName, lastName, receiptDate, eliminationDate, userId } = migration;

      return {
        [UsersMigrationsTableColumnAccessor.resource]: (
          <Div sx={styles.resourceColumn} onClick={() => openUserProfile(userId)}>
            <Avatar src={avatarPlaceholder} />
            <TableCell value={`${firstName} ${lastName}`} />
          </Div>
        ),
        [UsersMigrationsTableColumnAccessor.hierarchy]: <TableCell value={hierarchy} />,
        [UsersMigrationsTableColumnAccessor.receiptDate]: <TableCell value={receiptDate} />,
        [UsersMigrationsTableColumnAccessor.eliminationDate]: <TableCell value={eliminationDate ?? 'No data'} />,
      };
    });
  }, [usersMigrationsData]);

  const USERS_MIGRATION_LIST_COLUMNS = useMemo(() => {
    return [
      {
        Header: <TableHeader title={t('users:table.headers.resource')} />,
        accessor: UsersMigrationsTableColumnAccessor.resource,
        minWidth: '220px',
      },
      {
        Header: <TableHeader title={t('users:table.headers.hierarchy')} />,
        accessor: UsersMigrationsTableColumnAccessor.hierarchy,
        minWidth: '220px',
      },
      {
        Header: <TableHeader title={t('users:table.headers.receiptDate')} />,
        accessor: UsersMigrationsTableColumnAccessor.receiptDate,
        minWidth: '220px',
      },
      {
        Header: <TableHeader title={t('users:table.headers.eliminationDate')} />,
        accessor: UsersMigrationsTableColumnAccessor.eliminationDate,
        minWidth: '220px',
      },
    ];
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabName(newValue);
  };

  const adminCheck = currentUser && (isAdmin(currentUser.user) || isSuperAdmin(currentUser.user));
  const temporaryName = 'cruds';
  return (
    <>
      <Div sx={styles.header}>
        <Typography variant="h1">{t('users:title')}</Typography>
        {canCreateUser && currentTabIndex === TabName.users && (
          <Button onClick={() => handleCreateModalOpen()}>{t('users:buttons.createUser')}</Button>
        )}
      </Div>
      <Div>
        <Tabs value={currentTabIndex} onChange={handleTabChange} aria-label="tabs" textColor="inherit">
          <LinkTab
            label={t('users:tabs.users')}
            value={TabName.users}
            blockLabel={temporaryName}
            to={{ path: pathname, hash: TabName.users }}
          />
          {adminCheck && (
            <LinkTab
              label={t('users:tabs.resources')}
              value={TabName.resources}
              blockLabel={temporaryName}
              to={{ path: pathname, hash: TabName.resources }}
            />
          )}
        </Tabs>
      </Div>
      <Div>
        <TabPanel value={currentTabIndex} name={TabName.users} tabGroupName={temporaryName}>
          <Div sx={styles.smartFilter}>
            <SmartFilter<IUsersSmartFilters>
              smartFilter={smartFilter}
              onSmartFilterChange={handleSmartFiltersChange}
              filters={FILTERS}
              onReset={queryStringClear}
            />
          </Div>
          <Table
            data={tableData}
            columns={USERS_LIST_COLUMNS}
            isLoading={isUsersDataLoading || isCurrentUserPermissionsLoading}
            isError={isUsersDataLoadingError}
            pagination={pagination}
            onPaginationChange={handlePaginationChange}
          />
        </TabPanel>
        {adminCheck && (
          <TabPanel value={currentTabIndex} name={TabName.resources} tabGroupName={temporaryName}>
            <Div sx={styles.smartFilter}>
              <SmartFilter<IUsersSmartFilters>
                smartFilter={resourcesSmartFilter}
                onSmartFilterChange={handleResourcesSmartFiltersChange}
                filters={RESOURCES_FILTERS}
                onReset={queryStringClear}
              />
            </Div>
            <Table
              data={tableUserMigrationsData}
              columns={USERS_MIGRATION_LIST_COLUMNS}
              isLoading={isUsersMigrationsDataLoading}
              isError={isUsersMigrationsDataLoadingError}
              pagination={paginationUserMigrations}
              onPaginationChange={handleUserMigrationsPaginationChange}
            />
          </TabPanel>
        )}
      </Div>
    </>
  );
};

export default Users;
