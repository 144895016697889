import { Lock } from '@mui/icons-material';
import { List, ListItem, Typography } from '@mui/material';
import { isBefore } from 'date-fns';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ResourceTrackedTimeDayEntries } from 'domain/resource/trackedTimeEntry/types';

import { formatDateTime, formatToDateWithFixedTimeZone, getHoursAndMinutesStringFromMinutes } from 'utils/dateTime';

import ResourceTrackedTimeEntry from './components/ResourceTrackedTimeEntry';
import styles from './styles';

interface IDayListProps {
  resourceId: number;
  date: string;
  editLockDate: string;
  dayEntries: ResourceTrackedTimeDayEntries;
  onTimeTrackerSettingsCopy: (formData) => void;
}

const DayList = (props: IDayListProps) => {
  const { resourceId, dayEntries, date, editLockDate, onTimeTrackerSettingsCopy } = props;

  const { t } = useTranslation('timeTracker');

  const { totalDayMinutes, entries } = dayEntries;
  const sortedResourceTrackedEntries = _.orderBy(entries, ['id'], ['desc']);

  const groupHeaderDate = formatDateTime(date);

  const isBeforeLockDate = isBefore(formatToDateWithFixedTimeZone(date), formatToDateWithFixedTimeZone(editLockDate));
  const isLockedPeriod = isBeforeLockDate || date === editLockDate;

  return (
    <>
      {isLockedPeriod && (
        <Div sx={styles.lockDateRoot}>
          <Lock />
          <Div>{t('trackedTimeEntriesGroupList.lockedPeriod')}</Div>
        </Div>
      )}
      <ListItem sx={styles.root}>
        <Div sx={styles.header}>
          <Typography sx={styles.headerTitle} variant="body1">
            {groupHeaderDate}
          </Typography>
          <Typography sx={MUISx(styles.headerTitle, styles.totalEntriesHours)} variant="h4" component="p">
            {getHoursAndMinutesStringFromMinutes(totalDayMinutes)}
          </Typography>
        </Div>
        <List sx={styles.group}>
          {sortedResourceTrackedEntries.map(resourceTrackedTimeEntry => (
            <ResourceTrackedTimeEntry
              key={resourceTrackedTimeEntry.id}
              resourceTrackedTimeEntry={resourceTrackedTimeEntry}
              onTimeTrackerSettingsCopy={onTimeTrackerSettingsCopy}
              resourceId={resourceId}
            />
          ))}
        </List>
      </ListItem>
    </>
  );
};

export default DayList;
