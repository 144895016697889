import { FC, useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import _ from 'lodash';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Autocomplete from 'components/common/Autocomplete';
import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { ModalContext } from 'contexts/ModalContext';

import { useGetTechnologiesQuery, useUpdateTechnologyMutation } from 'domain/technology/apiSlice';
import {
  formatAttributesToSubmit,
  initialValues,
  IUpdateTechnologyFormData,
  buildValidationSchema,
} from 'domain/technology/schemas/update';
import { ITechnology } from 'domain/technology/types';

import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';
import { getSortParameter } from 'utils/runsack';

import styles from './styles';
import { IUpdateTechnologyFormProps } from './types';

const UpdateTechnologyForm: FC<IUpdateTechnologyFormProps> = props => {
  const { currentParentTechnology, currentTechnology } = props;

  const [parentTechnologyName, setParentTechnologyName] = useState<string>('');
  const [debouncedTechnologyName] = useDebounce(parentTechnologyName, DEFAULT_DEBOUNCE_DELAY_MS);

  const { t } = useTranslation('adminPanel');

  const {
    data: technologiesData,
    isLoading: isTechnologiesDataLoading,
    isFetching: isTechnologiesDataFetching,
  } = useGetTechnologiesQuery({
    nameCont: debouncedTechnologyName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const { closeModal } = useContext(ModalContext);
  const [updateTechnology, { isLoading: isUpdateTechnologyLoading }] = useUpdateTechnologyMutation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IUpdateTechnologyFormData>({
    defaultValues: initialValues(currentTechnology, currentParentTechnology),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: parentTechnology, onChange: onParentTechnologyChange },
  } = useController({ name: 'parentTechnology', control });

  const onSubmit = async (formData: IUpdateTechnologyFormData) => {
    try {
      const parameters = formatAttributesToSubmit(currentTechnology.id, formData);
      await updateTechnology(parameters).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  if (isTechnologiesDataLoading) {
    return <Loader />;
  }

  const handleCurrentParentTechnologyChange = (currentParentTechnology: ITechnology) => {
    if (_.isNil(currentParentTechnology)) {
      onParentTechnologyChange(null);
      return;
    }
    onParentTechnologyChange(currentParentTechnology);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.input}>
        <TextField
          variant="outlined"
          error={Boolean(errors.name)}
          fullWidth
          label={t('specializations.inputName')}
          {...register('name')}
          disabled={isUpdateTechnologyLoading}
          helperText={buildErrorMessage(t, errors.name)}
        ></TextField>
      </Div>

      <Autocomplete
        options={technologiesData.technologies}
        isFetching={isTechnologiesDataFetching}
        isError={Boolean(errors.parentTechnology)}
        name={parentTechnologyName}
        setName={setParentTechnologyName}
        onCurrentValueChange={handleCurrentParentTechnologyChange}
        currentValue={parentTechnology}
        label={t('specializations.inputParent')}
      />

      <Div sx={styles.buttonsContainer}>
        <Button type="submit" variant="contained" disabled={isUpdateTechnologyLoading}>
          {t('specializations.editStack')}
        </Button>
        <Button variant="outlined" onClick={closeModal} disabled={isUpdateTechnologyLoading}>
          {t('buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default UpdateTechnologyForm;
