import { SxStyles } from 'theme';

const styles: SxStyles = {
  endDatePicker: {
    paddingTop: 1,
  },
  buttons: {
    marginTop: 4,
  },
  removeButton: {
    marginRight: 1.25,
  },
};

export default styles;
