import { FC, useState } from 'react';

import { Alert } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Loader from 'components/common/Loader';
import MultipleAutocomplete from 'components/common/SmartFilter/components/MultipleAutocomplete';
import { IMultipleSlot } from 'components/common/SmartFilter/types';

import { useGetGroupsQuery } from 'domain/group/apiSlice';
import { IGroup } from 'domain/group/types';

import { SuggestionAutocompleteType } from 'enums/SuggestionAutocomplete';

import { IAutocompleteOption } from 'types/autocomplete';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { getSortParameter } from 'utils/runsack';
import { getPreparedDataForSuggestionAutocomplete } from 'utils/suggestionAutocomplete';

import { IGroupsProps } from './types';

const Groups: FC<IGroupsProps> = props => {
  const { slot, onSlotPatch, setOpenPopover } = props;

  const { t } = useTranslation(['common', 'smartFilter']);

  const value = slot.value as Array<IMultipleSlot>;

  const [groupName, setGroupName] = useState<string>('');

  const [debouncedGroupName] = useDebounce(groupName, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: groupsData,
    isLoading: isGroupsDataLoading,
    isFetching: isGroupsDataFetching,
    isError: isGroupsDataLoadingError,
  } = useGetGroupsQuery({
    nameCont: debouncedGroupName,
    paginated: false,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  if (isGroupsDataLoading) {
    return <Loader />;
  }

  if (isGroupsDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCurrentGroupsChange = (groups: Array<IAutocompleteOption>, filterWithEnter = false) => {
    if (_.isEmpty(groups)) {
      onSlotPatch(
        slot.id,
        {
          value: [],
        },
        filterWithEnter,
      );
      return;
    }

    const value = groups.map(group => ({ id: group.id, name: group.name }));

    onSlotPatch(
      slot.id,
      {
        value,
      },
      filterWithEnter,
    );
  };

  const options = getPreparedDataForSuggestionAutocomplete<IGroup>({
    data: groupsData.groups,
    type: SuggestionAutocompleteType.tree,
  });
  const currentOptions = getPreparedDataForSuggestionAutocomplete<IMultipleSlot>({ data: value });

  return (
    <MultipleAutocomplete
      options={options}
      currentOptions={currentOptions}
      isFetching={isGroupsDataFetching}
      onCurrentOptionsChange={handleCurrentGroupsChange}
      setName={setGroupName}
      name={groupName}
      label={t('smartFilter:groups.label')}
      setOpenPopover={setOpenPopover}
    />
  );
};

export default Groups;
