import { TableBody } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import range from 'lodash/range';

import Row from './components/Row';
import SkeletonRow from './components/SkeletonRow';
import styles from './styles';
import { IBodyProps } from './types';

const MAX_RESOURCE_COUNT = 15;

const Body = (props: IBodyProps) => {
  const { isFetching, isWideRange, resources, stickyCellsPositions, dateRangesByMonth } = props;

  const resourceCount = resources.length || MAX_RESOURCE_COUNT;
  const skeletonRowCount = resourceCount < MAX_RESOURCE_COUNT ? resourceCount : MAX_RESOURCE_COUNT;

  const isDataLoading = isFetching || isEmpty(stickyCellsPositions);

  return (
    <TableBody sx={styles.body}>
      {isDataLoading
        ? range(skeletonRowCount).map(key => (
            <SkeletonRow
              key={key}
              isWideRange={isWideRange}
              dateRangesByMonth={dateRangesByMonth}
              stickyCellsPositions={stickyCellsPositions}
            />
          ))
        : resources.map(resource => (
            <Row
              key={resource.id}
              isWideRange={isWideRange}
              resource={resource}
              stickyCellsPositions={stickyCellsPositions}
              dateRangesByMonth={dateRangesByMonth}
            />
          ))}
    </TableBody>
  );
};

export default Body;
