/* eslint-disable @typescript-eslint/naming-convention */
declare module '@mui/material/styles' {
  interface TypographyVariants {
    heading3: React.CSSProperties;
    subtitle3: React.CSSProperties;
    tableHeading: React.CSSProperties;
    subtitle4: React.CSSProperties;
    mainTableHeading: React.CSSProperties;
    heading2: React.CSSProperties;
    body2: React.CSSProperties;
    heading4: React.CSSProperties;
    heading5: React.CSSProperties;
    subtitle5: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    heading3?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
    tableHeading: React.CSSProperties;
    subtitle4?: React.CSSProperties;
    mainTableHeading: React.CSSProperties;
    heading2: React.CSSProperties;
    body2: React.CSSProperties;
    heading4: React.CSSProperties;
    heading5: React.CSSProperties;
    subtitle5: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body2: true;
    heading3: true;
    subtitle3: true;
    tableHeading: true;
    subtitle4: true;
    mainTableHeading: true;
    heading2: true;
    heading4: true;
    heading5: true;
    subtitle5: true;
  }
}

export default {
  fontFamily: 'Inter',
  h1: {
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '32px',
  },
  h2: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
  },
  h3: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '32px',
  },
  h4: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.005em',
  },
  h5: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.005em',
  },
  h6: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.005em',
  },
  mainTableHeading: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.005em',
  },
  heading2: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.005em',
  },
  tableHeading: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '24px',
  },
  body1: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.005em',
  },
  body2: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.005em',
  },
  subtitle3: {
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '0.005em',
  },
  heading3: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.005em',
  },
  heading4: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.005em',
  },
  heading5: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.005em',
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.005em',
  },
  subtitle4: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.005em',
  },
  subtitle5: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.005em',
  },
};
