import { provideTags, TagTypes } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { IUserProfileProject } from './types';

interface IUserProfileProjectsResponse {
  projects: Array<IUserProfileProject>;
  meta: IMeta;
}

interface IGetUserProfileProjectsQueryParameters {
  nameCont?: string;
}

interface IGetUserProfileProjectsParameters {
  userId: number;
  page?: number;
  perPage?: number;
  q?: IGetUserProfileProjectsQueryParameters;
}

export const userProfileProjectApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getUserProfileProjects: builder.query<IUserProfileProjectsResponse, IGetUserProfileProjectsParameters>({
      query: ({ userId, ...parameters }) => ({
        url: apiRoutes.apiV1UserProfileProjectsPath(userId),
        method: 'GET',
        params: {
          ...parameters,
        },
      }),
      providesTags: response => provideTags(TagTypes.UserProfileProject, response?.projects),
    }),
  }),
});

export const { useGetUserProfileProjectsQuery } = userProfileProjectApi;
