export enum UserType {
  admin = 'admin',
  user = 'user',
  superAdmin = 'super_admin',
}

export enum UserState {
  active = 'active',
  archived = 'archived',
}
