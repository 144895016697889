import { FC } from 'react';

import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Loader from 'components/common/Loader';

import { useGetUserExpectedBillableTimePercentsQuery } from 'domain/user/expectedBillableTimePercent/apiSlice';
import { useGetUserHoursPerWeeksQuery } from 'domain/user/hoursPerWeek/apiSlice';

import { getSortParameter } from 'utils/runsack';

import UserExpectedBillableTimePercent from './components/UserExpectedBillableTimePercent';
import UserHoursPerWeek from './components/UserHoursPerWeek';
import { IScheduleProps } from './types';

const Schedule: FC<IScheduleProps> = props => {
  const { user } = props;

  const { t } = useTranslation(['common']);
  const {
    data: userHoursPerWeeksData,
    isLoading: isUserHoursPerWeeksDataLoading,
    isSuccess: isUserHoursPerWeeksDataLoadingSuccess,
    isError: isUserHoursPerWeeksDataLoadingError,
  } = useGetUserHoursPerWeeksQuery({
    userId: user.id,
    sort: getSortParameter('start_date', 'desc'),
  });
  const {
    data: userExpectedBillableTimePercentsData,
    isLoading: isUserExpectedBillableTimePercentsDataLoading,
    isSuccess: isUserExpectedBillableTimePercentsDataLoadingSuccess,
    isError: isUserExpectedBillableTimePercentsDataLoadingError,
  } = useGetUserExpectedBillableTimePercentsQuery({
    userId: user.id,
    sort: getSortParameter('start_date', 'desc'),
  });

  if (isUserHoursPerWeeksDataLoadingError || isUserExpectedBillableTimePercentsDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  if (isUserHoursPerWeeksDataLoading || isUserExpectedBillableTimePercentsDataLoading) {
    <Loader />;
  }
  return (
    isUserHoursPerWeeksDataLoadingSuccess &&
    isUserExpectedBillableTimePercentsDataLoadingSuccess && (
      <>
        <UserHoursPerWeek userHoursPerWeeks={userHoursPerWeeksData.userHoursPerWeeks} user={user} />
        <UserExpectedBillableTimePercent
          userExpectedBillableTimePercents={userExpectedBillableTimePercentsData.userExpectedBillableTimePercents}
          user={user}
        />
      </>
    )
  );
};

export default Schedule;
