import { SxStyles } from 'theme';

const styles: SxStyles = {
  legend: {
    display: 'flex',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 4,
  },
  colorItem: {
    width: '12px',
    height: '12px',
    marginRight: 1.5,
  },
  completedColor: {
    backgroundColor: 'background.lightDarkGreen',
  },
  approvedColor: {
    backgroundColor: 'background.mint',
  },
  requestedColor: {
    backgroundColor: 'background.orange',
  },
  currentColor: {
    backgroundColor: 'primary.blue',
  },
};

export default styles;
