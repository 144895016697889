import { TagTypes, provideTags } from 'shared/api/types';

import { ConditionState } from 'components/common/SmartFilter/enums';

import { SkillLevel } from 'enums/SkillLevel';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { ICreateResourceFormDataToSubmit } from './schemas/create';
import { IResource } from './types';

interface IGetResourcesQueryParameters {
  page?: number;
  perPage?: number;
  paginated?: boolean;
  stateEq?: ConditionState;
  skillLevelEq?: SkillLevel;
  userIdEq?: number;
  groupIdEq?: number;
  nameCont?: string;
  sort?: ISortParameter;
}

interface IResourcesResponse {
  resources: Array<IResource>;
  meta: IMeta;
}

interface IResourceResponse {
  resource: IResource;
}

export const resourceApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getResources: builder.query<IResourcesResponse, IGetResourcesQueryParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1ResourcesPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.Resource, response?.resources),
    }),
    getResource: builder.query<IResourceResponse, number>({
      query: id => ({
        url: apiRoutes.apiV1ResourcePath(id),
        method: 'GET',
      }),
      providesTags: ({ resource }) => [{ type: TagTypes.Resource, id: resource.id }],
    }),
    createResorce: builder.mutation<IResourceResponse, ICreateResourceFormDataToSubmit>({
      query: formData => ({
        url: apiRoutes.apiV1ResourcesPath(),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: [TagTypes.Resource],
    }),
    updateResorce: builder.mutation<IResourceResponse, unknown>({
      query: ({ id, formData }) => ({
        url: apiRoutes.apiV1ResourcePath(id),
        method: 'PUT',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: ({ resource }) => [{ type: TagTypes.Resource, id: resource.id }],
    }),
    deleteResource: builder.mutation<void, number>({
      query: id => ({
        url: apiRoutes.apiV1ResourcePath(id),
        method: 'DELETE',
      }),
      invalidatesTags: (_response, _error, id) => [{ type: TagTypes.Resource, id }],
    }),
    activateResource: builder.mutation<IResourceResponse, number>({
      query: id => ({
        url: apiRoutes.activateApiV1ResourcePath(id),
        method: 'PUT',
      }),
      invalidatesTags: ({ resource }) => [{ type: TagTypes.Resource, id: resource.id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetResourcesQuery,
  useActivateResourceMutation,
  useCreateResorceMutation,
  useDeleteResourceMutation,
  useGetResourceQuery,
  useUpdateResorceMutation,
} = resourceApi;
