import { FC } from 'react';

import { Avatar, Typography } from '@mui/material';

import { useScreen } from 'hooks';

import Div from 'components/common/Div';

import { getFullName } from 'utils/person';

import CurrentUserActionsMenu from './components/CurrentUserActionsMenu';
import ImpersonationBadge from './components/ImpersonationBadge';
import styles from './styles';
import { IUserBadgeProps } from './types';

const UserBadge: FC<IUserBadgeProps> = props => {
  const { user } = props;

  const { lessThanDesktop } = useScreen();

  const fullUserName = getFullName(user);

  const isImpersonated = user.impersonated;

  return (
    <Div sx={styles.root}>
      {isImpersonated && <ImpersonationBadge />}

      <Div sx={styles.userInfo}>
        {!lessThanDesktop && (
          <>
            <Avatar sx={styles.avatar} src={user.avatar} />
            <Typography sx={styles.userName} variant="h6">
              {fullUserName}
            </Typography>
          </>
        )}

        <CurrentUserActionsMenu isImpersonated={isImpersonated} userId={user.id} />
      </Div>
    </Div>
  );
};

export default UserBadge;
