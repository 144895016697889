import { FC, useMemo } from 'react';

import _ from 'lodash';

import Div from 'components/common/Div';

import { ITag } from 'domain/tag/types';

import styles from './styles';
import TagsAutocompleteInput from './TagsAutocomplete/components/TagsAutocompleteInput';
import TagsAutocompleteOptions from './TagsAutocomplete/components/TagsAutocompleteOptions';
import { ITagsAutocompleteProps } from './types';

const TagsAutocomplete: FC<ITagsAutocompleteProps> = props => {
  const { options, onCurrentValueChange, isError, setName, isFetching, currentValue, name, placeholder } = props;

  const groupedOptions = useMemo(
    () =>
      options.reduce(
        (accumulator, option) => {
          if (currentValue.some(tag => tag.id === option.id)) {
            accumulator.selected.push(option);
          } else {
            accumulator.notSelected.push(option);
          }
          return accumulator;
        },
        { selected: [], notSelected: [] },
      ),
    [options],
  );

  const handleTagsAutocompleteChange = (value: string) => {
    if (value !== name) {
      setName(value);
    }
  };

  const handleCurrentTagsChange = (value: Array<ITag>) => {
    if (_.isNil(value)) {
      onCurrentValueChange([]);
    }
    onCurrentValueChange(value);
  };

  return (
    <Div sx={styles.autoComplete}>
      <TagsAutocompleteInput
        isError={isError}
        onTagsAutocompleteChange={handleTagsAutocompleteChange}
        isFetching={isFetching}
        name={name}
        placeholder={placeholder}
      />
      <TagsAutocompleteOptions
        onCurrentTagsChange={handleCurrentTagsChange}
        currentValue={currentValue}
        selectedTags={groupedOptions.selected}
        notSelectedTags={groupedOptions.notSelected}
      />
    </Div>
  );
};
export default TagsAutocomplete;
