import { ITechnology } from './types';

export const getTechnologyNames = (technologies: Array<ITechnology>, separator: string): string => {
  const technologyNames = technologies.map(technology => technology.name);
  return technologyNames.join(separator);
};

export const getTechnologyNamesWithAncestors = (technologies: Array<ITechnology>, separator: string): string => {
  const technologyNames = technologies.map(technology => technology.ancestorsPath);
  return technologyNames.join(separator);
};
