import { provideTags, TagTypes } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { IUserProfileAccessLevel } from './types';

interface IUserProfileAccessLevelsResponse {
  accessLevels: Array<IUserProfileAccessLevel>;
  meta: IMeta;
}

interface IGetUserProfileAccessLevelsQueryParameters {
  nameCont?: string;
}

interface IGetUserProfileAccessLevelsPlainParameters extends IGetUserProfileAccessLevelsQueryParameters {
  page?: number;
  perPage?: number;
  userId: number;
}

interface IGetUserProfileAccessLevelsParameters {
  userId: number;
  page?: number;
  perPage?: number;
  q?: IGetUserProfileAccessLevelsQueryParameters;
}

export const loadingGetUserProfileAccessLevelsParameters = (
  parameters: IGetUserProfileAccessLevelsPlainParameters,
): IGetUserProfileAccessLevelsParameters => {
  const { userId, perPage, page, ...queryParameters } = parameters;
  return {
    userId,
    perPage,
    page,
    q: { ...queryParameters },
  };
};

export const userProfileAccessLevelApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getUserProfileAccessLevels: builder.query<IUserProfileAccessLevelsResponse, IGetUserProfileAccessLevelsParameters>({
      query: ({ userId, ...parameters }) => ({
        url: apiRoutes.apiV1UserProfileAccessLevelsPath(userId),
        method: 'GET',
        params: {
          ...parameters,
        },
      }),
      providesTags: response => provideTags(TagTypes.UserProfileAccessLevel, response?.accessLevels),
    }),
  }),
});

export const { useGetUserProfileAccessLevelsQuery } = userProfileAccessLevelApi;
