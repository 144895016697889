import { SxStyles } from 'theme';

const styles: SxStyles = {
  selectionBlock: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 2.5,
  },
  buttonGroup: {
    marginLeft: '48px',
  },
  icon: {
    width: '20px',
    heiht: '20px',
    color: 'primary.gray',
    marginLeft: 1,
    marginRight: 1,
  },
  iconActive: {
    color: 'primary.blue',
  },
};

export default styles;
