import { SxStyles } from 'theme';

const styles: SxStyles = {
  title: {
    marginBottom: 1,
  },
  subtitle: {
    marginBottom: 5,
  },
};

export default styles;
