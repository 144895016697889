import { TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { VacancyState } from './enums';
import { ICreateVacancyFormDataToSubmit } from './schemas/create';
import { IUpdateVacancyFormDataToSubmit } from './schemas/update';
import { IVacancy } from './types';

interface IGetVacanciesQueryParameters {
  stateEq?: VacancyState;
  nameCont?: string;
}

interface IGetVacanciesPlainParameters extends IGetVacanciesQueryParameters {
  page?: number;
  perPage?: number;
}

interface IGetVacanciesParameters {
  q?: IGetVacanciesQueryParameters;
  perPage?: number;
  page?: number;
}

interface IVacanciesResponse {
  vacancies: Array<IVacancy>;
  meta: IMeta;
}

interface IVacancyResponse {
  vacancy: IVacancy;
}

export const loadingGetVacanciesParameters = (parameters: IGetVacanciesPlainParameters): IGetVacanciesParameters => {
  const { perPage, page, ...queryParameters } = parameters;
  return {
    perPage,
    page,
    q: { ...queryParameters },
  };
};

export const vacancyApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getVacancies: builder.query<IVacanciesResponse, IGetVacanciesParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1VacanciesPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.Vacancy, response?.vacancies),
    }),
    createVacancy: builder.mutation<IVacancyResponse, ICreateVacancyFormDataToSubmit>({
      query: ({ formData }) => ({
        url: apiRoutes.apiV1VacanciesPath(),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: ({ vacancy }) => {
        return [TagTypes.Vacancy, { type: TagTypes.ReportHoursByProject, id: vacancy.project.id }];
      },
    }),
    updateVacancy: builder.mutation<IVacancyResponse, IUpdateVacancyFormDataToSubmit>({
      query: ({ id, formData }) => ({
        url: apiRoutes.apiV1VacancyPath(id),
        method: 'PUT',
        data: formData,
      }),
      invalidatesTags: ({ vacancy }) => [
        { type: TagTypes.Vacancy, id: vacancy.id },
        { type: TagTypes.ReportHoursByProject, id: vacancy.project.id },
      ],
    }),
    deleteVacancy: builder.mutation<void, number>({
      query: id => ({
        url: apiRoutes.apiV1VacancyPath(id),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.Vacancy],
    }),
    activateVacancy: builder.mutation<IVacancyResponse, number>({
      query: id => ({
        url: apiRoutes.activateApiV1VacancyPath(id),
        method: 'PUT',
      }),
      invalidatesTags: ({ vacancy }) => [{ type: TagTypes.Vacancy, id: vacancy.id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetVacanciesQuery,
  useCreateVacancyMutation,
  useUpdateVacancyMutation,
  useDeleteVacancyMutation,
  useActivateVacancyMutation,
} = vacancyApi;
