import * as yup from 'yup';

import i18n from 'locales/i18n';

export interface ISignInFormData {
  email: string;
  password: string;
}

export const buildValidationFields = () => ({
  email: yup.string().email(i18n.t('forms:validation.email')).required(i18n.t('forms:validation.required')).default(''),
  password: yup.string().required(i18n.t('forms:validation:required')).default(''),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());
