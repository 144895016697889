import { FC } from 'react';

import { Button, Divider, List, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { usePermissions } from 'hooks/usePermissions';

import UserProfileAccessLevel from './components/UserProfileAccessLevel';
import styles from './styles';
import { IUserProfileGroupAccessLevelsProps } from './types';

const UserProfileGroupAccessLevels: FC<IUserProfileGroupAccessLevelsProps> = props => {
  const {
    userProfileAccessLevels,
    groupTitle,
    isUserProfileProjectAccessLevel = false,
    user,
    permission,
    onAddAccess,
  } = props;

  const { t } = useTranslation('userProfile');

  const { isRoleFeatureAllowed } = usePermissions();

  const translateAccessButton = isUserProfileProjectAccessLevel
    ? t('userProfile:accessLevel.buttons.addProjectAccess')
    : t('userProfile:accessLevel.buttons.addGroupAccess');

  const hasUserProfileAccessLevels = !_.isNil(userProfileAccessLevels);

  const canAddAccessLevel = user.permissions.canAddAccessLevel;

  return (
    <Div sx={styles.root}>
      <Typography sx={styles.listTitle} component="h3" variant="heading2">
        {groupTitle}
      </Typography>
      {hasUserProfileAccessLevels && (
        <List sx={styles.accessLevelsList}>
          {userProfileAccessLevels.map(userProfileAccessLevel => {
            return (
              <UserProfileAccessLevel
                key={userProfileAccessLevel.id}
                userProfileAccessLevel={userProfileAccessLevel}
                user={user}
                isUserProfileProjectAccessLevel={isUserProfileProjectAccessLevel}
              />
            );
          })}
        </List>
      )}
      {isRoleFeatureAllowed(permission) && canAddAccessLevel && (
        <Button onClick={onAddAccess} sx={styles.addAccessButton} variant="text">
          {translateAccessButton}
        </Button>
      )}
      {!isUserProfileProjectAccessLevel && <Divider />}
    </Div>
  );
};

export default UserProfileGroupAccessLevels;
