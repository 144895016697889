import { nanoid } from 'nanoid';
import { Navigate } from 'react-router-dom';

import AdminPanel from 'components/pages/AdminPanel';
import NewPassword from 'components/pages/NewPassword';
import Allocation from 'components/pages/Report/Allocation';
import HoursByProject from 'components/pages/Report/HoursByProject';
import HoursByProjects from 'components/pages/Report/HoursByProjects';
import HoursByResources from 'components/pages/Report/HoursByResources';
import TrackedTimeEntries from 'components/pages/Report/TrackedTimeEntries';
import Vacations from 'components/pages/Report/Vacations';
import ResetPassword from 'components/pages/ResetPassword';
import SignIn from 'components/pages/SignIn';
import TimeTracker from 'components/pages/TimeTracker';
import UserProfile from 'components/pages/UserProfile';
import Users from 'components/pages/Users';

import { UserType } from 'domain/user/enums';

import AuthLayout from 'layouts/public/AuthLayout';
import BaseLayout from 'layouts/public/BaseLayout';

import { IAppRoutes } from 'types/routes';

const appRoutes: IAppRoutes = {
  signIn: {
    id: nanoid(),
    path: () => '/sign-in',
    onlyUnauthorized: true,
    element: (
      <AuthLayout>
        <SignIn />
      </AuthLayout>
    ),
  },
  googleOauthLogin: {
    id: nanoid(),
    path: () => '/auth/google_oauth2',
    onlyPath: true,
  },

  googleOauthLogout: {
    id: nanoid(),
    path: () => '/auth/google_oauth2/logout',
    onlyPath: true,
  },
  newPassword: {
    id: nanoid(),
    path: () => '/new-password',
    protected: [UserType.admin, UserType.user],
    element: (
      <AuthLayout>
        <NewPassword />
      </AuthLayout>
    ),
  },
  resetPassword: {
    id: nanoid(),
    path: () => '/confirm-email',
    protected: [UserType.admin, UserType.user],
    element: (
      <AuthLayout>
        <ResetPassword />
      </AuthLayout>
    ),
  },
  users: {
    id: nanoid(),
    path: () => '/users',
    protected: [UserType.admin, UserType.user],
    element: (
      <BaseLayout>
        <Users />
      </BaseLayout>
    ),
  },
  adminPanel: {
    id: nanoid(),
    path: () => '/admin-panel',
    protected: [UserType.admin],
    element: (
      <BaseLayout>
        <AdminPanel />
      </BaseLayout>
    ),
  },
  allocation: {
    id: nanoid(),
    path: () => '/report/allocation',
    protected: [UserType.admin, UserType.user],
    element: (
      <BaseLayout>
        <Allocation />
      </BaseLayout>
    ),
  },
  reportTrackedTimeEntries: {
    id: nanoid(),
    path: () => '/report/tracked-time-entries',
    protected: [UserType.admin, UserType.user],
    element: (
      <BaseLayout>
        <TrackedTimeEntries />
      </BaseLayout>
    ),
  },
  reportHoursByResources: {
    id: nanoid(),
    path: () => '/report/hours-by-resources',
    protected: [UserType.admin, UserType.user],
    element: (
      <BaseLayout>
        <HoursByResources />
      </BaseLayout>
    ),
  },
  reportHoursByProjects: {
    id: nanoid(),
    path: () => '/report/hours-by-projects',
    protected: [UserType.admin, UserType.user],
    element: (
      <BaseLayout>
        <HoursByProjects />
      </BaseLayout>
    ),
  },
  reportHoursByProject: {
    id: nanoid(),
    path: projectId => `/report/hours-by-projects/${projectId}`,
    protected: [UserType.admin, UserType.user],
    element: (
      <BaseLayout>
        <HoursByProject />
      </BaseLayout>
    ),
    pathStringIds: [':projectId'],
  },
  timeTracker: {
    id: nanoid(),
    path: () => '/time-tracker',
    protected: [UserType.admin, UserType.user],
    element: (
      <BaseLayout>
        <TimeTracker />
      </BaseLayout>
    ),
  },
  userProfile: {
    id: nanoid(),
    path: (userId, hash) => (hash ? `/users/${userId}/profile#${hash}` : `/users/${userId}/profile`),
    protected: [UserType.admin, UserType.user],
    element: (
      <BaseLayout>
        <UserProfile />
      </BaseLayout>
    ),
    pathStringIds: [':userId'],
  },
  vacations: {
    id: nanoid(),
    path: () => '/report/vacations',
    protected: [UserType.admin, UserType.user],
    element: (
      <BaseLayout>
        <Vacations />
      </BaseLayout>
    ),
  },
  redirectToTimeTracker: {
    id: nanoid(),
    path: () => '*',
    protected: [UserType.admin, UserType.user],
    element: <Navigate to="/time-tracker" replace />,
  },
  redirectUnauthorized: {
    id: nanoid(),
    path: () => '*',
    onlyUnauthorized: true,
    element: <Navigate to="/sign-in" replace />,
  },
};

export default appRoutes;
