import { FC, useEffect, useState } from 'react';

import { Typography, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks';

import Div from 'components/common/Div';
import LinkTab from 'components/common/LinkTab';
import TabPanel from 'components/common/TabPanel';
import CompanyInfo from 'components/pages/AdminPanel/components/CompanyInfo';

import ForbiddenWords from './components/ForbiddenWords';
import Hierarchy from './components/Hierarchy';
import Specializations from './components/Specializations';
import Tags from './components/Tags';
import TrackingTypes from './components/TrackingTypes';
import WorkingCalendars from './components/WorkingCalendars';
import { TabName } from './enums';
import styles from './styles';

const AdminPanel: FC = () => {
  const [currentTabIndex, setCurrentTabName] = useState<string>(TabName.hierarchy);
  const { t } = useTranslation('adminPanel');
  const { pathname, location } = useRouter();

  useEffect(() => {
    if (location.hash) {
      setCurrentTabName(TabName[location.hash.slice(1)]);
    }
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabName(newValue);
  };

  const temporaryName = 'cruds';

  return (
    <>
      <Typography variant="h1" sx={styles.header}>
        {t('title')}
      </Typography>
      <Div sx={styles.container}>
        <Div sx={styles.tabHeadersContainer}>
          <Tabs value={currentTabIndex} onChange={handleTabChange} aria-label="tabs" textColor="inherit">
            <LinkTab
              label={t('tabs.hierarchy')}
              value={TabName.hierarchy}
              blockLabel={temporaryName}
              to={{ path: pathname, hash: TabName.hierarchy }}
            />
            <LinkTab
              label={t('tabs.specializations')}
              value={TabName.specializations}
              blockLabel={temporaryName}
              to={{ path: pathname, hash: TabName.specializations }}
            />
            <LinkTab
              label={t('tabs.workingCalendars')}
              value={TabName.workingCalendars}
              blockLabel={temporaryName}
              to={{ path: pathname, hash: TabName.workingCalendars }}
            />
            <LinkTab
              label={t('tabs.trackingTypes')}
              value={TabName.trackingTypes}
              blockLabel={temporaryName}
              to={{ path: pathname, hash: TabName.trackingTypes }}
            />
            <LinkTab
              label={t('tabs.tags')}
              value={TabName.tags}
              blockLabel={temporaryName}
              to={{ path: pathname, hash: TabName.tags }}
            />
            <LinkTab
              label={t('tabs.projectGroups')}
              value={TabName.projectGroups}
              blockLabel={temporaryName}
              to={{ path: pathname, hash: TabName.projectGroups }}
            />
            <LinkTab
              label={t('tabs.companyInfo')}
              value={TabName.companyInfo}
              blockLabel={temporaryName}
              to={{ path: pathname, hash: TabName.companyInfo }}
            />
            <LinkTab
              label={t('tabs.forbiddenWords')}
              value={TabName.forbiddenWords}
              blockLabel={temporaryName}
              to={{ path: pathname, hash: TabName.forbiddenWords }}
            />
          </Tabs>
        </Div>
        <TabPanel
          value={currentTabIndex}
          name={TabName.hierarchy}
          tabGroupName={temporaryName}
          sx={styles.tabContentContainer}
        >
          <Hierarchy />
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          name={TabName.specializations}
          tabGroupName={temporaryName}
          sx={styles.tabContentContainer}
        >
          <Specializations />
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          name={TabName.workingCalendars}
          tabGroupName={temporaryName}
          sx={styles.tabContentContainer}
        >
          <WorkingCalendars />
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          name={TabName.trackingTypes}
          tabGroupName={temporaryName}
          sx={styles.tabContentContainer}
        >
          <TrackingTypes />
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          name={TabName.tags}
          tabGroupName={temporaryName}
          sx={styles.tabContentContainer}
        >
          <Tags />
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          name={TabName.projectGroups}
          tabGroupName={temporaryName}
          sx={styles.tabContentContainer}
        >
          {t('tabs.projectGroups')}
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          name={TabName.companyInfo}
          tabGroupName={temporaryName}
          sx={styles.tabContentContainer}
        >
          {t('tabs.companyInfo')}
          <CompanyInfo />
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          name={TabName.forbiddenWords}
          tabGroupName={temporaryName}
          sx={styles.tabContentContainer}
        >
          <ForbiddenWords />
        </TabPanel>
      </Div>
    </>
  );
};

export default AdminPanel;
