import { TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { ICreateResourceTrackedTimeEntryFormDataToSubmit } from './schemas/create';
import { IUpdateResourceTrackedTimeEntryFormDataToSubmit } from './schemas/update';
import { mapTrackedTimeEntriesByWeekAndDay } from './service';
import {
  IDeleteResourceTrackedTimeEntryQueryParameters,
  IGetResourceTrackedTimeEntriesParameters,
  IResourceTrackedTimeEntry,
  IResourceTrackedTimeEntryDTO,
  ResourceTrackedTimeEntriesResponse,
} from './types';

export const resourceTrackedTimeEntryApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    resourceTrackedTimeEntries: builder.query<
      ResourceTrackedTimeEntriesResponse,
      IGetResourceTrackedTimeEntriesParameters
    >({
      query: ({ resourceId, ...parameters }) => {
        return {
          url: apiRoutes.apiV1ResourceTrackedTimeEntriesPath(resourceId),
          method: 'GET',
          params: parameters,
        };
      },
      transformResponse: mapTrackedTimeEntriesByWeekAndDay,
      providesTags: response => provideTags(TagTypes.ResourceTrackedTimeEntry, response?.trackedTimeEntries),
    }),
    createResourceTrackedTimeEntry: builder.mutation<
      IResourceTrackedTimeEntry,
      ICreateResourceTrackedTimeEntryFormDataToSubmit
    >({
      query: ({ resourceId, formData }) => ({
        url: apiRoutes.apiV1ResourceTrackedTimeEntriesPath(resourceId),
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: [TagTypes.ResourceTrackedTimeEntry],
    }),
    updateResourceTrackedTimeEntry: builder.mutation<
      IResourceTrackedTimeEntry,
      IUpdateResourceTrackedTimeEntryFormDataToSubmit
    >({
      query: ({ id, resourceId, formData }) => ({
        url: apiRoutes.apiV1ResourceTrackedTimeEntryPath(resourceId, id),
        method: 'PUT',
        data: formData,
      }),
      transformResponse: ({ trackedTimeEntry }: IResourceTrackedTimeEntryDTO) => trackedTimeEntry,
      invalidatesTags: (result, error) => {
        if (error || !result) {
          return [TagTypes.ResourceTrackedTimeEntry];
        }

        return [{ type: TagTypes.ResourceTrackedTimeEntry, id: result.id }];
      },
    }),
    deleteResourceTrackedTimeEntry: builder.mutation<void, IDeleteResourceTrackedTimeEntryQueryParameters>({
      query: ({ id, resourceId }) => ({
        url: apiRoutes.apiV1ResourceTrackedTimeEntryPath(resourceId, id),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.ResourceTrackedTimeEntry],
    }),
  }),
  overrideExisting: false,
});

export const {
  useResourceTrackedTimeEntriesQuery,
  useCreateResourceTrackedTimeEntryMutation,
  useUpdateResourceTrackedTimeEntryMutation,
  useDeleteResourceTrackedTimeEntryMutation,
} = resourceTrackedTimeEntryApi;
