import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ResourceTrackedTimeWeekEntries } from 'domain/resource/trackedTimeEntry/types';

import { formatMinutesToString, formatToDateWithFixedTimeZone } from 'utils/dateTime';

import DayList from './components/DayList';
import styles from './styles';

interface IWeekListProps {
  resourceId: number;
  editLockDate: string;
  currentWeekNumber: number;
  weekEntries: ResourceTrackedTimeWeekEntries;
  onTimeTrackerSettingsCopy: (formData) => void;
}

const WeekList = (props: IWeekListProps) => {
  const {
    resourceId,
    editLockDate,
    currentWeekNumber,
    weekEntries: { weekNumber, weekRange, totalWeekMinutes, entriesByDate },
    onTimeTrackerSettingsCopy,
  } = props;

  const { t } = useTranslation('timeTracker');

  const translate = (key: string, options: Record<string, number>) => t(`trackedTimeEntriesGroupList.${key}`, options);

  const weekLabelKey = weekNumber === currentWeekNumber ? 'currentWeek' : 'week';

  const sortedTrackedTimeEntriesByDateKeys = _.orderBy(Object.keys(entriesByDate), formatToDateWithFixedTimeZone, [
    'desc',
  ]);

  return (
    <Div sx={styles.timeTrackerWeekAndDateContainer}>
      <Div sx={styles.weekTimeTrackerCount}>
        <Div sx={styles.weekContainer}>
          <Div sx={styles.weekLabel}>{translate(weekLabelKey, { weekNumber })}:&nbsp;</Div>
          <Div sx={styles.weekDateRange}>{weekRange}</Div>
        </Div>
        <Div sx={styles.weekContainer}>
          <Div sx={styles.weekLabel}>{translate('totalWeek', { weekNumber })}:&nbsp;</Div>
          <Div sx={styles.weekTotal}>{formatMinutesToString(totalWeekMinutes)}</Div>
        </Div>
      </Div>
      {sortedTrackedTimeEntriesByDateKeys.map(dateKey => {
        const dayEntries = entriesByDate[dateKey];
        const dayListKey = `${dateKey}:${dayEntries.totalDayMinutes}`;

        return (
          <DayList
            key={dayListKey}
            resourceId={resourceId}
            date={dateKey}
            editLockDate={editLockDate}
            dayEntries={dayEntries}
            onTimeTrackerSettingsCopy={onTimeTrackerSettingsCopy}
          />
        );
      })}
    </Div>
  );
};

export default WeekList;
