import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { ITask } from '../types';

export interface IUpdateProjectTaskFormData {
  name: string;
}

export interface IUpdateProjectTaskFormDataToSubmit {
  id: number;
  projectId: number;
  formData: IUpdateProjectTaskFormData;
}

export const buildValidationFields = () => ({
  name: yup
    .string()
    .max(100, i18n.t('forms:validation:maxNumber', { count: 100 }))
    .required()
    .default(''),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (currentTask: ITask) => {
  if (_.isNil(currentTask)) {
    return buildValidationSchema().getDefault();
  }

  return {
    name: currentTask.name,
  };
};

export const formatAttributesToSubmit = (
  id: number,
  projectId: number,
  formData: IUpdateProjectTaskFormData,
): IUpdateProjectTaskFormDataToSubmit => {
  return {
    id,
    projectId,
    formData: {
      ...formData,
    },
  };
};
