import { FC } from 'react';

import { Avatar, Typography } from '@mui/material';

import Div from 'components/common/Div';

import styles from './styles';
import { ITrackedTimeEntryItemUserChipProps } from './types';

const TrackedTimeEntryUserChip: FC<ITrackedTimeEntryItemUserChipProps> = props => {
  const { fullName, avatarSrc } = props;

  return (
    <Div sx={styles.root}>
      <Avatar sx={styles.avatar} src={avatarSrc} />
      <Typography variant="heading3">{fullName}</Typography>
    </Div>
  );
};

export default TrackedTimeEntryUserChip;
