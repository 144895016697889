import { FC, PropsWithChildren } from 'react';

import { Button, ButtonProps } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

type LinkButtonProps = ButtonProps & LinkProps;

const LinkButton: FC<PropsWithChildren<LinkButtonProps>> = props => {
  const { children, ...other } = props;

  return (
    <Button component={Link} {...other}>
      {children}
    </Button>
  );
};

export default LinkButton;
