import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    backgroundColor: 'background.white',
  },
  table: {
    borderCollapse: 'separate',
  },
  tableLoading: {
    display: 'none',
  },
  column: {
    padding: 1,
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: 'border.main',
  },
  lastColumn: {
    borderRight: 'none',
  },
};

export default styles;
