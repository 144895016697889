import { FC } from 'react';

import { ExpandMoreOutlined, ExpandLessOutlined } from '@mui/icons-material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { IconButton, Typography, Avatar, Button, Tooltip } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import appRoutes from 'routes/appRoutes';

import Div from 'components/common/Div';

import { VacationApprovementApprovingLevelType } from 'domain/report/vacationRequest/enums';
import { formatVacationRequestRangeDate } from 'domain/report/vacationRequest/service';
import { isVacationRequestConfirmed, isVacationRequestDeclined } from 'domain/resource/vacation/service';

import { formatToShortString } from 'utils/dateTime';

import styles from './styles';
import { IVacationRequestVisibleContent } from './types';

const VacationRequestVisibleContent: FC<IVacationRequestVisibleContent> = props => {
  const {
    isExpanded,
    isLoading,
    withBackground,
    vacationRequest,
    approvementState,
    approvingLevelName,
    approvingLevelType,
    onExpand,
    onApprove,
    onDecline,
  } = props;

  const { t } = useTranslation('reportVacations');

  const requestDate = formatToShortString(new Date(vacationRequest.requestedDate));

  const isProject = approvingLevelType === VacationApprovementApprovingLevelType.project;

  const approvingLevelTypeKey = `vacationRequests.${isProject ? 'project' : 'techCenter'}`;
  const approvingLevelInfo = `${t(approvingLevelTypeKey)}: ${approvingLevelName}`;

  const tooltipKey = `vacationRequests.tooltips.${isProject ? 'project' : 'techCenter'}`;
  const tooltipText = `${t(tooltipKey)}: ${approvingLevelName}`;

  return (
    <Div sx={MUISx(styles.visibleContent, { condition: withBackground, sx: styles.visibleContentWithBackground })}>
      <Div sx={styles.expand}>
        <IconButton sx={styles.expandButton} onClick={onExpand}>
          {isExpanded ? <ExpandLessOutlined sx={styles.expandIcon} /> : <ExpandMoreOutlined sx={styles.expandIcon} />}
        </IconButton>
      </Div>
      <Div sx={styles.date}>
        <Typography variant="heading3">{requestDate}</Typography>
      </Div>
      <Div sx={styles.resource}>
        <Avatar sx={styles.avatar} src={vacationRequest.avatarUrl} />
        <Typography
          to={appRoutes.userProfile.path(vacationRequest.userId)}
          component={Link}
          sx={styles.userName}
          variant="body1"
        >
          {vacationRequest.requestedName}
        </Typography>
      </Div>
      <Div sx={styles.approvingLevel}>
        <Tooltip arrow placement="top-end" title={tooltipText}>
          <Typography noWrap variant="heading3" component={Div}>
            {approvingLevelInfo}
          </Typography>
        </Tooltip>
      </Div>
      <Div sx={styles.specialization}>
        <Typography variant="heading3">{vacationRequest.specializations.join(', ')}</Typography>
      </Div>
      <Div sx={styles.vacationRange}>
        <Typography variant="heading3" sx={styles.range}>
          {t('vacationRequests.range', {
            start: formatVacationRequestRangeDate(vacationRequest.startDate),
            end: formatVacationRequestRangeDate(vacationRequest.endDate),
          })}
        </Typography>
        <Typography variant="heading3" sx={styles.days}>
          {t('vacationRequests.days', { days: vacationRequest.daysNumber })}
        </Typography>
      </Div>
      <Div sx={styles.buttons}>
        {!isVacationRequestConfirmed(approvementState) && (
          <Button onClick={onApprove} disabled={isLoading} variant="text" sx={styles.approve}>
            <CheckCircleOutlineOutlinedIcon sx={styles.icon} />
            {t('vacationRequests.approve')}
          </Button>
        )}
        {!isVacationRequestDeclined(approvementState) && (
          <Button onClick={onDecline} disabled={isLoading} variant="text" sx={styles.cancel}>
            <CancelOutlinedIcon sx={styles.icon} />
            {t('vacationRequests.decline')}
          </Button>
        )}
      </Div>
    </Div>
  );
};

export default VacationRequestVisibleContent;
