import { createContext, FC, useState } from 'react';

import ConfirmDialog from 'components/common/ConfirmDialog';

import { IConfirmDialogConfig, IConfirmDialogProviderProps } from 'types/confirmDialog';

const ConfirmDialogContext = createContext(null);
ConfirmDialogContext.displayName = 'ConfirmDialogContext';

const ConfirmDialogProvider: FC<IConfirmDialogProviderProps> = props => {
  const { children } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<IConfirmDialogConfig>({} as IConfirmDialogConfig);

  const openDialog = ({ title, message, actionCallback }: IConfirmDialogConfig) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, actionCallback });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({} as IConfirmDialogConfig);
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback(false);
  };

  return (
    <ConfirmDialogContext.Provider value={{ openDialog }}>
      {children}
      <ConfirmDialog
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        open={dialogOpen}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
    </ConfirmDialogContext.Provider>
  );
};

export default ConfirmDialogProvider;
export { ConfirmDialogContext };
