import appRoutes from 'routes/appRoutes';

import { SlotField } from 'components/common/SmartFilter/enums';

import { IReportResourceUsage, IReportHoursByProjectsProject } from 'domain/report/hoursByProject/types';

import { dateToString } from 'utils/calendar';
import { convertDateStringFormat, parseShortDate } from 'utils/dateTime';

export const buildPathForResourceNameClick = (resourceUsage: IReportResourceUsage) => {
  return appRoutes.userProfile.path(resourceUsage.userId);
};

export const buildPathForProjectPlannedHoursClick = (
  project: IReportHoursByProjectsProject,
  options: { startDate: string; endDate: string },
  buildPath,
) => {
  const parameters = {
    [SlotField.project]: [project.id],
    startDate: dateToString(parseShortDate(options.startDate)),
    endDate: dateToString(parseShortDate(options.endDate)),
  };

  return buildPath(appRoutes.allocation.path(), parameters);
};

export const buildPathForResourcePlannedHoursClick = (
  project: IReportHoursByProjectsProject,
  resourceUsage: IReportResourceUsage,
  options: { startDate: string; endDate: string },
  buildPath,
) => {
  const parameters = {
    [SlotField.project]: [project.id],
    [SlotField.resource]: [resourceUsage.resourceId],
    startDate: dateToString(parseShortDate(options.startDate)),
    endDate: dateToString(parseShortDate(options.endDate)),
  };

  return buildPath(appRoutes.allocation.path(), parameters);
};

export const buildPathForProjectActualHoursClick = (
  project: IReportHoursByProjectsProject,
  options: { startDate: string; endDate: string },
  buildPath,
) => {
  const parameters: Record<string, unknown> = {
    afterDate: dateToString(parseShortDate(options.startDate)),
    beforeDate: dateToString(parseShortDate(options.endDate)),
    [SlotField.trackable]: [
      {
        id: project.id,
        name: project.name,
        billable: project.billable,
        type: project.type,
        category: 'project',
      },
    ],
  };

  return buildPath(appRoutes.reportTrackedTimeEntries.path(), parameters);
};

export const buildPathForResourceActualHoursClick = (
  project: IReportHoursByProjectsProject,
  resourceUsage: IReportResourceUsage,
  options: { startDate: string; endDate: string },
  buildPath,
) => {
  const parameters: Record<string, unknown> = {
    [SlotField.resource]: [resourceUsage.resourceId],
    afterDate: dateToString(parseShortDate(options.startDate)),
    beforeDate: dateToString(parseShortDate(options.endDate)),
    [SlotField.trackable]: [
      {
        id: project.id,
        name: project.name,
        billable: project.billable,
        type: project.type,
        category: 'project',
      },
    ],
  };

  return buildPath(appRoutes.reportTrackedTimeEntries.path(), parameters);
};

export const buildPathForProjectBudgetsClick = (
  projectId: string,
  hash: string,
  dateRange: { startDate: string; endDate: string },
) => {
  const startDate = convertDateStringFormat(dateRange.startDate);
  const endDate = convertDateStringFormat(dateRange.endDate);
  const queryParameters = new URLSearchParams({ startDate, endDate }).toString();
  const basePath = appRoutes.reportHoursByProject.path(projectId, '');

  return `${basePath}?${queryParameters}#${hash}`;
};
