import { FC } from 'react';

import { ExpandMoreOutlined } from '@mui/icons-material';
import { InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { GroupByPeriodFilter } from 'enums/GroupByPeriodFilter';

import styles from './styles';
import { IGroupedByPeriodFilterProps } from './types';

const GroupedByPeriodFilter: FC<IGroupedByPeriodFilterProps> = props => {
  const { onChange, value } = props;

  const { t } = useTranslation('groupedByPeriodFilter');

  return (
    <Div sx={styles.root}>
      <InputLabel sx={styles.groupedByLabel} id="groupedBy-select-label">
        <Typography sx={styles.groupedByLabelText} variant="heading3">
          {t('label')}
        </Typography>
      </InputLabel>
      <Select
        labelId="groupedBy-select-label"
        sx={styles.select}
        value={value}
        onChange={onChange}
        displayEmpty
        IconComponent={ExpandMoreOutlined}
      >
        {Object.values(GroupByPeriodFilter).map(groupBy => {
          return (
            <MenuItem key={groupBy} value={groupBy}>
              <Typography sx={styles.groupByOptionText} variant="subtitle1">
                {groupBy}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </Div>
  );
};

export default GroupedByPeriodFilter;
