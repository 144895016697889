import { FC } from 'react';

import { MenuItem, Menu } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ConditionState } from 'components/common/SmartFilter/enums';

import styles from './styles';
import { IStatusMenuProps } from './types';

const StatusMenu: FC<IStatusMenuProps> = ({
  anchorEl,
  isMenuOpen,
  setIsMenuOpen,
  handleMenuItemClick,
  currentStatus,
}) => {
  const { t } = useTranslation('smartFilter');

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <Menu
      open={isMenuOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      onClick={handleClose}
      sx={styles.menu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {Object.values(ConditionState).map(state => (
        <MenuItem key={state} onClick={() => handleMenuItemClick(state)} selected={state === currentStatus}>
          {t(`multipleAutocomplete.state.${state}`)}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default StatusMenu;
