import { FC, useContext } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { useDeleteResourceTrackedTimeEntryMutation } from 'domain/resource/trackedTimeEntry/apiSlice';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';

import styles from './styles';
import { IDeleteDialogProps } from './types';

const DeleteTimeEntryDialog: FC<IDeleteDialogProps> = props => {
  const { t } = useTranslation(['common', 'timeTrackerc']);

  const { closeModal } = useContext(ModalContext);
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [deleteResourceTrackedEntity, { isLoading: isDeleteResourceTrackedEntityLoading }] =
    useDeleteResourceTrackedTimeEntryMutation();

  const { id, resourceId } = props;

  const handleDeleteResourceTrackedEntity = async () => {
    try {
      await deleteResourceTrackedEntity({ id, resourceId }).unwrap();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
      showSuccessNotification();
    }
  };

  return (
    <Div>
      <Button
        disabled={isDeleteResourceTrackedEntityLoading}
        onClick={handleDeleteResourceTrackedEntity}
        sx={styles.buttonRemove}
      >
        {t('timeTracker:deleteTimeEntryDialog.remove')}
      </Button>
      <Button type="reset" variant="outlined" disabled={isDeleteResourceTrackedEntityLoading} onClick={closeModal}>
        {t('timeTracker:deleteTimeEntryDialog.cancel')}
      </Button>
    </Div>
  );
};

export default DeleteTimeEntryDialog;
