import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  isBefore,
  isSameMonth,
  lastDayOfMonth,
  startOfDay,
  startOfMonth,
} from 'date-fns';

import { SmartFilterDescriptionState } from 'components/common/SmartFilter/enums';

import { IReportVacationsSmartFilters, IGetReportVacationsParameters } from 'domain/report/vacation/apiSlice';

import { FIRST_ITEM_INDEX, getLastIndex } from 'utils/array';
import { formatToDateWithFixedTimeZone, ONE_MONTH } from 'utils/dateTime';

export const getFirstDate = (dates: Date[][]): Date => dates[FIRST_ITEM_INDEX][FIRST_ITEM_INDEX];

export const getLastDate = (dates: Date[][]): Date => {
  const lastMonth = dates[getLastIndex(dates)];

  return lastMonth[getLastIndex(lastMonth)];
};

const isBeforeEndDate = (dates: Date[][], end: Date): boolean => isBefore(getLastDate(dates), end);

export const groupDateRangeByMonth = (start: string, end: string): Date[][] => {
  const startRangeDate = formatToDateWithFixedTimeZone(start);
  const endRangeDate = formatToDateWithFixedTimeZone(end);

  const endDateByCalendar = startOfDay(lastDayOfMonth(startRangeDate));
  const endDate = isBefore(endDateByCalendar, endRangeDate) ? endDateByCalendar : endRangeDate;

  const monthDates = eachDayOfInterval({ start: startRangeDate, end: endDate });

  const datesByMonth = [monthDates];

  if (isSameMonth(startRangeDate, endRangeDate)) return datesByMonth;

  while (isBeforeEndDate(datesByMonth, endRangeDate)) {
    const previousMonthEndDate = getLastDate(datesByMonth);
    const nextMonthStartDate = startOfMonth(addMonths(previousMonthEndDate, ONE_MONTH));

    const nextMonthEndDateByCalendar = endOfMonth(nextMonthStartDate);
    const nextMonthEndDate = isBefore(nextMonthEndDateByCalendar, endRangeDate)
      ? nextMonthEndDateByCalendar
      : endRangeDate;

    const newMonthGroup = eachDayOfInterval({ start: nextMonthStartDate, end: nextMonthEndDate });

    datesByMonth.push(newMonthGroup);
  }

  return datesByMonth;
};

export const buildValuesToSubmit = (filters: IReportVacationsSmartFilters): IGetReportVacationsParameters => {
  const { stateEq, ...rest } = filters;

  return {
    ...rest,
    ...(stateEq !== SmartFilterDescriptionState.all && { stateEq }),
  };
};
