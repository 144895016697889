import { FC, useEffect } from 'react';

import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Alert,
  Stack,
  Select,
  Pagination,
  MenuItem,
  PaginationItem,
} from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';
import { useTable, usePagination, useExpanded } from 'react-table';

import Div from 'components/common/Div';
import TableSkeleton from 'components/common/TableSkeleton';

import { PER_PAGE_OPTIONS } from 'utils/pagination';

import styles from './styles';
import { ITableExpandedProps } from './types';

const TableExpanded: FC<ITableExpandedProps> = props => {
  const {
    data = [],
    columns = [],
    isHeaderHidden = false,
    isLoading = false,
    isError = false,
    pagination,
    onPaginationChange,
    isPaginationHidden = false,
    hasAccessToData = false,
  } = props;

  const pageCount = pagination?.totalPages;
  const pageSize = pagination?.pageSize;
  const pageIndex = pagination?.pageNumber - 1;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    gotoPage,
    setPageSize,
    page,
    state: { expanded },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      autoResetExpanded: false,
    },
    useExpanded,
    usePagination,
  );

  const { t } = useTranslation('table');

  useEffect(() => {
    if (pagination?.pageNumber - 1 !== pageIndex) {
      gotoPage(0);
    }
  }, [pagination]);

  const isTableDataEmpty = page.length === 0;
  const isSingleRow = page.length === 1;

  const isDataEmpty = isTableDataEmpty && !isLoading;

  const handleCurrentPageChange = (event, value: number): void => {
    onPaginationChange({ pageNumber: value, pageSize: pageSize });
    gotoPage(value - 1);
  };

  const handlePageSizeChange = event => {
    const pageSize = Number(event.target.value);
    onPaginationChange({ pageNumber: 1, pageSize: pageSize });
    gotoPage(0);
    setPageSize(pageSize);
  };

  const renderPagination = () => {
    if (isPaginationHidden) {
      return;
    }
    return (
      !isDataEmpty && (
        <Stack direction="row" sx={styles.paginationWrapper}>
          <Div sx={styles.pageNumbers}>
            {pageCount > 1 && (
              <Pagination
                shape="rounded"
                count={pageCount ?? 0}
                page={pageIndex + 1}
                onChange={handleCurrentPageChange}
                renderItem={item => (
                  <PaginationItem
                    components={{
                      previous: () => (
                        <Stack direction="row" sx={styles.paginationButton}>
                          <ChevronLeftOutlined />
                          <Typography variant="heading3">{t('previous')}</Typography>
                        </Stack>
                      ),
                      next: () => (
                        <Stack direction="row" sx={styles.paginationButton}>
                          <Typography variant="heading3">{t('next')}</Typography>
                          <ChevronRightOutlined />
                        </Stack>
                      ),
                    }}
                    {...item}
                  />
                )}
              />
            )}
          </Div>
          <Stack sx={styles.perPage}>
            <Select
              sx={styles.selector}
              onChange={handlePageSizeChange}
              value={pageSize}
              defaultValue={PER_PAGE_OPTIONS.at(0)}
            >
              {PER_PAGE_OPTIONS.map(pageSize => (
                <MenuItem key={pageSize} value={pageSize}>
                  {pageSize}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="heading3">{t('itemsPerPage')}</Typography>
          </Stack>
        </Stack>
      )
    );
  };

  if (isError) {
    return <Alert severity="error">{t('errorText')}</Alert>;
  }

  if (!hasAccessToData) {
    return <Typography textAlign="center">{t('noAccessToData')}</Typography>;
  }

  return (
    <Div sx={styles.root}>
      <Div sx={styles.tableRoot}>
        <MuiTable sx={MUISx(styles.table, { condition: isLoading, sx: styles.tableLoading })} {...getTableProps()}>
          <TableHead sx={MUISx(styles.tableHead, { condition: isHeaderHidden, sx: styles.headerHidden })}>
            {headerGroups.map((headerGroup, rowIndex) => {
              return (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, columnIndex, columns) => {
                    const isFirstHeaderRow = rowIndex === 0;
                    const isSecondHeaderRow = rowIndex === 1;
                    const isThirdHeaderRow = rowIndex === 2;
                    const isFirstHeaderColumn = columnIndex === 0;
                    const isSecondHeaderColumn = columnIndex === 1;
                    const isLastHeaderColumn = columns.length - 1 === columnIndex;

                    return (
                      <TableCell
                        sx={MUISx(
                          styles.cell,
                          { condition: isFirstHeaderRow, sx: styles.firstHeaderRow },
                          { condition: isSecondHeaderRow, sx: styles.secondHeaderRow },
                          { condition: isFirstHeaderColumn, sx: styles.firstHeaderColumn },
                          { condition: isLastHeaderColumn, sx: styles.lastHeaderColumn },
                          { condition: isSecondHeaderColumn, sx: styles.headerColumnsGroup },
                          { condition: isThirdHeaderRow, sx: styles.totalHoursRow },
                        )}
                        {...column.getHeaderProps({
                          style: {
                            width: column.width ?? 'auto',
                            minWidth: column.minWidth ?? 'auto',
                            maxWidth: column.maxWidth ?? 'auto',
                          },
                        })}
                      >
                        {column.render('Header')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);

              const isOpacityRow = !_.isEmpty(expanded) && !row.isExpanded && row.depth === 0;

              return (
                <TableRow
                  sx={MUISx(styles.row, {
                    condition: isOpacityRow,
                    sx: styles.rowWithOpacity,
                  })}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, cellIndex, cells) => {
                    const isFirstBodyColumn = cellIndex === 0;
                    const isSecondBodyColumn = cellIndex === 1;
                    const isLastBodyColumn = cells.length - 1 === cellIndex;
                    const isExpandedRow = row.depth === 1;

                    return (
                      <TableCell
                        sx={MUISx(
                          styles.cell,
                          { condition: isSingleRow, sx: styles.singleRow },
                          { condition: isFirstBodyColumn, sx: styles.firstHeaderColumn },
                          { condition: isLastBodyColumn, sx: styles.lastHeaderColumn },
                          { condition: isSecondBodyColumn, sx: styles.headerColumnsGroup },
                          {
                            condition: isExpandedRow || row.isExpanded,
                            sx: styles.depthRow,
                          },
                        )}
                        {...cell.getCellProps({
                          style: {
                            width: cell.column.width ?? 'auto',
                            minWidth: cell.column.minWidth ?? 'auto',
                            maxWidth: cell.column.maxWidth ?? 'auto',
                          },
                        })}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>

        {isLoading && <TableSkeleton perPage={pageSize} />}
        {isDataEmpty && <Typography textAlign="center">{t('noData')}</Typography>}
      </Div>
      {renderPagination()}
    </Div>
  );
};

export default TableExpanded;
