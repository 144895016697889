import { FC, useContext, useState } from 'react';

import { Alert, Button, List, ListItem, Typography } from '@mui/material';
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import Loader from 'components/common/Loader';
import SearchBar from 'components/pages/AdminPanel/components/SearchBar';

import { ModalContext } from 'contexts/ModalContext';

import { useDeleteForbiddenWordMutation, useGetForbiddenWordsQuery } from 'domain/forbiddenWord/apiSlice';
import { IForbiddenWord } from 'domain/forbiddenWord/types';

import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';
import { getSortParameter } from 'utils/runsack';

import CreateForbiddenWordForm from './components/CreateForbiddenWordForm';
import styles from './styles';

const ForbiddenWords: FC = () => {
  const { getConfirmation } = useConfirmDialog();

  const [searchString, setSearchString] = useState<string>('');

  const [debouncedSearchString] = useDebounce(searchString, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: forbiddenWordsData,
    isLoading: isForbiddenWordsDataLoading,
    isError: isForbiddenWordsDataLoadingError,
  } = useGetForbiddenWordsQuery({
    wordCont: debouncedSearchString,
    sort: getSortParameter('word', 'asc'),
  });

  const { t } = useTranslation('adminPanel');

  const { openModal } = useContext(ModalContext);

  const [deleteForbiddenWord, { isLoading: isDeleteForbiddenWordLoading }] = useDeleteForbiddenWordMutation();

  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const handleAddForbiddenWordModalOpen = () => {
    openModal({
      title: t('forbiddenWords.add'),
      content: <CreateForbiddenWordForm />,
    });
  };

  const handleForbiddenWordDelete = async (forbiddenWord: IForbiddenWord) => {
    const { word, id } = forbiddenWord;
    if (
      await getConfirmation({
        title: '',
        message: (
          <Trans
            t={t}
            i18nKey="forbiddenWords.deleteMessage"
            values={{ word }}
            components={{
              1: <Typography variant="subtitle" component="span" sx={styles.deleteWord} />,
            }}
          ></Trans>
        ),
      })
    ) {
      try {
        await deleteForbiddenWord(id).unwrap();
        showSuccessNotification();
      } catch (error) {
        const errors = generateBackendErrorMessages(error);
        for (const message of errors) {
          showErrorNotification(message);
        }
      }
    }
  };

  const forbiddenWordlistItem = forbiddenWord => {
    return (
      <ListItem key={forbiddenWord.id}>
        <Div sx={styles.listItemContainer}>
          <Icon name="listPointer" sx={styles.listPointer} />
          <Div sx={styles.textContainer}>
            <Typography variant="subtitle1">{forbiddenWord.word}</Typography>
            <Div data-actions="item-actions" sx={styles.actions}>
              <Button
                variant="text"
                disabled={isDeleteForbiddenWordLoading}
                sx={styles.deleteButton}
                onClick={() => handleForbiddenWordDelete(forbiddenWord)}
              >
                <Typography variant="subtitle3">{t('buttons.delete')}</Typography>
              </Button>
            </Div>
          </Div>
        </Div>
      </ListItem>
    );
  };

  if (isForbiddenWordsDataLoading) {
    return <Loader />;
  }

  if (isForbiddenWordsDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  return (
    <Div sx={styles.container}>
      <Div sx={styles.searchBar}>
        <SearchBar
          searchString={searchString}
          setSearchString={setSearchString}
          placeholder={t('forbiddenWords.searchBar')}
          buttonLabel={t('forbiddenWords.addButton')}
          onAddNodeButtonClick={handleAddForbiddenWordModalOpen}
          buttonWidth={145}
        />
      </Div>
      {!_.isEmpty(forbiddenWordsData) && (
        <Div sx={styles.forbiddenWordsContainer}>
          <List>
            {forbiddenWordsData.forbiddenWords.map(forbiddenWord => {
              return forbiddenWordlistItem(forbiddenWord);
            })}
          </List>
        </Div>
      )}
    </Div>
  );
};

export default ForbiddenWords;
