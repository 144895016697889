import { provideTags, TagTypes } from 'shared/api/types';

import { ConditionState } from 'components/common/SmartFilter/enums';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { ICreateHolidayCalendarFormDataToSubmit } from './schemas/create';
import { IUpdateHolidayCalendarFormDataToSubmit } from './schemas/update';
import { IHolidayCalendar } from './types';

export interface IHolidayCalendarResponse {
  calendar: IHolidayCalendar;
}

interface IGetHolidayCalendarsQueryParameters {
  stateEq?: ConditionState;
  nameCont?: string;
  codeCont?: string;
  s?: ISortParameter;
}

interface IGetHolidayCalendarsPlainParameters extends IGetHolidayCalendarsQueryParameters {
  page?: number;
  perPage?: number;
}

interface IGetHolidayCalendarsParameters {
  q?: IGetHolidayCalendarsQueryParameters;
  perPage?: number;
  page?: number;
}

interface IHolidayCalendarsResponse {
  calendars: Array<IHolidayCalendar>;
  meta: IMeta;
}

export const loadingGetHolidayCalendarsParameters = (
  parameters: IGetHolidayCalendarsPlainParameters,
): IGetHolidayCalendarsParameters => {
  const { page, perPage, ...queryParameters } = parameters;
  return {
    perPage,
    page,
    q: { ...queryParameters },
  };
};

export const holidayCalendarApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getHolidayCalendars: builder.query<IHolidayCalendarsResponse, IGetHolidayCalendarsParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1HolidayCalendarsPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.HolidayCalendar, response?.calendars),
    }),
    createHolidayCalendar: builder.mutation<IHolidayCalendarResponse, ICreateHolidayCalendarFormDataToSubmit>({
      query: ({ formData }) => ({
        url: apiRoutes.apiV1HolidayCalendarsPath(),
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: [TagTypes.HolidayCalendar, TagTypes.HolidayCalendarYear],
    }),
    updateHolidayCalendar: builder.mutation<IHolidayCalendarResponse, IUpdateHolidayCalendarFormDataToSubmit>({
      query: ({ formData, calendarId }) => ({
        url: apiRoutes.apiV1HolidayCalendarPath(calendarId),
        method: 'PUT',
        data: formData,
      }),
      invalidatesTags: [TagTypes.HolidayCalendar, TagTypes.HolidayCalendarYear],
    }),
    deleteHolidayCalendar: builder.mutation<IHolidayCalendarResponse, number>({
      query: calendarId => ({
        url: apiRoutes.apiV1HolidayCalendarPath(calendarId),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.HolidayCalendar, TagTypes.HolidayCalendarYear],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateHolidayCalendarMutation,
  useGetHolidayCalendarsQuery,
  useUpdateHolidayCalendarMutation,
  useDeleteHolidayCalendarMutation,
} = holidayCalendarApi;
