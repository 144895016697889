import { FC } from 'react';

import { Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import i18n from 'locales/i18n';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { formatToDateWithFixedTimeZone, formatDateToString } from 'utils/dateTime';
import { getFullName } from 'utils/person';

import styles from './styles';
import { IInfoTitle, IProfileInfo } from './types';

const INFO_TITLES: Array<IInfoTitle> = [
  {
    title: i18n.t('userProfile:info.location'),
    logo: 'location',
    source: 'location',
  },
  {
    title: i18n.t('userProfile:info.employer'),
    logo: 'location',
    source: 'groupName',
  },
  {
    title: i18n.t('userProfile:info.localTime'),
    logo: 'time',
    source: '',
  },
  {
    title: i18n.t('userProfile:info.dateOfBirth'),
    logo: 'calendarSmall',
    source: 'dateOfBirth',
  },
  {
    title: i18n.t('userProfile:info.firstDay'),
    logo: 'briefcase',
    source: 'employmentStartDate',
  },
];

const ProfileInfo: FC<IProfileInfo> = props => {
  const { user } = props;

  const { t } = useTranslation('userProfile');

  const formatedProfileData = {
    dateOfBirth: _.isNil(user.dateOfBirth) ? null : formatDateToString(formatToDateWithFixedTimeZone(user.dateOfBirth)),
    location: user.location,
    groupName: user.group.name,
    employmentStartDate: formatDateToString(formatToDateWithFixedTimeZone(user.employmentStartDate)),
    expectedBillableTimePercent: `${user.expectedBillableTimePercent} %`,
  };

  const name = getFullName(user);

  return (
    <Div sx={styles.container}>
      {_.isNil(user.avatar) ? (
        <Icon sx={styles.avatarPlaceholder} name="avatarPlaceholder" />
      ) : (
        <img
          src={user.avatar}
          alt={t('info.avatarPlaceholder')}
          width="321px"
          height="321px"
          referrerPolicy="no-referrer"
        />
      )}

      <Div sx={styles.userNameContainer}>
        <Typography variant="h2">{name}</Typography>
      </Div>
      <Div>
        {INFO_TITLES.map(({ title, logo, source }) => (
          <Div sx={styles.infoContainer} key={title}>
            <Div sx={styles.iconContainer}>
              <Icon name={logo} />
            </Div>
            <Typography variant="subtitle4" sx={styles.subtitle}>
              {title}
            </Typography>
            <Typography variant="subtitle1">{formatedProfileData[source] ?? '---'}</Typography>
          </Div>
        ))}
      </Div>
      <Div sx={styles.contactContainer}>
        <Typography variant="body1">{t('info.contact')}</Typography>
        <Div sx={styles.infoContainer}>
          <Div sx={styles.iconContainer}>
            <Icon name="letter" />
          </Div>
          <Typography variant="subtitle1">{user.email}</Typography>
        </Div>
        <Div sx={styles.infoContainer}>
          <Div sx={styles.iconContainer}>
            <Icon name="phone" />
          </Div>
          <Typography variant="subtitle1">{user.phoneNumber ?? '---'}</Typography>
        </Div>
      </Div>
    </Div>
  );
};

export default ProfileInfo;
