import { SxStyles } from 'theme';

const styles: SxStyles = {
  yearChangeSlider: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 2,
    color: 'primary.main',
  },
  yearChangeSliderPlaceholder: {
    display: 'flex',
    width: '75px',
    alignItems: 'center',
    cursor: 'default',
    marginX: 0.625,
    marginY: 0,
  },
  yearButton: {
    color: 'button.main',
  },
  calendarIconBlock: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 0.75,
  },
  monthPickerRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 1,
    width: '316px',
  },
  month: {
    width: '100px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    background: 'background.default',
    boxShadow: theme => `-1px 1px 2px ${theme.palette.border.shadow}`,
    borderRadius: '4px',
    color: 'text.gray',
    marginBottom: 1,
    marginRight: 1,
    '&:nth-of-type(3n)': {
      marginRight: 0,
    },
  },
  selectedMonth: {
    backgroundColor: 'background.primaryLight',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'primary.main',
  },
};

export default styles;
