import { FC } from 'react';

import { ExpandMoreOutlined } from '@mui/icons-material';
import { InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { GroupingOption } from 'enums/GroupingOption';

import { capitalizeFirstLetter } from 'utils/string';

import styles from './styles';
import { IReportTrackedTimeEntriesGroupedByCategoryProps } from './types';

const GroupedByCategory: FC<IReportTrackedTimeEntriesGroupedByCategoryProps> = props => {
  const { group, setGroup } = props;

  const { t } = useTranslation('reportTrackedTimeEntries');

  return (
    <Div sx={styles.root}>
      <InputLabel sx={styles.groupedByLabel} id="groupedBy-select-label">
        <Typography sx={styles.groupedByLabelText} variant="heading3">
          {t('groupedByCategory.label')}
        </Typography>
      </InputLabel>
      <Select
        labelId="groupedBy-select-label"
        sx={styles.select}
        value={group}
        onChange={event => setGroup(event.target.value as GroupingOption)}
        displayEmpty
        IconComponent={ExpandMoreOutlined}
      >
        {Object.values(GroupingOption).map(groupBy => {
          return (
            <MenuItem key={groupBy} value={groupBy}>
              <Typography sx={styles.groupByOptionText} variant="subtitle1">
                {capitalizeFirstLetter(groupBy)}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </Div>
  );
};

export default GroupedByCategory;
