import { FC } from 'react';

import { Typography } from '@mui/material';

import Div from 'components/common/Div';

import styles from './styles';
import { IGeneralInfoBlockProps } from './types';

const InfoBlock: FC<IGeneralInfoBlockProps> = props => {
  const { generalProjectInfoBlock, onClick: onUserSelectOpen, canUpdateManagementTeam } = props;

  const handleClick = canUpdateManagementTeam ? onUserSelectOpen : undefined;
  const contentStyle = canUpdateManagementTeam ? styles.contentName : styles.notClickable;

  return (
    <Div key={generalProjectInfoBlock.title} sx={styles.root}>
      <Typography variant="subtitle3" sx={styles.title}>
        {generalProjectInfoBlock.title}
      </Typography>

      <Typography variant="h5" sx={contentStyle} onClick={handleClick}>
        {generalProjectInfoBlock.content}
      </Typography>
    </Div>
  );
};

export default InfoBlock;
