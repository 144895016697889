import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IUser } from 'domain/user/types';

import { ReportType } from '../enums';

export interface ICreateReportFilterFormData<T> {
  name: string;
  filters: T;
  reportType: ReportType;
  user: IUser;
}

export interface ICreateReportFilterFormDataToSubmit
  extends Omit<ICreateReportFilterFormData<unknown>, 'filters' | 'user'> {
  filter: string;
  userId: number;
}

const requiredValidation = 'forms:validation:required';

export const buildValidationFields = () => ({
  name: yup.string().required(i18n.t(requiredValidation)).default(''),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (
  formData: ICreateReportFilterFormData<unknown>,
): ICreateReportFilterFormDataToSubmit => {
  const { name, reportType, user, filters } = formData;
  return {
    name,
    reportType: reportType,
    filter: JSON.stringify(filters),
    userId: user.id,
  };
};
