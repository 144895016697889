import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    marginY: 5.25,
    marginX: 6.25,
  },
  resourceAbility: {
    justifyContent: 'space-between',
    display: 'flex',
  },
};

export default styles;
