import { FC, useContext } from 'react';

import { Button, ListItem, Typography, Tooltip } from '@mui/material';
import camelize from 'camelize';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { ModalContext } from 'contexts/ModalContext';

import { useDeleteAccessLevelMutation } from 'domain/accessLevel/apiSlice';
import { PERMISSION_OPTIONS } from 'domain/accessLevel/service';
import { ITechnology } from 'domain/technology/types';

import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useNotifications } from 'hooks/useNotifications';
import { usePermissions } from 'hooks/usePermissions';

import { generateBackendErrorMessages } from 'utils/error';

import Divider from './components/Divider';
import UpdateUserProfileAdditionAccessLevelForm from './components/UpdateUserProfileAdditionAccessLevelForm';
import styles from './styles';
import { IUserProfileAccessLevelProps } from './types';

const UserProfileAccessLevel: FC<IUserProfileAccessLevelProps> = props => {
  const { userProfileAccessLevel, user, isUserProfileProjectAccessLevel } = props;

  const { t } = useTranslation('userProfile');

  const { isRoleFeatureAllowed } = usePermissions();

  const { openModal } = useContext(ModalContext);

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const { getConfirmation } = useConfirmDialog();

  const [deleteAccessLevel, { isLoading: isDeleteAccessLevelLoading }] = useDeleteAccessLevelMutation();

  const handleUserProfileAdditionAccessLevelDeleteClick = async () => {
    if (
      await getConfirmation({
        title: '',
        message: t('userProfile:accessLevel.userProfileAccessLevel.deleteAccessLevelConfirmationDialog.message'),
      })
    ) {
      try {
        await deleteAccessLevel(userProfileAccessLevel.id).unwrap();
        showSuccessNotification();
      } catch (error) {
        const errors = generateBackendErrorMessages(error);
        for (const message of errors) {
          showErrorNotification(message);
        }
      }
    }
  };

  const handleUserProfileAdditionAccessLevelUpdateClick = () => {
    openModal({
      title: isUserProfileProjectAccessLevel
        ? t(
            'userProfile:accessLevel.userProfileAccessLevel.updateUserProfileAdditionAccessLevelModalTitle.project.label',
          )
        : t(
            'userProfile:accessLevel.userProfileAccessLevel.updateUserProfileAdditionAccessLevelModalTitle.group.label',
          ),
      content: (
        <UpdateUserProfileAdditionAccessLevelForm
          userProfileAccessLevel={userProfileAccessLevel}
          user={user}
          isUserProfileProjectAccessLevel={isUserProfileProjectAccessLevel}
        />
      ),
    });
  };

  if (isDeleteAccessLevelLoading) {
    return <Loader />;
  }

  const userPermission = PERMISSION_OPTIONS[camelize(userProfileAccessLevel.permission)];
  const canUpdateAccessLevel = user.permissions.canUpdateAccessLevel;

  return (
    <ListItem sx={styles.accessLevelItem}>
      <Div sx={styles.contentText}>
        <Typography variant="h4">{userProfileAccessLevel.accessiblePath}</Typography>
        {userProfileAccessLevel.technologies.map((technology: ITechnology) => (
          <Div sx={styles.technologiesList} key={technology.id}>
            <Divider />
            <Typography variant="h4">{technology.ancestorsPath}</Typography>
          </Div>
        ))}
        <Divider />
        <Typography variant="h4">{userPermission}</Typography>
      </Div>
      {isRoleFeatureAllowed('canUpdateAccessLevel') && (
        <Tooltip title={t('tooltips.notAvailable')} disableHoverListener={canUpdateAccessLevel}>
          <Div sx={styles.actions}>
            <Button
              onClick={handleUserProfileAdditionAccessLevelUpdateClick}
              type="button"
              variant="text"
              disabled={isDeleteAccessLevelLoading || !canUpdateAccessLevel}
            >
              {t('userProfile:accessLevel.userProfileAccessLevel.buttons.edit')}
            </Button>
            <Button
              sx={styles.deleteButton}
              type="button"
              variant="text"
              onClick={handleUserProfileAdditionAccessLevelDeleteClick}
              disabled={isDeleteAccessLevelLoading || !canUpdateAccessLevel}
            >
              {t('userProfile:accessLevel.userProfileAccessLevel.buttons.delete')}
            </Button>
          </Div>
        </Tooltip>
      )}
    </ListItem>
  );
};

export default UserProfileAccessLevel;
