import { SxStyles } from 'theme';

const styles: SxStyles = {
  moreHorizButton: {
    display: 'flex',
  },
  moreHorizIcon: {
    color: 'primary.main',
  },
  removeAction: {
    color: 'text.darkRed',
  },
};

export default styles;
