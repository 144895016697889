import { FC, useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Button, FormControlLabel, Switch, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import _ from 'lodash';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import TechnologiesAutocomplete from 'components/common/TechnologiesAutocomplete';

import { ModalContext } from 'contexts/ModalContext';

import { useGetTechnologiesQuery } from 'domain/technology/apiSlice';
import { ITechnology } from 'domain/technology/types';
import { useCreateVacancyMutation } from 'domain/vacancy/apiSlice';
import {
  formatAttributesToSubmit,
  ICreateVacancyFormData,
  initialValues,
  buildValidationSchema,
} from 'domain/vacancy/schemas/create';

import { MeasurementUnitFilter } from 'enums/MeasurementUnitFilter';

import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';
import { getSortParameter } from 'utils/runsack';

import styles from './styles';
import { ICreateVacancyFormProps } from './types';

const CreateVacancyForm: FC<ICreateVacancyFormProps> = props => {
  const { projectId, groupId } = props;
  const { closeModal } = useContext(ModalContext);

  const [technologyName, setTechnologyName] = useState<string>('');
  const [hasEndDate, setHasEndDate] = useState<boolean>(false);

  const { t } = useTranslation('createResourceUsageAndVacancy');
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [createVacancy, { isLoading: isCreateVacancyLoading }] = useCreateVacancyMutation();

  const [debouncedTechnologyName] = useDebounce(technologyName, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: teachnologiesData,
    isLoading: isTechnologiesLoading,
    isFetching: isTehcnologiesFetching,
  } = useGetTechnologiesQuery({
    nameCont: debouncedTechnologyName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ICreateVacancyFormData>({
    defaultValues: initialValues(),
    resolver: yupResolver(buildValidationSchema()),
  });

  useEffect(() => {
    if (!hasEndDate) {
      setValue('endDate', null);
    }
  }, [hasEndDate]);

  const {
    field: { value: technologies, onChange: onTechnologiesChange },
  } = useController({ name: 'technologies', control });

  const {
    field: { value: startDate, onChange: onStartDateChange },
  } = useController({ name: 'startDate', control });

  const {
    field: { value: endDate, onChange: onEndDateChange },
  } = useController({ name: 'endDate', control });

  const onSubmit = async (formData: ICreateVacancyFormData) => {
    try {
      await createVacancy(formatAttributesToSubmit(projectId, groupId, formData)).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const isDataLoading = isTechnologiesLoading || isCreateVacancyLoading;

  if (isDataLoading) {
    return <Loader />;
  }

  const handleCurrentTechnologiesChange = (technologies: Array<ITechnology>) => {
    if (_.isEmpty(technologies)) {
      onTechnologiesChange([]);
      return;
    }
    onTechnologiesChange(technologies);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.root}>
        <Div sx={styles.select}>
          <TechnologiesAutocomplete
            technologies={teachnologiesData.technologies}
            currentTechnologies={technologies}
            onCurrentTechnologiesChange={handleCurrentTechnologiesChange}
            setTechnologyName={setTechnologyName}
            isError={Boolean(errors.technologies)}
            isTechnologiesFetching={isTehcnologiesFetching}
            label={t('createVacancyForm.vacancyStack.label')}
            isRequired
            helperText={buildErrorMessage(t, errors.technologies)}
          />
        </Div>

        <FormControlLabel
          sx={styles.hasEndDate}
          control={<Switch checked={hasEndDate} onChange={() => setHasEndDate(!hasEndDate)} />}
          label={<Typography variant="subtitle1">{t('switches.endDate.label')}</Typography>}
        />

        <Div sx={styles.groupFields}>
          <Div sx={styles.datePicker}>
            <DatePicker
              label={t('fields.startDate.label')}
              value={startDate}
              onChange={onStartDateChange}
              renderInput={parameters => (
                <TextField
                  required
                  {...parameters}
                  error={Boolean(errors.startDate)}
                  helperText={buildErrorMessage(t, errors.startDate)}
                  sx={styles.datePickerField}
                />
              )}
            />
          </Div>

          <Div sx={styles.datePicker}>
            <DatePicker
              label={t('fields.endDate.label')}
              value={endDate}
              onChange={onEndDateChange}
              disabled={!hasEndDate}
              renderInput={parameters => (
                <TextField
                  {...parameters}
                  helperText={buildErrorMessage(t, errors.endDate)}
                  error={Boolean(errors.endDate)}
                  sx={styles.datePickerField}
                />
              )}
            />
          </Div>

          <TextField
            required
            inputProps={{ minLength: 1, maxLength: 3 }}
            sx={styles.input}
            {...register('fte')}
            label={`${MeasurementUnitFilter.FTE} %`}
            InputLabelProps={{ sx: styles.selectPlaceholder }}
            error={Boolean(errors.fte)}
          />
        </Div>

        <Div>
          <Button type="submit" sx={styles.addResourceButton} disabled={isDataLoading}>
            {t('buttons.addResource')}
          </Button>
          <Button type="reset" onClick={closeModal} variant="outlined" disabled={isDataLoading}>
            {t('buttons.cancel')}
          </Button>
        </Div>
      </Div>
    </form>
  );
};

export default CreateVacancyForm;
