import { SxStyles } from 'theme';

const styles: SxStyles = {
  moreHorizButton: {
    display: 'flex',
    marginRight: 3,
    marginTop: 0.75,
  },
  moreHorizIcon: {
    color: 'primary.main',
  },
  removeVacancyAction: {
    color: 'text.darkRed',
  },
};

export default styles;
