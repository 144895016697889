import { FC, useMemo, useState } from 'react';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import { ConditionState } from 'components/common/SmartFilter/enums';

import StatusMenu from '../StatusMenu';

import styles from './styles';
import { IStatusBarProps } from './types';

const StatusBar: FC<IStatusBarProps> = ({ onMenuItemClick, status }) => {
  const { t } = useTranslation('smartFilter');

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(status);

  const buttonText = useMemo(() => {
    switch (status) {
      case ConditionState.active: {
        return t(`multipleAutocomplete.state.active`);
      }
      case ConditionState.archived: {
        return t(`multipleAutocomplete.state.archived`);
      }
      case ConditionState.both: {
        return `${t('multipleAutocomplete.state.active')} & ${t('multipleAutocomplete.state.archived')}`;
      }
      default: {
        return t(`multipleAutocomplete.state.active`);
      }
    }
  }, [status, t]);

  const handleClick = event => {
    event.preventDefault();
    setIsMenuOpen(true);
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuItemClick = status => {
    onMenuItemClick(status);
    setCurrentStatus(status);
    handleClose();
  };

  return (
    <Div>
      <hr style={{ marginBottom: 0, marginTop: 0 }} />
      <Box display="flex" sx={styles.statusBar}>
        <Typography variant="h6" sx={styles.showText}>
          {t('multipleAutocomplete.show')}
        </Typography>
        <Button
          endIcon={<ArrowRightIcon sx={styles.arrowRightIcon} />}
          variant="text"
          size="small"
          sx={styles.button}
          onClick={handleClick}
        >
          {buttonText}
        </Button>
        <StatusMenu
          anchorEl={anchorElement}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          handleMenuItemClick={handleMenuItemClick}
          currentStatus={currentStatus}
        />
      </Box>
    </Div>
  );
};

export default StatusBar;
