export enum HoursByProjectTab {
  dashboard = 'dashboard',
  overview = 'overview',
  planning = 'planning',
  budgets = 'budgets',
  tasks = 'tasks',
  notes = 'notes',
  actionItems = 'actionItems',
  activities = 'activities',
  allocationChart = 'allocationChart',
}
