import { TagTypes, provideTags } from 'shared/api/types';

import { ConditionState } from 'components/common/SmartFilter/enums';

import { ITimeTrackerTrackable } from 'domain/timeTracker/trackable/types';

import { GroupByPeriodFilter } from 'enums/GroupByPeriodFilter';
import { PtoType } from 'enums/PtoType';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { CreateExportReportHoursByResourcesExportState } from './enums';
import { IReportHoursByResources } from './types';

export interface IGetReportHoursByResourcesResponse {
  meta: IMeta;
  dateRange: object;
  report: IReportHoursByResources;
}

export interface IReportHoursByResourcesSmartFilters {
  projectIdsIn?: Array<number>;
  resourceNameEq?: string;
  groupIdEq?: number | null;
  utilizationLteq?: number | null;
  utilizationGteq?: number | null;
  allocationGteq?: number | null;
  allocationLteq?: number | null;
  resourceStateEq?: ConditionState | '';
  trackables?: Array<ITimeTrackerTrackable>;
}

interface IGetReportHoursByResourcesParameters {
  detailed?: boolean;
  page?: number;
  perPage?: number;
  groupByPeriod?: GroupByPeriodFilter;
  startDate?: string;
  endDate?: string;
  sort?: ISortParameter;
  pto?: PtoType;
}

interface ICreateExportReportHoursByResourcesExport {
  id: number;
  state: CreateExportReportHoursByResourcesExportState;
}

interface ICreateExportReportHoursByResourcesResponse {
  export: ICreateExportReportHoursByResourcesExport;
}

export const reportHoursByResourceApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getReportHoursByResources: builder.query<IGetReportHoursByResourcesResponse, IGetReportHoursByResourcesParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1ReportsHoursByResourcesPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ReportHoursByResource, response?.report?.resources),
    }),
    createCsvReportHoursByResources: builder.mutation<
      ICreateExportReportHoursByResourcesResponse,
      IGetReportHoursByResourcesParameters
    >({
      query: formData => ({
        url: apiRoutes.createCsvApiV1ReportsHoursByResourcesPath(),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
    }),
    createXlsxReportHoursByResources: builder.mutation<
      ICreateExportReportHoursByResourcesResponse,
      IGetReportHoursByResourcesParameters
    >({
      query: formData => ({
        url: apiRoutes.createXlsxApiV1ReportsHoursByResourcesPath(),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReportHoursByResourcesQuery,
  useCreateCsvReportHoursByResourcesMutation,
  useCreateXlsxReportHoursByResourcesMutation,
} = reportHoursByResourceApi;
