import { FC } from 'react';

import { List } from '@mui/material';

import { sortByDate } from 'domain/report/trackedTimeEntry/service';

import ReportTrackedTimeEntriesGroup from './components/ReportTrackedTimeEntriesGroup';
import styles from './styles';
import { IReportTrackedTimeEntriesGroupListProps } from './types';

const ReportTrackedTimeEntriesGroupList: FC<IReportTrackedTimeEntriesGroupListProps> = props => {
  const { groupedTrackedTimeEntries, group, onEntrySelect, selectedEntryIds } = props;

  const titles = Object.keys(groupedTrackedTimeEntries);

  return (
    <List sx={styles.groups}>
      {titles.map(title => {
        const reportTrackedTimeEntriesGroup = sortByDate(groupedTrackedTimeEntries[title].timeEntries);

        return (
          <ReportTrackedTimeEntriesGroup
            key={title}
            resourceId={groupedTrackedTimeEntries[title].id}
            title={title}
            group={group}
            reportTrackedTimeEntriesGroup={reportTrackedTimeEntriesGroup}
            onEntrySelect={onEntrySelect}
            selectedEntryIds={selectedEntryIds}
          />
        );
      })}
    </List>
  );
};

export default ReportTrackedTimeEntriesGroupList;
