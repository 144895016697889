/* eslint-disable sonarjs/no-duplicate-string */
import i18n from 'locales/i18n';

import { FilterPredicate } from 'enums/FilterPredicate';

import {
  SmartFilterDescriptionBillable,
  SmartFilterDescriptionApprovalState,
  SmartFilterDescriptionState,
  SmartFilterDescriptionEndDate,
  SmartFilterDescriptionVacationRequestState,
} from './enums';
import { ISmartFilterComparison } from './types';

export const INTERVAL_COMPARISONS: Array<ISmartFilterComparison> = [
  {
    textSymbol: '≤',
    description: i18n.t('smartFilter:relations.lteq.description'),
    predicate: FilterPredicate.lteq,
  },
  {
    textSymbol: '≥',
    description: i18n.t('smartFilter:relations.gteq.description'),
    predicate: FilterPredicate.gteq,
  },
  {
    textSymbol: '=',
    description: i18n.t('smartFilter:relations.equals.description'),
    predicate: FilterPredicate.eq,
  },
  {
    textSymbol: '≠',
    description: i18n.t('smartFilter:relations.notEquals.description'),
    predicate: FilterPredicate.noteq,
  },
  {
    textSymbol: '<',
    description: i18n.t('smartFilter:relations.lessThan.description'),
    predicate: FilterPredicate.lt,
  },
  {
    textSymbol: '>',
    description: i18n.t('smartFilter:relations.moreThan.description'),
    predicate: FilterPredicate.gt,
  },
];

export const SINGLE_VALUE_COMPARISONS: Array<ISmartFilterComparison> = [
  {
    textSymbol: '=',
    description: i18n.t('smartFilter:relations.equals.description'),
    predicate: FilterPredicate.eq,
  },
];

export const MULTIPLE_VALUES_COMPARISONS: ISmartFilterComparison = {
  textSymbol: '=',
  description: i18n.t('smartFilter:relations.equals.description'),
  predicate: FilterPredicate.in,
};

export const STATUS_COMPARISONS: Array<ISmartFilterComparison> = [
  {
    textSymbol: '=',
    description: SmartFilterDescriptionState.all,
    predicate: FilterPredicate.eq,
  },
  {
    textSymbol: '=',
    description: SmartFilterDescriptionState.active,
    predicate: FilterPredicate.eq,
  },
  {
    textSymbol: '=',
    description: SmartFilterDescriptionState.archived,
    predicate: FilterPredicate.eq,
  },
];

export const BILLABLE_COMPARISONS: Array<ISmartFilterComparison> = [
  {
    textSymbol: '=',
    description: SmartFilterDescriptionBillable.all,
    predicate: FilterPredicate.eq,
  },
  {
    textSymbol: '=',
    description: SmartFilterDescriptionBillable.billable,
    predicate: FilterPredicate.eq,
  },
  {
    textSymbol: '=',
    description: SmartFilterDescriptionBillable.nonBillable,
    predicate: FilterPredicate.eq,
  },
];

export const NAME_CONTAINS_COMPARISON: ISmartFilterComparison = {
  textSymbol: '=',
  description: i18n.t('smartFilter:relations.cont.description'),
  predicate: FilterPredicate.cont,
};

export const BLANK_COMPARISON: ISmartFilterComparison = {
  textSymbol: '=',
  description: i18n.t('smartFilter:relations.blank.description'),
  predicate: FilterPredicate.blank,
};

export const APPROVAL_STATUS_COMPARISONS: Array<ISmartFilterComparison> = [
  {
    textSymbol: '=',
    description: SmartFilterDescriptionApprovalState.all,
    predicate: FilterPredicate.eq,
  },
  {
    textSymbol: '=',
    description: SmartFilterDescriptionApprovalState.onApproval,
    predicate: FilterPredicate.eq,
  },
  {
    textSymbol: '=',
    description: SmartFilterDescriptionApprovalState.approved,
    predicate: FilterPredicate.eq,
  },
  {
    textSymbol: '=',
    description: SmartFilterDescriptionApprovalState.completed,
    predicate: FilterPredicate.eq,
  },
];

export const VACATION_REQUEST_STATUS_COMPARISONS: Array<ISmartFilterComparison> = Object.values(
  SmartFilterDescriptionVacationRequestState,
).map(description => ({
  description,
  textSymbol: '=',
  predicate: FilterPredicate.eq,
}));

export const END_DATE_COMPARISONS: Array<ISmartFilterComparison> = [
  {
    textSymbol: '=',
    description: SmartFilterDescriptionEndDate.all,
    predicate: FilterPredicate.null,
  },
  {
    textSymbol: '=',
    description: SmartFilterDescriptionEndDate.withEndDate,
    predicate: FilterPredicate.null,
  },
  {
    textSymbol: '=',
    description: SmartFilterDescriptionEndDate.withoutEndDate,
    predicate: FilterPredicate.null,
  },
];
