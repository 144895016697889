export default {
  title: 'Profile',
  buttons: {
    impersonate: 'Impersonate',
    edit: 'Edit User',
    activate: 'Activate',
    delete: 'Archive',
  },
  info: {
    avatarPlaceholder: 'Profile avatar placeholder',
    contact: 'Contact Information',
    location: 'Location:',
    employer: 'Employer:',
    localTime: 'Local time:',
    dateOfBirth: 'DOB:',
    firstDay: '1st Day:',
  },
  control: {
    specializationAndStack: 'Specialization & Stack',
    projects: 'Projects',
    accessLevel: 'Access Level',
    vacation: 'Vacation',
    schedule: 'Schedule',
    migrations: 'Migrations',
  },
  specializationAndStack: {
    addButton: 'Add Specialization or Stack',
    addModalTitle: 'Add Specialization',
    deleteModalTitle: 'Remove Specialization',
    deleteModalText:
      'Are you sure you want to remove specialization/stack <1>{{ userProfileStackAbility }}</1> of <2>{{ userFullName }}<2/> ?',
    removeSkill: 'Remove Skill',
    resetSkill: 'Reset Skill Level',
    changeSkill: 'Change Skill Level',
    updateSkillModalSubtitle:
      'You are about to change <1>{{ userProfileStackAbility }}</1>skill level of <2>{{ userFullName }}<2/>',
    stackInputLabel: 'Stack',
    skillInputLabel: 'Skill Level',
    saveButton: 'Save',
    cancelButton: 'Cancel',
    removeButton: 'End Allocation',
    notAvailable: 'You do not have permission to perform this action',
  },
  projects: {
    active: 'Active',
    inactive: 'Inactive',
    project: 'Project',
    fte: 'FTE',
    specialization: 'Specialization',
    productDirector: 'Product Director',
    teamLead: 'Team Lead',
    projectManager: 'Project Manager',
  },
  schedule: {
    weeklyHours: 'Weekly Hours',
    timePercent: 'Time Percent',
    startDate: 'Start Date',
    endDate: 'End Date',
    actions: 'Actions',
    hoursPerWeek: 'Hours Per Week',
    expectedBillableTimePercent: 'Expected Billable Time Percent',
    notDefined: 'Not defined',
    createHoursPerWeekForm: {
      title: 'Add Hours Per Week',
      buttons: {
        add: 'Add',
        cancel: 'Cancel',
      },
    },
    createExpectedBillableTimePercentForm: {
      title: 'Add Expected Billable Time Percent',
      buttons: {
        add: 'Add',
        cancel: 'Cancel',
      },
    },
    updateExpectedBillableTimePercentForm: {
      title: 'Update Billable Time Percent',
      buttons: {
        update: 'Update',
        cancel: 'Cancel',
      },
    },
    updateHoursPerWeekForm: {
      title: 'Update Hours Per Week',
      buttons: {
        update: 'Update',
        cancel: 'Cancel',
      },
    },
    deleteHoursPerWeekConfirmationDialog: {
      message: 'Are you sure want to remove Hours Per Week?',
    },
    deleteExpectedBillableTimePercentConfirmationDialog: {
      message: 'Are you sure want to remove Expected Billable Time Percent?',
    },
  },
  accessLevel: {
    createGroupAccessModalTitle: 'Add Group Access',
    createProjectAccessModalTitle: 'Add Project Access',
    createAdditionalAccessForm: {
      buttons: {
        addAccess: 'Add Access',
        cancel: 'Cancel',
      },
      selects: {
        accessibleType: {
          label: 'Access Type',
        },
        permission: {
          label: 'Access Level',
        },
        group: {
          label: 'Group',
        },
        project: {
          label: 'Project',
        },
      },
    },
    userProfileAccessLevel: {
      buttons: {
        edit: 'Edit',
        delete: 'Delete',
      },
      notAvailable: 'You do not have permission to perform this action',
      updateUserProfileAdditionAccessLevelModalTitle: {
        group: {
          label: 'Edit Group Access',
        },
        project: {
          label: 'Edit Project Access',
        },
      },
      updateAdditionalAccessForm: {
        buttons: {
          save: 'Save',
          cancel: 'Cancel',
        },
        selects: {
          permission: {
            label: 'Access Level',
          },
        },
      },
      deleteAccessLevelConfirmationDialog: {
        message: 'Are you sure you want to delete Access Level?',
      },
    },
    titles: {
      groupAccess: 'Group Access',
      projectAccess: 'Project Access',
    },
    buttons: {
      addGroupAccess: 'Add Group Access',
      addProjectAccess: 'Add Project Access',
    },
  },
  vacation: {
    approve: 'Approve',
    approved: 'Approved',
    available: '{{year}} Available',
    cancel: 'Cancel',
    carryOver: '{{year}} Carryover',
    days: '{{days}}d',
    decline: 'Decline',
    declined: 'Declined',
    newVacationRange: '{{start}} - {{end}}',
    onApproval: 'Waiting for approval',
    onApprovalRange: '{{start}} — {{end}}',
    scheduled: '{{year}} Scheduled',
    sendRequest: 'Send Request',
    workingHours: '{{hours}} w. h.',
  },
  tooltips: {
    sendRequest: 'Send Request',
    notAvailable: 'You do not have permission to perform this action',
  },
  migrations: {
    title: 'Migrations',
    group: 'Tech Center',
    dates: 'Dates',
    createNewLocationButton: 'Add New Location',
    addModalTitle: 'Add New Location',
    editModalTitle: 'Edit Location',
    createNewLocationInputLabel: 'Select the tech center',
    createNewLocationAddButton: 'Add Location',
    createNewLocationCancelButton: 'Cancel',
    createNewLocationStartDate: 'Start Date',
    createNewLocationEndDate: 'End Date',
    updateLocationSaveButton: 'Save',
    actionEditLabel: 'Edit',
  },
};
