import { SxStyles } from 'theme';

const styles: SxStyles = {
  datePickerButtonPlaceholder: {
    border: 'none',
    borderRadius: '6px',
    backgroundColor: 'background.lightGray',
    color: 'text.lightGray',
  },
  datePickerButtonPlaceholderText: {
    marginRight: 1,
  },
};

export default styles;
