import { SxStyles } from 'theme';

const styles: SxStyles = {
  header: {
    marginTop: 5.5,
    marginBottom: 2,
  },
};

export default styles;
