import { FC, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ProjectRole } from 'domain/project/enums';

import { IProjectBudgetType } from 'types/project';

import { getFullName } from 'utils/person';

import GeneralInfoBlocks from './components/GeneralInfoBlocks';
import HoursInfoBlock from './components/HoursInfoBlock';
import styles from './styles';
import { IInfoProps, IHoursBlock, IGeneralProjectInfo } from './types';

const Info: FC<IInfoProps> = props => {
  const { project, canUpdateBudgets, canUpdateManagementTeam } = props;

  const [budgetType, setBudgetType] = useState<IProjectBudgetType>('monthly');

  const { t } = useTranslation('reportHoursByProject');

  const isMonthlyTypeBudget = budgetType === 'monthly';

  const hoursBlocks: Array<IHoursBlock> = useMemo(
    () => [
      {
        title: isMonthlyTypeBudget
          ? t('info.titles.hoursBlocks.monthlyBudget')
          : t('info.titles.hoursBlocks.totalBudget'),
        hours: isMonthlyTypeBudget ? project.monthlyBudget : project.totalBudget,
        icon: 'dollarInCircle',
        isBudgetBlock: true,
      },
      {
        title: t('info.titles.hoursBlocks.monthlyPlanned'),
        hours: project.monthlyPlanned,
        icon: 'planned',
      },
      {
        title: t('info.titles.hoursBlocks.vacancyIncluded'),
        hours: project.vacanciesPlanned,
        icon: '🪑',
        isEmoji: true,
      },
    ],
    [project, budgetType],
  );

  const assign = true;
  const generalProjectInfo: Array<IGeneralProjectInfo> = useMemo(
    () => [
      {
        title: t('info.titles.generalProjectInfo.productDirector'),
        content: getFullName(project.productDirector, assign),
        role: ProjectRole.productDirector,
      },
      {
        title: t('info.titles.generalProjectInfo.teamLead'),
        content: getFullName(project.teamLead, assign),
        role: ProjectRole.teamLead,
      },
      {
        title: t('info.titles.generalProjectInfo.projectManager'),
        content: getFullName(project.projectManager, assign),
        role: ProjectRole.projectManager,
      },
    ],
    [project],
  );

  return (
    <Div sx={styles.root}>
      {hoursBlocks.map(hoursBlock => {
        return (
          <HoursInfoBlock
            key={hoursBlock.title}
            hoursBlock={hoursBlock}
            project={project}
            budgetType={budgetType}
            setBudgetType={setBudgetType}
            canUpdateBudgets={canUpdateBudgets}
          />
        );
      })}
      <GeneralInfoBlocks
        generalProjectInfo={generalProjectInfo}
        project={project}
        canUpdateManagementTeam={canUpdateManagementTeam}
      />
    </Div>
  );
};

export default Info;
