import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IHolidayCalendarYearShort } from 'domain/holiday/calendar/year/types';

export interface IUpdateHolidayCalendarFormData {
  name: string;
}

export interface IUpdateHolidayCalendarFormDataToSubmit {
  formData: IUpdateHolidayCalendarFormData;
  calendarId: number;
}

const requiredValidation = 'forms:validation:required';

export const buildValidationFields = () => ({
  name: yup
    .string()
    .max(30, i18n.t('forms:validation:maxSymbols', { count: 30 }))
    .required(i18n.t(requiredValidation))
    .default(''),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (values: IHolidayCalendarYearShort) => {
  if (_.isNil(values)) {
    return buildValidationSchema().getDefault();
  }

  return {
    name: values.calendarName,
  };
};

export const formatAttributesToSubmit = (values: IUpdateHolidayCalendarFormData, calendarId: number) => {
  const { name } = values;

  return {
    calendarId,
    formData: {
      name,
    },
  };
};
