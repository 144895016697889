import { SxStyles } from 'theme';

const styles: SxStyles = {
  tableLegend: {
    display: 'flex',
    alignItems: 'center',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 4,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  colorItem: {
    width: 12,
    height: 12,
    marginRight: 1,
  },
  completed: {
    backgroundColor: 'background.lightDarkGreen',
  },
  approved: {
    backgroundColor: 'background.mint',
  },
  requested: {
    backgroundColor: 'background.orange',
  },
};

export default styles;
