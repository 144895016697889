import { SxStyles } from 'theme';

const styles: SxStyles = {
  monthPicker: {
    marginBottom: 4,
  },
  monthPickerPlaceholderButton: {
    backgroundColor: 'transparent',
  },
};

export default styles;
