import { IMuiListItemType } from './types';

const MuiListItem: IMuiListItemType = {
  defaultProps: {},
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
};

export default MuiListItem;
