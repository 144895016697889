import { FC } from 'react';

import { TextField } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

import Div from 'components/common/Div';
import TimeTrackerTrackableDuration from 'components/common/TimeTrackerTrackableDuration';

import { EntityAction } from 'enums/EntityAction';

import styles from './styles';
import { IDatePickerWithDurationProps } from './types';

const DatePickerWithDuration: FC<IDatePickerWithDurationProps> = props => {
  const { date, setDate, duration, setDuration, isDisabled } = props;

  return (
    <Div sx={styles.root}>
      <TimeTrackerTrackableDuration
        duration={duration}
        setDuration={setDuration}
        isDisabled={isDisabled}
        type={EntityAction.update}
      />
      <StaticDatePicker
        componentsProps={{
          actionBar: {
            actions: [],
          },
        }}
        showToolbar={false}
        value={date}
        onChange={setDate}
        renderInput={parameters => <TextField {...parameters} />}
      />
    </Div>
  );
};

export default DatePickerWithDuration;
