import { FC } from 'react';

import { Button } from '@mui/material';

import Div from 'components/common/Div';

import { usePermissions } from 'hooks/usePermissions';

import styles from './styles';
import { ICreateResourceAbilityButtonProps } from './types';

const CreateResourceAbilityButton: FC<ICreateResourceAbilityButtonProps> = props => {
  const { buttonLabel, onAddNodeButtonClick } = props;

  const { isRoleFeatureAllowed } = usePermissions();

  return (
    <Div sx={styles.container}>
      {isRoleFeatureAllowed('canCreateSpecializationOrStack') && (
        <Button variant="contained" sx={styles.addButton} onClick={onAddNodeButtonClick}>
          {buttonLabel}
        </Button>
      )}
    </Div>
  );
};

export default CreateResourceAbilityButton;
