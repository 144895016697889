import { TagTypes } from 'shared/api/types';

import { ICalendarDay } from 'domain/holiday/calendar/year/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IUpdateHolidayCalendarFormDataToSubmit } from './schemas/update';

export interface IHolidayCalendarDayResponse {
  day: ICalendarDay;
}

export const holidayCalendarDayApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    updateHolidayCalendarDay: builder.mutation<IHolidayCalendarDayResponse, IUpdateHolidayCalendarFormDataToSubmit>({
      query: ({ formData, dayId }) => ({
        url: apiRoutes.apiV1HolidayCalendarsDayPath(dayId),
        method: 'PUT',
        data: formData,
      }),
      invalidatesTags: [TagTypes.HolidayCalendar, TagTypes.HolidayCalendarYear, TagTypes.HolidayCalendarDay],
    }),
  }),
  overrideExisting: false,
});

export const { useUpdateHolidayCalendarDayMutation } = holidayCalendarDayApi;
