import Loader from 'components/common/Loader';

import { useReportsVacationsRequestApprovementsQuery } from 'domain/report/vacationRequest/apiSlice';

import ApprovementsTable from './components/ApprovementsTable';
import { IApprovementsProps } from './types';

const Approvements = (props: IApprovementsProps) => {
  const { vacationsRequestId } = props;

  const { data, isLoading } = useReportsVacationsRequestApprovementsQuery({ vacationsRequestId });

  if (isLoading || !data) {
    return <Loader />;
  }

  return <ApprovementsTable approvements={data.approvements} />;
};

export default Approvements;
