import { FC } from 'react';

import { Typography } from '@mui/material';

import { IUsersTableCellProps } from './types';

const TableCell: FC<IUsersTableCellProps> = props => {
  const { value } = props;

  return <Typography variant="subtitle1">{value}</Typography>;
};

export default TableCell;
