import { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Chip, Typography } from '@mui/material';

import styles from './styles';
import { IUserListProps } from './types';

const UserList: FC<IUserListProps> = props => {
  const { users, onRemove } = props;

  return (
    <>
      {users.map(user => (
        <Chip
          sx={styles.userChip}
          onDelete={() => onRemove(user)}
          key={user.value}
          deleteIcon={<CloseIcon sx={styles.closeChipIcon} />}
          label={
            <Typography variant="heading4" sx={styles.chipUserName}>
              <Avatar sx={styles.userAvatar} src={user.avatar} />
              {user.value}
            </Typography>
          }
        />
      ))}
    </>
  );
};

export default UserList;
