import { SxStyles } from 'theme';

const styles: SxStyles = {
  workloadBlock: {
    textAlign: 'center',
    width: '50px',
  },
};

export default styles;
