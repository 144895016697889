import _ from 'lodash';

import { SlotField } from 'components/common/SmartFilter/enums';

import { IReportHoursByResource, IReportHoursByResourceProjectItem } from 'domain/report/hoursByResource/types';

import { dateToString } from 'utils/calendar';
import { parseShortDate } from 'utils/dateTime';

export const makeParametersForResourceNameClick = (filters, resource: IReportHoursByResource) => {
  return {
    [SlotField.resource]: [resource.id],
    afterDate: dateToString(filters.startDate),
    beforeDate: dateToString(filters.endDate),
  };
};

export const makeParametersForProjectNameClick = (
  filters,
  resource: IReportHoursByResource,
  project: IReportHoursByResourceProjectItem,
  billable: boolean,
) => {
  if (_.isNil(resource) || _.isNil(project)) {
    return;
  }

  const parameters: Record<string, unknown> = {
    [SlotField.resource]: [resource.id],
    afterDate: dateToString(filters.startDate),
    beforeDate: dateToString(filters.endDate),
  };

  if (project) {
    parameters.trackables = [
      {
        id: project.id,
        name: project.name,
        billable,
        type: 'Project',
        category: 'project',
      },
    ];
  }

  return parameters;
};

export const makeParametersForResourceActualHoursClick = (
  resource: IReportHoursByResource,
  options: {
    startDate: string;
    endDate: string;
  },
) => {
  return {
    [SlotField.resource]: [resource.id],
    afterDate: dateToString(parseShortDate(options.startDate)),
    beforeDate: dateToString(parseShortDate(options.endDate)),
  };
};

export const makeParametersForResourcePlannedHoursClick = (
  resource: IReportHoursByResource,
  options: {
    startDate: string;
    endDate: string;
  },
) => {
  return {
    [SlotField.resource]: [resource.id],
    startDate: dateToString(parseShortDate(options.startDate)),
    endDate: dateToString(parseShortDate(options.endDate)),
  };
};

export const makeParametersForProjectActualHoursClick = (
  resource: IReportHoursByResource,
  project: IReportHoursByResourceProjectItem,
  billable: boolean,
  options: {
    startDate: string;
    endDate: string;
  },
) => {
  if (_.isNil(resource) || _.isNil(project)) {
    return;
  }

  const parameters: Record<string, unknown> = {
    [SlotField.resource]: [resource.id],
    afterDate: dateToString(parseShortDate(options.startDate)),
    beforeDate: dateToString(parseShortDate(options.endDate)),
  };

  if (project) {
    parameters.trackables = [
      {
        id: project.id,
        name: project.name,
        billable,
        type: project.type,
        category: 'project',
      },
    ];
  }

  return parameters;
};
