import { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import styles from './styles';
import { IYearVacationInfoProps } from './types';

const YearVacationInfo: FC<IYearVacationInfoProps> = props => {
  const { currentYear, totals } = props;
  const { t } = useTranslation('userProfile');

  const rows = [
    {
      label: t('vacation.carryOver', { year: currentYear - 1 }),
      value: totals.carryoverPrev,
    },
    {
      label: t('vacation.available', { year: currentYear }),
      value: totals.available,
    },
    {
      label: t('vacation.scheduled', { year: currentYear }),
      value: totals.scheduled,
    },
    {
      label: t('vacation.carryOver', { year: currentYear + 1 }),
      value: totals.carryoverNext,
    },
  ];

  return (
    <Div sx={styles.root}>
      {rows.map(row => (
        <Div sx={styles.row} key={row.label}>
          <Typography sx={styles.label} variant="heading3">
            {row.label}
          </Typography>

          <Typography sx={styles.value} variant="heading3">
            {row.value}
          </Typography>
        </Div>
      ))}
    </Div>
  );
};

export default YearVacationInfo;
