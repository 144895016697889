import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: 3,
    paddingBottom: 3,
  },
  infoBlock: {
    display: 'flex',
  },
  today: {
    marginRight: 3,
  },
  title: {
    color: 'text.gray',
    marginRight: 1,
  },
};

export default styles;
