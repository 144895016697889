import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    position: 'relative',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    backgroundColor: 'background.white',
    marginTop: 2.5,
  },
  rootTable: {
    overflowX: 'auto',
    position: 'relative',
  },

  table: {
    width: 'auto',
    borderCollapse: 'separate',
  },
  tableLoading: {
    display: 'none',
  },
  withoutBorderHeaderRow: {
    borderBottom: 'none',
  },
  thirdHeaderRow: {
    borderBottom: theme => `3px solid ${theme.palette.border.main}`,
  },
  column: {
    padding: 1,
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: 'border.main',
  },
  lastColumn: {
    borderRight: 'none',
  },
  lastRow: {
    borderBottom: 'none',
  },
  resourceHeaderCell: {
    textAlign: 'center',
    color: 'red',
  },
  headerYearColumn: {
    verticalAlign: 'bottom',
    backgroundColor: 'background.lightGray',
  },
  bodyYearColumn: {
    textAlign: 'center',
    backgroundColor: 'background.lightGray',
  },
  leftStickyGroup: {
    position: 'sticky',
    left: 0,
    top: 0,
    backgroundColor: 'background.white',
  },

  carryoverPreviousColumnSticky: {
    textAlign: 'center',
    left: '250px',
    backgroundColor: 'background.white',
  },

  monthHeaderColumn: {
    textAlign: 'center',
    borderRightWidth: '3px',
    borderRightStyle: 'solid',
    borderRightColor: 'border.main',
  },
  dayColumn: {
    textAlign: 'center',
  },
  working25: {
    backgroundColor: 'background.green',
    opacity: '25%',
  },
  working50: {
    backgroundColor: 'background.green',
    opacity: '50%',
  },
  working75: {
    backgroundColor: 'background.green',
    opacity: '75%',
  },
  working: {
    backgroundColor: 'background.green',
  },
  holiday: {
    backgroundColor: 'background.orange',
  },
  dayColumnApproved: {
    backgroundColor: 'background.mint',
  },
  dayColumnRequested: {
    backgroundColor: 'background.orange',
  },
  dayWeekendColumn: {
    backgroundColor: 'border.lightGray',
  },
  dayLastOfMonthColumn: {
    borderRightWidth: '3px',
    borderRightStyle: 'solid',
    borderRightColor: 'border.main',
    '&:last-pf-type': {
      borderRight: 'none',
    },
  },
  tooltipCell: {
    width: ' 20px',
    height: ' 20px',
  },
};

export default styles;
