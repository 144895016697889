import React, { FC, useMemo } from 'react';

import { Button, ListItem, Typography } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { TimeTrackerTrackableTypeGroups as trackableGroups } from 'domain/timeTracker/trackable/enums';
import {
  hasTasks,
  isProjectType,
  generateProjectTaskMenuId,
  generateProjectTaskOptionId,
} from 'domain/timeTracker/trackable/service';
import { ITimeTrackerTrackable } from 'domain/timeTracker/trackable/types';

import ProjectTasksMenu from '../ProjectTasksMenu';

import styles from './styles';
import { IProjectTasksOptionsListProps } from './types';

const ProjectTasksOptionsList: FC<IProjectTasksOptionsListProps> = ({
  options,
  onClose,
  onOptionClick,
  onProjectTasksMenuClose,
  onTrackableWithTaskChange,
  onCurrentTrackerTrackableChange,
  isProjectTasksMenuOpen,
  tasksModalReference,
  currentValue,
}) => {
  const { t } = useTranslation('timeTracker');
  const groupedTasksOptionsList = useMemo(() => {
    return _.groupBy(options, (option: ITimeTrackerTrackable) =>
      t(`createProjectAndTaskButton.autocomplete.typeGroup.${trackableGroups[option.type]}`),
    );
  }, [options]);
  const isSelectedOption = (value: ITimeTrackerTrackable) => value === currentValue.trackable;

  return (
    <Div sx={styles.box}>
      {Object.keys(groupedTasksOptionsList).map(groupName => (
        <Div key={groupName} sx={styles.groupList}>
          <Div sx={styles.groupItem}>
            <Typography sx={styles.groupListTitle} variant="heading4" align="left">
              {groupName}
            </Typography>
            {groupedTasksOptionsList[groupName].map(value => (
              <ListItem
                key={generateProjectTaskOptionId(value)}
                sx={isSelectedOption(value) ? styles.selectedOption : styles.option}
              >
                {hasTasks(value) && isProjectType(value) ? (
                  <>
                    <Button
                      type="button"
                      sx={MUISx(styles.optionButton, styles.projectOptionButton)}
                      variant="text"
                      aria-describedby={generateProjectTaskMenuId(value)}
                      onClick={_ => {
                        onCurrentTrackerTrackableChange(value);
                        onOptionClick();
                      }}
                    >
                      <Typography sx={styles.optionText} variant="subtitle1">
                        {value.name}
                      </Typography>
                      <Div sx={styles.arrowRightBlock}>
                        <Icon sx={styles.arrowRightIcon} name="arrowRight" />
                      </Div>
                    </Button>
                    {!_.isNil(currentValue.trackable) &&
                      isSelectedOption(value) &&
                      isProjectType(currentValue.trackable) && (
                        <ProjectTasksMenu
                          projectTasksMenuId={generateProjectTaskMenuId(value)}
                          isOpen={isProjectTasksMenuOpen}
                          menuElementRef={tasksModalReference}
                          onClose={onProjectTasksMenuClose}
                          currentValue={currentValue}
                          onSetTask={onTrackableWithTaskChange}
                          onFullClose={onClose}
                        />
                      )}
                  </>
                ) : (
                  <Button
                    sx={styles.optionButton}
                    type="button"
                    variant="text"
                    onClick={_ => {
                      onCurrentTrackerTrackableChange(value);
                      onClose();
                    }}
                  >
                    <Typography sx={styles.optionText} variant="subtitle1">
                      {value.name}
                    </Typography>
                  </Button>
                )}
              </ListItem>
            ))}
          </Div>
        </Div>
      ))}
    </Div>
  );
};

export default ProjectTasksOptionsList;
