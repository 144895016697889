import { provideTags, TagTypes } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { ICreateNewLocationFormDataToSubmit } from './schemas/create';
import { IUpdateLocationFormDataToSubmit } from './schemas/update';
import { IUserProfileMigration } from './types';

interface IUserProfileMigrationsResponse {
  usersMigrations: Array<IUserProfileMigration>;
  meta: IMeta;
}

interface IGetUserProfileMigrationsParameters {
  groupIdsIn?: Array<number>;
  userIdsIn?: Array<number>;
  startDate?: string;
  endDate?: string;
  page?: number;
  perPage?: number;
}

export const userProfileMigrationsApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getUserProfileMigrations: builder.query<IUserProfileMigrationsResponse, IGetUserProfileMigrationsParameters>({
      query: parameters => ({
        url: apiRoutes.apiV1ReportsUsersMigrationsPath(),
        method: 'GET',
        params: parameters,
      }),
      providesTags: response => provideTags(TagTypes.UserProfileMigrations, response?.usersMigrations),
    }),
    createUserProfileMigration: builder.mutation<IUserProfileMigrationsResponse, ICreateNewLocationFormDataToSubmit>({
      query: formData => ({
        url: apiRoutes.apiV1ReportsUsersMigrationsPath(),
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: [TagTypes.UserProfileMigrations],
    }),
    updateUserProfileMigration: builder.mutation<IUserProfileMigration, IUpdateLocationFormDataToSubmit>({
      query: ({ userMigration, migrationId }) => ({
        url: apiRoutes.apiV1ReportsUsersMigrationPath(migrationId),
        method: 'PUT',
        data: { userMigration },
      }),
      invalidatesTags: [{ type: TagTypes.UserProfileMigrations }],
    }),
  }),
});

export const {
  useGetUserProfileMigrationsQuery,
  useCreateUserProfileMigrationMutation,
  useUpdateUserProfileMigrationMutation,
} = userProfileMigrationsApi;
