import { TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { ITimeTrackerTrackable } from './types';

interface IGetTimeTrackerTrackablesResponse {
  trackables: Array<ITimeTrackerTrackable>;
  meta: IMeta;
}

interface IGetTimeTrackerTrackablesQueryParameters {
  nameCont?: string;
  s?: ISortParameter;
}

interface IGetTimeTrackerTrackablesPlainParameters extends IGetTimeTrackerTrackablesQueryParameters {
  page?: number;
  perPage?: number;
  paginated?: boolean;
  s?: ISortParameter;
}

interface IGetTimeTrackerTrackablesParameters {
  page?: number;
  perPage?: number;
  paginated?: boolean;
  q?: IGetTimeTrackerTrackablesQueryParameters;
}

export const loadingGetTimeTrackerTrackablesParameters = (
  parameters: IGetTimeTrackerTrackablesPlainParameters,
): IGetTimeTrackerTrackablesParameters => {
  const { page, perPage, paginated, ...queryParameters } = parameters;
  return {
    perPage,
    page,
    paginated,
    q: { ...queryParameters },
  };
};

export const timeTrackerTrackableApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getTimeTrackerTrackables: builder.query<IGetTimeTrackerTrackablesResponse, IGetTimeTrackerTrackablesParameters>({
      query: ({ ...parameters }) => ({
        url: apiRoutes.apiV1TimeTrackerTrackablesPath(),
        method: 'GET',
        params: {
          ...parameters,
        },
      }),
      providesTags: response => provideTags(TagTypes.TimeTrackerTrackable, response?.trackables),
    }),
  }),
  overrideExisting: false,
});

export const { useGetTimeTrackerTrackablesQuery } = timeTrackerTrackableApi;
