import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    boxSizing: 'border-box',
    width: '369px',
    backgroundColor: 'background.white',
    border: '1px solid',
    borderColor: 'border.main',
    borderRadius: '8px',
    padding: 3,
    minHeight: '80vh',
    flexShrink: 0,
  },
  userNameContainer: {
    paddingY: 2,
    paddingX: 0,
    borderBottom: '1px solid',
    borderColor: 'border.gray',
    marginBottom: 2,
  },
  infoContainer: {
    marginTop: 1,
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  iconContainer: {
    width: '24px',
    height: '24px',
    color: 'border.main',
  },
  subtitle: {
    color: 'text.primary',
    opacity: '0.64',
    marginRight: 1,
    textTransform: 'uppercase',
  },
  calendar: {
    width: '18px',
    height: '16px',
  },
  contactContainer: {
    marginTop: 3,
    wordWrap: 'break-word',
  },
  avatarPlaceholder: {
    width: '321px',
    height: '321px',
  },
};

export default styles;
