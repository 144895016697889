import { FC } from 'react';

import { List } from '@mui/material';

import { useRouter } from 'hooks';

import NavigationItem from './components/NavigationItem';
import styles from './styles';
import { INavigationProps } from './types';

const Navigation: FC<INavigationProps> = props => {
  const { navigationItems, currentUser } = props;

  const { pathname } = useRouter();

  return (
    <List sx={styles.navigation}>
      {navigationItems.map(navigationItem => {
        const navigationItemPath = navigationItem.route.path();

        const isActiveLink = pathname.startsWith(navigationItemPath);

        return (
          <NavigationItem
            key={navigationItem.title}
            navigationItem={navigationItem}
            isActive={isActiveLink}
            currentUser={currentUser}
          />
        );
      })}
    </List>
  );
};

export default Navigation;
