import { useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { useCreateReportFilterMutation } from 'domain/report/filter/apiSlice';
import {
  initialValues,
  buildValidationSchema,
  ICreateReportFilterFormData,
  formatAttributesToSubmit,
} from 'domain/report/filter/schemas/create';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { ICreateReportFilterFormProps } from './types';

function CreateReportFilterForm<T>(props: ICreateReportFilterFormProps<T>): JSX.Element {
  const { filters, reportType } = props;

  const { t } = useTranslation('smartFilter');

  const { data: currentUser, isLoading: isCurrentUserLoading, isError: isCurrentUserError } = useGetCurrentUserQuery();

  const [createReportFilter, { isLoading: isCreateReportFilterLoading }] = useCreateReportFilterMutation();

  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const { closeModal } = useContext(ModalContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateReportFilterFormData<T>>({
    defaultValues: initialValues(),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: name, onChange: onNameChange },
  } = useController({ name: 'name', control });

  const onSubmit = async () => {
    const user = currentUser.user;
    const formData = {
      name,
      reportType,
      user,
      filters,
    };

    try {
      await createReportFilter(formatAttributesToSubmit(formData)).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const isDisabled = isCurrentUserLoading || isCurrentUserError || isCreateReportFilterLoading;

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.nameField}>
        <TextField
          sx={styles.name}
          value={name}
          onChange={onNameChange}
          error={Boolean(errors.name)}
          fullWidth
          inputProps={{ maxLength: 100 }}
          label={t('reportFilter.label')}
          InputLabelProps={{ sx: styles.placeholder }}
          helperText={buildErrorMessage(t, errors.name)}
        />
      </Div>

      <Div>
        <Button sx={styles.createButton} type="submit" disabled={isDisabled}>
          {t('reportFilter.buttons.save')}
        </Button>
        <Button onClick={closeModal} type="button" variant="outlined" disabled={isDisabled}>
          {t('reportFilter.buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
}

export default CreateReportFilterForm;
