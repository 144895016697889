export default {
  pagination: {
    previous: 'Previous',
    next: 'Next',
  },
  errotText: 'Error',
  itemsPerPage: 'Items per page',
  noData: 'No Data',
  noAccessToData: 'You do not have access to data.',
};
