import { FC, useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, TextField } from '@mui/material';
import _ from 'lodash';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Autocomplete from 'components/common/Autocomplete';
import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { ModalContext } from 'contexts/ModalContext';

import { useGetGroupsQuery, useUpdateGroupMutation } from 'domain/group/apiSlice';
import {
  formatAttributesToSubmit,
  initialValues,
  IUpdateGroupFormData,
  buildValidationSchema,
} from 'domain/group/schema/update';
import { IGroup } from 'domain/group/types';
import { loadingGetHolidayCalendarsParameters, useGetHolidayCalendarsQuery } from 'domain/holiday/calendar/apiSlice';
import { IHolidayCalendar } from 'domain/holiday/calendar/types';

import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';
import { getSortParameter } from 'utils/runsack';

import styles from './styles';
import { IUpdateHierarchyGroupFormProps } from './types';

const UpdateHierarchyGroupForm: FC<IUpdateHierarchyGroupFormProps> = props => {
  const { currentSuperGroup, currentGroup } = props;

  const [superGroupName, setSuperGroupName] = useState<string>('');
  const [debouncedGroupName] = useDebounce(superGroupName, DEFAULT_DEBOUNCE_DELAY_MS);

  const [holidayCalendarName, setHolidayCalendarName] = useState<string>('');
  const [debouncedHolidayCalendarName] = useDebounce(holidayCalendarName, DEFAULT_DEBOUNCE_DELAY_MS);

  const { t } = useTranslation(['common', 'adminPanel']);

  const {
    data: groupsData,
    isLoading: isGroupsDataLoading,
    isFetching: isGroupsDataFetching,
    isError: isGroupsDataLoadingError,
  } = useGetGroupsQuery({
    nameCont: debouncedGroupName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const holidayCalendarsQueryParameters = loadingGetHolidayCalendarsParameters({
    nameCont: debouncedHolidayCalendarName,
  });
  const {
    data: holidayCalendarsData,
    isLoading: isHolidayCalendarsDataLoading,
    isFetching: isHolidayCalendarsDataFetching,
    isError: isHolidayCalendarsDataLoadingError,
  } = useGetHolidayCalendarsQuery(holidayCalendarsQueryParameters);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IUpdateGroupFormData>({
    defaultValues: initialValues(currentGroup, currentSuperGroup),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: parentGroup, onChange: onSuperGroupChange },
  } = useController({ name: 'parentGroup', control });

  const {
    field: { value: calendar, onChange: onCalendarChange },
  } = useController({ name: 'calendar', control });

  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const { closeModal } = useContext(ModalContext);

  const [updateGroup, { isLoading: isUpdateGroupLoading }] = useUpdateGroupMutation();

  const onSubmit = async (formData: IUpdateGroupFormData) => {
    try {
      const parameters = formatAttributesToSubmit(currentGroup.id, formData);
      await updateGroup(parameters).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const isLoading = isGroupsDataLoading || isHolidayCalendarsDataLoading;
  const isError = isGroupsDataLoadingError || isHolidayCalendarsDataLoadingError;

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCurrentSuperGroupChange = (currentGroup: IGroup) => {
    if (_.isNil(currentGroup)) {
      onSuperGroupChange(null);
      return;
    }
    onSuperGroupChange(currentGroup);
  };

  const handleCurrentCalendarChange = (currentCalendar: IHolidayCalendar) => {
    if (_.isNil(currentCalendar)) {
      onCalendarChange(null);
      return;
    }
    onCalendarChange(currentCalendar);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.input}>
        <TextField
          variant="outlined"
          error={Boolean(errors.name)}
          fullWidth
          label={t('adminPanel:hierarchy.inputName')}
          {...register('name')}
          disabled={isUpdateGroupLoading}
          helperText={buildErrorMessage(t, errors.name)}
          InputLabelProps={{ sx: styles.placeholder }}
        ></TextField>
      </Div>

      <Div sx={styles.autocomplete}>
        <Autocomplete
          options={groupsData.groups}
          isFetching={isGroupsDataFetching}
          isError={Boolean(errors.parentGroup)}
          name={superGroupName}
          setName={setSuperGroupName}
          onCurrentValueChange={handleCurrentSuperGroupChange}
          currentValue={parentGroup}
          label={t('adminPanel:hierarchy.inputParent')}
        />
      </Div>

      <Autocomplete
        options={holidayCalendarsData.calendars}
        isFetching={isHolidayCalendarsDataFetching}
        isError={Boolean(errors.calendar)}
        name={holidayCalendarName}
        setName={setHolidayCalendarName}
        onCurrentValueChange={handleCurrentCalendarChange}
        currentValue={calendar}
        label={t('adminPanel:hierarchy.calendar')}
        isRequired
        helperText={buildErrorMessage(t, errors.calendar)}
      />

      <Div sx={styles.buttonsContainer}>
        <Button type="submit" variant="contained" disabled={isUpdateGroupLoading}>
          {t('adminPanel:buttons.editNode')}
        </Button>
        <Button variant="outlined" onClick={closeModal} disabled={isUpdateGroupLoading}>
          {t('adminPanel:buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default UpdateHierarchyGroupForm;
