import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 1.25,
  },
  select: {
    width: '100%',
  },
  selectPlaceholder: {
    color: 'text.gray',
  },
};

export default styles;
