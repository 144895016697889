import { invalidateTags, getListTag, TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { ICreateReportFilterFormDataToSubmit } from './schemas/create';
import { IReportFilter } from './types';

interface IGetReportFiltersResponse {
  filters: Array<IReportFilter>;
  meta: IMeta;
}

interface IReportFilterResponse {
  filter: IReportFilter;
}

interface IGetReportFiltersParameters {
  page?: number;
  perPage?: number;
  nameCont?: string;
  userIdEq: number;
  reportTypeEq: string;
  paginated?: boolean;
  sort?: ISortParameter;
}

export const reportFilterApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getReportFilters: builder.query<IGetReportFiltersResponse, IGetReportFiltersParameters>({
      query: parameters => ({
        url: apiRoutes.apiV1ReportsFiltersPath(),
        method: 'GET',
        params: {
          ...parameters,
        },
      }),
      providesTags: response => provideTags(TagTypes.Filter, response?.filters),
    }),
    getReportFilter: builder.query<IReportFilterResponse, number>({
      query: id => ({
        url: apiRoutes.apiV1ReportsFilterPath(id),
        method: 'GET',
      }),
      providesTags: response => provideTags(TagTypes.Filter, response?.filter ? [response.filter] : []),
    }),
    createReportFilter: builder.mutation<IReportFilterResponse, ICreateReportFilterFormDataToSubmit>({
      query: formData => ({
        url: apiRoutes.apiV1ReportsFiltersPath(),
        method: 'POST',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: invalidateTags(getListTag(TagTypes.Filter)),
    }),
    deleteReportFilter: builder.mutation<void, number>({
      query: id => ({
        url: apiRoutes.apiV1ReportsFilterPath(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateTags(getListTag(TagTypes.Filter)),
    }),
  }),
});

export const {
  useCreateReportFilterMutation,
  useDeleteReportFilterMutation,
  useGetReportFiltersQuery,
  useGetReportFilterQuery,
} = reportFilterApi;
