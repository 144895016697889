import { FC } from 'react';

import { Typography } from '@mui/material';

import { capitalizeFirstLetter } from 'utils/string';

import styles from './styles';
import { IReportVacationsCalendarTableHeaderMonth } from './types';

const TableHeaderMonth: FC<IReportVacationsCalendarTableHeaderMonth> = props => {
  const { title, firstDayOfMonth, lastDayOfMonth, year } = props;

  const dateRange = firstDayOfMonth === lastDayOfMonth ? firstDayOfMonth : `${firstDayOfMonth} - ${lastDayOfMonth}`;
  const header = `${dateRange} ${capitalizeFirstLetter(title)} ${year}`;

  return (
    <Typography variant="body2" sx={styles.root}>
      {header}
    </Typography>
  );
};

export default TableHeaderMonth;
