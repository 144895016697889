import decamelize from 'decamelize';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { AccessibleType, Permission } from 'domain/accessLevel/enums';
import { IGroup } from 'domain/group/types';
import { IProject } from 'domain/project/types';
import { ITechnology } from 'domain/technology/types';

export interface ICreateAccessLevelFormData {
  accessibleType: AccessibleType;
  permission: Permission;
  accessible: IGroup | IProject;
  technologies?: Array<ITechnology>;
}

export interface ICreateAccessLevelFormDataToSubmit
  extends Omit<ICreateAccessLevelFormData, 'technologies' | 'accessible'> {
  technologyIds: Array<number>;
  userId: number;
  accessibleId: number;
}

const requiredValidation = 'forms:validation:required';

export const buildValidationFields = () => ({
  accessibleType: yup.mixed<AccessibleType>().required(i18n.t(requiredValidation)).default(AccessibleType.group),
  permission: yup.mixed<Permission>().required(i18n.t(requiredValidation)).default(Permission.developer),
  accessible: yup.mixed<IGroup | IProject>().required(i18n.t(requiredValidation)).nullable().default(null),
  technologies: yup.array().of(yup.mixed<ITechnology>()).default([]),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (
  formData: ICreateAccessLevelFormData,
  userId: number,
): ICreateAccessLevelFormDataToSubmit => {
  const { technologies = [], accessible, permission, ...values } = formData;
  const technologyIds = technologies.map(technology => technology.id);
  const decamelizedPermission = decamelize(permission);
  return {
    userId,
    accessibleId: accessible.id,
    technologyIds,
    permission: decamelizedPermission,
    ...values,
  };
};
