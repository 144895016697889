export default {
  title: 'Reports',
  lastSync: 'Last sync with Toggl: {{ date }} at {{time}}',
  lastSyncNil: 'Last sync with Toggl: unknown',
  totalInfo: {
    totalHours: 'Total Hours',
    billableHours: 'Billable Hours',
  },
  groupedByCategory: {
    label: 'Grouped by',
  },
  loadMoreButton: 'Load More',
  exportTitle: 'Tracked Time Entries report',
  copyUser: 'Copy Resource',
  selectAll: 'Select All',
  deselectAll: 'Deselect All',
  copyEntryDrawer: {
    title: 'Copy Resource',
    copyingType: 'Copying Type',
    flatNameType: 'Flat list of resource names',
    slackNameType: 'List of resource Slack names',
    emailType: 'List of resource emails',
    users: 'Users',
    copy: 'Copy',
    cancel: 'Cancel',
  },
};
