import { FC } from 'react';

import { TableHead, Table as MUITable, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';
import { usePagination, useTable } from 'react-table';

import Div from 'components/common/Div';

import { getCurrentDate } from 'utils/calendar';
import { getMonthEnName } from 'utils/dateTime';

import { getColumnColor, getColumns } from './service';
import styles from './styles';
import { IReportVacationsCalendarTableProps } from './types';

const Table: FC<IReportVacationsCalendarTableProps> = props => {
  const {
    data = [],
    columns = [],
    coloredAccessors,
    weekendsAccessors,
    lastDayOfMonthAccessors,
    vacationAccessors,
  } = props;

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    usePagination,
  );

  const { t } = useTranslation('table');

  const isTableDataEmpty = page.length === 0;
  const isDataEmpty = isTableDataEmpty;

  return (
    <Div sx={styles.root}>
      <Div sx={styles.rootTable}>
        <MUITable sx={styles.table} {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, headerGroupIndex) => {
              const isFirstHeaderRow = headerGroupIndex === 0;
              const isSecondHeaderRow = headerGroupIndex === 1;
              const isThirdHeaderRow = headerGroupIndex === 2;

              return (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, columnIndex, columns) => {
                    const { id, originalId, width, minWidth, maxWidth } = column;

                    const columnAccessor = originalId ?? id;

                    const isFirstHeaderColumn = columnIndex === 0;
                    const isLastHeaderColumn = columns.length - 1 === columnIndex;

                    const {
                      isResourcesColumn,
                      isResourcesEmptyColumn,
                      isResourcesEmptyDepthColumn,
                      isMonthHeaderColumn,
                      isDayColumn,
                      isWeekendColumn,
                      isLastDayOfMonthColumn,
                      isVacationColumn,
                    } = getColumns(columnAccessor, weekendsAccessors, lastDayOfMonthAccessors, vacationAccessors);

                    const isHeaderLeftStickyGroup =
                      isResourcesColumn || isResourcesEmptyColumn || isResourcesEmptyDepthColumn;

                    const isFirstHeaderCell = isSecondHeaderRow && isFirstHeaderColumn;

                    const currentDate = getCurrentDate();
                    const isToday = columnAccessor === `${getMonthEnName(new Date()).toLowerCase()}-${currentDate}`;

                    return (
                      <TableCell
                        sx={MUISx(
                          styles.column,
                          { condition: isLastHeaderColumn, sx: styles.lastColumn },
                          { condition: isFirstHeaderCell, sx: styles.resourceHeaderCell },
                          { condition: isFirstHeaderRow || isSecondHeaderRow, sx: styles.withoutBorderHeaderRow },
                          { condition: isThirdHeaderRow, sx: styles.thirdHeaderRow },
                          { condition: isHeaderLeftStickyGroup, sx: styles.leftStickyGroup },
                          { condition: isMonthHeaderColumn, sx: styles.monthHeaderColumn },
                          { condition: isDayColumn, sx: styles.dayColumn },
                          { condition: isWeekendColumn, sx: styles.dayWeekendColumn },
                          { condition: isLastDayOfMonthColumn, sx: styles.dayLastOfMonthColumn },
                          { condition: isToday, sx: styles.dayHeaderColumnCurrent },
                          { condition: isVacationColumn && !isWeekendColumn, sx: styles.vacationColumn },
                        )}
                        {...column.getHeaderProps({
                          style: {
                            width: width ?? 'auto',
                            minWidth: minWidth ?? 'auto',
                            maxWidth: maxWidth ?? 'auto',
                          },
                        })}
                      >
                        {column.render('Header')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, rowIndex, rows) => {
              prepareRow(row);

              const isLastBodyRow = rowIndex === rows.length - 1;
              const resourceRowFullName = row.original.resources.props.fullName;

              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex, cells) => {
                    const { id: columnAccessor, width, minWidth, maxWidth } = cell.column;

                    const isLastBodyColumn = cells.length - 1 === cellIndex;

                    const {
                      isResourcesColumn,
                      isDayColumn,
                      isWeekendColumn,
                      isLastDayOfMonthColumn,
                      isVacationColumn,
                    } = getColumns(columnAccessor, weekendsAccessors, lastDayOfMonthAccessors, vacationAccessors);

                    const { isCompleted, isApproved, isRequested } = getColumnColor(
                      coloredAccessors,
                      columnAccessor,
                      resourceRowFullName,
                    );

                    const isBodyLeftStickyGroup = isResourcesColumn;
                    const currentDate = getCurrentDate();
                    const isToday = columnAccessor === `${getMonthEnName(new Date()).toLowerCase()}-${currentDate}`;

                    const isFirstDayOfVacation =
                      isVacationColumn && vacationAccessors.length > 0 && columnAccessor === vacationAccessors[0];

                    const isLastDayofVacation =
                      isVacationColumn && vacationAccessors.length > 0 && columnAccessor === vacationAccessors.at(-1);

                    return (
                      <TableCell
                        sx={MUISx(
                          styles.column,
                          { condition: isLastBodyColumn, sx: styles.lastColumn },
                          { condition: isLastBodyRow, sx: styles.lastRow },
                          { condition: isBodyLeftStickyGroup, sx: styles.leftStickyGroup },
                          { condition: isDayColumn, sx: styles.dayColumn },
                          { condition: isApproved, sx: styles.dayColumnApproved },
                          { condition: isRequested, sx: styles.dayColumnRequested },
                          { condition: isCompleted, sx: styles.dayColumnCompleted },
                          { condition: isWeekendColumn, sx: styles.dayWeekendColumn },
                          { condition: isLastDayOfMonthColumn, sx: styles.dayLastOfMonthColumn },
                          { condition: isToday, sx: styles.dayColumnCurrent },
                          { condition: isFirstDayOfVacation, sx: styles.firstDayVacationColumn },
                          { condition: isLastDayofVacation, sx: styles.lastDayVacationColumn },
                        )}
                        {...cell.getCellProps({
                          style: {
                            width: width ?? 'auto',
                            minWidth: minWidth ?? 'auto',
                            maxWidth: maxWidth ?? 'auto',
                          },
                        })}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MUITable>

        {isDataEmpty && <Typography textAlign="center">{t('noData')}</Typography>}
      </Div>
    </Div>
  );
};

export default Table;
