import { TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { PeriodType, ProjectState } from './enums';
import { ICreateProjectFormDataToSubmit } from './schemas/create';
import { IUpdateProjectFormDataToSubmit } from './schemas/update';
import { IProject } from './types';

export interface IProjectResponse {
  project: IProject;
}

interface IProjectsResponse {
  projects: Array<IProject>;
  meta: IMeta;
}

interface IGetProjectsQueryParameters {
  page?: number;
  perPage?: number;
  paginated?: boolean;
  stateEq?: ProjectState;
  periodTypeEq?: PeriodType;
  billableEq?: boolean;
  companyIdEq?: number;
  nameCont?: string;
  sort?: ISortParameter;
}

export const projectApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getProjects: builder.query<IProjectsResponse, IGetProjectsQueryParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1ProjectsPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.Project, response?.projects),
    }),
    getProject: builder.query<IProjectResponse, number>({
      query: projectId => ({
        url: apiRoutes.apiV1ProjectPath(projectId),
        method: 'GET',
      }),
      providesTags: ({ project }) => [{ type: TagTypes.Project, id: project.id }],
    }),
    createProject: builder.mutation<IProjectResponse, ICreateProjectFormDataToSubmit>({
      query: formData => ({
        url: apiRoutes.apiV1ProjectsPath(),
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: [TagTypes.Project, TagTypes.ReportHoursByProject],
    }),
    updateProject: builder.mutation<IProjectResponse, IUpdateProjectFormDataToSubmit>({
      query: ({ formData, projectId }) => ({
        url: apiRoutes.apiV1ProjectPath(projectId),
        method: 'PUT',
        data: formData,
      }),
      invalidatesTags: (result, error, { projectId }) => [
        { type: TagTypes.Project, id: projectId },
        { type: TagTypes.ReportHoursByProject, id: projectId },
      ],
    }),
    activateProject: builder.mutation<IProjectResponse, number>({
      query: projectId => ({
        url: apiRoutes.activateApiV1ProjectPath(projectId),
        method: 'PUT',
      }),
      invalidatesTags: ({ project }) => [{ type: TagTypes.Project, id: project.id }],
    }),
    deleteProject: builder.mutation<IProjectResponse, number>({
      query: projectId => ({
        url: apiRoutes.apiV1ProjectPath(projectId),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.Project, TagTypes.ReportHoursByProject],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProjectsQuery,
  useGetProjectQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useActivateProjectMutation,
  useDeleteProjectMutation,
} = projectApi;
