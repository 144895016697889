import { SxStyles } from 'theme';

const styles: SxStyles = {
  horizonIcon: {
    width: '24px',
    height: '24px',
    color: 'primary.blue',
  },
};

export default styles;
