import { FC, useContext } from 'react';

import { ListItem, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { ModalContext } from 'contexts/ModalContext';

import {
  useDeleteResourceFavoriteTimeEntryMutation,
  useGetResourceFavoriteTimeEntriesQuery,
} from 'domain/resource/favoriteTimeEntry/apiSlice';

import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';

import CreateFavoriteForm from './components/CreateFavoriteForm';
import FavoriteItem from './components/FavoriteItem';
import styles from './styles';
import { IFavoriteTrackedTimeEntriesBarProps } from './types';

const FavoriteTrackedTimeEntriesBar: FC<IFavoriteTrackedTimeEntriesBarProps> = props => {
  const { resourceId, handleClick } = props;
  const { t } = useTranslation('timeTracker');

  const { openModal } = useContext(ModalContext);
  const { data } = useGetResourceFavoriteTimeEntriesQuery({ resourceId });
  const { getConfirmation } = useConfirmDialog();

  const [deleteResourceFavoriteEntity] = useDeleteResourceFavoriteTimeEntryMutation();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const handleCreateFavoriteModalClick = () => {
    openModal({
      title: t('favoriteTrackedTimeEntriesBar.createFavoriteTimeEntry.title'),
      content: <CreateFavoriteForm resourceId={resourceId} />,
    });
  };

  const handleRemoveClick = async (event: React.MouseEvent<HTMLElement>, favoriteId: string) => {
    event.stopPropagation();
    if (
      await getConfirmation({
        title: '',
        message: t('favoriteTrackedTimeEntriesBar.deleteMessage'),
      })
    ) {
      try {
        await deleteResourceFavoriteEntity({ id: favoriteId, resourceId }).unwrap();
        showSuccessNotification();
      } catch (error) {
        const errors = generateBackendErrorMessages(error);
        const errorMessage = errors.join('\n');
        showErrorNotification(errorMessage);
      }
    }
  };
  return (
    <ListItem sx={styles.root}>
      <Div sx={styles.header}>
        <Typography sx={styles.headerTitle} variant="body1">
          {t('favoriteTrackedTimeEntriesBar.favorites')}
        </Typography>
        <Div sx={styles.group}>
          {data?.favorites.map(favorite => (
            <FavoriteItem
              key={favorite.id}
              favorite={favorite}
              handleClick={handleClick}
              handleRemoveClick={handleRemoveClick}
            />
          ))}
          <IconButton sx={styles.plusIconButton} onClick={handleCreateFavoriteModalClick}>
            <Icon sx={styles.plusIcon} name="plus" />
          </IconButton>
        </Div>
      </Div>
    </ListItem>
  );
};
export default FavoriteTrackedTimeEntriesBar;
