import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'block',
    width: '16px',
    height: '2px',
    backgroundColor: 'text.primary',
  },
};

export default styles;
