import { SxStyles } from 'theme';

const styles: SxStyles = {
  buttons: {
    display: 'flex',
  },
  closeChipIcon: {
    width: '18px',
    height: '18px',
    stroke: theme => theme.palette.primary.blue,
  },
  button: {
    color: 'text.blackLight',
    backgroundColor: 'background.white',
    maxWidth: '150px',
    '&:hover': {
      backgroundColor: 'background.white',
    },
  },
  tooltip: {
    color: 'text.blackLight',
    backgroundColor: 'background.white',
    boxShadow: 1,
  },
};

export default styles;
