import theme, { SxStyles } from 'theme';

const styles: SxStyles = {
  tableContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: 2,
    bgcolor: theme.palette.background.opacityGray,
  },
  table: {
    flex: 0,
    tableLayout: 'fixed',
    width: 'max-content',
  },
  noScroll: {
    overflow: 'hidden',
  },
  blur: {
    opacity: 0.5,
  },
  noDataTitle: theme => ({
    position: 'sticky',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%)',
    width: 'fit-content',
    fontSize: theme.typography.pxToRem(20),
  }),
};

export default styles;
