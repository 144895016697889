import { TagTypes } from 'shared/api/types';

import { IUpdateResourceUsageWorkloadPlanFormDataToSubmit } from 'domain/resource/usage/workloadPlan/schemas/update';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IResourceUsageWorkloadPlan } from './types';

interface IResourceUsageWorkloadPlanResponse {
  resourceUsageWorkloadPlan: IResourceUsageWorkloadPlan;
}

export const resourceUsageWorkloadPlanApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    updateResourceUsageWorkloadPlan: builder.mutation<
      IResourceUsageWorkloadPlanResponse,
      IUpdateResourceUsageWorkloadPlanFormDataToSubmit
    >({
      query: ({ resourceId, usageId, formData }) => ({
        url: apiRoutes.apiV1ResourceUsageWorkloadPlansPath(resourceId, usageId),
        method: 'PUT',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: [TagTypes.ReportHoursByProject],
    }),
  }),
  overrideExisting: false,
});

export const { useUpdateResourceUsageWorkloadPlanMutation } = resourceUsageWorkloadPlanApi;
