import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
  },
  total: {
    display: 'flex',
  },
  totalHours: {
    marginRight: 3,
  },
  totalTitle: {
    color: 'text.gray',
    marginRight: 1,
  },
  divider: {
    width: '2px',
    backgroundColor: 'text.gray',
    marginY: 0,
    marginX: 1,
  },
};

export default styles;
