import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 5,
    gap: 2.5,
  },
  buttonsContainer: {
    display: 'flex',
    gap: 2,
  },
  button: {
    backgroundColor: 'background.default',
    paddingY: 1,
    paddingX: 'auto',
    width: '148px',
  },
  editButton: {
    borderColor: 'border.blue',
    color: 'text.blue',
  },
  deleteButton: {
    borderColor: 'border.red',
    color: 'text.red',
  },
  activateButton: {
    borderColor: 'border.green',
    color: 'text.green',
  },
};

export default styles;
