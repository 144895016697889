import StarIcon from '@mui/icons-material/StarRate';
import { Button, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import TrackedTimeEntryProjectChip from 'components/common/TrackedTimeEntry/components/TrackedTimeEntryProjectChip';
import TrackedTimeEntryTagsChip from 'components/common/TrackedTimeEntry/components/TrackedTimeEntryTagsChip';

import { getTagsNames } from 'domain/tag/presenter';

import { getHoursAndMinutesStringFromMinutes } from 'utils/dateTime';

import styles from './styles';

const FavoriteTooltipContent = ({ favorite }) => {
  const { t } = useTranslation('timeTracker');

  const hasTimeTrackerTags = !_.isEmpty(favorite.tags);
  const hasTrackable = !_.isNil(favorite.trackableId);
  const hasDuration = favorite.duration > 0;

  const duration = getHoursAndMinutesStringFromMinutes(favorite.duration);

  return (
    <Div sx={styles.root}>
      <Div sx={styles.header}>
        <StarIcon color="primary" sx={styles.starIcon} />
        <Typography variant="h5">{t('favoriteTrackedTimeEntriesBar.favoritePopover.favorite')}</Typography>
      </Div>

      <Div sx={styles.description}>
        <Typography variant="body1">{favorite.description}</Typography>
      </Div>
      {hasTrackable && (
        <Div sx={styles.project}>
          <Button variant="text" disabled type="button">
            <TrackedTimeEntryProjectChip
              isBillable={favorite.billable}
              projectName={favorite.projectName}
              taskName={favorite.projectTaskName}
            />
          </Button>
        </Div>
      )}
      {hasTimeTrackerTags && (
        <Div sx={styles.tagsButton}>
          <Button variant="text" disabled size="small" type="button">
            <TrackedTimeEntryTagsChip tagsNames={getTagsNames(favorite.tags)} />
          </Button>
        </Div>
      )}
      {hasDuration && (
        <Div sx={styles.duration}>
          <Typography variant="body2">{duration}</Typography>
        </Div>
      )}
    </Div>
  );
};

export default FavoriteTooltipContent;
