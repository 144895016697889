export default {
  title: 'Vacations',
  tabs: {
    vacationRequests: {
      label: 'Vacation Requests',
    },
    calendar: {
      label: 'Calendar',
    },
  },
  info: {
    tableLegend: {
      tableLegendItems: {
        completed: 'Completed',
        approved: 'Approved',
        requested: 'Requested',
      },
    },
  },
  calendar: {
    tableHeaders: {
      resource: 'Resource',
      carryoverPrev: 'Carryover {{ count }}',
      available: 'Available {{ count }}',
      scheduled: 'Scheduled {{ count }}',
      carryoverNext: 'Carryover {{ count }}',
    },
  },
  vacationRequests: {
    requestDate: 'Request Date',
    resource: 'Resource',
    approvingLevel: 'Approving Level',
    specialization: 'Specialization',
    vacationRange: 'Vacation Range',
    approve: 'Approve',
    decline: 'Decline',
    days: '{{days}}d',
    range: '{{start}} - {{end}}',
    available: 'Available {{year}}',
    scheduled: 'Scheduled {{year}}',
    completed: 'Completed',
    approved: 'Approved',
    requested: 'Requested',
    today: 'Today',
    project: 'Pr',
    techCenter: 'Tc',
    tooltips: {
      project: 'Project',
      techCenter: 'Tech Center',
    },
    tabs: {
      project: {
        label: 'Project',
      },
      practice: {
        label: 'Practice',
      },
      pipeline: {
        label: 'Pipeline',
      },
    },
  },
  approvements: {
    titles: {
      approvingLevel: 'Approving Level',
      status: 'Status',
      approver: 'Approver',
      action: 'Action',
    },
    labels: {
      techCenter: 'Tech center',
      project: 'Project',
      pending: 'pending',
      approved: 'approved',
      declined: 'declined',
      none: 'none',
    },
  },
};
