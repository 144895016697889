import { FC } from 'react';

import { TableHead, TableBody, Table } from '@mui/material';
import { useTable } from 'react-table';

import Div from 'components/common/Div';

import MigrationsTableBody from './MigrationsTableBody';
import MigrationsTableHead from './MigrationsTableHead';
import styles from './styles';
import { IMigrationsTableProps } from './types';

const MigrationsTable: FC<IMigrationsTableProps> = props => {
  const { data, columns } = props;
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  });

  return (
    <Div sx={styles.root}>
      <Table sx={styles.table} {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => {
            return <MigrationsTableHead headerGroup={headerGroup} />;
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return <MigrationsTableBody row={row} />;
          })}
        </TableBody>
      </Table>
    </Div>
  );
};

export default MigrationsTable;
