import { SxStyles } from 'theme';

const primaryMainColor = 'primary.main';

const styles: SxStyles = {
  root: {
    padding: 4,
  },
  menuItem: {
    ':hover': {
      background: 'transparent',
      cursor: 'default',
    },
  },
  header: {
    marginBottom: 4,
  },
  infoFields: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '380px',
  },
  infoField: {
    minWidth: '160px',
    marginBottom: 3,
  },
  infoFieldTitle: {
    color: 'text.lightGray',
  },
  infoFieldContent: {
    color: primaryMainColor,
    cursor: 'pointer',
  },
  infoFieldContentNonClickable: {
    color: primaryMainColor,
    cursor: 'default',
  },
  twoPeopleIconButton: {
    width: '40px',
    height: '40px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: primaryMainColor,
    borderRadius: '8px',
    marginRight: 2,
  },
  twoPeopleIcon: {
    width: '23px',
    height: '17px',
    color: 'primary.blue',
  },
};

export default styles;
