import { TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { ICreateTechnologyFormDataToSubmit } from './schemas/create';
import { IUpdateTechnologyFormDataToSubmit } from './schemas/update';
import { ITechnology } from './types';

interface IGetTechnologiesParameters {
  perPage?: number;
  page?: number;
  nameCont?: string;
  parentIdEq?: number;
  sort?: ISortParameter;
}

interface ITechnologiesResponse {
  meta: IMeta;
  technologies: Array<ITechnology>;
}

interface ITechnologyResponse {
  technology: ITechnology;
}

export const technologyApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getTechnologies: builder.query<ITechnologiesResponse, IGetTechnologiesParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1TechnologiesPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.Technology, response?.technologies),
    }),
    getTechnology: builder.query<ITechnologyResponse, number>({
      query: technologyId => ({
        url: apiRoutes.apiV1TechnologyPath(technologyId),
        method: 'GET',
      }),
      providesTags: ({ technology }) => [{ type: TagTypes.Technology, id: technology.id }],
    }),
    createTechnology: builder.mutation<ITechnologyResponse, ICreateTechnologyFormDataToSubmit>({
      query: formData => ({
        url: apiRoutes.apiV1TechnologiesPath(),
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: [TagTypes.Technology],
    }),
    updateTechnology: builder.mutation<ITechnologyResponse, IUpdateTechnologyFormDataToSubmit>({
      query: ({ formData, technologyId }) => ({
        url: apiRoutes.apiV1TechnologyPath(technologyId),
        method: 'PUT',
        data: formData,
      }),
      invalidatesTags: ({ technology }) => [{ type: TagTypes.Technology, id: technology.id }],
    }),
    deleteTechnology: builder.mutation<unknown, number>({
      query: technologyId => ({
        url: apiRoutes.apiV1TechnologyPath(technologyId),
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.Technology],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTechnologiesQuery,
  useCreateTechnologyMutation,
  useDeleteTechnologyMutation,
  useGetTechnologyQuery,
  useUpdateTechnologyMutation,
} = technologyApi;
