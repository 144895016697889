import { FC, useState, useRef, useEffect, useContext } from 'react';

import { AttachMoneyOutlined } from '@mui/icons-material';
import { Button, IconButton, ListItem, Popover, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import CreateTagsButton from 'components/common/CreateTagsButton';
import DeleteTimeEntryDialog from 'components/common/DeleteTimeEntryDialog';
import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import InputField from 'components/common/InputField';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateResourceTrackedTimeEntryMutation } from 'domain/resource/trackedTimeEntry/apiSlice';
import { ITag } from 'domain/tag/types';
import { TrackedTimeEntrySource } from 'domain/timeTracker/trackedTimeEntry/enums';

import { useNotifications } from 'hooks/useNotifications';

import { IDuration } from 'types/dates';
import { ITimeTrackerTrackableState } from 'types/timeTrackerTrackable';

import {
  formatToDateWithFixedTimeZone,
  formatToUTCString,
  getHoursAndMinutesStringFromMinutes,
  getMinutesFromHours,
} from 'utils/dateTime';
import { generateBackendErrorMessages } from 'utils/error';

import DatePickerWithDuration from './components/DatePickerWithDuration';
import ProjectChip from './components/ProjectChip';
import styles from './styles';
import { IResourceTrackedTimeEntryProps } from './types';

const ResourceTrackedTimeEntry: FC<IResourceTrackedTimeEntryProps> = props => {
  const { resourceTrackedTimeEntry, resourceId, onTimeTrackerSettingsCopy } = props;

  const { t } = useTranslation('timeTracker');

  const { showErrorNotification } = useNotifications();
  const { openModal } = useContext(ModalContext);

  const [currentTimeTrackerTrackable, setCurrentTimeTrackerTrackable] = useState<ITimeTrackerTrackableState>({
    trackable: {
      id: resourceTrackedTimeEntry.trackableId,
      name: resourceTrackedTimeEntry.trackableName,
      billable: resourceTrackedTimeEntry.billable,
      type: resourceTrackedTimeEntry.trackableType,
      tasks: [resourceTrackedTimeEntry.task],
    },
    task: resourceTrackedTimeEntry.task,
  });

  const [currentTimeTrackerTags, setCurrentTimeTrackerTags] = useState<Array<ITag>>(resourceTrackedTimeEntry.tags);
  const [description, setDescription] = useState<string>(resourceTrackedTimeEntry.description);
  const [isDescriptionInputFocus, setIsDescriptionInputFocus] = useState<boolean>(false);

  const [hours, minutes] = getHoursAndMinutesStringFromMinutes(resourceTrackedTimeEntry.duration)?.split(':');

  const [duration, setDuration] = useState<IDuration>({
    hours: String(Number.parseInt(hours)),
    minutes: String(Number.parseInt(minutes)),
  });

  const [date, setDate] = useState<Date>(formatToDateWithFixedTimeZone(resourceTrackedTimeEntry.date));
  const [datePickerWithDurationElement, setDatePickerWithDurationElement] = useState<HTMLButtonElement | null>(null);

  const [isDescriptionTruncated, setIsDescriptionTruncated] = useState<boolean>(false);
  const descriptionReference = useRef<HTMLDivElement>(null);
  const previousFormData = {
    description: resourceTrackedTimeEntry.description,
    duration: resourceTrackedTimeEntry.duration,
    taskId: resourceTrackedTimeEntry.task?.id ?? null,
    trackableId: resourceTrackedTimeEntry.trackableId,
    trackableType: resourceTrackedTimeEntry.trackableType,
    tagIds: resourceTrackedTimeEntry.tags.map(({ id }) => id),
    date: formatToUTCString(formatToDateWithFixedTimeZone(resourceTrackedTimeEntry.date)),
  };

  useEffect(() => {
    const textContainer = descriptionReference?.current?.querySelectorAll('input')[0];
    if (textContainer) {
      const isTruncated = textContainer.clientWidth < textContainer.scrollWidth;
      setIsDescriptionTruncated(isTruncated);
    }
  }, [resourceTrackedTimeEntry.description]);

  const handleDatePickerWithDurationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDatePickerWithDurationElement(event.currentTarget);
  };

  const isDatePickerWithDurationOpen = Boolean(datePickerWithDurationElement);
  const datePickerWidthDurationId = isDatePickerWithDurationOpen ? 'trackable-date-picker' : undefined;

  const [updateResourceTrackedEntity, { isLoading: isUpdateResourceTrackedEntityLoading }] =
    useUpdateResourceTrackedTimeEntryMutation();

  const handleDatePickerWithDurationClose = async () => {
    await handleResourceTrackedEntityUpdate();
    setDatePickerWithDurationElement(null);
  };

  const handleResourceTrackedEntityUpdateError = error => {
    const errors = generateBackendErrorMessages(error);
    setDescription(resourceTrackedTimeEntry.description);
    const errorMessage = errors.join('\r\n');
    showErrorNotification(errorMessage);
  };

  const handleResourceTrackedEntityUpdate = async () => {
    setIsDescriptionInputFocus(false);
    const durationMinutes = getMinutesFromHours(Number(duration?.hours)) + Number(duration?.minutes);

    const formData = {
      description,
      duration: durationMinutes,
      taskId: currentTimeTrackerTrackable?.task?.id,
      trackableId: currentTimeTrackerTrackable?.trackable?.id,
      trackableType: currentTimeTrackerTrackable?.trackable?.type,
      tagIds: currentTimeTrackerTags?.map(({ id }) => id),
      date: formatToUTCString(date),
    };

    if (_.isEqual(formData, previousFormData)) return;

    const parameters = {
      id: resourceTrackedTimeEntry.id,
      resourceId,
      formData,
    };

    try {
      const resourceTrackedEntity = await updateResourceTrackedEntity(parameters).unwrap();

      setDescription(resourceTrackedEntity.description);
    } catch (error) {
      handleResourceTrackedEntityUpdateError(error);
    }
  };

  const handleTimeEntryProjectUpdate = async (trackable?: ITimeTrackerTrackableState) => {
    setIsDescriptionInputFocus(false);
    const durationMinutes = getMinutesFromHours(Number(duration?.hours)) + Number(duration?.minutes);

    const formData = {
      description,
      duration: durationMinutes,
      taskId: trackable?.task?.id,
      trackableId: trackable?.trackable?.id,
      trackableType: trackable?.trackable?.type,
      tagIds: currentTimeTrackerTags?.map(({ id }) => id),
      date: formatToUTCString(date ?? new Date()),
    };

    if (!_.isEqual(formData, previousFormData)) {
      try {
        await updateResourceTrackedEntity({ id: resourceTrackedTimeEntry.id, resourceId, formData }).unwrap();
      } catch (error) {
        const errors = generateBackendErrorMessages(error);
        for (const message of errors) {
          showErrorNotification(message);
        }
      }
    }
  };

  const handleDeleteResourceTrackedEntity = () => {
    openModal({
      title: t('deleteTimeEntryDialog.title'),
      content: <DeleteTimeEntryDialog id={resourceTrackedTimeEntry.id} resourceId={resourceId} />,
    });
  };

  const handleCopyResourceTrackedEntity = () => {
    const durationMinutes = getMinutesFromHours(Number(duration?.hours)) + Number(duration?.minutes);
    const formData = {
      description,
      date: new Date(),
      duration: durationMinutes,
      trackable: currentTimeTrackerTrackable,
      tags: currentTimeTrackerTags,
    };

    onTimeTrackerSettingsCopy?.(formData);
  };

  const isBillableProject = currentTimeTrackerTrackable.trackable.billable;
  const isTooltipEnabled = !isDescriptionInputFocus && isDescriptionTruncated;

  const isDisabled =
    resourceTrackedTimeEntry.source === TrackedTimeEntrySource.importedFromToggl ||
    isUpdateResourceTrackedEntityLoading;
  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setDescription(value);
  };

  return (
    <ListItem sx={styles.root}>
      <Div sx={styles.content}>
        <Div sx={styles.descriptionBlock}>
          <Tooltip
            arrow
            title={isTooltipEnabled ? resourceTrackedTimeEntry.description : ''}
            placement="top-start"
            componentsProps={{ tooltip: { sx: styles.descriptionTooltip } }}
          >
            <Div ref={descriptionReference}>
              <InputField
                value={description}
                onChange={handleDescriptionChange}
                onBlur={handleResourceTrackedEntityUpdate}
                onFocus={() => setIsDescriptionInputFocus(true)}
                isDisabled={isDisabled}
              />
              <Div sx={styles.inputMirror}>
                <Typography variant="subtitle1">{description}</Typography>
              </Div>
            </Div>
          </Tooltip>
        </Div>

        <Div sx={styles.projectTagsContainer}>
          <Div sx={styles.projectOptionBlock}>
            <ProjectChip
              currentTimeTrackerTrackable={currentTimeTrackerTrackable}
              setCurrentTimeTrackerTrackable={setCurrentTimeTrackerTrackable}
              onCurrentTimeTrackerTrackableChange={handleTimeEntryProjectUpdate}
              isBillable={isBillableProject}
              isDisabled={isDisabled}
            />
          </Div>

          <Div onBlur={handleResourceTrackedEntityUpdate} sx={styles.tagsOptionBlock}>
            <CreateTagsButton
              currentTags={currentTimeTrackerTags}
              setCurrentTags={setCurrentTimeTrackerTags}
              isDisabled={isDisabled}
            />
          </Div>
        </Div>

        <Div sx={styles.billableOptionBlock}>
          {isBillableProject && <AttachMoneyOutlined sx={styles.billableProjectSign} />}
        </Div>

        <Button
          sx={styles.dateWithDuration}
          aria-describedby={datePickerWidthDurationId}
          variant="text"
          onClick={handleDatePickerWithDurationClick}
          disabled={isDisabled}
        >
          <Typography variant="body1">
            {hours}:{minutes}
          </Typography>
        </Button>
        <Popover
          id={datePickerWidthDurationId}
          open={isDatePickerWithDurationOpen}
          anchorEl={datePickerWithDurationElement}
          onClose={handleDatePickerWithDurationClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <DatePickerWithDuration
            duration={duration}
            setDuration={setDuration}
            date={date}
            setDate={setDate}
            isDisabled={isDisabled}
          />
        </Popover>

        <Div data-actions="entity-action" sx={styles.actions}>
          <IconButton onClick={handleCopyResourceTrackedEntity} sx={MUISx(styles.actionButton, styles.copyButton)}>
            <Icon sx={styles.actionButtonIcon} name="copy" />
          </IconButton>
          <IconButton onClick={handleDeleteResourceTrackedEntity} sx={styles.actionButton} disabled={isDisabled}>
            <Icon sx={styles.actionButtonIcon} name="trash" />
          </IconButton>
        </Div>
      </Div>
    </ListItem>
  );
};

export default ResourceTrackedTimeEntry;
