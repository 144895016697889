import { SxStyles } from 'theme';

const styles: SxStyles = {
  listInner: {
    boxSizing: 'border-box',
    borderLeft: '2px solid',
    borderColor: 'text.lightGray',
    paddingLeft: 3,
  },
  listPointer: {
    width: '5px',
    height: '5px',
    color: 'text.lightGray',
  },
  listItemText: {
    display: 'inline',
    flexGrow: 0,
  },
  listItemContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '40px',
    gap: 0.75,
    paddingY: 0.5,
    paddingX: 0,
  },
  innerNode: {
    paddingLeft: 1,
  },
  node: {
    display: 'block',
  },
  archiveButton: {
    color: 'text.red',
  },
  activateButton: {
    color: 'text.green',
  },
  deleteButton: {
    color: 'text.red',
  },
  textContainer: {
    cursor: 'pointer',
    paddingY: 0,
    paddingX: 1,
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'background.gray',
      transition: 'background-color 0.2s linear',
    },
    '&:hover div[data-actions=item-actions]': {
      visibility: 'visible',
      opacity: 1,
      transition: 'visibility 0.2s linear, opacity 0.2s linear',
    },
  },
  archivedNode: {
    opacity: '0.7',
  },
  archivedTitle: {
    display: 'inline-block',
    backgroundColor: 'background.lightGray',
    color: 'text.lightGray',
    paddingY: 0,
    paddingX: 1,
    borderRadius: '4px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    visibility: 'hidden',
  },
};

export default styles;
