import { FC } from 'react';

import { TableRow, TableCell } from '@mui/material';

import styles from './styles';
import { IMigrationsTableHeadProps } from './types';

const MigrationsTableHead: FC<IMigrationsTableHeadProps> = props => {
  const { headerGroup } = props;

  return (
    <TableRow {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map(column => {
        return (
          <TableCell {...column.getHeaderProps()} sx={styles.headerCell}>
            {column.render('Header')}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MigrationsTableHead;
