import { FC, useState, useContext } from 'react';

import { MoreHorizOutlined } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { ModalContext } from 'contexts/ModalContext';

import UpdateVacancyEndDateForm from './components/UpdatevacancyEndDateForm';
import UpdateVacancyTechnologiesForm from './components/UpdateVacancyTechnologiesForm';
import styles from './styles';
import { IVacancyActionsProps } from './types';

const VacancyActions: FC<IVacancyActionsProps> = props => {
  const { vacancy, project, groupId } = props;

  const { t } = useTranslation('vacancyActions');

  const { openModal } = useContext(ModalContext);

  const [vacancyActionsMenu, setVacancyActionsMenu] = useState<null | HTMLElement>(null);

  const isVacancyActionsMenuOpen = Boolean(vacancyActionsMenu);

  const handleVacancyActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setVacancyActionsMenu(event.currentTarget);
  };

  const handleVacancyActionsClose = () => {
    setVacancyActionsMenu(null);
  };

  const handleVacancyUpdateModalOpen = () => {
    openModal({
      title: t('editVacancyModalTitle'),
      subtitle: `${t('vacancy')} - ${project.name}`,
      content: <UpdateVacancyTechnologiesForm projectId={project.id} groupId={groupId} vacancy={vacancy} />,
    });
    setVacancyActionsMenu(null);
  };

  const handleVacancyRemoveEndDateModalOpen = () => {
    openModal({
      title: t('removeVacancyModalTitle'),
      subtitle: (
        <Typography variant="subtitle1" component="span">
          <Trans
            t={t}
            i18nKey="removeVacancyModalSubtitle"
            values={{ projectName: project.name }}
            components={{
              1: <Typography variant="heading3" component="span" />,
              2: <br />,
              3: <Typography variant="heading3" component="span" />,
            }}
          ></Trans>
        </Typography>
      ),
      content: <UpdateVacancyEndDateForm projectId={project.id} groupId={groupId} vacancy={vacancy} />,
    });
    setVacancyActionsMenu(null);
  };

  return (
    <>
      <IconButton
        sx={styles.moreHorizButton}
        id={`actions-button-${vacancy.id}`}
        aria-controls={isVacancyActionsMenuOpen ? `actions-menu-${vacancy.id}` : undefined}
        aria-expanded={isVacancyActionsMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleVacancyActionsClick}
      >
        <MoreHorizOutlined sx={styles.moreHorizIcon} />
      </IconButton>
      <Menu
        id={`actions-menu-${vacancy.id}`}
        anchorEl={vacancyActionsMenu}
        open={isVacancyActionsMenuOpen}
        onClose={handleVacancyActionsClose}
        MenuListProps={{
          'aria-labelledby': `actions-button-${vacancy.id}`,
        }}
      >
        <MenuItem onClick={handleVacancyUpdateModalOpen}>
          <Typography variant="h6">{t('edit')}</Typography>
        </MenuItem>
        <MenuItem onClick={handleVacancyRemoveEndDateModalOpen} sx={styles.removeVacancyAction}>
          <Typography variant="h6">{t('remove')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default VacancyActions;
