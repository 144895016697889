import { SxStyles } from 'theme';

const styles: SxStyles = {
  technologiesAutocomplete: {
    paddingTop: 0.75,
    width: '400px',
    marginBottom: 3.75,
  },
  saveButton: {
    marginRight: 1.25,
  },
};

export default styles;
