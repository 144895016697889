import { SxStyles } from 'theme';

const styles: SxStyles = {
  calendar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    pt: 0,
    px: 2,
  },
  controlPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    my: 3,
  },
};

export default styles;
