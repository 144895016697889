import _ from 'lodash';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { ITechnology } from 'domain/technology/types';

import { convertDateToUTCString } from 'utils/dateTime';

export interface ICreateVacancyFormData {
  fte: number;
  projectId: number;
  groupId: number;
  technologies: Array<ITechnology>;
  startDate: Date | null;
  endDate?: Date | null;
}

export interface ICreateVacancyPreparedFormDataToSubmit
  extends Omit<ICreateVacancyFormData, 'technologies' | 'startDate' | 'endDate'> {
  technologyIds: Array<number>;
  startDate: string;
  endDate: string | null;
}

export interface ICreateVacancyFormDataToSubmit {
  formData: ICreateVacancyPreparedFormDataToSubmit;
}

const requiredValidation = 'forms:validation:required';

const dateFormatValidation = 'forms:validation:dateFormat';

export const buildValidationFields = () => ({
  fte: yup.number().min(0).max(100).required(i18n.t(requiredValidation)).nullable().default(null),
  startDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .required(i18n.t(requiredValidation))
    .nullable()
    .default(new Date()),
  endDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .min(yup.ref('startDate'), i18n.t('forms:validation:startDateBeforeEndDate'))
    .nullable()
    .default(null),
  technologies: yup
    .array()
    .of(yup.mixed<ITechnology>())
    .min(1, i18n.t('forms:validation:minItemsInArray', { count: 1 }))
    .required(i18n.t(requiredValidation))
    .default([]),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (
  projectId: number,
  groupId: number,
  formData: ICreateVacancyFormData,
): ICreateVacancyFormDataToSubmit => {
  const { technologies, startDate, endDate, ...values } = formData;

  const technologyIds = technologies.map(({ id }) => id);
  const normalizeStartDate = convertDateToUTCString(startDate);
  const normalizeEndDate = _.isNil(endDate) ? null : convertDateToUTCString(endDate);

  return {
    formData: {
      technologyIds,
      projectId,
      groupId,
      startDate: normalizeStartDate,
      endDate: normalizeEndDate,
      ...values,
    },
  };
};
