import { SxStyles } from 'theme';

const styles: SxStyles = {
  measurementUnits: {
    display: 'flex',
    width: '216px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  measurementUnit: {
    height: '40px',
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: 'border.main',
    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  measurementUnitButton: {
    width: '100%',
    height: '100%',
    color: 'text.gray',
    backgroundColor: 'background.white',
    border: 'none',
    borderRadius: 0,
  },
  measurementUnitButtonActive: {
    backgroundColor: 'background.gray',
  },
};

export default styles;
