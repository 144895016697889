import { FC } from 'react';

import TreeInner from './components/TreeInner';
import { ITreeProps } from './types';
import { normalizeData } from './utils';

const Tree: FC<ITreeProps> = props => {
  const { data, onEditModalOpen, onAddModalOpen, onNodeActivate, onNodeDeactivate, isLoading, type } = props;

  const dataTree = normalizeData(data);

  return (
    <TreeInner
      data={dataTree}
      isRootNode={true}
      onEditModalOpen={onEditModalOpen}
      onAddModalOpen={onAddModalOpen}
      onNodeDeactivate={onNodeDeactivate}
      onNodeActivate={onNodeActivate}
      isLoading={isLoading}
      type={type}
    />
  );
};

export default Tree;
