import { FC, useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, FormControl } from '@mui/material';
import _ from 'lodash';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Autocomplete from 'components/common/Autocomplete';
import Div from 'components/common/Div';
import Loader from 'components/common/Loader';
import SkillLevelSelect from 'components/common/SkillLevelSelect';

import { ModalContext } from 'contexts/ModalContext';

import { useCreateResourceAbilityMutation } from 'domain/resource/ability/apiSlice';
import {
  ICreateResourceAbilityFormData,
  initialValues,
  buildValidationSchema,
  formatAttributesToSubmit,
} from 'domain/resource/ability/schemas/create';
import { useGetTechnologiesQuery } from 'domain/technology/apiSlice';
import { ITechnology } from 'domain/technology/types';

import { SkillLevel } from 'enums/SkillLevel';

import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { ICreateResourceAbilityFormProps } from './types';

const CreateResourceAbilityForm: FC<ICreateResourceAbilityFormProps> = props => {
  const { resourceId } = props;

  const { t } = useTranslation('userProfile');

  const [technologyName, setTechnologyName] = useState<string>('');
  const [debouncedTechnologyName] = useDebounce(technologyName, DEFAULT_DEBOUNCE_DELAY_MS);

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ICreateResourceAbilityFormData>({
    defaultValues: initialValues(),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    data: technologiesData,
    isLoading: isTechnologiesDataLoading,
    isError: isTechnologiesDataLoadingError,
    isFetching: isTechnologiesDataFetching,
  } = useGetTechnologiesQuery({ nameCont: debouncedTechnologyName });

  const [createResourceAbility, { isLoading: isCreateResourceAbilityLoading }] = useCreateResourceAbilityMutation();

  const {
    field: { value: technology, onChange: onTechnologyChange },
  } = useController({ name: 'technology', control });

  const {
    field: { value: skillLevel, onChange: onSkillLevelChange },
  } = useController({ name: 'skillLevel', control });

  const { closeModal } = useContext(ModalContext);

  if (isTechnologiesDataLoading) {
    return <Loader />;
  }

  if (isTechnologiesDataLoadingError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  const handleCurrentTechnologyChange = (currentTechnology: ITechnology) => {
    if (_.isNil(currentTechnology)) {
      onTechnologyChange(null);
      return;
    }
    onTechnologyChange(currentTechnology);
  };

  const onSubmit = async (formData: ICreateResourceAbilityFormData) => {
    try {
      await createResourceAbility(formatAttributesToSubmit(resourceId, formData)).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  const handleSkillLevelChange = (skillLevel: SkillLevel) => {
    onSkillLevelChange(skillLevel);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.select}>
        <Autocomplete
          isRequired
          helperText={buildErrorMessage(t, errors.technology)}
          options={technologiesData.technologies}
          isFetching={isTechnologiesDataFetching}
          isError={Boolean(errors.technology)}
          name={technologyName}
          setName={setTechnologyName}
          onCurrentValueChange={handleCurrentTechnologyChange}
          currentValue={technology}
          label={t('specializationAndStack.stackInputLabel')}
        />
      </Div>

      <FormControl fullWidth sx={styles.select}>
        <SkillLevelSelect
          value={skillLevel}
          onChange={handleSkillLevelChange}
          error={errors.skillLevel}
          label={t('specializationAndStack.skillInputLabel')}
          isRequired
        />
      </FormControl>

      <Div sx={styles.buttonsContainer}>
        <Button type="submit" variant="contained" disabled={isCreateResourceAbilityLoading}>
          {t('specializationAndStack.saveButton')}
        </Button>
        <Button variant="outlined" onClick={closeModal} disabled={isCreateResourceAbilityLoading}>
          {t('specializationAndStack.cancelButton')}
        </Button>
      </Div>
    </form>
  );
};

export default CreateResourceAbilityForm;
