import palette from 'theme/baseTheme/palette';

import { IMuiTabType } from './types';

const MuiTab: IMuiTabType = {
  defaultProps: {},
  styleOverrides: {
    root: {
      textTransform: 'capitalize',
      fontSize: '16px',
      lineHeight: '24px',
      color: palette.text.black,
      letterSpacing: '0.005em',
    },
  },
};

export default MuiTab;
