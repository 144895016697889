import { format } from 'date-fns';

import { formatToDateWithFixedTimeZone } from 'utils/dateTime';

import { VacationApprovementApprovingLevelType } from './enums';

export const formatVacationRequestRangeDate = (date: string) => {
  return format(formatToDateWithFixedTimeZone(date), 'MMM d');
};

export const isTechCenter = (type: VacationApprovementApprovingLevelType): boolean =>
  type === VacationApprovementApprovingLevelType.group;
