import { FC, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MUISx from 'mui-sx';
import { useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateResourceUsageWorkloadPlanMutation } from 'domain/resource/usage/workloadPlan/apiSlice';
import {
  formatAttributesToSubmit,
  initialValues,
  IUpdateResourceUsageWorkloadPlanFormData,
  buildValidationSchema,
} from 'domain/resource/usage/workloadPlan/schemas/update';

import { useNotifications } from 'hooks/useNotifications';

import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';

import styles from './styles';
import { IUpdateProjectPlannedHoursForm } from './types';

const UpdateProjectPlannedHoursForm: FC<IUpdateProjectPlannedHoursForm> = props => {
  const { resourceId, resourceUsageId, hours, startDate, endDate } = props;

  const { t } = useTranslation('reportHoursByProject');

  const { closeModal } = useContext(ModalContext);

  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [updateResourceUsageWorkloadPlan, { isLoading: isUpdateResourceUsageWorkloadPlanLoading }] =
    useUpdateResourceUsageWorkloadPlanMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IUpdateResourceUsageWorkloadPlanFormData>({
    defaultValues: initialValues(hours, startDate, endDate),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: startDateValue, onChange: onStartDateChange },
  } = useController({ name: 'startDate', control });

  const {
    field: { value: endDateValue, onChange: onEndDateChange },
  } = useController({ name: 'endDate', control });

  const onSubmit = async (formData: IUpdateResourceUsageWorkloadPlanFormData) => {
    try {
      await updateResourceUsageWorkloadPlan(formatAttributesToSubmit(resourceId, resourceUsageId, formData)).unwrap();

      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    } finally {
      closeModal();
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.root}>
        <TextField
          required
          autoFocus
          sx={styles.input}
          label={t('updatePlannedHoursForm.fields.plannedHours.label')}
          InputLabelProps={{ sx: styles.selectPlaceholder }}
          error={Boolean(errors.plan)}
          helperText={buildErrorMessage(t, errors.plan)}
          {...register('plan')}
        />
        <Div>{t('updatePlannedHoursForm.period')}</Div>
        <Div sx={styles.groupFields}>
          <Div sx={MUISx(styles.datePicker, styles.startDate)}>
            <DatePicker
              label={t('updatePlannedHoursForm.fields.startDate.label')}
              value={startDateValue}
              onChange={onStartDateChange}
              renderInput={parameters => (
                <TextField
                  required
                  {...parameters}
                  error={Boolean(errors.startDate)}
                  helperText={buildErrorMessage(t, errors.startDate)}
                  sx={styles.datePickerField}
                />
              )}
            />
          </Div>

          <Div sx={styles.datePicker}>
            <DatePicker
              label={t('updatePlannedHoursForm.fields.endDate.label')}
              value={endDateValue}
              onChange={onEndDateChange}
              renderInput={parameters => (
                <TextField
                  {...parameters}
                  helperText={buildErrorMessage(t, errors.endDate)}
                  error={Boolean(errors.endDate)}
                  sx={styles.datePickerField}
                />
              )}
            />
          </Div>
        </Div>

        <Div>
          <Button sx={styles.updateButton} type="submit" disabled={isUpdateResourceUsageWorkloadPlanLoading}>
            {t('updatePlannedHoursForm.buttons.update')}
          </Button>
          <Button
            type="reset"
            variant="outlined"
            onClick={closeModal}
            disabled={isUpdateResourceUsageWorkloadPlanLoading}
          >
            {t('updatePlannedHoursForm.buttons.cancel')}
          </Button>
        </Div>
      </Div>
    </form>
  );
};

export default UpdateProjectPlannedHoursForm;
