import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  addProjectAndTaskButton: {
    borderColor: 'border.whiteBlue',
    backgroundColor: 'border.whiteBlue',
    borderRadius: '32px',
  },
  trackableButton: {
    background: 'none',
    border: 'none',
  },
  plusIcon: {
    width: '24px',
    height: '24px',
  },
  trackable: {
    display: 'flex',
  },
  popover: {
    left: '-250px',
  },
};

export default styles;
