import { FC } from 'react';

import { List, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Task from './components/Task';
import styles from './styles';
import { ITasksListProps } from './types';

const TasksList: FC<ITasksListProps> = props => {
  const { projectId, tasks, canUpdateTasks } = props;

  const { t } = useTranslation('reportHoursByProject');

  if (_.isEmpty(tasks)) {
    return (
      <Typography sx={styles.noTasks} variant="heading3">
        {t('projectTasks.tasksList.noTasks')}
      </Typography>
    );
  }

  return (
    <List>
      {tasks.map(task => {
        return <Task key={task.id} task={task} projectId={projectId} canUpdateTasks={canUpdateTasks} />;
      })}
    </List>
  );
};

export default TasksList;
