import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    gap: 5,
    minHeight: '100%',
  },
  specificationsTreeContainer: {
    marginTop: 2.5,
  },
};

export default styles;
