import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    position: 'relative',
    '& .rdrInputRanges': {
      display: 'none',
    },
  },
  button: {
    position: 'absolute',
    width: '206px',
    left: '10px',
    bottom: '20px',
  },
};

export default styles;
