export default {
  title: 'Reset Password',
  subtitle: 'Enter your email address and we will send you instructions on how to reset your password.',
  form: {
    emailField: {
      placeholder: 'Email@dualbootpartners.com',
    },
    resetButton: 'Reset Password',
    returnToLoginButton: 'Return to Login',
  },
};
