import { invalidateTags, getListTag, TagTypes, provideTags } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IUpdateCompanySettingFormDataToSubmit } from './schemas/form';
import { ICompanySettings } from './types';

interface ICompanySettingsResponse {
  companySettings: ICompanySettings;
}

export const companyApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getCompanySettings: builder.query<ICompanySettingsResponse, number>({
      query: () => ({
        url: apiRoutes.apiV1CompanySettingsPath(),
        method: 'GET',
      }),
      providesTags: response =>
        provideTags(TagTypes.CompanySettings, response?.companySettings ? [response?.companySettings] : []),
    }),
    updateCompanySettings: builder.mutation<ICompanySettingsResponse, IUpdateCompanySettingFormDataToSubmit>({
      query: ({ formData }) => ({
        url: apiRoutes.apiV1CompanySettingsPath(),
        method: 'PUT',
        data: {
          ...formData,
        },
      }),
      invalidatesTags: invalidateTags(getListTag(TagTypes.CompanySettings), getListTag(TagTypes.Company)),
    }),
  }),
  overrideExisting: false,
});

export const { useGetCompanySettingsQuery, useUpdateCompanySettingsMutation } = companyApi;
