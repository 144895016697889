import { FC, useContext } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ModalContext } from 'contexts/ModalContext';

import UpdateProjectMonthBudgetForm from './components/UpdateProjectMonthBudgetForm';
import { IProjectBudgetMonthsTableActionsProps } from './types';

const Actions: FC<IProjectBudgetMonthsTableActionsProps> = props => {
  const { projectId, budget } = props;

  const { t } = useTranslation('reportHoursByProject');

  const { openModal } = useContext(ModalContext);

  const handleEditModalOpen = () => {
    openModal({
      title: t('projectBudgetMonths.table.actions.editModalTitle'),
      content: <UpdateProjectMonthBudgetForm projectId={projectId} budget={budget} />,
    });
  };

  return (
    <Button onClick={handleEditModalOpen} type="button" variant="text">
      {t('projectBudgetMonths.table.actions.buttons.edit')}
    </Button>
  );
};

export default Actions;
