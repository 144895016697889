import { FC } from 'react';

import { ExpandMoreOutlined } from '@mui/icons-material';
import { InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { getPtoTypeLabel } from 'domain/report/hoursByResource/presenter';

import { PtoType } from 'enums/PtoType';

import styles from './styles';
import { IPtoFilterProps } from './types';

const PtoFilter: FC<IPtoFilterProps> = props => {
  const { onChange, value } = props;

  const { t } = useTranslation('reportHoursByResources');

  return (
    <Div sx={styles.root}>
      <InputLabel sx={styles.hoursTypeLabel} id="hoursType-select-label">
        <Typography sx={styles.hoursTypeLabelText} variant="heading3">
          {t('filter.ptoFilter.title')}
        </Typography>
      </InputLabel>
      <Select
        labelId="groupedBy-select-label"
        sx={styles.select}
        value={value}
        onChange={onChange}
        displayEmpty
        IconComponent={ExpandMoreOutlined}
      >
        {Object.values(PtoType).map(ptoType => {
          return (
            <MenuItem key={ptoType} value={ptoType}>
              <Typography sx={styles.ptoTypeOptionText} variant="subtitle1">
                {getPtoTypeLabel(ptoType, t)}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </Div>
  );
};

export default PtoFilter;
