import { provideTags, TagTypes } from 'shared/api/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';

import { IUpdateProjectMonthBudgetFormDataToSubmit } from './schemas/update';
import { IProjectBudgetMonth, IDateBetween } from './types';

interface IGetProjectBudgetMonthsesponse {
  budgetMonths: Array<IProjectBudgetMonth>;
  meta: IMeta;
}

interface IGetProjectBudgetMonthResponse {
  budgetMonth: IProjectBudgetMonth;
}

interface IGetProjectBudgetMonthsQueryParameters {
  dateBetween?: IDateBetween;
  s?: string | Array<string>;
}

interface IGetProjectBudgetMonthsPlainParameters extends IGetProjectBudgetMonthsQueryParameters {
  page?: number;
  perPage?: number;
}

interface IGetProjectBudgetMonthsParameters {
  page?: number;
  perPage?: number;
  projectId: number;
  q?: IGetProjectBudgetMonthsQueryParameters;
}

interface IGetProjectBudgetMonthParameters {
  projectId: number;
  id: number;
}

export const loadingGetProjectBudgetMonthsParameters = (
  projectId: number,
  parameters: IGetProjectBudgetMonthsPlainParameters,
): IGetProjectBudgetMonthsParameters => {
  const { page, perPage, ...queryParameters } = parameters;
  return {
    perPage,
    page,
    projectId,
    q: { ...queryParameters },
  };
};

export const projectBudgetMonthApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getProjectBudgetMonths: builder.query<IGetProjectBudgetMonthsesponse, IGetProjectBudgetMonthsParameters>({
      query: ({ projectId, ...parameters }) => ({
        url: apiRoutes.apiV1ProjectBudgetsMonthsPath(projectId),
        method: 'GET',
        params: {
          ...parameters,
        },
      }),
      providesTags: response => provideTags(TagTypes.ProjectBudgetMonth, response?.budgetMonths),
    }),
    getProjectBudgetMonth: builder.query<IGetProjectBudgetMonthResponse, IGetProjectBudgetMonthParameters>({
      query: ({ projectId, id }) => ({
        url: apiRoutes.apiV1ProjectBudgetsMonthPath(projectId, id),
        method: 'GET',
      }),
      providesTags: [TagTypes.ProjectBudgetMonth],
    }),
    updateProjectBudgetMonth: builder.mutation<
      IGetProjectBudgetMonthResponse,
      IUpdateProjectMonthBudgetFormDataToSubmit
    >({
      query: ({ projectId, budgetMonthId, formData }) => ({
        url: apiRoutes.apiV1ProjectBudgetsMonthPath(projectId, budgetMonthId),
        method: 'PUT',
        data: formData,
      }),
      invalidatesTags: ({ budgetMonth }) => {
        return [
          { type: TagTypes.ProjectBudgetMonth, id: budgetMonth.id },
          { type: TagTypes.ReportHoursByProject, id: budgetMonth.projectId },
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const { useUpdateProjectBudgetMonthMutation, useGetProjectBudgetMonthQuery, useGetProjectBudgetMonthsQuery } =
  projectBudgetMonthApi;
