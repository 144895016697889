import { FC } from 'react';

import { AttachMoneyOutlined } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';

import Div from 'components/common/Div';

import { getTagsNames } from 'domain/tag/presenter';

import {
  formatToDateWithFixedTimeZone,
  formatToShortString,
  getHoursAndMinutesStringFromMinutes,
} from 'utils/dateTime';
import { getFullName } from 'utils/person';

import TrackedTimeEntryProjectChip from './components/TrackedTimeEntryProjectChip';
import TrackedTimeEntryTagsChip from './components/TrackedTimeEntryTagsChip';
import UserChip from './components/TrackedTimeEntryUserChip';
import styles from './styles';
import { ITrackedTimeEntryProps } from './types';

const TrackedTimeEntry: FC<ITrackedTimeEntryProps> = props => {
  const { trackedTimeEntry, isGroupingByProject = false, isGroupingByUser = false } = props;

  const hasTags = !_.isEmpty(trackedTimeEntry.tags);

  const fullName = getFullName(trackedTimeEntry.user);
  const isBillable = trackedTimeEntry.billable;
  const durationView = getHoursAndMinutesStringFromMinutes(trackedTimeEntry.duration);
  const dateView = formatToShortString(formatToDateWithFixedTimeZone(trackedTimeEntry.date));
  const tagsNames = hasTags ? getTagsNames(trackedTimeEntry.tags) : [];

  return (
    <Div sx={styles.root}>
      <Div sx={styles.projectInfo}>
        <Tooltip
          arrow
          title={trackedTimeEntry.description}
          placement="top"
          componentsProps={{ tooltip: { sx: styles.descriptionTooltip } }}
          PopperProps={{
            disablePortal: true,
          }}
        >
          <Typography sx={styles.description} variant="subtitle1" component="p">
            {trackedTimeEntry.description}
          </Typography>
        </Tooltip>

        <Div sx={styles.projectWithTaskBlock}>
          {!isGroupingByProject && (
            <TrackedTimeEntryProjectChip
              isBillable={isBillable}
              projectName={trackedTimeEntry.trackableName}
              taskName={trackedTimeEntry?.task?.name}
            />
          )}
        </Div>
        {hasTags && (
          <Div sx={styles.tagsBlock}>
            <TrackedTimeEntryTagsChip tagsNames={tagsNames} />
          </Div>
        )}
      </Div>
      <Div sx={MUISx(styles.userInfo, { condition: isGroupingByUser, sx: styles.userInfoWithoutName })}>
        {!isGroupingByUser && <UserChip fullName={fullName} avatarSrc={trackedTimeEntry?.user?.avatar} />}

        <Div sx={styles.durationDateBlock}>
          <Div sx={styles.billableBlock}>{isBillable && <AttachMoneyOutlined sx={styles.billableProjectSign} />}</Div>

          <Typography sx={styles.duration} variant="subtitle1" component="p">
            {durationView}
          </Typography>
          <Typography variant="subtitle1" component="p">
            {dateView}
          </Typography>
        </Div>
      </Div>
    </Div>
  );
};

export default TrackedTimeEntry;
