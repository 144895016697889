import { FC } from 'react';

import { ListItem, Tooltip, Typography } from '@mui/material';
import MUISx from 'mui-sx';
import { Link } from 'react-router-dom';

import { useScreen } from 'hooks';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { isSuperAdmin } from 'domain/user/service';

import styles from './styles';
import { INavigationItemProps } from './types';

const NavigationItem: FC<INavigationItemProps> = props => {
  const { navigationItem, isActive, currentUser } = props;

  const { lessThanDesktop } = useScreen();

  const isItemVisible = isSuperAdmin(currentUser) || navigationItem.route.protected.includes(currentUser.userType);
  return (
    <>
      <ListItem
        sx={MUISx(
          styles.item,
          { condition: isActive, sx: styles.activeItem },
          { condition: !isItemVisible, sx: styles.itemHidden },
        )}
      >
        <Typography sx={styles.link} to={navigationItem.route.path()} component={Link}>
          <Div sx={MUISx(styles.iconBlock, { condition: isActive, sx: styles.activeItemIconBlock })}>
            {lessThanDesktop ? (
              <Tooltip arrow title={navigationItem.title} placement="right">
                <Icon name={navigationItem.iconName} />
              </Tooltip>
            ) : (
              <Icon name={navigationItem.iconName} />
            )}
          </Div>
          {!lessThanDesktop && (
            <Typography variant="h5" sx={MUISx(styles.title, { condition: isActive, sx: styles.activeItemTitle })}>
              {navigationItem.title}
            </Typography>
          )}
        </Typography>
      </ListItem>
    </>
  );
};

export default NavigationItem;
