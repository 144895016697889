import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';

import { IResourceFavoriteTimeEntry } from 'domain/resource/favoriteTimeEntry/types';
import {
  initialValues,
  ICreateResourceTrackedTimeEntryFormData,
  buildValidationSchema,
} from 'domain/resource/trackedTimeEntry/schemas/create';
import { IResourceTrackedTimeEntry } from 'domain/resource/trackedTimeEntry/types';
import { ITag } from 'domain/tag/types';

import { IDuration } from 'types/dates';
import { ITimeTrackerTrackableState } from 'types/timeTrackerTrackable';

import { getStringHoursFromDuration, getStringMinutesFromDuration } from 'utils/dateTime';

import CreateResourceTrackedEntityField from '../CreateResourceTrackedEntityField';
import { ICopiedTimeTrackerSettings } from '../CreateResourceTrackedEntityField/types';
import FavoriteTrackedTimeEntriesBar from '../FavoriteTrackedTimeEntriesBar';
import TotalInfo from '../TotalInfo';

interface ITimeTrackerHeaderProps {
  trackedTimeEntries: IResourceTrackedTimeEntry[];
  resourceId: number;
  todayTotalHours: number;
  currentWeekTotalHours: number;
  pastWeekTotalHours: number;
  copiedTimeTrackerSettings?: ICopiedTimeTrackerSettings;
}

const TimeTrackerHeader = (props: ITimeTrackerHeaderProps) => {
  const {
    trackedTimeEntries,
    resourceId,
    todayTotalHours,
    currentWeekTotalHours,
    pastWeekTotalHours,
    copiedTimeTrackerSettings,
  } = props;

  const [currentTimeTrackerTags, setCurrentTimeTrackerTags] = useState<Array<ITag>>([]);
  const [duration, setDuration] = useState<IDuration | null>({ hours: '00', minutes: '00' });

  const [currentTimeTrackerTrackable, setCurrentTimeTrackerTrackable] = useState<ITimeTrackerTrackableState>({
    trackable: null,
    task: null,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICreateResourceTrackedTimeEntryFormData>({
    defaultValues: initialValues(),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: description, onChange: onDescriptionChange },
  } = useController({ name: 'description', control });

  const {
    field: { value: trackable, onChange: onTrackableChange },
  } = useController({ name: 'trackable', control });

  const handleFavoriteClick = (event: React.MouseEvent<HTMLElement>, favorite: IResourceFavoriteTimeEntry) => {
    if (favorite.description.length > 0) onDescriptionChange(favorite.description);

    if (favorite.trackableId) {
      const trackableAndTask = {
        trackable: {
          id: favorite.trackableId,
          name: favorite.projectName,
          type: favorite.trackableType,
          billable: favorite.billable,
        },
        task: {
          id: favorite.projectTaskId,
          name: favorite.projectTaskName,
          projectId: favorite.trackableId,
          state: favorite.projectTaskState,
        },
      };

      onTrackableChange(trackableAndTask);
      setCurrentTimeTrackerTrackable(trackableAndTask);
    }

    if (favorite.tags.length > 0) setCurrentTimeTrackerTags(favorite.tags);

    if (favorite.duration)
      setDuration({
        hours: getStringHoursFromDuration(favorite.duration),
        minutes: getStringMinutesFromDuration(favorite.duration),
      });
  };

  return (
    <>
      <CreateResourceTrackedEntityField
        trackedTimeEntries={trackedTimeEntries ?? []}
        copiedTimeTrackerSettings={copiedTimeTrackerSettings}
        resourceId={resourceId}
        currentTimeTrackerTrackable={currentTimeTrackerTrackable}
        setCurrentTimeTrackerTrackable={setCurrentTimeTrackerTrackable}
        currentTimeTrackerTags={currentTimeTrackerTags}
        setCurrentTimeTrackerTags={setCurrentTimeTrackerTags}
        trackable={trackable}
        onTrackableChange={onTrackableChange}
        handleSubmit={handleSubmit}
        duration={duration}
        setDuration={setDuration}
        reset={reset}
        errors={errors}
        description={description}
        onDescriptionChange={onDescriptionChange}
      />
      <FavoriteTrackedTimeEntriesBar resourceId={resourceId} handleClick={handleFavoriteClick} />
      <TotalInfo
        todayTotalMinutes={todayTotalHours ?? 0}
        currentWeekTotalMinutes={currentWeekTotalHours ?? 0}
        pastWeekTotalMinutes={pastWeekTotalHours ?? 0}
      />
    </>
  );
};

export default TimeTrackerHeader;
